import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Grid } from "@mui/material";

import { CardProductHighlight } from "../Card/CardProductHighlight";
import { Carousel } from "./Carousel";

import { IProduct } from "../../models/Product.model";


interface Props {
    products: IProduct[];
}


const defaultNbItemsMD: number = 2.5;
const defaultNbItemsSM: number = 1.5;
const defaultNbItemsXS: number = 1.1;


export const CarouselProductHighlight = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));


    return (
        <>
        {(screenSizeUpMD && props.products.length > Math.floor(defaultNbItemsMD)) ||
         (!screenSizeUpMD && props.products.length > Math.floor(defaultNbItemsXS)) ?
        <Carousel
            elements={
                props.products.map((_, id) => {
                return <CardProductHighlight
                    product={_} />;
                })
            }
            nbItemsMD={defaultNbItemsMD}
            nbItemsSM={defaultNbItemsSM}
            nbItemsXS={defaultNbItemsXS} />

        :
        <Grid
            container
            justifyContent="center"
            spacing={2}>
            {props.products.map(_ => {
                return (
                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <CardProductHighlight product={_} />
                    </Grid>
                );})}
        </Grid>}
        </>
    );
}
