
import { defaultIconColor, defaultIconSize, IIcon } from "./settings"


export const ArrowRightIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 34 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M2 13.0103L32 13.0103" stroke={props.color || defaultIconColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.0202 1.99982L32.0306 13.0103L21.0202 24.0207" stroke={props.color || defaultIconColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
