import { cloneElement } from "react";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography"

import { ArrowRightIcon } from "../Icon/ArrowRightIcon";

import { getProductTypeInfos, IProductTypeInfos, ProductType } from "../../models/Product.model";


interface Props {
    type: ProductType;
}


export const CardProductType = (props: Props) => {

    const navigate = useNavigate();

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));

    const productInfos: IProductTypeInfos = getProductTypeInfos(props.type);

    return (
        <Card
            sx={{
                height: '100%',
                textAlign: 'center',
                backgroundColor: productInfos.color,
                boxShadow: "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: "0px 4px 20px 0px #3F8CD860",
                }
            }} >

            <CardActionArea
                sx={{
                    height: '100%'
                }}
                onClick={() => {
                    switch (props.type) {
                        case ProductType.APPLICATION:
                            navigate('/applications');
                            break;
                        case ProductType.OBJECT:
                            navigate('/objets');
                            break;
                        case ProductType.SERVICE:
                            navigate('/services');
                            break;
                        default:
                            break;
                    }
                }} >

                <Grid
                    container
                    direction="column"
                    justifyContent="flex-end"
                    spacing={0} >

                    {productInfos.illustration &&
                    <Grid
                        item>
                        {cloneElement(
                            productInfos.illustration,
                            {
                                width: screenSizeUpMD ? '100px' : '80px',
                                height: screenSizeUpMD ? '100px' : '80px'
                            })
                        }
                    </Grid>}

                    <Grid
                        item>
                        <CardContent
                            sx={{
                                px: 0
                            }}>
                            <Typography variant='h3' color='white'>{productInfos.label}</Typography>
                            <ArrowRightIcon color='white'/>
                        </CardContent>

                    </Grid>
                </Grid>

            </CardActionArea>
      </Card>
    )
}
