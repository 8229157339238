import { IProduct } from "./Product.model";


export interface IProductOffer {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    type: OfferType;
    value: number
    unit: UnitType;
    description?: string;
    isActive: boolean;
    product: IProduct;
}


export enum OfferType {
    DISCOUNT="DISCOUNT",
    PERIOD="PERIOD",
    CONSO="CONSO"
}


export const offerTypeOptions = [
    {
        value: OfferType.DISCOUNT,
        label: "Remise"
    },
    {
        value: OfferType.PERIOD,
        label: "Période"
    },
    {
        value: OfferType.CONSO,
        label: "Consommation"
    }
]


export enum UnitType {
    EURO="EURO",
    PERCENT="PERCENT",
    NUMBER="NUMBER",
    DAY="DAY",
    MONTH="MONTH",
    YEAR="YEAR"
}


export const unitTypeOptions = [
    {
        value: UnitType.EURO,
        label: "€"
    },
    {
        value: UnitType.PERCENT,
        label: "%"
    },
    {
        value: UnitType.NUMBER,
        label: "Nombre"
    },
    {
        value: UnitType.DAY,
        label: "Jour"
    },
    {
        value: UnitType.MONTH,
        label: "Mois"
    },
    {
        value: UnitType.YEAR,
        label: "Année"
    }
]
