import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ChevronRightIcon } from "../Icon/ChevronRightIcon";
import { FruitIcon } from "../Icon/FruitIcon";
import { LeafIcon } from "../Icon/LeafIcon";
import { ServiceIllustration } from "../Icon/Illustration/ServiceIllustration";
import { ObjectIllustration } from "../Icon/Illustration/ObjectIllustration";

import { colors } from "../../static/colors";
import AuthService from "../../service/AuthService";


const authentication = new AuthService();


export const CardPackDigitalDiscover = () => {

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                position: 'relative'
            }}>

            <Box
                sx={{
                    zIndex: 'modal',
                    position: 'absolute',
                    top: {
                        md: -45,
                        xs: -35
                    },
                    left: {
                        xs: -15
                    },
                    width: {
                        md: '120px',
                        xs: '80px'
                    }
                }}>
                <ObjectIllustration
                    color={colors.orange.secondary}
                    bgColor={colors.blue.main}
                    width='100%'
                    height='100%'
                    showDetails />
            </Box>

            <Box
                sx={{
                    zIndex: 'modal',
                    position: 'absolute',
                    bottom: {
                        md: -75,
                        xs: -55
                    },
                    right: {
                        md: 0,
                        xs: -10
                    },
                    width: {
                        md: '160px',
                        xs: '100px'
                    }
                }}>
                <FruitIcon
                    color={colors.orange.secondary}
                    bgColor={colors.blue.main}
                    width='100%'
                    height='100%'
                    showDetails />
            </Box>

            <Card
                sx={{
                    height: '100%',
                    position: 'relative',
                    textAlign: 'center',
                    backgroundColor: colors.blue.main
                }}>

                <Box
                    sx={{
                        position: 'absolute',
                        width: {
                            lg: '200px',
                            md: '175px',
                            xs: '150px'
                        },
                        bottom: {
                            md: -55,
                            xs: -70
                        },
                        left: {
                            md: -35,
                            sm: -25,
                            xs: -20
                        },
                        transform: 'rotate(0deg)'
                    }}>
                    <LeafIcon color='white' width='100%' />
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        display: {
                            sm: 'block',
                            xs: 'none'
                        },
                        width: {
                            md: '140px',
                            sm: '120px'
                        },
                        bottom: {
                            md: -35,
                            xs: -60
                        },
                        right: {
                            md: -35,
                            sm: -25,
                            xs: -20
                        },
                        transform: 'scale(1, -1) rotate(180deg)'
                    }}>
                    <ServiceIllustration color='white' bgColor={colors.blue.secondary} showDetails width='100%' />
                </Box>

                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    sx={{
                        height: '100%',
                        px: {
                            lg: 8,
                            xs: 2
                        },
                        pt: {
                            md: 8,
                            xs: 6
                        },
                        pb: {
                            xs: 6
                        }
                    }}>

                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant="h2"
                            sx={{
                                mb: 2
                            }}>
                            Découvrez le pack digital de la mutuelle GSMC et profitez gratuitement de vos innovations préférées !
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Button
                            onClick={() => navigate("/pack-digital")}
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                px: {
                                    sm: 10,
                                    xs: 4
                                },
                                color: colors.black.main,
                                backgroundColor: 'white',
                                ':hover': {
                                    backgroundColor: 'white'
                                }
                            }}
                        >
                            Je découvre le pack digital
                        </Button>
                    </Grid>
                    {!authentication.isLoggedIn() &&
                        <Grid
                            item
                            xs={12}>
                            <Button
                                onClick={() => navigate("/login")}
                                variant='text'
                                color='inherit'
                                endIcon={<ChevronRightIcon />}
                                sx={{
                                    width: {
                                        sm: 'auto',
                                        xs: '100%'
                                    },
                                    px: {
                                        sm: 10,
                                        xs: 4
                                    }
                                }} >
                                Je suis déjà adhérent
                            </Button>
                        </Grid>}

                </Grid>
            </Card>
        </Box>
    )
}
