import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const SportIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <rect width="16.4557" height="4.83301" transform="matrix(0.819268 -0.573411 0.580983 0.813916 5.63672 14.7249)" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        <rect width="3.34835" height="10.6326" rx="1.67418" transform="matrix(0.819268 -0.573411 0.580983 0.813916 1.12109 14.346)" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        <rect width="3.36931" height="10.6326" rx="1.68466" transform="matrix(0.819268 -0.573411 0.580983 0.813916 17.4287 2.93201)" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        </svg>
    )
}
