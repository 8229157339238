import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { LeafIcon } from "../Icon/LeafIcon";

import { colors } from "../../static/colors";



export const CardBecomeSubscriber = () => {


    return (
        <Card
            sx={{
                height: '100%',
                position: 'relative',
                textAlign: 'center',
                backgroundColor: colors.black.main
            }}>

            <Box
                sx={{
                    width: {
                        lg: '200px',
                        md: '175px',
                        xs: '150px'
                    },
                    position: 'absolute',
                    top: {
                        sm: -85,
                        xs: -100
                    },
                    left: {
                        md: -75,
                        xs: -65
                    },
                    transform: 'rotate(200deg)'
                }}>
                <LeafIcon color={colors.blue.main} width='100%' />
            </Box>

            <Box
                sx={{
                    width: {
                        lg: '200px',
                        md: '175px',
                        xs: '150px'
                    },
                    position: 'absolute',
                    bottom: -50,
                    right: {
                        md: -70,
                        xs: -50
                    },
                    transform: 'rotate(75deg)'
                }}>
                <LeafIcon color={colors.blue.main} width='100%' />
            </Box>

            <Box
                sx={{
                    width: {
                        lg: '200px',
                        md: '175px',
                        xs: '150px'
                    },
                    position: 'absolute',
                    bottom: -100,
                    right: {
                        md: 20,
                        sm: 20,
                        xs: 10
                    },
                    transform: 'rotate(15deg)'
                }}>
                <LeafIcon color={colors.blue.main} width='100%' />
            </Box>

            <Grid
                container
                justifyContent="center"
                spacing={2}
                sx={{
                    height: '100%',
                    px: {
                        lg: 8,
                        xs: 2
                    },
                    py: {
                        md: 10,
                        sm: 8,
                        xs: 7
                    }
                }}>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: 'white'
                        }}>
                        Pas encore adhérent ?<br />Découvrez la mutuelle GSMC !
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Button
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 4
                            },
                            color: colors.black.main,
                            backgroundColor: 'white',
                            boxShadow: `0px 1px 4px 0px ${colors.yellow.main}, 0px -1px 4px 0px ${colors.yellow.main}`,
                            ':hover': {
                                backgroundColor: 'white'
                            }
                        }}
                        onClick={() => {}} >
                        Découvrir la mutuelle
                    </Button>
                </Grid>

            </Grid>
        </Card>
    )
}
