import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { StarIcon } from "../Icon/Theme/StarIcon"

import { colors } from "../../static/colors"
import illustrationPageSubscriber from '../../static/img/illustrations/illustrationPageSubscriber.png';


interface Props {
    userInfos: any;
    percentConsumptionPack: number;
}


export const HeaderSubscriber = (props: Props) => {


    return (
        <Card
            sx={{
                px: {
                    md: 4,
                    xs: 2
                },
                pt: {
                    md: 4,
                    xs: 2
                },
                pb: {
                    md: 0,
                    xs: 2
                },
                background: `linear-gradient(${colors.orange.light}, white)`
            }}>
            <Grid
                container
                justifyContent='space-between'
                alignItems='stretch'
                spacing={3}>

                <Grid
                    item
                    md
                    xs={12}>

                    <Grid
                        container
                        direction='column'
                        justifyContent='space-between'
                        alignItems='stretch'
                        spacing={3}>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='h1'>
                                Bonjour {props.userInfos.firstname} !
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='body1'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems='center'
                                spacing={1}>
                                <Grid
                                    item
                                    xs='auto'>
                                    <StarIcon selected/>
                                </Grid>
                                <Grid
                                    item
                                    xs>
                                    <Typography variant='subtitle1' component='span' fontWeight={700}>État de consommation de votre pack</Typography>
                                    <Typography variant='body1' component='span'> : {Math.round(props.percentConsumptionPack)}%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    md='auto'
                    sx={{
                        display: {
                            md: 'block',
                            xs: 'none'
                        }
                    }} >
                    <img src={illustrationPageSubscriber} alt={"Illustration Adhérent"} loading="lazy" height='200px' />,
                </Grid>
            </Grid>
        </Card>
    )
}
