export const colors = {
    black: {
        main: '#262323',
        secondary: '#676565'
    },
    grey: {
        main: '#A8A7A7',
        secondary: '#E9E9E9',
        light: '#F8F8F8'
    },
    green: {
        main: '#1ACC8E',
        secondary: '#A7EA83',
        light: '#D7F9C2'
    },
    turquoise: {
        main: ''
    },
    blue: {
        main: '#56CDBC',
        secondary: '#CFF8F2',
        dark: '#4EC6B6'
    },
    purple: {
        main: '#5D8BF4'
    },
    pink: {
        main: ''
    },
    red: {
        main: '#FC4F4F'
    },
    orange: {
        main: '#EB7A24',
        secondary: '#F9A02B',
        light: '#FDF2E9'
    },
    yellow: {
        main: '#FFD454'
    }
}
