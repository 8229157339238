import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from "@mui/material/Typography"

import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { FolderEmptyIcon } from "../Icon/FolderEmptyIcon"
import { FolderFullIcon } from "../Icon/FolderFullIcon"
import { UploadIcon } from "../Icon/UploadIcon"
import { colors } from '../../static/colors';


interface Props {
    handleFileUpload: (img: string) => void;
}



export const FieldFileUpload = (props: Props) => {

    const [render, setRender] = useState<boolean>(false);

    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const { open, getRootProps, getInputProps } = useDropzone({
        accept: [
            'image/jpg',
            'image/jpeg',
            'image/png'],
        maxFiles: 1,
        maxSize: 10000000,
        onDragEnter: e => setIsDragOver(true),
        onDragLeave: e => setIsDragOver(false),
        onDrop: async files => {
            if (files.length <= 0)
                return;
            const base64Image = await convertToBase64(files[0])
            props.handleFileUpload(base64Image as string)

        },
        onDropRejected: files => {
            setIsSnackbarOpened(true);
        }
    });

    const convertToBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    return (
        <Card
            sx={{
                textAlign: 'center',
                boxShadow: isDragOver ? 6 : 0
            }}
            {...getRootProps({ className: 'dropzone' })} >
            <Grid
                container
                direction='column'
                justifyContent='center'
            >
                <input {...getInputProps()} />

                <Grid
                    container
                    justifyContent='center'
                    sx={{
                        mb: 2
                    }} >
                    {/* {props.document.fileNameOrigin ?
                        <>
                            <FolderFullIcon color={colors.blue.main} />
                            <Typography
                                variant='body2'
                                component='span'
                                fontWeight={500}
                                sx={{
                                    textOverflow: 'ellipsis',
                                    mx: 2,
                                    my: 'auto'
                                }} >
                                {props.document.fileNameOrigin}
                            </Typography>
                        </>
                        :
                        <FolderEmptyIcon color={colors.blue.main} />
                    } */}
                </Grid>

                <Grid item>
                    <Button
                        sx={{
                            height: 40,
                            color: colors.black.main
                        }}
                        startIcon={<UploadIcon color={colors.black.main} />}
                    // onClick={open}  // Disabled : Card clickable.
                    >
                        Ajouter une image
                    </Button>
                </Grid>

                <Snackbar
                    sx={{
                        maxWidth: '1200px',
                        width: {
                            sm: '100%'
                        },
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpened}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose} >
                    <Alert
                        sx={{
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                            boxShadow: 3
                        }}
                        severity='error'
                        iconMapping={{
                            error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                        }} >
                        <Typography fontWeight={500} >Format de fichier invalide</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </Card>
    )
}
