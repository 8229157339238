/**
 *  Model Product.
*/

import { ApplicationIllustration } from "../components/Icon/Illustration/ApplicationIllustration";
import { ObjectIllustration } from "../components/Icon/Illustration/ObjectIllustration";
import { ServiceIllustration } from "../components/Icon/Illustration/ServiceIllustration";

import { colors } from "../static/colors";

import { IImage } from "./Image.model";
import { IOffer } from "./Offer.model";
import { IPage } from "./Page.model";
import { IPlateforme } from "./Plateforme.model";
import { IProductConsumption } from "./ProductConsumption.model";
import { IProductOffer } from "./ProductOffer.model";
import { IReview } from "./Review.model";
import { ITheme } from "./Theme.model";


export enum ProductType {
    APPLICATION="APPLICATION",
    OBJECT="OBJECT",
    SERVICE="SERVICE"
}


export const productTypeOptions = [
    {
        value: ProductType.APPLICATION,
        label: "Application"
    },
    {
        value: ProductType.OBJECT,
        label: "Objet"
    },
    {
        value: ProductType.SERVICE,
        label: "Service"
    }
]


export interface IProduct {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    type: ProductType;
    code: string;
    name: string;
    tagline?: string;
    description?: string;
    content?: string;
    url?: string;
    reviewGSMC?: string;
    reviewNote?: number;
    color?: string;
    colorSecondary?: string;
    isActive: boolean;
    nbDownload: number;
    themes: ITheme[];
    images: IImage[];
    plateformes: IPlateforme[];
    userReview?: IReview;
    userOffer?: IProductOffer;
    userConsumption?: IProductConsumption;
}


export interface IPageProduct extends IPage {
    content: IProduct[];
}


export interface IProductTypeInfos {
    type: ProductType;
    label: string;
    illustration?: JSX.Element;
    color: string;
    uri: string;
}


export const productTypesInfos: IProductTypeInfos[] = [
    {
        type: ProductType.APPLICATION,
        label: "Applications",
        illustration: <ApplicationIllustration showDetails />,
        color: colors.green.secondary,
        uri: '/applications'
    },
    {
        type: ProductType.OBJECT,
        label: "Objets",
        illustration: <ObjectIllustration color={colors.orange.main} showDetails />,
        color: colors.blue.main,
        uri: '/objets'
    },
    {
        type: ProductType.SERVICE,
        label: "Services",
        illustration: <ServiceIllustration showDetails />,
        color: colors.orange.main,
        uri: '/services'
    }
]


export const getProductTypeInfos = (type: ProductType): IProductTypeInfos => {

    let productTypeInfos = productTypesInfos.find(_ => _.type === type);

    if (productTypeInfos)
        return productTypeInfos;

    return productTypesInfos[0];
}
