/**
 *  Model ProductConsumption.
*/

import { IProductOffer } from "./ProductOffer.model";


export interface IProductConsumption {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    status: ProductConsumptionStatus;
    nbConso: number
    dateStart?: Date;
    dateEnd?: Date;
    productOffer: IProductOffer
}


export enum ProductConsumptionStatus {
    CREATED="CREATED",
    PENDING="PENDING",
    IN_PROGRESS="IN_PROGRESS",
    COMPLETED="COMPLETED"
}
