import { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { LoadingButton } from '@mui/lab';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PageLoginSteps } from '../../../pages/Login/PageLogin';

import { recoverAccount } from '../../../api/Authentication.api';

import { colors } from '../../../static/colors';


interface Props {
    goToStep: (step: PageLoginSteps) => void;
}


export const FormUsernameForgotten = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);

    const getInitialValues = () => {
        return {
            email: undefined as string | undefined,
        }
    };

    const validationSchema = yup.object({
        email: yup.string().email("Email non valide").required("Champ obligatoire")
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);

            if (!values.email) {  // Should never occur with the yup validation.
                setLoading(false);
                return;
            }

            recoverAccount(values.email)
            .then(response => {
                if (response.status === 200)
                    props.goToStep(PageLoginSteps.USERNAME_FORGOTTEN_INFO);
                else
                    formik.setFieldError("email", "Aucun utilisateur n'est associé à cet email");

                setLoading(false);
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: {
                            md: 'left',
                            xs: 'center'
                        }
                    }}>
                    <Typography variant='h1'>Numéro d'adhérent oublié ?</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Votre adresse mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        loadingIndicator={
                            <Typography
                                noWrap
                                variant="button"
                                sx={{
                                    textAlign: 'center',
                                    color: 'white'
                                }}>
                                Recherche en cours...
                            </Typography>
                        }
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Retrouvez moi
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}
