import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { CheckIcon } from "../Icon/CheckIcon";

import { colors } from "../../static/colors";


export const CardPackDigitalInstructions = () => {


    return (
        <Card
            sx={{
                height: '100%',
                backgroundColor: colors.grey.light
            }}>

            <Grid
                container
                justifyContent="center"
                spacing={2}
                sx={{
                    height: '100%',
                    px: {
                        md: 8,
                        xs: 2
                    },
                    py: 6
                }}>

                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant="h2">
                        Comment utiliser mon pack ?
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems='center'
                        spacing={2}>
                        <Grid
                            item
                            xs='auto'>
                            <Avatar
                                sx={{
                                    bgcolor: colors.blue.main,
                                    p: 0.25
                                }}>
                                <CheckIcon selected color="white" />
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <Typography variant='subtitle1' component='span'>Je sélectionne une offre de mon pack</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems='center'
                        spacing={2}>
                        <Grid
                            item
                            xs='auto'>
                            <Avatar
                                sx={{
                                    bgcolor: colors.blue.main,
                                    p: 0.25
                                }}>
                                <CheckIcon selected color="white" />
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <Typography variant='subtitle1' component='span'>Je clique sur le bouton "Souscrire"</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems='center'
                        spacing={2}>
                        <Grid
                            item
                            xs='auto'>
                            <Avatar
                                sx={{
                                    bgcolor: colors.blue.main,
                                    p: 0.25
                                }}>
                                <CheckIcon selected color="white" />
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <Typography variant='subtitle1' component='span'>Je suis redirigé sur le site du partenaire pour valider mon inscription</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems='center'
                        spacing={2}>
                        <Grid
                            item
                            xs='auto'>
                            <Avatar
                                sx={{
                                    bgcolor: colors.blue.main,
                                    p: 0.25
                                }}>
                                <CheckIcon selected color="white" />
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <Typography variant='subtitle1' component='span'>Je profite de mon offre</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Card>
    )
}
