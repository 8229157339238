import { IPlateforme } from "../models/Plateforme.model"


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const updatePlateformeDownloads = (id: number): Promise<IPlateforme> => {

    return fetch(`${apiUrl}/plateforme/${id}/download`,
    {
        method: "PUT",
    })
    .then(_ => _.json());
}
