import { PublicClientApplication } from "@azure/msal-browser";

import { IPageTheme, ITheme } from "../models/Theme.model";

import { msalConfig } from "../settings/authConfig";


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}


export const getThemesBo = (page: number, pageSize: number) : Promise<IPageTheme> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/bo/theme?page=${page}&pageSize=${pageSize}`,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                }
            })
            .then(_ => _.json());
    });
}


export const getThemes = (page: number, pageSize: number, orderBy?: string) : Promise<IPageTheme> => {

    let uri: string = `${apiUrl}/theme?page=${page}&pageSize=${pageSize}`;

    if (orderBy)
        uri += `&orderBy=${orderBy}`;

    return fetch(uri,
    {
        method: "GET",
    })
    .then(_ => _.json())
}


export const getTheme = (id: number) : Promise<ITheme> => {

    return fetch(`${apiUrl}/theme/${id}`,
    {
        method: "GET",
    }).then(_ => _.json()) 
}


export const getThemeByCode = (code: string) : Promise<ITheme> => {

    return fetch(`${apiUrl}/theme/code/${code}`,
    {
        method: "GET",
    }).then(_ => _.json()) 
}


export const createTheme = (theme: any) : Promise<ITheme> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/theme`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(theme)
            })
            .then(_ => _.json())
    });
}


export const modifyTheme = (theme: any) : Promise<ITheme> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/theme`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(theme)
            })
            .then(_ => _.json())
    });
}
