import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { CarouselProduct } from '../../components/Carousel/CarouselProduct';

import { CardPackDigitalInstructions } from '../../components/Card/CardPackDigitalInstructions';
import { CardListEmpty } from '../../components/Card/CardListEmpty';
import { CardProductConsumption } from '../../components/Card/CardProductConsumption';
import { CardSponsorship } from '../../components/Card/CardSponsorship';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { HeaderSubscriber } from '../../components/Header/HeaderSubscriber';
import Skeleton from '@mui/material/Skeleton';
import { TitleSection } from '../../components/Title/TitleSection';

import { getProducts } from '../../api/Product.api';
import { getUserConsumption } from '../../api/ProductConsumption.api';

import { IProduct, ProductType } from '../../models/Product.model';
import { IProductConsumption, ProductConsumptionStatus } from '../../models/ProductConsumption.model';

import AuthService from '../../service/AuthService';

import { Page } from '../../settings/Page';

import { theme as Theme } from '../../static/theme';


const authentication = new AuthService();


export const PageSubscriber = () => {


    const page: Page = Page.SUBSCRIBER;
    const navigate = useNavigate();

    const userInfos = authentication.getProfile();
    const [userProducts, setUserProducts] = useState<IProduct[]>([]);
    const [userConsumptions, setUserConsumptions] = useState<IProductConsumption[]>();

    useEffect(() => {
        window.scrollTo({
            top: 0
        });

        getUserConsumption().then(response => {
            setUserConsumptions(response)
        })

        getProducts(0, 100, undefined, true, undefined, undefined)
        .then(response => {
            setUserProducts(response.content)
        });
    }, [])


    const calculatePercentConsoPack = () => {
        if (userProducts.length > 0)
            return ((userConsumptions?.length || 0) / userProducts.length) * 100;

        return 0;
    }


    return (
        <ThemeProvider theme={Theme}>

            <Header
                page={page} />

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    mx: 'auto',
                    mt: 2
                }} >

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={3}
                    sx={{
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        xs={12} >
                        <HeaderSubscriber userInfos={userInfos} percentConsumptionPack={calculatePercentConsoPack()} />
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <Typography variant='h2'>Votre pack digital</Typography>
                    </Grid>

                    {!userConsumptions ?
                    <Grid
                        item
                        xs={12} >
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width='100%'
                            height='150px' />
                    </Grid>
                    :

                    <>
                    <Grid
                        item
                        md={userConsumptions.filter(_ => _.status === ProductConsumptionStatus.COMPLETED).length === 0 && userConsumptions.filter(_ => _.status === ProductConsumptionStatus.IN_PROGRESS || _.status === ProductConsumptionStatus.PENDING).length === 0 ? 12 : 6}
                        xs={12} >

                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}>

                            <Grid
                                item
                                xs={12} >
                                <Typography variant='subtitle1'>Vos abonnements en cours</Typography>
                            </Grid>

                            {userConsumptions.filter(_ => _.status === ProductConsumptionStatus.IN_PROGRESS || _.status === ProductConsumptionStatus.PENDING).length === 0 ?
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    minHeight: '150px'
                                }}>
                                <CardListEmpty
                                    title="Aucun abonnement en cours" />
                            </Grid>

                            : userConsumptions.filter(_ => _.status === ProductConsumptionStatus.IN_PROGRESS || _.status === ProductConsumptionStatus.PENDING)
                                              .sort((a, b) => {
                                                const aValue = a.status === ProductConsumptionStatus.IN_PROGRESS ? 100 : 0;
                                                const bValue = b.status === ProductConsumptionStatus.IN_PROGRESS ? 100 : 0;

                                                if (aValue > bValue)
                                                    return -1;
                                        
                                                if (aValue < bValue)
                                                    return 1;
                                            
                                                return 0;
                                              })
                                              .map((_: IProductConsumption, i) => {
                                return (
                                    <Grid
                                        key={`consumption_${i}`}
                                        item
                                        xs={12} >
                                        <CardProductConsumption productConsumption={_} />
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Grid>

                    {userConsumptions.filter(_ => _.status === ProductConsumptionStatus.COMPLETED).length > 0 &&
                    <Grid
                        item
                        md={6}
                        xs={12} >

                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={3}>

                            <Grid
                                item
                                xs={12} >
                                <Typography variant='subtitle1'>Vos abonnements terminés</Typography>
                            </Grid>

                            {userConsumptions.filter(_ => _.status === ProductConsumptionStatus.COMPLETED).map((_: IProductConsumption, i) => {
                                return (
                                    <Grid
                                        key={`consumption_${i}`}
                                        item
                                        xs={12} >
                                        <CardProductConsumption productConsumption={_} />
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Grid>}
                    </>}

                    {userProducts.filter(_ => _.type === ProductType.APPLICATION).length > 0 &&
                    <>
                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Abonnez-vous aux autres applications santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/applications')} />
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            products={userProducts}
                            type={ProductType.APPLICATION} />
                    </Grid>
                    </>}

                    {userProducts.filter(_ => _.type === ProductType.OBJECT).length > 0 &&
                    <>
                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Abonnez-vous aux autres objets santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/objets')} />
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            products={userProducts}
                            type={ProductType.OBJECT} />
                    </Grid>
                    </>}

                    {userProducts.filter(_ => _.type === ProductType.SERVICE).length > 0 &&
                    <>
                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Abonnez-vous aux autres services santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/services')} />
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            products={userProducts}
                            type={ProductType.SERVICE} />
                    </Grid>
                    </>}

                    <Grid item xs={12} />

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <CardPackDigitalInstructions />
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <CardSponsorship />
                    </Grid>
                </Grid>

            </Box>

            <Footer />

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider >
    );
}
