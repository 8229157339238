import { colors } from "../../static/colors";


export interface IIcon {
    color?: string;
    secondaryColor?: string;
    bgColor?: string;
    dark?: Boolean;
    selected?: Boolean;
    width?: string;
    height?: string;
    showDetails?: Boolean;
}


export interface IIconSize {
    width: string;
    height: string;
}


export const defaultIconColor = colors.black.main

export const defaultIconSize: IIconSize = {
    width: "25",
    height: "25"
}


export const defaultIconHeaderSize: IIconSize = {
    width: "30",
    height: "30"
}
