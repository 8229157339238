import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { StarIcon } from "../Icon/Theme/StarIcon";
import { DialogReview } from "../Dialog/DialogReview";

import { getProductReviews, getReview } from "../../api/Review.api";

import { IImage, ImageType } from "../../models/Image.model";
import { IProductConsumption, ProductConsumptionStatus } from "../../models/ProductConsumption.model";
import { OfferType } from "../../models/ProductOffer.model";

import { colors } from "../../static/colors"
import { IReview } from "../../models/Review.model";


interface Props {
    productConsumption: IProductConsumption;
}


const getConsumptionPercentage = (productConsumption: IProductConsumption) => {

    if (productConsumption.status === ProductConsumptionStatus.COMPLETED)
        return 100;

    if (productConsumption.status === ProductConsumptionStatus.PENDING)
        return 0;

    if (productConsumption.status === ProductConsumptionStatus.IN_PROGRESS) {
        if (productConsumption.productOffer.type === OfferType.DISCOUNT)
            return 100;

        if (productConsumption.productOffer.type === OfferType.CONSO) {
            if (productConsumption.nbConso < productConsumption.productOffer.value)
                return (productConsumption.nbConso / productConsumption.productOffer.value) * 100;

            return 100;
        }

        if (productConsumption.productOffer.type === OfferType.PERIOD) {
            if (productConsumption.dateStart && new Date(parseInt(productConsumption.dateStart.toString())) > new Date())
                return 0;

            if (productConsumption.dateEnd && new Date(parseInt(productConsumption.dateEnd.toString())) < new Date())
                return 100;

            if (productConsumption.dateStart === undefined ||
                productConsumption.dateStart === null ||
                productConsumption.dateEnd === undefined ||
                productConsumption.dateEnd === null)
                return 0;

            const nbSinceStart = new Date().getTime() - new Date(parseInt(productConsumption.dateStart.toString())).getTime();
            const nbTotal = new Date(parseInt(productConsumption.dateEnd.toString())).getTime() - new Date(parseInt(productConsumption.dateStart.toString())).getTime();

            return (nbSinceStart / nbTotal) * 100
        }
    }
    return 0;
}


export const CardProductConsumption = (props: Props) => {

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [review, setReview] = useState<IReview>();


    const image: IImage | undefined = props.productConsumption.productOffer.product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0];

    const consumptionPercentage: number = getConsumptionPercentage(props.productConsumption);


    useEffect(() => {
        if (!props.productConsumption.productOffer.product.code)
            return;

        getProductReviews(props.productConsumption.productOffer.product.code)
        .then((response) => {
            setReview(response[0]);
        })

    }, [props.productConsumption.productOffer.product])


    return (
        <Grid
            container
            alignItems='flex-start'
            justifyContent='space-between'
            spacing={{
                md: 4,
                xs: 2
            }}>

            <DialogReview
                product={props.productConsumption.productOffer.product}
                review={review}
                open={dialogOpen}
                send={(_) => setReview(_)}
                handleClose={() => setDialogOpen(false)} />

            <Grid
                item
                xs='auto'>

                {image ?
                <CardMedia
                    component="img"
                    src={image.image}
                    sx={{
                        borderRadius: '8px',
                        height: {
                            md: '90px',
                            xs: '60px'
                        },
                        width: {
                            md: '90px',
                            xs: '60px'
                        }
                    }} />
                :
                <Box
                    sx={{
                        backgroundColor: colors.grey.secondary,
                        borderRadius: '8px',
                        height: {
                            md: '90px',
                            xs: '60px'
                        },
                        width: {
                            md: '90px',
                            xs: '60px'
                        }
                    }} />
                }
            </Grid>

            <Grid
                item
                xs>
                <Grid
                    container>
                    <Grid
                        item
                        xs>
                        <Grid
                            container>
                            <Grid
                                item
                                xs={12}>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    fontWeight={700}
                                    color={props.productConsumption.status === ProductConsumptionStatus.COMPLETED ? colors.grey.main : colors.black.main}>
                                    {props.productConsumption.productOffer.product.name},
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Typography
                                    variant='body1'
                                    color={props.productConsumption.status === ProductConsumptionStatus.COMPLETED ? colors.grey.main : colors.black.main}>
                                    {props.productConsumption.productOffer.description}
                                </Typography>
                            </Grid>
        
                        </Grid>

                    </Grid>

                    <Grid
                        item
                        xs='auto'>
                        <IconButton
                            sx={{
                                p: 0
                            }}
                            onClick={() => setDialogOpen(true)}>
                            <StarIcon
                                color={review ? colors.yellow.main : colors.black.main}
                                selected />
                        </IconButton>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            pt: {
                                md: 1,
                                xs: 0
                            }
                        }}>

                        {props.productConsumption.status === ProductConsumptionStatus.PENDING ? 
                        <Chip
                            label={
                                <Typography
                                    variant='button' >
                                    en cours en validation
                                </Typography>}
                            sx={{
                                mt: 1,
                                color: 'white',
                                backgroundColor: colors.blue.main
                            }} />
                        :
                        <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                            spacing={1}>
                            <Grid
                                item
                                xs>
                                <LinearProgress
                                    value={consumptionPercentage === 0 ? 3 : consumptionPercentage}
                                    variant="determinate"
                                    sx={{
                                        width: '100%',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: props.productConsumption.status === ProductConsumptionStatus.COMPLETED ? colors.grey.main : colors.blue.main,
                                        }
                                    }}/>
                            </Grid>

                            <Grid
                                item
                                xs='auto'>
                                <Typography
                                    variant='body1'
                                    color={props.productConsumption.status === ProductConsumptionStatus.COMPLETED ? colors.grey.main : colors.black.main}>
                                    {consumptionPercentage < 10 ? <span>&nbsp;&nbsp;</span> : consumptionPercentage < 100 ? <span>&nbsp;</span> : ""}
                                    {Math.round(consumptionPercentage)}%
                                </Typography>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}
