import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from "@mui/material/Grid";

import { Carousel } from "./Carousel";
import { CardListEmpty } from "../Card/CardListEmpty";
import { CardProduct } from "../Card/CardProduct";
import { CardProductType } from "../Card/CardProductType";
import { CardProductSkeleton } from "../Card/CardProductSkeleton";

import { getProducts } from "../../api/Product.api";

import { IProduct, ProductType } from "../../models/Product.model";


interface Props {
    products?: IProduct[];
    nbItems?: number;
    type?: ProductType;
    filterByThemes?: string[];
    excludeProducts?: string[];
    showTypeLink?: Boolean;
    showRank?: Boolean;
}


const defaultNbItems: number = 20;
const defaultNbItemsMD: number = 5;
const defaultNbItemsSM: number = 3;
const defaultNbItemsXS: number = 2.2;


export const CarouselProduct = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const [products, setProducts] = useState<IProduct[] | undefined>(undefined);


    useEffect(() => {
        if (props.products) {
            if (props.type)
                setProducts(props.products.filter(_ => _.type === props.type));
            else
                setProducts(props.products);
            return;
        }

        getProducts(0, (props.showRank ? 9 : props.showTypeLink ? props.nbItems || defaultNbItems - 1 : props.nbItems || defaultNbItems), props.showRank ? true : false, false, props.type?.toString(), props.filterByThemes)
        .then((response) => {
            let excludeProducts: string[] = props.excludeProducts || [];

            setProducts(response.content.filter(_ => !excludeProducts.includes(_.code)));
        })
        .catch(exception => setProducts([]));
    }, [props.products,
        props.type,
        props.filterByThemes,
        props.excludeProducts])


    return (
        <>
        {products === undefined ?
        <Grid
            container
            spacing={{
                md: 4,
                xs: 2
            }}>
            {props.showTypeLink && props.type &&
            <Grid
                item
                md={12 / (props.showRank ? 4 : defaultNbItemsMD)}
                sm={12 / (props.showRank ? 2 : defaultNbItemsSM)}
                xs={12 / (props.showRank ? 1.5 : defaultNbItemsXS)} >
                <CardProductType type={props.type}/>
            </Grid>}
            {Array.from({length: (screenSizeUpMD ? props.showRank ? 4 : defaultNbItemsMD :
                                    screenSizeUpSM ? props.showRank ? 2 : defaultNbItemsSM :
                                    props.showRank ? 1.5 : defaultNbItemsXS) - (props.showTypeLink && props.type ? 1 : 0)}, (_, i) =>
                <Grid
                key={i}
                    item
                    xs >
                    <CardProductSkeleton />
                </Grid>)
            }
        </Grid>

        : products.length === 0 ?
        <Grid
            container
            spacing={{
                md: 4,
                xs: 2
            }}>
            {props.showTypeLink && props.type &&
            <Grid
                item
                md={12 / (props.showRank ? 4 : defaultNbItemsMD)}
                sm={12 / (props.showRank ? 2 : defaultNbItemsSM)}
                xs={12 / (props.showRank ? 1.5 : defaultNbItemsXS)} >
                <CardProductType type={props.type}/>
            </Grid>}

            <Grid
                item
                xs
                sx={{
                    minHeight: '200px'
                }}>
                <CardListEmpty
                    title="Aucun résultat"
                    description=""/>
            </Grid>
        </Grid>

        :
        <Carousel
            elements={
                props.showTypeLink && props.type ?
                [<CardProductType type={props.type} />].concat(products.map((_, id) => {
                    return (
                        <CardProduct
                            key={`card_product_${id}`}
                            product={_}
                            rank={props.showRank ? id + 1 : undefined} />
                    );
                }))
                :
                products.map((_, id) => {
                    return (
                        <CardProduct
                            key={`card_product_${id}`}
                            product={_}
                            rank={props.showRank ? id + 1 : undefined} />
                    );
                })
            }
            nbItemsMD={props.showRank ? 4 : defaultNbItemsMD}
            nbItemsSM={props.showRank ? 2 : defaultNbItemsSM}
            nbItemsXS={props.showRank ? 1.5 : defaultNbItemsXS} />
        }
        </>
    );
}
