import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CardPlateforme } from '../Card/CardPlateforme';
import { CarouselImage } from '../Carousel/CarouselImage';
import { CarouselProduct } from '../Carousel/CarouselProduct';
import { CarouselReview } from '../Carousel/CarouselReview';
import { HeaderProduct } from '../Header/HeaderProduct';
import { ChevronRightIcon } from '../Icon/ChevronRightIcon';
import { PlusIcon } from '../Icon/PlusIcon';
import { TitleSection } from '../Title/TitleSection';

import { updateProductDownload } from '../../api/Product.api';
import { getProductReviews } from '../../api/Review.api';

import { IImage, ImageType } from '../../models/Image.model';
import { TypePlateforme } from '../../models/Plateforme.model';
import { IProduct, ProductType } from '../../models/Product.model';
import { ProductConsumptionStatus } from '../../models/ProductConsumption.model';

import { IReview } from '../../models/Review.model';

import { colors } from '../../static/colors';
import productHeader from '../../static/img/products/productHeader.jpg'
import productStatic from '../../static/img/products/productStatic.png'

import AuthService from '../../service/AuthService';

interface Props {
    product: IProduct;
}


export const PageProduct = (props: Props) => {

    const authentication = new AuthService();
    const navigate = useNavigate();

    const [imageHeader, setImageHeader] = useState<IImage | undefined>();
    const [imageLogo, setImageLogo] = useState<IImage | undefined>();
    const [imageStatic, setImageStatic] = useState<IImage | undefined>();
    const [imagesPreview, setImagesPreview] = useState<IImage[]>([]);
    const [reviews, setReviews] = useState<IReview[]>([]);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [])

    useEffect(() => {
        setImageHeader(props.product.images.filter(_ => _.type === ImageType.HEADER).sort((a, b) => a.order - b.order)[0]);
        setImageLogo(props.product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0]);
        setImageStatic(props.product.images.filter(_ => _.type === ImageType.STATIC).sort((a, b) => a.order - b.order)[0]);
        setImagesPreview(props.product.images.filter(_ => _.type === ImageType.PREVIEW).sort((a, b) => a.order - b.order).slice(0, 10));

        getProductReviews(props.product.code).then(response => {
            setReviews(response);
        })
    }, [props.product])


    return (
        <Box
            sx={{
                position: 'relative',
            }} >
            <CardMedia
                component="img"
                src={imageHeader ? imageHeader.image : productHeader}
                sx={{
                    height: '260px',
                    position: 'absolute',
                    zIndex: -1000,
                    pt: 4
                }} />

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    mx: 'auto',
                    mt: 2,
                    mb: 5
                }} >

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={6}
                    sx={{
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        xs={12}>
                        <HeaderProduct product={props.product} />
                    </Grid>

                    {props.product.content &&
                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant='h2'>
                            Description
                        </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                pt: 3,
                                textAlign: {
                                    sm: 'justify',
                                    xs: 'left'
                                },
                                whiteSpace: 'pre-wrap'
                            }}>
                            {props.product.content}
                        </Typography>
                    </Grid>}

                    {imagesPreview.length > 0 &&
                    <Grid
                        item
                        xs={12}>
                        <CarouselImage images={imagesPreview} />
                    </Grid>}

                    {props.product.url &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }}>

                        <Button
                            sx={{
                                color: colors.black.main
                            }}
                            onClick={() => {
                                if (props.product.url)
                                    window.open(props.product.url);
                            }}>
                            <Box>
                                <Typography
                                    variant='button'
                                    component='div'
                                    sx={{
                                        mb: 1
                                    }} >
                                    Voir + d'infos sur {props.product.name}
                                </Typography>
                                <PlusIcon />
                            </Box>
                        </Button>
                    </Grid>}

                    <Grid
                        item
                        md
                        xs={12}>

                        <Typography
                            variant='h2'
                            sx={{
                                mb: 4
                            }}>
                            Comment obtenir l’application ?
                        </Typography>

                        <Typography
                            variant='h3'
                            fontWeight={700}
                            color={colors.blue.main}
                            sx={{
                                mb: 4
                            }}>
                            Avec des avantages, via le pack digital
                        </Typography>

                        {props.product.userOffer &&
                        <>
                        <Typography
                            variant='body2'>
                            En adhérant à la mutuelle GSMC, vous bénéficiez de :
                        </Typography>
                        <ul>
                            <li style={{ color: colors.grey.main }}><Typography variant='body2'>{props.product.userOffer.description}</Typography></li>
                        </ul>
                        </>}

                        <Box
                            sx={{
                                mt: 4,
                                textAlign: {
                                    md: 'left',
                                    xs: 'center'
                                }
                            }}>

                            {(!props.product.userConsumption ||
                              props.product.userConsumption?.status === ProductConsumptionStatus.CREATED) &&
                            <Box>
                                <Button
                                    onClick={() => {
                                        switch (props.product.type) {
                                            case ProductType.APPLICATION:
                                                navigate(`/applications/${props.product.code}/obtenir`);
                                                return;

                                            case ProductType.OBJECT:
                                                navigate(`/objets/${props.product.code}/obtenir`);
                                                return;

                                            case ProductType.SERVICE:
                                                navigate(`/services/${props.product.code}/obtenir`);
                                                return;
                                        }
                                    }}
                                    sx={{
                                        mb: 2,
                                        px: {
                                            sm: 6,
                                            xs: 3
                                        },
                                        color: 'white',
                                        backgroundColor: colors.black.main,
                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                        ':hover': {
                                            backgroundColor: 'black'
                                        }
                                    }} >
                                    Obtenir via le pack digital
                                </Button>
                            </Box>}

                            <Box>
                                <Button
                                    sx={{
                                        px: {
                                            sm: 3,
                                            xs: 2
                                        },
                                        color: colors.black.main
                                    }}
                                    endIcon={<ChevronRightIcon />}
                                    onClick={() => {
                                        if (props.product.id)
                                            updateProductDownload(props.product.id);
                                        navigate('/pack-digital');
                                    }} >
                                    Découvrir le pack digital
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        md
                        xs={12}
                        sx={{
                            mt: {
                                md: 3,
                                xs: 0
                            }
                        }}>

                        <CardMedia
                            component="img"
                            src={imageStatic ? imageStatic.image : productStatic}
                            sx={{
                                height: '100%',
                                maxHeight: {
                                    md: 'none',
                                    xs: '300px'
                                },
                                borderRadius: 2
                            }} />
                    </Grid>

                    {props.product.plateformes.filter(_ => _.isActive).length > 0 &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }}>
                        <Typography
                            variant='h3'
                            fontWeight={700} >
                            Via d’autres plateformes
                        </Typography>

                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                            sx={{
                                pt: 3,
                                px: {
                                    md: 5,
                                    xs: 2
                                }
                            }} >
                            {props.product.plateformes.filter(_ => _.isActive).map((_) => {
                                return (
                                    <Grid
                                        item
                                        md={4}
                                        xs={12} >
                                        <CardPlateforme
                                            plateforme={_}
                                            label={_.type === TypePlateforme.WEB ? `le site ${props.product.name}` : undefined}
                                            icon={_.type === TypePlateforme.WEB ? imageLogo?.image : undefined} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>}

                    {(reviews.filter(_ => _.isActive).length > 0 || props.product.reviewGSMC) &&
                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant='h2'
                            sx={{
                                pb: 3,
                                textAlign: 'center'
                            }} >
                            Ils ont testé l’application
                        </Typography>

                        <CarouselReview
                            reviews={reviews.filter(_ => _.isActive).slice(0, 10)}
                            reviewGSMC={props.product.reviewGSMC} />
                    </Grid>}

                    {props.product.themes.length > 0 &&
                    <Grid
                        item
                        xs={12} >
                        <Box
                            sx={{
                                pb: 3,
                            }}>
                            <TitleSection
                                content={
                                    <Box component='span'>
                                        Les applications similaires
                                    </Box>}
                                // COMMENT : Link disabled
                                // label="Voir toute la thématique"
                                // onClick={() => {
                                //     navigate(`/theme/${props.product.themes[0].code.toLowerCase()}`);
                                // }}
                                />
                        </Box>

                        <CarouselProduct
                            filterByThemes={[props.product.themes[0].code]}
                            excludeProducts={[props.product.code]}/>
                    </Grid>}

                </Grid>

            </Box>
        </Box>
    );
}
