import { CardReview } from "../Card/CardReview";
import { Carousel } from "./Carousel";

import { IReview } from "../../models/Review.model";
import { CardReviewGSMC } from "../Card/CardReviewGSMC";


interface Props {
    reviews: IReview[];
    reviewGSMC?: string;
}


const defaultNbItemsMD: number = 3;
const defaultNbItemsSM: number = 2;
const defaultNbItemsXS: number = 1.2;


export const CarouselReview = (props: Props) => {


    return (
        <Carousel
            elements={
                props.reviewGSMC ?
                [<CardReviewGSMC description={props.reviewGSMC} />].concat(props.reviews.map((_) => {
                    return <CardReview review={_} />;
                }))
                :
                props.reviews.map((_) => {
                    return <CardReview review={_} />;
                })
            }
            nbItemsMD={defaultNbItemsMD}
            nbItemsSM={defaultNbItemsSM}
            nbItemsXS={defaultNbItemsXS} />
    );
}
