import { ThemeProvider } from '@mui/material/styles';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { PageProducts } from '../../components/Page/PageProducts';

import { Page } from '../../settings/Page';

import { theme } from '../../static/theme';


export const PageServices = () => {


    const page: Page = Page.SERVICES;


    return (
        <ThemeProvider theme={theme}>

            <Header
                page={page} />

            <PageProducts
                page={page} />

            <Footer />

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider>
    );
}
