import { useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { CardSubscription } from '../../components/Card/CardSubscription';
import { FormAccountActivation } from '../../components/Form/Auth/FormAccountActivation';
import { FormAccountActivationInfo } from '../../components/Form/Auth/FormAccountActivationInfo';
import { FormPassword } from '../../components/Form/Auth/FormPassword';
import { FormPasswordForgotten } from '../../components/Form/Auth/FormPasswordForgotten';
import { FormUsername } from '../../components/Form/Auth/FormUsername';
import { FormUsernameForgotten } from '../../components/Form/Auth/FormUsernameForgotten';
import { IUser } from '../../models/User.model';

import { theme } from '../../static/theme';
import { FormUsernameForgottenInfo } from '../../components/Form/Auth/FormUsernameForgottenInfo';
import { FormPasswordForgottenInfo } from '../../components/Form/Auth/FormPasswordForgottenInfo';

import { CheckIcon } from '../../components/Icon/CheckIcon';
import { colors } from '../../static/colors';


export enum PageLoginSteps {
    USERNAME,
    USERNAME_FORGOTTEN,
    USERNAME_FORGOTTEN_INFO,
    PASSWORD,
    PASSWORD_FORGOTTEN,
    PASSWORD_FORGOTTEN_INFO,
    ACCOUNT_ACTIVATION,
    ACCOUNT_ACTIVATION_INFO,
    SUCCESS
}


export const PageLogin = () => {

    const navigate = useNavigate();
    
    const [searchParams, setSearchParams] = useSearchParams();

    const [user, setUser] = useState<IUser>();

    const [step, setStep] = useState<PageLoginSteps>(PageLoginSteps.USERNAME);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackBarText, setSnackBarText] = useState<String>();

    const handleGoBack = () => {

        switch (step) {
            case (PageLoginSteps.USERNAME):
                navigate(-1);
                break;

            case (PageLoginSteps.ACCOUNT_ACTIVATION_INFO):
                setStep(PageLoginSteps.ACCOUNT_ACTIVATION);
                break;

            case (PageLoginSteps.USERNAME_FORGOTTEN_INFO):
                setStep(PageLoginSteps.USERNAME_FORGOTTEN);
                break;

            case (PageLoginSteps.PASSWORD_FORGOTTEN_INFO):
                setStep(PageLoginSteps.PASSWORD_FORGOTTEN);
                break;

            default:
                setStep(PageLoginSteps.USERNAME);
                break;
        }
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };


    return (
        <ThemeProvider theme={theme}>

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    height: '100vh',
                    mx: 'auto'
                }} >

                <Snackbar
                    sx={{
                        width: '100%',
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}>
                    <Alert
                        sx={{
                            textAlign: 'left',
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                            boxShadow: 3
                        }}
                        severity='success'
                        iconMapping={{
                            success: <CheckIcon color={colors.green.main} />,
                        }} >
                        <Typography fontWeight={500} >
                            {snackBarText}
                        </Typography>
                    </Alert>
                </Snackbar>

                <Grid
                    container
                    justifyContent="space-around"
                    spacing={3}
                    sx={{
                        height: '100%',
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }}>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: {
                                md: 6,
                                xs: 1
                            }
                        }}>
                        <Button
                            variant='text'
                            color='inherit'
                            sx={{
                                p: 1.5
                            }}
                            onClick={() => 
                                handleGoBack()
                            }>
                            <Typography variant='h4'>
                                {"< Retour"}
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>

                        {step === PageLoginSteps.USERNAME &&
                        <FormUsername
                            user={user}
                            send={(_) => setUser(_)}
                            goToStep={(_) => setStep(_)} />}

                        {step === PageLoginSteps.USERNAME_FORGOTTEN &&
                        <FormUsernameForgotten
                            goToStep={(_) => {
                                setSnackBarText("Votre numéro d'adhérent vous a été envoyé par email");
                                setIsSnackbarOpened(true);
                                setStep(_);
                            }} />}

                        {step === PageLoginSteps.USERNAME_FORGOTTEN_INFO &&
                        <FormUsernameForgottenInfo
                            goToStep={(_) => setStep(_)} />}

                        {step === PageLoginSteps.PASSWORD && user &&
                        <FormPassword
                            user={user}
                            goToStep={(_) => {
                                if (_ === PageLoginSteps.SUCCESS) {
                                    if (searchParams.get('redirect') && searchParams.get('redirect') !== null) {
                                        navigate(searchParams.get('redirect') as string);
                                        return;
                                    }

                                    navigate("/adherent");
                                }

                                setStep(_);
                            }} />}

                        {step === PageLoginSteps.PASSWORD_FORGOTTEN && user &&
                        <FormPasswordForgotten
                            user={user}
                            goToStep={(_) => {
                                setSnackBarText("Un email vous a été envoyé pour réinitialiser votre mot de passe");
                                setIsSnackbarOpened(true);
                                setStep(_);
                            }} />}

                        {step === PageLoginSteps.PASSWORD_FORGOTTEN_INFO && user &&
                        <FormPasswordForgottenInfo user={user} />}

                        {step === PageLoginSteps.ACCOUNT_ACTIVATION && user &&
                        <FormAccountActivation
                            user={user}
                            send={(_) => setUser(_)}
                            goToStep={(_) => {
                                setSnackBarText("Un email vous a été envoyé pour activer votre compte");
                                setIsSnackbarOpened(true);
                                setStep(_);
                            }} />}

                        {step === PageLoginSteps.ACCOUNT_ACTIVATION_INFO && user &&
                        <FormAccountActivationInfo user={user} />}
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <CardSubscription />
                    </Grid>

                    {/* Add padding bottom */}
                    <Grid
                        item
                        xs={12}>
                    </Grid>
                </Grid>

            </Box>
        </ThemeProvider>
    );
}
