import { ProductType } from "../models/Product.model";
import { colors } from "../static/colors";
import illustrationPageApplication from '../static/img/illustrations/illustrationPageApplication.png';
import illustrationPageHome from '../static/img/illustrations/illustrationPageHome.png';
import illustrationPageObject from '../static/img/illustrations/illustrationPageObject.png';
import illustrationPagePackDigital from '../static/img/illustrations/illustrationPagePackDigital.png';
import illustrationPageService from '../static/img/illustrations/illustrationPageService.png';
import illustrationPageThematic from '../static/img/illustrations/illustrationPageThematic.png';


export enum Page {
    APPLICATION,
    APPLICATIONS,
    ARTICLE,
    ARTICLES,
    HOME,
    OBJECT,
    OBJECTS,
    PACK_DIGITAL,
    SERVICE,
    SERVICES,
    SUBSCRIBER,
    THEME
}


export enum PageBo {
    INDEX,
    PRODUCT,
    PRODUCTS,
    OFFERS,
    SECTION,
    SECTIONS,
    THEMES,
}


export interface IPageInfos {
    page: Page;
    productType?: ProductType;
    illustration?: JSX.Element;
    color: string;
}


export const pagesInfos: IPageInfos[] = [
    {
        page: Page.APPLICATION,
        productType: ProductType.APPLICATION,
        color: colors.green.secondary,
    },
    {
        page: Page.APPLICATIONS,
        productType: ProductType.APPLICATION,
        illustration: <img src={illustrationPageApplication} alt={"Illustration Application"} loading="lazy" />,
        color: colors.green.secondary,
    },
    {
        page: Page.ARTICLE,
        color: colors.black.main,
    },
    {
        page: Page.ARTICLES,
        color: colors.black.main,
    },
    {
        page: Page.HOME,
        illustration: <img src={illustrationPageHome} alt={"Illustration Marketplace"} loading="lazy" />,
        color: colors.blue.main,
    },
    {
        page: Page.OBJECT,
        productType: ProductType.OBJECT,
        color: colors.blue.main,
    },
    {
        page: Page.OBJECTS,
        productType: ProductType.OBJECT,
        illustration: <img src={illustrationPageObject} alt={"Illustration Objet"} loading="lazy" />,
        color: colors.blue.main,
    },
    {
        page: Page.PACK_DIGITAL,
        illustration: <img src={illustrationPagePackDigital} alt={"Illustration Pack Digital"} loading="lazy" />,
        color: colors.black.main,
    },
    {
        page: Page.SERVICE,
        productType: ProductType.SERVICE,
        color: colors.orange.main,
    },
    {
        page: Page.SERVICES,
        productType: ProductType.SERVICE,
        illustration: <img src={illustrationPageService} alt={"Illustration Service"} loading="lazy" />,
        color: colors.orange.main,
    },
    {
        page: Page.THEME,
        illustration: <img src={illustrationPageThematic} alt={"Illustration Thématique"} loading="lazy" />,
        color: colors.black.main,
    }
]


export const getPageInfos = (page: Page): IPageInfos => {

    let pageInfos = pagesInfos.find(_ => _.page === page);

    if (pageInfos)
        return pageInfos;

    return pagesInfos[0];
}
