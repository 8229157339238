import { PublicClientApplication } from "@azure/msal-browser";

import { IOffer, IPageOffer } from "../models/Offer.model"

import { msalConfig } from "../settings/authConfig";

const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}


export const getOffersBo = (page: number, pageSize: number) : Promise<IPageOffer> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/bo/offer?page=${page}&pageSize=${pageSize}`,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                }
            })
            .then(_ => _.json());
    });
}


export const getOffers = (page: number, pageSize: number) : Promise<IPageOffer> => {

    return fetch(`${apiUrl}/offer?page=${page}&pageSize=${pageSize}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}


export const getOffer = (id: number) : Promise<IOffer> => {

    return fetch(`${apiUrl}/offer/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}


export const createOffer = (offer: IOffer) : Promise<IOffer> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/offer`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(offer)
            })
            .then(_ => _.json())
    });
}


export const modifyOffer = (offer: IOffer) : Promise<IOffer> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/offer`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(offer)
            })
            .then(_ => _.json())
    });
}
