import { defaultIconColor, IIcon } from "../settings"


export const ApplicationIllustration = (props: IIcon) => {

    //@ts-ignore
    return (
        <svg
            width={props.width ? props.width : "190"}
            height={props.height ? props.height : "190"}
            viewBox="0 0 190 190"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_340)">
        <path d="M179.935 46.2222C177.783 41.5667 173.479 38.4463 168.466 37.5657L135.682 32.3869C133.896 32.1055 132.113 31.7474 130.294 31.7748C128.099 31.8036 125.945 32.3299 123.993 33.2659C121.37 34.5178 119.109 36.5171 117.587 39.0551C115.773 42.0874 115.423 45.4567 114.891 48.8561C113.052 60.4761 111.219 72.1009 109.38 83.7209C107.241 97.2597 105.102 110.798 102.962 124.337C102.363 128.136 101.764 131.935 101.159 135.73C101.036 136.528 100.885 137.324 100.812 138.133C100.356 143.243 102.564 148.318 106.599 151.478C110.802 154.772 116.725 154.979 121.811 155.781C128.743 156.874 135.682 157.973 142.615 159.066C148.272 159.955 153.867 161.347 159.187 158.203C163.151 155.856 165.729 151.941 166.513 147.414C166.631 146.719 166.733 146.022 166.845 145.323C168.008 137.962 169.171 130.602 170.338 123.236C170.838 120.048 171.348 116.858 171.848 113.669C174.048 99.7258 176.25 85.7932 178.455 71.8439C179.171 67.2907 179.893 62.7423 180.609 58.1891C181.26 54.1063 181.777 50.2012 179.935 46.2222ZM134.697 39.4174L164.514 44.128C170.887 45.1329 175.241 51.117 174.231 57.4956L164.735 117.613C164.482 117.279 164.235 116.951 163.992 116.616C164.097 115.645 164.198 114.68 164.282 113.711C163.97 114.377 163.658 115.042 163.34 115.703C163.199 115.5 163.057 115.296 162.916 115.093C162.425 114.37 161.945 113.635 161.485 112.898C154.901 102.354 151.205 89.9626 147.374 78.0056C143.032 64.4496 137.914 50.4626 128.269 40.2236C130.226 39.3799 132.439 39.06 134.697 39.4174Z" fill="#7ED346"/>
        <path d="M164.734 117.612L161.137 140.375L160.733 142.921C160.397 145.018 159.531 146.892 158.291 148.435C155.753 151.582 151.653 153.316 147.377 152.636L133.3 150.417L117.565 147.931C111.193 146.926 106.843 140.936 107.848 134.563L110.71 116.476L111.007 114.585L121.339 49.1329C121.987 45.0177 124.709 41.7537 128.273 40.2174C137.913 50.4624 143.037 64.4543 147.378 77.9994C151.209 89.9564 154.911 102.352 161.489 112.892C161.954 113.634 162.429 114.364 162.92 115.087C163.061 115.29 163.202 115.493 163.344 115.697C163.656 115.031 163.974 114.371 164.285 113.705C164.202 114.674 164.097 115.645 163.996 116.61C164.234 116.95 164.481 117.279 164.734 117.612Z" fill="#B6F097"/>
        <path d="M174.232 57.4962L164.736 117.613C164.482 117.28 164.235 116.951 163.993 116.617C164.098 115.646 164.198 114.681 164.282 113.712C163.97 114.377 163.658 115.043 163.34 115.703C163.199 115.5 163.058 115.297 162.916 115.094C162.426 114.371 161.945 113.636 161.486 112.898C154.902 102.354 151.206 89.9632 147.375 78.0062C143.033 64.4502 137.914 50.4631 128.27 40.2242C130.226 39.3804 132.435 39.0666 134.703 39.4228L164.521 44.1334C170.893 45.1383 175.241 51.1176 174.232 57.4962Z" fill="#D7F9C2"/>
        <path d="M163.34 115.703C163.651 115.038 163.969 114.377 164.281 113.711C164.198 114.68 164.092 115.651 163.992 116.616C163.768 116.307 163.557 116.008 163.34 115.703Z" fill="#D7F9C2"/>
        {props.showDetails &&
        <g style={{
            mixBlendMode: 'overlay'
        }}>
        <path d="M147.74 140.452C148.099 140.743 148.626 140.688 148.916 140.329C149.207 139.97 149.152 139.443 148.793 139.153C148.434 138.862 147.907 138.917 147.617 139.276C147.326 139.635 147.381 140.162 147.74 140.452Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.01 148.183C147.465 148.551 148.131 148.481 148.499 148.026C148.867 147.572 148.797 146.905 148.343 146.537C147.888 146.169 147.222 146.239 146.854 146.694C146.486 147.148 146.556 147.815 147.01 148.183Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.8 146.837C145.238 147.192 145.88 147.124 146.235 146.686C146.59 146.248 146.522 145.606 146.084 145.251C145.646 144.897 145.004 144.964 144.649 145.402C144.295 145.84 144.362 146.482 144.8 146.837Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.553 142.509C141.77 142.685 142.089 142.652 142.265 142.435C142.441 142.217 142.407 141.898 142.19 141.722C141.973 141.546 141.654 141.58 141.478 141.797C141.302 142.015 141.335 142.333 141.553 142.509Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.166 142.058C139.417 142.26 139.784 142.222 139.986 141.971C140.189 141.721 140.151 141.354 139.9 141.151C139.65 140.949 139.283 140.987 139.08 141.238C138.878 141.488 138.916 141.855 139.166 142.058Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.372 142.214C137.593 142.393 137.917 142.359 138.095 142.138C138.274 141.917 138.24 141.594 138.019 141.415C137.799 141.236 137.475 141.27 137.296 141.491C137.118 141.712 137.152 142.035 137.372 142.214Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.276 148.502C133.638 148.795 134.17 148.739 134.463 148.377C134.756 148.015 134.701 147.483 134.338 147.19C133.976 146.897 133.445 146.953 133.152 147.315C132.858 147.677 132.914 148.208 133.276 148.502Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.307 142.1C133.64 142.37 134.128 142.318 134.397 141.986C134.666 141.653 134.615 141.165 134.283 140.896C133.95 140.627 133.462 140.678 133.193 141.011C132.923 141.343 132.975 141.831 133.307 142.1Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M131.48 143.562C131.75 143.781 132.146 143.739 132.365 143.469C132.584 143.199 132.542 142.803 132.272 142.585C132.002 142.366 131.606 142.408 131.387 142.678C131.169 142.948 131.21 143.344 131.48 143.562Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.057 138.976C132.291 139.165 132.634 139.129 132.823 138.895C133.013 138.661 132.977 138.318 132.743 138.129C132.509 137.94 132.166 137.976 131.977 138.21C131.787 138.443 131.824 138.786 132.057 138.976Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.277 144.907C129.501 145.088 129.829 145.054 130.01 144.83C130.192 144.606 130.157 144.278 129.933 144.096C129.709 143.915 129.381 143.95 129.2 144.173C129.018 144.397 129.053 144.726 129.277 144.907Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.532 148.885C128.822 149.119 129.247 149.075 129.482 148.785C129.716 148.495 129.672 148.07 129.382 147.835C129.092 147.601 128.667 147.646 128.433 147.935C128.198 148.225 128.243 148.65 128.532 148.885Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.677 138.557C127.937 138.768 128.318 138.728 128.529 138.467C128.74 138.207 128.7 137.826 128.439 137.615C128.179 137.404 127.798 137.445 127.587 137.705C127.376 137.965 127.417 138.346 127.677 138.557Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.587 146.951C119.887 147.194 120.326 147.148 120.569 146.848C120.812 146.549 120.765 146.109 120.466 145.866C120.166 145.624 119.727 145.67 119.484 145.97C119.241 146.269 119.288 146.709 119.587 146.951Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.24 139.129C121.698 139.5 122.369 139.429 122.74 138.971C123.11 138.514 123.04 137.842 122.582 137.472C122.124 137.101 121.453 137.172 121.082 137.629C120.712 138.087 120.782 138.758 121.24 139.129Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.327 140.047C118.581 140.253 118.953 140.214 119.158 139.96C119.363 139.707 119.324 139.335 119.071 139.129C118.817 138.924 118.445 138.963 118.24 139.217C118.035 139.47 118.074 139.842 118.327 140.047Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.691 135.762C121.066 136.066 121.617 136.008 121.921 135.633C122.225 135.258 122.167 134.707 121.791 134.403C121.416 134.099 120.865 134.157 120.561 134.532C120.257 134.907 120.315 135.458 120.691 135.762Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M116.899 133.995C117.245 134.275 117.752 134.221 118.032 133.876C118.312 133.53 118.259 133.023 117.913 132.743C117.567 132.463 117.06 132.516 116.78 132.862C116.5 133.208 116.553 133.715 116.899 133.995Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.886 132.444C116.311 132.788 116.934 132.722 117.278 132.298C117.622 131.873 117.556 131.25 117.132 130.906C116.707 130.562 116.084 130.627 115.74 131.052C115.396 131.477 115.461 132.1 115.886 132.444Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.2 127.999C115.516 128.255 115.98 128.206 116.236 127.89C116.492 127.574 116.443 127.11 116.127 126.854C115.811 126.598 115.348 126.647 115.092 126.963C114.836 127.279 114.884 127.743 115.2 127.999Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.99 127.865C107.217 128.049 107.55 128.014 107.734 127.787C107.918 127.559 107.883 127.226 107.656 127.042C107.429 126.858 107.095 126.893 106.911 127.12C106.728 127.348 106.763 127.681 106.99 127.865Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M112.008 122.995C112.268 123.206 112.65 123.166 112.861 122.906C113.071 122.646 113.031 122.264 112.771 122.054C112.511 121.843 112.129 121.883 111.919 122.143C111.708 122.403 111.748 122.785 112.008 122.995Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.378 121.162C111.586 121.33 111.89 121.299 112.058 121.091C112.226 120.884 112.194 120.579 111.987 120.411C111.779 120.243 111.475 120.275 111.307 120.483C111.139 120.69 111.171 120.995 111.378 121.162Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M116.284 118.887C116.528 119.084 116.885 119.047 117.083 118.803C117.28 118.559 117.242 118.202 116.999 118.004C116.755 117.807 116.398 117.845 116.2 118.088C116.003 118.332 116.041 118.689 116.284 118.887Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.979 116.969C109.239 117.18 109.621 117.14 109.832 116.88C110.042 116.619 110.002 116.238 109.742 116.027C109.482 115.817 109.1 115.857 108.89 116.117C108.679 116.377 108.719 116.758 108.979 116.969Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.046 114.041C108.352 114.289 108.801 114.242 109.049 113.936C109.297 113.629 109.25 113.18 108.944 112.932C108.637 112.684 108.188 112.731 107.94 113.038C107.692 113.344 107.739 113.793 108.046 114.041Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.056 98.9521C124.286 99.1388 124.624 99.1032 124.811 98.8728C124.998 98.6423 124.962 98.3041 124.732 98.1175C124.501 97.9308 124.163 97.9664 123.976 98.1969C123.79 98.4273 123.825 98.7655 124.056 98.9521Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.462 102.015C123.923 102.389 124.599 102.318 124.973 101.857C125.346 101.396 125.275 100.719 124.814 100.346C124.353 99.9728 123.677 100.044 123.303 100.505C122.93 100.966 123.001 101.642 123.462 102.015Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.892 103.915C129.268 104.219 129.818 104.162 130.122 103.786C130.426 103.411 130.368 102.86 129.993 102.556C129.618 102.252 129.067 102.31 128.763 102.685C128.459 103.061 128.517 103.612 128.892 103.915Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.281 105.344C123.663 105.653 124.224 105.594 124.533 105.212C124.842 104.83 124.783 104.27 124.402 103.96C124.02 103.651 123.459 103.71 123.15 104.092C122.841 104.474 122.899 105.034 123.281 105.344Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.316 107.623C125.507 107.778 125.787 107.749 125.942 107.558C126.097 107.367 126.067 107.087 125.876 106.932C125.685 106.777 125.405 106.807 125.25 106.998C125.096 107.189 125.125 107.469 125.316 107.623Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.139 109.67C122.35 109.841 122.659 109.808 122.83 109.598C123 109.387 122.968 109.078 122.757 108.907C122.546 108.736 122.237 108.769 122.066 108.98C121.896 109.19 121.928 109.5 122.139 109.67Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.203 110.659C120.43 110.843 120.763 110.808 120.947 110.581C121.131 110.354 121.096 110.021 120.869 109.837C120.642 109.653 120.309 109.688 120.125 109.915C119.941 110.142 119.976 110.475 120.203 110.659Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.008 113.328C129.225 113.504 129.544 113.47 129.72 113.253C129.896 113.036 129.863 112.717 129.645 112.541C129.428 112.365 129.109 112.398 128.933 112.616C128.757 112.833 128.791 113.152 129.008 113.328Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.477 115.79C127.744 116.006 128.135 115.965 128.351 115.698C128.567 115.431 128.526 115.04 128.259 114.824C127.992 114.608 127.601 114.649 127.385 114.916C127.169 115.182 127.21 115.574 127.477 115.79Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.449 117.246C122.654 117.412 122.953 117.38 123.118 117.176C123.284 116.972 123.252 116.672 123.048 116.507C122.844 116.342 122.544 116.373 122.379 116.577C122.214 116.782 122.245 117.081 122.449 117.246Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.117 120.435C129.493 120.739 130.043 120.681 130.347 120.306C130.651 119.93 130.593 119.38 130.218 119.076C129.843 118.772 129.292 118.83 128.988 119.205C128.684 119.58 128.742 120.131 129.117 120.435Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.515 121.456C122.851 121.728 123.344 121.676 123.616 121.34C123.888 121.004 123.836 120.511 123.5 120.239C123.164 119.967 122.672 120.019 122.4 120.355C122.128 120.691 122.179 121.184 122.515 121.456Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.531 124.578C122.814 124.807 123.23 124.763 123.459 124.48C123.688 124.197 123.645 123.782 123.361 123.552C123.078 123.323 122.663 123.367 122.434 123.65C122.204 123.933 122.248 124.348 122.531 124.578Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.039 125.837C121.21 125.976 121.461 125.949 121.6 125.778C121.738 125.607 121.712 125.356 121.541 125.217C121.37 125.078 121.118 125.105 120.98 125.276C120.841 125.447 120.867 125.698 121.039 125.837Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.858 127.992C126.082 128.174 126.41 128.139 126.591 127.915C126.773 127.691 126.738 127.363 126.514 127.182C126.29 127 125.962 127.035 125.781 127.259C125.599 127.483 125.634 127.811 125.858 127.992Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.607 130.828C122.042 131.18 122.679 131.113 123.031 130.679C123.383 130.244 123.316 129.606 122.882 129.254C122.447 128.903 121.809 128.97 121.457 129.404C121.105 129.839 121.172 130.476 121.607 130.828Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.089 131.999C123.491 132.324 124.08 132.262 124.405 131.86C124.73 131.459 124.669 130.869 124.267 130.544C123.865 130.219 123.276 130.281 122.951 130.682C122.625 131.084 122.687 131.673 123.089 131.999Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.61 133.566C121.923 133.819 122.382 133.771 122.635 133.458C122.889 133.145 122.841 132.686 122.528 132.433C122.215 132.18 121.756 132.228 121.503 132.541C121.249 132.853 121.298 133.312 121.61 133.566Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.181 133.349C129.553 133.65 130.099 133.593 130.4 133.221C130.701 132.849 130.644 132.303 130.272 132.002C129.9 131.7 129.354 131.758 129.053 132.13C128.751 132.502 128.809 133.048 129.181 133.349Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.793 134.289C129.254 134.662 129.93 134.591 130.303 134.13C130.677 133.669 130.606 132.993 130.145 132.619C129.684 132.246 129.007 132.317 128.634 132.778C128.261 133.239 128.332 133.915 128.793 134.289Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.135 136.253C127.527 136.57 128.102 136.51 128.419 136.118C128.737 135.726 128.676 135.151 128.284 134.834C127.893 134.517 127.318 134.577 127 134.969C126.683 135.361 126.744 135.936 127.135 136.253Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.572 137.632C125.763 137.787 126.043 137.758 126.197 137.567C126.352 137.376 126.323 137.096 126.132 136.941C125.941 136.786 125.661 136.816 125.506 137.007C125.351 137.198 125.381 137.478 125.572 137.632Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M126.364 132.706C126.561 132.866 126.851 132.836 127.011 132.638C127.171 132.441 127.141 132.151 126.943 131.991C126.746 131.831 126.456 131.862 126.296 132.059C126.136 132.257 126.166 132.546 126.364 132.706Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.467 141.25C125.704 141.442 126.052 141.405 126.244 141.168C126.436 140.931 126.4 140.583 126.163 140.391C125.926 140.199 125.578 140.236 125.386 140.473C125.194 140.71 125.23 141.058 125.467 141.25Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.38 106.741C161.824 107.101 162.476 107.033 162.836 106.588C163.196 106.144 163.128 105.491 162.683 105.132C162.239 104.772 161.587 104.84 161.227 105.285C160.867 105.729 160.935 106.381 161.38 106.741Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M156.276 107.988C156.562 108.22 156.982 108.175 157.214 107.889C157.446 107.603 157.402 107.182 157.116 106.95C156.829 106.718 156.409 106.762 156.177 107.049C155.945 107.335 155.989 107.756 156.276 107.988Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.283 110.798C161.566 111.028 161.981 110.984 162.211 110.701C162.44 110.418 162.396 110.002 162.113 109.773C161.83 109.544 161.414 109.587 161.185 109.87C160.956 110.154 160.999 110.569 161.283 110.798Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M162.388 114.053C162.7 114.306 163.159 114.258 163.413 113.945C163.666 113.632 163.618 113.173 163.305 112.92C162.992 112.667 162.533 112.715 162.28 113.028C162.027 113.341 162.075 113.799 162.388 114.053Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.83 116.889C162.265 117.24 162.902 117.173 163.254 116.739C163.606 116.304 163.539 115.667 163.105 115.315C162.67 114.963 162.033 115.03 161.681 115.464C161.329 115.899 161.395 116.537 161.83 116.889Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M157.039 117.599C157.322 117.828 157.738 117.784 157.967 117.501C158.196 117.218 158.153 116.803 157.87 116.573C157.586 116.344 157.171 116.388 156.942 116.671C156.712 116.954 156.756 117.369 157.039 117.599Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M157.969 120.059C158.41 120.417 159.057 120.349 159.415 119.907C159.772 119.466 159.704 118.819 159.263 118.462C158.822 118.104 158.174 118.172 157.817 118.614C157.46 119.055 157.528 119.702 157.969 120.059Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M156.279 121.988C156.496 122.164 156.815 122.13 156.991 121.913C157.167 121.696 157.133 121.377 156.916 121.201C156.699 121.025 156.38 121.058 156.204 121.276C156.028 121.493 156.062 121.812 156.279 121.988Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.179 124.663C161.377 124.823 161.666 124.793 161.826 124.595C161.986 124.397 161.956 124.108 161.758 123.948C161.561 123.788 161.271 123.818 161.111 124.016C160.951 124.213 160.981 124.503 161.179 124.663Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.194 123.872C152.451 124.08 152.828 124.041 153.036 123.784C153.244 123.527 153.204 123.15 152.947 122.942C152.691 122.734 152.314 122.774 152.106 123.031C151.898 123.287 151.937 123.664 152.194 123.872Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.984 127.286C153.346 127.579 153.878 127.523 154.171 127.161C154.464 126.799 154.409 126.268 154.046 125.974C153.684 125.681 153.153 125.737 152.86 126.099C152.566 126.461 152.622 126.993 152.984 127.286Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M160.003 132.466C160.17 132.602 160.417 132.576 160.553 132.409C160.689 132.241 160.663 131.994 160.495 131.858C160.327 131.722 160.081 131.748 159.945 131.916C159.809 132.084 159.835 132.33 160.003 132.466Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M153.819 133.935C154 134.081 154.266 134.053 154.412 133.872C154.559 133.691 154.531 133.425 154.35 133.279C154.169 133.132 153.903 133.16 153.757 133.341C153.61 133.522 153.638 133.788 153.819 133.935Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M157.043 139.388C157.263 139.567 157.587 139.533 157.766 139.312C157.944 139.091 157.91 138.768 157.69 138.589C157.469 138.41 157.145 138.444 156.967 138.665C156.788 138.886 156.822 139.209 157.043 139.388Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.546 140.663C152.826 140.889 153.237 140.846 153.463 140.566C153.69 140.286 153.647 139.876 153.367 139.649C153.087 139.423 152.677 139.466 152.45 139.746C152.223 140.025 152.266 140.436 152.546 140.663Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.061 141.204C145.39 141.471 145.873 141.42 146.139 141.091C146.406 140.762 146.355 140.279 146.026 140.012C145.697 139.745 145.214 139.796 144.947 140.125C144.68 140.455 144.731 140.938 145.061 141.204Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M142.094 141.22C142.275 141.367 142.541 141.339 142.687 141.158C142.834 140.977 142.806 140.711 142.625 140.565C142.444 140.418 142.178 140.446 142.032 140.627C141.885 140.808 141.913 141.074 142.094 141.22Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.417 141.372C139.647 141.559 139.986 141.523 140.172 141.293C140.359 141.062 140.323 140.724 140.093 140.537C139.862 140.351 139.524 140.386 139.338 140.617C139.151 140.847 139.186 141.185 139.417 141.372Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M138.789 139.245C139.02 139.431 139.358 139.396 139.545 139.165C139.731 138.935 139.696 138.597 139.465 138.41C139.235 138.224 138.897 138.259 138.71 138.49C138.523 138.72 138.559 139.058 138.789 139.245Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.226 146.181C134.49 146.394 134.876 146.354 135.09 146.09C135.303 145.827 135.262 145.441 134.999 145.227C134.735 145.014 134.349 145.055 134.136 145.318C133.922 145.581 133.963 145.968 134.226 146.181Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.885 138.482C135.125 138.677 135.478 138.639 135.673 138.399C135.867 138.159 135.83 137.806 135.59 137.611C135.35 137.417 134.997 137.454 134.802 137.694C134.608 137.935 134.645 138.287 134.885 138.482Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M131.958 143.633C132.188 143.82 132.526 143.784 132.713 143.554C132.9 143.323 132.864 142.985 132.634 142.799C132.403 142.612 132.065 142.648 131.878 142.878C131.692 143.108 131.727 143.447 131.958 143.633Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.614 143.225C130.028 143.561 130.637 143.497 130.973 143.083C131.309 142.668 131.245 142.059 130.83 141.723C130.415 141.387 129.807 141.451 129.471 141.866C129.135 142.281 129.199 142.889 129.614 143.225Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.704 142.656C127.958 142.862 128.33 142.823 128.535 142.569C128.74 142.315 128.701 141.944 128.448 141.738C128.194 141.533 127.822 141.572 127.617 141.825C127.412 142.079 127.451 142.451 127.704 142.656Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.393 139.591C127.561 139.727 127.807 139.701 127.943 139.533C128.079 139.365 128.054 139.119 127.886 138.983C127.718 138.847 127.471 138.873 127.335 139.041C127.199 139.209 127.225 139.455 127.393 139.591Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.794 136.608C124.965 136.746 125.216 136.72 125.355 136.549C125.493 136.377 125.467 136.126 125.296 135.988C125.125 135.849 124.873 135.875 124.735 136.047C124.596 136.218 124.623 136.469 124.794 136.608Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.393 139.303C123.637 139.501 123.994 139.463 124.192 139.219C124.389 138.976 124.351 138.618 124.108 138.421C123.864 138.224 123.507 138.261 123.309 138.505C123.112 138.749 123.149 139.106 123.393 139.303Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.519 143.719C122.707 143.871 122.982 143.842 123.134 143.654C123.286 143.466 123.257 143.191 123.07 143.039C122.882 142.887 122.607 142.916 122.455 143.104C122.303 143.291 122.331 143.567 122.519 143.719Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.169 136.209C121.419 136.411 121.786 136.373 121.989 136.122C122.192 135.872 122.153 135.505 121.903 135.302C121.653 135.1 121.285 135.138 121.083 135.389C120.88 135.639 120.919 136.006 121.169 136.209Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M169.108 112.24C169.559 112.605 170.221 112.536 170.587 112.085C170.952 111.634 170.882 110.972 170.431 110.606C169.98 110.241 169.318 110.311 168.953 110.762C168.588 111.213 168.657 111.875 169.108 112.24Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M162.161 111.994C162.457 112.234 162.892 112.188 163.132 111.892C163.372 111.596 163.327 111.161 163.03 110.921C162.734 110.681 162.299 110.727 162.059 111.023C161.819 111.319 161.865 111.754 162.161 111.994Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M167.689 115.305C168.114 115.649 168.737 115.584 169.081 115.159C169.425 114.734 169.359 114.111 168.935 113.767C168.51 113.423 167.887 113.489 167.543 113.914C167.199 114.338 167.264 114.961 167.689 115.305Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M157.701 115.756C158.097 116.076 158.676 116.015 158.996 115.62C159.316 115.225 159.255 114.645 158.86 114.325C158.465 114.005 157.885 114.066 157.565 114.461C157.245 114.857 157.306 115.436 157.701 115.756Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M164.043 117.467C164.296 117.672 164.668 117.633 164.874 117.379C165.079 117.126 165.04 116.754 164.786 116.549C164.533 116.343 164.161 116.382 163.956 116.636C163.75 116.889 163.789 117.261 164.043 117.467Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M158.132 117.891C158.405 118.113 158.806 118.071 159.027 117.797C159.248 117.524 159.206 117.123 158.933 116.902C158.66 116.68 158.259 116.723 158.038 116.996C157.816 117.269 157.858 117.67 158.132 117.891Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M159.339 120.439C159.79 120.804 160.452 120.735 160.817 120.284C161.183 119.833 161.113 119.171 160.662 118.806C160.211 118.44 159.549 118.51 159.184 118.961C158.818 119.412 158.888 120.074 159.339 120.439Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.178 123.369C161.412 123.559 161.755 123.523 161.944 123.289C162.134 123.055 162.097 122.712 161.864 122.523C161.63 122.333 161.287 122.369 161.098 122.603C160.908 122.837 160.944 123.18 161.178 123.369Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M162.568 126.41C163.009 126.767 163.656 126.699 164.014 126.258C164.371 125.817 164.303 125.17 163.862 124.812C163.421 124.455 162.773 124.523 162.416 124.964C162.059 125.405 162.127 126.053 162.568 126.41Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M154.934 127.256C155.315 127.565 155.876 127.506 156.185 127.124C156.494 126.742 156.436 126.182 156.054 125.873C155.672 125.563 155.111 125.622 154.802 126.004C154.493 126.386 154.552 126.946 154.934 127.256Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M156.159 128.376C156.468 128.627 156.922 128.579 157.173 128.27C157.424 127.96 157.376 127.506 157.066 127.256C156.757 127.005 156.303 127.053 156.052 127.362C155.802 127.672 155.849 128.126 156.159 128.376Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M160.629 131.206C160.938 131.457 161.392 131.409 161.643 131.1C161.894 130.79 161.846 130.336 161.536 130.086C161.227 129.835 160.773 129.883 160.522 130.192C160.272 130.502 160.319 130.956 160.629 131.206Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M163.589 133.841C163.902 134.094 164.36 134.046 164.614 133.733C164.867 133.42 164.819 132.961 164.506 132.708C164.193 132.455 163.734 132.503 163.481 132.816C163.228 133.128 163.276 133.587 163.589 133.841Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.975 134.1C153.192 134.276 153.511 134.242 153.687 134.025C153.863 133.808 153.83 133.489 153.612 133.313C153.395 133.137 153.076 133.171 152.9 133.388C152.724 133.605 152.758 133.924 152.975 134.1Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M159.278 139.431C159.719 139.789 160.367 139.721 160.724 139.28C161.081 138.838 161.013 138.191 160.572 137.834C160.131 137.476 159.484 137.544 159.126 137.986C158.769 138.427 158.837 139.074 159.278 139.431Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M153.082 138.866C153.53 139.228 154.187 139.159 154.55 138.712C154.912 138.264 154.843 137.607 154.395 137.244C153.948 136.882 153.291 136.951 152.928 137.398C152.565 137.846 152.635 138.503 153.082 138.866Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M158.604 142.104C158.851 142.304 159.214 142.266 159.413 142.019C159.613 141.772 159.575 141.409 159.328 141.21C159.082 141.01 158.719 141.048 158.519 141.295C158.319 141.541 158.357 141.904 158.604 142.104Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M159.569 147.248C159.757 147.4 160.032 147.371 160.184 147.184C160.336 146.996 160.307 146.721 160.119 146.569C159.932 146.417 159.656 146.446 159.504 146.633C159.352 146.821 159.381 147.096 159.569 147.248Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.508 146.377C152.847 146.652 153.345 146.599 153.619 146.26C153.894 145.921 153.842 145.424 153.502 145.149C153.163 144.874 152.666 144.927 152.391 145.266C152.117 145.605 152.169 146.102 152.508 146.377Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M155.26 154.203C155.592 154.472 156.08 154.421 156.35 154.088C156.619 153.756 156.568 153.268 156.235 152.998C155.903 152.729 155.415 152.78 155.145 153.113C154.876 153.445 154.927 153.933 155.26 154.203Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.627 147.941C144.999 148.243 145.545 148.185 145.846 147.813C146.148 147.441 146.09 146.895 145.718 146.594C145.346 146.293 144.8 146.35 144.499 146.722C144.198 147.094 144.255 147.64 144.627 147.941Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M142.632 152.673C142.876 152.87 143.233 152.833 143.43 152.589C143.628 152.345 143.59 151.988 143.346 151.791C143.103 151.593 142.745 151.631 142.548 151.874C142.351 152.118 142.388 152.476 142.632 152.673Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.014 157.808C140.435 158.149 141.053 158.084 141.395 157.663C141.736 157.241 141.671 156.623 141.25 156.282C140.828 155.94 140.21 156.005 139.869 156.427C139.527 156.848 139.592 157.467 140.014 157.808Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M136.602 148.036C136.915 148.289 137.374 148.241 137.627 147.928C137.881 147.616 137.832 147.157 137.52 146.903C137.207 146.65 136.748 146.698 136.495 147.011C136.241 147.324 136.29 147.783 136.602 148.036Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.262 150.12C135.496 150.309 135.839 150.273 136.028 150.039C136.217 149.805 136.181 149.463 135.947 149.273C135.714 149.084 135.371 149.12 135.181 149.354C134.992 149.587 135.028 149.93 135.262 150.12Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.77 152.366C133.056 152.598 133.477 152.554 133.709 152.267C133.941 151.981 133.896 151.56 133.61 151.328C133.324 151.097 132.903 151.141 132.671 151.427C132.439 151.714 132.483 152.134 132.77 152.366Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.727 149.191C129.97 149.388 130.328 149.351 130.525 149.107C130.723 148.863 130.685 148.506 130.441 148.308C130.198 148.111 129.84 148.149 129.643 148.392C129.446 148.636 129.483 148.993 129.727 149.191Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M126.614 146.877C126.947 147.147 127.435 147.095 127.704 146.763C127.973 146.43 127.922 145.942 127.59 145.673C127.257 145.404 126.769 145.455 126.5 145.788C126.231 146.12 126.282 146.608 126.614 146.877Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.237 151.586C123.599 151.879 124.131 151.823 124.424 151.461C124.717 151.099 124.662 150.567 124.299 150.274C123.937 149.981 123.406 150.037 123.112 150.399C122.819 150.761 122.875 151.292 123.237 151.586Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.813 153.837C120.001 153.989 120.276 153.96 120.428 153.773C120.58 153.585 120.551 153.31 120.363 153.158C120.176 153.006 119.9 153.034 119.748 153.222C119.597 153.41 119.625 153.685 119.813 153.837Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.665 146.896C121.034 147.195 121.575 147.138 121.874 146.769C122.172 146.401 122.115 145.86 121.747 145.561C121.378 145.262 120.837 145.319 120.538 145.688C120.239 146.057 120.296 146.598 120.665 146.896Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.754 151.528C118.034 151.755 118.445 151.711 118.671 151.431C118.898 151.152 118.855 150.741 118.575 150.514C118.295 150.288 117.884 150.331 117.658 150.611C117.431 150.891 117.474 151.301 117.754 151.528Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.197 147.059C117.595 147.382 118.18 147.321 118.503 146.922C118.825 146.524 118.764 145.939 118.365 145.617C117.967 145.294 117.382 145.355 117.06 145.754C116.737 146.152 116.799 146.737 117.197 147.059Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.204 146.758C114.54 147.03 115.033 146.978 115.305 146.642C115.577 146.306 115.525 145.814 115.189 145.542C114.853 145.27 114.361 145.322 114.089 145.657C113.817 145.993 113.868 146.486 114.204 146.758Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M112.96 143.943C113.272 144.196 113.731 144.148 113.985 143.835C114.238 143.523 114.19 143.064 113.877 142.81C113.564 142.557 113.105 142.605 112.852 142.918C112.599 143.231 112.647 143.69 112.96 143.943Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M107.563 146.207C107.988 146.551 108.611 146.485 108.955 146.061C109.299 145.636 109.233 145.013 108.809 144.669C108.384 144.325 107.761 144.39 107.417 144.815C107.073 145.24 107.138 145.863 107.563 146.207Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M107.634 138.11C107.838 138.276 108.138 138.244 108.303 138.04C108.468 137.836 108.437 137.536 108.233 137.371C108.029 137.206 107.729 137.237 107.564 137.441C107.399 137.646 107.43 137.945 107.634 138.11Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.587 135.606C111.923 135.878 112.416 135.826 112.688 135.49C112.96 135.154 112.908 134.662 112.572 134.39C112.236 134.118 111.743 134.169 111.471 134.505C111.199 134.841 111.251 135.334 111.587 135.606Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.721 133.246C112.04 133.505 112.509 133.455 112.767 133.136C113.026 132.817 112.977 132.348 112.657 132.09C112.338 131.831 111.869 131.88 111.611 132.2C111.352 132.519 111.401 132.988 111.721 133.246Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.594 128.422C103.802 128.59 104.106 128.558 104.274 128.351C104.442 128.143 104.41 127.839 104.202 127.671C103.995 127.503 103.691 127.535 103.523 127.742C103.355 127.95 103.387 128.254 103.594 128.422Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M112.578 128.896C112.901 129.158 113.374 129.108 113.636 128.785C113.897 128.463 113.847 127.989 113.525 127.728C113.202 127.467 112.729 127.516 112.467 127.839C112.206 128.162 112.256 128.635 112.578 128.896Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.473 127.083C110.898 127.427 111.521 127.361 111.865 126.937C112.209 126.512 112.143 125.889 111.719 125.545C111.294 125.201 110.671 125.266 110.327 125.691C109.983 126.116 110.048 126.739 110.473 127.083Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.025 123.087C110.407 123.396 110.967 123.338 111.277 122.956C111.586 122.574 111.527 122.013 111.145 121.704C110.763 121.395 110.203 121.454 109.894 121.836C109.584 122.218 109.643 122.778 110.025 123.087Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M104.053 120.225C104.497 120.585 105.149 120.516 105.509 120.072C105.869 119.627 105.801 118.975 105.356 118.615C104.912 118.255 104.26 118.324 103.9 118.768C103.54 119.213 103.608 119.865 104.053 120.225Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.842 120.285C112.014 120.423 112.265 120.397 112.403 120.226C112.542 120.054 112.516 119.803 112.344 119.665C112.173 119.526 111.922 119.552 111.783 119.724C111.645 119.895 111.671 120.146 111.842 120.285Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.864 118.108C106.262 118.431 106.847 118.37 107.17 117.971C107.492 117.573 107.431 116.988 107.032 116.666C106.634 116.343 106.049 116.405 105.727 116.803C105.404 117.201 105.466 117.786 105.864 118.108Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.279 117.559C113.523 117.756 113.88 117.719 114.077 117.475C114.275 117.231 114.237 116.874 113.993 116.677C113.75 116.479 113.392 116.517 113.195 116.76C112.998 117.004 113.035 117.362 113.279 117.559Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M158.73 104.881C158.94 105.052 159.25 105.02 159.42 104.809C159.591 104.598 159.558 104.289 159.348 104.118C159.137 103.948 158.828 103.98 158.657 104.191C158.486 104.402 158.519 104.711 158.73 104.881Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M158.071 108.277C158.266 108.434 158.551 108.404 158.708 108.21C158.865 108.016 158.835 107.731 158.641 107.573C158.447 107.416 158.162 107.446 158.004 107.64C157.847 107.835 157.877 108.12 158.071 108.277Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M159.886 110.754C160.301 111.09 160.91 111.026 161.246 110.611C161.582 110.196 161.518 109.587 161.103 109.251C160.688 108.915 160.079 108.979 159.743 109.394C159.407 109.809 159.471 110.418 159.886 110.754Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M157.635 112.06C158.01 112.364 158.561 112.306 158.865 111.931C159.169 111.555 159.111 111.005 158.736 110.701C158.361 110.397 157.81 110.455 157.506 110.83C157.202 111.205 157.26 111.756 157.635 112.06Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.966 115.499C162.256 115.733 162.681 115.689 162.916 115.399C163.15 115.109 163.106 114.684 162.816 114.45C162.526 114.215 162.101 114.26 161.866 114.549C161.632 114.839 161.676 115.264 161.966 115.499Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M153.239 114.413C153.588 114.696 154.1 114.642 154.383 114.293C154.665 113.944 154.612 113.432 154.263 113.149C153.914 112.867 153.401 112.92 153.119 113.269C152.836 113.618 152.89 114.131 153.239 114.413Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.845 117.513C162.122 117.737 162.527 117.695 162.751 117.418C162.975 117.142 162.933 116.736 162.656 116.512C162.38 116.288 161.974 116.33 161.75 116.607C161.526 116.884 161.569 117.289 161.845 117.513Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M155.79 117.861C156.103 118.115 156.562 118.066 156.815 117.754C157.068 117.441 157.02 116.982 156.707 116.729C156.395 116.475 155.936 116.524 155.682 116.836C155.429 117.149 155.477 117.608 155.79 117.861Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M161.015 119.841C161.242 120.025 161.576 119.99 161.76 119.763C161.944 119.536 161.909 119.203 161.681 119.019C161.454 118.835 161.121 118.87 160.937 119.097C160.753 119.324 160.788 119.657 161.015 119.841Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M158.346 122.725C158.596 122.927 158.964 122.889 159.166 122.638C159.369 122.388 159.33 122.021 159.08 121.818C158.83 121.616 158.463 121.654 158.26 121.904C158.057 122.155 158.096 122.522 158.346 122.725Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M153.36 124.738C153.65 124.973 154.075 124.928 154.31 124.639C154.544 124.349 154.5 123.924 154.21 123.689C153.92 123.454 153.495 123.499 153.26 123.789C153.026 124.079 153.07 124.504 153.36 124.738Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M153.903 128.09C154.308 128.418 154.903 128.356 155.231 127.951C155.559 127.546 155.496 126.952 155.091 126.624C154.686 126.296 154.092 126.358 153.764 126.763C153.436 127.168 153.499 127.762 153.903 128.09Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M156.576 131.676C157.007 132.025 157.64 131.959 157.989 131.528C158.339 131.096 158.272 130.463 157.841 130.114C157.409 129.765 156.777 129.831 156.427 130.263C156.078 130.694 156.145 131.327 156.576 131.676Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M150.992 131.853C151.351 132.143 151.878 132.088 152.168 131.729C152.459 131.37 152.403 130.844 152.045 130.553C151.686 130.262 151.159 130.318 150.869 130.677C150.578 131.036 150.633 131.562 150.992 131.853Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M154.279 138.424C154.496 138.6 154.815 138.566 154.991 138.349C155.167 138.132 155.133 137.813 154.916 137.637C154.699 137.461 154.38 137.494 154.204 137.712C154.028 137.929 154.062 138.248 154.279 138.424Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.394 136.806C147.76 137.102 148.296 137.046 148.592 136.68C148.888 136.315 148.832 135.779 148.466 135.483C148.101 135.187 147.565 135.243 147.269 135.609C146.973 135.974 147.029 136.51 147.394 136.806Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.197 148.206C147.474 148.43 147.88 148.388 148.104 148.111C148.328 147.834 148.285 147.429 148.008 147.205C147.732 146.981 147.326 147.023 147.102 147.3C146.878 147.576 146.921 147.982 147.197 148.206Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M143.267 137.847C143.55 138.077 143.966 138.033 144.195 137.75C144.424 137.467 144.381 137.051 144.098 136.822C143.815 136.593 143.399 136.636 143.17 136.919C142.94 137.203 142.984 137.618 143.267 137.847Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.72 147.55C140.131 147.883 140.735 147.82 141.069 147.408C141.402 146.997 141.338 146.393 140.927 146.06C140.515 145.726 139.911 145.79 139.578 146.201C139.245 146.613 139.308 147.217 139.72 147.55Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.349 138.049C137.517 138.185 137.763 138.159 137.899 137.991C138.035 137.823 138.009 137.577 137.841 137.441C137.673 137.305 137.427 137.331 137.291 137.499C137.155 137.667 137.181 137.913 137.349 138.049Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.953 145.664C134.252 145.906 134.692 145.86 134.934 145.56C135.177 145.261 135.131 144.821 134.831 144.579C134.532 144.336 134.092 144.382 133.849 144.682C133.607 144.981 133.653 145.421 133.953 145.664Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.193 141.584C132.4 141.752 132.705 141.72 132.873 141.512C133.041 141.305 133.009 141 132.801 140.832C132.594 140.665 132.29 140.696 132.122 140.904C131.954 141.111 131.986 141.416 132.193 141.584Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.977 138.762C130.238 138.973 130.619 138.933 130.83 138.672C131.04 138.412 131 138.031 130.74 137.82C130.48 137.609 130.099 137.65 129.888 137.91C129.677 138.17 129.717 138.551 129.977 138.762Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.77 139.699C128.037 139.915 128.428 139.874 128.644 139.607C128.86 139.34 128.819 138.949 128.552 138.733C128.285 138.517 127.894 138.558 127.678 138.825C127.462 139.092 127.503 139.483 127.77 139.699Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.372 145.091C123.773 145.416 124.363 145.354 124.688 144.952C125.013 144.551 124.952 143.961 124.55 143.636C124.148 143.311 123.559 143.373 123.233 143.774C122.908 144.176 122.97 144.765 123.372 145.091Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.326 138.084C122.694 138.383 123.236 138.326 123.534 137.957C123.833 137.588 123.776 137.047 123.407 136.748C123.038 136.45 122.497 136.507 122.199 136.875C121.9 137.244 121.957 137.785 122.326 138.084Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.019 138.435C115.437 138.774 116.051 138.709 116.39 138.291C116.728 137.873 116.664 137.26 116.246 136.921C115.827 136.582 115.214 136.647 114.875 137.065C114.537 137.483 114.601 138.097 115.019 138.435Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.17 130.874C110.486 131.13 110.949 131.082 111.205 130.766C111.461 130.45 111.413 129.986 111.096 129.73C110.78 129.474 110.317 129.523 110.061 129.839C109.805 130.155 109.853 130.618 110.17 130.874Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.743 128.321C118.178 128.673 118.815 128.606 119.167 128.171C119.519 127.737 119.452 127.099 119.018 126.747C118.583 126.395 117.946 126.462 117.594 126.897C117.242 127.332 117.309 127.969 117.743 128.321Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.531 125.682C117.775 125.879 118.132 125.842 118.33 125.598C118.527 125.355 118.489 124.997 118.246 124.8C118.002 124.603 117.645 124.64 117.447 124.884C117.25 125.127 117.288 125.485 117.531 125.682Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.095 122.355C114.361 122.571 114.753 122.53 114.968 122.264C115.184 121.997 115.143 121.606 114.877 121.39C114.61 121.174 114.219 121.215 114.003 121.481C113.787 121.748 113.828 122.139 114.095 122.355Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.109 120.208C120.507 120.531 121.092 120.469 121.414 120.071C121.737 119.672 121.676 119.088 121.277 118.765C120.879 118.442 120.294 118.504 119.971 118.902C119.649 119.301 119.71 119.885 120.109 120.208Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M116.78 117.492C117.023 117.689 117.381 117.652 117.578 117.408C117.775 117.164 117.738 116.807 117.494 116.61C117.25 116.412 116.893 116.45 116.696 116.694C116.498 116.937 116.536 117.295 116.78 117.492Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.117 114.616C117.318 114.779 117.613 114.748 117.776 114.547C117.938 114.346 117.907 114.051 117.706 113.889C117.506 113.726 117.211 113.757 117.048 113.958C116.886 114.159 116.917 114.453 117.117 114.616Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.194 111.023C110.46 111.239 110.852 111.197 111.068 110.931C111.284 110.664 111.242 110.273 110.976 110.057C110.709 109.841 110.318 109.882 110.102 110.149C109.886 110.415 109.927 110.807 110.194 111.023Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.945 109.354C120.192 109.554 120.554 109.516 120.754 109.269C120.954 109.022 120.916 108.66 120.669 108.46C120.422 108.26 120.06 108.298 119.86 108.545C119.66 108.792 119.698 109.154 119.945 109.354Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.825 105.878C111.247 106.219 111.865 106.154 112.207 105.733C112.548 105.311 112.483 104.693 112.061 104.352C111.64 104.011 111.022 104.076 110.68 104.497C110.339 104.918 110.404 105.537 110.825 105.878Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M154.333 107.886C154.531 108.046 154.821 108.015 154.981 107.818C155.141 107.62 155.11 107.33 154.913 107.17C154.715 107.01 154.425 107.041 154.265 107.238C154.105 107.436 154.136 107.726 154.333 107.886Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.984 106.161C153.356 106.463 153.902 106.405 154.203 106.033C154.505 105.661 154.447 105.115 154.075 104.814C153.703 104.513 153.157 104.57 152.856 104.942C152.555 105.314 152.612 105.86 152.984 106.161Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.386 103.443C144.564 103.587 144.825 103.559 144.969 103.381C145.113 103.203 145.085 102.943 144.908 102.799C144.73 102.655 144.469 102.682 144.325 102.86C144.181 103.038 144.208 103.299 144.386 103.443Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M150.292 103.23C150.74 103.593 151.396 103.524 151.759 103.076C152.122 102.628 152.053 101.971 151.605 101.609C151.157 101.246 150.5 101.315 150.138 101.763C149.775 102.21 149.844 102.867 150.292 103.23Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.545 134.597C118.9 134.885 119.422 134.83 119.71 134.475C119.998 134.119 119.943 133.597 119.587 133.31C119.232 133.022 118.71 133.076 118.422 133.432C118.134 133.788 118.189 134.309 118.545 134.597Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.751 135.83C119.176 136.174 119.799 136.109 120.143 135.684C120.487 135.259 120.422 134.636 119.997 134.292C119.572 133.948 118.949 134.014 118.605 134.438C118.261 134.863 118.327 135.486 118.751 135.83Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.734 137.851C119.083 138.133 119.595 138.08 119.878 137.731C120.161 137.382 120.107 136.87 119.758 136.587C119.409 136.304 118.897 136.358 118.614 136.707C118.332 137.056 118.385 137.568 118.734 137.851Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.492 136.846C121.676 136.995 121.946 136.966 122.096 136.782C122.245 136.598 122.217 136.327 122.032 136.178C121.848 136.029 121.577 136.057 121.428 136.241C121.279 136.426 121.307 136.696 121.492 136.846Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.401 137.207C122.793 137.524 123.368 137.464 123.685 137.072C124.002 136.68 123.942 136.105 123.55 135.788C123.158 135.471 122.584 135.531 122.266 135.923C121.949 136.315 122.009 136.89 122.401 137.207Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.948 136.545C124.373 136.889 124.996 136.823 125.34 136.398C125.684 135.974 125.619 135.351 125.194 135.007C124.769 134.663 124.146 134.728 123.802 135.153C123.458 135.578 123.524 136.201 123.948 136.545Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.971 141.499C125.158 141.651 125.434 141.622 125.586 141.434C125.738 141.246 125.709 140.971 125.521 140.819C125.333 140.667 125.058 140.696 124.906 140.884C124.754 141.071 124.783 141.347 124.971 141.499Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.636 139.491C125.913 139.715 126.319 139.672 126.543 139.396C126.766 139.119 126.724 138.714 126.447 138.49C126.171 138.266 125.765 138.308 125.541 138.585C125.317 138.861 125.36 139.267 125.636 139.491Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.397 137.235C127.621 137.416 127.949 137.382 128.13 137.158C128.312 136.934 128.277 136.605 128.053 136.424C127.829 136.243 127.501 136.277 127.32 136.501C127.138 136.725 127.173 137.053 127.397 137.235Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.765 142.194C130.147 142.503 130.708 142.444 131.017 142.062C131.326 141.68 131.267 141.12 130.885 140.811C130.503 140.501 129.943 140.56 129.634 140.942C129.324 141.324 129.383 141.884 129.765 142.194Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.256 139.097C132.519 139.31 132.906 139.27 133.119 139.006C133.332 138.743 133.292 138.357 133.028 138.143C132.765 137.93 132.379 137.971 132.165 138.234C131.952 138.497 131.993 138.884 132.256 139.097Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.52 134.494C134.911 134.811 135.486 134.751 135.804 134.359C136.121 133.967 136.06 133.392 135.669 133.075C135.277 132.758 134.702 132.818 134.385 133.21C134.067 133.602 134.128 134.177 134.52 134.494Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.604 132.027C137.93 132.291 138.409 132.241 138.673 131.915C138.937 131.589 138.886 131.111 138.56 130.847C138.234 130.583 137.756 130.633 137.492 130.959C137.228 131.285 137.279 131.763 137.604 132.027Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.287 136.283C141.689 136.608 142.278 136.546 142.603 136.145C142.929 135.743 142.867 135.154 142.465 134.828C142.063 134.503 141.474 134.565 141.149 134.967C140.823 135.368 140.885 135.958 141.287 136.283Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M143.737 133.282C144.013 133.506 144.419 133.463 144.643 133.187C144.867 132.91 144.824 132.504 144.548 132.28C144.271 132.056 143.865 132.099 143.641 132.376C143.417 132.652 143.46 133.058 143.737 133.282Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.598 131.571C144.769 131.71 145.021 131.683 145.159 131.512C145.298 131.341 145.271 131.09 145.1 130.951C144.929 130.812 144.678 130.839 144.539 131.01C144.401 131.181 144.427 131.432 144.598 131.571Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.641 130.114C141.882 130.309 142.234 130.272 142.429 130.031C142.624 129.791 142.587 129.438 142.346 129.244C142.106 129.049 141.753 129.086 141.559 129.326C141.364 129.567 141.401 129.919 141.641 130.114Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.627 137.064C145.897 137.282 146.293 137.241 146.512 136.971C146.731 136.701 146.689 136.305 146.419 136.086C146.149 135.867 145.753 135.909 145.534 136.179C145.316 136.449 145.357 136.845 145.627 137.064Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.917 134.384C141.263 134.664 141.77 134.611 142.05 134.265C142.33 133.919 142.277 133.412 141.931 133.132C141.585 132.852 141.078 132.905 140.798 133.251C140.518 133.597 140.572 134.104 140.917 134.384Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.514 132.813C139.85 133.085 140.342 133.033 140.614 132.697C140.886 132.361 140.835 131.869 140.499 131.597C140.163 131.325 139.67 131.377 139.398 131.712C139.126 132.048 139.178 132.541 139.514 132.813Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.767 131.942C140.119 132.228 140.636 132.173 140.922 131.821C141.207 131.469 141.153 130.952 140.8 130.666C140.448 130.381 139.931 130.435 139.646 130.788C139.36 131.14 139.415 131.657 139.767 131.942Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.111 131.431C140.558 131.793 141.215 131.724 141.578 131.277C141.941 130.829 141.872 130.172 141.424 129.809C140.976 129.447 140.319 129.516 139.956 129.963C139.594 130.411 139.663 131.068 140.111 131.431Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M150.5 129.963C150.674 130.104 150.93 130.077 151.071 129.903C151.213 129.728 151.186 129.472 151.011 129.331C150.837 129.19 150.581 129.216 150.439 129.391C150.298 129.565 150.325 129.821 150.5 129.963Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.426 127.671C145.601 127.812 145.857 127.785 145.998 127.611C146.139 127.436 146.112 127.18 145.938 127.039C145.763 126.898 145.507 126.924 145.366 127.099C145.225 127.273 145.252 127.529 145.426 127.671Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M150.267 126.143C150.55 126.372 150.966 126.328 151.195 126.045C151.424 125.762 151.381 125.346 151.098 125.117C150.815 124.888 150.399 124.931 150.17 125.215C149.94 125.498 149.984 125.913 150.267 126.143Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M146.597 124.167C146.798 124.329 147.092 124.298 147.255 124.097C147.418 123.897 147.387 123.602 147.186 123.439C146.985 123.277 146.69 123.308 146.528 123.508C146.365 123.709 146.396 124.004 146.597 124.167Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M142.598 121.728C142.878 121.955 143.289 121.912 143.515 121.632C143.742 121.352 143.699 120.941 143.419 120.715C143.139 120.488 142.729 120.531 142.502 120.811C142.275 121.091 142.318 121.501 142.598 121.728Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M146.629 120.126C146.965 120.398 147.458 120.346 147.73 120.01C148.002 119.674 147.95 119.181 147.614 118.909C147.278 118.637 146.786 118.689 146.514 119.025C146.242 119.361 146.293 119.854 146.629 120.126Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.213 117.844C145.549 118.116 146.042 118.064 146.314 117.728C146.586 117.392 146.534 116.899 146.198 116.627C145.862 116.355 145.369 116.407 145.097 116.743C144.825 117.079 144.877 117.572 145.213 117.844Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M146.714 115.999C146.908 116.156 147.193 116.126 147.35 115.932C147.508 115.738 147.478 115.453 147.283 115.296C147.089 115.138 146.804 115.168 146.647 115.362C146.489 115.557 146.519 115.842 146.714 115.999Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M146.769 112.825C147.121 113.11 147.638 113.056 147.923 112.704C148.208 112.352 148.154 111.835 147.802 111.549C147.449 111.264 146.933 111.318 146.647 111.671C146.362 112.023 146.416 112.54 146.769 112.825Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M148.574 111.899C149.022 112.261 149.679 112.192 150.042 111.745C150.404 111.297 150.335 110.64 149.888 110.277C149.44 109.915 148.783 109.984 148.42 110.431C148.058 110.879 148.127 111.536 148.574 111.899Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.696 105.619C146.078 105.928 146.638 105.869 146.947 105.487C147.257 105.105 147.198 104.545 146.816 104.236C146.434 103.926 145.874 103.985 145.564 104.367C145.255 104.749 145.314 105.31 145.696 105.619Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.992 106.691C153.371 106.998 153.926 106.939 154.233 106.56C154.54 106.182 154.481 105.626 154.103 105.32C153.724 105.013 153.169 105.071 152.862 105.45C152.555 105.829 152.614 106.384 152.992 106.691Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.086 121.555C129.544 121.925 130.215 121.855 130.586 121.397C130.957 120.939 130.886 120.268 130.429 119.897C129.971 119.527 129.299 119.597 128.929 120.055C128.558 120.513 128.629 121.184 129.086 121.555Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.48 122.634C128.931 122.999 129.593 122.929 129.958 122.478C130.324 122.027 130.254 121.365 129.803 121C129.352 120.635 128.69 120.704 128.325 121.155C127.96 121.606 128.029 122.268 128.48 122.634Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.648 126.025C124.895 126.225 125.257 126.187 125.457 125.94C125.657 125.693 125.619 125.331 125.372 125.131C125.125 124.931 124.763 124.969 124.563 125.216C124.363 125.463 124.401 125.825 124.648 126.025Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M126.784 125.071C127.041 125.279 127.418 125.239 127.626 124.982C127.834 124.726 127.794 124.349 127.537 124.141C127.28 123.933 126.904 123.972 126.696 124.229C126.488 124.486 126.527 124.863 126.784 125.071Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.988 124.141C129.252 124.354 129.638 124.314 129.852 124.05C130.065 123.787 130.024 123.4 129.761 123.187C129.497 122.974 129.111 123.014 128.898 123.278C128.684 123.541 128.725 123.928 128.988 124.141Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.362 121.028C130.691 121.295 131.174 121.244 131.44 120.915C131.707 120.585 131.656 120.102 131.327 119.836C130.998 119.569 130.515 119.62 130.248 119.949C129.982 120.278 130.032 120.761 130.362 121.028Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.408 130.394C130.81 130.72 131.399 130.658 131.725 130.256C132.05 129.854 131.988 129.265 131.586 128.94C131.185 128.615 130.595 128.676 130.27 129.078C129.945 129.48 130.007 130.069 130.408 130.394Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.252 126.596C132.561 126.847 133.015 126.799 133.266 126.49C133.517 126.18 133.469 125.726 133.16 125.476C132.85 125.225 132.396 125.273 132.145 125.582C131.895 125.892 131.942 126.346 132.252 126.596Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.948 128.345C133.396 128.707 134.053 128.638 134.415 128.19C134.778 127.743 134.709 127.086 134.261 126.723C133.813 126.36 133.156 126.429 132.794 126.877C132.431 127.325 132.5 127.982 132.948 128.345Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.44 126.888C135.608 127.024 135.855 126.998 135.991 126.83C136.127 126.663 136.101 126.416 135.933 126.28C135.765 126.144 135.518 126.17 135.382 126.338C135.246 126.506 135.272 126.752 135.44 126.888Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.384 121.059C137.71 121.323 138.188 121.273 138.452 120.947C138.716 120.621 138.666 120.143 138.34 119.879C138.014 119.615 137.536 119.665 137.272 119.991C137.008 120.317 137.058 120.795 137.384 121.059Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.959 126.312C138.345 126.624 138.91 126.565 139.222 126.18C139.534 125.795 139.474 125.229 139.089 124.917C138.704 124.605 138.139 124.665 137.827 125.05C137.515 125.435 137.574 126 137.959 126.312Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.426 129.83C139.594 129.966 139.84 129.94 139.976 129.772C140.112 129.604 140.086 129.358 139.918 129.222C139.75 129.086 139.504 129.111 139.368 129.279C139.232 129.447 139.258 129.694 139.426 129.83Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.673 125.242C139.989 125.498 140.453 125.449 140.709 125.133C140.965 124.817 140.916 124.353 140.6 124.098C140.284 123.842 139.82 123.89 139.564 124.206C139.308 124.522 139.357 124.986 139.673 125.242Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.701 120.892C141.077 121.196 141.627 121.138 141.931 120.763C142.235 120.388 142.178 119.837 141.802 119.533C141.427 119.229 140.876 119.287 140.572 119.662C140.268 120.038 140.326 120.588 140.701 120.892Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.037 123.326C141.333 123.566 141.768 123.52 142.008 123.224C142.248 122.928 142.202 122.493 141.906 122.253C141.61 122.013 141.175 122.059 140.935 122.355C140.695 122.651 140.741 123.086 141.037 123.326Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.635 124.709C141.961 124.973 142.439 124.923 142.703 124.597C142.967 124.271 142.917 123.792 142.591 123.529C142.265 123.265 141.787 123.315 141.523 123.641C141.259 123.967 141.309 124.445 141.635 124.709Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.192 121.645C139.426 121.834 139.769 121.798 139.958 121.564C140.148 121.33 140.112 120.987 139.878 120.798C139.644 120.609 139.301 120.645 139.112 120.879C138.922 121.112 138.959 121.455 139.192 121.645Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M143.561 125.637C143.844 125.866 144.26 125.823 144.489 125.539C144.718 125.256 144.675 124.841 144.391 124.611C144.108 124.382 143.693 124.426 143.463 124.709C143.234 124.992 143.278 125.408 143.561 125.637Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.121 122.309C141.342 122.487 141.665 122.453 141.844 122.233C142.023 122.012 141.989 121.688 141.768 121.51C141.547 121.331 141.224 121.365 141.045 121.586C140.867 121.806 140.901 122.13 141.121 122.309Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.435 118.613C139.642 118.781 139.946 118.749 140.114 118.542C140.282 118.334 140.25 118.03 140.043 117.862C139.836 117.694 139.531 117.726 139.363 117.933C139.195 118.141 139.227 118.445 139.435 118.613Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.498 121.007C147.801 121.252 148.246 121.206 148.491 120.903C148.736 120.6 148.69 120.156 148.387 119.91C148.084 119.665 147.639 119.712 147.394 120.015C147.149 120.317 147.195 120.762 147.498 121.007Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M142.162 115.6C142.449 115.832 142.869 115.788 143.101 115.502C143.333 115.215 143.289 114.795 143.003 114.563C142.716 114.331 142.296 114.375 142.064 114.661C141.832 114.948 141.876 115.368 142.162 115.6Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M146.637 113.587C146.838 113.75 147.133 113.719 147.296 113.518C147.458 113.318 147.427 113.023 147.226 112.86C147.026 112.698 146.731 112.728 146.568 112.929C146.406 113.13 146.437 113.425 146.637 113.587Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M152.165 113.621C152.454 113.856 152.879 113.811 153.114 113.521C153.349 113.232 153.304 112.807 153.014 112.572C152.724 112.337 152.299 112.382 152.065 112.672C151.83 112.961 151.875 113.386 152.165 113.621Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.09 108.435C145.531 108.792 146.179 108.724 146.536 108.283C146.893 107.842 146.825 107.195 146.384 106.837C145.943 106.48 145.296 106.548 144.938 106.989C144.581 107.43 144.649 108.078 145.09 108.435Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.687 104.713C118.885 104.873 119.174 104.842 119.334 104.645C119.494 104.447 119.464 104.157 119.266 103.997C119.069 103.837 118.779 103.868 118.619 104.065C118.459 104.263 118.489 104.553 118.687 104.713Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.322 107.303C127.598 107.527 128.004 107.484 128.228 107.207C128.452 106.931 128.409 106.525 128.133 106.301C127.856 106.077 127.45 106.12 127.226 106.396C127.002 106.673 127.045 107.079 127.322 107.303Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.475 109.271C120.772 109.511 121.206 109.465 121.446 109.169C121.686 108.872 121.641 108.437 121.344 108.197C121.048 107.957 120.613 108.003 120.373 108.299C120.133 108.596 120.179 109.031 120.475 109.271Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.89 111.314C122.163 111.536 122.564 111.494 122.785 111.22C123.007 110.947 122.965 110.546 122.691 110.325C122.418 110.103 122.017 110.146 121.796 110.419C121.575 110.692 121.617 111.093 121.89 111.314Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.877 113.001C119.16 113.23 119.576 113.186 119.805 112.903C120.034 112.62 119.991 112.205 119.708 111.975C119.424 111.746 119.009 111.79 118.78 112.073C118.55 112.356 118.594 112.771 118.877 113.001Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.023 115.923C120.257 116.112 120.6 116.076 120.789 115.842C120.978 115.608 120.942 115.265 120.708 115.076C120.475 114.887 120.132 114.923 119.942 115.157C119.753 115.39 119.789 115.733 120.023 115.923Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.22 115.681C130.407 115.833 130.683 115.804 130.835 115.617C130.987 115.429 130.958 115.153 130.77 115.002C130.582 114.85 130.307 114.878 130.155 115.066C130.003 115.254 130.032 115.529 130.22 115.681Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.758 120.023C122.117 120.313 122.644 120.258 122.934 119.899C123.225 119.54 123.169 119.014 122.811 118.723C122.452 118.432 121.925 118.488 121.634 118.847C121.344 119.205 121.399 119.732 121.758 120.023Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.583 118.058C131.011 118.405 131.639 118.339 131.986 117.911C132.332 117.483 132.266 116.855 131.838 116.508C131.41 116.162 130.782 116.228 130.436 116.656C130.089 117.084 130.155 117.712 130.583 118.058Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.761 122.613C126.202 122.971 126.85 122.903 127.207 122.462C127.564 122.02 127.496 121.373 127.055 121.016C126.614 120.659 125.967 120.727 125.609 121.168C125.252 121.609 125.32 122.256 125.761 122.613Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.379 125.196C124.659 125.423 125.07 125.379 125.296 125.099C125.523 124.82 125.48 124.409 125.2 124.182C124.92 123.956 124.51 123.999 124.283 124.279C124.056 124.559 124.099 124.969 124.379 125.196Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.721 120.442C133.031 120.692 133.485 120.645 133.736 120.335C133.986 120.026 133.938 119.572 133.629 119.321C133.319 119.07 132.865 119.118 132.615 119.428C132.364 119.737 132.412 120.191 132.721 120.442Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.65 122.781C129.992 123.058 130.495 123.006 130.772 122.663C131.049 122.321 130.996 121.818 130.654 121.541C130.312 121.264 129.809 121.317 129.532 121.659C129.255 122.001 129.307 122.504 129.65 122.781Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.208 123.717C130.481 123.938 130.882 123.896 131.103 123.623C131.324 123.349 131.282 122.948 131.009 122.727C130.736 122.506 130.335 122.548 130.113 122.821C129.892 123.095 129.934 123.495 130.208 123.717Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.824 119.983C134.032 120.151 134.336 120.119 134.504 119.911C134.672 119.704 134.64 119.399 134.433 119.231C134.225 119.063 133.921 119.095 133.753 119.303C133.585 119.51 133.617 119.815 133.824 119.983Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M131.43 123.878C131.71 124.104 132.121 124.061 132.347 123.781C132.574 123.501 132.531 123.091 132.251 122.864C131.971 122.638 131.561 122.681 131.334 122.961C131.107 123.24 131.15 123.651 131.43 123.878Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.276 127.366C132.662 127.678 133.227 127.619 133.539 127.234C133.851 126.849 133.791 126.283 133.406 125.971C133.021 125.659 132.456 125.719 132.144 126.104C131.832 126.489 131.891 127.054 132.276 127.366Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.031 123.516C134.35 123.775 134.819 123.726 135.078 123.406C135.336 123.087 135.287 122.618 134.968 122.36C134.648 122.101 134.18 122.15 133.921 122.47C133.662 122.789 133.712 123.258 134.031 123.516Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M136.449 127.211C136.768 127.47 137.236 127.421 137.495 127.101C137.754 126.782 137.705 126.313 137.385 126.055C137.066 125.796 136.597 125.845 136.339 126.165C136.08 126.484 136.129 126.953 136.449 127.211Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.748 118.984C136.14 119.301 136.715 119.241 137.032 118.849C137.349 118.457 137.289 117.882 136.897 117.565C136.505 117.247 135.93 117.308 135.613 117.7C135.296 118.091 135.356 118.666 135.748 118.984Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.577 118.955C137.903 119.219 138.382 119.168 138.646 118.843C138.91 118.517 138.859 118.038 138.533 117.774C138.207 117.51 137.729 117.561 137.465 117.887C137.201 118.213 137.251 118.691 137.577 118.955Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.714 124.249C140.889 124.391 141.145 124.364 141.286 124.189C141.427 124.015 141.4 123.759 141.226 123.617C141.051 123.476 140.795 123.503 140.654 123.678C140.513 123.852 140.54 124.108 140.714 124.249Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M142.009 124.222C142.256 124.422 142.619 124.384 142.819 124.137C143.018 123.89 142.98 123.528 142.733 123.328C142.487 123.128 142.124 123.166 141.924 123.413C141.724 123.66 141.762 124.022 142.009 124.222Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.365 117.58C141.608 117.777 141.966 117.74 142.163 117.496C142.361 117.252 142.323 116.895 142.079 116.698C141.836 116.5 141.478 116.538 141.281 116.781C141.084 117.025 141.121 117.383 141.365 117.58Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.972 119.962C130.354 120.271 130.914 120.213 131.223 119.831C131.532 119.449 131.474 118.888 131.092 118.579C130.71 118.27 130.149 118.329 129.84 118.711C129.531 119.092 129.59 119.653 129.972 119.962Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.446 122.084C129.825 122.39 130.381 122.332 130.687 121.953C130.994 121.575 130.936 121.019 130.557 120.713C130.178 120.406 129.623 120.464 129.316 120.843C129.009 121.222 129.068 121.777 129.446 122.084Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.705 125.736C124.002 125.976 124.437 125.931 124.677 125.634C124.917 125.338 124.871 124.903 124.574 124.663C124.278 124.423 123.843 124.469 123.603 124.765C123.363 125.061 123.409 125.496 123.705 125.736Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M126.049 125.512C126.332 125.741 126.748 125.697 126.977 125.414C127.206 125.131 127.163 124.716 126.879 124.486C126.596 124.257 126.181 124.301 125.951 124.584C125.722 124.867 125.766 125.282 126.049 125.512Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.401 124.615C133.72 124.873 134.189 124.824 134.447 124.505C134.706 124.185 134.657 123.717 134.337 123.458C134.018 123.199 133.549 123.249 133.291 123.568C133.032 123.887 133.081 124.356 133.401 124.615Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.322 125.045C135.733 125.379 136.337 125.315 136.671 124.904C137.004 124.492 136.94 123.888 136.529 123.555C136.117 123.222 135.513 123.285 135.18 123.697C134.847 124.108 134.91 124.712 135.322 125.045Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.728 125.887C135.153 126.231 135.776 126.166 136.12 125.741C136.464 125.316 136.399 124.693 135.974 124.349C135.549 124.005 134.926 124.071 134.582 124.496C134.238 124.92 134.304 125.543 134.728 125.887Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.877 125.445C135.219 125.723 135.722 125.67 135.999 125.327C136.276 124.985 136.224 124.483 135.881 124.205C135.539 123.928 135.036 123.981 134.759 124.323C134.482 124.666 134.535 125.168 134.877 125.445Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M131.277 126.479C131.455 126.623 131.716 126.595 131.86 126.417C132.004 126.24 131.977 125.979 131.799 125.835C131.621 125.691 131.36 125.718 131.216 125.896C131.072 126.074 131.1 126.335 131.277 126.479Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.01 127.752C130.378 128.051 130.919 127.994 131.218 127.625C131.517 127.256 131.46 126.715 131.091 126.416C130.722 126.118 130.181 126.175 129.883 126.543C129.584 126.912 129.641 127.453 130.01 127.752Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.376 121.415C129.59 121.588 129.904 121.555 130.078 121.341C130.251 121.127 130.218 120.813 130.004 120.64C129.79 120.466 129.476 120.499 129.303 120.713C129.129 120.927 129.162 121.241 129.376 121.415Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.032 125.313C132.434 125.639 133.023 125.577 133.349 125.175C133.674 124.773 133.612 124.184 133.21 123.859C132.809 123.533 132.219 123.595 131.894 123.997C131.569 124.399 131.631 124.988 132.032 125.313Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.633 121.595C133.949 121.851 134.413 121.802 134.669 121.486C134.925 121.17 134.876 120.706 134.56 120.45C134.244 120.194 133.781 120.243 133.525 120.559C133.269 120.875 133.317 121.339 133.633 121.595Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.541 125.973C137.824 126.203 138.239 126.159 138.469 125.876C138.698 125.593 138.654 125.177 138.371 124.948C138.088 124.719 137.673 124.762 137.443 125.045C137.214 125.329 137.258 125.744 137.541 125.973Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.591 122.411C137.894 122.657 138.338 122.61 138.584 122.307C138.829 122.004 138.782 121.56 138.479 121.314C138.176 121.069 137.732 121.116 137.487 121.419C137.241 121.721 137.288 122.166 137.591 122.411Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.128 124.19C141.398 124.409 141.794 124.367 142.013 124.097C142.232 123.827 142.19 123.431 141.92 123.212C141.65 122.994 141.254 123.035 141.035 123.305C140.817 123.575 140.858 123.971 141.128 124.19Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M141.985 121.923C142.232 122.123 142.594 122.085 142.794 121.838C142.994 121.591 142.956 121.228 142.709 121.028C142.462 120.828 142.1 120.866 141.9 121.113C141.7 121.36 141.738 121.723 141.985 121.923Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M143.71 121.829C144.017 122.077 144.466 122.03 144.714 121.724C144.962 121.417 144.915 120.968 144.608 120.72C144.302 120.472 143.853 120.519 143.605 120.826C143.357 121.132 143.404 121.581 143.71 121.829Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.962 120.77C145.14 120.914 145.401 120.886 145.545 120.708C145.689 120.531 145.661 120.27 145.484 120.126C145.306 119.982 145.045 120.009 144.901 120.187C144.757 120.365 144.784 120.626 144.962 120.77Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.435 121.667C147.738 121.912 148.182 121.865 148.427 121.562C148.673 121.259 148.626 120.815 148.323 120.57C148.02 120.324 147.576 120.371 147.33 120.674C147.085 120.977 147.132 121.421 147.435 121.667Z" fill={props.bgColor || defaultIconColor}/>
        </g>}
        </g>
        <path d="M136.682 126.21C134.693 129.439 132.331 132.421 129.811 135.263C124.664 141.163 118.918 146.561 112.257 150.638C97.0016 159.985 77.6066 161.628 60.4172 156.68C55.2455 155.196 50.3115 153.19 45.7219 150.446C31.3881 141.769 22.4791 126.338 21.7757 109.637L21.9824 109.595C56.9399 100.74 92.1155 92.5293 127.394 85.0301L33.0068 107.468C36.2954 122.908 47.1581 136.517 61.4578 143.133C75.7575 149.749 93.1542 149.184 106.994 141.656C120.833 134.128 130.702 119.723 132.784 104.11C133.698 97.4182 133.205 90.6908 132.769 83.9301C135.87 83.2955 138.938 82.6031 142.039 81.9685C146.946 96.4735 144.868 113.162 136.682 126.21Z" fill="#F9A02B"/>
        <path d="M107.018 141.564C93.1785 149.092 75.7819 149.658 61.4822 143.042C47.1825 136.426 36.3198 122.816 33.0311 107.377L127.418 84.9389C129.221 84.5915 130.991 84.1863 132.851 83.8055C133.345 90.5328 133.78 97.2935 132.867 103.986C130.784 119.598 120.915 134.003 107.018 141.564Z" fill="#FFD89F"/>
        <path d="M69.1671 104.958C65.889 108.623 60.258 109.948 57.6692 114.139C56.5271 116.032 56.2078 118.682 57.7957 120.231C58.8432 121.244 60.5329 121.502 61.9134 121.09C63.2939 120.678 64.4897 119.68 65.3786 118.55C68.2098 114.912 68.544 110.018 68.8382 105.456" fill="white"/>
        <path d="M99.2249 99.3293C103.03 102.45 104.561 108.038 108.835 110.502C110.752 111.553 113.426 111.782 114.942 110.136C115.922 109.031 116.122 107.374 115.619 105.969C115.116 104.564 114.093 103.46 112.905 102.604C109.185 99.8975 104.301 99.7123 99.6896 99.6004" fill="white"/>
        <path d="M85.2187 105.142C83.395 110.125 82.6652 115.401 83.1027 120.696C83.2857 122.748 83.6843 124.907 85.2055 126.34C86.3441 127.378 88.0428 127.785 89.6056 127.422C91.135 127.001 92.413 125.878 92.9862 124.468C94.0014 121.955 92.9044 119.121 91.7431 116.709C89.8722 112.935 87.8278 109.261 85.4945 105.753" fill="white"/>
        <path d="M132.794 83.8391C130.991 84.1865 129.221 84.5916 127.36 84.9724L132.794 83.8391Z" fill="url(#paint0_linear_2_340)"/>
        {props.showDetails &&
        <g style={{
            mixBlendMode: 'overlay'
        }}>
        <path d="M142.812 121.746C142.971 121.654 143.026 121.45 142.934 121.291C142.842 121.131 142.638 121.076 142.479 121.169C142.319 121.261 142.265 121.464 142.357 121.624C142.449 121.783 142.653 121.838 142.812 121.746Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M147.981 128.163C148.364 127.942 148.496 127.452 148.275 127.069C148.053 126.686 147.564 126.555 147.181 126.776C146.798 126.997 146.666 127.487 146.887 127.87C147.109 128.253 147.598 128.384 147.981 128.163Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M145.937 129.96C146.192 129.813 146.279 129.486 146.132 129.231C145.985 128.976 145.658 128.888 145.403 129.036C145.147 129.183 145.06 129.509 145.207 129.765C145.355 130.02 145.681 130.108 145.937 129.96Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.903 129.784C141.19 129.618 141.289 129.251 141.123 128.963C140.957 128.676 140.59 128.578 140.303 128.744C140.015 128.909 139.917 129.277 140.083 129.564C140.249 129.851 140.616 129.95 140.903 129.784Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M144.604 133.658C144.955 133.455 145.075 133.006 144.873 132.655C144.67 132.304 144.221 132.184 143.87 132.386C143.519 132.589 143.398 133.038 143.601 133.389C143.804 133.74 144.253 133.861 144.604 133.658Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.845 129.699C136.101 129.551 136.188 129.225 136.041 128.969C135.893 128.714 135.567 128.627 135.311 128.774C135.056 128.922 134.969 129.248 135.116 129.503C135.263 129.759 135.59 129.846 135.845 129.699Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.144 134.344C139.591 134.086 139.744 133.515 139.486 133.068C139.228 132.621 138.656 132.468 138.21 132.726C137.763 132.984 137.61 133.555 137.868 134.002C138.126 134.449 138.697 134.602 139.144 134.344Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.882 137.231C138.137 137.083 138.225 136.757 138.077 136.501C137.93 136.246 137.603 136.158 137.348 136.306C137.093 136.453 137.005 136.78 137.153 137.035C137.3 137.29 137.627 137.378 137.882 137.231Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M138.015 142.933C138.462 142.675 138.615 142.104 138.357 141.657C138.099 141.21 137.527 141.057 137.081 141.315C136.634 141.573 136.481 142.144 136.739 142.591C136.997 143.038 137.568 143.191 138.015 142.933Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.39 138.936C130.678 138.77 130.776 138.403 130.61 138.115C130.444 137.828 130.077 137.73 129.79 137.895C129.502 138.061 129.404 138.429 129.57 138.716C129.736 139.003 130.103 139.102 130.39 138.936Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.631 145.617C133.822 145.507 133.888 145.262 133.777 145.071C133.667 144.88 133.422 144.814 133.231 144.925C133.04 145.035 132.974 145.28 133.085 145.471C133.195 145.662 133.44 145.728 133.631 145.617Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.746 146.359C130.97 146.23 131.046 145.945 130.917 145.721C130.788 145.498 130.502 145.421 130.279 145.55C130.056 145.679 129.979 145.965 130.108 146.188C130.237 146.412 130.523 146.488 130.746 146.359Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.824 143.844C125.015 143.733 125.081 143.489 124.971 143.297C124.86 143.106 124.615 143.041 124.424 143.151C124.233 143.262 124.167 143.506 124.278 143.698C124.388 143.889 124.633 143.954 124.824 143.844Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.621 150.821C128.813 150.711 128.878 150.466 128.768 150.275C128.657 150.084 128.413 150.018 128.221 150.129C128.03 150.239 127.965 150.484 128.075 150.675C128.185 150.866 128.43 150.932 128.621 150.821Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.232 148.694C123.551 148.509 123.661 148.101 123.477 147.782C123.292 147.463 122.884 147.354 122.565 147.538C122.246 147.722 122.136 148.13 122.321 148.45C122.505 148.769 122.913 148.878 123.232 148.694Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.525 145.209C119.876 145.006 119.996 144.557 119.794 144.206C119.591 143.855 119.142 143.735 118.791 143.937C118.44 144.14 118.32 144.589 118.522 144.94C118.725 145.291 119.174 145.412 119.525 145.209Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.586 150.646C121.937 150.444 122.057 149.995 121.854 149.644C121.652 149.292 121.203 149.172 120.852 149.375C120.5 149.578 120.38 150.027 120.583 150.378C120.786 150.729 121.235 150.849 121.586 150.646Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.672 151.867C121.087 151.628 121.229 151.097 120.99 150.682C120.75 150.267 120.219 150.125 119.805 150.365C119.39 150.604 119.247 151.135 119.487 151.55C119.727 151.965 120.257 152.107 120.672 151.867Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.258 147.288C117.705 147.03 117.858 146.459 117.6 146.012C117.342 145.565 116.771 145.412 116.324 145.67C115.877 145.928 115.724 146.499 115.982 146.946C116.24 147.393 116.811 147.546 117.258 147.288Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.288 154.94C120.576 154.774 120.674 154.406 120.508 154.119C120.342 153.832 119.975 153.734 119.688 153.899C119.401 154.065 119.302 154.433 119.468 154.72C119.634 155.007 120.001 155.106 120.288 154.94Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.484 148.621C115.708 148.492 115.784 148.206 115.655 147.983C115.526 147.759 115.241 147.683 115.017 147.812C114.794 147.941 114.717 148.226 114.846 148.45C114.975 148.673 115.261 148.75 115.484 148.621Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.061 156.688C118.508 156.43 118.661 155.859 118.403 155.412C118.145 154.965 117.574 154.812 117.127 155.07C116.68 155.328 116.527 155.899 116.785 156.346C117.043 156.793 117.614 156.946 118.061 156.688Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.9 154.815C115.155 154.667 115.242 154.341 115.095 154.085C114.948 153.83 114.621 153.743 114.366 153.89C114.11 154.037 114.023 154.364 114.17 154.619C114.318 154.875 114.644 154.962 114.9 154.815Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M112.627 155.818C112.978 155.616 113.099 155.167 112.896 154.815C112.693 154.464 112.244 154.344 111.893 154.547C111.542 154.749 111.422 155.198 111.625 155.55C111.827 155.901 112.276 156.021 112.627 155.818Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.577 159.737C111.768 159.627 111.833 159.382 111.723 159.191C111.613 159 111.368 158.934 111.177 159.045C110.985 159.155 110.92 159.4 111.03 159.591C111.141 159.782 111.385 159.848 111.577 159.737Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.774 160.355C109.189 160.115 109.331 159.585 109.091 159.17C108.852 158.755 108.321 158.613 107.906 158.852C107.491 159.092 107.349 159.622 107.589 160.037C107.828 160.452 108.359 160.594 108.774 160.355Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.291 160.324C106.642 160.122 106.762 159.673 106.559 159.322C106.357 158.97 105.908 158.85 105.557 159.053C105.206 159.256 105.085 159.705 105.288 160.056C105.491 160.407 105.94 160.527 106.291 160.324Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.415 160.676C105.702 160.51 105.8 160.143 105.635 159.856C105.469 159.568 105.101 159.47 104.814 159.636C104.527 159.802 104.428 160.169 104.594 160.456C104.76 160.743 105.127 160.842 105.415 160.676Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M104.346 162.295C104.729 162.074 104.86 161.584 104.639 161.201C104.418 160.818 103.928 160.687 103.545 160.908C103.162 161.129 103.031 161.619 103.252 162.002C103.473 162.385 103.963 162.516 104.346 162.295Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M101.723 156.951C102.042 156.767 102.152 156.359 101.967 156.04C101.783 155.72 101.375 155.611 101.056 155.795C100.737 155.98 100.627 156.388 100.811 156.707C100.996 157.026 101.404 157.135 101.723 156.951Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M98.6294 155.731C98.7888 155.639 98.8435 155.435 98.7514 155.276C98.6594 155.116 98.4555 155.062 98.296 155.154C98.1366 155.246 98.0819 155.45 98.174 155.609C98.2661 155.768 98.4699 155.823 98.6294 155.731Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M98.8507 164.389C99.1699 164.204 99.2793 163.796 99.095 163.477C98.9107 163.158 98.5026 163.048 98.1834 163.233C97.8642 163.417 97.7549 163.825 97.9391 164.144C98.1234 164.464 98.5316 164.573 98.8507 164.389Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.4804 161.093C93.8315 160.891 93.9518 160.442 93.7491 160.091C93.5464 159.74 93.0974 159.619 92.7463 159.822C92.3952 160.025 92.2749 160.474 92.4776 160.825C92.6803 161.176 93.1293 161.296 93.4804 161.093Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M92.0036 158.401C92.4186 158.162 92.5607 157.631 92.3212 157.216C92.0816 156.801 91.551 156.659 91.1361 156.899C90.7211 157.138 90.579 157.669 90.8185 158.084C91.0581 158.499 91.5887 158.641 92.0036 158.401Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.8832 164.867C92.1705 164.701 92.2689 164.333 92.103 164.046C91.9372 163.759 91.5699 163.661 91.2826 163.826C90.9953 163.992 90.8969 164.36 91.0628 164.647C91.2286 164.934 91.5959 165.033 91.8832 164.867Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M90.863 166.303C91.2779 166.064 91.4201 165.533 91.1805 165.118C90.941 164.703 90.4104 164.561 89.9954 164.801C89.5805 165.04 89.4383 165.571 89.6779 165.986C89.9175 166.401 90.4481 166.543 90.863 166.303Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M88.8261 164.243C89.2091 164.022 89.3404 163.532 89.1192 163.149C88.8981 162.766 88.4083 162.635 88.0253 162.856C87.6423 163.077 87.5111 163.567 87.7322 163.95C87.9533 164.333 88.4431 164.464 88.8261 164.243Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.2523 158.447C87.4757 158.318 87.5523 158.033 87.4233 157.809C87.2943 157.586 87.0086 157.509 86.7852 157.638C86.5617 157.767 86.4852 158.053 86.6142 158.276C86.7432 158.5 87.0289 158.576 87.2523 158.447Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.5314 158.94C84.7867 158.792 84.8742 158.466 84.7268 158.21C84.5794 157.955 84.2529 157.868 83.9975 158.015C83.7422 158.162 83.6547 158.489 83.8021 158.744C83.9495 159 84.276 159.087 84.5314 158.94Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.5822 159.565C83.9333 159.362 84.0536 158.913 83.8509 158.562C83.6482 158.211 83.1992 158.09 82.8481 158.293C82.497 158.496 82.3767 158.945 82.5794 159.296C82.7822 159.647 83.2311 159.767 83.5822 159.565Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.5382 165.637C80.7936 165.49 80.881 165.163 80.7336 164.908C80.5862 164.653 80.2597 164.565 80.0043 164.713C79.749 164.86 79.6615 165.186 79.8089 165.442C79.9563 165.697 80.2829 165.785 80.5382 165.637Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.6542 157.439C77.9415 157.273 78.0399 156.906 77.874 156.619C77.7082 156.331 77.3409 156.233 77.0536 156.399C76.7663 156.565 76.6679 156.932 76.8338 157.219C76.9996 157.506 77.3669 157.605 77.6542 157.439Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.8634 160.477C76.1188 160.329 76.2063 160.003 76.0588 159.747C75.9114 159.492 75.5849 159.404 75.3295 159.552C75.0742 159.699 74.9867 160.026 75.1341 160.281C75.2816 160.537 75.6081 160.624 75.8634 160.477Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.5812 165.068C72.8046 164.939 72.8811 164.654 72.7521 164.43C72.6232 164.207 72.3375 164.13 72.114 164.259C71.8906 164.388 71.814 164.674 71.943 164.897C72.072 165.121 72.3577 165.197 72.5812 165.068Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.1718 164.226C72.5548 164.005 72.6861 163.515 72.4649 163.132C72.2438 162.749 71.754 162.618 71.371 162.839C70.988 163.06 70.8568 163.55 71.0779 163.933C71.299 164.316 71.7888 164.447 72.1718 164.226Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.9221 156.856C71.337 156.617 71.4792 156.086 71.2396 155.671C71.0001 155.256 70.4695 155.114 70.0545 155.354C69.6396 155.593 69.4974 156.124 69.737 156.539C69.9766 156.954 70.5071 157.096 70.9221 156.856Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.9 162.569C67.1873 162.403 67.2857 162.036 67.1199 161.749C66.954 161.461 66.5867 161.363 66.2994 161.529C66.0122 161.695 65.9137 162.062 66.0796 162.349C66.2455 162.637 66.6128 162.735 66.9 162.569Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.9201 155.931C67.3031 155.71 67.4344 155.22 67.2132 154.837C66.9921 154.454 66.5023 154.323 66.1193 154.544C65.7363 154.765 65.605 155.255 65.8262 155.638C66.0473 156.021 66.5371 156.152 66.9201 155.931Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.7172 160.13C62.9406 160.001 63.0171 159.715 62.8881 159.491C62.7591 159.268 62.4734 159.191 62.25 159.32C62.0266 159.449 61.95 159.735 62.079 159.959C62.208 160.182 62.4937 160.259 62.7172 160.13Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.9067 154.318C63.2578 154.115 63.3781 153.666 63.1754 153.315C62.9726 152.964 62.5237 152.844 62.1726 153.046C61.8215 153.249 61.7012 153.698 61.9039 154.049C62.1066 154.4 62.5556 154.52 62.9067 154.318Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.9743 156.049C59.3254 155.846 59.4457 155.397 59.243 155.046C59.0403 154.695 58.5913 154.575 58.2402 154.777C57.8891 154.98 57.7688 155.429 57.9715 155.78C58.1742 156.131 58.6232 156.251 58.9743 156.049Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.195 152.029C58.61 151.79 58.7521 151.259 58.5126 150.844C58.273 150.429 57.7424 150.287 57.3275 150.527C56.9125 150.766 56.7704 151.297 57.0099 151.712C57.2495 152.127 57.7801 152.269 58.195 152.029Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.9531 148.277C57.4 148.019 57.5531 147.448 57.2951 147.001C57.0371 146.554 56.4657 146.401 56.0188 146.659C55.572 146.917 55.4189 147.488 55.6769 147.935C55.9349 148.382 56.5062 148.535 56.9531 148.277Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.259 154.03C51.7059 153.772 51.859 153.201 51.601 152.754C51.343 152.307 50.7716 152.154 50.3247 152.412C49.8779 152.67 49.7248 153.241 49.9828 153.688C50.2408 154.135 50.8122 154.288 51.259 154.03Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.66 151.525C50.9473 151.359 51.0457 150.992 50.8799 150.704C50.714 150.417 50.3467 150.319 50.0594 150.485C49.7722 150.65 49.6738 151.018 49.8396 151.305C50.0055 151.592 50.3728 151.691 50.66 151.525Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.2941 145.148C51.5813 144.983 51.6798 144.615 51.5139 144.328C51.3481 144.041 50.9807 143.942 50.6935 144.108C50.4062 144.274 50.3078 144.641 50.4736 144.929C50.6395 145.216 51.0068 145.314 51.2941 145.148Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.9001 148.762C43.2192 148.577 43.3286 148.169 43.1443 147.85C42.96 147.531 42.5519 147.422 42.2327 147.606C41.9135 147.79 41.8042 148.198 41.9885 148.517C42.1727 148.837 42.5809 148.946 42.9001 148.762Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.3332 142.696C46.7482 142.456 46.8903 141.925 46.6508 141.51C46.4112 141.096 45.8806 140.953 45.4657 141.193C45.0507 141.433 44.9086 141.963 45.1481 142.378C45.3877 142.793 45.9183 142.935 46.3332 142.696Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.3444 145.806C39.6955 145.603 39.8158 145.154 39.6131 144.803C39.4104 144.452 38.9614 144.332 38.6103 144.535C38.2592 144.737 38.1389 145.186 38.3416 145.537C38.5444 145.888 38.9933 146.009 39.3444 145.806Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.4545 142.66C39.6779 142.531 39.7544 142.245 39.6254 142.022C39.4964 141.798 39.2107 141.722 38.9873 141.851C38.7639 141.98 38.6873 142.266 38.8163 142.489C38.9453 142.712 39.231 142.789 39.4545 142.66Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.202 142.227C38.3934 142.116 38.4589 141.871 38.3485 141.68C38.238 141.489 37.9933 141.423 37.802 141.534C37.6107 141.644 37.5451 141.889 37.6556 142.08C37.7661 142.271 38.0107 142.337 38.202 142.227Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.579 140.082C36.9301 139.879 37.0504 139.43 36.8477 139.079C36.645 138.728 36.1961 138.608 35.845 138.81C35.4939 139.013 35.3736 139.462 35.5763 139.813C35.779 140.164 36.2279 140.285 36.579 140.082Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.2162 135.977C38.5673 135.775 38.6876 135.326 38.4849 134.975C38.2822 134.624 37.8333 134.503 37.4822 134.706C37.1311 134.909 37.0108 135.358 37.2135 135.709C37.4162 136.06 37.8651 136.18 38.2162 135.977Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.8192 134.895C36.1384 134.711 36.2478 134.303 36.0635 133.984C35.8792 133.665 35.4711 133.555 35.1519 133.74C34.8327 133.924 34.7234 134.332 34.9076 134.651C35.0919 134.97 35.5001 135.08 35.8192 134.895Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.6833 131.854C37.0344 131.651 37.1547 131.202 36.952 130.851C36.7493 130.5 36.3003 130.38 35.9492 130.582C35.5981 130.785 35.4778 131.234 35.6805 131.585C35.8832 131.936 36.3322 132.057 36.6833 131.854Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.14 130.662C31.4592 130.477 31.5686 130.069 31.3843 129.75C31.2 129.431 30.7919 129.321 30.4727 129.506C30.1535 129.69 30.0442 130.098 30.2284 130.417C30.4127 130.736 30.8209 130.846 31.14 130.662Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.3065 126.406C33.5299 126.277 33.6065 125.991 33.4775 125.767C33.3485 125.544 33.0628 125.468 32.8394 125.597C32.6159 125.726 32.5394 126.011 32.6684 126.235C32.7974 126.458 33.0831 126.535 33.3065 126.406Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.1427 125.998C30.4619 125.813 30.5713 125.405 30.387 125.086C30.2027 124.767 29.7946 124.658 29.4754 124.842C29.1562 125.026 29.0469 125.434 29.2311 125.753C29.4154 126.073 29.8236 126.182 30.1427 125.998Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.2743 125.034C27.6254 124.831 27.7457 124.382 27.543 124.031C27.3403 123.68 26.8914 123.56 26.5403 123.762C26.1892 123.965 26.0689 124.414 26.2716 124.765C26.4743 125.116 26.9232 125.237 27.2743 125.034Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.6984 121.479C33.9537 121.331 34.0412 121.005 33.8938 120.75C33.7464 120.494 33.4198 120.407 33.1645 120.554C32.9091 120.702 32.8217 121.028 32.9691 121.283C33.1165 121.539 33.443 121.626 33.6984 121.479Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.5067 121.432C27.794 121.266 27.8924 120.899 27.7266 120.611C27.5607 120.324 27.1934 120.226 26.9061 120.392C26.6189 120.557 26.5204 120.925 26.6863 121.212C26.8521 121.499 27.2195 121.598 27.5067 121.432Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.6233 117.746C33.7827 117.654 33.8373 117.45 33.7453 117.29C33.6532 117.131 33.4494 117.076 33.2899 117.168C33.1305 117.26 33.0758 117.464 33.1679 117.624C33.26 117.783 33.4638 117.838 33.6233 117.746Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.3396 116.059C31.499 115.967 31.5537 115.763 31.4616 115.604C31.3695 115.444 31.1657 115.39 31.0062 115.482C30.8468 115.574 30.7922 115.778 30.8842 115.937C30.9763 116.097 31.1801 116.151 31.3396 116.059Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.7111 115.111C28.9024 115 28.968 114.755 28.8575 114.564C28.747 114.373 28.5024 114.307 28.311 114.418C28.1197 114.528 28.0541 114.773 28.1646 114.964C28.2751 115.155 28.5197 115.221 28.7111 115.111Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.007 114.706C23.2942 114.54 23.3927 114.173 23.2268 113.886C23.061 113.598 22.6936 113.5 22.4064 113.666C22.1191 113.832 22.0207 114.199 22.1865 114.486C22.3524 114.773 22.7197 114.872 23.007 114.706Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.6901 109.999C29.8814 109.888 29.947 109.644 29.8365 109.453C29.726 109.261 29.4814 109.196 29.29 109.306C29.0987 109.417 29.0332 109.661 29.1436 109.853C29.2541 110.044 29.4987 110.109 29.6901 109.999Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.0321 158.288C58.3513 158.104 58.4607 157.696 58.2764 157.376C58.0921 157.057 57.684 156.948 57.3648 157.132C57.0456 157.316 56.9363 157.725 57.1205 158.044C57.3048 158.363 57.713 158.472 58.0321 158.288Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.2914 159.947C55.5148 159.818 55.5914 159.533 55.4624 159.309C55.3334 159.086 55.0477 159.009 54.8242 159.138C54.6008 159.267 54.5243 159.553 54.6532 159.776C54.7822 160 55.0679 160.076 55.2914 159.947Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.2676 156.57C55.427 156.478 55.4816 156.274 55.3896 156.115C55.2975 155.955 55.0936 155.9 54.9342 155.993C54.7748 156.085 54.7201 156.288 54.8122 156.448C54.9042 156.607 55.1081 156.662 55.2676 156.57Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.6735 153.138C54.9288 152.99 55.0163 152.664 54.8689 152.408C54.7215 152.153 54.3949 152.066 54.1396 152.213C53.8842 152.36 53.7968 152.687 53.9442 152.942C54.0916 153.198 54.4181 153.285 54.6735 153.138Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.3552 157.403C50.7063 157.201 50.8266 156.752 50.6238 156.401C50.4211 156.05 49.9722 155.929 49.6211 156.132C49.27 156.335 49.1497 156.784 49.3524 157.135C49.5551 157.486 50.0041 157.606 50.3552 157.403Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.8181 156.479C48.265 156.221 48.4181 155.65 48.1601 155.203C47.9021 154.756 47.3307 154.603 46.8838 154.861C46.437 155.119 46.2839 155.69 46.5418 156.137C46.7998 156.584 47.3712 156.737 47.8181 156.479Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.1352 158.105C45.5182 157.884 45.6495 157.394 45.4283 157.011C45.2072 156.628 44.7174 156.497 44.3344 156.718C43.9514 156.939 43.8201 157.429 44.0413 157.812C44.2624 158.195 44.7522 158.326 45.1352 158.105Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.224 149.848C46.5432 149.664 46.6526 149.255 46.4683 148.936C46.284 148.617 45.8759 148.508 45.5567 148.692C45.2375 148.876 45.1281 149.284 45.3124 149.604C45.4967 149.923 45.9048 150.032 46.224 149.848Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.5411 151.474C43.7965 151.326 43.884 151 43.7365 150.745C43.5891 150.489 43.2626 150.402 43.0073 150.549C42.7519 150.697 42.6644 151.023 42.8119 151.279C42.9593 151.534 43.2858 151.621 43.5411 151.474Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.7685 151.88C40.1515 151.659 40.2828 151.169 40.0616 150.786C39.8405 150.403 39.3507 150.272 38.9677 150.493C38.5847 150.714 38.4534 151.204 38.6746 151.587C38.8957 151.97 39.3855 152.101 39.7685 151.88Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.6767 144.769C44.0597 144.547 44.1909 144.058 43.9698 143.675C43.7487 143.292 43.2589 143.16 42.8759 143.382C42.4929 143.603 42.3616 144.092 42.5828 144.475C42.8039 144.859 43.2937 144.99 43.6767 144.769Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.1825 146.324C40.4059 146.195 40.4825 145.909 40.3535 145.686C40.2245 145.462 39.9388 145.386 39.7153 145.515C39.4919 145.644 39.4154 145.929 39.5444 146.153C39.6734 146.376 39.9591 146.453 40.1825 146.324Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.7548 142.911C41.1698 142.671 41.312 142.141 41.0724 141.726C40.8328 141.311 40.3022 141.169 39.8873 141.408C39.4724 141.648 39.3302 142.178 39.5698 142.593C39.8093 143.008 40.3399 143.15 40.7548 142.911Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.8307 142.518C38.022 142.408 38.0876 142.163 37.9771 141.972C37.8666 141.78 37.622 141.715 37.4307 141.825C37.2393 141.936 37.1738 142.18 37.2842 142.372C37.3947 142.563 37.6394 142.628 37.8307 142.518Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.8256 139.709C37.049 139.58 37.1255 139.294 36.9965 139.07C36.8675 138.847 36.5818 138.77 36.3584 138.899C36.135 139.028 36.0584 139.314 36.1874 139.538C36.3164 139.761 36.6021 139.838 36.8256 139.709Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.6051 138.526C36.8924 138.36 36.9908 137.993 36.825 137.705C36.6591 137.418 36.2918 137.32 36.0045 137.486C35.7172 137.651 35.6188 138.019 35.7847 138.306C35.9505 138.593 36.3179 138.692 36.6051 138.526Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.3505 140.097C33.7016 139.894 33.8219 139.445 33.6192 139.094C33.4165 138.743 32.9675 138.622 32.6164 138.825C32.2653 139.028 32.145 139.477 32.3477 139.828C32.5505 140.179 32.9994 140.299 33.3505 140.097Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.5295 142.957C28.9125 142.736 29.0437 142.246 28.8226 141.863C28.6015 141.48 28.1117 141.349 27.7287 141.57C27.3457 141.791 27.2144 142.281 27.4356 142.664C27.6567 143.047 28.1465 143.178 28.5295 142.957Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.433 136.773C32.6243 136.662 32.6899 136.418 32.5794 136.226C32.4689 136.035 32.2243 135.969 32.033 136.08C31.8416 136.19 31.7761 136.435 31.8865 136.626C31.997 136.818 32.2417 136.883 32.433 136.773Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.6506 140.768C28.0655 140.528 28.2077 139.997 27.9681 139.583C27.7286 139.168 27.198 139.025 26.7831 139.265C26.3681 139.505 26.2259 140.035 26.4655 140.45C26.7051 140.865 27.2357 141.007 27.6506 140.768Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.5087 135.034C32.796 134.869 32.8944 134.501 32.7285 134.214C32.5627 133.927 32.1953 133.828 31.9081 133.994C31.6208 134.16 31.5224 134.527 31.6882 134.815C31.8541 135.102 32.2214 135.2 32.5087 135.034Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.8889 139.588C25.3038 139.348 25.446 138.818 25.2064 138.403C24.9669 137.988 24.4363 137.846 24.0213 138.085C23.6064 138.325 23.4642 138.855 23.7038 139.27C23.9434 139.685 24.4739 139.827 24.8889 139.588Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.0192 131.925C30.2105 131.814 30.2761 131.569 30.1656 131.378C30.0551 131.187 29.8105 131.121 29.6191 131.232C29.4278 131.342 29.3623 131.587 29.4727 131.778C29.5832 131.969 29.8278 132.035 30.0192 131.925Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.1171 134.253C22.4682 134.05 22.5885 133.601 22.3858 133.25C22.1831 132.899 21.7341 132.779 21.383 132.982C21.0319 133.184 20.9116 133.633 21.1144 133.984C21.3171 134.335 21.766 134.456 22.1171 134.253Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.0319 131.836C22.3191 131.671 22.4176 131.303 22.2517 131.016C22.0859 130.729 21.7185 130.63 21.4313 130.796C21.144 130.962 21.0456 131.329 21.2114 131.617C21.3773 131.904 21.7446 132.002 22.0319 131.836Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.3733 128.018C24.5327 127.926 24.5873 127.722 24.4953 127.563C24.4032 127.403 24.1994 127.348 24.0399 127.44C23.8805 127.533 23.8258 127.736 23.9179 127.896C24.01 128.055 24.2138 128.11 24.3733 128.018Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.5214 126.545C22.8086 126.379 22.9071 126.012 22.7412 125.724C22.5754 125.437 22.208 125.339 21.9208 125.505C21.6335 125.67 21.5351 126.038 21.7009 126.325C21.8668 126.612 22.2341 126.711 22.5214 126.545Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.8156 124.524C21.2306 124.285 21.3727 123.754 21.1332 123.339C20.8936 122.924 20.363 122.782 19.9481 123.022C19.5332 123.261 19.391 123.792 19.6305 124.207C19.8701 124.622 20.4007 124.764 20.8156 124.524Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.1848 123.307C17.5678 123.086 17.699 122.597 17.4779 122.214C17.2567 121.83 16.767 121.699 16.384 121.92C16.0009 122.142 15.8697 122.631 16.0908 123.014C16.312 123.397 16.8017 123.529 17.1848 123.307Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.5822 118.918C19.9014 118.734 20.0107 118.325 19.8265 118.006C19.6422 117.687 19.234 117.578 18.9148 117.762C18.5957 117.946 18.4863 118.354 18.6706 118.674C18.8549 118.993 19.263 119.102 19.5822 118.918Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.827 114.926C24.0824 114.778 24.1699 114.452 24.0224 114.197C23.875 113.941 23.5485 113.854 23.2932 114.001C23.0378 114.149 22.9503 114.475 23.0977 114.73C23.2452 114.986 23.5717 115.073 23.827 114.926Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.4177 113.965C18.8646 113.707 19.0177 113.136 18.7597 112.689C18.5017 112.242 17.9303 112.089 17.4834 112.347C17.0366 112.605 16.8835 113.176 17.1415 113.623C17.3994 114.07 17.9708 114.223 18.4177 113.965Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.6738 110.929C22.0249 110.726 22.1452 110.277 21.9424 109.926C21.7397 109.575 21.2908 109.455 20.9397 109.658C20.5886 109.86 20.4683 110.309 20.671 110.66C20.8737 111.011 21.3227 111.132 21.6738 110.929Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M16.9618 108.641C17.3129 108.438 17.4332 107.989 17.2305 107.638C17.0278 107.287 16.5789 107.167 16.2278 107.369C15.8767 107.572 15.7564 108.021 15.9591 108.372C16.1618 108.723 16.6107 108.843 16.9618 108.641Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.5993 107.196C21.8865 107.03 21.985 106.663 21.8191 106.376C21.6532 106.089 21.2859 105.99 20.9987 106.156C20.7114 106.322 20.613 106.689 20.7788 106.976C20.9447 107.264 21.312 107.362 21.5993 107.196Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.0929 105.522C20.2842 105.412 20.3498 105.167 20.2393 104.976C20.1289 104.785 19.8842 104.719 19.6929 104.83C19.5015 104.94 19.436 105.185 19.5465 105.376C19.6569 105.567 19.9016 105.633 20.0929 105.522Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M15.3698 102.547C15.5611 102.436 15.6266 102.192 15.5162 102C15.4057 101.809 15.1611 101.743 14.9697 101.854C14.7784 101.964 14.7128 102.209 14.8233 102.4C14.9338 102.592 15.1784 102.657 15.3698 102.547Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.4269 100.629C20.6182 100.518 20.6838 100.274 20.5733 100.082C20.4628 99.8911 20.2182 99.8255 20.0269 99.936C19.8355 100.046 19.77 100.291 19.8804 100.482C19.9909 100.674 20.2356 100.739 20.4269 100.629Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M16.1123 99.9614C16.5272 99.7219 16.6694 99.1913 16.4298 98.7763C16.1902 98.3614 15.6597 98.2192 15.2447 98.4588C14.8298 98.6984 14.6876 99.229 14.9272 99.6439C15.1667 100.059 15.6973 100.201 16.1123 99.9614Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.5082 96.7678C19.8274 96.5835 19.9368 96.1753 19.7525 95.8562C19.5682 95.537 19.1601 95.4276 18.8409 95.6119C18.5217 95.7962 18.4123 96.2043 18.5966 96.5235C18.7809 96.8427 19.189 96.952 19.5082 96.7678Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M65.2029 160.698C65.6497 160.44 65.8028 159.869 65.5448 159.422C65.2868 158.975 64.7154 158.822 64.2686 159.08C63.8217 159.338 63.6686 159.909 63.9266 160.356C64.1846 160.803 64.756 160.956 65.2029 160.698Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.468 161.968C62.6274 161.876 62.6821 161.672 62.59 161.512C62.498 161.353 62.2941 161.298 62.1346 161.39C61.9752 161.483 61.9206 161.686 62.0126 161.846C62.1047 162.005 62.3086 162.06 62.468 161.968Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.3212 163.054C60.7043 162.833 60.8355 162.343 60.6144 161.96C60.3932 161.577 59.9034 161.446 59.5204 161.667C59.1374 161.888 59.0062 162.378 59.2273 162.761C59.4485 163.144 59.9382 163.275 60.3212 163.054Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.6725 158.727C59.8638 158.617 59.9294 158.372 59.8189 158.181C59.7084 157.989 59.4638 157.924 59.2725 158.034C59.0811 158.145 59.0156 158.389 59.126 158.581C59.2365 158.772 59.4812 158.838 59.6725 158.727Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.9828 153.925C60.2701 153.759 60.3685 153.392 60.2026 153.105C60.0368 152.818 59.6695 152.719 59.3822 152.885C59.0949 153.051 58.9965 153.418 59.1624 153.705C59.3282 153.993 59.6955 154.091 59.9828 153.925Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.7221 158.157C56.1371 157.918 56.2792 157.387 56.0397 156.972C55.8001 156.557 55.2695 156.415 54.8546 156.655C54.4396 156.894 54.2975 157.425 54.537 157.84C54.7766 158.255 55.3072 158.397 55.7221 158.157Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.3527 156.984C55.7038 156.781 55.8241 156.332 55.6214 155.981C55.4187 155.63 54.9697 155.51 54.6186 155.712C54.2675 155.915 54.1472 156.364 54.3499 156.715C54.5527 157.066 55.0016 157.186 55.3527 156.984Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.4389 153.66C56.8539 153.42 56.996 152.89 56.7565 152.475C56.5169 152.06 55.9863 151.917 55.5714 152.157C55.1564 152.397 55.0143 152.927 55.2538 153.342C55.4934 153.757 56.024 153.899 56.4389 153.66Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M52.7335 156.184C52.9569 156.055 53.0335 155.769 52.9045 155.546C52.7755 155.323 52.4898 155.246 52.2664 155.375C52.0429 155.504 51.9664 155.79 52.0954 156.013C52.2244 156.237 52.5101 156.313 52.7335 156.184Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M52.3202 150.798C52.6394 150.613 52.7488 150.205 52.5645 149.886C52.3802 149.567 51.9721 149.457 51.6529 149.642C51.3337 149.826 51.2243 150.234 51.4086 150.553C51.5929 150.872 52.001 150.982 52.3202 150.798Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.6723 148.744C50.9595 148.578 51.058 148.211 50.8921 147.923C50.7262 147.636 50.3589 147.538 50.0717 147.704C49.7844 147.869 49.686 148.237 49.8518 148.524C50.0177 148.811 50.385 148.91 50.6723 148.744Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.0319 148.035C50.2872 147.887 50.3747 147.561 50.2273 147.305C50.0799 147.05 49.7533 146.963 49.498 147.11C49.2426 147.257 49.1552 147.584 49.3026 147.839C49.45 148.095 49.7765 148.182 50.0319 148.035Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.9013 147.954C46.2843 147.733 46.4156 147.243 46.1944 146.86C45.9733 146.477 45.4835 146.346 45.1005 146.567C44.7175 146.788 44.5863 147.278 44.8074 147.661C45.0285 148.044 45.5183 148.175 45.9013 147.954Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.5014 146.603C42.8525 146.4 42.9728 145.951 42.7701 145.6C42.5674 145.249 42.1184 145.129 41.7673 145.332C41.4162 145.534 41.2959 145.983 41.4986 146.334C41.7013 146.686 42.1503 146.806 42.5014 146.603Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.7714 141.863C44.0267 141.715 44.1142 141.389 43.9668 141.134C43.8193 140.878 43.4928 140.791 43.2375 140.938C42.9821 141.086 42.8947 141.412 43.0421 141.667C43.1895 141.923 43.516 142.01 43.7714 141.863Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.9372 142.96C41.2244 142.794 41.3228 142.427 41.157 142.139C40.9911 141.852 40.6238 141.754 40.3365 141.919C40.0493 142.085 39.9509 142.453 40.1167 142.74C40.2826 143.027 40.6499 143.126 40.9372 142.96Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.3825 136.773C47.7336 136.57 47.8539 136.121 47.6512 135.77C47.4485 135.419 46.9995 135.298 46.6484 135.501C46.2973 135.704 46.177 136.153 46.3797 136.504C46.5824 136.855 47.0314 136.975 47.3825 136.773Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.8246 140.366C40.2077 140.144 40.3389 139.655 40.1178 139.272C39.8966 138.889 39.4069 138.757 39.0238 138.979C38.6408 139.2 38.5096 139.689 38.7307 140.072C38.9519 140.455 39.4416 140.587 39.8246 140.366Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.6764 135.022C44.8998 134.893 44.9764 134.607 44.8474 134.383C44.7184 134.16 44.4327 134.083 44.2092 134.212C43.9858 134.341 43.9093 134.627 44.0383 134.851C44.1673 135.074 44.4529 135.151 44.6764 135.022Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.5378 136.138C37.9527 135.898 38.0949 135.368 37.8553 134.953C37.6158 134.538 37.0852 134.396 36.6703 134.635C36.2553 134.875 36.1131 135.405 36.3527 135.82C36.5923 136.235 37.1229 136.377 37.5378 136.138Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.0868 132.277C40.3102 132.148 40.3868 131.863 40.2578 131.639C40.1288 131.416 39.8431 131.339 39.6196 131.468C39.3962 131.597 39.3197 131.883 39.4487 132.106C39.5777 132.33 39.8634 132.406 40.0868 132.277Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.8864 131.273C37.0777 131.163 37.1432 130.918 37.0328 130.727C36.9223 130.535 36.6777 130.47 36.4863 130.58C36.295 130.691 36.2294 130.935 36.3399 131.127C36.4504 131.318 36.695 131.384 36.8864 131.273Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.5703 127.106C41.9533 126.885 42.0845 126.395 41.8634 126.012C41.6422 125.629 41.1525 125.498 40.7694 125.719C40.3864 125.94 40.2552 126.43 40.4763 126.813C40.6975 127.196 41.1872 127.327 41.5703 127.106Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.3925 125.203C39.5838 125.092 39.6494 124.848 39.5389 124.656C39.4284 124.465 39.1838 124.399 38.9924 124.51C38.8011 124.62 38.7355 124.865 38.846 125.056C38.9565 125.248 39.2011 125.313 39.3925 125.203Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.0638 125.313C35.2552 125.203 35.3207 124.958 35.2103 124.767C35.0998 124.575 34.8551 124.51 34.6638 124.62C34.4725 124.731 34.4069 124.975 34.5174 125.167C34.6279 125.358 34.8725 125.424 35.0638 125.313Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.9369 122.69C35.1603 122.561 35.2369 122.276 35.1079 122.052C34.9789 121.829 34.6932 121.752 34.4697 121.881C34.2463 122.01 34.1698 122.296 34.2988 122.519C34.4278 122.743 34.7134 122.819 34.9369 122.69Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.7902 118.423C38.1733 118.202 38.3045 117.712 38.0833 117.329C37.8622 116.946 37.3724 116.815 36.9894 117.036C36.6064 117.257 36.4752 117.747 36.6963 118.13C36.9174 118.513 37.4072 118.644 37.7902 118.423Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.6476 120.466C30.9668 120.282 31.0762 119.874 30.8919 119.555C30.7076 119.235 30.2995 119.126 29.9803 119.31C29.6611 119.495 29.5517 119.903 29.736 120.222C29.9203 120.541 30.3284 120.651 30.6476 120.466Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.4948 118.473C28.8459 118.27 28.9662 117.821 28.7635 117.47C28.5608 117.119 28.1118 116.998 27.7607 117.201C27.4096 117.404 27.2893 117.853 27.492 118.204C27.6947 118.555 28.1437 118.675 28.4948 118.473Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.1152 115.804C31.5621 115.546 31.7152 114.975 31.4572 114.528C31.1992 114.081 30.6278 113.928 30.1809 114.186C29.7341 114.444 29.581 115.015 29.839 115.462C30.097 115.909 30.6684 116.062 31.1152 115.804Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.8451 112.8C31.1962 112.598 31.3165 112.149 31.1138 111.798C30.9111 111.447 30.4622 111.326 30.1111 111.529C29.76 111.732 29.6397 112.181 29.8424 112.532C30.0451 112.883 30.494 113.003 30.8451 112.8Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.8486 109.87C31.2635 109.631 31.4057 109.1 31.1661 108.685C30.9266 108.27 30.396 108.128 29.9811 108.367C29.5661 108.607 29.4239 109.138 29.6635 109.553C29.9031 109.968 30.4337 110.11 30.8486 109.87Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.8578 107.477C33.1451 107.311 33.2435 106.944 33.0776 106.657C32.9118 106.37 32.5445 106.271 32.2572 106.437C31.9699 106.603 31.8715 106.97 32.0374 107.257C32.2032 107.545 32.5705 107.643 32.8578 107.477Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.1657 108.836C25.4849 108.652 25.5942 108.244 25.4099 107.925C25.2257 107.605 24.8175 107.496 24.4983 107.68C24.1792 107.865 24.0698 108.273 24.2541 108.592C24.4384 108.911 24.8465 109.02 25.1657 108.836Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.9013 106.37C27.2843 106.149 27.4156 105.659 27.1944 105.276C26.9733 104.893 26.4835 104.762 26.1005 104.983C25.7175 105.204 25.5862 105.694 25.8074 106.077C26.0285 106.46 26.5183 106.591 26.9013 106.37Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.4391 106.249C22.7264 106.083 22.8248 105.716 22.6589 105.429C22.4931 105.141 22.1258 105.043 21.8385 105.209C21.5512 105.375 21.4528 105.742 21.6187 106.029C21.7845 106.316 22.1518 106.415 22.4391 106.249Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.3242 102.847C22.4836 102.755 22.5383 102.551 22.4462 102.392C22.3542 102.232 22.1503 102.178 21.9908 102.27C21.8314 102.362 21.7768 102.566 21.8688 102.725C21.9609 102.885 22.1648 102.939 22.3242 102.847Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.5917 100.768C24.847 100.621 24.9345 100.294 24.7871 100.039C24.6397 99.7835 24.3132 99.696 24.0578 99.8434C23.8025 99.9908 23.715 100.317 23.8624 100.573C24.0098 100.828 24.3363 100.916 24.5917 100.768Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.3642 97.9714C24.6834 97.7871 24.7927 97.3789 24.6084 97.0598C24.4242 96.7406 24.016 96.6312 23.6968 96.8155C23.3776 96.9998 23.2683 97.4079 23.4526 97.7271C23.6368 98.0463 24.045 98.1556 24.3642 97.9714Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.781 151.877C48.1959 151.638 48.3381 151.107 48.0985 150.692C47.8589 150.277 47.3284 150.135 46.9134 150.375C46.4985 150.614 46.3563 151.145 46.5959 151.56C46.8355 151.975 47.366 152.117 47.781 151.877Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.5148 150.758C44.7382 150.629 44.8147 150.343 44.6857 150.12C44.5567 149.896 44.2711 149.82 44.0476 149.949C43.8242 150.078 43.7476 150.363 43.8766 150.587C44.0056 150.81 44.2913 150.887 44.5148 150.758Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.6123 147.851C47.0591 147.593 47.2122 147.022 46.9543 146.575C46.6963 146.128 46.1249 145.975 45.678 146.233C45.2311 146.491 45.078 147.062 45.336 147.509C45.594 147.956 46.1654 148.109 46.6123 147.851Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.7582 147.843C45.0135 147.696 45.101 147.369 44.9536 147.114C44.8062 146.858 44.4797 146.771 44.2243 146.918C43.969 147.066 43.8815 147.392 44.0289 147.648C44.1763 147.903 44.5028 147.99 44.7582 147.843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.8353 149.454C42.0907 149.306 42.1782 148.98 42.0307 148.724C41.8833 148.469 41.5568 148.382 41.3015 148.529C41.0461 148.676 40.9586 149.003 41.106 149.258C41.2535 149.514 41.58 149.601 41.8353 149.454Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.3586 143.951C43.6777 143.766 43.7871 143.358 43.6028 143.039C43.4185 142.72 43.0104 142.61 42.6912 142.795C42.372 142.979 42.2627 143.387 42.447 143.706C42.6312 144.026 43.0394 144.135 43.3586 143.951Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.681 145.726C38.8724 145.615 38.9379 145.371 38.8275 145.179C38.717 144.988 38.4723 144.922 38.281 145.033C38.0897 145.143 38.0241 145.388 38.1346 145.579C38.2451 145.771 38.4897 145.836 38.681 145.726Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.3855 144.279C38.8323 144.021 38.9854 143.45 38.7274 143.003C38.4695 142.556 37.8981 142.403 37.4512 142.661C37.0043 142.919 36.8512 143.49 37.1092 143.937C37.3672 144.384 37.9386 144.537 38.3855 144.279Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.1903 139.537C38.4137 139.408 38.4903 139.122 38.3613 138.899C38.2323 138.675 37.9466 138.599 37.7232 138.728C37.4997 138.857 37.4232 139.143 37.5522 139.366C37.6812 139.589 37.9669 139.666 38.1903 139.537Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.5844 137.42C37.7757 137.31 37.8412 137.065 37.7308 136.874C37.6203 136.682 37.3757 136.617 37.1843 136.727C36.993 136.838 36.9274 137.082 37.0379 137.274C37.1484 137.465 37.393 137.531 37.5844 137.42Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.4914 136.858C37.8744 136.637 38.0057 136.147 37.7845 135.764C37.5634 135.381 37.0736 135.25 36.6906 135.471C36.3076 135.692 36.1763 136.182 36.3975 136.565C36.6186 136.948 37.1084 137.079 37.4914 136.858Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.4611 136.681C34.8441 136.46 34.9754 135.97 34.7542 135.587C34.5331 135.204 34.0433 135.073 33.6603 135.294C33.2773 135.515 33.1461 136.005 33.3672 136.388C33.5883 136.771 34.0781 136.902 34.4611 136.681Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.9677 135.156C35.3507 134.935 35.482 134.445 35.2608 134.062C35.0397 133.679 34.5499 133.548 34.1669 133.769C33.7839 133.99 33.6527 134.48 33.8738 134.863C34.0949 135.246 34.5847 135.377 34.9677 135.156Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.1464 132.394C36.3377 132.284 36.4033 132.039 36.2928 131.848C36.1823 131.656 35.9377 131.591 35.7463 131.701C35.555 131.812 35.4895 132.056 35.5999 132.248C35.7104 132.439 35.955 132.504 36.1464 132.394Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.3441 132.623C29.5995 132.476 29.687 132.149 29.5395 131.894C29.3921 131.639 29.0656 131.551 28.8102 131.699C28.5549 131.846 28.4674 132.173 28.6148 132.428C28.7623 132.683 29.0888 132.771 29.3441 132.623Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.9919 129.744C29.311 129.56 29.4204 129.152 29.2361 128.833C29.0518 128.514 28.6437 128.404 28.3245 128.588C28.0053 128.773 27.896 129.181 28.0803 129.5C28.2645 129.819 28.6727 129.929 28.9919 129.744Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.6989 125.755C33.0819 125.534 33.2132 125.044 32.992 124.661C32.7709 124.278 32.2811 124.147 31.8981 124.368C31.5151 124.589 31.3839 125.079 31.605 125.462C31.8261 125.845 32.3159 125.976 32.6989 125.755Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.7657 125.752C27.9892 125.623 28.0657 125.337 27.9367 125.114C27.8077 124.89 27.522 124.814 27.2986 124.943C27.0752 125.072 26.9986 125.357 27.1276 125.581C27.2566 125.804 27.5423 125.881 27.7657 125.752Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.5311 126.155C24.7545 126.026 24.8311 125.74 24.7021 125.517C24.5731 125.294 24.2874 125.217 24.064 125.346C23.8405 125.475 23.764 125.761 23.893 125.984C24.022 126.208 24.3077 126.284 24.5311 126.155Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.3928 124.848C24.7758 124.627 24.907 124.137 24.6859 123.754C24.4647 123.371 23.975 123.24 23.592 123.461C23.2089 123.682 23.0777 124.172 23.2988 124.555C23.52 124.938 24.0097 125.069 24.3928 124.848Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.6455 124.355C24.0285 124.134 24.1597 123.644 23.9386 123.261C23.7174 122.878 23.2277 122.747 22.8446 122.968C22.4616 123.189 22.3304 123.679 22.5515 124.062C22.7727 124.445 23.2624 124.576 23.6455 124.355Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.725 119.266C31.0123 119.1 31.1107 118.732 30.9448 118.445C30.779 118.158 30.4117 118.059 30.1244 118.225C29.8371 118.391 29.7387 118.759 29.9046 119.046C30.0704 119.333 30.4377 119.431 30.725 119.266Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.5907 119.573C28.9099 119.389 29.0193 118.981 28.835 118.662C28.6507 118.342 28.2426 118.233 27.9234 118.417C27.6042 118.602 27.4948 119.01 27.6791 119.329C27.8634 119.648 28.2716 119.757 28.5907 119.573Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.0829 119.634C26.434 119.431 26.5543 118.982 26.3516 118.631C26.1489 118.28 25.7 118.16 25.3489 118.362C24.9978 118.565 24.8775 119.014 25.0802 119.365C25.2829 119.716 25.7318 119.837 26.0829 119.634Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.5673 116.196C28.9503 115.975 29.0816 115.485 28.8604 115.102C28.6393 114.719 28.1495 114.588 27.7665 114.809C27.3835 115.03 27.2523 115.52 27.4734 115.903C27.6945 116.286 28.1843 116.417 28.5673 116.196Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.0348 116.499C20.2901 116.351 20.3776 116.025 20.2302 115.77C20.0828 115.514 19.7563 115.427 19.5009 115.574C19.2456 115.722 19.1581 116.048 19.3055 116.303C19.4529 116.559 19.7794 116.646 20.0348 116.499Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.3271 111.248C26.7101 111.027 26.8413 110.537 26.6202 110.154C26.3991 109.771 25.9093 109.64 25.5263 109.861C25.1433 110.082 25.012 110.572 25.2332 110.955C25.4543 111.338 25.9441 111.469 26.3271 111.248Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.0195 111.655C24.4664 111.397 24.6195 110.826 24.3615 110.379C24.1035 109.932 23.5321 109.779 23.0852 110.037C22.6384 110.295 22.4853 110.867 22.7433 111.314C23.0013 111.76 23.5726 111.913 24.0195 111.655Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.4552 108.4C26.7106 108.253 26.798 107.926 26.6506 107.671C26.5032 107.415 26.1767 107.328 25.9213 107.475C25.666 107.623 25.5785 107.949 25.7259 108.205C25.8733 108.46 26.1999 108.547 26.4552 108.4Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.5924 110.127C19.9116 109.943 20.021 109.535 19.8367 109.216C19.6524 108.897 19.2443 108.787 18.9251 108.972C18.6059 109.156 18.4966 109.564 18.6808 109.883C18.8651 110.202 19.2733 110.312 19.5924 110.127Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.6796 105.995C23.9988 105.811 24.1081 105.403 23.9239 105.084C23.7396 104.765 23.3314 104.655 23.0123 104.839C22.6931 105.024 22.5837 105.432 22.768 105.751C22.9523 106.07 23.3604 106.18 23.6796 105.995Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M15.2316 106.711C15.6147 106.49 15.7459 106.001 15.5248 105.618C15.3036 105.235 14.8138 105.103 14.4308 105.324C14.0478 105.546 13.9166 106.035 14.1377 106.418C14.3588 106.801 14.8486 106.933 15.2316 106.711Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.1561 101.363C19.4115 101.216 19.499 100.889 19.3515 100.634C19.2041 100.379 18.8776 100.291 18.6223 100.439C18.3669 100.586 18.2794 100.913 18.4268 101.168C18.5743 101.423 18.9008 101.511 19.1561 101.363Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.5485 100.982C17.9315 100.76 18.0628 100.271 17.8416 99.8876C17.6205 99.5046 17.1307 99.3733 16.7477 99.5945C16.3647 99.8156 16.2335 100.305 16.4546 100.688C16.6757 101.071 17.1655 101.203 17.5485 100.982Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.5768 100.114C11.8321 99.9669 11.9196 99.6404 11.7722 99.385C11.6248 99.1297 11.2983 99.0422 11.0429 99.1896C10.7876 99.337 10.7001 99.6635 10.8475 99.9189C10.9949 100.174 11.3214 100.262 11.5768 100.114Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.5558 180.733C86.7471 180.622 86.8127 180.378 86.7022 180.186C86.5918 179.995 86.3471 179.93 86.1558 180.04C85.9644 180.15 85.8989 180.395 86.0093 180.586C86.1198 180.778 86.3645 180.843 86.5558 180.733Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.7733 179.914C85.0606 179.748 85.159 179.38 84.9932 179.093C84.8273 178.806 84.46 178.707 84.1727 178.873C83.8855 179.039 83.787 179.406 83.9529 179.694C84.1187 179.981 84.4861 180.079 84.7733 179.914Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.5661 175.411C87.013 175.153 87.1661 174.581 86.9081 174.135C86.6501 173.688 86.0787 173.535 85.6319 173.793C85.185 174.051 85.0319 174.622 85.2899 175.069C85.5479 175.516 86.1193 175.669 86.5661 175.411Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.9272 170.695C88.3102 170.474 88.4414 169.984 88.2203 169.601C87.9992 169.218 87.5094 169.087 87.1264 169.308C86.7434 169.529 86.6121 170.019 86.8333 170.402C87.0544 170.785 87.5442 170.916 87.9272 170.695Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.525 176.55C83.7804 176.403 83.8679 176.076 83.7204 175.821C83.573 175.565 83.2465 175.478 82.9912 175.625C82.7358 175.773 82.6483 176.099 82.7957 176.355C82.9432 176.61 83.2697 176.698 83.525 176.55Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.2941 175.757C81.4854 175.646 81.551 175.402 81.4405 175.21C81.33 175.019 81.0854 174.953 80.894 175.064C80.7027 175.174 80.6372 175.419 80.7476 175.61C80.8581 175.802 81.1028 175.867 81.2941 175.757Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.0759 175.651C79.4589 175.43 79.5901 174.94 79.369 174.557C79.1478 174.174 78.6581 174.043 78.2751 174.264C77.892 174.485 77.7608 174.975 77.9819 175.358C78.2031 175.741 78.6928 175.872 79.0759 175.651Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.2743 169.989C79.5616 169.823 79.66 169.455 79.4942 169.168C79.3283 168.881 78.961 168.782 78.6737 168.948C78.3864 169.114 78.288 169.481 78.4539 169.769C78.6197 170.056 78.987 170.154 79.2743 169.989Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.944 166.48C79.1993 166.333 79.2868 166.007 79.1394 165.751C78.992 165.496 78.6654 165.408 78.4101 165.556C78.1548 165.703 78.0673 166.03 78.2147 166.285C78.3621 166.54 78.6886 166.628 78.944 166.48Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.0425 167.461C76.2019 167.369 76.2565 167.166 76.1645 167.006C76.0724 166.847 75.8685 166.792 75.7091 166.884C75.5497 166.976 75.495 167.18 75.5871 167.339C75.6791 167.499 75.883 167.554 76.0425 167.461Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.9399 172.103C71.3548 171.864 71.497 171.333 71.2574 170.918C71.0179 170.503 70.4873 170.361 70.0724 170.601C69.6574 170.84 69.5152 171.371 69.7548 171.786C69.9944 172.201 70.525 172.343 70.9399 172.103Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.4364 168.695C70.7237 168.529 70.8221 168.162 70.6563 167.875C70.4904 167.588 70.1231 167.489 69.8358 167.655C69.5486 167.821 69.4501 168.188 69.616 168.475C69.7818 168.763 70.1492 168.861 70.4364 168.695Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.4929 162.114C72.8759 161.893 73.0071 161.403 72.786 161.02C72.5648 160.637 72.0751 160.506 71.6921 160.727C71.309 160.948 71.1778 161.438 71.3989 161.821C71.6201 162.204 72.1098 162.335 72.4929 162.114Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.2541 161.172C70.6052 160.969 70.7255 160.52 70.5228 160.169C70.3201 159.818 69.8711 159.698 69.52 159.901C69.1689 160.103 69.0486 160.552 69.2513 160.903C69.454 161.254 69.903 161.375 70.2541 161.172Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.2604 165.867C66.5157 165.719 66.6032 165.393 66.4558 165.137C66.3084 164.882 65.9819 164.794 65.7265 164.942C65.4712 165.089 65.3837 165.416 65.5311 165.671C65.6785 165.926 66.005 166.014 66.2604 165.867Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.0082 165.703C64.3593 165.5 64.4796 165.051 64.2769 164.7C64.0742 164.349 63.6253 164.229 63.2742 164.431C62.9231 164.634 62.8028 165.083 63.0055 165.434C63.2082 165.785 63.6571 165.905 64.0082 165.703Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.4203 161.612C64.7714 161.409 64.8917 160.96 64.689 160.609C64.4863 160.258 64.0374 160.138 63.6863 160.34C63.3352 160.543 63.2149 160.992 63.4176 161.343C63.6203 161.694 64.0692 161.814 64.4203 161.612Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.3986 159.311C62.7816 159.09 62.9129 158.6 62.6917 158.217C62.4706 157.834 61.9808 157.703 61.5978 157.924C61.2148 158.145 61.0836 158.635 61.3047 159.018C61.5258 159.401 62.0156 159.532 62.3986 159.311Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.2486 162.67C57.4721 162.541 57.5486 162.255 57.4196 162.032C57.2906 161.808 57.0049 161.732 56.7815 161.861C56.5581 161.99 56.4815 162.276 56.6105 162.499C56.7395 162.722 57.0252 162.799 57.2486 162.67Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.5582 154.787C61.7496 154.676 61.8151 154.432 61.7047 154.24C61.5942 154.049 61.3495 153.983 61.1582 154.094C60.9669 154.204 60.9013 154.449 61.0118 154.64C61.1222 154.832 61.3669 154.897 61.5582 154.787Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.3566 153.903C61.6439 153.737 61.7423 153.369 61.5764 153.082C61.4106 152.795 61.0433 152.696 60.756 152.862C60.4687 153.028 60.3703 153.395 60.5361 153.683C60.702 153.97 61.0693 154.068 61.3566 153.903Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.1286 153.648C59.4797 153.445 59.6 152.996 59.3973 152.645C59.1946 152.294 58.7456 152.173 58.3945 152.376C58.0434 152.579 57.9231 153.028 58.1258 153.379C58.3285 153.73 58.7775 153.85 59.1286 153.648Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.3849 152.227C58.5762 152.116 58.6418 151.872 58.5313 151.68C58.4209 151.489 58.1762 151.423 57.9849 151.534C57.7935 151.644 57.728 151.889 57.8384 152.08C57.9489 152.272 58.1936 152.337 58.3849 152.227Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M52.7506 154.479C52.974 154.35 53.0506 154.064 52.9216 153.841C52.7926 153.617 52.5069 153.541 52.2835 153.67C52.06 153.799 51.9835 154.084 52.1125 154.308C52.2415 154.531 52.5272 154.608 52.7506 154.479Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.5094 150.188C53.7328 150.059 53.8094 149.773 53.6804 149.55C53.5514 149.326 53.2657 149.25 53.0422 149.379C52.8188 149.508 52.7423 149.793 52.8713 150.017C53.0003 150.24 53.286 150.317 53.5094 150.188Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.737 148.052C52.0881 147.849 52.2084 147.4 52.0057 147.049C51.803 146.698 51.354 146.578 51.0029 146.78C50.6518 146.983 50.5315 147.432 50.7342 147.783C50.9369 148.134 51.3859 148.255 51.737 148.052Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.9581 150.85C48.1816 150.721 48.2581 150.435 48.1291 150.212C48.0001 149.988 47.7144 149.912 47.491 150.041C47.2676 150.17 47.191 150.456 47.32 150.679C47.449 150.902 47.7347 150.979 47.9581 150.85Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.5126 144.867C49.736 144.738 49.8125 144.452 49.6835 144.229C49.5545 144.005 49.2689 143.929 49.0454 144.058C48.822 144.187 48.7454 144.472 48.8744 144.696C49.0034 144.919 49.2891 144.996 49.5126 144.867Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.7425 148.083C44.966 147.954 45.0425 147.668 44.9135 147.445C44.7845 147.222 44.4988 147.145 44.2754 147.274C44.052 147.403 43.9754 147.689 44.1044 147.912C44.2334 148.136 44.5191 148.212 44.7425 148.083Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.111 150.065C42.4621 149.862 42.5824 149.413 42.3797 149.062C42.177 148.711 41.728 148.591 41.3769 148.793C41.0258 148.996 40.9055 149.445 41.1082 149.796C41.311 150.147 41.7599 150.268 42.111 150.065Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.6928 141.717C47.012 141.533 47.1213 141.125 46.9371 140.806C46.7528 140.486 46.3446 140.377 46.0254 140.561C45.7063 140.746 45.5969 141.154 45.7812 141.473C45.9655 141.792 46.3736 141.902 46.6928 141.717Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.2541 144.704C43.6691 144.465 43.8112 143.934 43.5717 143.519C43.3321 143.104 42.8015 142.962 42.3866 143.201C41.9716 143.441 41.8295 143.972 42.069 144.387C42.3086 144.802 42.8392 144.944 43.2541 144.704Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.6283 142.021C41.9156 141.855 42.014 141.488 41.8482 141.201C41.6823 140.914 41.315 140.815 41.0277 140.981C40.7404 141.147 40.642 141.514 40.8079 141.801C40.9737 142.089 41.3411 142.187 41.6283 142.021Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.4835 137.634C41.6749 137.524 41.7404 137.279 41.6299 137.088C41.5195 136.897 41.2748 136.831 41.0835 136.942C40.8922 137.052 40.8266 137.297 40.9371 137.488C41.0475 137.679 41.2922 137.745 41.4835 137.634Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.0141 139.368C37.3013 139.202 37.3997 138.835 37.2339 138.548C37.068 138.26 36.7007 138.162 36.4135 138.328C36.1262 138.494 36.0278 138.861 36.1936 139.148C36.3595 139.436 36.7268 139.534 37.0141 139.368Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.7245 134.99C39.0437 134.806 39.1531 134.398 38.9688 134.079C38.7845 133.759 38.3764 133.65 38.0572 133.834C37.738 134.019 37.6286 134.427 37.8129 134.746C37.9972 135.065 38.4053 135.174 38.7245 134.99Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.6892 136.009C34.8486 135.917 34.9033 135.713 34.8112 135.554C34.7192 135.395 34.5153 135.34 34.3558 135.432C34.1964 135.524 34.1418 135.728 34.2338 135.887C34.3259 136.047 34.5297 136.101 34.6892 136.009Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.3993 137.254C30.6227 137.125 30.6993 136.839 30.5703 136.616C30.4413 136.392 30.1556 136.316 29.9321 136.445C29.7087 136.574 29.6322 136.859 29.7612 137.083C29.8902 137.306 30.1758 137.383 30.3993 137.254Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.6733 133.858C30.8328 133.766 30.8874 133.563 30.7953 133.403C30.7033 133.244 30.4994 133.189 30.34 133.281C30.1805 133.373 30.1259 133.577 30.2179 133.736C30.31 133.896 30.5139 133.951 30.6733 133.858Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.8335 132.534C31.2484 132.294 31.3906 131.764 31.151 131.349C30.9114 130.934 30.3809 130.792 29.9659 131.031C29.551 131.271 29.4088 131.801 29.6484 132.216C29.8879 132.631 30.4185 132.773 30.8335 132.534Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.5807 130.367C28.9318 130.164 29.0521 129.715 28.8494 129.364C28.6467 129.013 28.1978 128.893 27.8467 129.095C27.4956 129.298 27.3753 129.747 27.578 130.098C27.7807 130.449 28.2296 130.57 28.5807 130.367Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.1708 127.521C28.5219 127.318 28.6422 126.869 28.4395 126.518C28.2368 126.167 27.7879 126.047 27.4368 126.25C27.0856 126.452 26.9654 126.901 27.1681 127.252C27.3708 127.604 27.8197 127.724 28.1708 127.521Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.3425 125.957C26.7574 125.717 26.8996 125.186 26.66 124.771C26.4205 124.357 25.8899 124.214 25.4749 124.454C25.06 124.693 24.9178 125.224 25.1574 125.639C25.397 126.054 25.9276 126.196 26.3425 125.957Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.7121 124.47C24.0951 124.249 24.2264 123.759 24.0052 123.376C23.7841 122.993 23.2943 122.862 22.9113 123.083C22.5283 123.304 22.397 123.794 22.6182 124.177C22.8393 124.56 23.3291 124.691 23.7121 124.47Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.1198 124.117C21.407 123.952 21.5055 123.584 21.3396 123.297C21.1738 123.01 20.8064 122.911 20.5192 123.077C20.2319 123.243 20.1335 123.61 20.2993 123.898C20.4652 124.185 20.8325 124.283 21.1198 124.117Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.3556 117.704C26.6748 117.52 26.7842 117.112 26.5999 116.792C26.4156 116.473 26.0075 116.364 25.6883 116.548C25.3691 116.732 25.2597 117.141 25.444 117.46C25.6283 117.779 26.0364 117.888 26.3556 117.704Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.338 118.903C17.5293 118.792 17.5949 118.547 17.4844 118.356C17.374 118.165 17.1293 118.099 16.938 118.21C16.7467 118.32 16.6811 118.565 16.7916 118.756C16.902 118.947 17.1467 119.013 17.338 118.903Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.4144 116.626C19.6698 116.478 19.7573 116.152 19.6098 115.896C19.4624 115.641 19.1359 115.554 18.8806 115.701C18.6252 115.849 18.5377 116.175 18.6851 116.43C18.8326 116.686 19.1591 116.773 19.4144 116.626Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.5287 115.094C17.8479 114.91 17.9573 114.502 17.773 114.183C17.5887 113.864 17.1806 113.754 16.8614 113.939C16.5422 114.123 16.4328 114.531 16.6171 114.85C16.8014 115.169 17.2095 115.279 17.5287 115.094Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.1894 113.44C17.3489 113.348 17.4035 113.144 17.3115 112.985C17.2194 112.825 17.0155 112.771 16.8561 112.863C16.6966 112.955 16.642 113.159 16.7341 113.318C16.8261 113.478 17.03 113.532 17.1894 113.44Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.4882 108.339C21.7435 108.192 21.831 107.865 21.6836 107.61C21.5362 107.355 21.2096 107.267 20.9543 107.414C20.6989 107.562 20.6115 107.888 20.7589 108.144C20.9063 108.399 21.2328 108.487 21.4882 108.339Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M12.7599 111.374C12.9512 111.263 13.0168 111.019 12.9063 110.828C12.7958 110.636 12.5512 110.571 12.3599 110.681C12.1685 110.792 12.103 111.036 12.2134 111.228C12.3239 111.419 12.5686 111.484 12.7599 111.374Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M14.3164 109.397C14.7313 109.158 14.8735 108.627 14.6339 108.212C14.3943 107.797 13.8638 107.655 13.4488 107.895C13.0339 108.134 12.8917 108.665 13.1313 109.08C13.3708 109.495 13.9014 109.637 14.3164 109.397Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.1992 105.846C18.5822 105.624 18.7134 105.135 18.4923 104.752C18.2711 104.369 17.7814 104.237 17.3984 104.458C17.0153 104.68 16.8841 105.169 17.1052 105.552C17.3264 105.935 17.8161 106.067 18.1992 105.846Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.8835 107.72C12.3304 107.462 12.4835 106.89 12.2255 106.443C11.9675 105.997 11.3961 105.843 10.9492 106.101C10.5024 106.359 10.3493 106.931 10.6073 107.378C10.8653 107.825 11.4367 107.978 11.8835 107.72Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M12.9918 103.766C13.2791 103.6 13.3775 103.233 13.2117 102.946C13.0458 102.659 12.6785 102.56 12.3912 102.726C12.104 102.892 12.0055 103.259 12.1714 103.547C12.3372 103.834 12.7046 103.932 12.9918 103.766Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M14.1349 100.408C14.3262 100.298 14.3918 100.053 14.2813 99.8619C14.1709 99.6706 13.9262 99.605 13.7349 99.7155C13.5435 99.826 13.478 100.071 13.5884 100.262C13.6989 100.453 13.9436 100.519 14.1349 100.408Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.0206 99.8185C11.244 99.6895 11.3206 99.4038 11.1916 99.1803C11.0626 98.9569 10.7769 98.8804 10.5535 99.0094C10.3301 99.1383 10.2535 99.424 10.3825 99.6475C10.5115 99.8709 10.7972 99.9475 11.0206 99.8185Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.2917 99.3538C11.7066 99.1142 11.8488 98.5836 11.6093 98.1687C11.3697 97.7537 10.8391 97.6116 10.4242 97.8511C10.0092 98.0907 9.86706 98.6213 10.1066 99.0362C10.3462 99.4512 10.8768 99.5933 11.2917 99.3538Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.713 135.897C129.096 135.676 129.227 135.186 129.006 134.803C128.785 134.42 128.295 134.289 127.912 134.51C127.529 134.731 127.398 135.221 127.619 135.604C127.84 135.987 128.33 136.118 128.713 135.897Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.666 141.55C130.922 141.403 131.009 141.076 130.862 140.821C130.714 140.566 130.388 140.478 130.133 140.626C129.877 140.773 129.79 141.1 129.937 141.355C130.085 141.61 130.411 141.698 130.666 141.55Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.229 141.069C127.612 140.848 127.743 140.358 127.522 139.975C127.301 139.592 126.811 139.461 126.428 139.682C126.045 139.903 125.914 140.393 126.135 140.776C126.356 141.159 126.846 141.29 127.229 141.069Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.33 140.316C125.617 140.15 125.716 139.783 125.55 139.495C125.384 139.208 125.017 139.11 124.73 139.276C124.442 139.441 124.344 139.809 124.51 140.096C124.676 140.383 125.043 140.482 125.33 140.316Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.006 142.159C124.293 141.994 124.391 141.626 124.226 141.339C124.06 141.052 123.692 140.953 123.405 141.119C123.118 141.285 123.019 141.652 123.185 141.94C123.351 142.227 123.718 142.325 124.006 142.159Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.195 147.021C125.641 146.763 125.795 146.192 125.537 145.745C125.279 145.298 124.707 145.145 124.26 145.403C123.813 145.661 123.66 146.232 123.918 146.679C124.176 147.126 124.748 147.279 125.195 147.021Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.752 148.394C124.167 148.154 124.309 147.623 124.07 147.208C123.83 146.794 123.3 146.651 122.885 146.891C122.47 147.13 122.328 147.661 122.567 148.076C122.807 148.491 123.337 148.633 123.752 148.394Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.925 146.172C119.148 146.043 119.225 145.757 119.096 145.534C118.967 145.31 118.681 145.234 118.458 145.363C118.234 145.492 118.158 145.777 118.287 146.001C118.416 146.224 118.701 146.301 118.925 146.172Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.957 147.037C116.116 146.945 116.171 146.741 116.079 146.582C115.986 146.422 115.783 146.368 115.623 146.46C115.464 146.552 115.409 146.756 115.501 146.915C115.593 147.075 115.797 147.129 115.957 147.037Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.7 148.997C114.923 148.868 115 148.582 114.871 148.359C114.742 148.135 114.456 148.059 114.232 148.188C114.009 148.317 113.932 148.602 114.061 148.826C114.19 149.049 114.476 149.126 114.7 148.997Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.123 147.477C110.475 147.275 110.595 146.826 110.392 146.475C110.189 146.124 109.741 146.003 109.389 146.206C109.038 146.409 108.918 146.858 109.121 147.209C109.323 147.56 109.772 147.68 110.123 147.477Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M109.084 152.084C109.404 151.9 109.513 151.492 109.329 151.173C109.144 150.854 108.736 150.744 108.417 150.929C108.098 151.113 107.988 151.521 108.173 151.84C108.357 152.159 108.765 152.269 109.084 152.084Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.39 153.023C106.805 152.784 106.947 152.253 106.708 151.838C106.468 151.423 105.938 151.281 105.523 151.521C105.108 151.76 104.965 152.291 105.205 152.706C105.445 153.121 105.975 153.263 106.39 153.023Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.651 153.217C103.842 153.107 103.907 152.862 103.797 152.671C103.686 152.479 103.442 152.414 103.25 152.524C103.059 152.635 102.994 152.879 103.104 153.071C103.215 153.262 103.459 153.328 103.651 153.217Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M102.412 157.478C102.667 157.33 102.755 157.004 102.607 156.749C102.46 156.493 102.133 156.406 101.878 156.553C101.623 156.701 101.535 157.027 101.683 157.282C101.83 157.538 102.157 157.625 102.412 157.478Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.7372 152.317C98.0883 152.114 98.2086 151.665 98.0059 151.314C97.8032 150.963 97.3543 150.843 97.0032 151.046C96.6521 151.248 96.5318 151.697 96.7345 152.048C96.9372 152.399 97.3861 152.52 97.7372 152.317Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M100.29 160.475C100.609 160.291 100.718 159.883 100.534 159.564C100.35 159.244 99.9418 159.135 99.6226 159.319C99.3034 159.504 99.1941 159.912 99.3783 160.231C99.5626 160.55 99.9708 160.66 100.29 160.475Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M96.0097 152.929C96.3289 152.745 96.4382 152.336 96.2539 152.017C96.0697 151.698 95.6615 151.589 95.3423 151.773C95.0232 151.957 94.9138 152.365 95.0981 152.685C95.2823 153.004 95.6905 153.113 96.0097 152.929Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.5375 156.515C95.8248 156.349 95.9232 155.982 95.7573 155.695C95.5915 155.407 95.2242 155.309 94.9369 155.475C94.6496 155.641 94.5512 156.008 94.7171 156.295C94.8829 156.583 95.2502 156.681 95.5375 156.515Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M94.5141 160.882C94.8013 160.716 94.8998 160.349 94.7339 160.061C94.568 159.774 94.2007 159.676 93.9135 159.842C93.6262 160.007 93.5278 160.375 93.6936 160.662C93.8595 160.949 94.2268 161.048 94.5141 160.882Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.3136 157.875C91.6328 157.691 91.7422 157.283 91.5579 156.963C91.3736 156.644 90.9655 156.535 90.6463 156.719C90.3271 156.903 90.2177 157.311 90.402 157.631C90.5863 157.95 90.9945 158.059 91.3136 157.875Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M92.4919 164.053C92.8749 163.832 93.0061 163.342 92.785 162.959C92.5639 162.576 92.0741 162.445 91.6911 162.666C91.3081 162.887 91.1768 163.377 91.398 163.76C91.6191 164.143 92.1089 164.274 92.4919 164.053Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.9497 159.123C88.1091 159.031 88.1638 158.827 88.0717 158.667C87.9797 158.508 87.7758 158.453 87.6163 158.545C87.4569 158.638 87.4023 158.841 87.4943 159.001C87.5864 159.16 87.7902 159.215 87.9497 159.123Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.7834 161.106C86.9429 161.014 86.9975 160.81 86.9054 160.651C86.8134 160.491 86.6095 160.437 86.4501 160.529C86.2906 160.621 86.236 160.825 86.3281 160.984C86.4201 161.144 86.624 161.198 86.7834 161.106Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M82.04 157.295C82.1995 157.203 82.2541 156.999 82.162 156.84C82.07 156.68 81.8661 156.626 81.7067 156.718C81.5472 156.81 81.4926 157.014 81.5847 157.173C81.6767 157.332 81.8806 157.387 82.04 157.295Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.4061 156.467C80.6615 156.319 80.749 155.993 80.6015 155.737C80.4541 155.482 80.1276 155.394 79.8723 155.542C79.6169 155.689 79.5294 156.016 79.6768 156.271C79.8243 156.527 80.1508 156.614 80.4061 156.467Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.7069 159.259C79.9942 159.093 80.0926 158.726 79.9268 158.439C79.7609 158.152 79.3936 158.053 79.1063 158.219C78.8191 158.385 78.7206 158.752 78.8865 159.039C79.0523 159.327 79.4197 159.425 79.7069 159.259Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.2706 160.512C77.5579 160.346 77.6563 159.979 77.4905 159.691C77.3246 159.404 76.9573 159.306 76.67 159.471C76.3828 159.637 76.2844 160.005 76.4502 160.292C76.6161 160.579 76.9834 160.678 77.2706 160.512Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M73.6474 162.912C74.0304 162.691 74.1616 162.201 73.9405 161.818C73.7194 161.435 73.2296 161.304 72.8466 161.525C72.4636 161.746 72.3323 162.236 72.5535 162.619C72.7746 163.002 73.2644 163.133 73.6474 162.912Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.8357 157.908C71.2506 157.668 71.3928 157.138 71.1532 156.723C70.9136 156.308 70.383 156.166 69.9681 156.405C69.5532 156.645 69.411 157.175 69.6506 157.59C69.8901 158.005 70.4207 158.148 70.8357 157.908Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.8222 163.77C67.1414 163.585 67.2507 163.177 67.0665 162.858C66.8822 162.539 66.474 162.43 66.1548 162.614C65.8357 162.798 65.7263 163.206 65.9106 163.525C66.0949 163.845 66.503 163.954 66.8222 163.77Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.6427 158.788C66.8981 158.64 66.9855 158.314 66.8381 158.058C66.6907 157.803 66.3642 157.716 66.1088 157.863C65.8535 158.01 65.766 158.337 65.9134 158.592C66.0608 158.848 66.3874 158.935 66.6427 158.788Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.5737 160.676C65.0206 160.418 65.1737 159.846 64.9157 159.399C64.6577 158.953 64.0863 158.8 63.6394 159.058C63.1926 159.316 63.0395 159.887 63.2975 160.334C63.5555 160.781 64.1269 160.934 64.5737 160.676Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.1638 157.83C64.5468 157.609 64.678 157.119 64.4569 156.736C64.2357 156.353 63.746 156.222 63.363 156.443C62.9799 156.664 62.8487 157.154 63.0698 157.537C63.291 157.92 63.7807 158.051 64.1638 157.83Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.6413 162.407C61.0563 162.167 61.1984 161.637 60.9589 161.222C60.7193 160.807 60.1887 160.665 59.7738 160.904C59.3588 161.144 59.2167 161.674 59.4562 162.089C59.6958 162.504 60.2264 162.646 60.6413 162.407Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.1345 154.185C61.4856 153.982 61.6059 153.533 61.4031 153.182C61.2004 152.831 60.7515 152.711 60.4004 152.913C60.0493 153.116 59.929 153.565 60.1317 153.916C60.3344 154.267 60.7833 154.388 61.1345 154.185Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.518 158.738C55.7733 158.59 55.8608 158.264 55.7134 158.009C55.5659 157.753 55.2394 157.666 54.9841 157.813C54.7287 157.961 54.6412 158.287 54.7887 158.542C54.9361 158.798 55.2626 158.885 55.518 158.738Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.2279 156.902C55.5471 156.717 55.6565 156.309 55.4722 155.99C55.2879 155.671 54.8798 155.561 54.5606 155.746C54.2414 155.93 54.1321 156.338 54.3163 156.657C54.5006 156.976 54.9088 157.086 55.2279 156.902Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.5327 151.293C54.8838 151.09 55.0041 150.641 54.8013 150.29C54.5986 149.939 54.1497 149.818 53.7986 150.021C53.4475 150.224 53.3272 150.673 53.5299 151.024C53.7326 151.375 54.1816 151.495 54.5327 151.293Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M52.0182 151.742C52.3374 151.558 52.4468 151.15 52.2625 150.831C52.0782 150.512 51.6701 150.402 51.3509 150.586C51.0317 150.771 50.9223 151.179 51.1066 151.498C51.2909 151.817 51.699 151.927 52.0182 151.742Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.5112 151.265C51.9581 151.007 52.1112 150.435 51.8532 149.988C51.5952 149.541 51.0238 149.388 50.5769 149.646C50.1301 149.904 49.977 150.476 50.235 150.923C50.493 151.369 51.0643 151.523 51.5112 151.265Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.2445 152.417C47.4358 152.307 47.5014 152.062 47.3909 151.871C47.2805 151.679 47.0358 151.614 46.8445 151.724C46.6532 151.835 46.5876 152.079 46.6981 152.271C46.8085 152.462 47.0532 152.528 47.2445 152.417Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.1357 147.55C49.4548 147.366 49.5642 146.958 49.3799 146.639C49.1956 146.319 48.7875 146.21 48.4683 146.394C48.1491 146.579 48.0398 146.987 48.2241 147.306C48.4083 147.625 48.8165 147.735 49.1357 147.55Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.5922 147.015C46.9114 146.831 47.0207 146.423 46.8365 146.104C46.6522 145.785 46.244 145.675 45.9249 145.859C45.6057 146.044 45.4963 146.452 45.6806 146.771C45.8649 147.09 46.273 147.2 46.5922 147.015Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.2555 148.711C43.5747 148.526 43.6841 148.118 43.4998 147.799C43.3155 147.48 42.9074 147.37 42.5882 147.555C42.269 147.739 42.1596 148.147 42.3439 148.466C42.5282 148.786 42.9363 148.895 43.2555 148.711Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.4315 146.219C41.7188 146.053 41.8172 145.686 41.6514 145.399C41.4855 145.111 41.1182 145.013 40.8309 145.179C40.5437 145.345 40.4452 145.712 40.6111 145.999C40.7769 146.286 41.1443 146.385 41.4315 146.219Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.6557 141.93C44.0387 141.708 44.17 141.219 43.9488 140.836C43.7277 140.453 43.2379 140.321 42.8549 140.542C42.4719 140.764 42.3406 141.253 42.5618 141.636C42.7829 142.019 43.2727 142.151 43.6557 141.93Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.3302 144.581C39.6175 144.415 39.7159 144.048 39.5501 143.761C39.3842 143.473 39.0169 143.375 38.7296 143.541C38.4423 143.707 38.3439 144.074 38.5098 144.361C38.6756 144.648 39.043 144.747 39.3302 144.581Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.8022 143.806C37.2171 143.566 37.3593 143.036 37.1197 142.621C36.8802 142.206 36.3496 142.064 35.9347 142.303C35.5197 142.543 35.3776 143.073 35.6171 143.488C35.8567 143.903 36.3873 144.045 36.8022 143.806Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.4794 141.912C36.7667 141.746 36.8651 141.379 36.6992 141.091C36.5334 140.804 36.1661 140.706 35.8788 140.872C35.5915 141.037 35.4931 141.405 35.6589 141.692C35.8248 141.979 36.1921 142.078 36.4794 141.912Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.6189 142.023C36.0338 141.784 36.176 141.253 35.9364 140.838C35.6968 140.423 35.1663 140.281 34.7513 140.521C34.3364 140.76 34.1942 141.291 34.4338 141.706C34.6733 142.121 35.2039 142.263 35.6189 142.023Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.8937 140.901C35.149 140.753 35.2365 140.427 35.0891 140.171C34.9417 139.916 34.6152 139.829 34.3598 139.976C34.1045 140.123 34.017 140.45 34.1644 140.705C34.3118 140.961 34.6383 141.048 34.8937 140.901Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.0631 138.798C33.3823 138.614 33.4917 138.206 33.3074 137.886C33.1231 137.567 32.715 137.458 32.3958 137.642C32.0766 137.826 31.9673 138.235 32.1515 138.554C32.3358 138.873 32.744 138.982 33.0631 138.798Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.2384 137.233C33.4938 137.086 33.5812 136.759 33.4338 136.504C33.2864 136.248 32.9599 136.161 32.7045 136.308C32.4492 136.456 32.3617 136.782 32.5091 137.038C32.6566 137.293 32.9831 137.38 33.2384 137.233Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.0031 138.175C28.3223 137.991 28.4316 137.583 28.2474 137.263C28.0631 136.944 27.6549 136.835 27.3357 137.019C27.0166 137.203 26.9072 137.612 27.0915 137.931C27.2758 138.25 27.6839 138.359 28.0031 138.175Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.8112 133.432C30.0665 133.285 30.154 132.958 30.0066 132.703C29.8591 132.448 29.5326 132.36 29.2773 132.507C29.0219 132.655 28.9345 132.981 29.0819 133.237C29.2293 133.492 29.5558 133.58 29.8112 133.432Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.5349 133.09C28.9498 132.851 29.092 132.32 28.8524 131.905C28.6129 131.49 28.0823 131.348 27.6673 131.588C27.2524 131.827 27.1102 132.358 27.3498 132.773C27.5894 133.188 28.1199 133.33 28.5349 133.09Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.4208 131.961C27.7081 131.795 27.8065 131.428 27.6406 131.141C27.4748 130.853 27.1075 130.755 26.8202 130.921C26.5329 131.087 26.4345 131.454 26.6004 131.741C26.7662 132.029 27.1335 132.127 27.4208 131.961Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.7902 127.394C28.9815 127.284 29.047 127.039 28.9366 126.848C28.8261 126.656 28.5815 126.591 28.3901 126.701C28.1988 126.812 28.1333 127.056 28.2437 127.248C28.3542 127.439 28.5988 127.504 28.7902 127.394Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.0438 129.324C21.3311 129.159 21.4295 128.791 21.2637 128.504C21.0978 128.217 20.7305 128.118 20.4432 128.284C20.156 128.45 20.0575 128.817 20.2234 129.105C20.3893 129.392 20.7566 129.49 21.0438 129.324Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.6115 122.681C27.9307 122.497 28.04 122.089 27.8557 121.77C27.6715 121.451 27.2633 121.341 26.9441 121.525C26.625 121.71 26.5156 122.118 26.6999 122.437C26.8842 122.756 27.2923 122.866 27.6115 122.681Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.4472 125.084C21.7664 124.9 21.8757 124.492 21.6914 124.173C21.5072 123.854 21.099 123.744 20.7798 123.929C20.4607 124.113 20.3513 124.521 20.5356 124.84C20.7199 125.159 21.128 125.269 21.4472 125.084Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.0187 121.671C22.21 121.561 22.2756 121.316 22.1651 121.125C22.0546 120.934 21.81 120.868 21.6187 120.978C21.4273 121.089 21.3618 121.334 21.4722 121.525C21.5827 121.716 21.8274 121.782 22.0187 121.671Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.7277 121.57C20.983 121.422 21.0705 121.096 20.9231 120.841C20.7757 120.585 20.4491 120.498 20.1938 120.645C19.9384 120.793 19.851 121.119 19.9984 121.374C20.1458 121.63 20.4723 121.717 20.7277 121.57Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.1283 119.603C18.3836 119.456 18.4711 119.129 18.3237 118.874C18.1763 118.618 17.8498 118.531 17.5944 118.678C17.3391 118.826 17.2516 119.152 17.399 119.408C17.5464 119.663 17.873 119.75 18.1283 119.603Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M16.5104 117.069C16.7017 116.958 16.7673 116.713 16.6568 116.522C16.5463 116.331 16.3017 116.265 16.1104 116.376C15.919 116.486 15.8535 116.731 15.9639 116.922C16.0744 117.113 16.3191 117.179 16.5104 117.069Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.42 113.964C20.6114 113.853 20.6769 113.609 20.5665 113.417C20.456 113.226 20.2114 113.16 20.02 113.271C19.8287 113.381 19.7631 113.626 19.8736 113.817C19.9841 114.009 20.2287 114.074 20.42 113.964Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.5414 111.505C20.7327 111.394 20.7983 111.15 20.6878 110.958C20.5773 110.767 20.3327 110.701 20.1414 110.812C19.95 110.922 19.8845 111.167 19.9949 111.358C20.1054 111.55 20.3501 111.615 20.5414 111.505Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.978 110.522C22.3929 110.283 22.5351 109.752 22.2955 109.337C22.056 108.922 21.5254 108.78 21.1104 109.02C20.6955 109.259 20.5533 109.79 20.7929 110.205C21.0325 110.62 21.5631 110.762 21.978 110.522Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M14.8684 110.62C15.2514 110.399 15.3826 109.909 15.1615 109.526C14.9403 109.143 14.4506 109.012 14.0675 109.233C13.6845 109.454 13.5533 109.944 13.7744 110.327C13.9956 110.71 14.4853 110.841 14.8684 110.62Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.1903 105.697C18.4457 105.549 18.5331 105.223 18.3857 104.968C18.2383 104.712 17.9118 104.625 17.6564 104.772C17.4011 104.92 17.3136 105.246 17.461 105.501C17.6085 105.757 17.935 105.844 18.1903 105.697Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.9623 151.797C90.2815 151.613 90.3909 151.205 90.2066 150.885C90.0223 150.566 89.6142 150.457 89.295 150.641C88.9758 150.825 88.8664 151.234 89.0507 151.553C89.235 151.872 89.6431 151.981 89.9623 151.797Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M92.1254 157.946C92.3807 157.798 92.4682 157.472 92.3208 157.217C92.1734 156.961 91.8468 156.874 91.5915 157.021C91.3361 157.169 91.2487 157.495 91.3961 157.75C91.5435 158.006 91.87 158.093 92.1254 157.946Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M88.9657 156.611C89.2211 156.463 89.3085 156.137 89.1611 155.881C89.0137 155.626 88.6872 155.539 88.4318 155.686C88.1765 155.833 88.089 156.16 88.2364 156.415C88.3838 156.671 88.7104 156.758 88.9657 156.611Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M85.4038 156.047C85.7868 155.826 85.918 155.336 85.6969 154.953C85.4757 154.57 84.986 154.439 84.6029 154.66C84.2199 154.881 84.0887 155.371 84.3098 155.754C84.531 156.137 85.0207 156.268 85.4038 156.047Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.9677 154.1C82.159 153.99 82.2245 153.745 82.1141 153.554C82.0036 153.362 81.759 153.297 81.5676 153.407C81.3763 153.518 81.3107 153.762 81.4212 153.954C81.5317 154.145 81.7763 154.211 81.9677 154.1Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.6188 154.032C80.8741 153.885 80.9616 153.558 80.8142 153.303C80.6668 153.047 80.3403 152.96 80.0849 153.107C79.8296 153.255 79.7421 153.581 79.8895 153.837C80.0369 154.092 80.3634 154.179 80.6188 154.032Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.8678 158.741C79.0591 158.63 79.1247 158.386 79.0142 158.194C78.9038 158.003 78.6591 157.938 78.4678 158.048C78.2764 158.158 78.2109 158.403 78.3214 158.594C78.4318 158.786 78.6765 158.851 78.8678 158.741Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.2812 159.196C77.7281 158.938 77.8812 158.366 77.6232 157.919C77.3652 157.472 76.7938 157.319 76.347 157.577C75.9001 157.835 75.747 158.407 76.005 158.854C76.263 159.3 76.8344 159.454 77.2812 159.196Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.6618 158.396C75.0129 158.193 75.1332 157.744 74.9305 157.393C74.7278 157.042 74.2788 156.922 73.9277 157.124C73.5766 157.327 73.4563 157.776 73.659 158.127C73.8617 158.478 74.3107 158.599 74.6618 158.396Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M73.0158 162.352C73.3988 162.13 73.5301 161.641 73.3089 161.258C73.0878 160.875 72.598 160.743 72.215 160.965C71.832 161.186 71.7008 161.675 71.9219 162.059C72.143 162.442 72.6328 162.573 73.0158 162.352Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.1726 155.825C70.5875 155.586 70.7297 155.055 70.4901 154.64C70.2505 154.225 69.72 154.083 69.305 154.323C68.8901 154.562 68.7479 155.093 68.9875 155.508C69.227 155.923 69.7576 156.065 70.1726 155.825Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.3439 159.614C63.5992 159.466 63.6867 159.14 63.5393 158.884C63.3919 158.629 63.0653 158.541 62.81 158.689C62.5547 158.836 62.4672 159.163 62.6146 159.418C62.762 159.674 63.0885 159.761 63.3439 159.614Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M65.0407 154.01C65.3279 153.845 65.4264 153.477 65.2605 153.19C65.0946 152.903 64.7273 152.804 64.4401 152.97C64.1528 153.136 64.0544 153.503 64.2202 153.791C64.3861 154.078 64.7534 154.176 65.0407 154.01Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.891 154.558C63.1144 154.429 63.191 154.143 63.062 153.92C62.933 153.696 62.6473 153.62 62.4238 153.749C62.2004 153.878 62.1239 154.164 62.2529 154.387C62.3819 154.61 62.6676 154.687 62.891 154.558Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.7819 156.778C61.1011 156.593 61.2104 156.185 61.0262 155.866C60.8419 155.547 60.4337 155.437 60.1146 155.622C59.7954 155.806 59.686 156.214 59.8703 156.533C60.0546 156.852 60.4627 156.962 60.7819 156.778Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.2785 155.642C59.5657 155.476 59.6641 155.109 59.4983 154.821C59.3324 154.534 58.9651 154.436 58.6779 154.602C58.3906 154.767 58.2922 155.135 58.458 155.422C58.6239 155.709 58.9912 155.808 59.2785 155.642Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.7816 156.659C56.0689 156.493 56.1673 156.126 56.0015 155.838C55.8356 155.551 55.4683 155.453 55.181 155.619C54.8938 155.784 54.7953 156.152 54.9612 156.439C55.127 156.726 55.4944 156.825 55.7816 156.659Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.1469 151.285C56.4022 151.138 56.4897 150.812 56.3423 150.556C56.1948 150.301 55.8683 150.213 55.613 150.361C55.3576 150.508 55.2701 150.835 55.4176 151.09C55.565 151.345 55.8915 151.433 56.1469 151.285Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.948 149.743C55.3948 149.485 55.5479 148.913 55.2899 148.467C55.0319 148.02 54.4606 147.867 54.0137 148.125C53.5668 148.383 53.4137 148.954 53.6717 149.401C53.9297 149.848 54.5011 150.001 54.948 149.743Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.0165 150.935C53.3357 150.751 53.4451 150.343 53.2608 150.023C53.0765 149.704 52.6684 149.595 52.3492 149.779C52.03 149.963 51.9206 150.372 52.1049 150.691C52.2892 151.01 52.6973 151.119 53.0165 150.935Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.0334 146.957C54.4164 146.736 54.5476 146.246 54.3265 145.863C54.1054 145.48 53.6156 145.349 53.2326 145.57C52.8496 145.791 52.7183 146.281 52.9395 146.664C53.1606 147.047 53.6504 147.178 54.0334 146.957Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.5178 153.417C49.9327 153.178 50.0749 152.647 49.8353 152.232C49.5958 151.817 49.0652 151.675 48.6502 151.915C48.2353 152.154 48.0931 152.685 48.3327 153.1C48.5723 153.515 49.1028 153.657 49.5178 153.417Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.0896 150.543C48.5364 150.285 48.6895 149.714 48.4315 149.267C48.1735 148.82 47.6022 148.667 47.1553 148.925C46.7084 149.183 46.5553 149.754 46.8133 150.201C47.0713 150.648 47.6427 150.801 48.0896 150.543Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.6906 148.654C47.0098 148.469 47.1191 148.061 46.9349 147.742C46.7506 147.423 46.3424 147.314 46.0232 147.498C45.7041 147.682 45.5947 148.09 45.779 148.409C45.9633 148.729 46.3714 148.838 46.6906 148.654Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.3957 148.553C43.6829 148.387 43.7813 148.02 43.6155 147.732C43.4496 147.445 43.0823 147.347 42.795 147.512C42.5078 147.678 42.4094 148.046 42.5752 148.333C42.7411 148.62 43.1084 148.719 43.3957 148.553Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.8388 146.523C42.2538 146.284 42.3959 145.753 42.1564 145.338C41.9168 144.923 41.3862 144.781 40.9713 145.021C40.5563 145.26 40.4142 145.791 40.6537 146.206C40.8933 146.621 41.4239 146.763 41.8388 146.523Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.4645 146.815C39.7518 146.65 39.8502 146.282 39.6843 145.995C39.5185 145.708 39.1512 145.609 38.8639 145.775C38.5766 145.941 38.4782 146.308 38.6441 146.596C38.8099 146.883 39.1772 146.981 39.4645 146.815Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.0685 147.467C38.3238 147.32 38.4113 146.993 38.2639 146.738C38.1165 146.482 37.79 146.395 37.5346 146.542C37.2793 146.69 37.1918 147.016 37.3392 147.272C37.4866 147.527 37.8131 147.615 38.0685 147.467Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.2477 144.975C38.5669 144.791 38.6763 144.382 38.492 144.063C38.3077 143.744 37.8996 143.635 37.5804 143.819C37.2612 144.003 37.1518 144.411 37.3361 144.731C37.5204 145.05 37.9285 145.159 38.2477 144.975Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.6579 142.619C38.009 142.416 38.1293 141.967 37.9266 141.616C37.7239 141.265 37.2749 141.144 36.9238 141.347C36.5727 141.55 36.4524 141.999 36.6551 142.35C36.8578 142.701 37.3068 142.821 37.6579 142.619Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.7103 144.32C34.9337 144.191 35.0103 143.905 34.8813 143.682C34.7523 143.459 34.4666 143.382 34.2432 143.511C34.0197 143.64 33.9432 143.926 34.0722 144.149C34.2012 144.373 34.4869 144.449 34.7103 144.32Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.9399 138.177C35.259 137.993 35.3684 137.585 35.1841 137.266C34.9998 136.946 34.5917 136.837 34.2725 137.021C33.9533 137.206 33.844 137.614 34.0282 137.933C34.2125 138.252 34.6207 138.361 34.9399 138.177Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.5373 138.83C29.9522 138.591 30.0944 138.06 29.8549 137.645C29.6153 137.23 29.0847 137.088 28.6698 137.328C28.2548 137.567 28.1127 138.098 28.3522 138.513C28.5918 138.928 29.1224 139.07 29.5373 138.83Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.3915 136.178C28.7426 135.976 28.8629 135.527 28.6602 135.176C28.4575 134.824 28.0086 134.704 27.6575 134.907C27.3064 135.11 27.1861 135.559 27.3888 135.91C27.5915 136.261 28.0404 136.381 28.3915 136.178Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.0143 138.205C25.2056 138.094 25.2712 137.849 25.1607 137.658C25.0502 137.467 24.8056 137.401 24.6143 137.512C24.4229 137.622 24.3574 137.867 24.4678 138.058C24.5783 138.249 24.823 138.315 25.0143 138.205Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.3546 131.037C30.546 130.927 30.6115 130.682 30.501 130.491C30.3906 130.3 30.1459 130.234 29.9546 130.344C29.7633 130.455 29.6977 130.7 29.8082 130.891C29.9186 131.082 30.1633 131.148 30.3546 131.037Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.6386 132.875C27.0855 132.617 27.2386 132.046 26.9806 131.599C26.7226 131.152 26.1512 130.999 25.7044 131.257C25.2575 131.515 25.1044 132.086 25.3624 132.533C25.6204 132.98 26.1918 133.133 26.6386 132.875Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.777 131.522C24.0323 131.375 24.1198 131.048 23.9724 130.793C23.825 130.538 23.4985 130.45 23.2431 130.598C22.9878 130.745 22.9003 131.072 23.0477 131.327C23.1951 131.582 23.5216 131.67 23.777 131.522Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.1274 128.122C25.4785 127.92 25.5988 127.471 25.3961 127.12C25.1934 126.769 24.7444 126.648 24.3933 126.851C24.0422 127.054 23.9219 127.503 24.1246 127.854C24.3273 128.205 24.7763 128.325 25.1274 128.122Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.8998 127.867C23.1551 127.72 23.2426 127.394 23.0952 127.138C22.9478 126.883 22.6213 126.795 22.3659 126.943C22.1106 127.09 22.0231 127.417 22.1705 127.672C22.3179 127.927 22.6444 128.015 22.8998 127.867Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.9704 124.784C24.3854 124.544 24.5275 124.014 24.288 123.599C24.0484 123.184 23.5178 123.042 23.1029 123.281C22.6879 123.521 22.5458 124.051 22.7853 124.466C23.0249 124.881 23.5555 125.023 23.9704 124.784Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.0118 125.411C17.2353 125.282 17.3118 124.996 17.1828 124.772C17.0538 124.549 16.7681 124.472 16.5447 124.601C16.3213 124.73 16.2447 125.016 16.3737 125.24C16.5027 125.463 16.7884 125.54 17.0118 125.411Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.079 122.715C20.3982 122.531 20.5076 122.123 20.3233 121.803C20.139 121.484 19.7309 121.375 19.4117 121.559C19.0925 121.743 18.9831 122.152 19.1674 122.471C19.3517 122.79 19.7598 122.899 20.079 122.715Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.3291 121.953C18.744 121.713 18.8862 121.183 18.6466 120.768C18.407 120.353 17.8765 120.211 17.4615 120.45C17.0466 120.69 16.9044 121.22 17.144 121.635C17.3835 122.05 17.9141 122.192 18.3291 121.953Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.9604 117.699C22.3434 117.478 22.4746 116.988 22.2535 116.605C22.0324 116.222 21.5426 116.091 21.1596 116.312C20.7766 116.533 20.6453 117.023 20.8665 117.406C21.0876 117.789 21.5774 117.92 21.9604 117.699Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M13.6263 119.814C13.8498 119.685 13.9263 119.399 13.7973 119.175C13.6683 118.952 13.3826 118.875 13.1592 119.004C12.9358 119.133 12.8592 119.419 12.9882 119.643C13.1172 119.866 13.4029 119.943 13.6263 119.814Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M12.3347 119.712C12.7177 119.491 12.8489 119.001 12.6278 118.618C12.4066 118.235 11.9169 118.104 11.5339 118.325C11.1508 118.546 11.0196 119.036 11.2407 119.419C11.4619 119.802 11.9516 119.933 12.3347 119.712Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.1701 115.265C20.4893 115.081 20.5986 114.672 20.4143 114.353C20.2301 114.034 19.8219 113.925 19.5027 114.109C19.1836 114.293 19.0742 114.701 19.2585 115.021C19.4428 115.34 19.8509 115.449 20.1701 115.265Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M15.3232 114.21C15.6423 114.026 15.7517 113.618 15.5674 113.299C15.3831 112.98 14.975 112.87 14.6558 113.055C14.3366 113.239 14.2273 113.647 14.4116 113.966C14.5958 114.285 15.004 114.395 15.3232 114.21Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.9792 114.292C12.3941 114.052 12.5363 113.521 12.2968 113.106C12.0572 112.691 11.5266 112.549 11.1117 112.789C10.6967 113.028 10.5546 113.559 10.7941 113.974C11.0337 114.389 11.5643 114.531 11.9792 114.292Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M11.2428 112.482C11.4662 112.353 11.5428 112.067 11.4138 111.844C11.2848 111.62 10.9991 111.544 10.7756 111.673C10.5522 111.802 10.4757 112.088 10.6047 112.311C10.7337 112.534 11.0194 112.611 11.2428 112.482Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.7501 107.337C17.9415 107.227 18.007 106.982 17.8965 106.791C17.7861 106.599 17.5414 106.534 17.3501 106.644C17.1588 106.755 17.0932 106.999 17.2037 107.191C17.3141 107.382 17.5588 107.448 17.7501 107.337Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M15.0201 105.678C15.2436 105.549 15.3201 105.263 15.1911 105.04C15.0621 104.816 14.7764 104.74 14.553 104.869C14.3296 104.998 14.253 105.283 14.382 105.507C14.511 105.73 14.7967 105.807 15.0201 105.678Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M9.82633 104.823C10.1455 104.639 10.2549 104.231 10.0706 103.912C9.88631 103.593 9.47817 103.483 9.15899 103.668C8.8398 103.852 8.73044 104.26 8.91473 104.579C9.09901 104.898 9.50715 105.008 9.82633 104.823Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M14.5583 100.473C14.8774 100.289 14.9868 99.8809 14.8025 99.5617C14.6182 99.2425 14.2101 99.1332 13.8909 99.3175C13.5717 99.5017 13.4624 99.9099 13.6467 100.229C13.8309 100.548 14.2391 100.658 14.5583 100.473Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M5.91466 101.919C6.13809 101.79 6.21464 101.504 6.08565 101.281C5.95665 101.057 5.67095 100.981 5.44752 101.11C5.22409 101.239 5.14754 101.525 5.27654 101.748C5.40553 101.971 5.69123 102.048 5.91466 101.919Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M12.4169 99.1667C12.7361 98.9824 12.8455 98.5743 12.6612 98.2551C12.4769 97.9359 12.0687 97.8265 11.7496 98.0108C11.4304 98.1951 11.321 98.6032 11.5053 98.9224C11.6896 99.2416 12.0977 99.351 12.4169 99.1667Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M5.27364 99.2068C5.68858 98.9672 5.83075 98.4367 5.59118 98.0217C5.35162 97.6068 4.82104 97.4646 4.4061 97.7042C3.99116 97.9437 3.84899 98.4743 4.08856 98.8893C4.32812 99.3042 4.8587 99.4464 5.27364 99.2068Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.194 165.988C76.5132 165.803 76.6226 165.395 76.4383 165.076C76.254 164.757 75.8459 164.648 75.5267 164.832C75.2075 165.016 75.0981 165.424 75.2824 165.743C75.4667 166.063 75.8748 166.172 76.194 165.988Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.8174 170.556C71.2323 170.316 71.3745 169.786 71.1349 169.371C70.8953 168.956 70.3647 168.814 69.9498 169.053C69.5349 169.293 69.3927 169.823 69.6323 170.238C69.8718 170.653 70.4024 170.796 70.8174 170.556Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.4664 166.211C70.6578 166.101 70.7233 165.856 70.6129 165.665C70.5024 165.474 70.2577 165.408 70.0664 165.518C69.8751 165.629 69.8095 165.874 69.92 166.065C70.0305 166.256 70.2751 166.322 70.4664 166.211Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.2375 164.88C70.6524 164.641 70.7946 164.11 70.5551 163.695C70.3155 163.28 69.7849 163.138 69.37 163.378C68.955 163.617 68.8129 164.148 69.0524 164.563C69.292 164.978 69.8226 165.12 70.2375 164.88Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M69.1283 164.827C69.3836 164.68 69.4711 164.353 69.3237 164.098C69.1763 163.843 68.8498 163.755 68.5944 163.902C68.3391 164.05 68.2516 164.376 68.399 164.632C68.5464 164.887 68.8729 164.975 69.1283 164.827Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M65.7273 166.945C66.1422 166.705 66.2844 166.175 66.0448 165.76C65.8052 165.345 65.2747 165.203 64.8597 165.442C64.4448 165.682 64.3026 166.212 64.5422 166.627C64.7817 167.042 65.3123 167.184 65.7273 166.945Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.8291 168.195C64.2759 167.937 64.429 167.366 64.171 166.919C63.9131 166.472 63.3417 166.319 62.8948 166.577C62.4479 166.835 62.2948 167.406 62.5528 167.853C62.8108 168.3 63.3822 168.453 63.8291 168.195Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.8766 166.548C62.1639 166.382 62.2623 166.015 62.0964 165.728C61.9306 165.44 61.5633 165.342 61.276 165.508C60.9887 165.674 60.8903 166.041 61.0562 166.328C61.222 166.616 61.5893 166.714 61.8766 166.548Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.7328 164.704C60.052 164.519 60.1614 164.111 59.9771 163.792C59.7928 163.473 59.3847 163.363 59.0655 163.548C58.7463 163.732 58.6369 164.14 58.8212 164.459C59.0055 164.778 59.4136 164.888 59.7328 164.704Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.8988 165.531C58.218 165.347 58.3274 164.939 58.1431 164.62C57.9588 164.3 57.5507 164.191 57.2315 164.375C56.9123 164.56 56.803 164.968 56.9872 165.287C57.1715 165.606 57.5797 165.715 57.8988 165.531Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.4377 162.33C57.7888 162.127 57.9091 161.678 57.7064 161.327C57.5037 160.976 57.0547 160.856 56.7036 161.058C56.3525 161.261 56.2322 161.71 56.4349 162.061C56.6376 162.412 57.0866 162.533 57.4377 162.33Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.7611 158.753C58.0165 158.605 58.1039 158.279 57.9565 158.023C57.8091 157.768 57.4826 157.681 57.2272 157.828C56.9719 157.975 56.8844 158.302 57.0318 158.557C57.1792 158.813 57.5058 158.9 57.7611 158.753Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.2587 161.893C55.6098 161.69 55.7301 161.241 55.5274 160.89C55.3247 160.539 54.8757 160.419 54.5246 160.621C54.1735 160.824 54.0532 161.273 54.256 161.624C54.4587 161.975 54.9076 162.095 55.2587 161.893Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.0324 155.358C56.4793 155.1 56.6324 154.529 56.3744 154.082C56.1164 153.635 55.545 153.482 55.0982 153.74C54.6513 153.998 54.4982 154.57 54.7562 155.016C55.0142 155.463 55.5856 155.616 56.0324 155.358Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.8165 160.335C52.1038 160.169 52.2022 159.802 52.0364 159.515C51.8705 159.227 51.5032 159.129 51.2159 159.295C50.9287 159.461 50.8302 159.828 50.9961 160.115C51.162 160.403 51.5293 160.501 51.8165 160.335Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.474 159.49C46.7613 159.324 46.8597 158.957 46.6939 158.67C46.528 158.382 46.1607 158.284 45.8734 158.45C45.5861 158.616 45.4877 158.983 45.6536 159.27C45.8194 159.557 46.1868 159.656 46.474 159.49Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.8458 156C46.1331 155.834 46.2315 155.467 46.0657 155.179C45.8998 154.892 45.5325 154.794 45.2452 154.959C44.958 155.125 44.8595 155.493 45.0254 155.78C45.1913 156.067 45.5586 156.166 45.8458 156Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.34 154.595C43.6592 154.411 43.7685 154.003 43.5843 153.684C43.4 153.365 42.9918 153.255 42.6727 153.44C42.3535 153.624 42.2441 154.032 42.4284 154.351C42.6127 154.67 43.0208 154.78 43.34 154.595Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.9753 151.956C45.3902 151.716 45.5324 151.186 45.2928 150.771C45.0533 150.356 44.5227 150.214 44.1078 150.453C43.6928 150.693 43.5507 151.223 43.7902 151.638C44.0298 152.053 44.5604 152.195 44.9753 151.956Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.108 150.184C45.3634 150.036 45.4509 149.71 45.3034 149.455C45.156 149.199 44.8295 149.112 44.5742 149.259C44.3188 149.407 44.2313 149.733 44.3788 149.988C44.5262 150.244 44.8527 150.331 45.108 150.184Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.6159 150.005C42.9031 149.839 43.0016 149.471 42.8357 149.184C42.6698 148.897 42.3025 148.798 42.0153 148.964C41.728 149.13 41.6296 149.498 41.7954 149.785C41.9613 150.072 42.3286 150.17 42.6159 150.005Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.7853 149.905C40.9766 149.794 41.0422 149.55 40.9317 149.358C40.8212 149.167 40.5766 149.101 40.3853 149.212C40.1939 149.322 40.1284 149.567 40.2388 149.758C40.3493 149.95 40.594 150.015 40.7853 149.905Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.3608 147.03C41.7438 146.809 41.875 146.319 41.6539 145.936C41.4328 145.553 40.943 145.422 40.56 145.643C40.177 145.864 40.0457 146.354 40.2669 146.737C40.488 147.12 40.9778 147.251 41.3608 147.03Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.0265 149.533C37.3138 149.367 37.4122 149 37.2463 148.712C37.0805 148.425 36.7132 148.327 36.4259 148.493C36.1386 148.659 36.0402 149.026 36.2061 149.313C36.3719 149.6 36.7392 149.699 37.0265 149.533Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.9293 146.429C37.1207 146.318 37.1862 146.074 37.0757 145.882C36.9653 145.691 36.7206 145.626 36.5293 145.736C36.338 145.846 36.2724 146.091 36.3829 146.282C36.4933 146.474 36.738 146.539 36.9293 146.429Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.5514 140.292C39.7748 140.163 39.8514 139.878 39.7224 139.654C39.5934 139.431 39.3077 139.354 39.0842 139.483C38.8608 139.612 38.7843 139.898 38.9133 140.121C39.0423 140.345 39.328 140.421 39.5514 140.292Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.0298 141.67C35.4447 141.43 35.5869 140.9 35.3473 140.485C35.1077 140.07 34.5771 139.928 34.1622 140.167C33.7473 140.407 33.6051 140.937 33.8447 141.352C34.0842 141.767 34.6148 141.91 35.0298 141.67Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.7043 139.238C35.0554 139.035 35.1757 138.586 34.973 138.235C34.7703 137.884 34.3213 137.764 33.9702 137.966C33.6191 138.169 33.4988 138.618 33.7015 138.969C33.9042 139.32 34.3532 139.44 34.7043 139.238Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.5661 137.812C29.9491 137.591 30.0804 137.101 29.8592 136.718C29.6381 136.335 29.1483 136.204 28.7653 136.425C28.3823 136.646 28.251 137.136 28.4722 137.519C28.6933 137.902 29.1831 138.033 29.5661 137.812Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.1335 133.711C34.4526 133.527 34.562 133.119 34.3777 132.8C34.1934 132.48 33.7853 132.371 33.4661 132.555C33.1469 132.74 33.0376 133.148 33.2219 133.467C33.4061 133.786 33.8143 133.895 34.1335 133.711Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.0468 131.833C32.2381 131.722 32.3036 131.478 32.1932 131.286C32.0827 131.095 31.8381 131.029 31.6467 131.14C31.4554 131.25 31.3898 131.495 31.5003 131.686C31.6108 131.878 31.8554 131.943 32.0468 131.833Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.6242 132.307C29.9753 132.105 30.0956 131.656 29.8929 131.305C29.6902 130.954 29.2412 130.833 28.8901 131.036C28.539 131.239 28.4187 131.688 28.6214 132.039C28.8241 132.39 29.2731 132.51 29.6242 132.307Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.0205 131.268C27.4354 131.028 27.5776 130.498 27.338 130.083C27.0984 129.668 26.5679 129.526 26.1529 129.765C25.738 130.005 25.5958 130.535 25.8354 130.95C26.0749 131.365 26.6055 131.507 27.0205 131.268Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.8461 129.634C26.1014 129.487 26.1889 129.16 26.0415 128.905C25.8941 128.649 25.5675 128.562 25.3122 128.709C25.0569 128.857 24.9694 129.183 25.1168 129.439C25.2642 129.694 25.5907 129.781 25.8461 129.634Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.6115 127.496C24.9626 127.293 25.0829 126.844 24.8802 126.493C24.6775 126.142 24.2285 126.022 23.8774 126.224C23.5263 126.427 23.406 126.876 23.6087 127.227C23.8114 127.578 24.2604 127.698 24.6115 127.496Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.6359 122.59C27.8593 122.461 27.9358 122.175 27.8068 121.952C27.6778 121.729 27.3921 121.652 27.1687 121.781C26.9453 121.91 26.8687 122.196 26.9977 122.419C27.1267 122.643 27.4124 122.719 27.6359 122.59Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.494 121.435C19.7813 121.269 19.8797 120.901 19.7139 120.614C19.548 120.327 19.1807 120.228 18.8934 120.394C18.6062 120.56 18.5077 120.927 18.6736 121.215C18.8394 121.502 19.2068 121.6 19.494 121.435Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.0123 117.516C24.2677 117.368 24.3552 117.042 24.2077 116.786C24.0603 116.531 23.7338 116.444 23.4785 116.591C23.2231 116.738 23.1356 117.065 23.2831 117.32C23.4305 117.576 23.757 117.663 24.0123 117.516Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.5044 115.304C22.9512 115.046 23.1043 114.475 22.8463 114.028C22.5883 113.581 22.017 113.428 21.5701 113.686C21.1232 113.944 20.9701 114.515 21.2281 114.962C21.4861 115.409 22.0575 115.562 22.5044 115.304Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.6723 112.243C27.9595 112.078 28.058 111.71 27.8921 111.423C27.7262 111.136 27.3589 111.037 27.0717 111.203C26.7844 111.369 26.686 111.736 26.8518 112.024C27.0177 112.311 27.385 112.409 27.6723 112.243Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.5947 111.053C24.0096 110.813 24.1518 110.283 23.9122 109.868C23.6727 109.453 23.1421 109.311 22.7271 109.55C22.3122 109.79 22.17 110.321 22.4096 110.735C22.6492 111.15 23.1797 111.293 23.5947 111.053Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.6564 107.82C24.9756 107.636 25.085 107.228 24.9007 106.908C24.7164 106.589 24.3082 106.48 23.9891 106.664C23.6699 106.848 23.5605 107.257 23.7448 107.576C23.9291 107.895 24.3372 108.004 24.6564 107.82Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.726 107.159C18.9173 107.048 18.9828 106.804 18.8724 106.612C18.7619 106.421 18.5173 106.356 18.3259 106.466C18.1346 106.576 18.069 106.821 18.1795 107.012C18.29 107.204 18.5346 107.269 18.726 107.159Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.1051 103.28C18.3924 103.114 18.4908 102.747 18.325 102.46C18.1591 102.172 17.7918 102.074 17.5045 102.24C17.2172 102.406 17.1188 102.773 17.2847 103.06C17.4505 103.347 17.8179 103.446 18.1051 103.28Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.3694 101.32C23.8162 101.062 23.9693 100.49 23.7113 100.044C23.4533 99.5967 22.8819 99.4436 22.4351 99.7016C21.9882 99.9596 21.8351 100.531 22.0931 100.978C22.3511 101.425 22.9225 101.578 23.3694 101.32Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M16.8229 97.8559C17.1421 97.6716 17.2515 97.2635 17.0672 96.9443C16.8829 96.6251 16.4748 96.5157 16.1556 96.7C15.8364 96.8843 15.727 97.2924 15.9113 97.6116C16.0956 97.9308 16.5037 98.0402 16.8229 97.8559Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.5097 95.5719C18.8608 95.3692 18.9811 94.9202 18.7784 94.5691C18.5757 94.218 18.1267 94.0977 17.7756 94.3004C17.4245 94.5032 17.3042 94.9521 17.5069 95.3032C17.7096 95.6543 18.1586 95.7746 18.5097 95.5719Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.2834 94.2397C20.6025 94.0554 20.7119 93.6473 20.5276 93.3281C20.3433 93.0089 19.9352 92.8995 19.616 93.0838C19.2968 93.2681 19.1875 93.6762 19.3718 93.9954C19.556 94.3146 19.9642 94.424 20.2834 94.2397Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.7546 91.4615C18.1057 91.2588 18.226 90.8099 18.0233 90.4588C17.8205 90.1077 17.3716 89.9874 17.0205 90.1901C16.6694 90.3928 16.5491 90.8418 16.7518 91.1929C16.9545 91.544 17.4035 91.6643 17.7546 91.4615Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.0675 89.3187C23.2909 89.1897 23.3675 88.904 23.2385 88.6806C23.1095 88.4572 22.8238 88.3806 22.6004 88.5096C22.3769 88.6386 22.3004 88.9243 22.4294 89.1477C22.5584 89.3711 22.8441 89.4477 23.0675 89.3187Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.057 88.0931C25.3124 87.9456 25.3999 87.6191 25.2524 87.3638C25.105 87.1084 24.7785 87.0209 24.5231 87.1684C24.2678 87.3158 24.1803 87.6423 24.3277 87.8977C24.4752 88.153 24.8017 88.2405 25.057 88.0931Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.5341 171.379C77.8533 171.195 77.9626 170.786 77.7784 170.467C77.5941 170.148 77.1859 170.039 76.8668 170.223C76.5476 170.407 76.4382 170.815 76.6225 171.135C76.8068 171.454 77.2149 171.563 77.5341 171.379Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.9374 169.681C76.2247 169.515 76.3231 169.148 76.1572 168.86C75.9914 168.573 75.6241 168.475 75.3368 168.641C75.0495 168.806 74.9511 169.174 75.117 169.461C75.2828 169.748 75.6501 169.847 75.9374 169.681Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.0404 167.193C75.3596 167.009 75.469 166.601 75.2847 166.282C75.1004 165.963 74.6923 165.853 74.3731 166.037C74.0539 166.222 73.9446 166.63 74.1288 166.949C74.3131 167.268 74.7213 167.378 75.0404 167.193Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.8138 165.473C73.0052 165.362 73.0707 165.118 72.9603 164.926C72.8498 164.735 72.6052 164.669 72.4138 164.78C72.2225 164.89 72.1569 165.135 72.2674 165.326C72.3779 165.518 72.6225 165.583 72.8138 165.473Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M68.091 167.969C68.3782 167.803 68.4767 167.436 68.3108 167.149C68.145 166.862 67.7776 166.763 67.4904 166.929C67.2031 167.095 67.1047 167.462 67.2705 167.749C67.4364 168.037 67.8037 168.135 68.091 167.969Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.5546 166.506C67.9057 166.304 68.026 165.855 67.8233 165.504C67.6206 165.153 67.1716 165.032 66.8205 165.235C66.4694 165.438 66.3491 165.887 66.5519 166.238C66.7546 166.589 67.2035 166.709 67.5546 166.506Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.412 166.396C66.7312 166.211 66.8406 165.803 66.6563 165.484C66.472 165.165 66.0639 165.056 65.7447 165.24C65.4255 165.424 65.3161 165.832 65.5004 166.151C65.6847 166.471 66.0928 166.58 66.412 166.396Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.6992 159.95C68.1141 159.711 68.2563 159.18 68.0167 158.765C67.7772 158.35 67.2466 158.208 66.8316 158.448C66.4167 158.687 66.2745 159.218 66.5141 159.633C66.7537 160.048 67.2842 160.19 67.6992 159.95Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M66.3754 158.325C66.5667 158.214 66.6323 157.97 66.5218 157.778C66.4113 157.587 66.1667 157.522 65.9753 157.632C65.784 157.742 65.7185 157.987 65.8289 158.178C65.9394 158.37 66.184 158.435 66.3754 158.325Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.4396 158.442C64.7588 158.257 64.8682 157.849 64.6839 157.53C64.4996 157.211 64.0915 157.101 63.7723 157.286C63.4531 157.47 63.3437 157.878 63.528 158.197C63.7123 158.517 64.1204 158.626 64.4396 158.442Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.1247 159.777C60.4758 159.574 60.5961 159.125 60.3934 158.774C60.1907 158.423 59.7417 158.303 59.3906 158.506C59.0395 158.708 58.9192 159.157 59.1219 159.508C59.3246 159.859 59.7736 159.98 60.1247 159.777Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.6102 157.954C58.8337 157.825 58.9102 157.54 58.7812 157.316C58.6522 157.093 58.3665 157.016 58.1431 157.145C57.9196 157.274 57.8431 157.56 57.9721 157.783C58.1011 158.007 58.3868 158.083 58.6102 157.954Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.8829 156.025C59.1063 155.896 59.1829 155.61 59.0539 155.386C58.9249 155.163 58.6392 155.086 58.4158 155.215C58.1924 155.344 58.1158 155.63 58.2448 155.854C58.3738 156.077 58.6595 156.153 58.8829 156.025Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.0121 154.253C57.2034 154.142 57.269 153.898 57.1585 153.706C57.048 153.515 56.8034 153.45 56.6121 153.56C56.4207 153.67 56.3552 153.915 56.4656 154.106C56.5761 154.298 56.8208 154.363 57.0121 154.253Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.5475 156.793C53.8028 156.646 53.8903 156.319 53.7429 156.064C53.5955 155.809 53.269 155.721 53.0136 155.869C52.7583 156.016 52.6708 156.342 52.8182 156.598C52.9656 156.853 53.2921 156.941 53.5475 156.793Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.687 152.091C56.1338 151.833 56.287 151.261 56.029 150.814C55.771 150.367 55.1996 150.214 54.7527 150.472C54.3059 150.73 54.1527 151.302 54.4107 151.749C54.6687 152.195 55.2401 152.349 55.687 152.091Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.5707 155.238C51.7941 155.109 51.8706 154.823 51.7416 154.6C51.6127 154.376 51.327 154.3 51.1035 154.429C50.8801 154.558 50.8035 154.843 50.9325 155.067C51.0615 155.29 51.3472 155.367 51.5707 155.238Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.7187 153.226C52.1337 152.986 52.2758 152.455 52.0363 152.041C51.7967 151.626 51.2661 151.483 50.8512 151.723C50.4362 151.963 50.2941 152.493 50.5336 152.908C50.7732 153.323 51.3038 153.465 51.7187 153.226Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.4733 154.945C48.8883 154.706 49.0305 154.175 48.7909 153.76C48.5513 153.345 48.0207 153.203 47.6058 153.442C47.1909 153.682 47.0487 154.213 47.2883 154.628C47.5278 155.042 48.0584 155.185 48.4733 154.945Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.6053 151.439C47.8288 151.31 47.9053 151.025 47.7763 150.801C47.6473 150.578 47.3616 150.501 47.1382 150.63C46.9148 150.759 46.8382 151.045 46.9672 151.268C47.0962 151.492 47.3819 151.568 47.6053 151.439Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.1457 149.315C45.4329 149.149 45.5313 148.782 45.3655 148.494C45.1996 148.207 44.8323 148.109 44.545 148.274C44.2578 148.44 44.1594 148.808 44.3252 149.095C44.4911 149.382 44.8584 149.481 45.1457 149.315Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.6447 151.259C42.0597 151.019 42.2018 150.489 41.9623 150.074C41.7227 149.659 41.1921 149.517 40.7772 149.756C40.3623 149.996 40.2201 150.527 40.4597 150.942C40.6992 151.356 41.2298 151.499 41.6447 151.259Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.7397 150.626C41.0588 150.441 41.1682 150.033 40.9839 149.714C40.7996 149.395 40.3915 149.286 40.0723 149.47C39.7531 149.654 39.6438 150.062 39.8281 150.381C40.0123 150.701 40.4205 150.81 40.7397 150.626Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.6674 147.431C40.9227 147.284 41.0102 146.957 40.8628 146.702C40.7153 146.446 40.3888 146.359 40.1335 146.506C39.8781 146.654 39.7907 146.98 39.9381 147.236C40.0855 147.491 40.412 147.578 40.6674 147.431Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.6972 147.759C38.0802 147.537 38.2115 147.048 37.9903 146.665C37.7692 146.282 37.2794 146.15 36.8964 146.372C36.5134 146.593 36.3821 147.082 36.6033 147.465C36.8244 147.849 37.3142 147.98 37.6972 147.759Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.433 140.091C38.7522 139.906 38.8616 139.498 38.6773 139.179C38.493 138.86 38.0849 138.75 37.7657 138.935C37.4465 139.119 37.3371 139.527 37.5214 139.846C37.7057 140.165 38.1138 140.275 38.433 140.091Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.3195 140.846C32.6067 140.68 32.7052 140.313 32.5393 140.026C32.3735 139.738 32.0061 139.64 31.7189 139.806C31.4316 139.972 31.3332 140.339 31.499 140.626C31.6649 140.914 32.0322 141.012 32.3195 140.846Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.0883 138.438C34.3117 138.309 34.3882 138.023 34.2592 137.8C34.1302 137.576 33.8445 137.5 33.6211 137.629C33.3977 137.758 33.3211 138.043 33.4501 138.267C33.5791 138.49 33.8648 138.567 34.0883 138.438Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.2128 133.706C37.532 133.521 37.6413 133.113 37.4571 132.794C37.2728 132.475 36.8646 132.366 36.5455 132.55C36.2263 132.734 36.1169 133.142 36.3012 133.461C36.4855 133.781 36.8936 133.89 37.2128 133.706Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.8578 132.712C30.1451 132.546 30.2435 132.179 30.0776 131.892C29.9118 131.604 29.5445 131.506 29.2572 131.672C28.9699 131.838 28.8715 132.205 29.0374 132.492C29.2032 132.78 29.5705 132.878 29.8578 132.712Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.8923 131.843C28.2753 131.622 28.4065 131.132 28.1854 130.749C27.9643 130.366 27.4745 130.235 27.0915 130.456C26.7084 130.677 26.5772 131.167 26.7984 131.55C27.0195 131.933 27.5093 132.064 27.8923 131.843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.5311 128.816C27.7545 128.687 27.8311 128.401 27.7021 128.177C27.5731 127.954 27.2874 127.877 27.064 128.006C26.8405 128.135 26.764 128.421 26.893 128.645C27.022 128.868 27.3077 128.945 27.5311 128.816Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.3764 128.287C25.7275 128.084 25.8478 127.635 25.6451 127.284C25.4424 126.933 24.9934 126.813 24.6423 127.016C24.2912 127.218 24.1709 127.667 24.3736 128.018C24.5763 128.369 25.0253 128.49 25.3764 128.287Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.9672 127.445C25.2864 127.26 25.3957 126.852 25.2115 126.533C25.0272 126.214 24.619 126.104 24.2999 126.289C23.9807 126.473 23.8713 126.881 24.0556 127.2C24.2399 127.519 24.648 127.629 24.9672 127.445Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.6027 122.651C32.9219 122.467 33.0312 122.059 32.847 121.739C32.6627 121.42 32.2545 121.311 31.9354 121.495C31.6162 121.679 31.5068 122.088 31.6911 122.407C31.8754 122.726 32.2835 122.835 32.6027 122.651Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.7929 123.269C26.2079 123.029 26.35 122.499 26.1105 122.084C25.8709 121.669 25.3403 121.527 24.9254 121.766C24.5104 122.006 24.3683 122.537 24.6078 122.952C24.8474 123.366 25.378 123.509 25.7929 123.269Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.5094 119.041C25.7967 118.875 25.8951 118.507 25.7293 118.22C25.5634 117.933 25.1961 117.834 24.9088 118C24.6215 118.166 24.5231 118.533 24.689 118.821C24.8548 119.108 25.2222 119.206 25.5094 119.041Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.7587 115.736C25.95 115.626 26.0156 115.381 25.9051 115.19C25.7946 114.998 25.55 114.933 25.3586 115.043C25.1673 115.154 25.1018 115.399 25.2122 115.59C25.3227 115.781 25.5673 115.847 25.7587 115.736Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.0742 114.401C30.4572 114.18 30.5884 113.691 30.3673 113.308C30.1461 112.924 29.6564 112.793 29.2734 113.014C28.8903 113.236 28.7591 113.725 28.9802 114.108C29.2014 114.491 29.6911 114.623 30.0742 114.401Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.1617 111.766C25.3531 111.655 25.4186 111.411 25.3082 111.219C25.1977 111.028 24.953 110.962 24.7617 111.073C24.5704 111.183 24.5048 111.428 24.6153 111.619C24.7258 111.811 24.9704 111.876 25.1617 111.766Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.1214 110.482C29.3449 110.353 29.4214 110.068 29.2924 109.844C29.1634 109.621 28.8777 109.544 28.6543 109.673C28.4309 109.802 28.3543 110.088 28.4833 110.311C28.6123 110.535 28.898 110.611 29.1214 110.482Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.8439 108.794C31.0992 108.647 31.1867 108.32 31.0393 108.065C30.8919 107.81 30.5654 107.722 30.31 107.87C30.0547 108.017 29.9672 108.343 30.1146 108.599C30.262 108.854 30.5885 108.942 30.8439 108.794Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.3178 106.554C28.605 106.388 28.7035 106.021 28.5376 105.733C28.3718 105.446 28.0044 105.348 27.7172 105.514C27.4299 105.68 27.3315 106.047 27.4973 106.334C27.6632 106.621 28.0305 106.72 28.3178 106.554Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.8065 104.461C30.0619 104.314 30.1494 103.988 30.0019 103.732C29.8545 103.477 29.528 103.389 29.2727 103.537C29.0173 103.684 28.9298 104.011 29.0772 104.266C29.2247 104.521 29.5512 104.609 29.8065 104.461Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.3244 103.117C21.6116 102.951 21.7101 102.584 21.5442 102.296C21.3783 102.009 21.011 101.911 20.7238 102.077C20.4365 102.243 20.3381 102.61 20.5039 102.897C20.6698 103.184 21.0371 103.283 21.3244 103.117Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.6947 101.092C21.0139 100.907 21.1233 100.499 20.939 100.18C20.7547 99.8609 20.3466 99.7516 20.0274 99.9359C19.7082 100.12 19.5989 100.528 19.7831 100.847C19.9674 101.167 20.3756 101.276 20.6947 101.092Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.5639 98.5876C24.0108 98.3296 24.1639 97.7582 23.9059 97.3114C23.6479 96.8645 23.0765 96.7114 22.6297 96.9694C22.1828 97.2274 22.0297 97.7988 22.2877 98.2456C22.5457 98.6925 23.1171 98.8456 23.5639 98.5876Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.5905 96.7618C24.9416 96.5591 25.0619 96.1102 24.8592 95.7591C24.6565 95.408 24.2075 95.2877 23.8564 95.4904C23.5053 95.6931 23.385 96.142 23.5877 96.4931C23.7904 96.8443 24.2394 96.9645 24.5905 96.7618Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.323 94.4148C29.7379 94.1752 29.8801 93.6447 29.6405 93.2297C29.4009 92.8148 28.8704 92.6726 28.4554 92.9122C28.0405 93.1517 27.8983 93.6823 28.1379 94.0973C28.3774 94.5122 28.908 94.6544 29.323 94.4148Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.4672 93.3299C29.8503 93.1088 29.9815 92.619 29.7604 92.236C29.5392 91.853 29.0494 91.7217 28.6664 91.9429C28.2834 92.164 28.1522 92.6538 28.3733 93.0368C28.5944 93.4198 29.0842 93.551 29.4672 93.3299Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.979 97.5869C107.394 97.3474 107.536 96.8168 107.297 96.4018C107.057 95.9869 106.527 95.8447 106.112 96.0843C105.697 96.3239 105.555 96.8544 105.794 97.2694C106.034 97.6843 106.564 97.8265 106.979 97.5869Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M101.122 92.6465C101.537 92.4069 101.679 91.8763 101.439 91.4614C101.2 91.0465 100.669 90.9043 100.254 91.1439C99.8393 91.3834 99.6971 91.914 99.9367 92.329C100.176 92.7439 100.707 92.8861 101.122 92.6465Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M100.695 93.5095C101.014 93.3252 101.124 92.917 100.939 92.5978C100.755 92.2787 100.347 92.1693 100.028 92.3536C99.7085 92.5379 99.5991 92.946 99.7834 93.2652C99.9677 93.5844 100.376 93.6937 100.695 93.5095Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M99.5678 93.1583C99.9189 92.9556 100.039 92.5067 99.8365 92.1555C99.6338 91.8044 99.1848 91.6841 98.8337 91.8869C98.4826 92.0896 98.3623 92.5385 98.565 92.8896C98.7677 93.2407 99.2167 93.361 99.5678 93.1583Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M100.613 101.108C100.932 100.924 101.042 100.516 100.857 100.197C100.673 99.8775 100.265 99.7682 99.9456 99.9525C99.6264 100.137 99.5171 100.545 99.7013 100.864C99.8856 101.183 100.294 101.293 100.613 101.108Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.6564 97.7286C95.9117 97.5811 95.9992 97.2546 95.8518 96.9993C95.7044 96.7439 95.3779 96.6564 95.1225 96.8039C94.8672 96.9513 94.7797 97.2778 94.9271 97.5332C95.0745 97.7885 95.401 97.876 95.6564 97.7286Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.0058 100.339C95.325 100.155 95.4343 99.7464 95.25 99.4272C95.0658 99.108 94.6576 98.9986 94.3384 99.1829C94.0193 99.3672 93.9099 99.7754 94.0942 100.095C94.2784 100.414 94.6866 100.523 95.0058 100.339Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M96.6301 104.487C97.0131 104.265 97.1443 103.776 96.9232 103.393C96.702 103.01 96.2123 102.878 95.8293 103.1C95.4462 103.321 95.315 103.81 95.5361 104.194C95.7573 104.577 96.247 104.708 96.6301 104.487Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M92.8365 102.054C93.06 101.925 93.1365 101.639 93.0075 101.415C92.8785 101.192 92.5928 101.115 92.3694 101.244C92.146 101.373 92.0694 101.659 92.1984 101.883C92.3274 102.106 92.6131 102.183 92.8365 102.054Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.2179 98.055C89.4732 97.9076 89.5607 97.581 89.4133 97.3257C89.2659 97.0703 88.9394 96.9829 88.684 97.1303C88.4287 97.2777 88.3412 97.6042 88.4886 97.8596C88.636 98.1149 88.9626 98.2024 89.2179 98.055Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.588 98.5718C86.8434 98.4244 86.9308 98.0979 86.7834 97.8425C86.636 97.5872 86.3095 97.4997 86.0541 97.6471C85.7988 97.7946 85.7113 98.1211 85.8587 98.3764C86.0062 98.6318 86.3327 98.7192 86.588 98.5718Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M85.3669 103.13C85.8138 102.872 85.9669 102.3 85.7089 101.854C85.4509 101.407 84.8795 101.254 84.4326 101.512C83.9858 101.77 83.8327 102.341 84.0907 102.788C84.3487 103.235 84.9201 103.388 85.3669 103.13Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.7367 103.377C83.928 103.266 83.9936 103.022 83.8831 102.83C83.7727 102.639 83.528 102.573 83.3367 102.684C83.1453 102.794 83.0798 103.039 83.1903 103.23C83.3007 103.422 83.5454 103.487 83.7367 103.377Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.3359 100.292C79.4954 100.2 79.55 99.9964 79.458 99.8369C79.3659 99.6775 79.162 99.6229 79.0026 99.7149C78.8431 99.807 78.7885 100.011 78.8805 100.17C78.9726 100.33 79.1765 100.384 79.3359 100.292Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.3261 108.814C80.7091 108.593 80.8404 108.103 80.6192 107.72C80.3981 107.337 79.9083 107.206 79.5253 107.427C79.1423 107.648 79.0111 108.138 79.2322 108.521C79.4533 108.904 79.9431 109.035 80.3261 108.814Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.3163 104.271C76.6674 104.068 76.7877 103.619 76.585 103.268C76.3823 102.917 75.9334 102.797 75.5823 102.999C75.2312 103.202 75.1109 103.651 75.3136 104.002C75.5163 104.353 75.9652 104.474 76.3163 104.271Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.265 106.457C74.5204 106.31 74.6078 105.983 74.4604 105.728C74.313 105.472 73.9865 105.385 73.7311 105.532C73.4758 105.68 73.3883 106.006 73.5357 106.262C73.6832 106.517 74.0097 106.604 74.265 106.457Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M71.6369 105.509C71.988 105.306 72.1083 104.857 71.9056 104.506C71.7029 104.155 71.2539 104.034 70.9028 104.237C70.5517 104.44 70.4314 104.889 70.6341 105.24C70.8368 105.591 71.2858 105.711 71.6369 105.509Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.5802 104.346C70.8994 104.162 71.0088 103.754 70.8245 103.435C70.6402 103.115 70.2321 103.006 69.9129 103.19C69.5937 103.375 69.4844 103.783 69.6686 104.102C69.8529 104.421 70.2611 104.53 70.5802 104.346Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M69.2797 104.095C69.6628 103.874 69.794 103.384 69.5728 103.001C69.3517 102.618 68.8619 102.487 68.4789 102.708C68.0959 102.929 67.9647 103.419 68.1858 103.802C68.4069 104.185 68.8967 104.316 69.2797 104.095Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M68.6435 109.934C69.0265 109.713 69.1578 109.223 68.9366 108.84C68.7155 108.457 68.2257 108.325 67.8427 108.547C67.4597 108.768 67.3284 109.258 67.5496 109.641C67.7707 110.024 68.2605 110.155 68.6435 109.934Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M65.2526 101.258C65.476 101.129 65.5525 100.843 65.4235 100.62C65.2945 100.396 65.0088 100.32 64.7854 100.449C64.562 100.578 64.4854 100.863 64.6144 101.087C64.7434 101.31 65.0291 101.387 65.2526 101.258Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.6862 106.553C63.8775 106.443 63.943 106.198 63.8326 106.007C63.7221 105.816 63.4775 105.75 63.2861 105.861C63.0948 105.971 63.0292 106.216 63.1397 106.407C63.2502 106.598 63.4948 106.664 63.6862 106.553Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.2453 109.929C62.6603 109.69 62.8024 109.159 62.5629 108.744C62.3233 108.329 61.7927 108.187 61.3778 108.427C60.9628 108.666 60.8207 109.197 61.0602 109.612C61.2998 110.027 61.8304 110.169 62.2453 109.929Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.3139 97.0016C95.6331 96.8174 95.7424 96.4092 95.5582 96.09C95.3739 95.7708 94.9657 95.6615 94.6465 95.8458C94.3274 96.0301 94.218 96.4382 94.4023 96.7574C94.5866 97.0766 94.9947 97.1859 95.3139 97.0016Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.5629 99.7079C93.8821 99.5237 93.9914 99.1155 93.8072 98.7963C93.6229 98.4772 93.2147 98.3678 92.8956 98.5521C92.5764 98.7364 92.467 99.1445 92.6513 99.4637C92.8356 99.7829 93.2437 99.8922 93.5629 99.7079Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M94.2256 101.79C94.385 101.698 94.4396 101.494 94.3476 101.335C94.2555 101.176 94.0517 101.121 93.8922 101.213C93.7328 101.305 93.6781 101.509 93.7702 101.668C93.8622 101.828 94.0661 101.882 94.2256 101.79Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.2428 102.897C91.4662 102.768 91.5428 102.482 91.4138 102.258C91.2848 102.035 90.9991 101.958 90.7756 102.087C90.5522 102.216 90.4757 102.502 90.6047 102.726C90.7337 102.949 91.0194 103.026 91.2428 102.897Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.9284 101.302C85.3434 101.063 85.4855 100.532 85.246 100.117C85.0064 99.7025 84.4758 99.5603 84.0609 99.7999C83.6459 100.039 83.5038 100.57 83.7433 100.985C83.9829 101.4 84.5135 101.542 84.9284 101.302Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.006 102.374C84.3252 102.19 84.4346 101.782 84.2503 101.463C84.066 101.143 83.6579 101.034 83.3387 101.218C83.0195 101.403 82.9101 101.811 83.0944 102.13C83.2787 102.449 83.6868 102.558 84.006 102.374Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.4923 102.167C78.8115 101.983 78.9209 101.575 78.7366 101.256C78.5523 100.936 78.1442 100.827 77.825 101.011C77.5058 101.196 77.3965 101.604 77.5807 101.923C77.765 102.242 78.1732 102.351 78.4923 102.167Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.5497 102.403C77.805 102.256 77.8925 101.929 77.7451 101.674C77.5977 101.418 77.2712 101.331 77.0158 101.478C76.7605 101.626 76.673 101.952 76.8204 102.208C76.9678 102.463 77.2943 102.551 77.5497 102.403Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.1059 104.971C77.5528 104.713 77.7059 104.142 77.4479 103.695C77.1899 103.248 76.6185 103.095 76.1717 103.353C75.7248 103.611 75.5717 104.182 75.8297 104.629C76.0877 105.076 76.6591 105.229 77.1059 104.971Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.5607 100.16C75.752 100.05 75.8176 99.805 75.7071 99.6136C75.5966 99.4223 75.352 99.3568 75.1606 99.4672C74.9693 99.5777 74.9038 99.8223 75.0142 100.014C75.1247 100.205 75.3693 100.271 75.5607 100.16Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M73.5636 103.24C73.8828 103.056 73.9922 102.648 73.8079 102.329C73.6236 102.009 73.2155 101.9 72.8963 102.084C72.5771 102.269 72.4677 102.677 72.652 102.996C72.8363 103.315 73.2444 103.424 73.5636 103.24Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.7318 104.875C71.0191 104.709 71.1175 104.342 70.9517 104.055C70.7858 103.768 70.4185 103.669 70.1312 103.835C69.844 104.001 69.7455 104.368 69.9114 104.655C70.0772 104.943 70.4446 105.041 70.7318 104.875Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.0292 102.466C67.4123 102.245 67.5435 101.755 67.3224 101.372C67.1012 100.989 66.6115 100.858 66.2284 101.079C65.8454 101.3 65.7142 101.79 65.9353 102.173C66.1565 102.556 66.6462 102.688 67.0292 102.466Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M65.1479 102.011C65.3074 101.919 65.362 101.715 65.2699 101.555C65.1779 101.396 64.974 101.341 64.8146 101.433C64.6551 101.525 64.6005 101.729 64.6926 101.889C64.7846 102.048 64.9885 102.103 65.1479 102.011Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.1406 100.936C63.5555 100.696 63.6977 100.166 63.4581 99.7507C63.2186 99.3358 62.688 99.1936 62.273 99.4332C61.8581 99.6727 61.7159 100.203 61.9555 100.618C62.1951 101.033 62.7256 101.175 63.1406 100.936Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.4257 95.5673C60.617 95.4569 60.6826 95.2122 60.5721 95.0209C60.4616 94.8295 60.217 94.764 60.0256 94.8744C59.8343 94.9849 59.7687 95.2296 59.8792 95.4209C59.9897 95.6122 60.2343 95.6778 60.4257 95.5673Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.0238 94.336C60.2472 94.207 60.3238 93.9213 60.1948 93.6979C60.0658 93.4745 59.7801 93.3979 59.5567 93.5269C59.3332 93.6559 59.2567 93.9416 59.3857 94.1651C59.5147 94.3885 59.8004 94.465 60.0238 94.336Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.0421 96.5186C55.2975 96.3712 55.385 96.0447 55.2375 95.7893C55.0901 95.534 54.7636 95.4465 54.5082 95.5939C54.2529 95.7413 54.1654 96.0678 54.3128 96.3232C54.4603 96.5785 54.7868 96.666 55.0421 96.5186Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.7428 92.5303C55.0301 92.3644 55.1285 91.9971 54.9627 91.7098C54.7968 91.4225 54.4295 91.3241 54.1422 91.49C53.8549 91.6558 53.7565 92.0232 53.9224 92.3104C54.0882 92.5977 54.4556 92.6961 54.7428 92.5303Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.4589 79.6533C89.7781 79.469 89.8874 79.0608 89.7032 78.7417C89.5189 78.4225 89.1107 78.3131 88.7916 78.4974C88.4724 78.6817 88.363 79.0898 88.5473 79.409C88.7316 79.7282 89.1397 79.8375 89.4589 79.6533Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.1513 82.8396C95.4385 82.6737 95.537 82.3064 95.3711 82.0191C95.2052 81.7319 94.8379 81.6335 94.5507 81.7993C94.2634 81.9652 94.165 82.3325 94.3308 82.6198C94.4967 82.907 94.864 83.0054 95.1513 82.8396Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.4603 86.4381C97.6838 86.3091 97.7603 86.0234 97.6313 85.8C97.5023 85.5765 97.2166 85.5 96.9932 85.629C96.7698 85.758 96.6932 86.0437 96.8222 86.2671C96.9512 86.4905 97.2369 86.5671 97.4603 86.4381Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.2946 87.3768C89.6776 87.1556 89.8089 86.6659 89.5877 86.2828C89.3666 85.8998 88.8768 85.7686 88.4938 85.9897C88.1108 86.2109 87.9796 86.7006 88.2007 87.0837C88.4218 87.4667 88.9116 87.5979 89.2946 87.3768Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.0226 88.7679C91.246 88.6389 91.3226 88.3532 91.1936 88.1298C91.0646 87.9064 90.7789 87.8298 90.5554 87.9588C90.332 88.0878 90.2555 88.3735 90.3844 88.5969C90.5134 88.8204 90.7991 88.8969 91.0226 88.7679Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.4948 93.044C95.8778 92.8229 96.0091 92.3331 95.7879 91.9501C95.5668 91.5671 95.077 91.4358 94.694 91.657C94.311 91.8781 94.1798 92.3679 94.4009 92.7509C94.622 93.1339 95.1118 93.2651 95.4948 93.044Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.0868 93.429C87.3421 93.2816 87.4296 92.9551 87.2822 92.6997C87.1348 92.4444 86.8083 92.3569 86.5529 92.5043C86.2976 92.6517 86.2101 92.9782 86.3575 93.2336C86.5049 93.4889 86.8314 93.5764 87.0868 93.429Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M85.5743 94.1472C85.7657 94.0367 85.8312 93.792 85.7208 93.6007C85.6103 93.4094 85.3656 93.3438 85.1743 93.4543C84.983 93.5647 84.9174 93.8094 85.0279 94.0007C85.1384 94.1921 85.383 94.2576 85.5743 94.1472Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.0822 97.1673C84.3375 97.0199 84.425 96.6933 84.2776 96.438C84.1301 96.1826 83.8036 96.0952 83.5483 96.2426C83.2929 96.39 83.2054 96.7165 83.3529 96.9719C83.5003 97.2272 83.8268 97.3147 84.0822 97.1673Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.8257 100.472C80.2725 100.214 80.4256 99.643 80.1676 99.1961C79.9096 98.7493 79.3382 98.5962 78.8914 98.8542C78.4445 99.1121 78.2914 99.6835 78.5494 100.13C78.8074 100.577 79.3788 100.73 79.8257 100.472Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.2697 94.975C78.6208 94.7723 78.7411 94.3233 78.5384 93.9722C78.3357 93.6211 77.8867 93.5008 77.5356 93.7035C77.1845 93.9062 77.0642 94.3552 77.2669 94.7063C77.4696 95.0574 77.9186 95.1777 78.2697 94.975Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M71.5358 97.8601C71.7271 97.7496 71.7927 97.5049 71.6822 97.3136C71.5717 97.1223 71.3271 97.0567 71.1357 97.1672C70.9444 97.2776 70.8789 97.5223 70.9893 97.7136C71.0998 97.905 71.3444 97.9705 71.5358 97.8601Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.4313 96.8805C70.7505 96.6963 70.8599 96.2881 70.6756 95.9689C70.4913 95.6498 70.0832 95.5404 69.764 95.7247C69.4448 95.909 69.3354 96.3171 69.5197 96.6363C69.704 96.9555 70.1121 97.0648 70.4313 96.8805Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.8058 93.6589C71.0931 93.4931 71.1915 93.1257 71.0256 92.8385C70.8598 92.5512 70.4925 92.4528 70.2052 92.6186C69.9179 92.7845 69.8195 93.1518 69.9854 93.4391C70.1512 93.7263 70.5185 93.8248 70.8058 93.6589Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.5404 107.101C63.7638 106.972 63.8404 106.686 63.7114 106.463C63.5824 106.239 63.2967 106.163 63.0733 106.292C62.8498 106.421 62.7733 106.706 62.9023 106.93C63.0313 107.153 63.317 107.23 63.5404 107.101Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.2743 105.054C58.4656 104.944 58.5312 104.699 58.4207 104.508C58.3103 104.317 58.0656 104.251 57.8743 104.362C57.6829 104.472 57.6174 104.717 57.7278 104.908C57.8383 105.099 58.083 105.165 58.2743 105.054Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.9756 108.541C58.135 108.449 58.1896 108.245 58.0976 108.085C58.0055 107.926 57.8017 107.871 57.6422 107.963C57.4828 108.055 57.4281 108.259 57.5202 108.419C57.6123 108.578 57.8161 108.633 57.9756 108.541Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.3004 108.733C48.5877 108.567 48.6861 108.2 48.5203 107.913C48.3544 107.625 47.9871 107.527 47.6998 107.693C47.4126 107.859 47.3141 108.226 47.48 108.513C47.6458 108.801 48.0132 108.899 48.3004 108.733Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.0864 107.431C47.5013 107.191 47.6435 106.661 47.4039 106.246C47.1644 105.831 46.6338 105.688 46.2188 105.928C45.8039 106.168 45.6617 106.698 45.9013 107.113C46.1409 107.528 46.6714 107.67 47.0864 107.431Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.2038 108.171C46.6507 107.913 46.8038 107.342 46.5458 106.895C46.2878 106.448 45.7164 106.295 45.2696 106.553C44.8227 106.811 44.6696 107.383 44.9276 107.829C45.1856 108.276 45.757 108.429 46.2038 108.171Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.7047 103.567C47.0239 103.383 47.1333 102.974 46.949 102.655C46.7647 102.336 46.3566 102.227 46.0374 102.411C45.7182 102.595 45.6089 103.003 45.7931 103.323C45.9774 103.642 46.3856 103.751 46.7047 103.567Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.9093 104.989C47.1006 104.879 47.1662 104.634 47.0557 104.443C46.9453 104.251 46.7006 104.186 46.5093 104.296C46.3179 104.407 46.2524 104.651 46.3629 104.843C46.4733 105.034 46.718 105.1 46.9093 104.989Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.498 118.041C84.881 117.82 85.0123 117.33 84.7911 116.947C84.57 116.564 84.0802 116.433 83.6972 116.654C83.3142 116.875 83.1829 117.365 83.4041 117.748C83.6252 118.131 84.115 118.262 84.498 118.041Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.0996 116.001C89.5145 115.761 89.6567 115.231 89.4171 114.816C89.1775 114.401 88.647 114.259 88.232 114.498C87.8171 114.738 87.6749 115.269 87.9145 115.683C88.1541 116.098 88.6846 116.241 89.0996 116.001Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M90.9364 117.715C91.2556 117.53 91.365 117.122 91.1807 116.803C90.9964 116.484 90.5883 116.374 90.2691 116.559C89.9499 116.743 89.8406 117.151 90.0248 117.47C90.2091 117.789 90.6173 117.899 90.9364 117.715Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.4586 118.34C95.7459 118.174 95.8443 117.807 95.6785 117.519C95.5126 117.232 95.1453 117.134 94.858 117.3C94.5708 117.465 94.4723 117.833 94.6382 118.12C94.804 118.407 95.1714 118.506 95.4586 118.34Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M96.6003 121.919C96.9514 121.716 97.0717 121.267 96.869 120.916C96.6662 120.565 96.2173 120.445 95.8662 120.648C95.5151 120.85 95.3948 121.299 95.5975 121.65C95.8002 122.001 96.2492 122.122 96.6003 121.919Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.284 124.45C93.5075 124.321 93.584 124.035 93.455 123.812C93.326 123.588 93.0403 123.512 92.8169 123.641C92.5935 123.77 92.5169 124.056 92.6459 124.279C92.7749 124.502 93.0606 124.579 93.284 124.45Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.833 95.7104C114.28 95.4524 114.433 94.881 114.175 94.4342C113.917 93.9873 113.346 93.8342 112.899 94.0922C112.452 94.3502 112.299 94.9216 112.557 95.3684C112.815 95.8153 113.386 95.9684 113.833 95.7104Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.278 97.5366C118.725 97.2786 118.878 96.7072 118.62 96.2603C118.362 95.8135 117.79 95.6604 117.344 95.9184C116.897 96.1764 116.744 96.7478 117.002 97.1946C117.26 97.6415 117.831 97.7946 118.278 97.5366Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.034 98.0278C125.449 97.7883 125.591 97.2577 125.352 96.8428C125.112 96.4278 124.582 96.2857 124.167 96.5252C123.752 96.7648 123.61 97.2954 123.849 97.7103C124.089 98.1252 124.619 98.2674 125.034 98.0278Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.7816 123.713C49.037 123.566 49.1245 123.239 48.977 122.984C48.8296 122.728 48.5031 122.641 48.2477 122.788C47.9924 122.936 47.9049 123.262 48.0523 123.518C48.1998 123.773 48.5263 123.86 48.7816 123.713Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.7953 122.396C51.1145 122.212 51.2239 121.804 51.0396 121.485C50.8553 121.165 50.4472 121.056 50.128 121.24C49.8088 121.425 49.6994 121.833 49.8837 122.152C50.068 122.471 50.4761 122.58 50.7953 122.396Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.8483 118.626C48.1355 118.461 48.234 118.093 48.0681 117.806C47.9023 117.519 47.5349 117.42 47.2477 117.586C46.9604 117.752 46.862 118.119 47.0278 118.407C47.1937 118.694 47.561 118.792 47.8483 118.626Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.4633 120.472C55.8144 120.269 55.9347 119.82 55.732 119.469C55.5293 119.118 55.0803 118.997 54.7292 119.2C54.3781 119.403 54.2578 119.852 54.4605 120.203C54.6633 120.554 55.1122 120.674 55.4633 120.472Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.9667 118.947C54.2859 118.763 54.3953 118.355 54.211 118.035C54.0267 117.716 53.6186 117.607 53.2994 117.791C52.9802 117.975 52.8708 118.384 53.0551 118.703C53.2394 119.022 53.6475 119.131 53.9667 118.947Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.5141 94.5695C87.8333 94.3852 87.9426 93.9771 87.7583 93.6579C87.5741 93.3387 87.1659 93.2294 86.8467 93.4136C86.5275 93.5979 86.4182 94.0061 86.6025 94.3252C86.7868 94.6444 87.1949 94.7538 87.5141 94.5695Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.0139 98.129C80.3969 97.9078 80.5281 97.4181 80.307 97.035C80.0858 96.652 79.5961 96.5208 79.2131 96.7419C78.83 96.9631 78.6988 97.4528 78.9199 97.8359C79.1411 98.2189 79.6308 98.3501 80.0139 98.129Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.6119 98.4799C86.8033 98.3694 86.8688 98.1248 86.7584 97.9335C86.6479 97.7421 86.4032 97.6766 86.2119 97.787C86.0206 97.8975 85.955 98.1422 86.0655 98.3335C86.176 98.5248 86.4206 98.5904 86.6119 98.4799Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.9646 101.241C82.4114 100.983 82.5645 100.412 82.3066 99.9647C82.0486 99.5178 81.4772 99.3647 81.0303 99.6227C80.5834 99.8807 80.4303 100.452 80.6883 100.899C80.9463 101.346 81.5177 101.499 81.9646 101.241Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.093 103.326C84.476 103.105 84.6072 102.615 84.3861 102.232C84.1649 101.849 83.6752 101.718 83.2922 101.939C82.9091 102.16 82.7779 102.65 82.999 103.033C83.2202 103.416 83.7099 103.547 84.093 103.326Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.3469 104.027C86.5063 103.935 86.561 103.731 86.4689 103.572C86.3769 103.412 86.173 103.358 86.0136 103.45C85.8541 103.542 85.7995 103.746 85.8915 103.905C85.9836 104.065 86.1875 104.119 86.3469 104.027Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.4084 106.535C86.8553 106.277 87.0084 105.706 86.7504 105.259C86.4924 104.812 85.921 104.659 85.4741 104.917C85.0273 105.175 84.8742 105.747 85.1322 106.193C85.3902 106.64 85.9616 106.793 86.4084 106.535Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.7566 109.684C83.916 109.592 83.9706 109.388 83.8786 109.229C83.7865 109.069 83.5827 109.014 83.4232 109.107C83.2638 109.199 83.2091 109.402 83.3012 109.562C83.3933 109.721 83.5971 109.776 83.7566 109.684Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.6086 111.696C83.9277 111.512 84.0371 111.104 83.8528 110.785C83.6685 110.466 83.2604 110.356 82.9412 110.541C82.622 110.725 82.5127 111.133 82.697 111.452C82.8812 111.771 83.2894 111.881 83.6086 111.696Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M85.4677 112.781C85.7549 112.615 85.8534 112.248 85.6875 111.96C85.5216 111.673 85.1543 111.575 84.8671 111.74C84.5798 111.906 84.4814 112.274 84.6472 112.561C84.8131 112.848 85.1804 112.947 85.4677 112.781Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.9799 114.184C92.331 113.981 92.4513 113.532 92.2486 113.181C92.0459 112.83 91.5969 112.71 91.2458 112.912C90.8947 113.115 90.7744 113.564 90.9771 113.915C91.1799 114.266 91.6288 114.386 91.9799 114.184Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.6143 116.778C83.7737 116.686 83.8283 116.482 83.7363 116.323C83.6442 116.164 83.4403 116.109 83.2809 116.201C83.1214 116.293 83.0668 116.497 83.1589 116.656C83.2509 116.816 83.4548 116.87 83.6143 116.778Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M88.1526 119.167C88.312 119.075 88.3666 118.871 88.2746 118.711C88.1825 118.552 87.9787 118.497 87.8192 118.589C87.6598 118.681 87.6051 118.885 87.6972 119.045C87.7893 119.204 87.9931 119.259 88.1526 119.167Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.4752 121.33C87.6665 121.22 87.7321 120.975 87.6216 120.784C87.5112 120.592 87.2665 120.527 87.0752 120.637C86.8839 120.748 86.8183 120.993 86.9288 121.184C87.0392 121.375 87.2839 121.441 87.4752 121.33Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.2687 123.645C86.46 123.534 86.5256 123.29 86.4151 123.099C86.3046 122.907 86.06 122.842 85.8686 122.952C85.6773 123.063 85.6118 123.307 85.7222 123.499C85.8327 123.69 86.0773 123.755 86.2687 123.645Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.4696 125.995C86.6609 125.884 86.7265 125.64 86.616 125.448C86.5056 125.257 86.2609 125.191 86.0696 125.302C85.8782 125.412 85.8127 125.657 85.9232 125.848C86.0336 126.04 86.2783 126.105 86.4696 125.995Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.423 127.254C84.6783 127.107 84.7658 126.78 84.6184 126.525C84.471 126.27 84.1444 126.182 83.8891 126.33C83.6338 126.477 83.5463 126.803 83.6937 127.059C83.8411 127.314 84.1676 127.402 84.423 127.254Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.3749 128.901C86.5662 128.79 86.6318 128.545 86.5213 128.354C86.4108 128.163 86.1662 128.097 85.9749 128.208C85.7835 128.318 85.718 128.563 85.8284 128.754C85.9389 128.945 86.1836 129.011 86.3749 128.901Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M84.7906 129.893C85.046 129.746 85.1335 129.419 84.9861 129.164C84.8386 128.908 84.5121 128.821 84.2568 128.968C84.0014 129.116 83.9139 129.442 84.0614 129.698C84.2088 129.953 84.5353 130.041 84.7906 129.893Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.6974 126.907C91.9208 126.778 91.9974 126.493 91.8684 126.269C91.7394 126.046 91.4537 125.969 91.2302 126.098C91.0068 126.227 90.9303 126.513 91.0593 126.736C91.1882 126.96 91.4739 127.036 91.6974 126.907Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.4633 129.432C93.7505 129.267 93.849 128.899 93.6831 128.612C93.5173 128.325 93.1499 128.226 92.8627 128.392C92.5754 128.558 92.477 128.925 92.6428 129.213C92.8087 129.5 93.176 129.598 93.4633 129.432Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.8254 134.076C90.2723 133.818 90.4254 133.246 90.1674 132.799C89.9094 132.353 89.338 132.199 88.8911 132.457C88.4443 132.715 88.2912 133.287 88.5492 133.734C88.8072 134.181 89.3786 134.334 89.8254 134.076Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M109.43 84.6135C109.717 84.4477 109.816 84.0803 109.65 83.7931C109.484 83.5058 109.116 83.4074 108.829 83.5732C108.542 83.7391 108.444 84.1064 108.609 84.3937C108.775 84.6809 109.143 84.7794 109.43 84.6135Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.075 86.3986C110.522 86.1406 110.675 85.5692 110.417 85.1224C110.159 84.6755 109.588 84.5224 109.141 84.7804C108.694 85.0384 108.541 85.6098 108.799 86.0567C109.057 86.5035 109.628 86.6566 110.075 86.3986Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.157 89.0524C118.348 88.942 118.414 88.6973 118.303 88.506C118.193 88.3146 117.948 88.2491 117.757 88.3595C117.566 88.47 117.5 88.7147 117.61 88.906C117.721 89.0973 117.966 89.1629 118.157 89.0524Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.575 95.1278C114.958 94.9066 115.09 94.4169 114.868 94.0338C114.647 93.6508 114.158 93.5196 113.775 93.7407C113.392 93.9618 113.26 94.4516 113.481 94.8346C113.703 95.2177 114.192 95.3489 114.575 95.1278Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.916 97.5893C114.235 97.405 114.344 96.9969 114.16 96.6777C113.976 96.3585 113.568 96.2491 113.249 96.4334C112.929 96.6177 112.82 97.0258 113.004 97.345C113.189 97.6642 113.597 97.7736 113.916 97.5893Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.102 97.368C117.485 97.1469 117.616 96.6571 117.395 96.2741C117.174 95.891 116.684 95.7598 116.301 95.9809C115.918 96.2021 115.787 96.6918 116.008 97.0749C116.229 97.4579 116.719 97.5891 117.102 97.368Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.91 99.7122C115.325 99.4726 115.467 98.942 115.228 98.5271C114.988 98.1121 114.458 97.97 114.043 98.2095C113.628 98.4491 113.486 98.9797 113.725 99.3946C113.965 99.8096 114.495 99.9517 114.91 99.7122Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.571 99.103C122.018 98.845 122.171 98.2736 121.913 97.8267C121.655 97.3799 121.084 97.2268 120.637 97.4848C120.19 97.7428 120.037 98.3142 120.295 98.761C120.553 99.2079 121.124 99.361 121.571 99.103Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.053 99.6716C122.244 99.5611 122.31 99.3164 122.2 99.1251C122.089 98.9338 121.844 98.8682 121.653 98.9787C121.462 99.0892 121.396 99.3338 121.507 99.5251C121.617 99.7165 121.862 99.782 122.053 99.6716Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.264 103.247C120.456 103.137 120.521 102.892 120.411 102.701C120.3 102.509 120.056 102.444 119.864 102.554C119.673 102.665 119.607 102.909 119.718 103.101C119.828 103.292 120.073 103.357 120.264 103.247Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.696 101.846C124.951 101.698 125.039 101.372 124.891 101.116C124.744 100.861 124.417 100.773 124.162 100.921C123.906 101.068 123.819 101.395 123.966 101.65C124.114 101.905 124.44 101.993 124.696 101.846Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.881 104.705C124.296 104.465 124.438 103.935 124.198 103.52C123.959 103.105 123.428 102.963 123.013 103.202C122.598 103.442 122.456 103.972 122.695 104.387C122.935 104.802 123.466 104.944 123.881 104.705Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.652 110.46C119.971 110.276 120.08 109.867 119.896 109.548C119.712 109.229 119.303 109.12 118.984 109.304C118.665 109.488 118.556 109.896 118.74 110.216C118.924 110.535 119.332 110.644 119.652 110.46Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.07 108.758C128.261 108.648 128.327 108.403 128.216 108.212C128.106 108.02 127.861 107.955 127.67 108.065C127.478 108.176 127.413 108.42 127.523 108.612C127.634 108.803 127.878 108.869 128.07 108.758Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.746 115.686C123.097 115.483 123.217 115.034 123.014 114.683C122.812 114.332 122.363 114.212 122.012 114.414C121.661 114.617 121.54 115.066 121.743 115.417C121.946 115.768 122.395 115.888 122.746 115.686Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.184 98.2989C77.5351 98.0962 77.6554 97.6473 77.4527 97.2962C77.25 96.9451 76.801 96.8248 76.4499 97.0275C76.0988 97.2302 75.9785 97.6792 76.1812 98.0303C76.384 98.3814 76.8329 98.5017 77.184 98.2989Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.4352 98.8832C72.6586 98.7542 72.7352 98.4685 72.6062 98.245C72.4772 98.0216 72.1915 97.9451 71.968 98.074C71.7446 98.203 71.668 98.4887 71.797 98.7122C71.926 98.9356 72.2117 99.0122 72.4352 98.8832Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.3806 101.457C72.8275 101.199 72.9806 100.628 72.7226 100.181C72.4646 99.7344 71.8932 99.5813 71.4463 99.8393C70.9995 100.097 70.8464 100.669 71.1043 101.116C71.3623 101.562 71.9337 101.715 72.3806 101.457Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.5873 104.076C75.9065 103.891 76.0159 103.483 75.8316 103.164C75.6473 102.845 75.2392 102.735 74.92 102.92C74.6008 103.104 74.4914 103.512 74.6757 103.831C74.86 104.151 75.2681 104.26 75.5873 104.076Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.4477 106.506C74.7349 106.34 74.8333 105.973 74.6675 105.685C74.5016 105.398 74.1343 105.3 73.847 105.466C73.5598 105.631 73.4614 105.999 73.6272 106.286C73.7931 106.573 74.1604 106.672 74.4477 106.506Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.4183 108.181C77.6737 108.034 77.7612 107.707 77.6138 107.452C77.4663 107.197 77.1398 107.109 76.8845 107.256C76.6291 107.404 76.5416 107.73 76.6891 107.986C76.8365 108.241 77.163 108.329 77.4183 108.181Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.665 109.81C77.8245 109.718 77.8791 109.514 77.787 109.355C77.695 109.196 77.4911 109.141 77.3317 109.233C77.1722 109.325 77.1176 109.529 77.2096 109.688C77.3017 109.848 77.5056 109.902 77.665 109.81Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.0411 113.525C80.2645 113.396 80.3411 113.11 80.2121 112.887C80.0831 112.663 79.7974 112.587 79.574 112.716C79.3506 112.845 79.274 113.131 79.403 113.354C79.532 113.577 79.8177 113.654 80.0411 113.525Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.1759 114.984C72.4632 114.818 72.5616 114.451 72.3958 114.163C72.2299 113.876 71.8626 113.778 71.5753 113.944C71.2881 114.109 71.1896 114.477 71.3555 114.764C71.5213 115.051 71.8887 115.15 72.1759 114.984Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.4718 116.819C76.6952 116.69 76.7718 116.404 76.6428 116.181C76.5138 115.957 76.2281 115.881 76.0047 116.01C75.7812 116.139 75.7047 116.424 75.8337 116.648C75.9627 116.871 76.2484 116.948 76.4718 116.819Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.2702 117.549C72.6532 117.328 72.7845 116.838 72.5633 116.455C72.3422 116.072 71.8524 115.941 71.4694 116.162C71.0864 116.383 70.9551 116.873 71.1763 117.256C71.3974 117.639 71.8872 117.771 72.2702 117.549Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M71.8458 118.95C72.1331 118.784 72.2315 118.417 72.0657 118.13C71.8998 117.842 71.5325 117.744 71.2452 117.91C70.958 118.076 70.8595 118.443 71.0254 118.73C71.1913 119.018 71.5586 119.116 71.8458 118.95Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.8759 118.857C76.0672 118.747 76.1327 118.502 76.0223 118.311C75.9118 118.12 75.6672 118.054 75.4758 118.164C75.2845 118.275 75.2189 118.52 75.3294 118.711C75.4399 118.902 75.6845 118.968 75.8759 118.857Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.3459 120.474C75.697 120.272 75.8173 119.823 75.6146 119.471C75.4119 119.12 74.9629 119 74.6118 119.203C74.2607 119.405 74.1404 119.854 74.3431 120.206C74.5458 120.557 74.9948 120.677 75.3459 120.474Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.4445 121.843C76.8275 121.622 76.9588 121.132 76.7376 120.749C76.5165 120.366 76.0267 120.235 75.6437 120.456C75.2607 120.677 75.1295 121.167 75.3506 121.55C75.5717 121.933 76.0615 122.064 76.4445 121.843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.0441 121.15C76.3314 120.984 76.4298 120.617 76.2639 120.329C76.0981 120.042 75.7307 119.944 75.4435 120.11C75.1562 120.275 75.0578 120.643 75.2236 120.93C75.3895 121.217 75.7568 121.316 76.0441 121.15Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.6056 121.444C80.7969 121.333 80.8625 121.088 80.752 120.897C80.6415 120.706 80.3969 120.64 80.2056 120.751C80.0142 120.861 79.9487 121.106 80.0591 121.297C80.1696 121.488 80.4143 121.554 80.6056 121.444Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.6289 122.818C81.0758 122.56 81.2289 121.989 80.9709 121.542C80.7129 121.095 80.1415 120.942 79.6946 121.2C79.2478 121.458 79.0946 122.029 79.3526 122.476C79.6106 122.923 80.182 123.076 80.6289 122.818Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.7051 102.284C29.8645 102.192 29.9191 101.989 29.8271 101.829C29.735 101.67 29.5311 101.615 29.3717 101.707C29.2123 101.799 29.1576 102.003 29.2497 102.162C29.3417 102.322 29.5456 102.377 29.7051 102.284Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.0404 104.597C31.3596 104.412 31.469 104.004 31.2847 103.685C31.1004 103.366 30.6923 103.256 30.3731 103.441C30.0539 103.625 29.9446 104.033 30.1288 104.352C30.3131 104.672 30.7213 104.781 31.0404 104.597Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.7673 106.796C30.2142 106.538 30.3673 105.966 30.1093 105.52C29.8513 105.073 29.2799 104.92 28.833 105.178C28.3862 105.436 28.2331 106.007 28.4911 106.454C28.7491 106.901 29.3205 107.054 29.7673 106.796Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.8779 108.313C33.3248 108.055 33.4779 107.484 33.2199 107.037C32.9619 106.59 32.3905 106.437 31.9436 106.695C31.4968 106.953 31.3437 107.525 31.6017 107.971C31.8597 108.418 32.431 108.571 32.8779 108.313Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.5539 109.887C32.8411 109.722 32.9395 109.354 32.7737 109.067C32.6078 108.78 32.2405 108.681 31.9532 108.847C31.666 109.013 31.5676 109.38 31.7334 109.668C31.8993 109.955 32.2666 110.053 32.5539 109.887Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.7206 112.718C30.944 112.589 31.0206 112.304 30.8916 112.08C30.7626 111.857 30.4769 111.78 30.2534 111.909C30.03 112.038 29.9534 112.324 30.0824 112.547C30.2114 112.771 30.4971 112.847 30.7206 112.718Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.2114 111.552C36.5944 111.33 36.7256 110.841 36.5045 110.458C36.2833 110.075 35.7936 109.943 35.4106 110.165C35.0275 110.386 34.8963 110.875 35.1174 111.258C35.3386 111.641 35.8283 111.773 36.2114 111.552Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.391 113.334C35.5823 113.224 35.6479 112.979 35.5374 112.788C35.427 112.597 35.1823 112.531 34.991 112.642C34.7996 112.752 34.7341 112.997 34.8445 113.188C34.955 113.379 35.1997 113.445 35.391 113.334Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.7123 114.691C35.9357 114.562 36.0122 114.276 35.8833 114.053C35.7543 113.829 35.4686 113.753 35.2451 113.882C35.0217 114.011 34.9451 114.296 35.0741 114.52C35.2031 114.743 35.4888 114.82 35.7123 114.691Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.9872 113.687C40.3064 113.503 40.4158 113.095 40.2315 112.775C40.0472 112.456 39.6391 112.347 39.3199 112.531C39.0007 112.715 38.8913 113.123 39.0756 113.443C39.2599 113.762 39.668 113.871 39.9872 113.687Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.921 115.844C39.1444 115.715 39.221 115.429 39.092 115.205C38.963 114.982 38.6773 114.906 38.4539 115.034C38.2304 115.163 38.1539 115.449 38.2829 115.673C38.4119 115.896 38.6976 115.973 38.921 115.844Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.7182 119.773C36.0693 119.57 36.1896 119.121 35.9869 118.77C35.7842 118.419 35.3352 118.299 34.9841 118.502C34.633 118.704 34.5127 119.153 34.7154 119.504C34.9181 119.855 35.3671 119.976 35.7182 119.773Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.2152 121.298C37.5025 121.132 37.6009 120.765 37.4351 120.477C37.2692 120.19 36.9019 120.092 36.6146 120.258C36.3274 120.423 36.2289 120.791 36.3948 121.078C36.5606 121.365 36.928 121.464 37.2152 121.298Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.8994 121.136C42.2824 120.915 42.4136 120.425 42.1925 120.042C41.9713 119.659 41.4816 119.528 41.0986 119.749C40.7155 119.97 40.5843 120.46 40.8054 120.843C41.0266 121.226 41.5163 121.357 41.8994 121.136Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.7003 124.408C37.8917 124.297 37.9572 124.053 37.8467 123.861C37.7363 123.67 37.4916 123.604 37.3003 123.715C37.109 123.825 37.0434 124.07 37.1539 124.261C37.2643 124.453 37.509 124.518 37.7003 124.408Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.0019 124.389C40.2891 124.224 40.3875 123.856 40.2217 123.569C40.0558 123.282 39.6885 123.183 39.4012 123.349C39.114 123.515 39.0156 123.882 39.1814 124.17C39.3473 124.457 39.7146 124.555 40.0019 124.389Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.7131 122.133C47.128 121.894 47.2702 121.363 47.0306 120.948C46.7911 120.533 46.2605 120.391 45.8456 120.63C45.4306 120.87 45.2884 121.401 45.528 121.816C45.7676 122.23 46.2982 122.373 46.7131 122.133Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.9533 126.963C44.3363 126.742 44.4676 126.252 44.2464 125.869C44.0253 125.486 43.5355 125.355 43.1525 125.576C42.7695 125.797 42.6383 126.287 42.8594 126.67C43.0805 127.053 43.5703 127.184 43.9533 126.963Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.18 128.026C43.4035 127.897 43.48 127.611 43.351 127.388C43.222 127.164 42.9363 127.088 42.7129 127.217C42.4895 127.346 42.4129 127.631 42.5419 127.855C42.6709 128.078 42.9566 128.155 43.18 128.026Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.4184 127.352C49.8014 127.131 49.9327 126.641 49.7115 126.258C49.4904 125.875 49.0006 125.744 48.6176 125.965C48.2346 126.186 48.1033 126.676 48.3245 127.059C48.5456 127.442 49.0354 127.573 49.4184 127.352Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.6954 128.77C48.8868 128.66 48.9523 128.415 48.8419 128.224C48.7314 128.033 48.4867 127.967 48.2954 128.078C48.1041 128.188 48.0385 128.433 48.149 128.624C48.2595 128.815 48.5041 128.881 48.6954 128.77Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.5458 127.434C51.8011 127.286 51.8886 126.96 51.7412 126.705C51.5938 126.449 51.2673 126.362 51.0119 126.509C50.7566 126.657 50.6691 126.983 50.8165 127.238C50.9639 127.494 51.2904 127.581 51.5458 127.434Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.7975 135.492C47.0209 135.363 47.0975 135.078 46.9685 134.854C46.8395 134.631 46.5538 134.554 46.3303 134.683C46.1069 134.812 46.0304 135.098 46.1594 135.321C46.2883 135.545 46.574 135.621 46.7975 135.492Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.6552 132.689C54.0382 132.468 54.1695 131.978 53.9483 131.595C53.7272 131.212 53.2374 131.081 52.8544 131.302C52.4714 131.523 52.3402 132.013 52.5613 132.396C52.7824 132.779 53.2722 132.91 53.6552 132.689Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M50.9801 137.932C51.2674 137.766 51.3658 137.399 51.2 137.112C51.0341 136.824 50.6668 136.726 50.3795 136.892C50.0923 137.058 49.9938 137.425 50.1597 137.712C50.3255 138 50.6929 138.098 50.9801 137.932Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.7148 136.392C55.0659 136.19 55.1862 135.741 54.9835 135.39C54.7808 135.039 54.3318 134.918 53.9807 135.121C53.6296 135.324 53.5093 135.773 53.712 136.124C53.9147 136.475 54.3637 136.595 54.7148 136.392Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.2199 138.335C55.5071 138.17 55.6056 137.802 55.4397 137.515C55.2739 137.228 54.9065 137.129 54.6193 137.295C54.332 137.461 54.2336 137.828 54.3994 138.116C54.5653 138.403 54.9326 138.501 55.2199 138.335Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.5653 141.873C54.7887 141.744 54.8653 141.458 54.7363 141.235C54.6073 141.011 54.3216 140.935 54.0982 141.064C53.8747 141.193 53.7982 141.478 53.9272 141.702C54.0562 141.925 54.3419 142.002 54.5653 141.873Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.161 136.214C61.3524 136.104 61.4179 135.859 61.3074 135.668C61.197 135.477 60.9523 135.411 60.761 135.521C60.5697 135.632 60.5041 135.877 60.6146 136.068C60.725 136.259 60.9697 136.325 61.161 136.214Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M58.0002 141.816C58.4151 141.577 58.5573 141.046 58.3177 140.631C58.0782 140.216 57.5476 140.074 57.1327 140.314C56.7177 140.553 56.5756 141.084 56.8151 141.499C57.0547 141.914 57.5853 142.056 58.0002 141.816Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M61.0148 142.233C61.3659 142.03 61.4862 141.581 61.2835 141.23C61.0808 140.879 60.6319 140.759 60.2807 140.962C59.9296 141.164 59.8093 141.613 60.0121 141.964C60.2148 142.315 60.6637 142.436 61.0148 142.233Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.2387 143.415C63.5259 143.249 63.6243 142.882 63.4585 142.595C63.2926 142.307 62.9253 142.209 62.6381 142.375C62.3508 142.541 62.2524 142.908 62.4182 143.195C62.5841 143.482 62.9514 143.581 63.2387 143.415Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.6508 144.526C64.8742 144.397 64.9507 144.112 64.8217 143.888C64.6927 143.665 64.407 143.588 64.1836 143.717C63.9602 143.846 63.8836 144.132 64.0126 144.355C64.1416 144.579 64.4273 144.655 64.6508 144.526Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.6584 143.329C68.0095 143.127 68.1298 142.678 67.9271 142.326C67.7244 141.975 67.2754 141.855 66.9243 142.058C66.5732 142.26 66.4529 142.709 66.6556 143.061C66.8583 143.412 67.3073 143.532 67.6584 143.329Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M68.0226 148.898C68.3418 148.714 68.4512 148.306 68.2669 147.987C68.0826 147.668 67.6745 147.558 67.3553 147.743C67.0361 147.927 66.9267 148.335 67.111 148.654C67.2953 148.973 67.7034 149.083 68.0226 148.898Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.1591 146.587C72.5421 146.366 72.6734 145.876 72.4522 145.493C72.2311 145.11 71.7413 144.979 71.3583 145.2C70.9753 145.421 70.8441 145.911 71.0652 146.294C71.2863 146.677 71.7761 146.808 72.1591 146.587Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.7509 147.478C73.102 147.276 73.2223 146.827 73.0196 146.476C72.8169 146.125 72.3679 146.004 72.0168 146.207C71.6657 146.41 71.5454 146.859 71.7481 147.21C71.9509 147.561 72.3998 147.681 72.7509 147.478Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M75.3195 149.925C75.6067 149.759 75.7052 149.392 75.5393 149.105C75.3735 148.818 75.0061 148.719 74.7189 148.885C74.4316 149.051 74.3332 149.418 74.499 149.705C74.6649 149.993 75.0322 150.091 75.3195 149.925Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.0399 142.228C77.2633 142.099 77.3399 141.813 77.2109 141.59C77.0819 141.366 76.7962 141.29 76.5728 141.419C76.3493 141.548 76.2728 141.833 76.4018 142.057C76.5308 142.28 76.8165 142.357 77.0399 142.228Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.9387 143.25C78.3856 142.992 78.5387 142.421 78.2807 141.974C78.0227 141.527 77.4513 141.374 77.0044 141.632C76.5576 141.89 76.4045 142.462 76.6625 142.908C76.9204 143.355 77.4918 143.508 77.9387 143.25Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.6898 144.819C78.8812 144.709 78.9467 144.464 78.8362 144.273C78.7258 144.081 78.4811 144.016 78.2898 144.126C78.0985 144.237 78.0329 144.482 78.1434 144.673C78.2538 144.864 78.4985 144.93 78.6898 144.819Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.0273 142.198C80.1868 142.106 80.2414 141.902 80.1493 141.742C80.0573 141.583 79.8534 141.528 79.694 141.62C79.5345 141.712 79.4799 141.916 79.572 142.076C79.664 142.235 79.8679 142.29 80.0273 142.198Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M82.8185 149.834C83.1696 149.631 83.2899 149.182 83.0872 148.831C82.8845 148.48 82.4355 148.36 82.0845 148.563C81.7334 148.765 81.6131 149.214 81.8158 149.565C82.0185 149.917 82.4674 150.037 82.8185 149.834Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.1215 102.814C93.5365 102.574 93.6787 102.044 93.4391 101.629C93.1995 101.214 92.6689 101.072 92.254 101.311C91.8391 101.551 91.6969 102.081 91.9365 102.496C92.176 102.911 92.7066 103.053 93.1215 102.814Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.8586 101.306C88.2416 101.085 88.3728 100.595 88.1517 100.212C87.9306 99.8288 87.4408 99.6976 87.0578 99.9187C86.6748 100.14 86.5435 100.63 86.7647 101.013C86.9858 101.396 87.4756 101.527 87.8586 101.306Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.7247 106.007C89.9161 105.896 89.9816 105.652 89.8712 105.46C89.7607 105.269 89.516 105.203 89.3247 105.314C89.1334 105.424 89.0678 105.669 89.1783 105.86C89.2888 106.052 89.5334 106.117 89.7247 106.007Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.6464 103.352C83.8698 103.223 83.9463 102.938 83.8173 102.714C83.6883 102.491 83.4026 102.414 83.1792 102.543C82.9558 102.672 82.8792 102.958 83.0082 103.181C83.1372 103.405 83.4229 103.481 83.6464 103.352Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.8173 109.531C81.0086 109.421 81.0742 109.176 80.9637 108.985C80.8532 108.793 80.6086 108.728 80.4172 108.838C80.2259 108.949 80.1604 109.193 80.2708 109.385C80.3813 109.576 80.6259 109.642 80.8173 109.531Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.3276 100.025C74.7106 99.8038 74.8418 99.3141 74.6207 98.931C74.3996 98.548 73.9098 98.4168 73.5268 98.6379C73.1438 98.8591 73.0125 99.3488 73.2337 99.7319C73.4548 100.115 73.9446 100.246 74.3276 100.025Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.765 97.4583C71.0204 97.3109 71.1079 96.9844 70.9604 96.729C70.813 96.4737 70.4865 96.3862 70.2311 96.5336C69.9758 96.681 69.8883 97.0075 70.0357 97.2629C70.1832 97.5182 70.5097 97.6057 70.765 97.4583Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.479 93.1981C79.862 92.9769 79.9932 92.4871 79.7721 92.1041C79.5509 91.7211 79.0612 91.5899 78.6781 91.811C78.2951 92.0322 78.1639 92.5219 78.385 92.9049C78.6062 93.288 79.0959 93.4192 79.479 93.1981Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.7025 96.3828C81.8938 96.2723 81.9594 96.0276 81.8489 95.8363C81.7385 95.645 81.4938 95.5794 81.3025 95.6899C81.1112 95.8003 81.0456 96.045 81.1561 96.2363C81.2665 96.4277 81.5112 96.4932 81.7025 96.3828Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.2897 93.9261C83.5131 93.7971 83.5896 93.5114 83.4606 93.288C83.3316 93.0646 83.0459 92.988 82.8225 93.117C82.5991 93.246 82.5225 93.5317 82.6515 93.7551C82.7805 93.9786 83.0662 94.0551 83.2897 93.9261Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.2295 96.0816C86.6125 95.8605 86.7437 95.3707 86.5226 94.9877C86.3014 94.6046 85.8117 94.4734 85.4286 94.6946C85.0456 94.9157 84.9144 95.4055 85.1355 95.7885C85.3566 96.1715 85.8464 96.3027 86.2295 96.0816Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M88.1103 89.0624C88.4295 88.8781 88.5388 88.47 88.3545 88.1508C88.1703 87.8316 87.7621 87.7223 87.4429 87.9066C87.1237 88.0908 87.0144 88.499 87.1987 88.8182C87.3829 89.1374 87.7911 89.2467 88.1103 89.0624Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.5026 91.7286C93.7579 91.5811 93.8454 91.2546 93.698 90.9993C93.5506 90.7439 93.224 90.6564 92.9687 90.8039C92.7134 90.9513 92.6259 91.2778 92.7733 91.5331C92.9207 91.7885 93.2472 91.876 93.5026 91.7286Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.734 96.5277C95.9574 96.3987 96.034 96.113 95.905 95.8896C95.776 95.6661 95.4903 95.5896 95.2669 95.7186C95.0434 95.8476 94.9669 96.1333 95.0959 96.3567C95.2249 96.5801 95.5106 96.6567 95.734 96.5277Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.154 101.256C97.5051 101.053 97.6254 100.604 97.4227 100.253C97.2199 99.9021 96.771 99.7818 96.4199 99.9845C96.0688 100.187 95.9485 100.636 96.1512 100.987C96.3539 101.338 96.8029 101.459 97.154 101.256Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.5146 102.281C97.8657 102.078 97.986 101.629 97.7833 101.278C97.5806 100.927 97.1316 100.807 96.7805 101.009C96.4294 101.212 96.3091 101.661 96.5118 102.012C96.7145 102.363 97.1635 102.484 97.5146 102.281Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.7968 105.045C96.116 104.861 96.2253 104.452 96.041 104.133C95.8568 103.814 95.4486 103.705 95.1294 103.889C94.8103 104.073 94.7009 104.481 94.8852 104.801C95.0695 105.12 95.4776 105.229 95.7968 105.045Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.5546 106.106C91.7459 105.996 91.8115 105.751 91.701 105.56C91.5905 105.369 91.3459 105.303 91.1545 105.414C90.9632 105.524 90.8977 105.769 91.0081 105.96C91.1186 106.151 91.3632 106.217 91.5546 106.106Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.8649 105.849C90.0562 105.738 90.1218 105.494 90.0113 105.302C89.9008 105.111 89.6562 105.045 89.4648 105.156C89.2735 105.266 89.208 105.511 89.3184 105.702C89.4289 105.894 89.6735 105.959 89.8649 105.849Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.7307 111.628C88.1137 111.407 88.2449 110.917 88.0238 110.534C87.8026 110.151 87.3129 110.02 86.9298 110.241C86.5468 110.462 86.4156 110.952 86.6367 111.335C86.8579 111.718 87.3476 111.849 87.7307 111.628Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.4711 104.918C83.7264 104.77 83.8139 104.444 83.6665 104.188C83.5191 103.933 83.1926 103.846 82.9372 103.993C82.6819 104.14 82.5944 104.467 82.7418 104.722C82.8892 104.978 83.2157 105.065 83.4711 104.918Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.8708 106.15C82.2219 105.947 82.3422 105.498 82.1395 105.147C81.9368 104.796 81.4878 104.676 81.1367 104.878C80.7856 105.081 80.6653 105.53 80.868 105.881C81.0707 106.232 81.5197 106.352 81.8708 106.15Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.3901 111.472C76.7731 111.251 76.9043 110.761 76.6832 110.378C76.4621 109.995 75.9723 109.864 75.5893 110.085C75.2062 110.306 75.075 110.796 75.2962 111.179C75.5173 111.562 76.0071 111.693 76.3901 111.472Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M78.4628 106.653C78.782 106.469 78.8914 106.061 78.7071 105.742C78.5228 105.423 78.1147 105.313 77.7955 105.498C77.4763 105.682 77.3669 106.09 77.5512 106.409C77.7355 106.728 78.1436 106.838 78.4628 106.653Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M79.1909 102.843C79.6058 102.603 79.748 102.072 79.5084 101.657C79.2689 101.243 78.7383 101.1 78.3233 101.34C77.9084 101.579 77.7662 102.11 78.0058 102.525C78.2454 102.94 78.7759 103.082 79.1909 102.843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M72.2446 100.688C72.6276 100.467 72.7588 99.9774 72.5377 99.5944C72.3166 99.2114 71.8268 99.0801 71.4438 99.3013C71.0607 99.5224 70.9295 100.012 71.1506 100.395C71.3718 100.778 71.8616 100.909 72.2446 100.688Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.8529 97.9897C78.1402 97.8239 78.2386 97.4565 78.0728 97.1693C77.9069 96.882 77.5396 96.7836 77.2523 96.9495C76.9651 97.1153 76.8666 97.4826 77.0325 97.7699C77.1983 98.0572 77.5657 98.1556 77.8529 97.9897Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M76.2995 95.0328C76.459 94.9407 76.5136 94.7368 76.4216 94.5774C76.3295 94.418 76.1256 94.3633 75.9662 94.4554C75.8067 94.5474 75.7521 94.7513 75.8442 94.9108C75.9362 95.0702 76.1401 95.1248 76.2995 95.0328Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.2196 91.4203C77.443 91.2913 77.5196 91.0056 77.3906 90.7821C77.2616 90.5587 76.9759 90.4822 76.7524 90.6112C76.529 90.7402 76.4525 91.0259 76.5815 91.2493C76.7105 91.4727 76.9962 91.5493 77.2196 91.4203Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M77.401 89.4658C77.6883 89.3 77.7867 88.9326 77.6209 88.6454C77.455 88.3581 77.0877 88.2597 76.8004 88.4255C76.5131 88.5914 76.4147 88.9587 76.5806 89.246C76.7464 89.5332 77.1138 89.6317 77.401 89.4658Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.2257 96.3516C83.4811 96.2042 83.5686 95.8777 83.4211 95.6223C83.2737 95.367 82.9472 95.2795 82.6918 95.4269C82.4365 95.5743 82.349 95.9008 82.4964 96.1562C82.6439 96.4115 82.9704 96.499 83.2257 96.3516Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.7756 91.5649C84.2225 91.3069 84.3756 90.7355 84.1176 90.2886C83.8596 89.8418 83.2882 89.6887 82.8413 89.9467C82.3945 90.2047 82.2414 90.7761 82.4994 91.2229C82.7574 91.6698 83.3288 91.8229 83.7756 91.5649Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.8925 88.0299C84.2436 87.8272 84.3639 87.3782 84.1612 87.0271C83.9585 86.676 83.5095 86.5557 83.1584 86.7584C82.8073 86.9612 82.687 87.4101 82.8897 87.7612C83.0925 88.1123 83.5414 88.2326 83.8925 88.0299Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M91.0531 93.7592C91.3723 93.5749 91.4817 93.1668 91.2974 92.8476C91.1131 92.5284 90.705 92.4191 90.3858 92.6033C90.0666 92.7876 89.9573 93.1958 90.1415 93.5149C90.3258 93.8341 90.734 93.9435 91.0531 93.7592Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.2394 95.4227C89.4628 95.2937 89.5393 95.008 89.4103 94.7846C89.2814 94.5612 88.9957 94.4846 88.7722 94.6136C88.5488 94.7426 88.4722 95.0283 88.6012 95.2517C88.7302 95.4752 89.0159 95.5517 89.2394 95.4227Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.937 88.7746C84.3839 88.5166 84.537 87.9452 84.279 87.4984C84.021 87.0515 83.4496 86.8984 83.0027 87.1564C82.5559 87.4144 82.4027 87.9858 82.6607 88.4326C82.9187 88.8795 83.4901 89.0326 83.937 88.7746Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.3617 92.0372C87.617 91.8897 87.7045 91.5632 87.5571 91.3079C87.4097 91.0525 87.0832 90.965 86.8278 91.1125C86.5725 91.2599 86.485 91.5864 86.6324 91.8417C86.7798 92.0971 87.1064 92.1846 87.3617 92.0372Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.5392 88.8561C93.7626 88.7271 93.8391 88.4414 93.7101 88.2179C93.5811 87.9945 93.2955 87.918 93.072 88.047C92.8486 88.1759 92.7721 88.4616 92.9011 88.6851C93.03 88.9085 93.3157 88.9851 93.5392 88.8561Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.2594 86.63C95.4828 86.501 95.5594 86.2153 95.4304 85.9919C95.3014 85.7684 95.0157 85.6919 94.7923 85.8209C94.5688 85.9499 94.4923 86.2356 94.6213 86.459C94.7503 86.6824 95.036 86.759 95.2594 86.63Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.749 91.5919C103.973 91.4629 104.049 91.1772 103.92 90.9538C103.791 90.7303 103.506 90.6538 103.282 90.7828C103.059 90.9118 102.982 91.1975 103.111 91.4209C103.24 91.6443 103.526 91.7209 103.749 91.5919Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M101.202 95.9898C101.362 95.8978 101.416 95.6939 101.324 95.5344C101.232 95.375 101.028 95.3204 100.869 95.4124C100.71 95.5045 100.655 95.7084 100.747 95.8678C100.839 96.0272 101.043 96.0819 101.202 95.9898Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.016 95.2531C105.335 95.0688 105.444 94.6607 105.26 94.3415C105.076 94.0223 104.667 93.913 104.348 94.0972C104.029 94.2815 103.92 94.6897 104.104 95.0088C104.288 95.328 104.696 95.4374 105.016 95.2531Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.097 100.6C105.512 100.36 105.654 99.8297 105.415 99.4148C105.175 98.9998 104.645 98.8577 104.23 99.0972C103.815 99.3368 103.672 99.8674 103.912 100.282C104.152 100.697 104.682 100.839 105.097 100.6Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M101.808 105.581C102.223 105.341 102.365 104.811 102.126 104.396C101.886 103.981 101.356 103.839 100.941 104.078C100.526 104.318 100.384 104.849 100.623 105.264C100.863 105.678 101.393 105.821 101.808 105.581Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M102.521 109.485C102.872 109.282 102.993 108.833 102.79 108.482C102.587 108.131 102.138 108.011 101.787 108.213C101.436 108.416 101.316 108.865 101.518 109.216C101.721 109.567 102.17 109.687 102.521 109.485Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.5102 110.682C97.8613 110.48 97.9816 110.031 97.7789 109.68C97.5762 109.329 97.1272 109.208 96.7761 109.411C96.425 109.614 96.3047 110.063 96.5074 110.414C96.7101 110.765 97.1591 110.885 97.5102 110.682Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M97.2877 116.436C97.5111 116.307 97.5877 116.022 97.4587 115.798C97.3297 115.575 97.044 115.498 96.8206 115.627C96.5971 115.756 96.5206 116.042 96.6496 116.265C96.7786 116.489 97.0643 116.565 97.2877 116.436Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M95.33 119.185C95.6492 119 95.7585 118.592 95.5743 118.273C95.39 117.954 94.9818 117.845 94.6626 118.029C94.3435 118.213 94.2341 118.621 94.4184 118.94C94.6027 119.26 95.0108 119.369 95.33 119.185Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M93.1926 116.951C93.5437 116.749 93.664 116.3 93.4612 115.949C93.2585 115.597 92.8096 115.477 92.4585 115.68C92.1074 115.883 91.9871 116.331 92.1898 116.683C92.3925 117.034 92.8415 117.154 93.1926 116.951Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M89.6391 122.008C90.0541 121.768 90.1962 121.238 89.9567 120.823C89.7171 120.408 89.1865 120.266 88.7716 120.505C88.3566 120.745 88.2145 121.276 88.454 121.691C88.6936 122.105 89.2242 122.248 89.6391 122.008Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M86.2656 119.101C86.6805 118.862 86.8227 118.331 86.5831 117.916C86.3436 117.501 85.813 117.359 85.398 117.598C84.9831 117.838 84.8409 118.369 85.0805 118.784C85.3201 119.198 85.8507 119.341 86.2656 119.101Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M87.7224 113.482C88.1373 113.243 88.2795 112.712 88.0399 112.297C87.8003 111.882 87.2698 111.74 86.8548 111.98C86.4399 112.219 86.2977 112.75 86.5373 113.165C86.7769 113.58 87.3074 113.722 87.7224 113.482Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M82.7836 117.875C83.1028 117.691 83.2121 117.283 83.0279 116.963C82.8436 116.644 82.4354 116.535 82.1163 116.719C81.7971 116.903 81.6877 117.311 81.872 117.631C82.0563 117.95 82.4644 118.059 82.7836 117.875Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M83.6909 113.575C83.8503 113.482 83.905 113.279 83.8129 113.119C83.7209 112.96 83.517 112.905 83.3575 112.997C83.1981 113.089 83.1435 113.293 83.2355 113.453C83.3276 113.612 83.5315 113.667 83.6909 113.575Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M81.8998 109.137C82.0911 109.027 82.1567 108.782 82.0462 108.591C81.9358 108.4 81.6911 108.334 81.4998 108.445C81.3084 108.555 81.2429 108.8 81.3533 108.991C81.4638 109.182 81.7085 109.248 81.8998 109.137Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M80.4723 108.266C80.6636 108.156 80.7292 107.911 80.6187 107.72C80.5082 107.529 80.2636 107.463 80.0723 107.573C79.8809 107.684 79.8154 107.929 79.9258 108.12C80.0363 108.311 80.281 108.377 80.4723 108.266Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.1639 109.752C74.3873 109.623 74.4639 109.337 74.3349 109.114C74.2059 108.89 73.9202 108.814 73.6968 108.943C73.4734 109.072 73.3968 109.357 73.5258 109.581C73.6548 109.804 73.9405 109.881 74.1639 109.752Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M71.2465 108.97C71.5976 108.767 71.7179 108.318 71.5152 107.967C71.3125 107.616 70.8635 107.496 70.5124 107.699C70.1613 107.901 70.041 108.35 70.2437 108.701C70.4465 109.053 70.8954 109.173 71.2465 108.97Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M74.8319 101.967C75.0233 101.857 75.0888 101.612 74.9783 101.421C74.8679 101.23 74.6232 101.164 74.4319 101.275C74.2406 101.385 74.175 101.63 74.2855 101.821C74.3959 102.012 74.6406 102.078 74.8319 101.967Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M68.5653 103.659C68.7566 103.549 68.8222 103.304 68.7117 103.113C68.6013 102.921 68.3566 102.856 68.1653 102.966C67.974 103.077 67.9084 103.321 68.0189 103.513C68.1293 103.704 68.374 103.77 68.5653 103.659Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M70.6013 99.7105C71.0162 99.4709 71.1584 98.9403 70.9188 98.5254C70.6793 98.1104 70.1487 97.9683 69.7337 98.2078C69.3188 98.4474 69.1766 98.978 69.4162 99.3929C69.6558 99.8079 70.1863 99.95 70.6013 99.7105Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.6029 95.5853C67.8263 95.4563 67.9029 95.1706 67.7739 94.9472C67.6449 94.7238 67.3592 94.6472 67.1357 94.7762C66.9123 94.9052 66.8358 95.1909 66.9648 95.4143C67.0938 95.6377 67.3795 95.7143 67.6029 95.5853Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M67.4372 91.828C67.7883 91.6253 67.9086 91.1763 67.7059 90.8252C67.5032 90.4741 67.0542 90.3538 66.7031 90.5565C66.352 90.7593 66.2317 91.2082 66.4344 91.5593C66.6371 91.9104 67.0861 92.0307 67.4372 91.828Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.205 90.691C133.588 90.4698 133.719 89.9801 133.498 89.5971C133.277 89.214 132.787 89.0828 132.404 89.3039C132.021 89.5251 131.889 90.0148 132.111 90.3979C132.332 90.7809 132.822 90.9121 133.205 90.691Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.898 90.0207C135.089 89.9102 135.155 89.6656 135.044 89.4742C134.934 89.2829 134.689 89.2174 134.498 89.3278C134.306 89.4383 134.241 89.6829 134.351 89.8743C134.462 90.0656 134.706 90.1311 134.898 90.0207Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.403 94.5385C122.754 94.3358 122.874 93.8868 122.672 93.5357C122.469 93.1846 122.02 93.0643 121.669 93.267C121.318 93.4697 121.198 93.9187 121.4 94.2698C121.603 94.6209 122.052 94.7412 122.403 94.5385Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.459 93.3101C119.746 93.1442 119.845 92.7769 119.679 92.4896C119.513 92.2023 119.146 92.1039 118.859 92.2698C118.571 92.4356 118.473 92.8029 118.639 93.0902C118.805 93.3775 119.172 93.4759 119.459 93.3101Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.682 89.9466C123.841 89.8546 123.896 89.6507 123.804 89.4912C123.712 89.3318 123.508 89.2772 123.348 89.3692C123.189 89.4613 123.134 89.6651 123.226 89.8246C123.318 89.984 123.522 90.0387 123.682 89.9466Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.946 87.8681C124.233 87.7023 124.332 87.335 124.166 87.0477C124 86.7604 123.633 86.662 123.346 86.8279C123.058 86.9937 122.96 87.361 123.126 87.6483C123.292 87.9356 123.659 88.034 123.946 87.8681Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.011 83.977C124.202 83.8665 124.268 83.6219 124.158 83.4305C124.047 83.2392 123.802 83.1737 123.611 83.2841C123.42 83.3946 123.354 83.6392 123.465 83.8306C123.575 84.0219 123.82 84.0875 124.011 83.977Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.341 80.1621C118.756 79.9225 118.898 79.392 118.659 78.977C118.419 78.5621 117.888 78.4199 117.473 78.6595C117.059 78.8991 116.916 79.4296 117.156 79.8446C117.396 80.2595 117.926 80.4017 118.341 80.1621Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.653 78.1703C117.973 77.9861 118.082 77.5779 117.898 77.2587C117.713 76.9395 117.305 76.8302 116.986 77.0145C116.667 77.1988 116.558 77.6069 116.742 77.9261C116.926 78.2453 117.334 78.3546 117.653 78.1703Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.172 79.1843C124.587 78.9448 124.729 78.4142 124.49 77.9992C124.25 77.5843 123.72 77.4421 123.305 77.6817C122.89 77.9213 122.748 78.4519 122.987 78.8668C123.227 79.2817 123.757 79.4239 124.172 79.1843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.883 79.3181C135.075 79.2076 135.14 78.9629 135.03 78.7716C134.919 78.5803 134.675 78.5147 134.483 78.6252C134.292 78.7356 134.227 78.9803 134.337 79.1716C134.447 79.363 134.692 79.4285 134.883 79.3181Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.114 82.535C130.369 82.3875 130.457 82.061 130.309 81.8057C130.162 81.5503 129.835 81.4628 129.58 81.6103C129.325 81.7577 129.237 82.0842 129.385 82.3395C129.532 82.5949 129.859 82.6824 130.114 82.535Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M138.707 82.7368C139.122 82.4973 139.264 81.9667 139.025 81.5517C138.785 81.1368 138.254 80.9946 137.839 81.2342C137.425 81.4738 137.282 82.0043 137.522 82.4193C137.761 82.8342 138.292 82.9764 138.707 82.7368Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.799 86.6489C134.118 86.4646 134.228 86.0565 134.043 85.7373C133.859 85.4181 133.451 85.3087 133.132 85.493C132.813 85.6773 132.703 86.0854 132.888 86.4046C133.072 86.7238 133.48 86.8332 133.799 86.6489Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.093 87.6766C139.476 87.4555 139.607 86.9657 139.386 86.5827C139.165 86.1997 138.675 86.0684 138.292 86.2895C137.909 86.5107 137.778 87.0004 137.999 87.3835C138.22 87.7665 138.71 87.8977 139.093 87.6766Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.245 90.3596C133.66 90.1201 133.802 89.5895 133.562 89.1746C133.323 88.7596 132.792 88.6174 132.377 88.857C131.962 89.0966 131.82 89.6271 132.06 90.0421C132.299 90.457 132.83 90.5992 133.245 90.3596Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M140.929 92.8584C141.216 92.6925 141.315 92.3252 141.149 92.0379C140.983 91.7507 140.616 91.6523 140.328 91.8181C140.041 91.984 139.943 92.3513 140.108 92.6386C140.274 92.9258 140.642 93.0242 140.929 92.8584Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M137.323 93.5533C137.578 93.4058 137.666 93.0793 137.518 92.824C137.371 92.5686 137.044 92.4811 136.789 92.6286C136.534 92.776 136.446 93.1025 136.594 93.3578C136.741 93.6132 137.068 93.7007 137.323 93.5533Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.853 95.0173C134.3 94.7593 134.453 94.1879 134.195 93.741C133.937 93.2942 133.366 93.1411 132.919 93.3991C132.472 93.6571 132.319 94.2285 132.577 94.6753C132.835 95.1222 133.406 95.2753 133.853 95.0173Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M139.759 97.7721C140.142 97.5509 140.273 97.0611 140.052 96.6781C139.831 96.2951 139.341 96.1638 138.958 96.385C138.575 96.6061 138.444 97.0959 138.665 97.4789C138.886 97.862 139.376 97.9932 139.759 97.7721Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.906 101.382C134.193 101.216 134.292 100.849 134.126 100.562C133.96 100.275 133.593 100.176 133.306 100.342C133.018 100.508 132.92 100.875 133.086 101.162C133.252 101.45 133.619 101.548 133.906 101.382Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M134.632 104.508C134.951 104.324 135.061 103.916 134.876 103.596C134.692 103.277 134.284 103.168 133.965 103.352C133.645 103.536 133.536 103.945 133.72 104.264C133.905 104.583 134.313 104.692 134.632 104.508Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.184 105.074C132.44 104.926 132.527 104.6 132.38 104.344C132.232 104.089 131.906 104.001 131.65 104.149C131.395 104.296 131.308 104.623 131.455 104.878C131.602 105.133 131.929 105.221 132.184 105.074Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M129.623 106.244C129.847 106.115 129.923 105.829 129.794 105.606C129.665 105.382 129.38 105.306 129.156 105.435C128.933 105.564 128.856 105.849 128.985 106.073C129.114 106.296 129.4 106.373 129.623 106.244Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.888 108.828C133.175 108.663 133.274 108.295 133.108 108.008C132.942 107.721 132.575 107.622 132.287 107.788C132 107.954 131.902 108.321 132.068 108.609C132.233 108.896 132.601 108.994 132.888 108.828Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.686 109.946C132.845 109.854 132.9 109.65 132.808 109.491C132.715 109.331 132.512 109.277 132.352 109.369C132.193 109.461 132.138 109.665 132.23 109.824C132.322 109.984 132.526 110.038 132.686 109.946Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M132.608 114.615C132.927 114.431 133.036 114.023 132.852 113.704C132.668 113.385 132.259 113.275 131.94 113.46C131.621 113.644 131.512 114.052 131.696 114.371C131.88 114.69 132.288 114.8 132.608 114.615Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M130.86 116.394C131.051 116.284 131.117 116.039 131.006 115.848C130.896 115.656 130.651 115.591 130.46 115.701C130.269 115.812 130.203 116.056 130.314 116.248C130.424 116.439 130.669 116.505 130.86 116.394Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.14 116.229C125.331 116.119 125.396 115.874 125.286 115.683C125.175 115.491 124.931 115.426 124.739 115.536C124.548 115.647 124.483 115.891 124.593 116.083C124.704 116.274 124.948 116.34 125.14 116.229Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.157 119.188C128.317 119.096 128.372 118.892 128.279 118.732C128.187 118.573 127.984 118.518 127.824 118.61C127.665 118.702 127.61 118.906 127.702 119.066C127.794 119.225 127.998 119.28 128.157 119.188Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M126.751 121.156C127.071 120.972 127.18 120.564 126.996 120.244C126.811 119.925 126.403 119.816 126.084 120C125.765 120.184 125.655 120.592 125.84 120.912C126.024 121.231 126.432 121.34 126.751 121.156Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.964 123.654C129.283 123.47 129.393 123.062 129.209 122.742C129.024 122.423 128.616 122.314 128.297 122.498C127.978 122.682 127.868 123.091 128.053 123.41C128.237 123.729 128.645 123.838 128.964 123.654Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M124.397 122.283C124.588 122.173 124.654 121.928 124.543 121.737C124.433 121.546 124.188 121.48 123.997 121.591C123.805 121.701 123.74 121.946 123.85 122.137C123.961 122.328 124.206 122.394 124.397 122.283Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.081 125.203C125.464 124.982 125.595 124.492 125.374 124.109C125.153 123.726 124.663 123.595 124.28 123.816C123.897 124.037 123.766 124.527 123.987 124.91C124.208 125.293 124.698 125.424 125.081 125.203Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.505 100.721C122.856 100.518 122.976 100.069 122.774 99.7183C122.571 99.3672 122.122 99.2469 121.771 99.4496C121.42 99.6523 121.3 100.101 121.502 100.452C121.705 100.803 122.154 100.924 122.505 100.721Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.059 92.6162C119.474 92.3767 119.616 91.8461 119.376 91.4311C119.137 91.0162 118.606 90.874 118.191 91.1136C117.776 91.3532 117.634 91.8837 117.874 92.2987C118.113 92.7136 118.644 92.8558 119.059 92.6162Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.526 95.9673C117.813 95.8014 117.911 95.4341 117.746 95.1468C117.58 94.8596 117.212 94.7611 116.925 94.927C116.638 95.0928 116.539 95.4602 116.705 95.7474C116.871 96.0347 117.239 96.1331 117.526 95.9673Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.061 101.168C117.284 101.039 117.361 100.753 117.232 100.529C117.103 100.306 116.817 100.229 116.594 100.358C116.37 100.487 116.294 100.773 116.423 100.997C116.552 101.22 116.837 101.297 117.061 101.168Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.591 100.09C116.006 99.85 116.148 99.3195 115.908 98.9045C115.669 98.4896 115.138 98.3474 114.723 98.587C114.308 98.8265 114.166 99.3571 114.406 99.7721C114.645 100.187 115.176 100.329 115.591 100.09Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.685 99.7257C114.036 99.523 114.157 99.0741 113.954 98.723C113.751 98.3718 113.302 98.2515 112.951 98.4543C112.6 98.657 112.48 99.1059 112.682 99.457C112.885 99.8081 113.334 99.9284 113.685 99.7257Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M111.221 101.996C111.636 101.757 111.778 101.226 111.538 100.811C111.299 100.396 110.768 100.254 110.353 100.494C109.938 100.733 109.796 101.264 110.036 101.679C110.275 102.094 110.806 102.236 111.221 101.996Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.895 94.6317C109.278 94.4105 109.409 93.9208 109.188 93.5377C108.967 93.1547 108.477 93.0235 108.094 93.2446C107.711 93.4658 107.58 93.9555 107.801 94.3385C108.022 94.7216 108.512 94.8528 108.895 94.6317Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.047 94.5035C106.43 94.2824 106.561 93.7926 106.34 93.4096C106.119 93.0265 105.629 92.8953 105.246 93.1164C104.863 93.3376 104.732 93.8273 104.953 94.2104C105.174 94.5934 105.664 94.7246 106.047 94.5035Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M104.947 96.6026C105.17 96.4736 105.247 96.1879 105.118 95.9645C104.989 95.7411 104.703 95.6645 104.48 95.7935C104.256 95.9225 104.18 96.2082 104.309 96.4316C104.438 96.6551 104.723 96.7316 104.947 96.6026Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.539 102.039C103.763 101.91 103.839 101.624 103.71 101.401C103.581 101.177 103.296 101.101 103.072 101.23C102.849 101.359 102.772 101.645 102.901 101.868C103.03 102.091 103.316 102.168 103.539 102.039Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M100.907 94.5429C101.226 94.3586 101.335 93.9505 101.151 93.6313C100.967 93.3121 100.558 93.2028 100.239 93.387C99.9201 93.5713 99.8108 93.9795 99.995 94.2986C100.179 94.6178 100.587 94.7272 100.907 94.5429Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.101 100.212C103.548 99.9536 103.701 99.3822 103.443 98.9354C103.185 98.4885 102.613 98.3354 102.167 98.5934C101.72 98.8514 101.567 99.4228 101.825 99.8697C102.083 100.317 102.654 100.47 103.101 100.212Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M98.5276 86.2844C98.9744 86.0264 99.1275 85.455 98.8695 85.0081C98.6115 84.5613 98.0401 84.4082 97.5933 84.6662C97.1464 84.9242 96.9933 85.4956 97.2513 85.9424C97.5093 86.3893 98.0807 86.5424 98.5276 86.2844Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M99.1918 84.8993C99.4152 84.7703 99.4917 84.4846 99.3627 84.2611C99.2337 84.0377 98.948 83.9612 98.7246 84.0902C98.5012 84.2192 98.4246 84.5049 98.5536 84.7283C98.6826 84.9517 98.9683 85.0283 99.1918 84.8993Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M101.624 86.5769C101.88 86.4295 101.967 86.103 101.82 85.8477C101.672 85.5923 101.346 85.5048 101.091 85.6523C100.835 85.7997 100.748 86.1262 100.895 86.3815C101.043 86.6369 101.369 86.7244 101.624 86.5769Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M103.116 85.0225C103.371 84.8751 103.459 84.5486 103.311 84.2932C103.164 84.0379 102.837 83.9504 102.582 84.0978C102.327 84.2452 102.239 84.5717 102.387 84.8271C102.534 85.0824 102.861 85.1699 103.116 85.0225Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.12 83.5562C105.28 83.4642 105.334 83.2603 105.242 83.1009C105.15 82.9414 104.946 82.8868 104.787 82.9788C104.627 83.0709 104.573 83.2748 104.665 83.4342C104.757 83.5936 104.961 83.6483 105.12 83.5562Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.979 90.1124C107.203 89.9834 107.279 89.6977 107.15 89.4743C107.021 89.2509 106.735 89.1743 106.512 89.3033C106.289 89.4323 106.212 89.718 106.341 89.9414C106.47 90.1648 106.756 90.2414 106.979 90.1124Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.124 92.7639C108.316 92.6534 108.381 92.4087 108.271 92.2174C108.16 92.0261 107.916 91.9605 107.724 92.071C107.533 92.1814 107.467 92.4261 107.578 92.6174C107.688 92.8088 107.933 92.8743 108.124 92.7639Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M109.227 85.7315C109.386 85.6395 109.441 85.4356 109.349 85.2761C109.257 85.1167 109.053 85.0621 108.894 85.1541C108.734 85.2462 108.68 85.4501 108.772 85.6095C108.864 85.7689 109.068 85.8236 109.227 85.7315Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M109.939 85.629C110.098 85.5369 110.153 85.333 110.061 85.1736C109.969 85.0142 109.765 84.9595 109.605 85.0516C109.446 85.1436 109.391 85.3475 109.483 85.507C109.575 85.6664 109.779 85.721 109.939 85.629Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.547 90.9441C113.962 90.7045 114.104 90.174 113.864 89.759C113.625 89.3441 113.094 89.2019 112.679 89.4415C112.264 89.681 112.122 90.2116 112.362 90.6266C112.601 91.0415 113.132 91.1837 113.547 90.9441Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M116.139 91.2962C116.298 91.2042 116.353 91.0003 116.261 90.8408C116.169 90.6814 115.965 90.6268 115.805 90.7188C115.646 90.8109 115.591 91.0148 115.683 91.1742C115.775 91.3336 115.979 91.3883 116.139 91.2962Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.345 86.9788C117.632 86.8129 117.731 86.4456 117.565 86.1583C117.399 85.871 117.032 85.7726 116.745 85.9385C116.457 86.1043 116.359 86.4716 116.525 86.7589C116.691 87.0462 117.058 87.1446 117.345 86.9788Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.811 86.7111C120.13 86.5268 120.239 86.1187 120.055 85.7995C119.871 85.4803 119.463 85.371 119.143 85.5553C118.824 85.7395 118.715 86.1477 118.899 86.4669C119.083 86.786 119.492 86.8954 119.811 86.7111Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M120.614 88.6363C120.997 88.4152 121.128 87.9254 120.907 87.5424C120.686 87.1594 120.196 87.0281 119.813 87.2493C119.43 87.4704 119.299 87.9602 119.52 88.3432C119.741 88.7262 120.231 88.8574 120.614 88.6363Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.249 86.2661C121.568 86.0818 121.677 85.6736 121.493 85.3544C121.309 85.0353 120.901 84.9259 120.582 85.1102C120.262 85.2945 120.153 85.7026 120.337 86.0218C120.522 86.341 120.93 86.4503 121.249 86.2661Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.121 86.5721C123.472 86.3694 123.592 85.9205 123.39 85.5694C123.187 85.2183 122.738 85.098 122.387 85.3007C122.036 85.5034 121.916 85.9523 122.118 86.3035C122.321 86.6546 122.77 86.7749 123.121 86.5721Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M135.69 104.205C135.914 104.076 135.99 103.79 135.861 103.567C135.732 103.344 135.447 103.267 135.223 103.396C135 103.525 134.923 103.811 135.052 104.034C135.181 104.258 135.467 104.334 135.69 104.205Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M133.166 100.5C133.485 100.315 133.594 99.9073 133.41 99.5881C133.226 99.2689 132.818 99.1595 132.498 99.3438C132.179 99.5281 132.07 99.9362 132.254 100.255C132.438 100.575 132.846 100.684 133.166 100.5Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M128.437 107.391C128.756 107.207 128.865 106.799 128.681 106.479C128.497 106.16 128.089 106.051 127.77 106.235C127.45 106.419 127.341 106.828 127.525 107.147C127.71 107.466 128.118 107.575 128.437 107.391Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M127.933 103.983C128.157 103.854 128.233 103.568 128.104 103.345C127.975 103.121 127.689 103.045 127.466 103.174C127.243 103.303 127.166 103.589 127.295 103.812C127.424 104.035 127.71 104.112 127.933 103.983Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M125.786 107.071C125.945 106.979 126 106.775 125.908 106.616C125.816 106.457 125.612 106.402 125.453 106.494C125.293 106.586 125.238 106.79 125.33 106.949C125.423 107.109 125.626 107.163 125.786 107.071Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M123.8 103.364C124.024 103.235 124.1 102.949 123.971 102.726C123.842 102.503 123.556 102.426 123.333 102.555C123.11 102.684 123.033 102.97 123.162 103.193C123.291 103.417 123.577 103.493 123.8 103.364Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M122.156 105.855C122.475 105.67 122.584 105.262 122.4 104.943C122.216 104.624 121.808 104.515 121.488 104.699C121.169 104.883 121.06 105.291 121.244 105.61C121.428 105.93 121.836 106.039 122.156 105.855Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M119.482 107.63C119.929 107.372 120.082 106.8 119.824 106.353C119.566 105.906 118.994 105.753 118.547 106.011C118.101 106.269 117.947 106.841 118.205 107.288C118.463 107.734 119.035 107.888 119.482 107.63Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M118.605 98.5036C118.924 98.3193 119.034 97.9112 118.849 97.592C118.665 97.2728 118.257 97.1634 117.938 97.3477C117.619 97.532 117.509 97.9401 117.694 98.2593C117.878 98.5785 118.286 98.6879 118.605 98.5036Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M116.788 103.097C117.203 102.858 117.345 102.327 117.106 101.912C116.866 101.497 116.335 101.355 115.921 101.595C115.506 101.834 115.363 102.365 115.603 102.78C115.843 103.195 116.373 103.337 116.788 103.097Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.265 105.671C113.648 105.45 113.779 104.96 113.558 104.577C113.337 104.194 112.847 104.063 112.464 104.284C112.081 104.505 111.95 104.995 112.171 105.378C112.392 105.761 112.882 105.892 113.265 105.671Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.434 101.026C113.625 100.915 113.691 100.671 113.58 100.479C113.47 100.288 113.225 100.222 113.034 100.333C112.843 100.443 112.777 100.688 112.888 100.879C112.998 101.071 113.243 101.136 113.434 101.026Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M112.783 96.1616C112.975 96.0511 113.04 95.8064 112.93 95.6151C112.819 95.4238 112.575 95.3582 112.383 95.4687C112.192 95.5792 112.126 95.8238 112.237 96.0151C112.347 96.2065 112.592 96.272 112.783 96.1616Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M108.839 100.674C109.19 100.471 109.311 100.023 109.108 99.6714C108.905 99.3203 108.456 99.2 108.105 99.4027C107.754 99.6055 107.634 100.054 107.837 100.406C108.039 100.757 108.488 100.877 108.839 100.674Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M105.255 102.743C105.415 102.651 105.469 102.447 105.377 102.287C105.285 102.128 105.081 102.073 104.922 102.165C104.763 102.257 104.708 102.461 104.8 102.621C104.892 102.78 105.096 102.835 105.255 102.743Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M106.13 98.3853C106.513 98.1642 106.645 97.6744 106.423 97.2914C106.202 96.9084 105.713 96.7771 105.329 96.9983C104.946 97.2194 104.815 97.7092 105.036 98.0922C105.258 98.4752 105.747 98.6065 106.13 98.3853Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M104.408 92.5987C104.631 92.4697 104.708 92.184 104.579 91.9606C104.45 91.7372 104.164 91.6606 103.941 91.7896C103.717 91.9186 103.641 92.2043 103.77 92.4278C103.899 92.6512 104.184 92.7277 104.408 92.5987Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M109.695 90.5468C109.887 90.4364 109.952 90.1917 109.842 90.0004C109.731 89.809 109.487 89.7435 109.295 89.8539C109.104 89.9644 109.039 90.2091 109.149 90.4004C109.259 90.5917 109.504 90.6573 109.695 90.5468Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M110.388 86.1406C110.835 85.8826 110.988 85.3112 110.73 84.8643C110.472 84.4175 109.901 84.2644 109.454 84.5224C109.007 84.7804 108.854 85.3518 109.112 85.7986C109.37 86.2455 109.942 86.3986 110.388 86.1406Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M113.789 89.4943C114.14 89.2915 114.26 88.8426 114.058 88.4915C113.855 88.1404 113.406 88.0201 113.055 88.2228C112.704 88.4255 112.584 88.8745 112.786 89.2256C112.989 89.5767 113.438 89.697 113.789 89.4943Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M114.077 85.3212C114.396 85.1369 114.505 84.7288 114.321 84.4096C114.137 84.0904 113.728 83.9811 113.409 84.1654C113.09 84.3496 112.981 84.7578 113.165 85.077C113.349 85.3961 113.757 85.5055 114.077 85.3212Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M115.646 84.5692C116.029 84.348 116.16 83.8583 115.939 83.4752C115.718 83.0922 115.228 82.961 114.845 83.1821C114.462 83.4033 114.331 83.893 114.552 84.276C114.773 84.6591 115.263 84.7903 115.646 84.5692Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M117.995 82.3648C118.154 82.2728 118.209 82.0689 118.117 81.9095C118.025 81.75 117.821 81.6954 117.661 81.7874C117.502 81.8795 117.447 82.0834 117.539 82.2428C117.631 82.4022 117.835 82.4569 117.995 82.3648Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M121.542 85.1721C121.989 84.9141 122.142 84.3427 121.884 83.8958C121.626 83.449 121.055 83.2959 120.608 83.5539C120.161 83.8119 120.008 84.3832 120.266 84.8301C120.524 85.277 121.095 85.4301 121.542 85.1721Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.4264 102.18C36.6498 102.051 36.7264 101.766 36.5974 101.542C36.4684 101.319 36.1827 101.242 35.9592 101.371C35.7358 101.5 35.6593 101.786 35.7883 102.009C35.9173 102.233 36.203 102.309 36.4264 102.18Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.3039 103.293C39.655 103.091 39.7753 102.642 39.5726 102.291C39.3699 101.939 38.9209 101.819 38.5698 102.022C38.2187 102.225 38.0984 102.674 38.3011 103.025C38.5038 103.376 38.9528 103.496 39.3039 103.293Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.1989 106.858C36.55 106.655 36.6703 106.206 36.4676 105.855C36.2649 105.504 35.8159 105.384 35.4648 105.587C35.1137 105.789 34.9934 106.238 35.1961 106.589C35.3988 106.94 35.8478 107.061 36.1989 106.858Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.7087 107.605C38.0278 107.42 38.1372 107.012 37.9529 106.693C37.7686 106.374 37.3605 106.265 37.0413 106.449C36.7221 106.633 36.6128 107.041 36.7971 107.36C36.9813 107.68 37.3895 107.789 37.7087 107.605Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.1769 110.148C39.56 109.926 39.6912 109.437 39.4701 109.054C39.2489 108.671 38.7592 108.539 38.3761 108.76C37.9931 108.982 37.8619 109.471 38.083 109.854C38.3042 110.237 38.7939 110.369 39.1769 110.148Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.4003 113.753C34.8153 113.513 34.9575 112.983 34.7179 112.568C34.4783 112.153 33.9477 112.011 33.5328 112.25C33.1179 112.49 32.9757 113.02 33.2153 113.435C33.4548 113.85 33.9854 113.993 34.4003 113.753Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.3332 114.294C37.7481 114.055 37.8903 113.524 37.6508 113.109C37.4112 112.694 36.8806 112.552 36.4657 112.792C36.0507 113.031 35.9086 113.562 36.1481 113.977C36.3877 114.392 36.9183 114.534 37.3332 114.294Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.1906 116.456C35.5098 116.272 35.6191 115.864 35.4349 115.545C35.2506 115.225 34.8424 115.116 34.5232 115.3C34.2041 115.485 34.0947 115.893 34.279 116.212C34.4633 116.531 34.8714 116.641 35.1906 116.456Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.9239 115.843C41.1793 115.695 41.2668 115.369 41.1194 115.114C40.9719 114.858 40.6454 114.771 40.3901 114.918C40.1347 115.066 40.0472 115.392 40.1947 115.647C40.3421 115.903 40.6686 115.99 40.9239 115.843Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.0194 117.601C44.3067 117.435 44.4051 117.067 44.2393 116.78C44.0734 116.493 43.7061 116.394 43.4188 116.56C43.1316 116.726 43.0331 117.093 43.199 117.381C43.3648 117.668 43.7322 117.766 44.0194 117.601Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.858 122.545C38.1134 122.397 38.2009 122.071 38.0534 121.815C37.906 121.56 37.5795 121.473 37.3242 121.62C37.0688 121.767 36.9813 122.094 37.1288 122.349C37.2762 122.605 37.6027 122.692 37.858 122.545Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.1559 122.915C42.3793 122.786 42.4559 122.5 42.3269 122.277C42.1979 122.053 41.9122 121.977 41.6887 122.106C41.4653 122.235 41.3888 122.52 41.5177 122.744C41.6467 122.967 41.9324 123.044 42.1559 122.915Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.3583 123.799C42.6136 123.652 42.7011 123.325 42.5537 123.07C42.4063 122.815 42.0798 122.727 41.8244 122.875C41.5691 123.022 41.4816 123.349 41.629 123.604C41.7764 123.859 42.1029 123.947 42.3583 123.799Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.8503 126.79C42.2652 126.55 42.4074 126.02 42.1678 125.605C41.9283 125.19 41.3977 125.048 40.9828 125.287C40.5678 125.527 40.4257 126.057 40.6652 126.472C40.9048 126.887 41.4354 127.029 41.8503 126.79Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.3823 126.368C45.5418 126.276 45.5964 126.072 45.5043 125.913C45.4123 125.753 45.2084 125.699 45.049 125.791C44.8895 125.883 44.8349 126.087 44.9269 126.246C45.019 126.406 45.2229 126.46 45.3823 126.368Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.3685 130.615C45.6558 130.449 45.7542 130.082 45.5884 129.795C45.4225 129.507 45.0552 129.409 44.7679 129.575C44.4807 129.741 44.3823 130.108 44.5481 130.395C44.714 130.682 45.0813 130.781 45.3685 130.615Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.8058 131.096C49.0292 130.967 49.1058 130.682 48.9768 130.458C48.8478 130.235 48.5621 130.158 48.3386 130.287C48.1152 130.416 48.0387 130.702 48.1677 130.925C48.2966 131.149 48.5823 131.225 48.8058 131.096Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M52.8156 130.167C53.0069 130.057 53.0724 129.812 52.962 129.621C52.8515 129.43 52.6069 129.364 52.4155 129.475C52.2242 129.585 52.1586 129.83 52.2691 130.021C52.3796 130.212 52.6242 130.278 52.8156 130.167Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.1173 130.149C55.3726 130.002 55.4601 129.675 55.3127 129.42C55.1653 129.165 54.8388 129.077 54.5834 129.225C54.3281 129.372 54.2406 129.698 54.388 129.954C54.5354 130.209 54.862 130.297 55.1173 130.149Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.1093 131.734C56.3646 131.587 56.4521 131.26 56.3047 131.005C56.1572 130.75 55.8307 130.662 55.5754 130.809C55.32 130.957 55.2326 131.283 55.38 131.539C55.5274 131.794 55.8539 131.882 56.1093 131.734Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.3085 137.433C51.4998 137.323 51.5654 137.078 51.4549 136.887C51.3444 136.696 51.0998 136.63 50.9084 136.74C50.7171 136.851 50.6516 137.096 50.762 137.287C50.8725 137.478 51.1171 137.544 51.3085 137.433Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M51.8525 140.511C52.2036 140.308 52.3239 139.859 52.1212 139.508C51.9185 139.157 51.4695 139.036 51.1184 139.239C50.7673 139.442 50.647 139.891 50.8497 140.242C51.0524 140.593 51.5014 140.713 51.8525 140.511Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.2397 137.286C60.6546 137.047 60.7968 136.516 60.5573 136.101C60.3177 135.686 59.7871 135.544 59.3722 135.784C58.9572 136.023 58.8151 136.554 59.0546 136.969C59.2942 137.384 59.8248 137.526 60.2397 137.286Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M57.8986 139.639C58.0899 139.528 58.1555 139.284 58.045 139.092C57.9345 138.901 57.6899 138.836 57.4985 138.946C57.3072 139.056 57.2416 139.301 57.3521 139.492C57.4626 139.684 57.7072 139.749 57.8986 139.639Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.8251 140.839C60.0804 140.692 60.1679 140.365 60.0205 140.11C59.8731 139.855 59.5466 139.767 59.2912 139.915C59.0359 140.062 58.9484 140.389 59.0958 140.644C59.2432 140.899 59.5697 140.987 59.8251 140.839Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M63.7579 141.112C64.0133 140.964 64.1008 140.638 63.9534 140.382C63.8059 140.127 63.4794 140.039 63.2241 140.187C62.9687 140.334 62.8812 140.661 63.0287 140.916C63.1761 141.172 63.5026 141.259 63.7579 141.112Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.9096 139.852C38.1009 139.742 38.1664 139.497 38.056 139.306C37.9455 139.114 37.7009 139.049 37.5095 139.159C37.3182 139.27 37.2526 139.514 37.3631 139.706C37.4736 139.897 37.7182 139.963 37.9096 139.852Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.4787 139.909C36.7979 139.725 36.9072 139.316 36.7229 138.997C36.5387 138.678 36.1305 138.569 35.8113 138.753C35.4921 138.937 35.3828 139.345 35.5671 139.665C35.7514 139.984 36.1595 140.093 36.4787 139.909Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.1259 134.487C38.3172 134.377 38.3827 134.132 38.2723 133.941C38.1618 133.75 37.9172 133.684 37.7258 133.795C37.5345 133.905 37.4689 134.15 37.5794 134.341C37.6899 134.532 37.9345 134.598 38.1259 134.487Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.9451 133.243C37.1045 133.151 37.1591 132.947 37.0671 132.788C36.975 132.628 36.7711 132.574 36.6117 132.666C36.4523 132.758 36.3976 132.962 36.4897 133.121C36.5817 133.28 36.7856 133.335 36.9451 133.243Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.2277 135.889C30.483 135.742 30.5705 135.415 30.4231 135.16C30.2756 134.904 29.9491 134.817 29.6938 134.964C29.4384 135.112 29.351 135.438 29.4984 135.694C29.6458 135.949 29.9723 136.036 30.2277 135.889Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.9873 128.787C34.4341 128.529 34.5872 127.957 34.3292 127.511C34.0713 127.064 33.4999 126.911 33.053 127.169C32.6061 127.427 32.453 127.998 32.711 128.445C32.969 128.892 33.5404 129.045 33.9873 128.787Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.6571 127.82C31.9125 127.673 31.9999 127.346 31.8525 127.091C31.7051 126.836 31.3786 126.748 31.1232 126.896C30.8679 127.043 30.7804 127.37 30.9278 127.625C31.0753 127.88 31.4018 127.968 31.6571 127.82Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.9179 125.473C31.3329 125.233 31.475 124.703 31.2355 124.288C30.9959 123.873 30.4653 123.731 30.0504 123.97C29.6354 124.21 29.4933 124.74 29.7328 125.155C29.9724 125.57 30.503 125.712 30.9179 125.473Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.2736 127.845C24.6247 127.642 24.745 127.193 24.5423 126.842C24.3396 126.491 23.8906 126.371 23.5395 126.574C23.1884 126.776 23.0681 127.225 23.2708 127.576C23.4736 127.928 23.9225 128.048 24.2736 127.845Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.4761 120.716C26.6355 120.624 26.6901 120.42 26.5981 120.261C26.506 120.101 26.3021 120.047 26.1427 120.139C25.9833 120.231 25.9286 120.435 26.0207 120.594C26.1127 120.754 26.3166 120.808 26.4761 120.716Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.5177 119.34C19.805 119.174 19.9034 118.807 19.7376 118.52C19.5717 118.233 19.2044 118.134 18.9171 118.3C18.6298 118.466 18.5314 118.833 18.6973 119.12C18.8631 119.408 19.2305 119.506 19.5177 119.34Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.548 116.436C26.7393 116.326 26.8049 116.081 26.6944 115.89C26.5839 115.699 26.3393 115.633 26.1479 115.744C25.9566 115.854 25.8911 116.099 26.0015 116.29C26.112 116.481 26.3566 116.547 26.548 116.436Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.4003 115.67C18.7514 115.468 18.8717 115.019 18.669 114.668C18.4663 114.316 18.0173 114.196 17.6662 114.399C17.3151 114.602 17.1948 115.05 17.3976 115.402C17.6003 115.753 18.0492 115.873 18.4003 115.67Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.1059 112.758C18.3931 112.592 18.4915 112.225 18.3257 111.937C18.1598 111.65 17.7925 111.552 17.5052 111.718C17.218 111.883 17.1196 112.251 17.2854 112.538C17.4513 112.825 17.8186 112.924 18.1059 112.758Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.1418 110.93C25.5887 110.672 25.7418 110.101 25.4838 109.654C25.2258 109.207 24.6544 109.054 24.2075 109.312C23.7607 109.57 23.6076 110.142 23.8656 110.588C24.1236 111.035 24.695 111.188 25.1418 110.93Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.9557 109.148C22.1791 109.019 22.2557 108.734 22.1267 108.51C21.9977 108.287 21.712 108.21 21.4885 108.339C21.2651 108.468 21.1886 108.754 21.3176 108.977C21.4466 109.201 21.7322 109.277 21.9557 109.148Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.1967 108.237C20.452 108.09 20.5395 107.763 20.3921 107.508C20.2446 107.253 19.9181 107.165 19.6628 107.313C19.4074 107.46 19.32 107.787 19.4674 108.042C19.6148 108.297 19.9413 108.385 20.1967 108.237Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.7619 105.871C24.113 105.668 24.2333 105.219 24.0306 104.868C23.8279 104.517 23.3789 104.397 23.0278 104.599C22.6767 104.802 22.5564 105.251 22.7591 105.602C22.9618 105.953 23.4108 106.073 23.7619 105.871Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.7077 108.413C34.9949 108.247 35.0933 107.88 34.9275 107.592C34.7616 107.305 34.3943 107.207 34.1071 107.373C33.8198 107.538 33.7214 107.906 33.8872 108.193C34.0531 108.48 34.4204 108.579 34.7077 108.413Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.4676 110.939C32.6911 110.81 32.7676 110.524 32.6386 110.301C32.5096 110.078 32.2239 110.001 32.0005 110.13C31.7771 110.259 31.7005 110.545 31.8295 110.768C31.9585 110.992 32.2442 111.068 32.4676 110.939Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.4684 110.519C39.7237 110.371 39.8112 110.045 39.6638 109.79C39.5164 109.534 39.1899 109.447 38.9345 109.594C38.6792 109.742 38.5917 110.068 38.7391 110.323C38.8865 110.579 39.213 110.666 39.4684 110.519Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.3987 112.407C37.5581 112.315 37.6127 112.111 37.5207 111.951C37.4286 111.792 37.2247 111.737 37.0653 111.829C36.9059 111.921 36.8512 112.125 36.9433 112.285C37.0353 112.444 37.2392 112.499 37.3987 112.407Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.2224 115.438C37.6373 115.198 37.7795 114.667 37.5399 114.252C37.3004 113.837 36.7698 113.695 36.3548 113.935C35.9399 114.174 35.7977 114.705 36.0373 115.12C36.2769 115.535 36.8074 115.677 37.2224 115.438Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.061 115.686C39.4121 115.483 39.5324 115.034 39.3297 114.683C39.1269 114.332 38.678 114.212 38.3269 114.414C37.9758 114.617 37.8555 115.066 38.0582 115.417C38.2609 115.768 38.7099 115.889 39.061 115.686Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.6913 114.9C43.0105 114.716 43.1199 114.307 42.9356 113.988C42.7513 113.669 42.3432 113.56 42.024 113.744C41.7048 113.928 41.5954 114.336 41.7797 114.656C41.964 114.975 42.3721 115.084 42.6913 114.9Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.8923 119.404C37.2434 119.201 37.3637 118.752 37.161 118.401C36.9582 118.05 36.5093 117.93 36.1582 118.132C35.8071 118.335 35.6868 118.784 35.8895 119.135C36.0922 119.486 36.5412 119.606 36.8923 119.404Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.4972 121.789C36.7525 121.642 36.84 121.315 36.6926 121.06C36.5452 120.805 36.2187 120.717 35.9633 120.865C35.708 121.012 35.6205 121.339 35.7679 121.594C35.9153 121.849 36.2419 121.937 36.4972 121.789Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.7829 122.011C39.0701 121.845 39.1685 121.478 39.0027 121.191C38.8368 120.903 38.4695 120.805 38.1822 120.971C37.895 121.137 37.7966 121.504 37.9624 121.791C38.1283 122.078 38.4956 122.177 38.7829 122.011Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.1854 121.089C45.4727 120.923 45.5711 120.555 45.4053 120.268C45.2394 119.981 44.8721 119.882 44.5848 120.048C44.2976 120.214 44.1991 120.582 44.365 120.869C44.5309 121.156 44.8982 121.254 45.1854 121.089Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.8722 126.161C42.1914 125.977 42.3008 125.568 42.1165 125.249C41.9322 124.93 41.5241 124.821 41.2049 125.005C40.8857 125.189 40.7763 125.597 40.9606 125.917C41.1449 126.236 41.553 126.345 41.8722 126.161Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.0886 128.271C42.4716 128.049 42.6028 127.56 42.3817 127.177C42.1606 126.794 41.6708 126.662 41.2878 126.884C40.9047 127.105 40.7735 127.594 40.9946 127.977C41.2158 128.36 41.7055 128.492 42.0886 128.271Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.0048 129.591C43.4198 129.351 43.562 128.821 43.3224 128.406C43.0828 127.991 42.5522 127.849 42.1373 128.088C41.7224 128.328 41.5802 128.858 41.8198 129.273C42.0593 129.688 42.5899 129.83 43.0048 129.591Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M47.613 129.164C47.7725 129.072 47.8271 128.868 47.735 128.709C47.643 128.55 47.4391 128.495 47.2797 128.587C47.1202 128.679 47.0656 128.883 47.1576 129.042C47.2497 129.202 47.4536 129.256 47.613 129.164Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.0538 134.611C44.2772 134.482 44.3538 134.196 44.2248 133.973C44.0958 133.749 43.8101 133.673 43.5867 133.802C43.3633 133.931 43.2867 134.216 43.4157 134.44C43.5447 134.663 43.8304 134.74 44.0538 134.611Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M48.3866 133.573C48.6739 133.407 48.7723 133.04 48.6065 132.753C48.4406 132.466 48.0733 132.367 47.786 132.533C47.4987 132.699 47.4003 133.066 47.5662 133.353C47.732 133.641 48.0994 133.739 48.3866 133.573Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.0544 138.649C33.4055 138.447 33.5258 137.998 33.3231 137.647C33.1204 137.295 32.6714 137.175 32.3203 137.378C31.9692 137.581 31.8489 138.03 32.0516 138.381C32.2543 138.732 32.7033 138.852 33.0544 138.649Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.8688 136.328C32.0922 136.199 32.1687 135.914 32.0397 135.69C31.9108 135.467 31.6251 135.39 31.4016 135.519C31.1782 135.648 31.1016 135.934 31.2306 136.157C31.3596 136.381 31.6453 136.457 31.8688 136.328Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.5981 136.405C27.9172 136.221 28.0266 135.813 27.8423 135.494C27.658 135.175 27.2499 135.065 26.9307 135.25C26.6115 135.434 26.5022 135.842 26.6865 136.161C26.8707 136.48 27.2789 136.59 27.5981 136.405Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.9244 134.712C25.1158 134.601 25.1813 134.357 25.0709 134.165C24.9604 133.974 24.7157 133.909 24.5244 134.019C24.3331 134.129 24.2675 134.374 24.378 134.565C24.4885 134.757 24.7331 134.822 24.9244 134.712Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.6721 132.276C23.8315 132.184 23.8862 131.98 23.7941 131.82C23.7021 131.661 23.4982 131.606 23.3387 131.698C23.1793 131.79 23.1247 131.994 23.2167 132.154C23.3088 132.313 23.5127 132.368 23.6721 132.276Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.714 128.595C28.9693 128.448 29.0568 128.121 28.9094 127.866C28.762 127.61 28.4355 127.523 28.1801 127.67C27.9248 127.818 27.8373 128.144 27.9847 128.4C28.1321 128.655 28.4586 128.742 28.714 128.595Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.3571 126.912C27.7082 126.71 27.8285 126.261 27.6258 125.91C27.4231 125.559 26.9741 125.438 26.623 125.641C26.2719 125.844 26.1516 126.293 26.3543 126.644C26.557 126.995 27.006 127.115 27.3571 126.912Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.4562 124.694C23.6475 124.584 23.7131 124.339 23.6026 124.148C23.4921 123.956 23.2475 123.891 23.0562 124.001C22.8648 124.112 22.7993 124.357 22.9097 124.548C23.0202 124.739 23.2648 124.805 23.4562 124.694Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.2772 122.254C21.5645 122.089 21.6629 121.721 21.4971 121.434C21.3312 121.147 20.9639 121.048 20.6766 121.214C20.3894 121.38 20.2909 121.747 20.4568 122.035C20.6227 122.322 20.99 122.42 21.2772 122.254Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.8766 119.407C27.1319 119.259 27.2194 118.933 27.072 118.677C26.9246 118.422 26.5981 118.335 26.3427 118.482C26.0874 118.63 25.9999 118.956 26.1473 119.211C26.2947 119.467 26.6212 119.554 26.8766 119.407Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.2894 117.201C22.5128 117.072 22.5894 116.786 22.4604 116.563C22.3314 116.339 22.0457 116.263 21.8223 116.392C21.5988 116.521 21.5223 116.806 21.6513 117.03C21.7803 117.253 22.066 117.33 22.2894 117.201Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.4826 115.545C18.8656 115.324 18.9969 114.835 18.7757 114.452C18.5546 114.069 18.0648 113.937 17.6818 114.158C17.2988 114.38 17.1675 114.869 17.3887 115.252C17.6098 115.635 18.0996 115.767 18.4826 115.545Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.7956 113.403C24.1467 113.2 24.267 112.751 24.0643 112.4C23.8616 112.049 23.4126 111.929 23.0615 112.131C22.7104 112.334 22.5901 112.783 22.7928 113.134C22.9955 113.485 23.4445 113.606 23.7956 113.403Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.6588 110.512C18.9461 110.346 19.0445 109.979 18.8787 109.692C18.7128 109.404 18.3455 109.306 18.0582 109.472C17.771 109.638 17.6725 110.005 17.8384 110.292C18.0042 110.58 18.3716 110.678 18.6588 110.512Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.3851 108.166C19.6405 108.019 19.728 107.692 19.5805 107.437C19.4331 107.182 19.1066 107.094 18.8513 107.242C18.5959 107.389 18.5084 107.716 18.6559 107.971C18.8033 108.226 19.1298 108.314 19.3851 108.166Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M19.4538 106.817C19.773 106.633 19.8823 106.224 19.698 105.905C19.5138 105.586 19.1056 105.477 18.7864 105.661C18.4672 105.845 18.3579 106.253 18.5422 106.573C18.7264 106.892 19.1346 107.001 19.4538 106.817Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.7001 104.171C20.9554 104.024 21.0429 103.697 20.8955 103.442C20.7481 103.186 20.4216 103.099 20.1662 103.246C19.9109 103.394 19.8234 103.72 19.9708 103.976C20.1182 104.231 20.4447 104.318 20.7001 104.171Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.2665 152.327C45.6176 152.124 45.7379 151.675 45.5352 151.324C45.3325 150.973 44.8836 150.853 44.5325 151.056C44.1814 151.258 44.0611 151.707 44.2638 152.058C44.4665 152.409 44.9154 152.53 45.2665 152.327Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M38.1451 153.741C38.3365 153.63 38.402 153.386 38.2916 153.194C38.1811 153.003 37.9364 152.937 37.7451 153.048C37.5538 153.158 37.4882 153.403 37.5987 153.594C37.7092 153.786 37.9538 153.851 38.1451 153.741Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.9427 152.856C38.1341 152.745 38.1996 152.501 38.0892 152.309C37.9787 152.118 37.7341 152.053 37.5427 152.163C37.3514 152.273 37.2858 152.518 37.3963 152.709C37.5068 152.901 37.7514 152.966 37.9427 152.856Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.258 151.672C36.5452 151.506 36.6437 151.139 36.4778 150.851C36.3119 150.564 35.9446 150.466 35.6573 150.632C35.3701 150.797 35.2717 151.165 35.4375 151.452C35.6034 151.739 35.9707 151.838 36.258 151.672Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M40.177 145.247C40.3364 145.155 40.3911 144.951 40.299 144.792C40.207 144.632 40.0031 144.578 39.8436 144.67C39.6842 144.762 39.6296 144.966 39.7216 145.125C39.8137 145.285 40.0175 145.339 40.177 145.247Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.1824 145.666C37.3418 145.574 37.3964 145.37 37.3044 145.211C37.2123 145.051 37.0084 144.997 36.849 145.089C36.6896 145.181 36.6349 145.385 36.727 145.544C36.819 145.704 37.0229 145.758 37.1824 145.666Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M37.6513 143.007C38.0024 142.805 38.1227 142.356 37.92 142.005C37.7173 141.654 37.2683 141.533 36.9172 141.736C36.5661 141.939 36.4458 142.388 36.6485 142.739C36.8512 143.09 37.3002 143.21 37.6513 143.007Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.7057 143.244C34.9291 143.115 35.0057 142.829 34.8767 142.606C34.7477 142.382 34.462 142.306 34.2385 142.435C34.0151 142.564 33.9386 142.85 34.0676 143.073C34.1965 143.296 34.4822 143.373 34.7057 143.244Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.5617 141.399C32.9767 141.16 33.1188 140.629 32.8793 140.214C32.6397 139.799 32.1091 139.657 31.6942 139.897C31.2792 140.136 31.1371 140.667 31.3766 141.082C31.6162 141.497 32.1468 141.639 32.5617 141.399Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.0665 138.41C31.3219 138.262 31.4094 137.936 31.2619 137.68C31.1145 137.425 30.788 137.338 30.5327 137.485C30.2773 137.632 30.1898 137.959 30.3373 138.214C30.4847 138.47 30.8112 138.557 31.0665 138.41Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.1057 138.886C28.5206 138.647 28.6628 138.116 28.4232 137.701C28.1836 137.286 27.6531 137.144 27.2381 137.384C26.8232 137.623 26.681 138.154 26.9206 138.569C27.1602 138.984 27.6907 139.126 28.1057 138.886Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.515 134.645C32.7704 134.498 32.8579 134.171 32.7104 133.916C32.563 133.661 32.2365 133.573 31.9811 133.721C31.7258 133.868 31.6383 134.195 31.7857 134.45C31.9332 134.705 32.2597 134.793 32.515 134.645Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M29.5607 134.733C29.848 134.567 29.9464 134.2 29.7805 133.912C29.6147 133.625 29.2473 133.527 28.9601 133.693C28.6728 133.858 28.5744 134.226 28.7402 134.513C28.9061 134.8 29.2734 134.899 29.5607 134.733Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M32.3959 130.168C32.6832 130.002 32.7816 129.635 32.6157 129.347C32.4499 129.06 32.0826 128.962 31.7953 129.127C31.508 129.293 31.4096 129.661 31.5755 129.948C31.7413 130.235 32.1086 130.334 32.3959 130.168Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.2592 129.819C25.6742 129.579 25.8163 129.048 25.5768 128.634C25.3372 128.219 24.8066 128.076 24.3917 128.316C23.9768 128.556 23.8346 129.086 24.0742 129.501C24.3137 129.916 24.8443 130.058 25.2592 129.819Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.5442 127.649C23.9591 127.41 24.1013 126.879 23.8617 126.464C23.6221 126.049 23.0915 125.907 22.6766 126.147C22.2617 126.386 22.1195 126.917 22.3591 127.332C22.5986 127.747 23.1292 127.889 23.5442 127.649Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.8566 123.504C29.1758 123.319 29.2852 122.911 29.1009 122.592C28.9166 122.273 28.5084 122.163 28.1893 122.348C27.8701 122.532 27.7607 122.94 27.945 123.259C28.1293 123.579 28.5374 123.688 28.8566 123.504Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M25.1159 121.964C25.3072 121.853 25.3727 121.608 25.2623 121.417C25.1518 121.226 24.9072 121.16 24.7158 121.271C24.5245 121.381 24.4589 121.626 24.5694 121.817C24.6799 122.008 24.9245 122.074 25.1159 121.964Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.3769 122.697C20.6961 122.513 20.8054 122.105 20.6211 121.785C20.4369 121.466 20.0287 121.357 19.7095 121.541C19.3903 121.725 19.281 122.134 19.4653 122.453C19.6495 122.772 20.0577 122.881 20.3769 122.697Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M20.3179 120.727C20.4773 120.635 20.5319 120.431 20.4399 120.272C20.3478 120.112 20.1439 120.057 19.9845 120.149C19.8251 120.242 19.7704 120.445 19.8625 120.605C19.9545 120.764 20.1584 120.819 20.3179 120.727Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.7634 117.47C29.1783 117.23 29.3205 116.7 29.0809 116.285C28.8414 115.87 28.3108 115.728 27.8958 115.967C27.4809 116.207 27.3387 116.737 27.5783 117.152C27.8179 117.567 28.3484 117.709 28.7634 117.47Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.1266 115.985C22.4458 115.8 22.5552 115.392 22.3709 115.073C22.1866 114.754 21.7785 114.644 21.4593 114.829C21.1401 115.013 21.0307 115.421 21.215 115.74C21.3993 116.059 21.8074 116.169 22.1266 115.985Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.1887 112.482C24.5717 112.261 24.7029 111.771 24.4818 111.388C24.2606 111.005 23.7709 110.874 23.3879 111.095C23.0048 111.316 22.8736 111.806 23.0947 112.189C23.3159 112.572 23.8056 112.703 24.1887 112.482Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.6257 110.691C29.0726 110.433 29.2257 109.862 28.9677 109.415C28.7097 108.968 28.1383 108.815 27.6914 109.073C27.2446 109.331 27.0915 109.902 27.3495 110.349C27.6075 110.796 28.1789 110.949 28.6257 110.691Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.2531 109.4C21.6681 109.16 21.8102 108.63 21.5707 108.215C21.3311 107.8 20.8005 107.657 20.3856 107.897C19.9707 108.137 19.8285 108.667 20.068 109.082C20.3076 109.497 20.8382 109.639 21.2531 109.4Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.8139 108.348C29.0692 108.2 29.1567 107.874 29.0093 107.618C28.8618 107.363 28.5353 107.276 28.28 107.423C28.0246 107.57 27.9371 107.897 28.0846 108.152C28.232 108.408 28.5585 108.495 28.8139 108.348Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.7101 106.172C26.9335 106.043 27.0101 105.757 26.8811 105.534C26.7521 105.31 26.4664 105.234 26.2429 105.363C26.0195 105.492 25.943 105.778 26.0719 106.001C26.2009 106.224 26.4866 106.301 26.7101 106.172Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.4431 105.71C26.8899 105.452 27.0431 104.88 26.7851 104.433C26.5271 103.987 25.9557 103.833 25.5088 104.091C25.062 104.349 24.9089 104.921 25.1668 105.368C25.4248 105.815 25.9962 105.968 26.4431 105.71Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.9681 103.825C24.2235 103.678 24.311 103.351 24.1636 103.096C24.0161 102.84 23.6896 102.753 23.4343 102.9C23.1789 103.048 23.0914 103.374 23.2389 103.63C23.3863 103.885 23.7128 103.972 23.9681 103.825Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M28.8944 102.214C29.1817 102.048 29.2801 101.681 29.1143 101.393C28.9484 101.106 28.5811 101.008 28.2938 101.174C28.0066 101.339 27.9081 101.707 28.074 101.994C28.2398 102.281 28.6072 102.38 28.8944 102.214Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.3444 166.819C64.5997 166.671 64.6872 166.345 64.5398 166.089C64.3924 165.834 64.0658 165.747 63.8105 165.894C63.5551 166.041 63.4677 166.368 63.6151 166.623C63.7625 166.879 64.089 166.966 64.3444 166.819Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M62.6159 165.427C62.8072 165.316 62.8727 165.072 62.7623 164.88C62.6518 164.689 62.4072 164.624 62.2158 164.734C62.0245 164.845 61.9589 165.089 62.0694 165.281C62.1799 165.472 62.4245 165.537 62.6159 165.427Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M64.914 160.865C65.2331 160.68 65.3425 160.272 65.1582 159.953C64.974 159.634 64.5658 159.525 64.2466 159.709C63.9275 159.893 63.8181 160.301 64.0024 160.62C64.1866 160.94 64.5948 161.049 64.914 160.865Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M60.87 161.735C61.1254 161.588 61.2128 161.261 61.0654 161.006C60.918 160.751 60.5915 160.663 60.3361 160.81C60.0808 160.958 59.9933 161.284 60.1407 161.54C60.2881 161.795 60.6147 161.883 60.87 161.735Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M59.0964 163.067C59.5113 162.828 59.6535 162.297 59.4139 161.882C59.1744 161.467 58.6438 161.325 58.2289 161.565C57.8139 161.804 57.6717 162.335 57.9113 162.75C58.1509 163.165 58.6815 163.307 59.0964 163.067Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.3923 161.854C56.8392 161.596 56.9923 161.025 56.7343 160.578C56.4763 160.131 55.9049 159.978 55.458 160.236C55.0112 160.494 54.8581 161.066 55.1161 161.512C55.3741 161.959 55.9455 162.112 56.3923 161.854Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M56.6534 159.237C56.8448 159.127 56.9103 158.882 56.7999 158.691C56.6894 158.499 56.4447 158.434 56.2534 158.544C56.0621 158.655 55.9965 158.899 56.107 159.091C56.2175 159.282 56.4621 159.348 56.6534 159.237Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.798 160.694C55.0533 160.547 55.1408 160.22 54.9934 159.965C54.846 159.71 54.5195 159.622 54.2641 159.77C54.0088 159.917 53.9213 160.244 54.0687 160.499C54.2161 160.754 54.5426 160.842 54.798 160.694Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M55.3571 156.057C55.7721 155.817 55.9143 155.287 55.6747 154.872C55.4351 154.457 54.9045 154.314 54.4896 154.554C54.0747 154.794 53.9325 155.324 54.1721 155.739C54.4116 156.154 54.9422 156.296 55.3571 156.057Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M53.9639 153.778C54.1233 153.686 54.1779 153.482 54.0859 153.323C53.9938 153.163 53.7899 153.108 53.6305 153.201C53.4711 153.293 53.4164 153.496 53.5085 153.656C53.6005 153.815 53.8044 153.87 53.9639 153.778Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M54.2407 150.921C54.4002 150.829 54.4548 150.625 54.3627 150.466C54.2707 150.306 54.0668 150.252 53.9074 150.344C53.7479 150.436 53.6933 150.64 53.7853 150.799C53.8774 150.958 54.0813 151.013 54.2407 150.921Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.3922 156.803C49.6156 156.674 49.6922 156.389 49.5632 156.165C49.4342 155.942 49.1485 155.865 48.9251 155.994C48.7016 156.123 48.6251 156.409 48.7541 156.632C48.8831 156.856 49.1688 156.932 49.3922 156.803Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M49.3932 153.335C49.7124 153.151 49.8218 152.743 49.6375 152.424C49.4532 152.104 49.0451 151.995 48.7259 152.179C48.4067 152.364 48.2973 152.772 48.4816 153.091C48.6659 153.41 49.074 153.519 49.3932 153.335Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M46.446 155.037C46.7651 154.853 46.8745 154.444 46.6902 154.125C46.5059 153.806 46.0978 153.697 45.7786 153.881C45.4594 154.065 45.3501 154.473 45.5344 154.793C45.7186 155.112 46.1268 155.221 46.446 155.037Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M45.829 152.234C46.1801 152.031 46.3004 151.582 46.0977 151.231C45.895 150.88 45.4461 150.759 45.095 150.962C44.7439 151.165 44.6236 151.614 44.8263 151.965C45.029 152.316 45.4779 152.436 45.829 152.234Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M43.2104 153.437C43.5934 153.216 43.7246 152.726 43.5035 152.343C43.2824 151.96 42.7926 151.829 42.4096 152.05C42.0266 152.271 41.8953 152.761 42.1165 153.144C42.3376 153.527 42.8274 153.658 43.2104 153.437Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M42.8273 151.038C43.0826 150.891 43.1701 150.564 43.0227 150.309C42.8753 150.054 42.5488 149.966 42.2934 150.114C42.0381 150.261 41.9506 150.588 42.098 150.843C42.2454 151.098 42.5719 151.186 42.8273 151.038Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M41.0114 152.164C41.2986 151.998 41.3971 151.631 41.2312 151.343C41.0654 151.056 40.698 150.958 40.4108 151.124C40.1235 151.289 40.0251 151.657 40.1909 151.944C40.3568 152.231 40.7241 152.33 41.0114 152.164Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M44.5521 144.417C44.7435 144.306 44.809 144.062 44.6985 143.87C44.5881 143.679 44.3434 143.613 44.1521 143.724C43.9608 143.834 43.8952 144.079 44.0057 144.27C44.1161 144.462 44.3608 144.527 44.5521 144.417Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.8048 147.004C40.124 146.82 40.2334 146.412 40.0491 146.092C39.8648 145.773 39.4567 145.664 39.1375 145.848C38.8183 146.032 38.709 146.441 38.8932 146.76C39.0775 147.079 39.4857 147.188 39.8048 147.004Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M39.0365 143.672C39.3238 143.506 39.4222 143.139 39.2564 142.851C39.0905 142.564 38.7232 142.466 38.4359 142.632C38.1486 142.797 38.0502 143.165 38.2161 143.452C38.3819 143.739 38.7492 143.838 39.0365 143.672Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M34.1398 146.268C34.3951 146.12 34.4826 145.794 34.3352 145.539C34.1878 145.283 33.8612 145.196 33.6059 145.343C33.3506 145.491 33.2631 145.817 33.4105 146.072C33.5579 146.328 33.8844 146.415 34.1398 146.268Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M36.8154 143.028C37.2303 142.788 37.3725 142.258 37.1329 141.843C36.8934 141.428 36.3628 141.286 35.9478 141.525C35.5329 141.765 35.3907 142.295 35.6303 142.71C35.8699 143.125 36.4005 143.267 36.8154 143.028Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M35.0583 140.652C35.3774 140.468 35.4868 140.059 35.3025 139.74C35.1182 139.421 34.7101 139.312 34.3909 139.496C34.0717 139.68 33.9624 140.088 34.1467 140.408C34.3309 140.727 34.7391 140.836 35.0583 140.652Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.1039 140.47C33.3273 140.341 33.4039 140.055 33.2749 139.832C33.1459 139.609 32.8602 139.532 32.6367 139.661C32.4133 139.79 32.3368 140.076 32.4657 140.299C32.5947 140.523 32.8804 140.599 33.1039 140.47Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.3293 140.068C30.7123 139.847 30.8435 139.358 30.6224 138.974C30.4013 138.591 29.9115 138.46 29.5285 138.681C29.1455 138.903 29.0142 139.392 29.2354 139.775C29.4565 140.158 29.9463 140.29 30.3293 140.068Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M30.017 136.858C30.4001 136.637 30.5313 136.147 30.3101 135.764C30.089 135.381 29.5992 135.25 29.2162 135.471C28.8332 135.692 28.702 136.182 28.9231 136.565C29.1442 136.948 29.634 137.079 30.017 136.858Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.5863 135.988C26.8736 135.822 26.972 135.455 26.8062 135.167C26.6403 134.88 26.273 134.782 25.9857 134.947C25.6985 135.113 25.6 135.481 25.7659 135.768C25.9317 136.055 26.2991 136.154 26.5863 135.988Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M33.5769 131.412C33.9599 131.191 34.0911 130.702 33.87 130.318C33.6488 129.935 33.1591 129.804 32.776 130.025C32.393 130.247 32.2618 130.736 32.4829 131.119C32.7041 131.502 33.1938 131.634 33.5769 131.412Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.1788 133.68C26.4342 133.533 26.5217 133.206 26.3742 132.951C26.2268 132.696 25.9003 132.608 25.645 132.756C25.3896 132.903 25.3021 133.229 25.4496 133.485C25.597 133.74 25.9235 133.828 26.1788 133.68Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M31.2283 128.145C31.6751 127.887 31.8282 127.316 31.5702 126.869C31.3122 126.422 30.7408 126.269 30.294 126.527C29.8471 126.785 29.694 127.356 29.952 127.803C30.21 128.25 30.7814 128.403 31.2283 128.145Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.8175 127.992C23.1048 127.826 23.2032 127.459 23.0374 127.172C22.8715 126.884 22.5042 126.786 22.2169 126.952C21.9297 127.118 21.8312 127.485 21.9971 127.772C22.1629 128.06 22.5303 128.158 22.8175 127.992Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.4221 125.833C24.8051 125.612 24.9363 125.122 24.7152 124.739C24.494 124.356 24.0043 124.225 23.6213 124.446C23.2382 124.667 23.107 125.157 23.3281 125.54C23.5493 125.923 24.039 126.054 24.4221 125.833Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.2111 121.988C27.4664 121.84 27.5539 121.514 27.4065 121.259C27.2591 121.003 26.9325 120.916 26.6772 121.063C26.4218 121.211 26.3344 121.537 26.4818 121.792C26.6292 122.048 26.9557 122.135 27.2111 121.988Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M21.2878 122.941C21.4473 122.849 21.5019 122.645 21.4098 122.486C21.3178 122.326 21.1139 122.272 20.9545 122.364C20.795 122.456 20.7404 122.66 20.8324 122.819C20.9245 122.978 21.1284 123.033 21.2878 122.941Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M27.8854 119.286C28.0767 119.176 28.1423 118.931 28.0318 118.74C27.9213 118.548 27.6767 118.483 27.4854 118.593C27.294 118.704 27.2285 118.948 27.3389 119.14C27.4494 119.331 27.694 119.397 27.8854 119.286Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.3966 118.449C24.5879 118.338 24.6535 118.094 24.543 117.902C24.4326 117.711 24.1879 117.646 23.9966 117.756C23.8052 117.867 23.7397 118.111 23.8502 118.302C23.9606 118.494 24.2053 118.559 24.3966 118.449Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M26.3678 114.922C26.5591 114.812 26.6247 114.567 26.5142 114.376C26.4038 114.185 26.1591 114.119 25.9678 114.229C25.7764 114.34 25.7109 114.585 25.8213 114.776C25.9318 114.967 26.1765 115.033 26.3678 114.922Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M23.6239 114.04C23.8152 113.93 23.8808 113.685 23.7703 113.494C23.6599 113.303 23.4152 113.237 23.2239 113.348C23.0325 113.458 22.967 113.703 23.0775 113.894C23.1879 114.085 23.4326 114.151 23.6239 114.04Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.8903 112.77C23.3053 112.53 23.4475 111.999 23.2079 111.584C22.9683 111.17 22.4377 111.027 22.0228 111.267C21.6079 111.506 21.4657 112.037 21.7053 112.452C21.9448 112.867 22.4754 113.009 22.8903 112.77Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M24.5302 109.203C24.8174 109.037 24.9159 108.67 24.75 108.382C24.5842 108.095 24.2168 107.997 23.9296 108.163C23.6423 108.328 23.5439 108.696 23.7097 108.983C23.8756 109.27 24.2429 109.369 24.5302 109.203Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M18.7863 107.664C18.9776 107.553 19.0432 107.309 18.9327 107.117C18.8222 106.926 18.5776 106.86 18.3862 106.971C18.1949 107.081 18.1293 107.326 18.2398 107.517C18.3503 107.709 18.5949 107.774 18.7863 107.664Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M17.5839 107.049C17.9031 106.864 18.0124 106.456 17.8282 106.137C17.6439 105.818 17.2357 105.709 16.9166 105.893C16.5974 106.077 16.488 106.485 16.6723 106.804C16.8566 107.124 17.2647 107.233 17.5839 107.049Z" fill={props.bgColor || defaultIconColor}/>
        <path d="M22.4386 104.246C22.7259 104.08 22.8243 103.713 22.6585 103.425C22.4926 103.138 22.1253 103.04 21.838 103.206C21.5507 103.371 21.4523 103.739 21.6182 104.026C21.784 104.313 22.1514 104.412 22.4386 104.246Z" fill={props.bgColor || defaultIconColor}/>
        </g>}
        </svg>
    )
}
