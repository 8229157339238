import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';


import { useFormik } from 'formik';
import * as yup from 'yup';

import { ThemeProvider } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CardSubscription } from '../../components/Card/CardSubscription';
import { TextFieldPassword } from '../../components/TextField/TextFieldPassword';

import { changePassword, getTokenInfos } from '../../api/Authentication.api';

import { ILoginRequest, IResetPassword, ITokenInfos } from '../../models/Authentication.model';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';

import AuthService from '../../service/AuthService';


const passwordMinLength: number = 6;
const passwordMaxLength: number = 100;

const authService = new AuthService();


interface Props {
    step: "activate" | "reset";
}


export const PagePassword = (props: Props) => {

    const { uuid } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [infoAccount, setInfoAccount] = useState<ITokenInfos>();


    useEffect(() => {
        if (!uuid)
            return;

        getTokenInfos(uuid).then(response => {
            setInfoAccount(response);
        })
    }, [uuid])


    const getInitialValues = () => {
        return {
            password1: undefined as string | undefined,
            password2: undefined as string | undefined,
        }
    };

    const validationSchema = yup.object({
        password1: yup.string()
                      .required('Champ obligatoire')
                      .test('length',
                            `Votre mot de passe doit avoir entre ${passwordMinLength} et ${passwordMaxLength} caractères`,
                            value => value !== undefined && value !== null && value.length >= passwordMinLength && value.length <= passwordMaxLength),
        password2: yup.string().required("Champ obligatoire")
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);

            if (!infoAccount || !uuid) {  // Should never occur.
                setLoading(false);
                return;
            }

            if (!values.password1 || !values.password2) {  // Should never occur with the yup validation.
                setLoading(false);
                return;
            }

            if (values.password1 !== values.password2) {
                formik.setFieldError("password2", "Les mots de passe ne sont pas identiques");
                setLoading(false);
                return;
            }

            const toSend: IResetPassword = {
                token: uuid,
                dateBirth: infoAccount.dateBirth,
                numDelegataire: infoAccount.numDelegataire,
                newPassword: values.password1 as string
            }

            changePassword(toSend).then(response => {
                if(response.status === 200) {

                    if (!values.password1) {  // Should never occur with the yup validation.
                        setLoading(false);
                        return;
                    }

                    const toSend: ILoginRequest = {
                        dateBirth: infoAccount.dateBirth,
                        numDelegataire: infoAccount.numDelegataire,
                        password: values.password1
                    };

                    authService.login(toSend)
                    .then(response => {
                        navigate("/adherent");
                        setLoading(false);
                    })
                    .catch(error => {
                        navigate("/login");
                        setLoading(false);
                    });
                }
            })
        }
    })


    return (
        <ThemeProvider theme={theme}>

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    height: '100vh',
                    mx: 'auto'
                }} >

                <Grid
                    container
                    justifyContent="space-around"
                    spacing={3}
                    sx={{
                        height: '100%',
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }}>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: {
                                md: 6,
                                xs: 1
                            }
                        }}>
                        <Button
                            variant='text'
                            color='inherit'
                            sx={{
                                p: 1
                            }}
                            onClick={() => 
                                navigate("/login")
                            }>
                            <Typography variant='caption'>
                                {"< Retour"}
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <form
                            onSubmit={formik.handleSubmit}>
                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={4}>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        textAlign: {
                                            md: 'left',
                                            xs: 'center'
                                        }
                                    }}>
                                    <Typography variant='h1'>
                                        {props.step === "activate" &&
                                        "Choisissez votre mot de passe"
                                        }
                                        {props.step === "reset" &&
                                        "Choisissez votre nouveau mot de passe"
                                        }
                                        </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextFieldPassword
                                        fullWidth
                                        id="password1"
                                        placeholder="Votre mot de passe"
                                        value={formik.values.password1}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password1 && Boolean(formik.errors.password1)} />

                                    {formik.touched.password1 && formik.errors.password1 &&
                                    <FormHelperText
                                        sx={{
                                            ml: 2
                                        }}>
                                        <Typography
                                            variant='caption'
                                            color={colors.red.main}>
                                            {formik.errors.password1}
                                        </Typography>
                                    </FormHelperText>}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <TextFieldPassword
                                        fullWidth
                                        id="password2"
                                        placeholder="Confirmation"
                                        value={formik.values.password2}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password2 && Boolean(formik.errors.password2)} />

                                    {formik.touched.password2 && formik.errors.password2 &&
                                    <FormHelperText
                                        sx={{
                                            ml: 2
                                        }}>
                                        <Typography
                                            variant='caption'
                                            color={colors.red.main}>
                                            {formik.errors.password2}
                                        </Typography>
                                    </FormHelperText>}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        textAlign: 'center'
                                    }}>
                                    <LoadingButton
                                        type='submit'
                                        loading={loading}
                                        loadingIndicator={
                                            <Typography
                                                noWrap
                                                variant="button"
                                                sx={{
                                                    textAlign: 'center',
                                                    color: 'white'
                                                }}>
                                                Initialisation en cours...
                                            </Typography>
                                        }
                                        sx={{
                                            width: {
                                                sm: 'auto',
                                                xs: '100%'
                                            },
                                            px: {
                                                sm: 10,
                                                xs: 0
                                            },
                                            color: 'white',
                                            backgroundColor: colors.black.main,
                                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                            ':hover': {
                                                backgroundColor: 'black'
                                            }
                                        }}>
                                        Valider
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                        
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <CardSubscription />
                    </Grid>

                    {/* Add padding bottom */}
                    <Grid
                        item
                        xs={12}>
                    </Grid>
                </Grid>

            </Box>
        </ThemeProvider>
    );
}
