import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import WarningIcon from '@mui/icons-material/Warning';

import { ErrorComponent } from '../../components/ErrorComponent';
import { HeaderBo } from '../../components/Header/HeaderBo';
import { Loading } from '../../components/Loading';

import { createProduct, getProductsBo } from '../../api/Product.api';

import { IPageProduct, IProduct, ProductType, productTypeOptions } from '../../models/Product.model';

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';


interface IPropsDialog {
    open: boolean,
    handleClose: () => void
}


const initialValues = {
    type: undefined as any,
    name: undefined as undefined | string,
    code: undefined as undefined | string,
    isActive: false as boolean,
}


const DialogProduct = (props: IPropsDialog) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        formik.setValues(initialValues);
    }, [props.open])


    const validationSchema = yup.object({
        type: yup.object().required('Champ obligatoire'),
        name: yup.string().required('Champ obligatoire'),
        code: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: any = {
                type: values.type.value as string,
                name: values.name,
                code: values.code,
                isActive: values.isActive
            }

            createProduct(toSend).then((response: IProduct) => {
                if (response.id) {
                    navigate('/bo/produits/' + response.id)
                }
            });
        }
    })

    return (
        <Dialog
            maxWidth={'md'}
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Produit
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>
                        <Grid
                            item
                            xs={12}>
                            <Autocomplete
                                id="type"
                                value={formik.values.type}
                                options={productTypeOptions}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, value) => formik.setFieldValue("type", value)}
                                onBlur={formik.handleBlur}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="type"
                                        label="Type *" variant="outlined"
                                        error={formik.touched.type && Boolean(formik.errors.type)}
                                        helperText={formik.touched.type && formik.errors.type} />} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="code"
                                fullWidth
                                variant="outlined"
                                name="code"
                                label={"Code *"}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="name"
                                fullWidth
                                variant="outlined"
                                name="name"
                                label={"Nom *"}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name} />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        type="submit"
                        sx={{
                            color: 'white'
                        }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}


export const PageProducts = () => {

    const navigate = useNavigate();

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.PRODUCTS;

    const [products, setProducts] = useState<IProduct[] | undefined | null>(undefined);
    const [productsCount, setProductsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getProductsBo(pageNumber, rowPerPage).then((response: IPageProduct) => {
            setProducts(response.content);
            setProductsCount(response.totalElements);
        })
        .catch((exception) => {
            setProducts(null);
            setProductsCount(0);
        })

    }, [rowPerPage, pageNumber])

    const forceRefresh = () => {
        getProductsBo(pageNumber, rowPerPage).then((response: IPageProduct) => {
            setProducts(response.content);
            setProductsCount(response.totalElements);
        })
        .catch((exception) => {
            setProducts(null);
            setProductsCount(0);
        })
        setOpen(false);
    }

    const handleClickModify = (product: any) => {
        navigate('/bo/produits/' + product.id)
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>
            <ThemeProvider theme={theme}>

                <DialogProduct
                    open={open}
                    handleClose={() => forceRefresh()} />

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        mt: {
                            md: 2,
                            xs: 0
                        }
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }} >
                        <Grid
                            item
                            xs={12} >
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                onClick={() => setOpen(true)}
                                sx={{
                                    color: colors.black.main
                                }}>
                                Ajouter un produit
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Card
                                sx={{
                                    border: 1,
                                    borderColor: colors.grey.secondary,
                                    width: '100%'
                                }}>
                                <TableContainer>
                                    <Table aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Nom</TableCell>
                                                <TableCell align="center">Code</TableCell>
                                                <TableCell align="center">Type</TableCell>
                                                <TableCell align="center">Date de création</TableCell>
                                                <TableCell align="center">Actif</TableCell>
                                                <TableCell
                                                    key={'outil'}
                                                    sx={{
                                                        minWidth: '20px'
                                                    }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {products === undefined ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Chargement des produits en cours...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : products === null ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <WarningIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.red.main
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Une erreur est survenue lors de la récupération des produits
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        sx={{
                                                            mt: 1
                                                        }}>
                                                        Veuillez réessayer ultérieurement
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : products.length === 0 ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <InboxIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.black.secondary
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Aucun produit
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : products.map((_) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{_.name}</TableCell>
                                                        <TableCell>{_.code}</TableCell>
                                                        <TableCell align="center">
                                                            {_.type === ProductType.APPLICATION ?
                                                            <Chip
                                                                color='success'
                                                                label="APPLICATION"
                                                                sx={{
                                                                    color: 'white'
                                                                }} />
                                                            : _.type === ProductType.OBJECT ?
                                                            <Chip
                                                                color='primary'
                                                                label="OBJET"
                                                                sx={{
                                                                    color: 'white'
                                                                }} />
                                                            :
                                                            <Chip
                                                                color='warning'
                                                                label="SERVICE"
                                                                sx={{
                                                                    color: 'white'
                                                                }} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.dateCreated !== undefined && _.dateCreated !== null &&
                                                            new Date(_.dateCreated).getDate().toString().padStart(2, '0')  + "-" + (new Date(_.dateCreated).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(_.dateCreated).getFullYear()}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                width: '20px'
                                                            }}>
                                                            <IconButton
                                                                onClick={() => handleClickModify(_)}>
                                                                <CreateIcon
                                                                    sx={{
                                                                        color: colors.blue.main
                                                                    }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={productsCount}
                                    rowsPerPage={rowPerPage}
                                    page={pageNumber}
                                    onPageChange={(event, newPage) => setPageNumber(newPage)}
                                    onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                                    sx={{
                                        border: 0
                                    }} />
                            </Card>
                        </Grid>
                    </Grid>

                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}
