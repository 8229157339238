import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { PageLoading } from '../../components/Page/PageLoading';
import { PageProduct } from '../../components/Page/PageProduct';

import { getProductByCode } from '../../api/Product.api';

import { IProduct } from '../../models/Product.model';

import { Page } from '../../settings/Page';

import { theme } from '../../static/theme';


export const PageService = () => {

    const paramsUri = useParams<{code: string}>();

    const page: Page = Page.SERVICE;

    const [product, setProduct] = useState<IProduct | undefined>();


    useEffect(() => {

        if (paramsUri.code) {
            getProductByCode(paramsUri.code)
            .then((response) => {
                setProduct(response);
            })
            .catch(exception => setProduct(undefined));  // TODO : Redirect to 404.
        }
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                sx={{
                    flexDirection: "column",
                    minHeight: "100vh"
                }}>
                <Grid
                    item
                    xs={12}>
                    <Header
                        page={page} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    {!product ?
                    <PageLoading />

                    :
                    <PageProduct
                        product={product} />}
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Footer />
                </Grid>
            </Grid>

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider>
    );
}
