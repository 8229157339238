import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FruitIcon } from "../Icon/FruitIcon";
import { LeafIcon } from "../Icon/LeafIcon";

import { colors } from "../../static/colors";


export const CardSubscription = () => {


    const navigate = useNavigate();


    return (
        <Box
            sx={{
                position: 'relative'
            }}>

            <Box
                sx={{
                    zIndex: 'modal',
                    position: 'absolute',
                    bottom: {
                        md: -100,
                        xs: -60
                    },
                    left: {
                        md: -15,
                        xs: -35
                    },
                    width: {
                        md: '160px',
                        xs: '120px'
                    },
                    transform: 'rotate(40deg)'
                }}>
                <FruitIcon
                    color={colors.blue.dark}
                    secondaryColor={colors.green.secondary}
                    bgColor={colors.blue.secondary}
                    width='100%'
                    height='100%'
                    showDetails />
            </Box>

            <Card
                sx={{
                    height: '100%',
                    position: 'relative',
                    textAlign: 'center',
                    ml: {
                        md: 5,
                        xs: 0
                    },
                    backgroundColor: colors.blue.main
                }}>

                <Box
                    sx={{
                        width: {
                            lg: '200px',
                            md: '180px',
                            sm: '140px',
                            xs: '110px'
                        },
                        position: 'absolute',
                        top: -70,
                        right: {
                            md: -60,
                            sm: -50,
                            xs: -20
                        },
                        transform: 'rotate(190deg)'
                    }}>
                    <LeafIcon width='100%' />
                </Box>

                <Box
                    sx={{
                        width: {
                            lg: '200px',
                            md: '180px',
                            sm: '140px',
                            xs: '110px'
                        },
                        position: 'absolute',
                        bottom: -50,
                        right: {
                            md: -60,
                            sm: -50,
                            xs: -30
                        },
                        transform: 'rotate(105deg)'
                    }}>
                    <LeafIcon width='100%' />
                </Box>

                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    sx={{
                        height: '100%',
                        px: {
                            lg: 8,
                            xs: 2
                        },
                        py: {
                            md: 15,
                            xs: 6
                        }
                    }}>

                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant="h2">
                            Vous n'êtes pas encore adhérent ?
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Button
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                px: {
                                    sm: 10,
                                    xs: 4
                                },
                                color: colors.black.main,
                                backgroundColor: 'white',
                                ':hover': {
                                    backgroundColor: 'white'
                                }
                            }}
                            onClick={() => navigate('/pack-digital')} >
                            En savoir + sur le pack
                        </Button>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Button
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                px: {
                                    sm: 10,
                                    xs: 4
                                },
                                color: colors.black.main,
                                backgroundColor: 'white',
                                ':hover': {
                                    backgroundColor: 'white'
                                }
                            }}
                            onClick={() => {}} >
                            Souscrire à la mutuelle
                        </Button>
                    </Grid>

                </Grid>
            </Card>
        </Box>
    )
}
