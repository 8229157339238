import { useState } from "react"

import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"

import { FruitIcon } from "../Icon/FruitIcon"
import { ObjectIllustration } from "../Icon/Illustration/ObjectIllustration"
import { ServiceIllustration } from "../Icon/Illustration/ServiceIllustration"
import { SmartphoneIllustration } from "../Icon/Illustration/SmartphoneIllustration"
import { LogoIcon } from "../Icon/LogoIcon"
import { MenuIcon } from "../Icon/MenuIcon"
import { RadioButton } from "../RadioButton/RadioButton"

import { PageBo } from "../../settings/Page"

import { colors } from "../../static/colors"


interface Props {
    page: PageBo;
}


export const HeaderBo = (props: Props) => {

    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <Box>
            <Grid
                container
                justifyContent="space-between"
                sx={{
                    px: {
                        md: 5,
                        xs: 2
                    },
                    py: 2
                }} >

                <Grid
                    item>
                    <IconButton
                        onClick={() => navigate("/bo")}
                        sx={{
                            p: 0,
                            height: {
                                md: "60px",
                                xs: "40px"
                            }
                        }} >
                        <LogoIcon height='100%' width="100%" />
                    </IconButton>
                </Grid>

                <Grid
                    item >

                    <Box
                        sx={{
                            display: {
                                md: "none",
                                xs: "block"
                            }
                        }}>
                        <IconButton
                            sx={{
                                p: 0,
                                height: "30px",
                                width: "30px"
                            }}
                            onClick={handleOpenNavMenu}>
                            <MenuIcon height='100%' width="100%" selected={Boolean(anchorElNav)}/>
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            PaperProps={{
                                sx: {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    left: '0 !important',
                                    mt: 1,
                                }
                            }} >
                            <MenuItem
                                key="products"
                                selected={props.page === PageBo.PRODUCTS}
                                onClick={() => navigate('/bo/produits')}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={1}>
                                    <Grid
                                        item
                                        xs='auto'>
                                        <SmartphoneIllustration height='20px' width='25px' />
                                    </Grid>
                                    <Grid
                                        item
                                        xs>
                                        <Typography
                                            variant='body1'
                                            fontWeight={700} >
                                            Produits
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            <MenuItem
                                key="offers"
                                selected={props.page === PageBo.OFFERS}
                                onClick={() => navigate('/bo/offres')}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={1}>
                                    <Grid
                                        item
                                        xs='auto'>
                                        <ObjectIllustration color={colors.blue.main} height='25px' width='25px' showDetails />
                                    </Grid>
                                    <Grid
                                        item
                                        xs>
                                        <Typography
                                            variant='body1'
                                            fontWeight={700} >
                                            Offres
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            <MenuItem
                                key="sections"
                                selected={props.page === PageBo.SECTIONS}
                                onClick={() => navigate('/bo/sections')}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={1}>
                                    <Grid
                                        item
                                        xs='auto'>
                                        <FruitIcon color={colors.orange.main} height='25px' width='25px' showDetails />
                                    </Grid>
                                    <Grid
                                        item
                                        xs>
                                        <Typography
                                            variant='body1'
                                            fontWeight={700} >
                                            Sections
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>

                            <MenuItem
                                key="themes"
                                selected={props.page === PageBo.THEMES}
                                onClick={() => navigate('/bo/themes')}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={1}>
                                    <Grid
                                        item
                                        xs='auto'>
                                        <ServiceIllustration color={colors.orange.main} height='25px' width='25px' showDetails />
                                    </Grid>
                                    <Grid
                                        item
                                        xs>
                                        <Typography
                                            variant='body1'
                                            fontWeight={700} >
                                            Thèmes
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={{
                            lg: 5,
                            md: 1
                        }}
                        sx={{
                            display: {
                                md: 'flex',
                                xs: 'none'
                            },
                            height: '100%',
                            pt: 2
                        }} >

                        <Grid
                            item >
                            <RadioButton
                                label="Produits"
                                variant='text'
                                selected={props.page === PageBo.PRODUCTS}
                                onClick={() => navigate('/bo/produits')} />
                        </Grid>

                        <Grid
                            item >
                            <RadioButton
                                label="Offres"
                                variant='text'
                                selected={props.page === PageBo.OFFERS}
                                onClick={() => navigate('/bo/offres')} />
                        </Grid>

                        <Grid
                            item >
                            <RadioButton
                                label="Sections"
                                variant='text'
                                selected={props.page === PageBo.SECTIONS}
                                onClick={() => navigate('/bo/sections')} />
                        </Grid>

                        <Grid
                            item >
                            <RadioButton
                                label="Themes"
                                variant='text'
                                selected={props.page === PageBo.THEMES}
                                onClick={() => navigate('/bo/themes')} />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    )
}
