import { PublicClientApplication } from "@azure/msal-browser";

import { IPageSection, ISection } from "../models/Section.model";

import { msalConfig } from "../settings/authConfig";


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}


export const getSectionsBo = (page: number, pageSize: number): Promise<IPageSection> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/bo/section?page=${page}&pageSize=${pageSize}`,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                }
            })
            .then(_ => _.json());
    });
}


export const getSections = (page: number, pageSize: number, type?: string, themes?: string[]): Promise<IPageSection> => {

    let uri: string = `${apiUrl}/section?page=${page}&pageSize=${pageSize}`;

    if (type)
        uri += `&type=${type}`;

    if (themes && themes.length > 0)
        uri += `&themes=${themes}`

    return fetch(uri,
        {
            method: "GET",
        })
        .then(_ => _.json())
}


export const getSection = (id: number): Promise<ISection> => {

    return fetch(`${apiUrl}/section/${id}`,
        {
            method: "GET",
        })
        .then(_ => _.json())
}


export const createSection = (section: ISection): Promise<ISection> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/section`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(section)
            })
            .then(_ => _.json())
    });
}


export const updateSection = (section: ISection): Promise<ISection> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/section`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(section)
            })
            .then(_ => _.json())
    });
}
