import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TextFieldPhone } from '../../components/TextField/TextFieldPhone';

import { updateUser } from '../../api/User.api';

import { IUser } from '../../models/User.model';

import { colors } from '../../static/colors';


interface Props {
    title: string;
    user: IUser;
    save: (user: IUser) => void;
    goToNextStep: () => void;
}


export const FormPhone = (props: Props) => {

    const getInitialValues = () => {
        return {
            phone: props.user?.phone
        }
    };

    const validationSchema = yup.object({
        phone: yup
            .string()
            .trim()
            .matches(/((\+?)33|32)(\s*\d){9}\s*$/g, 'Format de téléphone non valide')
            .nullable()
            .required("Champ obligatoire")
    });

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            let phone: string | undefined = values.phone;

            if (!phone)
                return;

            phone = phone.replace(/\s/g, "");
            phone = phone.replace(/\+/g, "");

            if (!props.user.id)
                return;

            

            updateUser({...props.user, offer:{id: props.user.offer?.id, code: props.user.offer?.code as string, name: props.user.offer?.name as string}, phone: phone})
            .then((response) => {
                props.save(response);
                props.goToNextStep();
            });
        }
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-around"
                spacing={3}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: {
                            md: 2
                        }
                    }}>
                    <Typography
                        variant='h2'>
                        {props.title}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'left'
                    }}>
                    <Typography
                        variant='body1'>
                        Nous allons vous envoyer un code confidentiel par SMS
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextFieldPhone
                        fullWidth
                        id="phone"
                        name="phone"
                        placeholder="Votre numéro de téléphone"
                        value={formik.values.phone}
                        onChange={(value) => formik.setFieldValue("phone", value)}
                        onBlur={(e) => formik.setFieldTouched("phone")}
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Button
                        type="submit"
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            mt: {
                                md: 4,
                                xs: 2
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Recevoir le code
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
