import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { IUser } from '../../../models/User.model';


interface Props {
    user: IUser;
}


export const FormAccountActivationInfo = (props: Props) => {

    return (
        
        <Grid
            container
            justifyContent="space-between"
            spacing={4}>
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: {
                        md: 'left',
                        xs: 'center'
                    }
                }}>
                <Typography variant='h1'>
                    Activez votre compte
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}>
                <Typography
                    variant='body1'
                    sx={{
                        my: 4
                    }}>
                    Un email vous a été envoyé à votre adresse : <span style={{fontWeight: 700}}>{props.user?.email}</span>
                    <div style={{paddingTop: "8px"}} />
                    Veuillez le consulter pour activer de votre compte marketplace GSMC.
                </Typography>
            </Grid>
        </Grid>
    )
}
