import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"

import { IOffer } from "../../models/Offer.model";
import { IProductOffer } from "../../models/ProductOffer.model";

import { colors } from "../../static/colors"


interface Props {
    offer?: IOffer | IProductOffer;
    color?: string;
}


export const ChipOffer = (props: Props) => {

    const isDisplayed = () => {

        if (props.offer?.isActive === false)
            return false;

        return true;
    }


    return (
        
        <Box
            sx={{
                display: isDisplayed() ? 'block' : 'none',
                mx: 1,
                mt: 1,
                p: 1,
                backgroundColor: props.color || colors.orange.main,
                color: 'white',
                borderRadius: 2
            }}>
            <Typography variant="button" component='div' >Pack digital</Typography>
            
            {props.offer?.description &&
            <Typography variant="caption">{props.offer?.description}</Typography>}
        </Box>
    )
}
