import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { updatePlateformeDownloads } from "../../api/Plateforme.api";

import { getPlateformeTypeInfos, IPlateforme, IPlateformeTypeInfos } from "../../models/Plateforme.model";

import { colors } from "../../static/colors"


interface Props {
    plateforme: IPlateforme;
    label?: string;
    icon?: string;
}


export const CardPlateforme = (props: Props) => {
    
    const navigate = useNavigate();
    const plateformeTypeInfos: IPlateformeTypeInfos | undefined = getPlateformeTypeInfos(props.plateforme.type);


    return (
        <Card
            sx={{
                border: 1,
                borderColor: colors.grey.main,
                boxShadow: "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: "0px 4px 20px 0px #3F8CD860"
                }
            }} >
            <CardActionArea
                sx={{
                    p: 2,
                    height: '100%'
                }}
                //@ts-ignore
                onClick={() => {
                    if (props.plateforme.id)
                        updatePlateformeDownloads(props.plateforme.id);

                    if (window !== null)
                        window.open(props.plateforme.url, '_blank')?.focus();
                }} >
                <Grid
                    container
                    spacing={2}
                    alignItems='center'>
                    <Grid
                        item
                        xs='auto'>
                        
                        {(props.icon || plateformeTypeInfos?.icon) &&
                        <CardMedia
                            component="img"
                            src={props.icon || plateformeTypeInfos?.icon}
                            sx={{
                                borderRadius: 2,
                                height: '40px',
                                maxWidth: '50px'
                            }} />}

                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            textAlign: 'left'
                        }}>
                        <Typography variant='body1'>Disponible sur</Typography>
                        <Typography variant='h3' fontWeight={700}>{props.label || plateformeTypeInfos?.label || "le site"}</Typography>
                    </Grid>

                </Grid>
            </CardActionArea>
      </Card>
    )
}
