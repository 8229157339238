import { IconButton } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import { colors } from "../../static/colors";


interface Props {
    onClick?: () => void;
}


export const ButtonLogout = (props: Props) => {

    return (

        <IconButton
            onClick={() => props.onClick ? props.onClick() : {}}>
            <LogoutIcon sx={{color: colors.black.main}} />
        </IconButton>
    )
}
