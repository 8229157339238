import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { LogoIcon } from "../Icon/LogoIcon";
import { FooterColumn } from "./FooterColumn";
import { FooterSocial } from "./FooterSocial";

import { colors } from "../../static/colors";


export const Footer = () => {

    const navigate = useNavigate();

    return (
        <Grid
            container
            justifyContent='space-between'
            columnSpacing={{
                md: 10,
                xs: 2
            }}
            sx={{
                pt: 2,
                pb: {
                    md: 7,
                    xs: 5
                },
                px: {
                    md: 5,
                    xs: 2
                },
                background: `linear-gradient(white, ${colors.orange.light})`
            }} >

            <Grid
                item
                md={5}
                xs={12} >

                <Grid
                    container
                    justifyContent='space-between'
                    spacing={2}>

                    <Grid
                        item
                        xs={12} >
                        <LogoIcon dark />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'justify'
                        }}>
                        <Typography variant='body1'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>

            <Grid
                item
                md={7}
                xs={12} >
                <Grid
                    container
                    justifyContent='space-between'
                    spacing={{
                        md: 5,
                        xs: 2
                    }}
                    sx={{
                        pt: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        md={4}
                        xs={12} >

                        <FooterColumn
                            title="GSMC"
                            elements={[
                                <Link onClick={() => navigate("/pack-digital")}>Le pack digital</Link>,
                                <Link>La mutuelle GSMC</Link>,
                                <Link>Qui sommes-nous ?</Link>
                            ]} />

                    </Grid>

                    <Grid
                        item
                        md={4}
                        xs={12} >
                        <FooterColumn
                            title="Autres liens"
                            elements={[
                                <Link>Mentions légales</Link>,
                                <Link>Politique de confidentialité</Link>
                            ]} />
                    </Grid>

                    <Grid
                        item
                        md={4}
                        xs={12} >
                        <FooterColumn
                            title="Contact"
                            elements={[
                                <Link>hello@gsmc.com</Link>,
                                <Link>06.00.00.00.00</Link>,
                                <Box sx={{
                                    display: {
                                        md: 'block',
                                        xs: 'none'
                                    }
                                }} >
                                    <FooterSocial />
                                </Box>
                            ]} />
                    </Grid>

                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                display={{
                    md: 'none',
                    xs: 'block'
                }} >
                <FooterSocial />
            </Grid>
        </Grid>
    )
}
