import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { CarouselProductHighlight } from '../Carousel/CarouselProductHighlight';

import { ISection } from '../../models/Section.model';

import { colors } from "../../static/colors"


interface Props {
    section: ISection;
}


export const CardSection = (props: Props) => {


    return (
        <Box
            sx={{
                textAlign: 'center',
                py: 6,
                px: 2,
                border: 1,
                borderRadius: 4,
                borderColor: colors.grey.main
            }}>
            {props.section.products.length > 0 &&
            <Grid
                container
                justifyContent='space-between'
                rowSpacing={3}>
                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='h2'>
                        Zoom sur “{props.section.title}”
                    </Typography>
                </Grid>

                {props.section.description &&
                <Grid
                    item
                    xs={12}>
                    <Typography
                        variant='body1'>
                        {props.section.description}
                    </Typography>
                </Grid>}

                <Grid
                    item
                    xs={12}
                    sx={{
                    }}>
                    <Box>
                        <CarouselProductHighlight products={props.section.products} />
                    </Box>
                </Grid>

                {props.section.uri &&
                <Grid
                    item
                    xs={12}>
                    <Button
                        sx={{
                            px: {
                                sm: 3,
                                xs: 2
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}
                        onClick={() =>  {
                            if (props.section.uri)
                                window.open(props.section.uri);
                        }} >
                        En savoir plus
                    </Button>
                </Grid>}
            </Grid>}
        </Box>
    )
}
