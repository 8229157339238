import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";


export const CardProductSkeleton = () => {


    return (
        <Card
            sx={{
                height: '100%'
            }} >
            <Box>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width='100%'
                    height='120px' />

                <CardContent
                    sx={{
                        px: 0,
                        py: 2
                    }} >

                    <Typography
                        variant="h3"
                        sx={{
                            mb: 1
                        }} >
                        <Skeleton
                            animation="wave"
                            variant="rectangular" />
                    </Typography>

                    <Typography
                        variant="caption"
                        component='div'
                        sx={{
                            mb: 1.5
                        }}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular" />
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex'
                        }} >

                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={15}
                            height={15} />

                        <Typography
                            variant="caption"
                            component='div'
                            sx={{
                                width: '50%',
                                mb: 1.5,
                                pl: 1
                            }} >
                            <Skeleton
                                animation="wave"
                                variant="rectangular" />

                        </Typography>
                    </Box>

                </CardContent>
            </Box>
            
      </Card>
    )
}
