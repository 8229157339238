import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const MeditationIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 31 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5097 20.963C7.41007 20.4561 1.69257 14.7885 1.18042 7.70354C8.28005 8.21048 13.9976 13.8781 14.5097 20.963Z" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        <path d="M15.2124 20.2217C10.5332 14.8487 10.5332 6.83596 15.2124 1.46293C19.8916 6.83596 19.8916 14.8487 15.2124 20.2217Z" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        <path d="M16.0728 20.963C16.585 13.8781 22.3025 8.21048 29.4021 7.70354C28.8899 14.7885 23.1724 20.4561 16.0728 20.963Z" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        </svg>
    )
}
