import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import logoGSMC from '../../static/img/gsmc/logo.png';


export const PageLoading = () => {


    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={2}
            sx={{
                height: "100%"
            }}>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
            <Grid
                item>
                <img
                    src={logoGSMC}
                    loading="lazy"
                    style={{
                        verticalAlign: 'bottom',
                        maxHeight: '50px',
                        // animation: "spin 2s infinite linear"
                    }}/>
            </Grid>
            <Grid
                item>
                <Typography variant="h3">Chargement en cours...</Typography>
            </Grid>
        </Grid>
    );
}
