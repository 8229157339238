import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { CardPackDigitalDiscover } from '../../components/Card/CardPackDigitalDiscover';
import { CarouselProduct } from '../../components/Carousel/CarouselProduct';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { FilterTheme } from '../../components/Filter/FilterTheme';
import { ListProducts } from '../../components/List/ListProducts';
import { PageHeader } from '../../components/Page/PageHeader';
import { TitleSection } from '../../components/Title/TitleSection';

import { ProductType } from '../../models/Product.model';
import { ITheme } from '../../models/Theme.model';

import { getThemeByCode } from '../../api/Theme.api';

import { Page } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme as Theme } from '../../static/theme';


export const PageTheme = () => {

    // URL parameters.
    const paramsUri = useParams<{theme: string}>();
    const navigate = useNavigate();

    const page: Page = Page.THEME;

    const [theme, setTheme] = useState<ITheme | undefined>(undefined);


    useEffect(() => {
        if (!paramsUri.theme)
            return;

        getThemeByCode(paramsUri.theme.toUpperCase())
        .then((response) => {
            setTheme(response);
        })
        .catch(exception => setTheme(undefined));

        window.scrollTo({
            top: 0
        });
    }, [paramsUri.theme])


    return (
        <ThemeProvider theme={Theme}>

            <Header
                page={page} />

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    mx: 'auto',
                    mt: 2
                }} >

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={3}
                    sx={{
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        md
                        xs={12} >
                        <PageHeader
                            page={page}
                            theme={theme} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={
                                <Box
                                    component='span'>
                                    Les innovations préférées
                                    <Box
                                        component='span'
                                        sx={{
                                            display: {
                                                md: 'inline',
                                                xs: 'none'
                                            }
                                        }}>
                                        &nbsp;des adhérents GSMC
                                    </Box>
                                </Box>} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            filterByThemes={paramsUri.theme ? [paramsUri.theme.toUpperCase(), ] : []}
                            showRank />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }}>
                        <Button
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                px: {
                                    sm: 10,
                                    xs: 0
                                },
                                color: 'white',
                                backgroundColor: colors.black.main,
                                boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                ':hover': {
                                    backgroundColor: 'black'
                                }
                            }}
                            onClick={() => navigate("/pack-digital")} >
                            Découvrir le pack digital
                        </Button>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Toutes les applications{theme && ` “${theme.name}”`}</Box>} />
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <ListProducts
                            type={ProductType.APPLICATION}
                            filterByThemes={paramsUri.theme ? [paramsUri.theme.toUpperCase(), ] : []}
                            showTypeLink />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Toutes les objets{theme && ` “${theme.name}”`}</Box>} />
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <ListProducts
                            type={ProductType.OBJECT}
                            filterByThemes={paramsUri.theme ? [paramsUri.theme.toUpperCase(), ] : []}
                            showTypeLink />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Tous les services{theme && ` “${theme.name}”`}</Box>} />
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <ListProducts
                            type={ProductType.SERVICE}
                            filterByThemes={paramsUri.theme ? [paramsUri.theme.toUpperCase(), ] : []}
                            showTypeLink />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center',
                            my: 1,
                            mt: 3
                        }}>
                        <Typography variant='h2'>Retrouvez les autres thématiques santé</Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <FilterTheme
                            themeCode={paramsUri.theme?.toUpperCase()}
                            filterBy={(_) => {
                                if (_ === undefined)
                                    return;

                                navigate(`/theme/${_.toLowerCase()}`);
                            }} />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            my: {
                                md: 5,
                                xs: 3
                            }
                        }}>
                        <CardPackDigitalDiscover />
                    </Grid>
                </Grid>

            </Box>

            <Footer />

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider>
    );
}
