import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const StarIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2115 3L17.9181 11.2918H26.6768L19.5909 16.4164L22.2975 24.7082L15.2115 19.5836L8.12562 24.7082L10.8322 16.4164L3.74628 11.2918H12.505L15.2115 3Z" fill={props.bgColor || ''} stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        </svg>
    )
}
