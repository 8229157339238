import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const MentalHealthIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M10.291 1.875V21" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round" strokeLinecap="round" />
        <path d="M1.20215 1.875C1.80808 15.375 18.7741 15.375 19.3801 1.875" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round" strokeLinecap="round" />
        </svg>
    )
}
