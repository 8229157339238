import { IProductConsumption } from "../models/ProductConsumption.model";
import { ISms } from "../models/Sms.model";

import AuthService from "../service/AuthService"


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'

const authService = new AuthService();


export const getProductConsumptionCount = (id: number): Promise<number> => {

    return fetch(`${apiUrl}/product/${id}/consumption/count`,
        {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
        .then(_ => {
            if(_.status === 200) return _.json();
            else throw _;
        });
}


export const getProductConsumption = (id: number): Promise<IProductConsumption> => {

    return fetch(`${apiUrl}/product/${id}/consumption`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({
                "content-type": "application/json"
            })
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const getUserConsumption = (): Promise<IProductConsumption[]> => {

    return fetch(`${apiUrl}/product/consumption`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({
                "content-type": "application/json"
            })
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const consumeProduct = (id: number): Promise<ISms> => {

    return fetch(`${apiUrl}/product/${id}/consume`,
        {
            method: "POST",
            headers: authService.getRequestHeaders({
                "content-type": "application/json"
            })
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}
