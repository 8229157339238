import { useEffect, useState } from 'react';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import WarningIcon from '@mui/icons-material/Warning';

import { ErrorComponent } from '../../components/ErrorComponent';
import { HeaderBo } from '../../components/Header/HeaderBo';
import { Loading } from '../../components/Loading';

import { createSection, getSectionsBo } from '../../api/Section.api';

import { IPageSection, ISection, sectionTypeOptions } from '../../models/Section.model';

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';
import { useNavigate } from 'react-router-dom';


interface IPropsDialog {
    open: boolean,
    handleClose: () => void
}


const initialValues = {
    type: undefined as any,
    title: undefined as undefined | string,
    dateStart: new Date(),
    isActive: false as boolean,
}


const DialogSection = (props: IPropsDialog) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        formik.setValues(initialValues);
    }, [props.open])


    const validationSchema = yup.object({
        type: yup.object().required('Champ obligatoire'),
        title: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: any = {
                type: values.type.value as string,
                title: values.title,
                dateStart: values.dateStart,
                isActive: values.isActive
            }

            createSection(toSend).then((response: ISection) => {
                if (response.id) {
                    navigate('/bo/sections/' + response.id)
                }
            });
        }
    })

    return (
        <Dialog
            maxWidth='md'
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Section
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>
                        <Grid
                            item
                            xs={12}>
                            <Autocomplete
                                id="type"
                                value={formik.values.type}
                                options={sectionTypeOptions}
                                getOptionLabel={(option) => option.label}
                                onChange={(e, value) => formik.setFieldValue("type", value)}
                                onBlur={formik.handleBlur}
                                fullWidth
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="type"
                                        label="Type *"
                                        error={formik.touched.type && Boolean(formik.errors.type)}
                                        helperText={formik.touched.type && formik.errors.type} />} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="title"
                                fullWidth
                                variant="outlined"
                                name="title"
                                label={"Titre *"}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title} />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        type="submit"
                        sx={{
                            color: 'white'
                        }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}


export const PageSections = () => {

    const navigate = useNavigate();

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.SECTIONS;

    const [sections, setSections] = useState<ISection[] | undefined | null>(undefined);
    const [sectionsCount, setSectionsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getSectionsBo(pageNumber, rowPerPage).then((response: IPageSection) => {
            setSections(response.content);
            setSectionsCount(response.totalElements);
        })
        .catch((exception) => {
            setSections(null);
            setSectionsCount(0);
        })

    }, [rowPerPage, pageNumber])

    const forceRefresh = () => {
        getSectionsBo(pageNumber, rowPerPage).then((response: IPageSection) => {
            setSections(response.content);
            setSectionsCount(response.totalElements);
        })
        .catch((exception) => {
            setSections(null);
            setSectionsCount(0);
        })
        setOpen(false);
    }

    const handleClickModify = (section: any) => {
        navigate('/bo/sections/' + section.id)
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>
            <ThemeProvider theme={theme}>

                <DialogSection
                    open={open}
                    handleClose={() => forceRefresh()} />

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        mt: {
                            md: 2,
                            xs: 0
                        }
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }} >
                        <Grid
                            item
                            xs={12} >
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                onClick={() => setOpen(true)}
                                sx={{
                                    color: colors.black.main
                                }}>
                                Ajouter une section
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Card
                                sx={{
                                    border: 1,
                                    borderColor: colors.grey.secondary,
                                    width: '100%'
                                }}>
                                <TableContainer>
                                    <Table aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Titre</TableCell>
                                                <TableCell align="center">Statut</TableCell>
                                                <TableCell align="center">Nb produits</TableCell>
                                                <TableCell align="center">Date de début</TableCell>
                                                <TableCell align="center">Date de fin</TableCell>
                                                <TableCell align="center">Actif</TableCell>
                                                <TableCell
                                                    key={'outil'}
                                                    sx={{
                                                        width: '20px'
                                                    }}>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sections === undefined ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Chargement des sections en cours...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : sections === null ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <WarningIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.red.main
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Une erreur est survenue lors de la récupération des sections
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        sx={{
                                                            mt: 1
                                                        }}>
                                                        Veuillez réessayer ultérieurement
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : sections.length === 0 ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <InboxIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.black.secondary
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Aucune section
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : sections.map((_) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{_.title}</TableCell>
                                                        <TableCell align="center">
                                                            {!_.isActive ?
                                                            <Chip
                                                                color='error'
                                                                label="DÉSACTIVÉE" />
                                                            :
                                                            _.dateEnd !== undefined && _.dateEnd !== null && new Date(_.dateEnd).getTime() < new Date().getTime() ?
                                                            <Chip
                                                                label="EXPIRÉE" />
                                                            :
                                                            _.dateStart !== undefined && _.dateStart !== null && new Date(_.dateStart).getTime() > new Date().getTime() ?
                                                            <Chip
                                                                label="PROGRAMMÉE"
                                                                sx={{
                                                                    color: 'white',
                                                                    backgroundColor: "#2666CF"
                                                                }} />
                                                            :
                                                            <Chip
                                                                color='success'
                                                                label="EN COURS"
                                                                sx={{
                                                                    color: 'white'
                                                                }} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Chip
                                                                label={_.products.length} />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.dateStart !== undefined && _.dateStart !== null &&
                                                            new Date(_.dateStart).getDate().toString().padStart(2, '0')  + "-" + (new Date(_.dateStart).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(_.dateStart).getFullYear()}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.dateEnd !== undefined && _.dateEnd !== null &&
                                                            new Date(_.dateEnd).getDate().toString().padStart(2, '0')  + "-" + (new Date(_.dateEnd).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(_.dateEnd).getFullYear()}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                onClick={() => handleClickModify(_)}>
                                                                <CreateIcon
                                                                    sx={{
                                                                        color: colors.blue.main
                                                                    }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={sectionsCount}
                                    rowsPerPage={rowPerPage}
                                    page={pageNumber}
                                    onPageChange={(event, newPage) => setPageNumber(newPage)}
                                    onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                                    sx={{
                                        border: 0
                                    }} />
                            </Card>
                        </Grid>
                    </Grid>

                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}
