import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { StarIcon } from "../Icon/Theme/StarIcon";

import { IReview } from "../../models/Review.model";

import { colors } from "../../static/colors"


interface Props {
    review: IReview;
}


export const CardReview = (props: Props) => {


    return (
        <Card
            sx={{
                minHeight: "260px",
                py: 4,
                px: 3,
                boxShadow: "0px 4px 20px 0px #00000028"
            }} >
            <Box
                sx={{
                    overflow: 'hidden',
                    height: '100%'
                }}>
                <Grid
                    container
                    spacing={3}>
                    <Grid
                        item
                        xs={12}>
                        {Array.from({length: Math.round(props.review.note)}).map((_) => {
                            return <StarIcon color={colors.yellow.main} bgColor={colors.yellow.main} selected />;
                        })}
                        {Array.from({length: 5 - Math.round(props.review.note)}).map((_) => {
                            return <StarIcon color={colors.yellow.main} selected />;
                        })}
                    </Grid>

                    <Grid
                        item
                        xs={12}>

                        <Typography
                            variant='body1'
                            fontWeight={700}
                            fontSize={18}>
                            {props.review.user}
                        </Typography>

                        <Typography
                            variant='body1'>
                            Adhérent GSMC
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant='body1'
                            sx={{
                            }}>
                            {/* TODO : Manage overflow with ellipsis */}
                            {`Témoignage : ${props.review.description}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
      </Card>
    )
}
