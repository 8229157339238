import Typography from "@mui/material/Typography"


interface Props {
    label: string;
    fontWeight?: number;
    isRequired?: boolean;
}


export const FieldLabel = (props: Props) => {

    return (
        <Typography
        fontWeight={props.fontWeight}
            component='div'
            variant='body1'
            sx={{
                mb: 1.5
            }} >
            {props.label}
            {props.isRequired === true ?
                <Typography
                    display='inline'
                    variant='body1'
                    color='error' >
                    *
                </Typography>
                :
                null
            }
        </Typography>
    )
}
