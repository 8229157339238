import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from "@mui/material/Grid";

import { Carousel } from "../Carousel/Carousel";
import { ButtonTheme } from "../Button/ButtonTheme";

import { getThemes } from "../../api/Theme.api";
import { getThemeIcon, ITheme } from "../../models/Theme.model";


interface Props {
    themeCode?: string;
    filterBy: (themeCode: string | undefined) => void;
}


const defaultNbItemsMD: number = 3.5;
const defaultNbItemsSM: number = 2.5;
const defaultNbItemsXS: number = 1.6;


export const FilterTheme = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));

    const [mounted, setMounted] = useState<boolean>(false);
    const [selectedTheme, setSelectedTheme] = useState<string | undefined>(props.themeCode);
    const [goToFirst, setGoToFirst] = useState<number>(0);

    const [themes, setThemes] = useState<ITheme[]>([]);


    useEffect(() => {
        setMounted(true);

        getThemes(0, 15)
            .then((response) => {
                setThemes(response.content);
            })
            .catch(exception => setThemes([]));
    }, [])


    useEffect(() => {
        if (mounted)
            props.filterBy(selectedTheme);
    }, [selectedTheme])


    return (
        <>
        {screenSizeUpLG ?
        <Grid
            container
            justifyContent='center'
            alignItems="center"
            spacing={2} >
            {themes.map((_, i) => {
                return (
                    <Grid
                        key={`filter_theme_${i}`}
                        item
                        xs={2.4}>
                        <ButtonTheme
                            label={_.name}
                            icon={getThemeIcon(_.code)}
                            selected={selectedTheme === _.code}
                            onClick={() => setSelectedTheme(_.code)} />
                    </Grid>
                );
            })} 
        </Grid>
        :
        <Carousel
            elements={
                [...themes].sort((a, b) => {
                    const aTypeValue = a.code === selectedTheme ? 10 : 1;
                    const bTypeValue = b.code === selectedTheme ? 10 : 1;
                
                    if (aTypeValue > bTypeValue)
                        return -1;
                
                    if (aTypeValue < bTypeValue)
                        return 1;

                    return 0;
                })
                .map((_, id) => {
                return <ButtonTheme
                    label={_.name}
                    icon={getThemeIcon(_.code)}
                    selected={selectedTheme === _.code}
                    onClick={() => {
                        setSelectedTheme(_.code);
                        setGoToFirst(goToFirst + 1);
                    }} />
                })
            }
            nbItemsMD={defaultNbItemsMD}
            nbItemsSM={defaultNbItemsSM}
            nbItemsXS={defaultNbItemsXS}
            goToFirst={goToFirst} />}
        </>
    )
}
