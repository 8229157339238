import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PageLoginSteps } from '../../../pages/Login/PageLogin';

import { colors } from '../../../static/colors';


interface Props {
    goToStep: (step: PageLoginSteps) => void;
}


export const FormUsernameForgottenInfo = (props: Props) => {

    return (
        
        <Grid
            container
            justifyContent="space-between"
            spacing={4}>
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: {
                        md: 'left',
                        xs: 'center'
                    }
                }}>
                <Typography variant='h1'>
                    Récupération réussie !
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}>
                <Typography
                    variant='body1'
                    sx={{
                        my: 2
                    }}>
                    Votre numéro d'adhérent vous a été envoyé par mail.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                order={{
                    xs: 2,
                    sm: 3
                }}
                sx={{
                    textAlign: 'center'
                }}>
                <Button
                    onClick={() => props.goToStep(PageLoginSteps.USERNAME)}
                    sx={{
                        width: {
                            sm: 'auto',
                            xs: '100%'
                        },
                        px: {
                            sm: 10,
                            xs: 0
                        },
                        color: 'white',
                        backgroundColor: colors.black.main,
                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }} >
                    Continuer
                </Button>
            </Grid>
        </Grid>
    )
}
