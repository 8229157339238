import { ChangeEvent } from 'react';

import MuiPhoneNumber from 'material-ui-phone-number';


interface Props {
    id: string;
    label?: string | JSX.Element;
    name?: string;
    value?: string;
    placeholder?: string;
    error?: boolean;
    helperText?: boolean | string;
    fullWidth?: boolean;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
}


export const TextFieldPhone = (props: Props) => {


    const onChange = (value: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.onChange(value as string);
    }

    
    return (
        <MuiPhoneNumber
            fullWidth
            variant="outlined"
            onlyCountries= {['fr', 'be']}
            defaultCountry= 'fr'
            label={props.label}
            value={props.value}
            name={props.name}
            placeholder={props.placeholder}
            onChange={(value) => onChange(value)}
            onBlur={(e) => props.onBlur(e.target.value)}
            error={props.error}
            helperText={props.helperText} />
    )
}
