
import { defaultIconColor, defaultIconSize, IIcon } from "./../settings"


export const FacebookCircleIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0001 0.0623779C6.71586 0.0623779 0 6.77823 0 15.0625C0 22.4925 5.40783 28.6457 12.4984 29.8373V18.1919H8.87992V14.0012H12.4984V10.9111C12.4984 7.32578 14.6882 5.37197 17.8871 5.37197C19.4191 5.37197 20.7357 5.48613 21.1179 5.53641V9.28406L18.8993 9.28514C17.16 9.28514 16.8247 10.1115 16.8247 11.3245V13.999H20.9748L20.4335 18.1897H16.8247V29.9375C24.2463 29.0342 30 22.7242 30 15.0582C30 6.77823 23.2841 0.0623779 15.0001 0.0623779Z" fill={props.color || defaultIconColor} />
        </svg>
    )
}
