import { PublicClientApplication } from "@azure/msal-browser";
import { IImage } from "../models/Image.model";

import { msalConfig } from "../settings/authConfig";


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}


export const createImage = (image: IImage): Promise<IImage> => { 
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/image`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(image)
            })
            .then(_ => _.json())
    });
}


export const updateImage = (image: IImage): Promise<IImage> => { 
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/image`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(image)
            })
            .then(_ => _.json())
    });
}


export const deleteImage = (id: number): Promise<any> => { 
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/image/${id}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                }
            })
    });
}
