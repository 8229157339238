import { cloneElement } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getThemeIcon, ITheme } from "../../models/Theme.model";

import { colors } from "../../static/colors";


interface Props {
    theme: ITheme;
    color?: string;
}


export const ChipTheme = (props: Props) => {


    const icon: JSX.Element | undefined = getThemeIcon(props.theme.code)


    return (
        <Box
            sx={{
                py: 0.5,
                display: props.theme.isActive === false ? 'none' : 'flex',
                alignItems: 'flex-end'
            }} >
            {icon && <>{cloneElement(icon, {color: props.color || colors.grey.main, height: '15px', width: '15px'})}</>}
            <Typography
                variant='caption'
                color={props.color || colors.grey.main}
                sx={{
                    mb: 0.25
                }}>
                &nbsp;{props.theme.name}
            </Typography>
        </Box>
    )
}
