import { ContextType, MouseEvent, useEffect, useRef, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useDrag } from "./useDrag";
import { CarouselItem } from "./CarouselItem";

import { ArrowLeftIcon } from "../Icon/ArrowLeftIcon";
import { ArrowRightIcon } from "../Icon/ArrowRightIcon";


export interface ISize {
    width: string;
    height: string;
}


interface Props {
    elements: JSX.Element[];
    nbItemsMD: number;
    nbItemsSM: number;
    nbItemsXS: number;
    goToFirst?: number;
    hideButtons?: Boolean;
}


type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;


export const Carousel = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));

    const apiRef = useRef({} as scrollVisibilityApiType);

    const containerRef = useRef<any>(null);
    const itemRef = useRef<any>(null);
    const [itemSize, setItemSize] = useState<ISize>({ width: "240px", height: "100%" });

    const [isFirstItemVisible, setIsFirstItemVisible] = useState<boolean>(true);
    const [isLastItemVisible, setIsLastItemVisible] = useState<boolean>(true);

    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    // const handleDrag = ({scrollContainer}: scrollVisibilityApiType) => (ev: MouseEvent) =>
    //     dragMove(ev, (posDiff) => {
    //         if (scrollContainer.current) {
    //             scrollContainer.current.scrollLeft += posDiff;
    //         }
    //     }
    // );

    const handleIsVisible = (isVisible: boolean, id: number) => {
        if (id === 0)
            setIsFirstItemVisible(isVisible);
        if (props.elements && id === props.elements.length - 1)
            setIsLastItemVisible(isVisible);
    }


    useEffect(() => {
        // Handler to call on window resize.
        function handleResize() {
            if (itemRef.current !== null) {
                setItemSize({
                    width: itemRef.current.offsetWidth.toString() + 'px',
                    height: itemRef.current.offsetHeight.toString() + 'px'
                });
            }
        }

        // Add event listener.
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size.
        handleResize();

        // Remove event listener on cleanup.
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    useEffect(() => {
        if (!props.goToFirst)
            return;

        apiRef.current.scrollToItem(apiRef.current.getItemById("0"))
    }, [props.goToFirst]);


    return (
        <Box
            sx={{
                position: 'relative',
                height: "100%",
                width: "calc(100% + 32px)",
                left: "-16px",
                borderRadius: 2
            }}>
            <Box
                ref={containerRef}
                onMouseLeave={dragStop}
                sx={{
                    position: 'relative'
                }} >
                <ScrollMenu
                    apiRef={apiRef}
                    onMouseDown={() => dragStart}
                    onMouseUp={() => dragStop}
                    // onMouseMove={handleDrag}
                    >
                    {props.elements.map((_, id) => (
                    <CarouselItem
                        key={id}
                        itemId={id.toString()}  // IMPORTANT : itemId is required to track items.
                        width={`calc(${itemSize.width} - ${screenSizeUpMD ? "32px" : "16px"})`}
                        isVisible={(isVisible: boolean) => {
                            handleIsVisible(isVisible, id)
                        }} >
                        {_}
                    </CarouselItem>
                    ))}
                </ScrollMenu>

                {!props.hideButtons && !isFirstItemVisible &&
                <Box
                    sx={{
                        display: {
                            md: 'block',
                            xs: 'none'
                        },
                        zIndex: 'modal',
                        position: 'absolute',
                        top: 0,
                        left: -1,
                        height: "100%",
                        background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
                        ':hover': {
                            boxShadow: "8px 0px 20px -5px #3F8CD860",
                        }
                    }}>
                    <Button
                        sx={{
                            height: '100%',
                            borderRadius: 2
                        }}
                        color='inherit'
                        onClick={() => apiRef.current.scrollPrev()}>
                        <ArrowLeftIcon />
                    </Button>
                </Box>}

                {!props.hideButtons && !isLastItemVisible &&
                <Box
                    sx={{
                        display: {
                            md: 'block',
                            xs: 'none'
                        },
                        zIndex: 'modal',
                        position: 'absolute',
                        top: 0,
                        right: -1,
                        height: "100%",
                        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70%)',
                        ':hover': {
                            boxShadow: "-8px 0px 20px -5px #3F8CD860",
                        }
                    }}>
                    <Button
                        sx={{
                            height: '100%',
                            borderRadius: 2
                        }}
                        color='inherit'
                        onClick={() => apiRef.current.scrollNext()}>
                        <ArrowRightIcon />
                    </Button>
                </Box>}
            </Box>

            <Grid
                container
                sx={{
                    height: '0px'
                }}>
                <Grid
                    ref={itemRef}
                    item
                    md={12 / props.nbItemsMD}
                    sm={12 / props.nbItemsSM}
                    xs={12 / props.nbItemsXS} >
                </Grid>
            </Grid>
        </Box>
    );
}
