
import { defaultIconColor, defaultIconSize, IIcon } from "./settings"


export const PlusIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0293 5V25" stroke={props.color || defaultIconColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.0293 15H6.0293" stroke={props.color || defaultIconColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
