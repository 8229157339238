import { useState } from "react"

import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"

import { ButtonLogout } from "../Button/ButtonLogout"
import { ButtonUser } from "../Button/ButtonUser"
import { ObjectIllustration } from "../Icon/Illustration/ObjectIllustration"
import { ServiceIllustration } from "../Icon/Illustration/ServiceIllustration"
import { SmartphoneIllustration } from "../Icon/Illustration/SmartphoneIllustration"
import { FruitIcon } from "../Icon/FruitIcon"
import { LogoIcon } from "../Icon/LogoIcon"
import { MenuIcon } from "../Icon/MenuIcon"
import { RadioButton } from "../RadioButton/RadioButton"

import AuthService from "../../service/AuthService"

import { Page } from "../../settings/Page"

import { colors } from "../../static/colors"


interface Props {
    page: Page;
}


const authentication = new AuthService();

export const Header = (props: Props) => {

    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const userInfos = authentication.getProfile();

    const handleClickUser = () => {
        if (authentication.isLoggedIn()) navigate("/adherent")
        else navigate("/login")
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLogout = () => {
        authentication.logout();
        navigate("/")
    }

    return (
        <Box>
            <Grid
                container
                justifyContent="space-between"
                alignItems='center'
                sx={{
                    px: {
                        md: 5,
                        xs: 2
                    },
                    py: 2
                }} >

                <Grid
                    item>
                    <IconButton
                        onClick={() => navigate("/")}
                        sx={{
                            p: 0,
                            height: {
                                md: "60px",
                                xs: "40px"
                            }
                        }} >
                        <LogoIcon height='100%' width="100%" />
                    </IconButton>
                </Grid>

                <Grid
                    item >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={{
                            lg: 5,
                            md: 2
                        }} >

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }} >
                            <RadioButton
                                label="Applications"
                                variant='text'
                                selected={props.page === Page.APPLICATION || props.page === Page.APPLICATIONS}
                                onClick={() => navigate('/applications')} />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }} >
                            <RadioButton
                                label="Objets"
                                variant='text'
                                selected={props.page === Page.OBJECT || props.page === Page.OBJECTS}
                                onClick={() => navigate('/objets')} />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }} >
                            <RadioButton
                                label="Services"
                                variant='text'
                                selected={props.page === Page.SERVICE || props.page === Page.SERVICES}
                                onClick={() => navigate('/services')} />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }} >
                            <RadioButton
                                label="Pack digital"
                                variant='text'
                                selected={props.page === Page.PACK_DIGITAL}
                                onClick={() => navigate('/pack-digital')} />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }}>
                            <ButtonUser onClick={() => handleClickUser()} user={userInfos} />
                        </Grid>

                        {authentication.isLoggedIn() &&
                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "block",
                                    xs: "none"
                                }
                            }}>
                            <ButtonLogout onClick={() => handleLogout()}/>
                        </Grid>}

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "none",
                                    xs: "block"
                                }
                            }}>
                            <IconButton
                                sx={{
                                    p: 0,
                                    height: "30px",
                                    width: "30px"
                                }}
                                onClick={handleOpenNavMenu}>
                                <MenuIcon height='100%' width="100%" selected={Boolean(anchorElNav)} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                PaperProps={{
                                    sx: {
                                        width: '100vw',
                                        maxWidth: '100vw',
                                        left: '0 !important',
                                        mt: 1,
                                    }
                                }} >

                                <MenuItem
                                    key="applications"
                                    selected={props.page === Page.APPLICATION || props.page === Page.APPLICATIONS}
                                    onClick={() => navigate('/applications')}>
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                        spacing={1}>
                                        <Grid
                                            item
                                            xs='auto'>
                                            <SmartphoneIllustration height='20px' width='25px' />
                                        </Grid>
                                        <Grid
                                            item
                                            xs>
                                            <Typography
                                                variant='body1'
                                                fontWeight={700} >
                                                Applications
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>

                                <MenuItem
                                    key="objets"
                                    selected={props.page === Page.OBJECT || props.page === Page.OBJECTS}
                                    onClick={() => navigate('/objets')}>
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                        spacing={1}>
                                        <Grid
                                            item
                                            xs='auto'>
                                            <ObjectIllustration color={colors.blue.main} height='25px' width='25px' showDetails />
                                        </Grid>
                                        <Grid
                                            item
                                            xs>
                                            <Typography
                                                variant='body1'
                                                fontWeight={700} >
                                                Objets
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>

                                <MenuItem
                                    key="services"
                                    selected={props.page === Page.SERVICE || props.page === Page.SERVICES}
                                    onClick={() => navigate('/services')}>
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                        spacing={1}>
                                        <Grid
                                            item
                                            xs='auto'>
                                            <ServiceIllustration color={colors.orange.main} height='25px' width='25px' />
                                        </Grid>
                                        <Grid
                                            item
                                            xs>
                                            <Typography
                                                variant='body1'
                                                fontWeight={700} >
                                                Services
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>

                                <MenuItem
                                    key="pack-digital"
                                    selected={props.page === Page.PACK_DIGITAL}
                                    onClick={() => navigate('/pack-digital')}>
                                    <Grid
                                        container
                                        justifyContent='center'
                                        alignItems='center'
                                        spacing={1}>
                                        <Grid
                                            item
                                            xs='auto'>
                                            <FruitIcon height='25px' width='25px' />
                                        </Grid>
                                        <Grid
                                            item
                                            xs>
                                            <Typography
                                                variant='body1'
                                                fontWeight={700} >
                                                Pack digital
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>

                                <MenuItem
                                    key="user" >
                                    <Grid
                                        container
                                        justifyContent={authentication.isLoggedIn() ? 'space-between' : 'center'}>
                                        <Grid
                                            item
                                            xs='auto'>
                                            <ButtonUser onClick={() => handleClickUser()} user={userInfos} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs='auto'>
                                            {authentication.isLoggedIn() &&
                                            <ButtonLogout onClick={() => handleLogout()}/>}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Box>
    )
}
