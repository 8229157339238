
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { ThemeProvider } from '@mui/material/styles';

import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { CardProduct } from "../../components/Card/CardProduct";
import { CardProductHighlight } from "../../components/Card/CardProductHighlight";
import { ErrorComponent } from '../../components/ErrorComponent';
import { FieldFileUpload } from "../../components/Field/FieldFileUpload";
import { FieldImage } from "../../components/Field/FieldImage";
import { FieldLabel } from "../../components/Field/FieldLabel";
import { HeaderBo } from "../../components/Header/HeaderBo";
import { CheckFullIcon } from "../../components/Icon/CheckFullIcon";
import { CloseFullIcon } from "../../components/Icon/CloseFullIcon";
import { Loading } from '../../components/Loading';
import { PageProduct } from "../../components/Page/PageProduct";

import { createImage, deleteImage, updateImage } from "../../api/Image.api";
import { getProduct, modifyProduct } from "../../api/Product.api";
import { getThemes } from "../../api/Theme.api";

import { IImage, ImageType } from "../../models/Image.model";
import { IPlateforme, TypePlateforme } from "../../models/Plateforme.model";
import { IProduct, ProductType } from "../../models/Product.model";
import { ITheme } from "../../models/Theme.model";

import { theme } from '../../static/theme';
import { colors } from "../../static/colors";

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from "../../settings/Page";


const initialValues = {
    type: undefined as any,
    name: undefined as undefined | string,
    code: undefined as undefined | string,
    tagline: undefined as undefined | string,
    description: undefined as undefined | string,
    content: undefined as undefined | string,
    url: undefined as undefined | string,
    reviewGSMC: undefined as undefined | string,
    color: undefined as undefined | string,
    colorSecondary: undefined as undefined | string,
    isActive: false as boolean,
    themes: [] as string[],
    plateformeAndroid: undefined as undefined | string,
    plateformeIos: undefined as undefined | string,
    plateformeWeb: undefined as undefined | string,
}

export const PageProductEdit = () => {

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.PRODUCT;
    const { id } = useParams();

    const [tabValue, setTabValue] = useState<string>("0");

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [snackBarText, setSnackBarText] = useState<String>();
    const [snackBarSeverity, setSnackBarSeverity] = useState<string>("success");

    const [product, setProduct] = useState<any>();
    const [productPreviewed, setProductPreviewed] = useState<IProduct>();
    const [themes, setThemes] = useState<ITheme[]>([]);
    const [images, setImages] = useState<IImage[]>([]);

    const validationSchema = yup.object({
        type: yup.string().required('Champ obligatoire'),
        name: yup.string().required('Champ obligatoire'),
        code: yup.string().required('Champ obligatoire'),
        tagline: yup.string().nullable(),
        description: yup.string().nullable(),
        content: yup.string().nullable(),
        url: yup.string().nullable(),
        reviewGSMC: yup.string().nullable(),
        color: yup.string().nullable(),
        colorSecondary: yup.string().nullable(),
        plateformeAndroid: yup.string().nullable(),
        plateformeIos: yup.string().nullable(),
        plateformeWeb: yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsSubmitDisabled(true);

            const plateformes: IPlateforme[] = [];

            plateformes.push({
                id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.ANDROID)?.id,
                type: TypePlateforme.ANDROID,
                url: values.plateformeAndroid ? values.plateformeAndroid : "",
                isActive: values.plateformeAndroid ? true : false
            })

            plateformes.push({
                id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.IOS)?.id,
                type: TypePlateforme.IOS,
                url: values.plateformeIos ? values.plateformeIos : "",
                isActive: values.plateformeIos ? true : false
            })

            plateformes.push({
                id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.WEB)?.id,
                type: TypePlateforme.WEB,
                url: values.plateformeWeb ? values.plateformeWeb : "",
                isActive: values.plateformeWeb ? true : false
            })

            const toSend: any = {
                id: id,
                type: values.type as string,
                name: values.name as string,
                code: values.code as string,
                tagline: values.tagline as string,
                description: values.description as string,
                content: values.content as string,
                url: values.url as string,
                reviewGSMC: values.reviewGSMC as string,
                color: values.color as string,
                colorSecondary: values.colorSecondary as string,
                isActive: values.isActive,
                dateCreated: product.dateCreated,
                themes: values.themes.map(t => {
                    return { id: themes.find(f => f.name === t)?.id }
                }),
                plateformes: plateformes
            }

            modifyProduct(toSend)
            .then((response: IProduct) => {
                setProduct(response);
                setSnackBarText("Produit “" + response.name + "” sauvegardé");
                setSnackBarSeverity("success");
                setIsSnackbarOpened(true);
                setIsSubmitDisabled(false);
            }).catch(() => {
                setSnackBarText("Erreur lors de la sauvegarde de votre produit");
                setSnackBarSeverity("error");
                setIsSnackbarOpened(true);
                setIsSubmitDisabled(false);
            });
        }
    })


    useEffect(() => {
        formik.setValues(initialValues);

        window.scrollTo({
            top: 0
        });

        getThemes(0, 10000).then(response => {
            setThemes(response.content)
        })
    }, [])


    useEffect(() => {
        id && getProduct(+id).then(response => {
            const values = {
                type: response.type,
                name: response.name,
                code: response.code,
                tagline: response.tagline as string,
                description: response.description as string,
                content: response.content as string,
                url: response.url as string,
                reviewGSMC: response.reviewGSMC as string,
                color: response.color as string,
                colorSecondary: response.colorSecondary as string,
                isActive: response.isActive,
                themes: response.themes.map((t: ITheme) => t.name),
                plateformeAndroid: response.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.ANDROID)?.url,
                plateformeIos: response.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.IOS)?.url,
                plateformeWeb: response.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.WEB)?.url,
            }

            formik.setValues(values)
            setProduct(response);
            setImages(response.images.map((_) => {
                _.productId = response.id;
                return _;
            }));
        })

    }, [id])


    useEffect(() => {

        if (!product) return;

        const t: ITheme[] = formik.values.themes.map(t => { return themes.find((f: ITheme) => f.name === t) }).filter(f => f !== undefined) as ITheme[]
        const p: IPlateforme[] = [];

        p.push({
            id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.ANDROID)?.id,
            type: TypePlateforme.ANDROID,
            url: formik.values.plateformeAndroid ? formik.values.plateformeAndroid : "",
            isActive: formik.values.plateformeAndroid ? true : false
        })

        p.push({
            id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.IOS)?.id,
            type: TypePlateforme.IOS,
            url: formik.values.plateformeIos ? formik.values.plateformeIos : "",
            isActive: formik.values.plateformeIos ? true : false
        })

        p.push({
            id: product.plateformes?.find((f: IPlateforme) => f.type === TypePlateforme.WEB)?.id,
            type: TypePlateforme.WEB,
            url: formik.values.plateformeWeb ? formik.values.plateformeWeb : "",
            isActive: formik.values.plateformeWeb ? true : false
        })

        const pr: IProduct = {
            type: formik.values.type as ProductType,
            code: formik.values.code as string,
            name: formik.values.name as string,
            tagline: formik.values.tagline as string,
            description: formik.values.description as string,
            content: formik.values.content as string,
            url: formik.values.url as string,
            reviewGSMC: formik.values.reviewGSMC as string,
            color: formik.values.color as string,
            colorSecondary: formik.values.colorSecondary as string,
            nbDownload: 142,
            isActive: true,
            themes: t,
            images: images,
            plateformes: p
        }

        setProductPreviewed(pr);
    }, [formik.values, images])

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const handleChangeSelect = (event: SelectChangeEvent, selector: string) => {
        const {
            target: { value },
        } = event;
        formik.setFieldValue(selector, value)
    }

    const handleFileUpload = (img: string) => {
        const toAdd: IImage = {
            image: img,
            order: 0,
            type: ImageType.PREVIEW,
            productId: product.id
        }

        createImage(toAdd)
        .then(response => {
            setImages([...images, {...response, productId: product.id}]);
        });
    }

    const handleChangeTypeImg = (id: number, event: SelectChangeEvent) => {
        const imgs = [...images];
        imgs[id].type = event.target.value as ImageType;

        updateImage(imgs[id])
        .then(response => {
            setImages(imgs);
        });
    }

    const handleChangeOrderImg = (id: number, order: number) => {
        const imgs = [...images];
        imgs[id].order = order;

        updateImage(imgs[id])
        .then(response => {
            setImages(imgs);
        });
    }

    const handleDeleteImg = (id: number, image: IImage) => {

        if (image.id)
            deleteImage(image.id)
            .then(response => {
                const imgs = [...images];
                imgs.splice(id, 1);
                setImages(imgs);
            });
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>

            <ThemeProvider theme={theme}>

                <Snackbar
                    sx={{
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpened}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}>
                    <Alert
                        sx={{
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                            boxShadow: 3,
                        }}
                        severity={snackBarSeverity as AlertColor}
                        iconMapping={{
                            success: <CheckFullIcon color='white' />,
                            error: <CloseFullIcon color='white' />,
                        }} >
                        <Typography fontWeight={500} >{snackBarText}</Typography>
                    </Alert>
                </Snackbar>

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        my: 5
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 6,
                                xs: 2
                            }
                        }} >

                        <Grid
                            item
                            md={5}
                            xs={12}>

                            <form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}>
                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.type && Boolean(formik.errors.type)} >
                                            <FieldLabel label="Type" isRequired />
                                        </FormLabel>
                                        <TextField
                                            disabled
                                            fullWidth
                                            id="type"
                                            name="type"
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            error={formik.touched.type && Boolean(formik.errors.type)}
                                            helperText={formik.touched.type && formik.errors.type}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.code && Boolean(formik.errors.code)} >
                                            <FieldLabel label="Code" isRequired />
                                        </FormLabel>
                                        <TextField
                                            disabled
                                            fullWidth
                                            id="code"
                                            name="code"
                                            value={formik.values.code}
                                            onChange={formik.handleChange}
                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                            helperText={formik.touched.code && formik.errors.code}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.name && Boolean(formik.errors.name)} >
                                            <FieldLabel label="Nom" isRequired />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.themes && Boolean(formik.errors.themes)} >
                                            <FieldLabel label="Thèmes" />
                                        </FormLabel>
                                        <Select
                                            labelId="themes"
                                            id="themes"
                                            fullWidth
                                            multiple
                                            //@ts-ignore
                                            value={formik.values.themes}
                                            onChange={(e: SelectChangeEvent) => handleChangeSelect(e, "themes")}
                                            input={<OutlinedInput label="Themes" />} >
                                            {themes && themes.map((theme: ITheme) => (
                                                <MenuItem
                                                    key={theme.id}
                                                    value={theme.name}>
                                                    {theme.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.tagline && Boolean(formik.errors.tagline)} >
                                            <FieldLabel label="En tête" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="tagline"
                                            name="tagline"
                                            value={formik.values.tagline}
                                            onChange={formik.handleChange}
                                            error={formik.touched.tagline && Boolean(formik.errors.tagline)}
                                            helperText={formik.touched.tagline && formik.errors.tagline}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                            <FieldLabel label="Description" />
                                        </FormLabel>
                                        <TextField
                                            multiline
                                            minRows={5}
                                            maxRows={10}
                                            fullWidth
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.description && Boolean(formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.content && Boolean(formik.errors.content)} >
                                            <FieldLabel label="Contenu" />
                                        </FormLabel>
                                        <TextField
                                            multiline
                                            minRows={5}
                                            maxRows={10}
                                            fullWidth
                                            id="content"
                                            name="content"
                                            value={formik.values.content}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.content && Boolean(formik.errors.content)}
                                            helperText={formik.touched.content && formik.errors.content} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.url && Boolean(formik.errors.url)} >
                                            <FieldLabel label="Site" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="url"
                                            name="url"
                                            value={formik.values.url}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.url && Boolean(formik.errors.url)}
                                            helperText={formik.touched.url && formik.errors.url} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.reviewGSMC && Boolean(formik.errors.reviewGSMC)} >
                                            <FieldLabel label="Commentaire GSMC" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            maxRows={10}
                                            id="reviewGSMC"
                                            name="reviewGSMC"
                                            value={formik.values.reviewGSMC}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.reviewGSMC && Boolean(formik.errors.reviewGSMC)}
                                            helperText={formik.touched.reviewGSMC && formik.errors.reviewGSMC} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}>
                                        <FormLabel error={formik.touched.color && Boolean(formik.errors.color)} >
                                            <FieldLabel label="Couleur primaire" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="color"
                                            name="color"
                                            value={formik.values.color}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.color && Boolean(formik.errors.color)}
                                            helperText={formik.touched.color && formik.errors.color}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                    <Box
                                                        sx={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: 1,
                                                            borderColor: colors.grey.main,
                                                            borderRadius: 1,
                                                            backgroundColor: formik.values.color || "white"
                                                        }}>
                                                    </Box>
                                                </InputAdornment>
                                              )
                                            }} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}>
                                        <FormLabel error={formik.touched.colorSecondary && Boolean(formik.errors.colorSecondary)} >
                                            <FieldLabel label="Couleur secondaire" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="colorSecondary"
                                            name="colorSecondary"
                                            value={formik.values.colorSecondary}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.colorSecondary && Boolean(formik.errors.colorSecondary)}
                                            helperText={formik.touched.colorSecondary && formik.errors.colorSecondary}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                    <Box
                                                        sx={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: 1,
                                                            borderColor: colors.grey.main,
                                                            borderRadius: 1,
                                                            backgroundColor: formik.values.colorSecondary || "white"
                                                        }}>
                                                    </Box>
                                                </InputAdornment>
                                              )
                                            }} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel >
                                            <FieldLabel label="Images" fontWeight={700} />
                                        </FormLabel>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            spacing={2}>
                                            {images.map((img, id) => {
                                                return (
                                                    <Grid
                                                        item
                                                        md={6}
                                                        xs={12}
                                                        sx={{
                                                            height: '100%'
                                                        }}>
                                                        <FieldImage
                                                            image={img}
                                                            handleChangeType={(_) => handleChangeTypeImg(id, _)}
                                                            handleChangeOrder={(_) => handleChangeOrderImg(id, _)}
                                                            handleDelete={(_) => handleDeleteImg(id, _)} />
                                                    </Grid>)
                                            })}
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FieldFileUpload handleFileUpload={handleFileUpload} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel >
                                            <FieldLabel label="Plateformes" fontWeight={700} />
                                        </FormLabel>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems='center'
                                            spacing={2}>
                                            <Grid
                                                item
                                                xs={2}>
                                                <FormLabel >
                                                    <FieldLabel label={`${TypePlateforme.ANDROID} :`} />
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}>
                                                <TextField
                                                    fullWidth
                                                    id="plateformeAndroid"
                                                    name="plateformeAndroid"
                                                    placeholder="URL"
                                                    value={formik.values.plateformeAndroid}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.plateformeAndroid && Boolean(formik.errors.plateformeAndroid)}
                                                    helperText={formik.touched.plateformeAndroid && formik.errors.plateformeAndroid} />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={2}>
                                                <FormLabel >
                                                    <FieldLabel label={`${TypePlateforme.IOS} :`} />
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}>
                                                <TextField
                                                    fullWidth
                                                    id="plateformeIos"
                                                    name="plateformeIos"
                                                    placeholder="URL"
                                                    value={formik.values.plateformeIos}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.plateformeIos && Boolean(formik.errors.plateformeIos)}
                                                    helperText={formik.touched.plateformeIos && formik.errors.plateformeIos} />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={2}>
                                                <FormLabel >
                                                    <FieldLabel label={`${TypePlateforme.WEB} :`} />
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}>
                                                <TextField
                                                    fullWidth
                                                    id="plateformeWeb"
                                                    name="plateformeWeb"
                                                    placeholder="URL"
                                                    value={formik.values.plateformeWeb}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.plateformeWeb && Boolean(formik.errors.plateformeWeb)}
                                                    helperText={formik.touched.plateformeWeb && formik.errors.plateformeWeb} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'right'
                                        }}>
                                        <Typography
                                            component='span'
                                            fontWeight={700}>
                                            Est actif :
                                        </Typography>
                                        <Switch
                                            checked={formik.values.isActive}
                                            onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                            color="primary"
                                            name="estActif"
                                            inputProps={{ 'aria-label': 'primary checkbox' }} />

                                        <Button
                                            autoFocus
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitDisabled}
                                            sx={{
                                                ml: 2,
                                                color: "white"
                                            }}>
                                            Sauvegarder
                                        </Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Grid>

                        <Grid
                            item
                            md={7}
                            xs={12}>
                            <TabContext value={tabValue.toString()}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider'
                                    }}>
                                    <TabList
                                        onChange={(e, _) => setTabValue(_)}>
                                        <Tab
                                            label={
                                                <Typography
                                                    fontWeight={tabValue === '0' ? 700 : 500}>
                                                    Page
                                                </Typography>
                                            }
                                            value="0" />
                                        <Tab
                                            label={
                                                <Typography
                                                    fontWeight={tabValue === '1' ? 700 : 500}>
                                                    Cartes
                                                </Typography>
                                            }
                                            value="1" />
                                    </TabList>
                                </Box>

                                <TabPanel
                                    value="0"
                                    sx={{
                                        px: 0
                                    }}>
                                    {productPreviewed &&
                                        <PageProduct product={productPreviewed} />}
                                </TabPanel>

                                <TabPanel
                                    value="1"
                                    sx={{
                                        px: 0
                                    }}>
                                    {productPreviewed &&
                                        <Grid
                                            container
                                            justifyContent='flex-start'
                                            spacing={4}>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='h3'
                                                    fontWeight={400}
                                                    sx={{
                                                        mb: 2
                                                    }}>
                                                    Carte classique
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        maxWidth: '300px'
                                                    }}>
                                                    <CardProduct product={productPreviewed} />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    height: '100%'
                                                }}>
                                                <Typography
                                                    variant='h3'
                                                    fontWeight={400}
                                                    sx={{
                                                        mb: 2
                                                    }}>
                                                    Carte “Zoom sur”
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        maxWidth: '600px'
                                                    }}>
                                                    <CardProductHighlight product={productPreviewed} />
                                                </Box>
                                            </Grid>
                                        </Grid>}
                                </TabPanel>
                            </TabContext>
                        </Grid>
                    </Grid>

                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}
