import { useEffect, useState } from 'react';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Chip, useMediaQuery } from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import WarningIcon from '@mui/icons-material/Warning';

import { ErrorComponent } from '../../components/ErrorComponent';
import { HeaderBo } from '../../components/Header/HeaderBo';
import { Loading } from '../../components/Loading';

import { createTheme, getThemesBo, modifyTheme } from '../../api/Theme.api';

import { getThemeIcon, IPageTheme, ITheme } from '../../models/Theme.model';

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';


interface IPropsDialog {
    open: boolean,
    theme?: ITheme;
    handleClose: () => void
}

const DialogTheme = (props: IPropsDialog) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        formik.setValues({
            code: props.theme?.code,
            name: props.theme?.name,
            order: props.theme?.order,
            isActive: props.theme?.isActive || false,
        });
        formik.setTouched({});
    }, [props.open])


    const validationSchema = yup.object({
        code: yup.string().required('Champ obligatoire'),
        name: yup.string().required('Champ obligatoire'),
        order: yup.number()
    })

    const formik = useFormik({
        initialValues: {
            code: props.theme?.code,
            name: props.theme?.name,
            order: props.theme?.order,
            isActive: props.theme?.isActive || false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (props.theme) {
                const toSend: ITheme = {
                    id: props.theme.id,
                    dateCreated: props.theme?.dateCreated,
                    code: values.code as string,
                    name: values.name as string,
                    order: values.order as number,
                    isActive: values.isActive

                }
                modifyTheme(toSend).then((response: ITheme) => {
                    if (response.id) {
                        props.handleClose();
                    }
                });
            }
            else {
                const toSend: ITheme = {
                    code: values.code as string,
                    name: values.name as string,
                    order: values.order as number,
                    isActive: values.isActive
                }
                createTheme(toSend).then((response: ITheme) => {
                    if (response.id) {
                        props.handleClose()
                    }
                });
            }
        }
    })


    return (
        <Dialog
            maxWidth={'md'}
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Thème
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="code"
                                fullWidth
                                variant="outlined"
                                name="code"
                                label={"Code *"}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="name"
                                fullWidth
                                variant="outlined"
                                name="name"
                                label={"Nom *"}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                id="order"
                                type="number"
                                fullWidth
                                variant="outlined"
                                name="order"
                                label={"Ordre"}
                                value={formik.values.order}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.order && Boolean(formik.errors.order)}
                                helperText={formik.touched.order && formik.errors.order} />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center'
                            }}>
                            <Typography
                                component='span'
                                fontWeight={700}>
                                Est actif :
                            </Typography>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif" />
                        </Grid>      

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        type="submit"
                        sx={{
                            color: 'white'
                        }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}


export const PageThemes = () => {

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.THEMES;

    const [themes, setThemes] = useState<ITheme[] | undefined | null>(undefined);
    const [themeToUpdate, setThemeToUpdate] = useState<ITheme | undefined>(undefined);
    const [themesCount, setThemesCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getThemesBo(pageNumber, rowPerPage).then((response: IPageTheme) => {
            setThemes(response.content);
            setThemesCount(response.totalElements);
        })
        .catch((exception) => {
            setThemes(null);
            setThemesCount(0);
        })

    }, [rowPerPage, pageNumber])

    const forceRefresh = () => {
        getThemesBo(pageNumber, rowPerPage).then((response: IPageTheme) => {
            setThemes(response.content);
            setThemesCount(response.totalElements);
        })
        .catch((exception) => {
            setThemes(null);
            setThemesCount(0);
        })
        setOpen(false);
    }

    const handleClickModify = (theme: ITheme) => {
        setThemeToUpdate(theme);
        setOpen(true);
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>

            <ThemeProvider theme={theme}>

                <DialogTheme
                    open={open}
                    theme={themeToUpdate}
                    handleClose={() => forceRefresh()} />

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        mt: {
                            md: 2,
                            xs: 0
                        }
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }} >
                        <Grid
                            item
                            xs={12} >
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setThemeToUpdate(undefined);
                                    setOpen(true);
                                }}
                                sx={{
                                    color: colors.black.main
                                }}>
                                Ajouter un thème
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Card
                                sx={{
                                    border: 1,
                                    borderColor: colors.grey.secondary,
                                    width: '100%'
                                }}>
                                <TableContainer>
                                    <Table aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Nom</TableCell>
                                                <TableCell align="center">Code</TableCell>
                                                <TableCell align="center">Icône</TableCell>
                                                <TableCell align="center">Ordre</TableCell>
                                                <TableCell align="center">Actif</TableCell>
                                                <TableCell
                                                    key={'outil'}
                                                    sx={{
                                                        minWidth: '20px'
                                                    }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {themes === undefined ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Chargement des thèmes en cours...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : themes === null ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <WarningIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.red.main
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Une erreur est survenue lors de la récupération des thèmes
                                                    </Typography>
                                                    <Typography
                                                        variant='body2'
                                                        sx={{
                                                            mt: 1
                                                        }}>
                                                        Veuillez réessayer ultérieurement
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : themes.length === 0 ?
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    sx={{
                                                        height: '150px',
                                                        textAlign: 'center'
                                                    }}>
                                                    <InboxIcon
                                                        fontSize="large"
                                                        sx={{
                                                            color: colors.black.secondary
                                                        }} />
                                                    <Typography
                                                        variant='subtitle1'>
                                                        Aucun thème
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            : themes.map((_) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{_.name}</TableCell>
                                                        <TableCell>{_.code}</TableCell>
                                                        <TableCell align="center">
                                                            {getThemeIcon(_.code)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.order !== undefined && <Chip label={_.order} />}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                onClick={() => handleClickModify(_)}>
                                                                <CreateIcon
                                                                    sx={{
                                                                        color: colors.blue.main
                                                                    }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={themesCount}
                                    rowsPerPage={rowPerPage}
                                    page={pageNumber}
                                    onPageChange={(event, newPage) => setPageNumber(newPage)}
                                    onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                                    sx={{
                                        border: 0
                                    }} />
                            </Card>
                        </Grid>
                    </Grid>

                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}
