import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';

import { ThemeProvider } from '@mui/material/styles';

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from '@mui/material/Select';
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';

import { ErrorComponent } from '../../components/ErrorComponent';
import { CardSection } from "../../components/Card/CardSection";
import { FieldLabel } from "../../components/Field/FieldLabel";
import { HeaderBo } from "../../components/Header/HeaderBo";
import { CheckFullIcon } from "../../components/Icon/CheckFullIcon";
import { Loading } from '../../components/Loading';

import { getProducts } from "../../api/Product.api";
import { getSection, updateSection } from "../../api/Section.api";

import { IProduct} from "../../models/Product.model";
import { ISection, SectionType, sectionTypeOptions } from "../../models/Section.model";

import { theme } from '../../static/theme';
import { colors } from "../../static/colors";

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from "../../settings/Page";


interface FormValues {
    type?: SectionType;
    title?: string;
    description?: string;
    uri?: string;
    dateStart: Date | null;
    dateEnd: Date | null;
    isActive?: boolean;
    products: IProduct[];
}


export const PageSectionEdit = () => {

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.SECTION;
    const { id } = useParams();

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [snackBarText, setSnackBarText] = useState<String>();
    const [snackBarColor, setSnackBarColor] = useState<string>(colors.green.main);

    const [section, setSection] = useState<ISection>();
    const [products, setProducts] = useState<IProduct[]>([]);

    const validationSchema = yup.object({
        type: yup.string().required('Champ obligatoire'),
        title: yup.string().required('Champ obligatoire'),
        description: yup.string().nullable(),
        uri: yup.string().nullable(),
        dateStart: yup
            .date()
            .typeError("Date non valide")
            .nullable()
            .required('Champ obligatoire'),
        dateEnd: yup
            .date()
            .typeError("Date non valide")
            .nullable()
    })


    const getInitialValues = () => {
        return {
            type: SectionType.HIGHLIGHT as SectionType | undefined,
            title: undefined as string | undefined,
            description: undefined as string | undefined,
            uri: undefined as string | undefined,
            dateStart: null as Date | null,
            dateEnd: null as Date | null,
            isActive: false as boolean,
            products: [] as IProduct[],
        }
    };


    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        validate: (values: FormValues) => {
            let errors: FormikErrors<FormValues> = {};

            if (values.dateStart !== undefined && values.dateStart !== null &&
                values.dateEnd !== undefined && values.dateEnd !== null &&
                new Date(values.dateStart).getTime() > new Date(values.dateEnd).getTime())
                errors.dateEnd = "La date de fin doit être postérieure à la date de début";

            return errors;
        },
        onSubmit: (values) => {
            if (!section)
                return;

            setIsSubmitDisabled(true);

            updateSection({...section, products: section.products.map(_ => {return {id : _.id} as IProduct})})
            .then((response: ISection) => {
                setSection(response);
                setSnackBarText("Section “" + response.title + "” sauvegardée");
                setSnackBarColor(colors.green.main);
                setIsSnackbarOpened(true);
                setIsSubmitDisabled(false);
            }).catch(() => {
                setSnackBarText("Erreur lors de la sauvegarde de la section");
                setSnackBarColor(colors.red.main);
                setIsSnackbarOpened(true);
                setIsSubmitDisabled(false);
            });
        }
    });


    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };


    useEffect(() => {
        if (!id)
            return;

        getSection(parseInt(id))
        .then((response: ISection) => {
            setSection(response);
            formik.setValues({
                type: response.type,
                title: response.title || "",
                description: response.description,
                uri: response.uri,
                dateStart: response.dateStart ? new Date(parseInt(response.dateStart.toString())) : null,
                dateEnd: response.dateEnd ? new Date(parseInt(response.dateEnd.toString())) : null,
                isActive: response.isActive,
                products: response.products || []
            })
        });

        getProducts(0, 1000, false).then(response => {
            setProducts(response.content);
        });

    }, [id])


    useEffect(() => {
        if (!formik.values.type)
            return;

        setSection({...section,
            type: formik.values.type,
            title: formik.values.title || "",
            description: formik.values.description,
            uri: formik.values.uri,
            dateStart: formik.values.dateStart || undefined,
            dateEnd: formik.values.dateEnd || undefined,
            isActive: formik.values.isActive,
            products: formik.values.products
        });

    }, [formik.values])


    useEffect(()=>{
    })

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>

            <ThemeProvider theme={theme}>

                <Snackbar
                    sx={{
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpened}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}>
                    <Alert
                        sx={{
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                            boxShadow: 3
                        }}
                        severity='success'
                        iconMapping={{
                            success: <CheckFullIcon color='white' bgColor={snackBarColor} />,
                        }} >
                        <Typography fontWeight={500} >{snackBarText}</Typography>
                    </Alert>
                </Snackbar>

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        my: 5
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 6,
                                xs: 2
                            }
                        }} >

                        <Grid
                            item
                            md={6}
                            xs={12}>

                            <form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}>
                                        <FormLabel error={formik.touched.type && Boolean(formik.errors.type)} >
                                            <FieldLabel label="Type" isRequired />
                                        </FormLabel>
                                        <Select
                                            id="type"
                                            fullWidth
                                            value={formik.values.type}
                                            onChange={(e) => formik.setFieldValue("type", e.target.value as SectionType)}
                                            onBlur={(e) => formik.setFieldTouched("type")} >
                                            {sectionTypeOptions.map((_: any) => (
                                                <MenuItem
                                                    value={_.value}>
                                                    {_.label}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        {formik.touched.type && formik.errors.type &&
                                        <FormHelperText>
                                            <Typography
                                                variant='caption'
                                                color={colors.red.main}>
                                                {formik.touched.type && formik.errors.type}
                                            </Typography>
                                        </FormHelperText>}
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.title && Boolean(formik.errors.title)} >
                                            <FieldLabel label="Titre" isRequired />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="title"
                                            name="title"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            error={formik.touched.title && Boolean(formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                            <FieldLabel label="Description" />
                                        </FormLabel>
                                        <TextField
                                            multiline
                                            minRows={2}
                                            maxRows={5}
                                            fullWidth
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.description && Boolean(formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.uri && Boolean(formik.errors.uri)} >
                                            <FieldLabel label="URL" />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="uri"
                                            name="uri"
                                            value={formik.values.uri}
                                            onChange={formik.handleChange}
                                            error={formik.touched.uri && Boolean(formik.errors.uri)}
                                            helperText={formik.touched.uri && formik.errors.uri}
                                            onBlur={formik.handleBlur} />
                                    </Grid>

                                    <Grid
                                        item
                                        md={6}
                                        xs={12}>
                                        <FormLabel error={formik.touched.dateStart && Boolean(formik.errors.dateStart)} >
                                            <FieldLabel label="Date de début" isRequired />
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} >
                                            <DatePicker
                                                value={formik.values.dateStart}
                                                onChange={(value) => formik.setFieldValue("dateStart", value)}
                                                cancelText="Annuler"
                                                okText="Valider"
                                                toolbarTitle="JJ/MM/AAAA"
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: "JJ/MM/AAAA"
                                                            }}
                                                            onBlur={(e) => formik.setFieldTouched("dateStart")}
                                                            error={formik.touched.dateStart && Boolean(formik.errors.dateStart)}
                                                            helperText={formik.touched.dateStart && formik.errors.dateStart}
                                                        />
                                                } />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        md={6}
                                        xs={12}>
                                        <FormLabel error={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)} >
                                            <FieldLabel label="Date de fin" />
                                        </FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} >
                                            <DatePicker
                                                value={formik.values.dateEnd}
                                                onChange={(value) => formik.setFieldValue("dateEnd", value)}
                                                cancelText="Annuler"
                                                okText="Valider"
                                                toolbarTitle="JJ/MM/AAAA"
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                placeholder: "JJ/MM/AAAA"
                                                            }}
                                                            onBlur={(e) => formik.setFieldTouched("dateEnd")}
                                                            error={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
                                                            helperText={formik.touched.dateEnd && formik.errors.dateEnd}
                                                        />
                                                } />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.products && Boolean(formik.errors.products)} >
                                            <FieldLabel label="Produits" />
                                        </FormLabel>
                                        <Select
                                            labelId="products"
                                            id="products"
                                            fullWidth
                                            multiple
                                            value={formik.values.products.map(_ => _.code)}
                                            onChange={(e) => {
                                                let productsCode: string[];

                                                if (typeof e.target.value === "string")
                                                    productsCode = [e.target.value];
                                                else
                                                    productsCode = e.target.value;

                                                formik.setFieldValue("products", products.filter(_ =>  productsCode.find(p => p === _.code)))

                                                return;
                                            }}
                                            input={<OutlinedInput label="" />}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: 0.5
                                                    }}>
                                                    {selected.map((value) => {
                                                        if (!products)
                                                            return <></>;

                                                        let p: IProduct | undefined = products.find(_ =>  value === _.code);

                                                        if (!p)
                                                            return <></>;

                                                        return (
                                                            <Chip
                                                                key={value}
                                                                label={p.name} />
                                                        );}
                                                    )}
                                                </Box>
                                            )} >
                                            {products && products.map((_: IProduct) => (
                                                <MenuItem
                                                    key={_.id}
                                                    value={_.code}>
                                                    {_.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            textAlign: 'right'
                                        }}>
                                        <Typography
                                            component='span'
                                            fontWeight={700}>
                                            Est actif :
                                        </Typography>

                                        <Switch
                                            checked={formik.values.isActive}
                                            onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                            color="primary"
                                            name="estActif"
                                            inputProps={{ 'aria-label': 'primary checkbox' }} />

                                        <Button
                                            autoFocus
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitDisabled}
                                            sx={{
                                                ml: 2,
                                                color: "white"
                                            }}>
                                            Sauvegarder
                                        </Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                            sx={{
                                height: '100%',
                                my: 'auto'
                            }}>
                            {section &&
                            <Box
                                component='div'>
                                <CardSection section={section} />
                            </Box>}
                        </Grid>
                    </Grid>

                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}
