
import { defaultIconColor, defaultIconSize, IIcon } from "./settings"


export const CheckIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M2 20.1509L11.5 28L29 2" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
