import AndroidLogo from '../static/img/plateformes/plateformeAndroidLogo.png'
import IOSLogo from '../static/img/plateformes/plateformeIosLogo.png'


export enum TypePlateforme {
    ANDROID="ANDROID",
    IOS="IOS",
    WEB="WEB"
}


export interface IPlateforme {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    type: TypePlateforme;
    url: string;
    nbDownload?: number;
    isActive: boolean;
}


export interface IPlateformeTypeInfos {
    type: TypePlateforme;
    label: string;
    icon?: string;
}


export const plateformeTypesInfos: IPlateformeTypeInfos[] = [
    {
        type: TypePlateforme.ANDROID,
        label: "Google Play",
        icon: AndroidLogo
    },
    {
        type: TypePlateforme.IOS,
        label: "App Store",
        icon: IOSLogo
    },
    {
        type: TypePlateforme.WEB,
        label: "le site du produit"
    }
]


export const getPlateformeTypeInfos = (type: TypePlateforme): IPlateformeTypeInfos | undefined => {

    let plateformeTypeInfos = plateformeTypesInfos.find(_ => _.type === type);

    if (plateformeTypeInfos)
        return plateformeTypeInfos;
}
