import { cloneElement } from "react";

import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ArrowRightIcon } from "../Icon/ArrowRightIcon";

import { colors } from "../../static/colors"


interface Props {
    label: string;
    icon?: JSX.Element;
    uri: string;
}


export const CardLink = (props: Props) => {

    const navigate = useNavigate();


    return (
        <Card
            sx={{
                backgroundColor: colors.grey.light,
                boxShadow: "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: "0px 4px 20px 0px #3F8CD860"
                }
            }} >
            <CardActionArea
                sx={{
                    height: '100%'
                }}
                onClick={() => navigate(props.uri)}>

                <CardContent
                    sx={{
                        p: 1
                    }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            pl: {
                                md: 2,
                                xs: 0.5
                            },
                            pr: {
                                md: 5,
                                xs: 1
                            }
                        }}>

                        {props.icon &&
                        <Grid
                            item
                            xs="auto"
                            sx={{
                                pr: {
                                    md: 1.5,
                                    xs: 1
                                }
                            }}>
                            {cloneElement(props.icon, {width: '40px', height: '40px'})}
                        </Grid>}

                        <Grid
                            item
                            xs>
                            <Typography variant='button'>{props.label}</Typography>
                        </Grid>

                        <Grid
                            item
                            xs="auto">
                            <ArrowRightIcon />
                        </Grid>

                    </Grid>
                </CardContent>

            </CardActionArea>
        </Card>
    )
}
