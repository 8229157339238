import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { TextFieldPassword } from '../../TextField/TextFieldPassword';

import { PageLoginSteps } from '../../../pages/Login/PageLogin';

import { ILoginRequest } from '../../../models/Authentication.model';
import { IUser } from '../../../models/User.model';

import { colors } from '../../../static/colors';

import AuthService from '../../../service/AuthService';


const passwordMinLength: number = 6;
const passwordMaxLength: number = 100;

const authService = new AuthService();


interface Props {
    user: IUser;
    goToStep: (step: PageLoginSteps) => void;
}


export const FormPassword = (props: Props) => {

    const getInitialValues = () => {
        return {
            password: undefined as string | undefined
        }
    };

    const validationSchema = yup.object({
        password: yup.string()
                     .required('Champ obligatoire')
                     .test('length',
                           `Votre mot de passe doit avoir entre ${passwordMinLength} et ${passwordMaxLength} caractères`,
                           value => value !== undefined && value !== null && value.length >= passwordMinLength && value.length <= passwordMaxLength),
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!values.password)
                return;  // Should never occur with the yup validation.

            const date = ('0' + (props.user.birthDate.getDate())).slice(-2) + "-" +
                         ('0' + (props.user.birthDate.getMonth() + 1)).slice(-2) + "-" +
                         props.user.birthDate.getFullYear();

            const toSend: ILoginRequest = {
                dateBirth: date,
                numDelegataire: props.user.numDelegataire,
                password: values.password
            };

            authService.login(toSend)
            .then(response => {
                props.goToStep(PageLoginSteps.SUCCESS)
            })
            .catch(error => {
                formik.setFieldError("password", "Mot de passe incorrect")
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: {
                            md: 'left',
                            xs: 'center'
                        }
                    }}>
                    <Typography variant='h1'>Bonjour !</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextFieldPassword
                        fullWidth
                        id="password"
                        placeholder="Votre mot de passe"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)} />

                    {formik.touched.password && formik.errors.password &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.password}
                        </Typography>
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 3,
                        sm: 2
                    }}
                    sx={{
                        textAlign: {
                            sm: 'right',
                            xs: 'center'
                        }
                    }}>
                    <Link
                        onClick={() => props.goToStep(PageLoginSteps.PASSWORD_FORGOTTEN)}>
                        <Typography
                            noWrap
                            component='span'
                            variant='body2'
                            fontWeight={700}
                            color={colors.black.secondary}>
                            Mot de passe oublié ?
                        </Typography>
                    </Link>
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        type="submit"
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Se connecter
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
