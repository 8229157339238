import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { CardBecomeSubscriber } from '../../components/Card/CardBecomeSubscriber';
import { CardPlateforme } from '../../components/Card/CardPlateforme';
import { Footer } from '../../components/Footer/Footer';
import { FormPhone } from '../../components/Form/FormPhone';
import { FormCode } from '../../components/Form/FormCode';
import { CheckIcon } from '../../components/Icon/CheckIcon';
import { ChevronRightIcon } from '../../components/Icon/ChevronRightIcon';
import { CloseFullIcon } from '../../components/Icon/CloseFullIcon';
import { PageLoading } from '../../components/Page/PageLoading';

import { IImage, ImageType } from '../../models/Image.model';
import { TypePlateforme } from '../../models/Plateforme.model';
import { IProduct, ProductType } from '../../models/Product.model';

import { getProductByCode } from '../../api/Product.api';
import { getProductConsumptionSms } from '../../api/Sms.api';
import { getUser } from '../../api/User.api';

import { ProductConsumptionStatus } from '../../models/ProductConsumption.model';
import { ISms } from '../../models/Sms.model';
import { IUser } from '../../models/User.model';

import AuthService from '../../service/AuthService';
import { theme } from '../../static/theme';
import { colors } from '../../static/colors';
import IllustrationPageSubscription from '../../static/img/illustrations/illustrationPageSubscription.png';


const authentication = new AuthService();


export enum PageSubscriptionSteps {
    LOGIN,
    PHONE,
    CODE,
    SUCCESS,
    INFO_SUBSCRIPTION_CREATED,
    ERROR_USER_WITHOUT_OFFER,
    ERROR_USER_OFFER_INCOMPATIBLE
}


export const PageSubscription = () => {

    const navigate = useNavigate();

    const paramsUri = useParams<{code: string}>();

    const [isContextInit, setIsContextInit] = useState<boolean>(false);

    const [step, setStep] = useState<PageSubscriptionSteps>();
    const [user, setUser] = useState<IUser>();
    const [product, setProduct] = useState<IProduct>();
    const [sms, setSms] = useState<ISms>();
    const [imageLogo, setImageLogo] = useState<IImage>();

    // Initialize the page states.
    useEffect(() => {

        if (!paramsUri.code)
            return;  // TODO : Redirect to 404.

        window.scrollTo({
            top: 0
        });

        getProductByCode(paramsUri.code)
        .then((response) => {
            setProduct(response);
        })
        .catch(exception => setProduct(undefined));  // TODO : Redirect to 404.

        if (authentication.isLoggedIn()) {
            getUser()
            .then((response) => {
                setUser(response);
            })
            .catch(exception => {
                setUser(undefined);
                if (!isContextInit) setIsContextInit(true);
            });
        }
        else
            if (!isContextInit) setIsContextInit(true);
    }, [])


    // Manage the current step.
    useEffect(() => {

        if (!isContextInit || !product)
            return;

        // User not connected.
        if (!user) {
            setStep(PageSubscriptionSteps.LOGIN);
            return;
        }

        // Check user offer.
        if (!user.offer) {
            setStep(PageSubscriptionSteps.ERROR_USER_WITHOUT_OFFER);
            return;
        }

        // Check product offer.
        if (!product.userOffer) {
            setStep(PageSubscriptionSteps.ERROR_USER_OFFER_INCOMPATIBLE);
            return;
        }

        // Check product consumption.
        if (product.userConsumption &&
            (product.userConsumption.status === ProductConsumptionStatus.IN_PROGRESS ||
             product.userConsumption.status === ProductConsumptionStatus.COMPLETED)) {
            setStep(PageSubscriptionSteps.SUCCESS);
            return;
        }

        // Check the SMS.
        if ((product.userConsumption && 
             product.userConsumption.status === ProductConsumptionStatus.PENDING) ||
            sms?.dateValidated) {
            setStep(PageSubscriptionSteps.INFO_SUBSCRIPTION_CREATED);
            return;
        }

        setStep(PageSubscriptionSteps.PHONE);
    }, [isContextInit,
        product,
        sms, ])


    // Get the SMS.
    useEffect(() => {

        if (!product || !user)
            return;

        setImageLogo(product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0]);

        if (!product.userConsumption?.id) {
            if (!isContextInit) setIsContextInit(true);
            return;
        }

        getProductConsumptionSms(product.userConsumption.id)
        .then((response) => {
            if (response.length > 0)
                setSms(response[0]);

            if (!isContextInit) setIsContextInit(true);
        })
        .catch(exception => {
            if (!isContextInit) setIsContextInit(true);
        });

    }, [product,
        user, ])


    return (
        <ThemeProvider theme={theme}>

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                direction="column"
                sx={{
                    minHeight: "100vh"
                }}>
                {isContextInit && product ?
                <Box
                    sx={{
                        maxWidth: '1200px',  // Update the background position if the maxWidth changed.
                        width: '100%',
                        mx: 'auto'
                    }} >

                    <Grid
                        container
                        justifyContent="center"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }}>

                        <Grid
                            item
                            md={5}
                            xs={12}
                            sx={{
                                position : 'relative',
                                pr: {
                                    md: 2,
                                    xs: 0
                                }
                            }}>

                            {/* Background */}
                            <Box
                                sx={{
                                    position : 'absolute',
                                    zIndex: -10000000,
                                    top: 0,
                                    left: {
                                        md: 'min(calc(-1 * ((100vw - 1200px) / 2 + 16px)), -16px)',  // Shift the background to the left.
                                        xs: 0
                                    },
                                    width: {
                                        md: 'max(calc(100% + (100vw - 1200px) / 2 + 16px), calc(100% + 16px))',
                                        xs: 'calc(100% + 16px)'  // Overlap the padding right.
                                    },
                                    height: '100%',
                                    background: `linear-gradient(${colors.orange.light}, white)`,
                                }}>
                            </Box>

                            <Grid
                                container
                                justifyContent="space-between"
                                spacing={3}
                                sx={{
                                    mt: {
                                        md: 3,
                                        xs: 1
                                    }
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Button
                                        variant='text'
                                        color='inherit'
                                        sx={{
                                            p: 1.5
                                        }}
                                        onClick={() => {
                                            switch (product.type) {
                                                case ProductType.APPLICATION:
                                                    navigate(`/applications/${product.code}`);
                                                    return;

                                                case ProductType.OBJECT:
                                                    navigate(`/objets/${product.code}`);
                                                    return;

                                                case ProductType.SERVICE:
                                                    navigate(`/services/${product.code}`);
                                                    return;
                                            }
                                        }}>
                                        <Typography variant='h4'>
                                            {"< Retour"}
                                        </Typography>
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        variant='h1'>
                                        {product.type === ProductType.APPLICATION ? `Souscription à l'application ${product.name} !` :
                                        product.type === ProductType.OBJECT ? `Obtenir ${product.name} !` :
                                        `Souscription au service ${product.name} !`}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <Grid
                                        container
                                        justifyContent="space-around"
                                        spacing={3}>

                                        {imageLogo &&
                                        <Grid
                                            item
                                            xs='auto'
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                }
                                            }}>
                                            <CardMedia
                                                component="img"
                                                src={imageLogo.image}
                                                sx={{
                                                    width: '70px',
                                                    height: '70px',
                                                    borderRadius: 2
                                                }} />
                                        </Grid>}

                                        <Grid
                                            item
                                            xs>
                                            <Grid
                                                container
                                                justifyContent="space-around"
                                                spacing={2}>

                                                {product.userOffer && product.userOffer.description &&
                                                <>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Typography
                                                        variant='body2'
                                                        fontWeight={700}>
                                                        Récapitulatif du Pack :
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <ul
                                                        style={{
                                                            margin: 0
                                                        }}>
                                                        <li style={{ color: colors.grey.main }}>
                                                            <Typography variant='body2'>
                                                                {product.userOffer.description}
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </Grid>
                                                </>}

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Button
                                                        sx={{
                                                            p: 1,
                                                            mb: 0.5,
                                                            color: colors.black.main
                                                        }}
                                                        endIcon={<ChevronRightIcon />}
                                                        onClick={() => {
                                                            switch (product.type) {
                                                                case ProductType.APPLICATION:
                                                                    navigate(`/applications/${product.code}`);
                                                                    return;

                                                                case ProductType.OBJECT:
                                                                    navigate(`/objets/${product.code}`);
                                                                    return;

                                                                case ProductType.SERVICE:
                                                                    navigate(`/services/${product.code}`);
                                                                    return;
                                                            }
                                                        }} >
                                                        + d'infos sur {product.name}
                                                    </Button>

                                                    <br />

                                                    {product.url &&
                                                    <Button
                                                        sx={{
                                                            p: 1,
                                                            mb: 0.5,
                                                            color: colors.black.main
                                                        }}
                                                        endIcon={<ChevronRightIcon />}
                                                        onClick={() => window.open(product.url)} >
                                                        Découvrir le site {product.name}
                                                    </Button>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: {
                                            md: 'block',
                                            xs: 'none'
                                        },
                                        textAlign: 'center',
                                        my: 4,
                                    }}>
                                    <img
                                        src={IllustrationPageSubscription}
                                        alt={"Illustration Souscription"}
                                        loading="lazy"
                                        width='300px'
                                        style={{
                                            filter: "drop-shadow(0px 4px 4px #00000028)"
                                        }} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            md={7}
                            xs={12}>

                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                spacing={3}
                                sx={{
                                    minHeight: '100%',
                                    mt: 0
                                }}>

                                <Grid
                                    item>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            px: {
                                                md: 8,
                                                sm: 6,
                                                xs: 2
                                            },
                                            py: {
                                                md: 8,
                                                xs: 4
                                            },
                                            border: 1,
                                            borderColor: colors.grey.main,
                                            borderRadius: 2
                                        }}>

                                        {step === PageSubscriptionSteps.LOGIN &&
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    mb: {
                                                        md: 4,
                                                        xs: 2
                                                    }
                                                }}>
                                                <Typography
                                                    variant='h2'>
                                                    Profitez de {product.name} avec le Pack Digital !
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='body1'>
                                                    Vous devez vous connecter pour obtenir {product.name} avec l'offre du Pack Digital
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Button
                                                    onClick={() => navigate(`/login?redirect=/applications/${product.code}/obtenir`)}
                                                    sx={{
                                                        width: {
                                                            sm: 'auto',
                                                            xs: '100%'
                                                        },
                                                        px: {
                                                            sm: 10,
                                                            xs: 0
                                                        },
                                                        mt: {
                                                            md: 4,
                                                            xs: 2
                                                        },
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}>
                                                    Se connecter
                                                </Button>
                                            </Grid>
                                        </Grid>}

                                        {step === PageSubscriptionSteps.PHONE && user &&
                                            <FormPhone
                                                title={`Profitez de ${product.name} avec le Pack Digital !`}
                                                user={user}
                                                save={(_) => setUser({...user, phone: _.phone})}
                                                goToNextStep={() => setStep(PageSubscriptionSteps.CODE)} />
                                        }
                                        
                                        {step === PageSubscriptionSteps.CODE && user &&
                                            <FormCode
                                                title={`Profitez de ${product.name} avec le Pack Digital !`}
                                                user={user}
                                                product={product}
                                                goToPreviousStep={() => setStep(PageSubscriptionSteps.PHONE)}
                                                goToNextStep={() => setStep(PageSubscriptionSteps.SUCCESS)} />
                                        }

                                        {step === PageSubscriptionSteps.SUCCESS &&
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: colors.blue.main,
                                                        p: 1
                                                    }}>
                                                    <CheckIcon selected color="white" />
                                                </Avatar>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='h2'>
                                                    Félicitations
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='body1'>
                                                    Vous avez souscrit à {product.name}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Button
                                                    onClick={() => navigate('/adherent')}
                                                    sx={{
                                                        width: {
                                                            sm: 'auto',
                                                            xs: '100%'
                                                        },
                                                        px: {
                                                            sm: 10,
                                                            xs: 0
                                                        },
                                                        mt: {
                                                            md: 4
                                                        },
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}>
                                                    Retour à l'espace adhérent
                                                </Button>
                                            </Grid>
                                        </Grid>}

                                        {step === PageSubscriptionSteps.INFO_SUBSCRIPTION_CREATED &&
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: colors.blue.main,
                                                        p: 1
                                                    }}>
                                                    <CheckIcon selected color="white" />
                                                </Avatar>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='h2'>
                                                    Souscription en cours
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='body1'>
                                                    Votre souscription à {product.name} est en cours
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Button
                                                    onClick={() => navigate('/adherent')}
                                                    sx={{
                                                        width: {
                                                            sm: 'auto',
                                                            xs: '100%'
                                                        },
                                                        px: {
                                                            sm: 10,
                                                            xs: 0
                                                        },
                                                        mt: {
                                                            md: 4
                                                        },
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}>
                                                    Retour à l'espace adhérent
                                                </Button>
                                            </Grid>
                                        </Grid>}

                                        {step === PageSubscriptionSteps.ERROR_USER_OFFER_INCOMPATIBLE &&
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: colors.red.main,
                                                        p: 1
                                                    }}>
                                                    <CloseFullIcon selected color='white'/>
                                                </Avatar>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='h2'>
                                                    Aucune offre disponible
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='body1'>
                                                    Aucune offre du Pack Ditigal n'est disponible pour {product.name}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Button
                                                    onClick={() => {
                                                        switch (product.type) {
                                                            case ProductType.APPLICATION:
                                                                navigate(`/applications/${product.code}`);
                                                                return;

                                                            case ProductType.OBJECT:
                                                                navigate(`/objets/${product.code}`);
                                                                return;

                                                            case ProductType.SERVICE:
                                                                navigate(`/services/${product.code}`);
                                                                return;
                                                        }
                                                    }}
                                                    sx={{
                                                        width: {
                                                            sm: 'auto',
                                                            xs: '100%'
                                                        },
                                                        px: {
                                                            sm: 10,
                                                            xs: 0
                                                        },
                                                        mt: {
                                                            md: 4,
                                                            xs: 2
                                                        },
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}>
                                                    Retour
                                                </Button>
                                            </Grid>
                                        </Grid>}

                                        {step === PageSubscriptionSteps.ERROR_USER_WITHOUT_OFFER &&
                                        <Grid
                                            container
                                            justifyContent="space-around"
                                            spacing={3}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                <Avatar
                                                    sx={{
                                                        bgcolor: colors.red.main,
                                                        p: 1
                                                    }}>
                                                    <CloseFullIcon selected color='white'/>
                                                </Avatar>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='h2'>
                                                    Pack Ditigal
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant='body1'>
                                                    Vous ne disposez pas de l'offre Pack Digital
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}>
                                                <Button
                                                    onClick={() => navigate("/pack-digital")}
                                                    sx={{
                                                        width: {
                                                            sm: 'auto',
                                                            xs: '100%'
                                                        },
                                                        px: {
                                                            sm: 10,
                                                            xs: 0
                                                        },
                                                        mt: {
                                                            md: 4,
                                                            xs: 2
                                                        },
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}>
                                                    Découvrir le Pack Digital
                                                </Button>
                                            </Grid>
                                        </Grid>}

                                        {step !== PageSubscriptionSteps.ERROR_USER_OFFER_INCOMPATIBLE &&
                                        step !== PageSubscriptionSteps.ERROR_USER_WITHOUT_OFFER &&
                                        <Box
                                            sx={{
                                                display: {
                                                    md: 'none',
                                                    xs: 'block'
                                                },
                                                textAlign: 'center',
                                                mt: 5
                                            }}>
                                            <img src={IllustrationPageSubscription} alt={"Illustration Souscription"} loading="lazy" width='150px' />
                                        </Box>}
                                    </Box>
                                </Grid>

                                {!user &&
                                <Grid
                                    item
                                    xs={12}>
                                    <CardBecomeSubscriber />
                                </Grid>}

                                {product.plateformes?.length > 0 &&
                                <Grid
                                    item
                                    xs={12}>

                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="stretch"
                                        spacing={3}>
                                        {product.plateformes.filter(_ => _.isActive && _.type !== TypePlateforme.WEB).map((_) => {
                                            return (
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                    sx={{
                                                        maxWidth: '400px'
                                                    }}>
                                                    <CardPlateforme
                                                        plateforme={_} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>}

                            </Grid>

                        </Grid>

                        {/* Add padding bottom */}
                        <Grid
                            item
                            xs={12}>
                        </Grid>
                    </Grid>

                </Box>
                :
                <>
                <Grid
                    item
                    xs={12}>
                </Grid>
                <Grid
                    item
                    xs={12}>
                    <PageLoading />
                </Grid>
                </>}

                <Grid
                    item
                    xs={12}>
                    <Footer />
                </Grid>
            </Grid>

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider>
    );
}
