import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { colors } from "../../static/colors"
import { FruitIcon } from "../Icon/FruitIcon";


interface Props {
    description: string;
}


export const CardReviewGSMC = (props: Props) => {


    return (
        <Card
            sx={{
                height: '100%',
                minHeight: "260px",
                textAlign: 'center',
                backgroundColor: colors.grey.secondary,
                boxShadow: "0px 4px 20px 0px #00000028",
            }} >
            <Grid
                container
                direction="column"
                justifyContent='flex-start'
                spacing={0}
                sx={{
                    height: '100%',
                    pt: 4,
                    px: 3
                }}>
                <Grid
                    item>
                    <Typography
                        variant='h2'
                        sx={{
                            pb: 4
                        }}>
                        Avis de la GSMC
                    </Typography>
                </Grid>

                <Grid
                    item>
                    <Box>
                        <Typography
                            variant='body1'
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                whiteSpace: 'pre-wrap',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 8,
                            }}>
                            “{`${props.description}`}”
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            maxHeight: "80px",
                        }}>
                        <FruitIcon
                            color={colors.blue.main}
                            secondaryColor={colors.green.secondary}
                            bgColor={colors.blue.secondary}
                            width='100px'
                            height='80px'
                            showDetails />
                    </Box>
                </Grid>

            </Grid>
      </Card>
    )
}
