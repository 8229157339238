import { cloneElement } from "react";

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { colors } from "../../static/colors"


interface Props {
    label: string;
    icon?: JSX.Element;
    variant?: "text"|"contained"|"outlined";
    selected?: Boolean;
    disabled?: Boolean;
    onHover?: () => void;
    onClick: () => void;
}


export const RadioButton = (props: Props) => {


    const icon = props.icon ? cloneElement(props.icon, {selected: props.selected}) : undefined;


    return (
        <Button
            fullWidth
            variant={props.variant || "contained"}
            startIcon={icon || undefined}
            onClick={() => !props.disabled && props.onClick()}
            onMouseOver={() => !props.disabled && props.onHover && props.onHover()}
            sx={{
                cursor: props.disabled ? 'default' : 'pointer',
                color: props.disabled ? colors.grey.main : colors.black.main,
                background: 'white',
                ':hover': {
                    backgroundColor: 'white'
                },
            }} >
            <Typography variant='button' fontWeight={props.selected ? 900 : 400}>{props.label}</Typography>
        </Button>
    )
}
