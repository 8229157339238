import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";


interface Props {
    title: string;
    description?: string;
}


export const CardListEmpty = (props: Props) => {


    return (
        <Card
            sx={{
                height: '100%',
                width: '100%',
                textAlign: 'center',
                boxShadow: "0px 4px 20px 0px #00000028"
            }} >

            <CardContent
                sx={{
                    py: 0,
                    px: 2,
                    height: '100%'
                }} >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        height: '100%'
                    }} >

                    <Grid
                        item>

                        <Typography
                            component='div'
                            variant='h3'
                            fontWeight={700} >
                            {props.title}
                        </Typography>

                        {props.description &&
                        <Typography
                            component='div'
                            variant='body2' >
                            {props.description}
                        </Typography>}

                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}
