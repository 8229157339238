import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { colors } from "../../static/colors"


interface Props {
    id: number;
    label: string;
}


export const Step = (props: Props) => {


    return (
        <Grid
            container
            alignItems='center'
            spacing={2}
            sx={{
                textAlign: 'left'
            }}>
            <Grid
                item
                xs='auto'>
                <Typography
                    fontFamily='Poppins'
                    fontSize={{
                        sm: 140,
                        xs: 100
                    }}
                    fontWeight={700}
                    lineHeight={0.85}
                    sx={{
                        color: 'white',
                        textShadow: `0px 0px 12px ${colors.blue.main}`
                    }}>
                    {props.id}
                </Typography>
            </Grid>

            <Grid
                item
                xs>
                <Typography
                    variant='h3'
                    fontWeight={700}>
                    {props.label}
                </Typography>
            </Grid>
        </Grid>
    )
}
