/**
 *  Model Image.
*/


export enum ImageType {
    LOGO="LOGO",
    HEADER="HEADER",
    PREVIEW="PREVIEW",
    STATIC="STATIC"
}


export interface IImage {
    id?: number;
    type: ImageType;
    image: string;
    order: number;
    productId?: number;
}
