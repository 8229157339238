/**
 *  Model Section.
*/


import { IPage } from "./Page.model";
import { IProduct } from "./Product.model";


export enum SectionType {
    HIGHLIGHT="HIGHLIGHT"
}


export const sectionTypeOptions = [
    {
        value: SectionType.HIGHLIGHT,
        label: "Zoom sur"
    }
]


export interface ISection {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    dateStart?: Date;
    dateEnd?: Date;
    type: SectionType;
    title: string;
    description?: string;
    uri?: string;
    isActive: boolean;
    products: IProduct[];
}


export interface IPageSection extends IPage {
    content: ISection[];
}
