import { ILoginRequest,
         IResetPassword,
         ISignupRequest } from "../models/Authentication.model"
import { IUser } from "../models/User.model"


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const getTokenInfos = (token: string) => {
    return fetch(`${apiUrl}/auth/token/${token}/info`,
        {
            method: "GET"
        }).then(_ => _.json())
}


export const getAccount = (dateBirth: string, numDelegataire: string): Promise<IUser> => {

    return fetch(`${apiUrl}/auth/account?dateBirth=${dateBirth}&numDelegataire=${numDelegataire}`,
        {
            method: "GET",
        }).then(_ => _.json())
}


export const recoverAccount = (email: string) => {
    return fetch(`${apiUrl}/auth/account/recover`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(email)
        })
}


export const activateAccount = (signupRequest: ISignupRequest): Promise<IUser> => {
    return fetch(`${apiUrl}/auth/account/activate`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(signupRequest)
        }).then(_ => _.json())
}


export const changePassword = (resetPassword: IResetPassword) => {
    return fetch(`${apiUrl}/auth/account/password/change`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(resetPassword)
        })
}


export const resetPassword = (email: string) => {
    return fetch(`${apiUrl}/auth/account/password/reset`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(email)
        })
}


export const login = (log: ILoginRequest) => {
    return fetch(`${apiUrl}/auth/signin`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(log)
        })
}
