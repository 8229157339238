import { cloneElement, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Autocomplete from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { LensIcon } from '../../components/Icon/LensIcon';

import { IProduct, ProductType } from "../../models/Product.model";
import { ITheme } from "../../models/Theme.model";

import { searchProducts } from "../../api/Product.api";

import { getPageInfos, IPageInfos, Page } from "../../settings/Page";

import { colors } from "../../static/colors";


var debounce = require('debounce-promise');
const debouncedSearch = debounce(searchProducts, 500);


interface Props {
    page: Page;
    theme?: ITheme;
}


export const PageHeader = (props: Props) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const pageInfos: IPageInfos = getPageInfos(props.page);

    const [search, setSearch] = useState<string>()

    const [productSearch, setProductSearch] = useState<IProduct[]>([]);

    useEffect(() => {
        setSearch(undefined);
        setProductSearch([]);
    },[props.page])

    useEffect(() => {
        debouncedSearch(search, 10)
        .then((response) => {
            setProductSearch(response);
        })
        .catch(exception => setProductSearch([]));
    }, [search])


    const handleChange = (value: any) => {
        if (!value.code || !value.type)
            return;

        switch (value.type) {
            case ProductType.APPLICATION:
                navigate(`/applications/${value.code}`);
                return;

            case ProductType.OBJECT:
                navigate(`/objets/${value.code}`);
                return;

            case ProductType.SERVICE:
                navigate(`/services/${value.code}`);
                return;
        }
    }


    const headersInfos = [
        {
            page: Page.APPLICATIONS,
            title: <>Les meilleures <span style={{color: pageInfos.color}}>applications</span> santé</>
        },
        {
            page: Page.HOME,
            title: <>Découvrez les meilleures innovations en matière de <span style={{color: pageInfos.color}}>santé</span></>,
            description: "Applications, objets connectés, services santé, ... Parcourez nos différents catalogues !"
        },
        {
            page: Page.OBJECTS,
            title: <>Les meilleurs <span style={{color: pageInfos.color}}>objets</span> santé</>
        },
        {
            page: Page.PACK_DIGITAL,
            title: <>Prenez soin de votre santé avec le pack digital</>,
            action: <Button
                sx={{
                    width: {
                        sm: 'auto',
                        xs: '100%'
                    },
                    px: {
                        sm: 5,
                        xs: 0
                    },
                    color: 'white',
                    backgroundColor: colors.black.main,
                    boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                    ':hover': {
                        backgroundColor: 'black'
                    }
                }}
                onClick={() => navigate('/pack-digital')} >
                Souscrire à la mutuelle GSMC
            </Button>
        },
        {
            page: Page.SERVICES,
            title: <>Les meilleurs <span style={{color: pageInfos.color}}>services</span> santé</>
        },
        {
            page: Page.THEME,
            title: <>Toutes les innovations {props.theme && "“" + props.theme.name + "”"}</>
        },
    ]

    const headerInfos = headersInfos.find(_ => _.page === props.page);


    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={{
                lg: 10,
                sm: 5,
                xs: 2
            }}
            rowSpacing={2} >

            <Grid
                item
                xs
                sx={{
                    textAlign: 'left',
                    mt: {
                        md: headerInfos?.description ? 3 : 0,
                        xs: 0
                    }
                }} >

                <Typography
                    variant='h1'
                    sx={{
                        mb: 3
                    }} >
                    {headerInfos?.title || null}
                </Typography>

                <Typography
                    variant='subtitle2'
                    sx={{
                        mb: 3
                    }} >
                    {headerInfos?.description || null}
                </Typography>

                {headerInfos?.action &&
                <Box
                    sx={{
                        display: {
                            md: 'block',
                            xs: 'none'
                        }
                    }}>
                    {headerInfos.action}
                </Box>}

                {props.page !== Page.PACK_DIGITAL &&
                <Autocomplete
                    id="search_product"
                    freeSolo
                    options={productSearch}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) =>
                        option.name === "Aucun Résultat"
                    }
                    filterOptions={(options, state) => {
                        if (options.length === 0 && state.inputValue)
                            return [{
                                name: "Aucun Résultat",
                                tagline: "Essayez une autre recherche"
                            } as IProduct]

                        return options;
                    }}
                    onInputChange= {(e, v) => setSearch(v)}
                    onChange={(e, v) => handleChange(v)}
                    PaperComponent={({ children }) => (
                        <Paper
                            sx={{
                                mx: 1,
                                mt: 0.2,
                                borderRadius: 4
                            }}>
                            {children}
                        </Paper>
                    )}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            {...props}>
                            <Typography
                                variant="body1"
                                fontWeight={700}
                                component='div' >
                                {option.name}
                            </Typography>

                            {option.tagline &&
                            <Typography
                                variant="caption"
                                fontWeight={400}
                                component='div'
                                sx={{
                                    pt: 0.25
                                }}>
                                &nbsp;- {option.tagline}
                            </Typography>}
                        </Box>
                    )}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                ...params.InputProps,
                                placeholder: 'Essayez “Méditation”, “Tabac”, “Pack digital”, ...',
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{
                                            ml: 2
                                        }} >
                                        <LensIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />}
                    />}
            </Grid>

            {pageInfos.illustration &&
            <Grid
                item
                xs='auto'
                sx={{
                    display: {
                        md: 'block',
                        sm: props.page === Page.HOME ? 'none' : 'block',
                        xs: 'none'
                    }
                }} >
                <Box
                    sx={{
                        textAlign: 'center',
                        maxHeight: '100%'
                    }} >
                    {cloneElement(pageInfos.illustration, {height: screenSizeUpMD ? '240px' : screenSizeUpSM ? '120px' : '80px'})}
                </Box>
            </Grid>}

            {headerInfos?.action &&
            <Grid
                item
                xs={12}
                sx={{
                    display: {
                        md: 'none',
                        xs: 'block'
                    },
                    textAlign: 'center'
                }}>
                {headerInfos.action}
            </Grid>}
        </Grid>
    )
}
