/**
 *  Model Theme.
*/
import { AddictionIcon } from "../components/Icon/Theme/AddictionIcon";
import { FoodIcon } from "../components/Icon/Theme/FoodIcon";
import { MedicationIcon } from "../components/Icon/Theme/MedicationIcon";
import { MeditationIcon } from "../components/Icon/Theme/MeditationIcon";
import { MentalHealthIcon } from "../components/Icon/Theme/MentalHealthIcon";
import { SleepIcon } from "../components/Icon/Theme/SleepIcon";
import { SportIcon } from "../components/Icon/Theme/SportIcon";
import { StarIcon } from "../components/Icon/Theme/StarIcon";

import { IPage } from "./Page.model";


export enum ThemeCode {
    ADDICTIONS = "ADDICTIONS",
    DIGITAL_PACK = "DIGITAL_PACK",
    FOOD = "FOOD",
    MEDICATION = "MEDICATION",
    MEDITATION = "MEDITATION",
    MENTAL_HEALTH = "MENTAL_HEALTH",
    OTHER = "OTHER",
    SLEEP = "SLEEP",
    SPORT = "SPORT"
}


export interface ITheme {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    code: string;
    name: string;
    order?: number;
    isActive?: boolean;
}


export interface IPageTheme extends IPage {
    content: ITheme[]
}


export const themesIcons: {themeCode: ThemeCode; icon: JSX.Element;}[] = [
    {
        themeCode: ThemeCode.DIGITAL_PACK,
        icon: <StarIcon />,
    },
    {
        themeCode: ThemeCode.MEDITATION,
        icon: <MeditationIcon />
    },
    {
        themeCode: ThemeCode.SLEEP,
        icon: <SleepIcon />
    },
    {
        themeCode: ThemeCode.ADDICTIONS,
        icon: <AddictionIcon />
    },
    {
        themeCode: ThemeCode.SPORT,
        icon: <SportIcon />
    },
    {
        themeCode: ThemeCode.FOOD,
        icon: <FoodIcon />
    },
    {
        themeCode: ThemeCode.MEDICATION,
        icon: <MedicationIcon />
    },
    {
        themeCode: ThemeCode.MENTAL_HEALTH,
        icon: <MentalHealthIcon />
    },
    {
        themeCode: ThemeCode.OTHER,
        icon: <MeditationIcon />
    },
]


export const getThemeIcon = (themeCode: string): JSX.Element | undefined => {

    let icon = themesIcons.find(_ => _.themeCode.toString() === themeCode);

    return icon?.icon;
}
