import { useNavigate } from 'react-router-dom';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ChipOffer } from "../Chip/ChipOffer";
import { ChipTheme } from "../Chip/ChipTheme";

import { IImage, ImageType } from "../../models/Image.model";
import { getProductTypeInfos, IProduct, IProductTypeInfos } from "../../models/Product.model";

import { colors } from "../../static/colors"


interface Props {
    product: IProduct;
    fullColor?: Boolean;
}


export const CardProductHighlight = (props: Props) => {

    const navigate = useNavigate();

    const logo: IImage | undefined = props.product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0];
    const preview: IImage | undefined = props.product.images.filter(_ => _.type === ImageType.PREVIEW).sort((a, b) => a.order - b.order)[0];


    return (
        <Card
            sx={{
                position: 'relative',
                height: '100%',
                color: 'white',
                borderRadius: 8,
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: "0px 4px 20px 0px #3F8CD860",
                }
            }} >
            <CardActionArea
                sx={{
                    height: '100%'
                }}
                onClick={() => {
                    const productTypeInfos: IProductTypeInfos = getProductTypeInfos(props.product.type);
                    navigate(`${productTypeInfos.uri}/${props.product.code}`);
                }}>

                {logo &&
                <Box
                    sx={{
                        zIndex: 'modal',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        m: 2
                    }}>
                    <CardMedia
                        component="img"
                        src={logo.image}
                        sx={{
                            height: '50px',
                            width: '50px',
                            borderRadius: 2
                        }} />
                </Box>}

                <Button
                    variant="contained"
                    sx={{
                        zIndex: 'modal',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        py: 0.75,
                        px: {
                            sm: 1.5,
                            xs: 0.75
                        },
                        m: 2,
                        color: 'white',
                        backgroundColor: colors.black.main,
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }}
                    onClick={(_) => {
                        _.stopPropagation();  // Stop the event propagation (ActionArea).
                        const productTypeInfos: IProductTypeInfos = getProductTypeInfos(props.product.type);
                        navigate(`${productTypeInfos.uri}/${props.product.code}/obtenir`);
                    }}>
                    Obtenir
                </Button>

                <Grid
                    container
                    direction="row"
                    sx={{
                        height: '100%'
                    }}>
                    <Grid
                        item
                        sm={preview ? 6 : 12}
                        xs={12}
                        sx={{
                            textAlign: 'left',
                            py: 4,
                            pl: 2,
                            pr: {
                                sm: 2,
                                xs: 10
                            },
                            backgroundColor: props.product.color || colors.green.main
                        }}>

                        <Box
                            sx={{
                                pl: 1
                            }}>
                            <Typography variant='h3' fontWeight={700}>
                                {props.product.name}
                            </Typography>

                            <Typography variant='caption'>
                                {props.product.tagline}
                            </Typography>

                            <Grid
                                container
                                rowSpacing={0.5}
                                columnSpacing={2}
                                sx={{
                                    mt: 1,
                                    pr: {
                                        sm: preview ? 0 : 10,
                                        xs: 0
                                    }
                                }}>
                                {props.product.themes.map((_) => {
                                    return (
                                        <Grid
                                            item
                                            md="auto"
                                            xs={12}>
                                            <ChipTheme
                                                theme={_}
                                                color='white' />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>

                        {props.product.userOffer &&
                        <Grid
                            container
                            direction="row" >
                            <Grid
                                item
                                xs='auto'>
                                <ChipOffer
                                    offer={props.product.userOffer}
                                    color={colors.orange.secondary} />
                            </Grid>
                        </Grid>}
                    </Grid>

                    {preview &&
                    <Grid
                        item
                        sm={6}
                        sx={{
                            display: {
                                sm: 'block',
                                xs: 'none'
                            },
                            backgroundColor: props.fullColor ? props.product.color || colors.green.main : props.product.colorSecondary || "#F0FFF6"
                        }}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="flex-end"
                            sx={{
                                overflow: 'hidden',
                                height: '100%'
                            }} >
                            <Grid
                                item
                                xs='auto'
                                sx={{
                                    px: 4,
                                    borderTopLeftRadius: 2,
                                    borderTopRightRadius: 2
                                }} >
                                <img
                                    src={preview.image}
                                    loading="lazy"
                                    style={{
                                        verticalAlign: 'bottom',
                                        maxHeight: '150px'
                                    }}/>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            </CardActionArea>
      </Card>
    )
}
