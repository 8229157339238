import { cloneElement } from "react";

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { colors } from "../../static/colors"


interface Props {
    label: string;
    icon?: JSX.Element;
    selected?: Boolean;
    disabled?: Boolean;
    onHover?: () => void;
    onClick: () => void;
}


export const ButtonTheme = (props: Props) => {


    const icon = props.icon ? cloneElement(props.icon, {selected: props.selected}) : undefined;


    return (
        <Button
            fullWidth
            variant="contained"
            startIcon={icon}
            onClick={() => !props.disabled && props.onClick()}
            onMouseOver={() => !props.disabled && props.onHover && props.onHover()}
            sx={{
                cursor: props.disabled ? 'default' : 'pointer',
                justifyContent: 'left',
                pl: 4,
                color: props.disabled ? colors.grey.main : colors.black.main,
                background: 'white',
                boxShadow: props.selected ? "0px 4px 20px 0px #3F8CD860" : "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    backgroundColor: 'white',
                    boxShadow: "0px 4px 20px 0px #3F8CD860"
                }
            }} >
            <Typography variant='button' fontWeight={props.selected ? 900 : 400}>{props.label}</Typography>
        </Button>
    )
}
