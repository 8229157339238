import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Button from "@mui/material/Button";

import { FruitIcon } from "../Icon/FruitIcon";


interface Props {
    user?: any;
    color?: string;
    secondaryColor?: string;
    onClick?: () => void;
}


export const ButtonUser = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));


    return (
        <Button
            variant={props.user ? 'outlined' : 'contained'}
            sx={{
                color: props.user ? 'primary' : 'white',
                border: props.user ? 3 : 0,
                py: {
                    md: props.user ? 0.5 : 2,
                    xs: props.user ? 0 : 1
                },
                ":hover": {
                    border: props.user ? 3 : 0,
                }
            }}
            onClick={() => props.onClick ? props.onClick() : {}}
            startIcon={props.user && <FruitIcon color={props.color || undefined} secondaryColor={props.secondaryColor || undefined} />}>
            {props.user ?
                screenSizeUpMD ?
                props.user.firstname + " " + props.user.lastname.substring(0, 1) :
                props.user.firstname.substring(0, 1) + props.user.lastname.substring(0, 1) : 'Espace Adhérent'}
        </Button>
    )
}
