
import { defaultIconColor, defaultIconSize, IIcon } from "./settings"


export const MenuIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 32 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2.0293 5H29.0293" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "4" : "3"} strokeLinecap="round"/>
            <path d="M2.0293 15H29.0293" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "4" : "3"} strokeLinecap="round"/>
            <path d="M2.0293 25H29.0293" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "4" : "3"} strokeLinecap="round"/>
        </svg>
    )
}
