
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from '@mui/icons-material/Close';

import { IImage, ImageType } from "../../models/Image.model";

import { colors } from "../../static/colors";


interface Props {
    image: IImage;
    handleChangeType: (event: SelectChangeEvent) => void;
    handleChangeOrder: (order: number) => void;
    handleDelete: (image: IImage) => void;
}


export const FieldImage = (props: Props) => {

    const getMenuItems = () => {

        const toReturn: any[] = [];
        for (const value in ImageType) {
            toReturn.push(
                <MenuItem
                    key={value}
                    value={value}>
                    {value}
                </MenuItem>)
        }
        return toReturn;
    }


    return (
        <Card
            sx={{
                height: '100%',
                position: 'relative',
                p: 1,
                border: 1,
                borderColor: colors.grey.main
            }} >

            <IconButton
                sx={{
                    position: 'absolute',
                    right: 0,
                    mr: 1
                }}
                onClick={() => props.handleDelete(props.image)}>
                <CloseIcon
                    sx={{
                        color: colors.black.main
                    }} />
            </IconButton>

            <Grid
                container
                justifyContent="center"
                alignItems="flex-end"
                spacing={1}
                sx={{
                    height: '100%'
                }}>

                <Grid
                    item
                    xs='auto'>

                    {props.image ?
                        <CardMedia
                            component="img"
                            src={props.image.image}
                            sx={{
                                px: 1,
                                borderRadius: 2,
                                height: '120px'
                            }} />
                        :
                        <Box
                            sx={{
                                backgroundColor: colors.grey.secondary,
                                borderRadius: 2,
                                width: '120px'
                            }} />
                    }
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Select
                        fullWidth
                        labelId="type"
                        id="type"
                        value={props.image.type}
                        onChange={props.handleChangeType}
                        input={<OutlinedInput label="Type" />} >
                        {getMenuItems()}
                    </Select>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                        spacing={2}>
                        <Grid
                            item
                            xs='auto'>
                            <Typography
                                component='span'
                                fontWeight={700}>
                                Ordre :
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs>
                            <TextField
                                fullWidth
                                id="order"
                                value={props.image.order !== null ? props.image.order.toString() : undefined}
                                type="number"
                                onChange={(e) => {
                                    props.handleChangeOrder(parseInt(e.target.value))
                                }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}
