import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const AddictionIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 28 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <rect x="1.17188" y="16.5927" width="24.312" height="4.62963" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round" />
        <path d="M6.78223 16.5927V21.2223" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinecap="round"/>
        <path d="M21.957 13.1148L22.0135 13.1752C22.8423 14.0598 24.2111 14.1605 25.1604 13.4068L25.2094 13.3679C25.963 12.7696 26.3594 11.8282 26.2607 10.8711L26.2446 10.7142C26.1847 10.1333 25.9222 9.59196 25.5031 9.18525L24.613 8.32148C23.3846 7.12949 22.735 5.4625 22.8328 3.75368L22.9459 1.77783L21.6167 5.09462C21.2704 5.95881 21.2822 6.92529 21.6495 7.78077L21.9574 8.49786C22.2401 9.15632 22.1655 9.91377 21.7598 10.5044C21.2043 11.3129 21.2864 12.399 21.957 13.1148Z" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round" strokeLinecap="round" />
        </svg>
    )
}
