import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const MedicationIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 31 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <rect x="1.35985" y="0.408926" width="8.07677" height="21.0512" rx="4.03838" transform="matrix(0.882717 -0.469906 0.477132 0.878832 5.15676 4.42366)" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"}/>
        <path d="M12.1099 13.5479L18.7811 9.99651" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinecap="round"/>
        </svg>
    )
}
