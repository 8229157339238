import { PublicClientApplication } from "@azure/msal-browser";

import { IProductOffer } from "../models/ProductOffer.model";
import AuthService from "../service/AuthService";

import { msalConfig } from "../settings/authConfig";


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const authService = new AuthService();


export const getProductOffers = (id: number): Promise<IProductOffer[]> => {

    return fetch(`${apiUrl}/product/${id}/offer`,
        {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const getProductOffer = (id: number, code: string): Promise<IProductOffer> => {

    return fetch(`${apiUrl}/product/${id}/offer/${code}`,
        {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const getProductOfferUser = (): Promise<IProductOffer[]> => {

    return fetch(`${apiUrl}/product/offer/user`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({
                "content-type": "application/json"
            })
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const saveRelationProductOffer = (values: any): Promise<IProductOffer> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/product/offer`,
            {
                method: "POST",
                headers: {
                    "Authorization": accessTokenResponse.idToken,
                    "content-type": "application/json"
                },
                body: JSON.stringify(values)
            })
            .then(_ => {
                if(_.status === 200) return _.json()
                else throw _
            })
    });
}
