import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import CloseIcon from '@mui/icons-material/Close';

import { IImage } from "../../models/Image.model";

import { colors } from "../../static/colors"


interface Props {
    image: IImage;
}

interface DialogProps {
    image: IImage;
    open: boolean;
    handleClose: () => void;
}

const DialogImage = (props: DialogProps) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <Dialog
            maxWidth={'md'}
            open={props.open}
            fullScreen={fullScreen}
            onClose={() => props.handleClose()}
            sx={{
                height: '100%'
            }}>

            <DialogTitle
                sx={{
                    my: 2
                }} >
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{
                    minWidth: {
                        lg: '800px',
                        md: '700px'
                    }
                }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '80vh'
                    }}>
                    <Grid
                        item>
                        <CardMedia
                            component="img"
                            src={props.image.image}
                            sx={{
                                maxHeight: '80vh'
                            }}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>)
}


export const CardImage = (props: Props) => {

    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
        <DialogImage open={open} handleClose={() => setOpen(false)} image={props.image}/>
        <Card
            sx={{
                backgroundColor: colors.grey.light,
                boxShadow: "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: "0px 4px 20px 0px #3F8CD860"
                }
            }} >
            <CardActionArea
                sx={{
                    height: '100%'
                }}
                onClick={() => {
                    setOpen(true)
                }} >
                <CardMedia
                    component="img"
                    src={props.image.image}
                    sx={{
                        height: {
                            md: '320px',
                            sm: '280px',
                            xs: '240px'
                        }
                    }} />

            </CardActionArea>
        </Card>
        </>
    )
}
