import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { CheckIcon } from '../Icon/CheckIcon';

import { consumeProduct } from '../../api/ProductConsumption.api';
import { validateSms } from '../../api/Sms.api';

import { IProduct } from '../../models/Product.model';
import { ISms } from '../../models/Sms.model';
import { IUser } from '../../models/User.model';

import { colors } from '../../static/colors';


interface Props {
    title: string;
    user: IUser;
    product: IProduct;
    goToPreviousStep: () => void;
    goToNextStep: () => void;
}


const codeLength: number = 6;
const codeExpirationMinutes: number = 15;


export const FormCode = (props: Props) => {

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const [sms, setSms] = useState<ISms>();

    const validationSchema = yup.object({
        code: yup
            .string()
            .test('length', 'Code incomplet', value => value !== undefined && value !== null && value.length === codeLength)
            .nullable()
            .required("Champ obligatoire")
    });

    const formik = useFormik({
        initialValues: {
            code: undefined
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!sms?.id) {
                formik.setFieldError("code", "L'envoi du SMS a échoué. Veuillez recommencer.");
                return;
            }

            if (values.code) {
                validateSms(sms.id, values.code)
                .then((response: string) => {
                    props.goToNextStep();
                })
                .catch((exception) => {
                   exception.text().then(_ => 
                        formik.setFieldError("code", _));
                });
            }
        }
    });


    const sendProductConsumption = () => {
        if (!props.product.id)
            return;

        consumeProduct(props.product.id).then((response: ISms) => {
            setSms(response);
        });

        setIsSnackbarOpened(true);
    }


    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };


    useEffect(() => {
        sendProductConsumption();
    }, [])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Snackbar
                sx={{
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    sx={{
                        textAlign: 'left',
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='success'
                    iconMapping={{
                        success: <CheckIcon color={colors.green.main} />,
                    }} >
                    <Typography fontWeight={500} >
                        Le code que vous devez saisir vous a été envoyé par SMS. Il est valable pendant {codeExpirationMinutes} min.
                    </Typography>
                </Alert>
            </Snackbar>

            <Grid
                container
                justifyContent="space-around"
                spacing={3}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        mb: {
                            md: 4,
                            xs: 2
                        }
                    }}>
                    <Typography
                        variant='h2'>
                        {props.title}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'left'
                    }}>
                    <Typography
                        variant='body1'>
                        Vous avez reçu un code par SMS
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="code"
                        name="code"
                        placeholder="Votre code"
                        value={formik.values.code}
                        onChange={(e) => formik.setFieldValue("code", e.target.value)}
                        onBlur={(e) => formik.setFieldTouched("code")}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'left'
                    }}>
                    <Link
                        component='button'
                        type='none'
                        onClick={() => {
                            sendProductConsumption();
                        }}
                        sx={{
                            textAlign: 'left',
                            color: colors.purple.main,
                            textDecoration: "underline",
                            mb: 1
                        }}>
                        Vous n’avez pas reçu le code ? Cliquez sur ce lien pour en recevoir un nouveau !
                    </Link>
                    <br />
                    <Link
                        component='button'
                        type='none'
                        onClick={() => {
                            props.goToPreviousStep();
                        }}
                        sx={{
                            textAlign: 'left',
                            color: colors.purple.main,
                            textDecoration: "underline",
                        }}>
                        Vérifier votre numéro de téléphone
                    </Link>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Button
                        type="submit"
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            mt: {
                                md: 4,
                                xs: 2
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Obtenir {props.product.name}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
