import { IUser } from "../models/User.model"

import AuthService from "../service/AuthService"


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'
const authService = new AuthService();


export const getUser = () : Promise<IUser> => {

    return fetch(`${apiUrl}/user`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authService.getToken()
            }
        })
        .then(_ => {
            if(_.status === 200)
                return _.json()
            throw "Forbidden"
        })
}


export const updateUser = (user: IUser) : Promise<IUser> => {

    return fetch(`${apiUrl}/user`,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authService.getToken()
            },
            body: JSON.stringify(user)
        })
        .then(_ => {
            if(_.status === 200)
                return _.json()
            throw "Forbidden"
        })
}
