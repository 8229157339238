import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// import { ButtonChatBot } from '../../components/Button/ButtonChatBot';
import { CarouselProduct } from '../../components/Carousel/CarouselProduct';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import { ArrowRightLongCurvedIcon } from '../../components/Icon/ArrowRightLongCurvedIcon';
import { PageHeader } from '../../components/Page/PageHeader';
import { Step } from '../../components/Step/Step';
import { TitleSection } from '../../components/Title/TitleSection';

import { Page } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';
import imageStatic1 from '../../static/img/packdigital/packdigitalStatic1.png';
import imageStatic2 from '../../static/img/packdigital/packdigitalStatic2.png';
import PageSubscriberDesktop from '../../static/img/packdigital/pageSubscriberDesktop.png';
import PageSubscriberMobile from '../../static/img/packdigital/pageSubscriberMobile.png';


export const PagePackDigital = () => {

    const page: Page = Page.PACK_DIGITAL;

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [])

    return (
        <ThemeProvider theme={theme}>

            <Header
                page={page} />

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    mx: 'auto',
                    mt: 2,
                }} >
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={3}
                    sx={{
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        xs={12}
                        sx={{
                            mb: 2
                        }}>
                        <PageHeader page={page} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>

                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3} >

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h2'
                                    sx={{
                                        mb: 4
                                    }}>
                                    Des avantages illimités !!!
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Avec le pack de la mutuelle GSMC, vous bénéficiez :
                                </Typography>
                                <ul>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}>
                                        <Typography variant='body2'>d’abonnements gratuits à des applications bien-être</Typography>
                                    </li>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}>
                                        <Typography variant='body2'>de réductions sur des objets connectés liés à la santé</Typography>
                                    </li>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}>
                                        <Typography variant='body2'>de services santé gratuits</Typography>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}>
                                <CardMedia
                                    component="img"
                                    src={imageStatic1}
                                    sx={{
                                        display: 'inline',
                                        maxWidth: {
                                            md: '100%',
                                            xs: '500px'
                                        },
                                        borderRadius: '8px',
                                    }} />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                                order={{
                                    md: 0,
                                    xs: 1
                                }}
                                sx={{
                                    textAlign: 'center'
                                }}>
                                <CardMedia
                                    component="img"
                                    src={imageStatic2}
                                    sx={{
                                        display: 'inline',
                                        maxWidth: {
                                            md: '100%',
                                            xs: '500px'
                                        },
                                        borderRadius: '8px',
                                    }} />
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h2'
                                    sx={{
                                        mb: 4
                                    }}>
                                    Composez votre pack selon vos envies !
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                </Typography>
                                <ul>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}><Typography variant='body2'>“Kwit premium” gratuit pendant 1 an</Typography></li>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}><Typography variant='body2'>“Holivia” gratuit pendant 1 an</Typography></li>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}><Typography variant='body2'>“PetitBambou” gratuit pendant 6 mois</Typography></li>
                                    <li style={{ color: colors.grey.main, marginBottom: "16px" }}><Typography variant='body2'>“Morphee” à 30€ au lieu de 80€ </Typography></li>
                                </ul>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* Section : Tuto get pack digital */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: 3
                        }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3} >

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    variant='h2'>
                                    Comment obtenir le pack ?
                                </Typography>
                            </Grid>
        
                            <Grid
                                item
                                md={6}
                                xs={12}
                                sx={{
                                    textAlign: 'right'
                                }}>
                                <Box
                                    sx={{
                                        pl: {
                                            md: 8,
                                            xs: 0
                                        },
                                        mx: {
                                            md: 0,
                                            xs: 2
                                        }
                                    }}>
                                    <Step
                                        id={1}
                                        label="Assurez-vous à la mutuelle GSMC avec l’offre de votre choix" />
                                </Box>
                            </Grid>
        
                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="stretch"
                                    spacing={{
                                        md: 2,
                                        xs: 0
                                    }}
                                    sx={{
                                        mt: {
                                            md: 1,
                                            xs: 0
                                        }
                                    }}>
        
                                    <Grid
                                        item
                                        md={12}
                                        xs='auto'
                                        order={{
                                            md: 0,
                                            xs: 1
                                        }}
                                        sx={{
                                            display: {
                                                md: 'block',
                                                xs: 'none'
                                            }
                                        }}>
                                        <ArrowRightLongCurvedIcon color={colors.blue.main} />
                                    </Grid>
        
                                    <Grid
                                        item
                                        md={12}
                                        xs='auto'>
                                        <Chip
                                            label={<Typography variant="button">Souscrire à la mutuelle</Typography>}
                                            sx={{
                                                ml: {
                                                    md: 16,
                                                    xs: 0
                                                },
                                                fontWeight: 900,
                                                color: 'white',
                                                backgroundColor: colors.black.main
                                            }} />
                                        <br />
                                        <Chip
                                            label={<Typography variant="button">Suivre à mon employeur</Typography>}
                                            sx={{
                                                mt: 2,
                                                ml: {
                                                    md: 24,
                                                    sm: 8,
                                                    xs: 4
                                                },
                                                fontWeight: 900,
                                                color: 'white',
                                                backgroundColor: colors.black.main
                                            }} />
                                    </Grid>
        
                                </Grid>
                            </Grid>
        
                            <Grid
                                item
                                md={7}
                                xs={12}
                                order={{
                                    md: 0,
                                    xs: 3
                                }}
                                sx={{
                                    position: 'relative',
                                    textAlign: 'center',
                                    top: {
                                        md: -50,
                                        xs: 0
                                    }
                                }} >

                                <Box
                                    sx={{
                                        display: {
                                            md: 'block',
                                            xs: 'none'
                                        }
                                    }}>
                                    <img src={PageSubscriberDesktop} alt={"Page adhérent"} loading="lazy" width="100%" />
                                </Box>

                                <Box
                                    sx={{
                                        display: {
                                            md: 'none',
                                            xs: 'block'
                                        }
                                    }}>
                                    <img src={PageSubscriberMobile} alt={"Page adhérent"} loading="lazy" />
                                </Box>

                            </Grid>
        
                            <Grid
                                item
                                md={5}
                                xs={12}
                                order={2}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                        height: '100%',
                                        pl: {
                                            md: 6,
                                            xs: 0
                                        }
                                    }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            mx: {
                                                md: 0,
                                                xs: 2
                                            }
                                        }}>
                                        <Step
                                            id={2}
                                            label="Rendez-vous dans votre espace adhérent" />
                                    </Grid>
        
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            position: 'relative'
                                        }}>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                left: -30,
                                                top: -60,
                                                display: {
                                                    md: 'block',
                                                    xs: 'none'
                                                },
                                                transform: 'rotate(95deg)'
                                            }}>
                                            <ArrowRightLongCurvedIcon color={colors.blue.main} />
                                        </Box>
                                    </Grid>
        
                                </Grid>
        
                            </Grid>
        
                            <Grid
                                item
                                md={4}
                                order={3}
                                sx={{
                                    position: 'relative',
                                    display: {
                                        md: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        transform: 'rotate(200deg)',
                                        mr: 6
                                    }}>
                                    <ArrowRightLongCurvedIcon color={colors.blue.main} />
                                </Box>
                            </Grid>
        
                            <Grid
                                item
                                md={6}
                                xs={12}
                                order={3}
                                sx={{
                                    mx: {
                                        md: 0,
                                        xs: 2
                                    }
                                }}>
                                <Step
                                    id={3}
                                    label="Consommez votre pack digital selon vos envies !" />
                            </Grid>
        
                            <Grid
                                item
                                order={3}
                                md={2}
                                xs={12}>
                            </Grid>

                        </Grid>

                    </Grid>

                    {/* Section : GSMC values */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: 3
                        }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={4} >

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    variant='h2'
                                    sx={{
                                        mb: 4
                                    }}>
                                    Les valeurs de la mutuelle GSMC
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h3'
                                    sx={{
                                        mb: 2
                                    }}>
                                    Valeur 1
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h3'
                                    sx={{
                                        mb: 2
                                    }}>
                                    Valeur 2
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h3'
                                    sx={{
                                        mb: 2
                                    }}>
                                    Valeur 3
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}>
                                <Typography
                                    variant='h3'
                                    sx={{
                                        mb: 2
                                    }}>
                                    Valeur 4
                                </Typography>

                                <Typography
                                    variant='body2'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    textAlign: 'center',
                                    mt: {
                                        md: 4,
                                        xs: 2
                                    }
                                }}>
                                <Button
                                    sx={{
                                        width: {
                                            sm: 'auto',
                                            xs: '100%'
                                        },
                                        px: {
                                            sm: 10,
                                            xs: 0
                                        },
                                        color: 'white',
                                        backgroundColor: colors.black.main,
                                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                                        ':hover': {
                                            backgroundColor: 'black'
                                        }
                                    }}
                                    onClick={() => {}} >
                                    En savoir plus
                                </Button>
                            </Grid>

                        </Grid>

                    </Grid>

                    
                    {/* Section : Best products */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: 3
                        }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={4} >

                            <Grid
                                item
                                xs={12}>
                                <TitleSection
                                    content={
                                        <Box
                                            component='span'>
                                            Les innovations préférées
                                            <Box
                                                component='span'
                                                sx={{
                                                    display: {
                                                        md: 'inline',
                                                        xs: 'none'
                                                    }
                                                }}>
                                                &nbsp;des adhérents GSMC
                                            </Box>
                                        </Box>} />
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <CarouselProduct
                                    showRank />
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>

            </Box>

        <Footer />

        {/* COMMENT : ChatBot in stand-by
        <ButtonChatBot /> */}

        </ThemeProvider>
    );
}
