import { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { DatePicker } from '@mui/lab';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';

import { PageLoginSteps } from '../../../pages/Login/PageLogin';

import { getAccount } from '../../../api/Authentication.api';

import { IUser } from '../../../models/User.model';

import { colors } from '../../../static/colors';


interface Props {
    user?: IUser;
    send: (user: IUser) => void;
    goToStep: (step: PageLoginSteps) => void;
}


export const FormUsername = (props: Props) => {

    const getInitialValues = () => {
        return {
            subscriberId: props.user?.numDelegataire,
            dateBirth: props.user?.birthDate || null,
        }
    };

    const validationSchema = yup.object({
        subscriberId: yup.string().required('Champ obligatoire'),
        dateBirth: yup.date().nullable().typeError("Date non valide").required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (values.dateBirth === null || !values.subscriberId)
                return;  // Should never occur with the yup validation.

            const date = values.dateBirth.getDate() + "-" +
                         ('0' + (values.dateBirth.getMonth() + 1)).slice(-2) + "-" +
                         values.dateBirth.getFullYear();

            getAccount(date, values.subscriberId)
            .then(response => {
                props.send({...response, birthDate: new Date(parseInt(response?.birthDate.toString()))});

                if (!response.isActive)
                    props.goToStep(PageLoginSteps.ACCOUNT_ACTIVATION);
                else
                    props.goToStep(PageLoginSteps.PASSWORD);

            })
            .catch((exception) => {
                formik.setFieldError("dateBirth", "Utilisateur inconnu");
                return;
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: {
                            md: 'left',
                            xs: 'center'
                        }
                    }}>
                    <Typography variant='h1'>Bonjour !</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="subscriberId"
                        name="subscriberId"
                        placeholder="Votre numéro d’adhérent"
                        value={formik.values.subscriberId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.subscriberId && Boolean(formik.errors.subscriberId)}
                        helperText={formik.touched.subscriberId && formik.errors.subscriberId} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} >
                        <DatePicker
                            value={formik.values.dateBirth}
                            onChange={(value) => formik.setFieldValue("dateBirth", value)}
                            cancelText="Annuler"
                            okText="Valider"
                            toolbarTitle="Votre date de naissance"
                            inputFormat="dd/MM/yyyy"
                            renderInput={
                                (params) =>
                                    <TextField
                                        fullWidth
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Votre date de naissance"
                                        }}
                                        onBlur={(e) => formik.setFieldTouched("dateBirth")}
                                        error={formik.touched.dateBirth && Boolean(formik.errors.dateBirth)}
                                        helperText={formik.touched.dateBirth && formik.errors.dateBirth}
                                    />
                            } />
                    </LocalizationProvider>
                </Grid>

                {/* <Grid
                    item
                    sm={6}
                    xs={12}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checkedIcon={undefined}
                            icon={undefined}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 28
                                }
                            }}/>}
                        label={
                            <Typography
                                noWrap
                                variant='body2'
                                fontWeight={700}
                                color={colors.black.secondary}>
                                Se souvenir de moi
                            </Typography>} />
                </Grid> */}

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 3,
                        sm: 2
                    }}
                    sx={{
                        textAlign: {
                            sm: 'right',
                            xs: 'center'
                        }
                    }}>
                    <Link
                        onClick={() => props.goToStep(PageLoginSteps.USERNAME_FORGOTTEN)}>
                        <Typography
                            noWrap
                            component='span'
                            variant='body2'
                            fontWeight={700}
                            color={colors.black.secondary}>
                            Numéro adhérent oublié ?
                        </Typography>
                    </Link>
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        type='submit'
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Se connecter
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
