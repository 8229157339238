import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { DownloadIcon } from "../Icon/DownloadIcon"
import { CheckIcon } from "../Icon/CheckIcon"
import { StarIcon } from "../Icon/Theme/StarIcon"

import { updateProductDownload } from "../../api/Product.api"

import { IImage, ImageType } from "../../models/Image.model"
import { IProduct, ProductType } from "../../models/Product.model"
import { ProductConsumptionStatus } from "../../models/ProductConsumption.model"

import { colors } from "../../static/colors"


interface Props {
    product: IProduct;
}


export const HeaderProduct = (props: Props) => {

    const navigate = useNavigate();

    const logo: IImage | undefined = props.product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0];

    // Get the number of download of the product (click on button "Obtenir...") + the number of download of the plateformes (click on buttons IOS...).
    const nbDownload: number = props.product.nbDownload + (props.product.plateformes.length > 0 ? props.product.plateformes.map(_ => _.nbDownload || 0).reduce((x, y) => x + y) : 0);

    return (
        <Box>
            <Grid
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
                spacing={3}>

                <Grid
                    item
                    xs={12} >
                    <Button
                        variant='contained'
                        color='inherit'
                        sx={{
                            p: 1.5,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #FFFFFF88, 0px -4px 10px 0px #FFFFFF88",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}
                        onClick={() => navigate(-1)}>
                        <Typography variant='h4'>
                            {"< Retour"}
                        </Typography>
                    </Button>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }} >
                    <Typography
                        variant='h1'
                        color='white'
                        sx={{
                            color: 'white',
                            textShadow: `1px 2px 5px ${colors.black.main}`
                        }}>
                        {props.product.name}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        mt: 2
                    }} >
                    <Card
                        sx={{
                            boxShadow: "0px 4px 20px 0px #00000028"
                        }}>
                        <Box
                            sx={{
                                px: 2,
                                py: {
                                    md: 2,
                                    xs: 3
                                }
                            }}>
                            <Grid
                                container
                                spacing={2}>
                                {logo &&
                                <Grid
                                    item
                                    xs='auto'
                                    sx={{
                                        display: {
                                            md: 'block',
                                            xs: 'none'
                                        }
                                    }}>
                                    <CardMedia
                                        component="img"
                                        src={logo.image}
                                        sx={{
                                            borderRadius: 2,
                                            width: '120px',
                                            height: '120px'
                                        }} />
                                </Grid>}

                                <Grid
                                    item
                                    xs>
                                    <Grid
                                        container
                                        spacing={1}
                                        justifyContent='center'
                                        alignItems='center'>
                                        {logo &&
                                        <Grid
                                            item
                                            xs='auto'
                                            sx={{
                                                display: {
                                                    md: 'none',
                                                    xs: 'block'
                                                }
                                            }}>
                                            <CardMedia
                                                component="img"
                                                src={logo.image}
                                                sx={{
                                                    borderRadius: '8px',
                                                    width: '50px',
                                                    height: '50px'
                                                }} />
                                        </Grid>}

                                        <Grid
                                            item
                                            xs
                                            alignItems="end">
                                            <Typography variant='h2' sx={{pt: 'auto'}}>{props.product.tagline || props.product.name}</Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12} >
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    whiteSpace: 'pre-wrap'
                                                }}>
                                                {props.product.description}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            sm
                                            xs={12} >
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                alignItems='flex-end'
                                                spacing={2}>

                                                <Grid
                                                    item
                                                    sm
                                                    xs={12} >
                                                    <Grid
                                                        container
                                                        justifyContent="flex-start"
                                                        alignItems='center'
                                                        spacing={2}>

                                                        {props.product.reviewNote &&
                                                        <Grid
                                                            item
                                                            xs='auto'>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems='center'
                                                                spacing={0.5}>
                                                                <Grid
                                                                    item
                                                                    xs='auto'>
                                                                    <StarIcon height="16px" selected/>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs>
                                                                    <Typography
                                                                        component='span'
                                                                        fontWeight={700}
                                                                        fontSize='1rem'
                                                                        noWrap>
                                                                        Avis<Typography variant='body1' component='span'> : {props.product.reviewNote}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}

                                                        <Grid
                                                            item
                                                            xs='auto'>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems='center'
                                                                wrap='nowrap'
                                                                spacing={0.5} >
                                                                <Grid
                                                                    item
                                                                    xs='auto'>
                                                                    <CheckIcon height="16px" selected/>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs>
                                                                    <Typography
                                                                        component='span'
                                                                        fontWeight={700}
                                                                        fontSize='1rem'
                                                                        noWrap>
                                                                        Téléchargements<Typography variant='body1' component='span'> : {nbDownload}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm='auto'
                                                    xs={12}
                                                    sx={{
                                                        textAlign: 'center'
                                                    }}>
                                                    {props.product.userConsumption &&
                                                     props.product.userConsumption?.status !== ProductConsumptionStatus.CREATED ?
                                                    <Chip
                                                        label={
                                                        <Typography variant="button">
                                                            {props.product.userConsumption.status === ProductConsumptionStatus.COMPLETED ?
                                                            "Abonnement terminé"

                                                            : props.product.userConsumption.status === ProductConsumptionStatus.IN_PROGRESS ?
                                                            "Abonnement en cours"

                                                            :
                                                            "Souscription en cours"
                                                            }
                                                        </Typography>}
                                                        sx={{
                                                            color: 'white',
                                                            backgroundColor: props.product.userConsumption.status === ProductConsumptionStatus.COMPLETED ? colors.black.main :
                                                                             props.product.userConsumption.status === ProductConsumptionStatus.IN_PROGRESS ? colors.green.main : colors.blue.main
                                                        }} />

                                                    :
                                                    <Button
                                                        variant='contained'
                                                        color="warning"
                                                        startIcon={<DownloadIcon color='white' />}
                                                        onClick={() => {
                                                            if (props.product.id)
                                                                updateProductDownload(props.product.id);

                                                            switch (props.product.type) {
                                                                case ProductType.APPLICATION:
                                                                    navigate(`/applications/${props.product.code}/obtenir`);
                                                                    return;
                    
                                                                case ProductType.OBJECT:
                                                                    navigate(`/objets/${props.product.code}/obtenir`);
                                                                    return;
                    
                                                                case ProductType.SERVICE:
                                                                    navigate(`/services/${props.product.code}/obtenir`);
                                                                    return;
                                                            }
                                                        }}
                                                        sx={{
                                                            minWidth: {
                                                                sm: "240px",
                                                                xs: 0
                                                            },
                                                            px: 4,
                                                            py: {
                                                                md: 2,
                                                                xs: 1.5
                                                            },
                                                            color: 'white',
                                                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860"
                                                        }}>
                                                        <Typography variant='button'>Obtenir via le pack digital</Typography>
                                                    </Button>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}
