import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const SleepIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 31 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M21.6032 2.51212L21.9054 3.46538C22.3209 3.33367 22.6032 2.948 22.6032 2.51212C22.6032 2.07623 22.3209 1.69056 21.9054 1.55886L21.6032 2.51212ZM21.6032 19.4879L21.9054 20.4411C22.3209 20.3094 22.6032 19.9238 22.6032 19.4879C22.6032 19.052 22.3209 18.6663 21.9054 18.5346L21.6032 19.4879ZM15.9127 11C15.9127 7.58796 18.3514 4.59188 21.9054 3.46538L21.3011 1.55886C17.0593 2.90337 13.9127 6.57472 13.9127 11H15.9127ZM21.9054 18.5346C18.3514 17.4081 15.9127 14.412 15.9127 11H13.9127C13.9127 15.4253 17.0593 19.0966 21.3011 20.4411L21.9054 18.5346ZM21.3011 18.5346C20.3522 18.8354 19.3281 19 18.258 19V21C19.5337 21 20.7616 20.8037 21.9054 20.4411L21.3011 18.5346ZM18.258 19C13.1624 19 9.22217 15.3186 9.22217 11H7.22217C7.22217 16.6225 12.2683 21 18.258 21V19ZM9.22217 11C9.22217 6.68139 13.1624 3 18.258 3V1C12.2683 1 7.22217 5.37749 7.22217 11H9.22217ZM18.258 3C19.3281 3 20.3522 3.16462 21.3011 3.46538L21.9054 1.55886C20.7616 1.19631 19.5337 1 18.258 1V3Z" fill={props.color || defaultIconColor}/>
        </svg>
    )
}
