import { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { LoadingButton } from '@mui/lab';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PageLoginSteps } from '../../../pages/Login/PageLogin';

import { activateAccount } from '../../../api/Authentication.api';

import { ISignupRequest } from '../../../models/Authentication.model';
import { IUser } from '../../../models/User.model';

import { colors } from '../../../static/colors';


interface Props {
    user: IUser;
    send: (user: IUser) => void;
    goToStep: (step: PageLoginSteps) => void;
}


export const FormAccountActivation = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);

    const getInitialValues = () => {
        return {
            firstname: props.user?.firstname,
            lastname: props.user?.lastname,
            email: props.user?.email,
        }
    };

    const validationSchema = yup.object({
        firstname: yup.string().required("Champ obligatoire"),
        lastname: yup.string().required("Champ obligatoire"),
        email: yup.string().email("Email non valide").required("Champ obligatoire")
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            setLoading(true);

            if (!values.firstname || !values.lastname || !values.email) {  // Should never occur with the yup validation.
                setLoading(false);
                return;
            }

            const date = props.user.birthDate.getDate() + "-" +
                         ('0' + (props.user.birthDate.getMonth() + 1)).slice(-2) + "-" +
                         props.user.birthDate.getFullYear();

            const signupRequest: ISignupRequest = {
                dateBirth: date,
                firstName: values.firstname,
                lastName: values.lastname,
                email: values.email,
                numDelegataire: props.user.numDelegataire
            };

            activateAccount(signupRequest).then(response => {
                props.send({...response, birthDate: new Date(parseInt(response?.birthDate.toString()))});
                setLoading(false);
            });

            // Change the step without waiting the mail to be sent.
            props.goToStep(PageLoginSteps.ACCOUNT_ACTIVATION_INFO);
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: {
                            md: 'left',
                            xs: 'center'
                        }
                    }}>
                    <Typography variant='h1'>Votre compte doit être activé !</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="firstname"
                        name="firstname"
                        placeholder="Votre prénom"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                        helperText={formik.touched.firstname && formik.errors.firstname} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="lastname"
                        name="lastname"
                        placeholder="Votre nom"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                        helperText={formik.touched.lastname && formik.errors.lastname} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Votre adresse mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        loadingIndicator={
                            <Typography
                                noWrap
                                variant="button"
                                sx={{
                                    textAlign: 'center',
                                    color: 'white'
                                }}>
                                Activation en cours...
                            </Typography>
                        }
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Valider
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    )
}
