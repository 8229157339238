import { useState } from 'react';

import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';


interface Props {
    id: string;
    value?: string;
    label?: string | JSX.Element;
    placeholder?: string;
    onChange: (event: React.ChangeEvent, value: string) => void;
    onBlur?: (e: any) => void;
    error?: Boolean;
    helperText?: string;
    fullWidth?: Boolean;
}


export const TextFieldPassword = (props: Props) => {

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onChange = (event: React.ChangeEvent) => {
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

        props.onChange(event, target.value);
    }


    return (
        <OutlinedInput
            id={props.id}
            value={props.value}
            label={props.label}
            placeholder={props.placeholder}
            onChange={onChange}
            onBlur={props.onBlur}
            error={props.error as boolean}
            fullWidth={props.fullWidth as boolean}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <Button
                        color='inherit'
                        onClick={() => setShowPassword(!showPassword)}
                        sx={{
                            p: 1.25
                        }}>
                        <Typography
                            variant='body2'>
                            {showPassword ? "Masquer" : "Voir"}
                        </Typography>
                    </Button>
                </InputAdornment>
            }/>
    );
}
