import { IIcon } from "../settings"


export const SmartphoneIllustration = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : "60"}
            height={props.height ? props.height : "60"}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M29.894 3.98849C27.9993 3.05691 25.814 3.17376 23.9824 4.1906L12.153 11.0274C11.5088 11.4001 10.8456 11.7474 10.2709 12.2274C9.57613 12.8053 9.0235 13.5317 8.64139 14.3369C8.12665 15.4169 7.92139 16.6422 8.09192 17.8485C8.29718 19.2885 9.05823 20.4569 9.76876 21.6822C12.1909 25.8759 14.6161 30.0695 17.0382 34.2632C19.8614 39.1485 22.6845 44.0338 25.5077 48.919C26.3003 50.2895 27.093 51.6601 27.8824 53.0306C28.0498 53.318 28.2077 53.6117 28.394 53.8895C29.5719 55.6422 31.593 56.6938 33.7024 56.659C35.9003 56.6243 37.8488 55.1559 39.6835 54.0948C42.1846 52.6485 44.6888 51.2022 47.1898 49.7559C49.2298 48.5748 51.3803 47.5706 52.2677 45.1864C52.9277 43.4085 52.7382 41.4885 51.8161 39.8369C51.674 39.5843 51.5256 39.3348 51.3803 39.0822C49.8456 36.4264 48.3109 33.7706 46.7761 31.1117C46.1098 29.9622 45.4467 28.8095 44.7803 27.6601C41.8719 22.6295 38.9667 17.6022 36.0582 12.5685C35.1077 10.9264 34.1603 9.28428 33.2098 7.64218C32.3603 6.16744 31.514 4.78428 29.894 3.98849ZM13.6593 13.5317L24.4182 7.31376C26.7172 5.98428 29.6603 6.7706 30.9898 9.0727L43.5267 30.7643C43.3593 30.7232 43.1951 30.6822 43.0309 30.638C42.813 30.3001 42.5951 29.9653 42.3709 29.6338C42.4435 29.9274 42.5161 30.2211 42.5856 30.5148C42.4877 30.4864 42.3898 30.458 42.2919 30.4295C41.9477 30.3253 41.6035 30.2148 41.2656 30.098C36.4277 28.4306 32.0351 25.4243 27.7119 22.5917C22.8109 19.3801 17.5498 16.2317 11.8119 15.4548C12.2193 14.678 12.8445 14.0022 13.6593 13.5317Z" fill={props.color || '#7ED346'}/>
        <path d="M43.5263 30.7644L48.2726 38.9781L48.8032 39.897C49.2389 40.6549 49.4474 41.4792 49.4505 42.2939C49.4537 43.9581 48.5916 45.5749 47.0474 46.4655L41.9695 49.4023L36.2916 52.6834C33.9926 54.0128 31.0495 53.2234 29.72 50.9244L25.9495 44.397L25.5547 43.7149L11.9032 20.1002C11.0442 18.616 11.0695 16.8665 11.8116 15.4518C17.5495 16.2318 22.8137 19.3802 27.7116 22.5886C32.0347 25.4213 36.4305 28.4276 41.2653 30.0949C41.6063 30.2118 41.9474 30.3223 42.2916 30.4265C42.3895 30.4549 42.4874 30.4834 42.5853 30.5118C42.5126 30.2181 42.4432 29.9244 42.3705 29.6307C42.5947 29.9623 42.8126 30.3002 43.0305 30.6349C43.1947 30.6823 43.3589 30.7234 43.5263 30.7644Z" fill={props.secondaryColor || '#B6F097'}/>
        <path d="M30.9899 9.07276L43.5267 30.7643C43.3594 30.7233 43.1952 30.6822 43.031 30.638C42.8131 30.3001 42.5952 29.9654 42.371 29.6338C42.4436 29.9275 42.5162 30.2212 42.5857 30.5149C42.4878 30.4864 42.3899 30.458 42.292 30.4296C41.9478 30.3254 41.6036 30.2149 41.2657 30.098C36.4278 28.4307 32.0352 25.4243 27.712 22.5917C22.811 19.3801 17.5499 16.2317 11.812 15.4549C12.2194 14.678 12.8446 14.0054 13.6625 13.5317L24.4215 7.31381C26.7204 5.98434 29.6604 6.77065 30.9899 9.07276Z" fill={props.bgColor || '#D7F9C2'}/>
        <path d="M42.5853 30.515C42.5127 30.2213 42.4432 29.9276 42.3706 29.6339C42.5948 29.9655 42.8127 30.3034 43.0306 30.6381C42.879 30.5971 42.7338 30.556 42.5853 30.515Z" fill={props.bgColor || '#D7F9C2'}/>
        <g style={{
            mixBlendMode: 'overlay'
        }}>
        <path d="M44.0071 42.4736C44.1972 42.4736 44.3513 42.3195 44.3513 42.1294C44.3513 41.9393 44.1972 41.7852 44.0071 41.7852C43.817 41.7852 43.6628 41.9393 43.6628 42.1294C43.6628 42.3195 43.817 42.4736 44.0071 42.4736Z" fill="#3E3314"/>
        <path d="M45.7759 45.1357C46.0166 45.1357 46.2117 44.9406 46.2117 44.7C46.2117 44.4593 46.0166 44.2642 45.7759 44.2642C45.5352 44.2642 45.3401 44.4593 45.3401 44.7C45.3401 44.9406 45.5352 45.1357 45.7759 45.1357Z" fill="#3E3314"/>
        <path d="M44.7205 45.2778C44.9525 45.2778 45.1405 45.0898 45.1405 44.8578C45.1405 44.6258 44.9525 44.4378 44.7205 44.4378C44.4886 44.4378 44.3005 44.6258 44.3005 44.8578C44.3005 45.0898 44.4886 45.2778 44.7205 45.2778Z" fill="#3E3314"/>
        <path d="M42.5606 44.7347C42.6757 44.7347 42.769 44.6414 42.769 44.5263C42.769 44.4112 42.6757 44.3179 42.5606 44.3179C42.4455 44.3179 42.3522 44.4112 42.3522 44.5263C42.3522 44.6414 42.4455 44.7347 42.5606 44.7347Z" fill="#3E3314"/>
        <path d="M41.6802 45.2084C41.8127 45.2084 41.9202 45.1009 41.9202 44.9684C41.9202 44.8358 41.8127 44.7284 41.6802 44.7284C41.5476 44.7284 41.4402 44.8358 41.4402 44.9684C41.4402 45.1009 41.5476 45.2084 41.6802 45.2084Z" fill="#3E3314"/>
        <path d="M41.1465 45.7231C41.2634 45.7231 41.3581 45.6284 41.3581 45.5115C41.3581 45.3947 41.2634 45.2999 41.1465 45.2999C41.0297 45.2999 40.9349 45.3947 40.9349 45.5115C40.9349 45.6284 41.0297 45.7231 41.1465 45.7231Z" fill="#3E3314"/>
        <path d="M41.4655 48.7957C41.6574 48.7957 41.8129 48.6402 41.8129 48.4484C41.8129 48.2565 41.6574 48.101 41.4655 48.101C41.2737 48.101 41.1182 48.2565 41.1182 48.4484C41.1182 48.6402 41.2737 48.7957 41.4655 48.7957Z" fill="#3E3314"/>
        <path d="M39.817 46.7399C39.9931 46.7399 40.1359 46.5971 40.1359 46.421C40.1359 46.2448 39.9931 46.1021 39.817 46.1021C39.6408 46.1021 39.498 46.2448 39.498 46.421C39.498 46.5971 39.6408 46.7399 39.817 46.7399Z" fill="#3E3314"/>
        <path d="M39.6111 47.681C39.7541 47.681 39.8701 47.5651 39.8701 47.4221C39.8701 47.2791 39.7541 47.1631 39.6111 47.1631C39.4681 47.1631 39.3522 47.2791 39.3522 47.4221C39.3522 47.5651 39.4681 47.681 39.6111 47.681Z" fill="#3E3314"/>
        <path d="M38.6071 46.0641C38.731 46.0641 38.8314 45.9638 38.8314 45.8399C38.8314 45.7161 38.731 45.6157 38.6071 45.6157C38.4833 45.6157 38.3829 45.7161 38.3829 45.8399C38.3829 45.9638 38.4833 46.0641 38.6071 46.0641Z" fill="#3E3314"/>
        <path d="M39.2549 48.682C39.3735 48.682 39.4696 48.5859 39.4696 48.4673C39.4696 48.3487 39.3735 48.2526 39.2549 48.2526C39.1363 48.2526 39.0402 48.3487 39.0402 48.4673C39.0402 48.5859 39.1363 48.682 39.2549 48.682Z" fill="#3E3314"/>
        <path d="M40.0468 50.1473C40.2003 50.1473 40.3247 50.0229 40.3247 49.8694C40.3247 49.716 40.2003 49.5916 40.0468 49.5916C39.8933 49.5916 39.7689 49.716 39.7689 49.8694C39.7689 50.0229 39.8933 50.1473 40.0468 50.1473Z" fill="#3E3314"/>
        <path d="M37.0973 47.0652C37.235 47.0652 37.3467 46.9535 37.3467 46.8157C37.3467 46.6779 37.235 46.5662 37.0973 46.5662C36.9595 46.5662 36.8478 46.6779 36.8478 46.8157C36.8478 46.9535 36.9595 47.0652 37.0973 47.0652Z" fill="#3E3314"/>
        <path d="M36.6846 51.8463C36.8433 51.8463 36.972 51.7176 36.972 51.5589C36.972 51.4002 36.8433 51.2715 36.6846 51.2715C36.5259 51.2715 36.3972 51.4002 36.3972 51.5589C36.3972 51.7176 36.5259 51.8463 36.6846 51.8463Z" fill="#3E3314"/>
        <path d="M35.1867 48.9157C35.4292 48.9157 35.6257 48.7192 35.6257 48.4768C35.6257 48.2344 35.4292 48.0378 35.1867 48.0378C34.9443 48.0378 34.7478 48.2344 34.7478 48.4768C34.7478 48.7192 34.9443 48.9157 35.1867 48.9157Z" fill="#3E3314"/>
        <path d="M34.4929 49.9642C34.6272 49.9642 34.7361 49.8554 34.7361 49.7211C34.7361 49.5868 34.6272 49.4779 34.4929 49.4779C34.3586 49.4779 34.2498 49.5868 34.2498 49.7211C34.2498 49.8554 34.3586 49.9642 34.4929 49.9642Z" fill="#3E3314"/>
        <path d="M34.1384 47.981C34.3373 47.981 34.4984 47.8199 34.4984 47.621C34.4984 47.4222 34.3373 47.261 34.1384 47.261C33.9396 47.261 33.7784 47.4222 33.7784 47.621C33.7784 47.8199 33.9396 47.981 34.1384 47.981Z" fill="#3E3314"/>
        <path d="M32.4681 48.3979C32.6512 48.3979 32.7996 48.2494 32.7996 48.0663C32.7996 47.8832 32.6512 47.7347 32.4681 47.7347C32.2849 47.7347 32.1365 47.8832 32.1365 48.0663C32.1365 48.2494 32.2849 48.3979 32.4681 48.3979Z" fill="#3E3314"/>
        <path d="M31.7422 48.1642C31.9671 48.1642 32.1495 47.9818 32.1495 47.7568C32.1495 47.5318 31.9671 47.3494 31.7422 47.3494C31.5172 47.3494 31.3348 47.5318 31.3348 47.7568C31.3348 47.9818 31.5172 48.1642 31.7422 48.1642Z" fill="#3E3314"/>
        <path d="M30.3712 46.92C30.5386 46.92 30.6744 46.7842 30.6744 46.6168C30.6744 46.4494 30.5386 46.3137 30.3712 46.3137C30.2038 46.3137 30.0681 46.4494 30.0681 46.6168C30.0681 46.7842 30.2038 46.92 30.3712 46.92Z" fill="#3E3314"/>
        <path d="M27.7098 49.0042C27.8302 49.0042 27.9277 48.9066 27.9277 48.7863C27.9277 48.6659 27.8302 48.5684 27.7098 48.5684C27.5895 48.5684 27.4919 48.6659 27.4919 48.7863C27.4919 48.9066 27.5895 49.0042 27.7098 49.0042Z" fill="#3E3314"/>
        <path d="M28.0536 46.1463C28.1913 46.1463 28.303 46.0346 28.303 45.8968C28.303 45.759 28.1913 45.6473 28.0536 45.6473C27.9158 45.6473 27.8041 45.759 27.8041 45.8968C27.8041 46.0346 27.9158 46.1463 28.0536 46.1463Z" fill="#3E3314"/>
        <path d="M27.3775 45.7231C27.4874 45.7231 27.5765 45.634 27.5765 45.5241C27.5765 45.4143 27.4874 45.3252 27.3775 45.3252C27.2677 45.3252 27.1786 45.4143 27.1786 45.5241C27.1786 45.634 27.2677 45.7231 27.3775 45.7231Z" fill="#3E3314"/>
        <path d="M28.357 43.7241C28.486 43.7241 28.5907 43.6195 28.5907 43.4905C28.5907 43.3614 28.486 43.2568 28.357 43.2568C28.2279 43.2568 28.1233 43.3614 28.1233 43.4905C28.1233 43.6195 28.2279 43.7241 28.357 43.7241Z" fill="#3E3314"/>
        <path d="M25.5236 45.0031C25.6614 45.0031 25.7731 44.8914 25.7731 44.7536C25.7731 44.6158 25.6614 44.5042 25.5236 44.5042C25.3859 44.5042 25.2742 44.6158 25.2742 44.7536C25.2742 44.8914 25.3859 45.0031 25.5236 45.0031Z" fill="#3E3314"/>
        <path d="M24.4662 44.3083C24.6284 44.3083 24.7598 44.1768 24.7598 44.0146C24.7598 43.8524 24.6284 43.7209 24.4662 43.7209C24.304 43.7209 24.1725 43.8524 24.1725 44.0146C24.1725 44.1768 24.304 44.3083 24.4662 44.3083Z" fill="#3E3314"/>
        <path d="M25.6787 35.3337C25.8008 35.3337 25.8997 35.2347 25.8997 35.1127C25.8997 34.9906 25.8008 34.8916 25.6787 34.8916C25.5566 34.8916 25.4576 34.9906 25.4576 35.1127C25.4576 35.2347 25.5566 35.3337 25.6787 35.3337Z" fill="#3E3314"/>
        <path d="M26.2822 36.4674C26.5264 36.4674 26.7243 36.2695 26.7243 36.0253C26.7243 35.7811 26.5264 35.5832 26.2822 35.5832C26.038 35.5832 25.8401 35.7811 25.8401 36.0253C25.8401 36.2695 26.038 36.4674 26.2822 36.4674Z" fill="#3E3314"/>
        <path d="M28.5116 35.6684C28.7104 35.6684 28.8716 35.5072 28.8716 35.3084C28.8716 35.1096 28.7104 34.9484 28.5116 34.9484C28.3128 34.9484 28.1516 35.1096 28.1516 35.3084C28.1516 35.5072 28.3128 35.6684 28.5116 35.6684Z" fill="#3E3314"/>
        <path d="M27.0871 37.5789C27.2895 37.5789 27.4535 37.4149 27.4535 37.2126C27.4535 37.0103 27.2895 36.8463 27.0871 36.8463C26.8848 36.8463 26.7208 37.0103 26.7208 37.2126C26.7208 37.4149 26.8848 37.5789 27.0871 37.5789Z" fill="#3E3314"/>
        <path d="M28.3287 37.7811C28.4298 37.7811 28.5118 37.699 28.5118 37.5979C28.5118 37.4967 28.4298 37.4147 28.3287 37.4147C28.2275 37.4147 28.1455 37.4967 28.1455 37.5979C28.1455 37.699 28.2275 37.7811 28.3287 37.7811Z" fill="#3E3314"/>
        <path d="M27.8422 39.259C27.9539 39.259 28.0443 39.1685 28.0443 39.0569C28.0443 38.9453 27.9539 38.8548 27.8422 38.8548C27.7306 38.8548 27.6401 38.9453 27.6401 39.0569C27.6401 39.1685 27.7306 39.259 27.8422 39.259Z" fill="#3E3314"/>
        <path d="M27.4791 40.0769C27.5995 40.0769 27.697 39.9793 27.697 39.859C27.697 39.7387 27.5995 39.6411 27.4791 39.6411C27.3588 39.6411 27.2612 39.7387 27.2612 39.859C27.2612 39.9793 27.3588 40.0769 27.4791 40.0769Z" fill="#3E3314"/>
        <path d="M30.9873 38.6495C31.1024 38.6495 31.1957 38.5562 31.1957 38.4411C31.1957 38.326 31.1024 38.2327 30.9873 38.2327C30.8721 38.2327 30.7788 38.326 30.7788 38.4411C30.7788 38.5562 30.8721 38.6495 30.9873 38.6495Z" fill="#3E3314"/>
        <path d="M31.1355 39.8337C31.2768 39.8337 31.3913 39.7192 31.3913 39.5779C31.3913 39.4367 31.2768 39.3221 31.1355 39.3221C30.9942 39.3221 30.8796 39.4367 30.8796 39.5779C30.8796 39.7192 30.9942 39.8337 31.1355 39.8337Z" fill="#3E3314"/>
        <path d="M29.9043 41.6022C30.0124 41.6022 30.1001 41.5145 30.1001 41.4064C30.1001 41.2982 30.0124 41.2106 29.9043 41.2106C29.7962 41.2106 29.7085 41.2982 29.7085 41.4064C29.7085 41.5145 29.7962 41.6022 29.9043 41.6022Z" fill="#3E3314"/>
        <path d="M32.8633 40.8947C33.0621 40.8947 33.2233 40.7336 33.2233 40.5347C33.2233 40.3359 33.0621 40.1747 32.8633 40.1747C32.6645 40.1747 32.5033 40.3359 32.5033 40.5347C32.5033 40.7336 32.6645 40.8947 32.8633 40.8947Z" fill="#3E3314"/>
        <path d="M31.0157 42.9316C31.1937 42.9316 31.3379 42.7874 31.3379 42.6095C31.3379 42.4316 31.1937 42.2874 31.0157 42.2874C30.8378 42.2874 30.6936 42.4316 30.6936 42.6095C30.6936 42.7874 30.8378 42.9316 31.0157 42.9316Z" fill="#3E3314"/>
        <path d="M31.83 43.9263C31.98 43.9263 32.1016 43.8047 32.1016 43.6548C32.1016 43.5048 31.98 43.3832 31.83 43.3832C31.68 43.3832 31.5585 43.5048 31.5585 43.6548C31.5585 43.8047 31.68 43.9263 31.83 43.9263Z" fill="#3E3314"/>
        <path d="M31.6789 44.7158C31.7696 44.7158 31.8431 44.6423 31.8431 44.5516C31.8431 44.4609 31.7696 44.3874 31.6789 44.3874C31.5882 44.3874 31.5146 44.4609 31.5146 44.5516C31.5146 44.6423 31.5882 44.7158 31.6789 44.7158Z" fill="#3E3314"/>
        <path d="M33.7785 44.1568C33.8971 44.1568 33.9932 44.0607 33.9932 43.9421C33.9932 43.8235 33.8971 43.7274 33.7785 43.7274C33.6599 43.7274 33.5637 43.8235 33.5637 43.9421C33.5637 44.0607 33.6599 44.1568 33.7785 44.1568Z" fill="#3E3314"/>
        <path d="M33.1533 46.1653C33.3835 46.1653 33.5701 45.9787 33.5701 45.7484C33.5701 45.5182 33.3835 45.3316 33.1533 45.3316C32.9231 45.3316 32.7365 45.5182 32.7365 45.7484C32.7365 45.9787 32.9231 46.1653 33.1533 46.1653Z" fill="#3E3314"/>
        <path d="M33.9308 46.1558C34.1436 46.1558 34.3161 45.9833 34.3161 45.7705C34.3161 45.5577 34.1436 45.3853 33.9308 45.3853C33.718 45.3853 33.5455 45.5577 33.5455 45.7705C33.5455 45.9833 33.718 46.1558 33.9308 46.1558Z" fill="#3E3314"/>
        <path d="M33.8637 47.0401C34.0294 47.0401 34.1637 46.9057 34.1637 46.7401C34.1637 46.5744 34.0294 46.4401 33.8637 46.4401C33.698 46.4401 33.5637 46.5744 33.5637 46.7401C33.5637 46.9057 33.698 47.0401 33.8637 47.0401Z" fill="#3E3314"/>
        <path d="M36.2294 45.0095C36.4265 45.0095 36.5862 44.8498 36.5862 44.6527C36.5862 44.4556 36.4265 44.2958 36.2294 44.2958C36.0323 44.2958 35.8726 44.4556 35.8726 44.6527C35.8726 44.8498 36.0323 45.0095 36.2294 45.0095Z" fill="#3E3314"/>
        <path d="M36.3486 45.4106C36.5928 45.4106 36.7907 45.2126 36.7907 44.9685C36.7907 44.7243 36.5928 44.5264 36.3486 44.5264C36.1044 44.5264 35.9065 44.7243 35.9065 44.9685C35.9065 45.2126 36.1044 45.4106 36.3486 45.4106Z" fill="#3E3314"/>
        <path d="M36.3273 46.4684C36.5349 46.4684 36.7031 46.3002 36.7031 46.0926C36.7031 45.8851 36.5349 45.7169 36.3273 45.7169C36.1198 45.7169 35.9515 45.8851 35.9515 46.0926C35.9515 46.3002 36.1198 46.4684 36.3273 46.4684Z" fill="#3E3314"/>
        <path d="M36.1845 47.3147C36.2857 47.3147 36.3677 47.2327 36.3677 47.1316C36.3677 47.0304 36.2857 46.9484 36.1845 46.9484C36.0833 46.9484 36.0013 47.0304 36.0013 47.1316C36.0013 47.2327 36.0833 47.3147 36.1845 47.3147Z" fill="#3E3314"/>
        <path d="M35.162 45.5337C35.2667 45.5337 35.3515 45.4489 35.3515 45.3443C35.3515 45.2396 35.2667 45.1548 35.162 45.1548C35.0574 45.1548 34.9725 45.2396 34.9725 45.3443C34.9725 45.4489 35.0574 45.5337 35.162 45.5337Z" fill="#3E3314"/>
        <path d="M37.0887 48.4989C37.2143 48.4989 37.3161 48.3971 37.3161 48.2716C37.3161 48.146 37.2143 48.0442 37.0887 48.0442C36.9631 48.0442 36.8613 48.146 36.8613 48.2716C36.8613 48.3971 36.9631 48.4989 37.0887 48.4989Z" fill="#3E3314"/>
        <path d="M39.6365 28.1558C39.872 28.1558 40.0628 27.9649 40.0628 27.7295C40.0628 27.494 39.872 27.3032 39.6365 27.3032C39.4011 27.3032 39.2102 27.494 39.2102 27.7295C39.2102 27.9649 39.4011 28.1558 39.6365 28.1558Z" fill="#3E3314"/>
        <path d="M38.3264 29.8769C38.4781 29.8769 38.6011 29.7539 38.6011 29.6022C38.6011 29.4505 38.4781 29.3275 38.3264 29.3275C38.1746 29.3275 38.0516 29.4505 38.0516 29.6022C38.0516 29.7539 38.1746 29.8769 38.3264 29.8769Z" fill="#3E3314"/>
        <path d="M40.6565 29.4788C40.8065 29.4788 40.928 29.3572 40.928 29.2072C40.928 29.0573 40.8065 28.9357 40.6565 28.9357C40.5065 28.9357 40.3849 29.0573 40.3849 29.2072C40.3849 29.3572 40.5065 29.4788 40.6565 29.4788Z" fill="#3E3314"/>
        <path d="M41.8533 30.2337C42.019 30.2337 42.1533 30.0994 42.1533 29.9337C42.1533 29.768 42.019 29.6337 41.8533 29.6337C41.6877 29.6337 41.5533 29.768 41.5533 29.9337C41.5533 30.0994 41.6877 30.2337 41.8533 30.2337Z" fill="#3E3314"/>
        <path d="M42.4098 31.2852C42.64 31.2852 42.8266 31.0986 42.8266 30.8684C42.8266 30.6381 42.64 30.4515 42.4098 30.4515C42.1795 30.4515 41.9929 30.6381 41.9929 30.8684C41.9929 31.0986 42.1795 31.2852 42.4098 31.2852Z" fill="#3E3314"/>
        <path d="M41.0609 32.7537C41.2109 32.7537 41.3325 32.6321 41.3325 32.4821C41.3325 32.3321 41.2109 32.2105 41.0609 32.2105C40.9109 32.2105 40.7893 32.3321 40.7893 32.4821C40.7893 32.6321 40.9109 32.7537 41.0609 32.7537Z" fill="#3E3314"/>
        <path d="M41.9958 33.3C42.2295 33.3 42.4189 33.1105 42.4189 32.8768C42.4189 32.6431 42.2295 32.4537 41.9958 32.4537C41.7621 32.4537 41.5726 32.6431 41.5726 32.8768C41.5726 33.1105 41.7621 33.3 41.9958 33.3Z" fill="#3E3314"/>
        <path d="M41.9546 34.3548C42.0697 34.3548 42.1631 34.2615 42.1631 34.1464C42.1631 34.0312 42.0697 33.9379 41.9546 33.9379C41.8395 33.9379 41.7462 34.0312 41.7462 34.1464C41.7462 34.2615 41.8395 34.3548 41.9546 34.3548Z" fill="#3E3314"/>
        <path d="M44.2156 33.9411C44.3202 33.9411 44.4051 33.8563 44.4051 33.7516C44.4051 33.647 44.3202 33.5621 44.2156 33.5621C44.111 33.5621 44.0261 33.647 44.0261 33.7516C44.0261 33.8563 44.111 33.9411 44.2156 33.9411Z" fill="#3E3314"/>
        <path d="M41.1365 36.0158C41.2725 36.0158 41.3828 35.9055 41.3828 35.7695C41.3828 35.6335 41.2725 35.5232 41.1365 35.5232C41.0004 35.5232 40.8901 35.6335 40.8901 35.7695C40.8901 35.9055 41.0004 36.0158 41.1365 36.0158Z" fill="#3E3314"/>
        <path d="M42.2734 36.9032C42.4652 36.9032 42.6208 36.7477 42.6208 36.5558C42.6208 36.364 42.4652 36.2084 42.2734 36.2084C42.0815 36.2084 41.926 36.364 41.926 36.5558C41.926 36.7477 42.0815 36.9032 42.2734 36.9032Z" fill="#3E3314"/>
        <path d="M45.8608 36.7421C45.9497 36.7421 46.0218 36.67 46.0218 36.5811C46.0218 36.4921 45.9497 36.42 45.8608 36.42C45.7718 36.42 45.6997 36.4921 45.6997 36.5811C45.6997 36.67 45.7718 36.7421 45.8608 36.7421Z" fill="#3E3314"/>
        <path d="M44.2629 38.8137C44.3588 38.8137 44.4366 38.736 44.4366 38.6401C44.4366 38.5441 44.3588 38.4664 44.2629 38.4664C44.167 38.4664 44.0892 38.5441 44.0892 38.6401C44.0892 38.736 44.167 38.8137 44.2629 38.8137Z" fill="#3E3314"/>
        <path d="M46.7069 39.7231C46.8238 39.7231 46.9185 39.6284 46.9185 39.5116C46.9185 39.3947 46.8238 39.3 46.7069 39.3C46.5901 39.3 46.4954 39.3947 46.4954 39.5116C46.4954 39.6284 46.5901 39.7231 46.7069 39.7231Z" fill="#3E3314"/>
        <path d="M45.599 41.2958C45.7472 41.2958 45.8674 41.1756 45.8674 41.0274C45.8674 40.8791 45.7472 40.759 45.599 40.759C45.4507 40.759 45.3306 40.8791 45.3306 41.0274C45.3306 41.1756 45.4507 41.2958 45.599 41.2958Z" fill="#3E3314"/>
        <path d="M43.3447 43.4084C43.5191 43.4084 43.6605 43.267 43.6605 43.0926C43.6605 42.9182 43.5191 42.7769 43.3447 42.7769C43.1703 42.7769 43.0289 42.9182 43.0289 43.0926C43.0289 43.267 43.1703 43.4084 43.3447 43.4084Z" fill="#3E3314"/>
        <path d="M42.3998 44.1821C42.4957 44.1821 42.5734 44.1044 42.5734 44.0085C42.5734 43.9125 42.4957 43.8348 42.3998 43.8348C42.3038 43.8348 42.2261 43.9125 42.2261 44.0085C42.2261 44.1044 42.3038 44.1821 42.3998 44.1821Z" fill="#3E3314"/>
        <path d="M41.5824 44.9242C41.7045 44.9242 41.8034 44.8253 41.8034 44.7032C41.8034 44.5811 41.7045 44.4821 41.5824 44.4821C41.4603 44.4821 41.3613 44.5811 41.3613 44.7032C41.3613 44.8253 41.4603 44.9242 41.5824 44.9242Z" fill="#3E3314"/>
        <path d="M40.8304 44.4063C40.9525 44.4063 41.0515 44.3074 41.0515 44.1853C41.0515 44.0632 40.9525 43.9642 40.8304 43.9642C40.7083 43.9642 40.6094 44.0632 40.6094 44.1853C40.6094 44.3074 40.7083 44.4063 40.8304 44.4063Z" fill="#3E3314"/>
        <path d="M41.168 47.8074C41.3076 47.8074 41.4207 47.6943 41.4207 47.5548C41.4207 47.4152 41.3076 47.3021 41.168 47.3021C41.0285 47.3021 40.9154 47.4152 40.9154 47.5548C40.9154 47.6943 41.0285 47.8074 41.168 47.8074Z" fill="#3E3314"/>
        <path d="M39.3838 45.1737C39.5112 45.1737 39.6144 45.0705 39.6144 44.9432C39.6144 44.8159 39.5112 44.7126 39.3838 44.7126C39.2565 44.7126 39.1533 44.8159 39.1533 44.9432C39.1533 45.0705 39.2565 45.1737 39.3838 45.1737Z" fill="#3E3314"/>
        <path d="M39.7825 47.58C39.9045 47.58 40.0035 47.4811 40.0035 47.359C40.0035 47.2369 39.9045 47.1379 39.7825 47.1379C39.6604 47.1379 39.5614 47.2369 39.5614 47.359C39.5614 47.4811 39.6604 47.58 39.7825 47.58Z" fill="#3E3314"/>
        <path d="M38.9268 48.0568C39.1466 48.0568 39.3247 47.8787 39.3247 47.6589C39.3247 47.4392 39.1466 47.261 38.9268 47.261C38.7071 47.261 38.5289 47.4392 38.5289 47.6589C38.5289 47.8787 38.7071 48.0568 38.9268 48.0568Z" fill="#3E3314"/>
        <path d="M38.1684 48.3694C38.3027 48.3694 38.4116 48.2606 38.4116 48.1263C38.4116 47.992 38.3027 47.8831 38.1684 47.8831C38.0342 47.8831 37.9253 47.992 37.9253 48.1263C37.9253 48.2606 38.0342 48.3694 38.1684 48.3694Z" fill="#3E3314"/>
        <path d="M37.2749 47.4695C37.3639 47.4695 37.436 47.3974 37.436 47.3084C37.436 47.2195 37.3639 47.1474 37.2749 47.1474C37.186 47.1474 37.1139 47.2195 37.1139 47.3084C37.1139 47.3974 37.186 47.4695 37.2749 47.4695Z" fill="#3E3314"/>
        <path d="M35.6701 47.1884C35.7608 47.1884 35.8343 47.1149 35.8343 47.0242C35.8343 46.9335 35.7608 46.86 35.6701 46.86C35.5794 46.86 35.5059 46.9335 35.5059 47.0242C35.5059 47.1149 35.5794 47.1884 35.6701 47.1884Z" fill="#3E3314"/>
        <path d="M35.9205 48.4137C36.0495 48.4137 36.1541 48.3091 36.1541 48.18C36.1541 48.051 36.0495 47.9464 35.9205 47.9464C35.7914 47.9464 35.6868 48.051 35.6868 48.18C35.6868 48.3091 35.7914 48.4137 35.9205 48.4137Z" fill="#3E3314"/>
        <path d="M36.785 50.0526C36.8844 50.0526 36.965 49.972 36.965 49.8726C36.965 49.7732 36.8844 49.6926 36.785 49.6926C36.6856 49.6926 36.605 49.7732 36.605 49.8726C36.605 49.972 36.6856 50.0526 36.785 50.0526Z" fill="#3E3314"/>
        <path d="M34.4074 48C34.5399 48 34.6474 47.8926 34.6474 47.76C34.6474 47.6275 34.5399 47.52 34.4074 47.52C34.2748 47.52 34.1674 47.6275 34.1674 47.76C34.1674 47.8926 34.2748 48 34.4074 48Z" fill="#3E3314"/>
        <path d="M43.5332 27.9126C43.7721 27.9126 43.9658 27.7189 43.9658 27.48C43.9658 27.2411 43.7721 27.0474 43.5332 27.0474C43.2943 27.0474 43.1006 27.2411 43.1006 27.48C43.1006 27.7189 43.2943 27.9126 43.5332 27.9126Z" fill="#3E3314"/>
        <path d="M41.2471 29.6337C41.4041 29.6337 41.5313 29.5064 41.5313 29.3495C41.5313 29.1925 41.4041 29.0652 41.2471 29.0652C41.0901 29.0652 40.9629 29.1925 40.9629 29.3495C40.9629 29.5064 41.0901 29.6337 41.2471 29.6337Z" fill="#3E3314"/>
        <path d="M43.8736 29.261C44.0985 29.261 44.2809 29.0786 44.2809 28.8536C44.2809 28.6286 44.0985 28.4462 43.8736 28.4462C43.6486 28.4462 43.4662 28.6286 43.4662 28.8536C43.4662 29.0786 43.6486 29.261 43.8736 29.261Z" fill="#3E3314"/>
        <path d="M40.7955 31.9926C41.0047 31.9926 41.1744 31.823 41.1744 31.6137C41.1744 31.4044 41.0047 31.2347 40.7955 31.2347C40.5862 31.2347 40.4165 31.4044 40.4165 31.6137C40.4165 31.823 40.5862 31.9926 40.7955 31.9926Z" fill="#3E3314"/>
        <path d="M43.2673 30.8969C43.4016 30.8969 43.5105 30.788 43.5105 30.6537C43.5105 30.5194 43.4016 30.4106 43.2673 30.4106C43.133 30.4106 43.0242 30.5194 43.0242 30.6537C43.0242 30.788 43.133 30.8969 43.2673 30.8969Z" fill="#3E3314"/>
        <path d="M41.4862 32.5643C41.631 32.5643 41.7483 32.4469 41.7483 32.3021C41.7483 32.1574 41.631 32.04 41.4862 32.04C41.3415 32.04 41.2241 32.1574 41.2241 32.3021C41.2241 32.4469 41.3415 32.5643 41.4862 32.5643Z" fill="#3E3314"/>
        <path d="M42.5322 33.0664C42.7712 33.0664 42.9649 32.8727 42.9649 32.6338C42.9649 32.3949 42.7712 32.2012 42.5322 32.2012C42.2933 32.2012 42.0996 32.3949 42.0996 32.6338C42.0996 32.8727 42.2933 33.0664 42.5322 33.0664Z" fill="#3E3314"/>
        <path d="M43.8799 33.5274C44.0037 33.5274 44.1041 33.427 44.1041 33.3032C44.1041 33.1794 44.0037 33.079 43.8799 33.079C43.756 33.079 43.6556 33.1794 43.6556 33.3032C43.6556 33.427 43.756 33.5274 43.8799 33.5274Z" fill="#3E3314"/>
        <path d="M45.1124 34.14C45.3461 34.14 45.5355 33.9506 45.5355 33.7169C45.5355 33.4832 45.3461 33.2937 45.1124 33.2937C44.8787 33.2937 44.6892 33.4832 44.6892 33.7169C44.6892 33.9506 44.8787 34.14 45.1124 34.14Z" fill="#3E3314"/>
        <path d="M42.8888 36.3884C43.0911 36.3884 43.2551 36.2244 43.2551 36.0221C43.2551 35.8198 43.0911 35.6558 42.8888 35.6558C42.6865 35.6558 42.5225 35.8198 42.5225 36.0221C42.5225 36.2244 42.6865 36.3884 42.8888 36.3884Z" fill="#3E3314"/>
        <path d="M43.571 36.4295C43.735 36.4295 43.8679 36.2966 43.8679 36.1327C43.8679 35.9687 43.735 35.8358 43.571 35.8358C43.4071 35.8358 43.2742 35.9687 43.2742 36.1327C43.2742 36.2966 43.4071 36.4295 43.571 36.4295Z" fill="#3E3314"/>
        <path d="M45.7342 36.1768C45.8982 36.1768 46.0311 36.0439 46.0311 35.88C46.0311 35.716 45.8982 35.5831 45.7342 35.5831C45.5703 35.5831 45.4374 35.716 45.4374 35.88C45.4374 36.0439 45.5703 36.1768 45.7342 36.1768Z" fill="#3E3314"/>
        <path d="M47.3637 36.2526C47.5294 36.2526 47.6637 36.1183 47.6637 35.9526C47.6637 35.787 47.5294 35.6526 47.3637 35.6526C47.198 35.6526 47.0637 35.787 47.0637 35.9526C47.0637 36.1183 47.198 36.2526 47.3637 36.2526Z" fill="#3E3314"/>
        <path d="M44.0353 39.0853C44.1504 39.0853 44.2438 38.992 44.2438 38.8769C44.2438 38.7618 44.1504 38.6685 44.0353 38.6685C43.9202 38.6685 43.8269 38.7618 43.8269 38.8769C43.8269 38.992 43.9202 39.0853 44.0353 39.0853Z" fill="#3E3314"/>
        <path d="M47.4333 39.1578C47.667 39.1578 47.8564 38.9684 47.8564 38.7347C47.8564 38.501 47.667 38.3115 47.4333 38.3115C47.1996 38.3115 47.0101 38.501 47.0101 38.7347C47.0101 38.9684 47.1996 39.1578 47.4333 39.1578Z" fill="#3E3314"/>
        <path d="M45.3045 40.5821C45.5417 40.5821 45.7339 40.3898 45.7339 40.1526C45.7339 39.9154 45.5417 39.7231 45.3045 39.7231C45.0673 39.7231 44.875 39.9154 44.875 40.1526C44.875 40.3898 45.0673 40.5821 45.3045 40.5821Z" fill="#3E3314"/>
        <path d="M47.9102 40.1874C48.041 40.1874 48.147 40.0813 48.147 39.9505C48.147 39.8197 48.041 39.7137 47.9102 39.7137C47.7794 39.7137 47.6733 39.8197 47.6733 39.9505C47.6733 40.0813 47.7794 40.1874 47.9102 40.1874Z" fill="#3E3314"/>
        <path d="M49.5513 41.5831C49.6507 41.5831 49.7313 41.5026 49.7313 41.4031C49.7313 41.3037 49.6507 41.2231 49.5513 41.2231C49.4519 41.2231 49.3713 41.3037 49.3713 41.4031C49.3713 41.5026 49.4519 41.5831 49.5513 41.5831Z" fill="#3E3314"/>
        <path d="M47.067 43.1337C47.2466 43.1337 47.3922 42.9881 47.3922 42.8084C47.3922 42.6288 47.2466 42.4832 47.067 42.4832C46.8873 42.4832 46.7417 42.6288 46.7417 42.8084C46.7417 42.9881 46.8873 43.1337 47.067 43.1337Z" fill="#3E3314"/>
        <path d="M49.9746 44.9242C50.1507 44.9242 50.2935 44.7814 50.2935 44.6053C50.2935 44.4291 50.1507 44.2863 49.9746 44.2863C49.7984 44.2863 49.6556 44.4291 49.6556 44.6053C49.6556 44.7814 49.7984 44.9242 49.9746 44.9242Z" fill="#3E3314"/>
        <path d="M44.9513 45.6758C45.1484 45.6758 45.3082 45.516 45.3082 45.3189C45.3082 45.1219 45.1484 44.9621 44.9513 44.9621C44.7542 44.9621 44.5945 45.1219 44.5945 45.3189C44.5945 45.516 44.7542 45.6758 44.9513 45.6758Z" fill="#3E3314"/>
        <path d="M45.5386 47.7063C45.6677 47.7063 45.7723 47.6017 45.7723 47.4726C45.7723 47.3436 45.6677 47.239 45.5386 47.239C45.4096 47.239 45.3049 47.3436 45.3049 47.4726C45.3049 47.6017 45.4096 47.7063 45.5386 47.7063Z" fill="#3E3314"/>
        <path d="M46.0312 50.0274C46.2544 50.0274 46.4354 49.8464 46.4354 49.6231C46.4354 49.3999 46.2544 49.2189 46.0312 49.2189C45.8079 49.2189 45.627 49.3999 45.627 49.6231C45.627 49.8464 45.8079 50.0274 46.0312 50.0274Z" fill="#3E3314"/>
        <path d="M42.4082 47.7852C42.5738 47.7852 42.7082 47.6509 42.7082 47.4852C42.7082 47.3196 42.5738 47.1852 42.4082 47.1852C42.2425 47.1852 42.1082 47.3196 42.1082 47.4852C42.1082 47.6509 42.2425 47.7852 42.4082 47.7852Z" fill="#3E3314"/>
        <path d="M42.5195 48.7989C42.6433 48.7989 42.7437 48.6986 42.7437 48.5747C42.7437 48.4509 42.6433 48.3505 42.5195 48.3505C42.3957 48.3505 42.2953 48.4509 42.2953 48.5747C42.2953 48.6986 42.3957 48.7989 42.5195 48.7989Z" fill="#3E3314"/>
        <path d="M42.304 50.1631C42.4558 50.1631 42.5788 50.0401 42.5788 49.8884C42.5788 49.7367 42.4558 49.6136 42.304 49.6136C42.1523 49.6136 42.0293 49.7367 42.0293 49.8884C42.0293 50.0401 42.1523 50.1631 42.304 50.1631Z" fill="#3E3314"/>
        <path d="M40.5079 49.9358C40.6369 49.9358 40.7415 49.8312 40.7415 49.7021C40.7415 49.5731 40.6369 49.4684 40.5079 49.4684C40.3788 49.4684 40.2742 49.5731 40.2742 49.7021C40.2742 49.8312 40.3788 49.9358 40.5079 49.9358Z" fill="#3E3314"/>
        <path d="M38.9134 50.0021C39.0896 50.0021 39.2324 49.8593 39.2324 49.6831C39.2324 49.507 39.0896 49.3642 38.9134 49.3642C38.7373 49.3642 38.5945 49.507 38.5945 49.6831C38.5945 49.8593 38.7373 50.0021 38.9134 50.0021Z" fill="#3E3314"/>
        <path d="M39.0523 52.3831C39.2442 52.3831 39.3997 52.2276 39.3997 52.0358C39.3997 51.8439 39.2442 51.6884 39.0523 51.6884C38.8605 51.6884 38.705 51.8439 38.705 52.0358C38.705 52.2276 38.8605 52.3831 39.0523 52.3831Z" fill="#3E3314"/>
        <path d="M38.5401 53.9905C38.6395 53.9905 38.7201 53.9099 38.7201 53.8105C38.7201 53.7111 38.6395 53.6305 38.5401 53.6305C38.4407 53.6305 38.3601 53.7111 38.3601 53.8105C38.3601 53.9099 38.4407 53.9905 38.5401 53.9905Z" fill="#3E3314"/>
        <path d="M37.0146 51.5495C37.2099 51.5495 37.3683 51.3911 37.3683 51.1958C37.3683 51.0005 37.2099 50.8421 37.0146 50.8421C36.8192 50.8421 36.6609 51.0005 36.6609 51.1958C36.6609 51.3911 36.8192 51.5495 37.0146 51.5495Z" fill="#3E3314"/>
        <path d="M37.2838 53.7853C37.432 53.7853 37.5522 53.6651 37.5522 53.5168C37.5522 53.3686 37.432 53.2484 37.2838 53.2484C37.1356 53.2484 37.0154 53.3686 37.0154 53.5168C37.0154 53.6651 37.1356 53.7853 37.2838 53.7853Z" fill="#3E3314"/>
        <path d="M35.9475 52.5C36.1586 52.5 36.3296 52.3289 36.3296 52.1179C36.3296 51.9069 36.1586 51.7358 35.9475 51.7358C35.7365 51.7358 35.5654 51.9069 35.5654 52.1179C35.5654 52.3289 35.7365 52.5 35.9475 52.5Z" fill="#3E3314"/>
        <path d="M34.9122 53.1789C35.0901 53.1789 35.2343 53.0347 35.2343 52.8568C35.2343 52.6789 35.0901 52.5347 34.9122 52.5347C34.7343 52.5347 34.5901 52.6789 34.5901 52.8568C34.5901 53.0347 34.7343 53.1789 34.9122 53.1789Z" fill="#3E3314"/>
        <path d="M33.7849 52.601C33.9506 52.601 34.0849 52.4667 34.0849 52.301C34.0849 52.1354 33.9506 52.001 33.7849 52.001C33.6192 52.001 33.4849 52.1354 33.4849 52.301C33.4849 52.4667 33.6192 52.601 33.7849 52.601Z" fill="#3E3314"/>
        <path d="M32.6448 54.7232C32.8698 54.7232 33.0522 54.5408 33.0522 54.3158C33.0522 54.0908 32.8698 53.9084 32.6448 53.9084C32.4198 53.9084 32.2374 54.0908 32.2374 54.3158C32.2374 54.5408 32.4198 54.7232 32.6448 54.7232Z" fill="#3E3314"/>
        <path d="M30.5699 52.1147C30.6781 52.1147 30.7657 52.0271 30.7657 51.9189C30.7657 51.8108 30.6781 51.7231 30.5699 51.7231C30.4618 51.7231 30.3741 51.8108 30.3741 51.9189C30.3741 52.0271 30.4618 52.1147 30.5699 52.1147Z" fill="#3E3314"/>
        <path d="M31.1859 50.2895C31.3638 50.2895 31.508 50.1453 31.508 49.9674C31.508 49.7895 31.3638 49.6453 31.1859 49.6453C31.008 49.6453 30.8638 49.7895 30.8638 49.9674C30.8638 50.1453 31.008 50.2895 31.1859 50.2895Z" fill="#3E3314"/>
        <path d="M30.6173 49.5C30.7865 49.5 30.9236 49.3628 30.9236 49.1936C30.9236 49.0245 30.7865 48.8873 30.6173 48.8873C30.4482 48.8873 30.311 49.0245 30.311 49.1936C30.311 49.3628 30.4482 49.5 30.6173 49.5Z" fill="#3E3314"/>
        <path d="M26.7679 50.0621C26.8778 50.0621 26.9669 49.973 26.9669 49.8631C26.9669 49.7533 26.8778 49.6642 26.7679 49.6642C26.658 49.6642 26.569 49.7533 26.569 49.8631C26.569 49.973 26.658 50.0621 26.7679 50.0621Z" fill="#3E3314"/>
        <path d="M29.7643 47.8863C29.9352 47.8863 30.0738 47.7478 30.0738 47.5769C30.0738 47.4059 29.9352 47.2674 29.7643 47.2674C29.5934 47.2674 29.4548 47.4059 29.4548 47.5769C29.4548 47.7478 29.5934 47.8863 29.7643 47.8863Z" fill="#3E3314"/>
        <path d="M28.621 47.8516C28.846 47.8516 29.0284 47.6692 29.0284 47.4442C29.0284 47.2193 28.846 47.0369 28.621 47.0369C28.396 47.0369 28.2136 47.2193 28.2136 47.4442C28.2136 47.6692 28.396 47.8516 28.621 47.8516Z" fill="#3E3314"/>
        <path d="M27.443 46.6895C27.6453 46.6895 27.8093 46.5255 27.8093 46.3232C27.8093 46.1209 27.6453 45.9568 27.443 45.9568C27.2407 45.9568 27.0767 46.1209 27.0767 46.3232C27.0767 46.5255 27.2407 46.6895 27.443 46.6895Z" fill="#3E3314"/>
        <path d="M24.7908 47.3211C25.0263 47.3211 25.2171 47.1302 25.2171 46.8948C25.2171 46.6593 25.0263 46.4684 24.7908 46.4684C24.5554 46.4684 24.3645 46.6593 24.3645 46.8948C24.3645 47.1302 24.5554 47.3211 24.7908 47.3211Z" fill="#3E3314"/>
        <path d="M27.298 45.3221C27.3887 45.3221 27.4622 45.2486 27.4622 45.1579C27.4622 45.0672 27.3887 44.9937 27.298 44.9937C27.2073 44.9937 27.1338 45.0672 27.1338 45.1579C27.1338 45.2486 27.2073 45.3221 27.298 45.3221Z" fill="#3E3314"/>
        <path d="M24.8219 46.1747C25.033 46.1747 25.204 46.0037 25.204 45.7926C25.204 45.5816 25.033 45.4105 24.8219 45.4105C24.6109 45.4105 24.4398 45.5816 24.4398 45.7926C24.4398 46.0037 24.6109 46.1747 24.8219 46.1747Z" fill="#3E3314"/>
        <path d="M27.0517 44.0779C27.1807 44.0779 27.2854 43.9733 27.2854 43.8442C27.2854 43.7152 27.1807 43.6105 27.0517 43.6105C26.9226 43.6105 26.818 43.7152 26.818 43.8442C26.818 43.9733 26.9226 44.0779 27.0517 44.0779Z" fill="#3E3314"/>
        <path d="M38.307 28.2475C38.4186 28.2475 38.5091 28.157 38.5091 28.0454C38.5091 27.9337 38.4186 27.8433 38.307 27.8433C38.1953 27.8433 38.1049 27.9337 38.1049 28.0454C38.1049 28.157 38.1953 28.2475 38.307 28.2475Z" fill="#3E3314"/>
        <path d="M38.9764 29.5043C39.0793 29.5043 39.1627 29.4209 39.1627 29.318C39.1627 29.2151 39.0793 29.1317 38.9764 29.1317C38.8735 29.1317 38.79 29.2151 38.79 29.318C38.79 29.4209 38.8735 29.5043 38.9764 29.5043Z" fill="#3E3314"/>
        <path d="M40.1984 29.8264C40.4182 29.8264 40.5963 29.6483 40.5963 29.4285C40.5963 29.2088 40.4182 29.0306 40.1984 29.0306C39.9787 29.0306 39.8005 29.2088 39.8005 29.4285C39.8005 29.6483 39.9787 29.8264 40.1984 29.8264Z" fill="#3E3314"/>
        <path d="M39.8168 30.8275C40.0156 30.8275 40.1768 30.6663 40.1768 30.4675C40.1768 30.2686 40.0156 30.1074 39.8168 30.1074C39.618 30.1074 39.4568 30.2686 39.4568 30.4675C39.4568 30.6663 39.618 30.8275 39.8168 30.8275Z" fill="#3E3314"/>
        <path d="M42.0933 30.8054C42.2468 30.8054 42.3712 30.681 42.3712 30.5275C42.3712 30.3741 42.2468 30.2496 42.0933 30.2496C41.9398 30.2496 41.8154 30.3741 41.8154 30.5275C41.8154 30.681 41.9398 30.8054 42.0933 30.8054Z" fill="#3E3314"/>
        <path d="M39.0202 32.7192C39.205 32.7192 39.3549 32.5693 39.3549 32.3844C39.3549 32.1995 39.205 32.0496 39.0202 32.0496C38.8353 32.0496 38.6854 32.1995 38.6854 32.3844C38.6854 32.5693 38.8353 32.7192 39.0202 32.7192Z" fill="#3E3314"/>
        <path d="M42.5763 31.4813C42.7228 31.4813 42.8416 31.3625 42.8416 31.216C42.8416 31.0695 42.7228 30.9507 42.5763 30.9507C42.4298 30.9507 42.311 31.0695 42.311 31.216C42.311 31.3625 42.4298 31.4813 42.5763 31.4813Z" fill="#3E3314"/>
        <path d="M40.7297 33.1612C40.8954 33.1612 41.0297 33.0269 41.0297 32.8612C41.0297 32.6955 40.8954 32.5612 40.7297 32.5612C40.564 32.5612 40.4297 32.6955 40.4297 32.8612C40.4297 33.0269 40.564 33.1612 40.7297 33.1612Z" fill="#3E3314"/>
        <path d="M42.9139 32.441C43.0343 32.441 43.1318 32.3435 43.1318 32.2231C43.1318 32.1028 43.0343 32.0052 42.9139 32.0052C42.7936 32.0052 42.696 32.1028 42.696 32.2231C42.696 32.3435 42.7936 32.441 42.9139 32.441Z" fill="#3E3314"/>
        <path d="M42.8073 34.0548C42.9398 34.0548 43.0473 33.9473 43.0473 33.8148C43.0473 33.6822 42.9398 33.5748 42.8073 33.5748C42.6747 33.5748 42.5673 33.6822 42.5673 33.8148C42.5673 33.9473 42.6747 34.0548 42.8073 34.0548Z" fill="#3E3314"/>
        <path d="M41.7339 35.9907C41.8874 35.9907 42.0118 35.8663 42.0118 35.7128C42.0118 35.5594 41.8874 35.4349 41.7339 35.4349C41.5805 35.4349 41.4561 35.5594 41.4561 35.7128C41.4561 35.8663 41.5805 35.9907 41.7339 35.9907Z" fill="#3E3314"/>
        <path d="M42.7761 36.9223C42.9906 36.9223 43.1645 36.7484 43.1645 36.5339C43.1645 36.3194 42.9906 36.1454 42.7761 36.1454C42.5616 36.1454 42.3877 36.3194 42.3877 36.5339C42.3877 36.7484 42.5616 36.9223 42.7761 36.9223Z" fill="#3E3314"/>
        <path d="M44.5599 37.3771C44.7884 37.3771 44.9736 37.1918 44.9736 36.9634C44.9736 36.7349 44.7884 36.5497 44.5599 36.5497C44.3315 36.5497 44.1462 36.7349 44.1462 36.9634C44.1462 37.1918 44.3315 37.3771 44.5599 37.3771Z" fill="#3E3314"/>
        <path d="M42.8192 38.8802C43.0093 38.8802 43.1634 38.7261 43.1634 38.536C43.1634 38.3459 43.0093 38.1918 42.8192 38.1918C42.6291 38.1918 42.475 38.3459 42.475 38.536C42.475 38.7261 42.6291 38.8802 42.8192 38.8802Z" fill="#3E3314"/>
        <path d="M45.5729 40.1307C45.688 40.1307 45.7813 40.0374 45.7813 39.9223C45.7813 39.8072 45.688 39.7139 45.5729 39.7139C45.4578 39.7139 45.3645 39.8072 45.3645 39.9223C45.3645 40.0374 45.4578 40.1307 45.5729 40.1307Z" fill="#3E3314"/>
        <path d="M42.9518 41.3969C43.1454 41.3969 43.3024 41.2399 43.3024 41.0463C43.3024 40.8527 43.1454 40.6958 42.9518 40.6958C42.7583 40.6958 42.6013 40.8527 42.6013 41.0463C42.6013 41.2399 42.7583 41.3969 42.9518 41.3969Z" fill="#3E3314"/>
        <path d="M45.8423 45.0947C45.9888 45.0947 46.1075 44.976 46.1075 44.8295C46.1075 44.683 45.9888 44.5642 45.8423 44.5642C45.6958 44.5642 45.577 44.683 45.577 44.8295C45.577 44.976 45.6958 45.0947 45.8423 45.0947Z" fill="#3E3314"/>
        <path d="M41.9013 42.7991C42.0513 42.7991 42.1729 42.6775 42.1729 42.5275C42.1729 42.3775 42.0513 42.2559 41.9013 42.2559C41.7514 42.2559 41.6298 42.3775 41.6298 42.5275C41.6298 42.6775 41.7514 42.7991 41.9013 42.7991Z" fill="#3E3314"/>
        <path d="M43.2802 46.8221C43.4982 46.8221 43.675 46.6454 43.675 46.4274C43.675 46.2094 43.4982 46.0327 43.2802 46.0327C43.0622 46.0327 42.8855 46.2094 42.8855 46.4274C42.8855 46.6454 43.0622 46.8221 43.2802 46.8221Z" fill="#3E3314"/>
        <path d="M40.06 44.3969C40.1489 44.3969 40.221 44.3248 40.221 44.2358C40.221 44.1469 40.1489 44.0748 40.06 44.0748C39.971 44.0748 39.8989 44.1469 39.8989 44.2358C39.8989 44.3248 39.971 44.3969 40.06 44.3969Z" fill="#3E3314"/>
        <path d="M40.9467 47.7127C41.1054 47.7127 41.234 47.584 41.234 47.4253C41.234 47.2666 41.1054 47.1379 40.9467 47.1379C40.788 47.1379 40.6593 47.2666 40.6593 47.4253C40.6593 47.584 40.788 47.7127 40.9467 47.7127Z" fill="#3E3314"/>
        <path d="M39.3266 46.8633C39.4365 46.8633 39.5256 46.7743 39.5256 46.6644C39.5256 46.5545 39.4365 46.4655 39.3266 46.4655C39.2168 46.4655 39.1277 46.5545 39.1277 46.6644C39.1277 46.7743 39.2168 46.8633 39.3266 46.8633Z" fill="#3E3314"/>
        <path d="M37.8868 46.5348C38.0246 46.5348 38.1363 46.4231 38.1363 46.2853C38.1363 46.1475 38.0246 46.0358 37.8868 46.0358C37.749 46.0358 37.6373 46.1475 37.6373 46.2853C37.6373 46.4231 37.749 46.5348 37.8868 46.5348Z" fill="#3E3314"/>
        <path d="M37.4231 47.4063C37.5644 47.4063 37.6789 47.2918 37.6789 47.1506C37.6789 47.0093 37.5644 46.8948 37.4231 46.8948C37.2819 46.8948 37.1674 47.0093 37.1674 47.1506C37.1674 47.2918 37.2819 47.4063 37.4231 47.4063Z" fill="#3E3314"/>
        <path d="M37.413 50.2706C37.6258 50.2706 37.7982 50.0981 37.7982 49.8853C37.7982 49.6725 37.6258 49.5001 37.413 49.5001C37.2002 49.5001 37.0277 49.6725 37.0277 49.8853C37.0277 50.0981 37.2002 50.2706 37.413 50.2706Z" fill="#3E3314"/>
        <path d="M35.2631 48.3002C35.4584 48.3002 35.6168 48.1419 35.6168 47.9465C35.6168 47.7512 35.4584 47.5928 35.2631 47.5928C35.0678 47.5928 34.9094 47.7512 34.9094 47.9465C34.9094 48.1419 35.0678 48.3002 35.2631 48.3002Z" fill="#3E3314"/>
        <path d="M33.0173 50.3054C33.2388 50.3054 33.4183 50.1259 33.4183 49.9044C33.4183 49.6829 33.2388 49.5033 33.0173 49.5033C32.7958 49.5033 32.6162 49.6829 32.6162 49.9044C32.6162 50.1259 32.7958 50.3054 33.0173 50.3054Z" fill="#3E3314"/>
        <path d="M29.5064 49.1432C29.6738 49.1432 29.8096 49.0075 29.8096 48.84C29.8096 48.6726 29.6738 48.5369 29.5064 48.5369C29.339 48.5369 29.2032 48.6726 29.2032 48.84C29.2032 49.0075 29.339 49.1432 29.5064 49.1432Z" fill="#3E3314"/>
        <path d="M31.2682 46.3643C31.4984 46.3643 31.685 46.1776 31.685 45.9474C31.685 45.7172 31.4984 45.5306 31.2682 45.5306C31.038 45.5306 30.8514 45.7172 30.8514 45.9474C30.8514 46.1776 31.038 46.3643 31.2682 46.3643Z" fill="#3E3314"/>
        <path d="M30.5167 45.5749C30.6458 45.5749 30.7504 45.4703 30.7504 45.3412C30.7504 45.2122 30.6458 45.1075 30.5167 45.1075C30.3877 45.1075 30.2831 45.2122 30.2831 45.3412C30.2831 45.4703 30.3877 45.5749 30.5167 45.5749Z" fill="#3E3314"/>
        <path d="M28.5555 45.4011C28.6967 45.4011 28.8113 45.2866 28.8113 45.1453C28.8113 45.004 28.6967 44.8895 28.5555 44.8895C28.4142 44.8895 28.2997 45.004 28.2997 45.1453C28.2997 45.2866 28.4142 45.4011 28.5555 45.4011Z" fill="#3E3314"/>
        <path d="M29.923 43.156C30.1341 43.156 30.3052 42.9849 30.3052 42.7739C30.3052 42.5629 30.1341 42.3918 29.923 42.3918C29.712 42.3918 29.5409 42.5629 29.5409 42.7739C29.5409 42.9849 29.712 43.156 29.923 43.156Z" fill="#3E3314"/>
        <path d="M28.1546 43.1497C28.2836 43.1497 28.3883 43.0451 28.3883 42.916C28.3883 42.7869 28.2836 42.6823 28.1546 42.6823C28.0255 42.6823 27.9209 42.7869 27.9209 42.916C27.9209 43.0451 28.0255 43.1497 28.1546 43.1497Z" fill="#3E3314"/>
        <path d="M27.5171 42.1422C27.6235 42.1422 27.7097 42.0559 27.7097 41.9495C27.7097 41.8431 27.6235 41.7569 27.5171 41.7569C27.4107 41.7569 27.3245 41.8431 27.3245 41.9495C27.3245 42.0559 27.4107 42.1422 27.5171 42.1422Z" fill="#3E3314"/>
        <path d="M24.3713 42.7864C24.5125 42.7864 24.6271 42.6718 24.6271 42.5306C24.6271 42.3893 24.5125 42.2748 24.3713 42.2748C24.23 42.2748 24.1155 42.3893 24.1155 42.5306C24.1155 42.6718 24.23 42.7864 24.3713 42.7864Z" fill="#3E3314"/>
        <path d="M27.0585 39.7264C27.1893 39.7264 27.2953 39.6203 27.2953 39.4895C27.2953 39.3587 27.1893 39.2527 27.0585 39.2527C26.9277 39.2527 26.8217 39.3587 26.8217 39.4895C26.8217 39.6203 26.9277 39.7264 27.0585 39.7264Z" fill="#3E3314"/>
        <path d="M23.2408 40.9769C23.464 40.9769 23.645 40.7959 23.645 40.5727C23.645 40.3494 23.464 40.1685 23.2408 40.1685C23.0175 40.1685 22.8365 40.3494 22.8365 40.5727C22.8365 40.7959 23.0175 40.9769 23.2408 40.9769Z" fill="#3E3314"/>
        <path d="M37.6785 30.3474C37.7831 30.3474 37.868 30.2626 37.868 30.1579C37.868 30.0533 37.7831 29.9684 37.6785 29.9684C37.5738 29.9684 37.489 30.0533 37.489 30.1579C37.489 30.2626 37.5738 30.3474 37.6785 30.3474Z" fill="#3E3314"/>
        <path d="M36.8002 30.1453C36.9973 30.1453 37.157 29.9856 37.157 29.7885C37.157 29.5914 36.9973 29.4316 36.8002 29.4316C36.6031 29.4316 36.4434 29.5914 36.4434 29.7885C36.4434 29.9856 36.6031 30.1453 36.8002 30.1453Z" fill="#3E3314"/>
        <path d="M33.3455 31.5031C33.4396 31.5031 33.516 31.4267 33.516 31.3326C33.516 31.2384 33.4396 31.162 33.3455 31.162C33.2513 31.162 33.1749 31.2384 33.1749 31.3326C33.1749 31.4267 33.2513 31.5031 33.3455 31.5031Z" fill="#3E3314"/>
        <path d="M35.1797 29.9052C35.4169 29.9052 35.6092 29.7129 35.6092 29.4757C35.6092 29.2385 35.4169 29.0463 35.1797 29.0463C34.9425 29.0463 34.7502 29.2385 34.7502 29.4757C34.7502 29.7129 34.9425 29.9052 35.1797 29.9052Z" fill="#3E3314"/>
        <path d="M33.1504 48.1643C33.3387 48.1643 33.4914 48.0117 33.4914 47.8233C33.4914 47.6349 33.3387 47.4822 33.1504 47.4822C32.962 47.4822 32.8093 47.6349 32.8093 47.8233C32.8093 48.0117 32.962 48.1643 33.1504 48.1643Z" fill="#3E3314"/>
        <path d="M33.5362 48.5053C33.7611 48.5053 33.9435 48.3229 33.9435 48.0979C33.9435 47.8729 33.7611 47.6906 33.5362 47.6906C33.3112 47.6906 33.1288 47.8729 33.1288 48.0979C33.1288 48.3229 33.3112 48.5053 33.5362 48.5053Z" fill="#3E3314"/>
        <path d="M34.0537 49.1559C34.2386 49.1559 34.3885 49.0061 34.3885 48.8212C34.3885 48.6363 34.2386 48.4865 34.0537 48.4865C33.8689 48.4865 33.719 48.6363 33.719 48.8212C33.719 49.0061 33.8689 49.1559 34.0537 49.1559Z" fill="#3E3314"/>
        <path d="M34.6757 48.1202C34.7734 48.1202 34.8526 48.041 34.8526 47.9433C34.8526 47.8457 34.7734 47.7665 34.6757 47.7665C34.5781 47.7665 34.4989 47.8457 34.4989 47.9433C34.4989 48.041 34.5781 48.1202 34.6757 48.1202Z" fill="#3E3314"/>
        <path d="M35.0604 48C35.2679 48 35.4361 47.8317 35.4361 47.6242C35.4361 47.4167 35.2679 47.2484 35.0604 47.2484C34.8528 47.2484 34.6846 47.4167 34.6846 47.6242C34.6846 47.8317 34.8528 48 35.0604 48Z" fill="#3E3314"/>
        <path d="M35.3834 47.3874C35.6084 47.3874 35.7908 47.205 35.7908 46.98C35.7908 46.755 35.6084 46.5726 35.3834 46.5726C35.1585 46.5726 34.9761 46.755 34.9761 46.98C34.9761 47.205 35.1585 47.3874 35.3834 47.3874Z" fill="#3E3314"/>
        <path d="M36.9938 48.7074C37.0933 48.7074 37.1738 48.6268 37.1738 48.5273C37.1738 48.4279 37.0933 48.3474 36.9938 48.3474C36.8944 48.3474 36.8138 48.4279 36.8138 48.5273C36.8138 48.6268 36.8944 48.7074 36.9938 48.7074Z" fill="#3E3314"/>
        <path d="M36.6868 47.8926C36.8333 47.8926 36.952 47.7739 36.952 47.6274C36.952 47.4809 36.8333 47.3621 36.6868 47.3621C36.5403 47.3621 36.4215 47.4809 36.4215 47.6274C36.4215 47.7739 36.5403 47.8926 36.6868 47.8926Z" fill="#3E3314"/>
        <path d="M36.6653 46.7148C36.7839 46.7148 36.88 46.6186 36.88 46.5C36.88 46.3814 36.7839 46.2853 36.6653 46.2853C36.5467 46.2853 36.4506 46.3814 36.4506 46.5C36.4506 46.6186 36.5467 46.7148 36.6653 46.7148Z" fill="#3E3314"/>
        <path d="M38.708 47.6875C38.9103 47.6875 39.0743 47.5235 39.0743 47.3212C39.0743 47.1189 38.9103 46.9549 38.708 46.9549C38.5057 46.9549 38.3417 47.1189 38.3417 47.3212C38.3417 47.5235 38.5057 47.6875 38.708 47.6875Z" fill="#3E3314"/>
        <path d="M38.7023 46.0516C38.8419 46.0516 38.955 45.9385 38.955 45.799C38.955 45.6594 38.8419 45.5463 38.7023 45.5463C38.5628 45.5463 38.4497 45.6594 38.4497 45.799C38.4497 45.9385 38.5628 46.0516 38.7023 46.0516Z" fill="#3E3314"/>
        <path d="M38.2343 43.9927C38.4419 43.9927 38.6101 43.8244 38.6101 43.6169C38.6101 43.4093 38.4419 43.2411 38.2343 43.2411C38.0268 43.2411 37.8585 43.4093 37.8585 43.6169C37.8585 43.8244 38.0268 43.9927 38.2343 43.9927Z" fill="#3E3314"/>
        <path d="M38.5816 42.4042C38.7542 42.4042 38.8942 42.2642 38.8942 42.0916C38.8942 41.9189 38.7542 41.7789 38.5816 41.7789C38.4089 41.7789 38.2689 41.9189 38.2689 42.0916C38.2689 42.2642 38.4089 42.4042 38.5816 42.4042Z" fill="#3E3314"/>
        <path d="M40.8622 42.8116C41.075 42.8116 41.2475 42.6391 41.2475 42.4263C41.2475 42.2136 41.075 42.0411 40.8622 42.0411C40.6494 42.0411 40.4769 42.2136 40.4769 42.4263C40.4769 42.6391 40.6494 42.8116 40.8622 42.8116Z" fill="#3E3314"/>
        <path d="M40.8685 41.217C41.015 41.217 41.1338 41.0982 41.1338 40.9517C41.1338 40.8052 41.015 40.6865 40.8685 40.6865C40.722 40.6865 40.6033 40.8052 40.6033 40.9517C40.6033 41.0982 40.722 41.217 40.8685 41.217Z" fill="#3E3314"/>
        <path d="M40.7007 40.4465C40.7914 40.4465 40.8649 40.3729 40.8649 40.2822C40.8649 40.1916 40.7914 40.118 40.7007 40.118C40.61 40.118 40.5365 40.1916 40.5365 40.2822C40.5365 40.3729 40.61 40.4465 40.7007 40.4465Z" fill="#3E3314"/>
        <path d="M39.3775 40.7463C39.5048 40.7463 39.608 40.6431 39.608 40.5158C39.608 40.3885 39.5048 40.2853 39.3775 40.2853C39.2502 40.2853 39.147 40.3885 39.147 40.5158C39.147 40.6431 39.2502 40.7463 39.3775 40.7463Z" fill="#3E3314"/>
        <path d="M42.4533 41.937C42.5963 41.937 42.7122 41.8211 42.7122 41.6781C42.7122 41.5351 42.5963 41.4191 42.4533 41.4191C42.3103 41.4191 42.1943 41.5351 42.1943 41.6781C42.1943 41.8211 42.3103 41.937 42.4533 41.937Z" fill="#3E3314"/>
        <path d="M40.2521 42.3C40.4352 42.3 40.5837 42.1515 40.5837 41.9684C40.5837 41.7853 40.4352 41.6368 40.2521 41.6368C40.069 41.6368 39.9205 41.7853 39.9205 41.9684C39.9205 42.1515 40.069 42.3 40.2521 42.3Z" fill="#3E3314"/>
        <path d="M39.3963 42.1611C39.5742 42.1611 39.7184 42.0168 39.7184 41.839C39.7184 41.6611 39.5742 41.5168 39.3963 41.5168C39.2184 41.5168 39.0742 41.6611 39.0742 41.839C39.0742 42.0168 39.2184 42.1611 39.3963 42.1611Z" fill="#3E3314"/>
        <path d="M39.2517 41.8169C39.4383 41.8169 39.5896 41.6656 39.5896 41.479C39.5896 41.2924 39.4383 41.1411 39.2517 41.1411C39.0651 41.1411 38.9138 41.2924 38.9138 41.479C38.9138 41.6656 39.0651 41.8169 39.2517 41.8169Z" fill="#3E3314"/>
        <path d="M39.2292 41.5642C39.4663 41.5642 39.6586 41.3719 39.6586 41.1347C39.6586 40.8975 39.4663 40.7053 39.2292 40.7053C38.992 40.7053 38.7997 40.8975 38.7997 41.1347C38.7997 41.3719 38.992 41.5642 39.2292 41.5642Z" fill="#3E3314"/>
        <path d="M42.1723 38.4032C42.2647 38.4032 42.3396 38.3282 42.3396 38.2358C42.3396 38.1434 42.2647 38.0684 42.1723 38.0684C42.0798 38.0684 42.0049 38.1434 42.0049 38.2358C42.0049 38.3282 42.0798 38.4032 42.1723 38.4032Z" fill="#3E3314"/>
        <path d="M39.9556 38.9844C40.048 38.9844 40.1229 38.9095 40.1229 38.817C40.1229 38.7246 40.048 38.6497 39.9556 38.6497C39.8631 38.6497 39.7882 38.7246 39.7882 38.817C39.7882 38.9095 39.8631 38.9844 39.9556 38.9844Z" fill="#3E3314"/>
        <path d="M41.108 37.2412C41.258 37.2412 41.3796 37.1196 41.3796 36.9696C41.3796 36.8196 41.258 36.6981 41.108 36.6981C40.958 36.6981 40.8364 36.8196 40.8364 36.9696C40.8364 37.1196 40.958 37.2412 41.108 37.2412Z" fill="#3E3314"/>
        <path d="M39.4224 37.56C39.5288 37.56 39.615 37.4738 39.615 37.3674C39.615 37.261 39.5288 37.1747 39.4224 37.1747C39.316 37.1747 39.2297 37.261 39.2297 37.3674C39.2297 37.4738 39.316 37.56 39.4224 37.56Z" fill="#3E3314"/>
        <path d="M37.511 37.8158C37.6592 37.8158 37.7794 37.6956 37.7794 37.5474C37.7794 37.3991 37.6592 37.2789 37.511 37.2789C37.3627 37.2789 37.2426 37.3991 37.2426 37.5474C37.2426 37.6956 37.3627 37.8158 37.511 37.8158Z" fill="#3E3314"/>
        <path d="M38.3858 36.2589C38.5637 36.2589 38.7079 36.1147 38.7079 35.9368C38.7079 35.759 38.5637 35.6147 38.3858 35.6147C38.2079 35.6147 38.0637 35.759 38.0637 35.9368C38.0637 36.1147 38.2079 36.2589 38.3858 36.2589Z" fill="#3E3314"/>
        <path d="M37.3411 35.8958C37.519 35.8958 37.6633 35.7516 37.6633 35.5737C37.6633 35.3958 37.519 35.2516 37.3411 35.2516C37.1633 35.2516 37.019 35.3958 37.019 35.5737C37.019 35.7516 37.1633 35.8958 37.3411 35.8958Z" fill="#3E3314"/>
        <path d="M37.3437 34.917C37.4466 34.917 37.53 34.8336 37.53 34.7307C37.53 34.6278 37.4466 34.5444 37.3437 34.5444C37.2408 34.5444 37.1573 34.6278 37.1573 34.7307C37.1573 34.8336 37.2408 34.917 37.3437 34.917Z" fill="#3E3314"/>
        <path d="M36.5385 33.8874C36.7251 33.8874 36.8764 33.7361 36.8764 33.5495C36.8764 33.3629 36.7251 33.2116 36.5385 33.2116C36.3518 33.2116 36.2006 33.3629 36.2006 33.5495C36.2006 33.7361 36.3518 33.8874 36.5385 33.8874Z" fill="#3E3314"/>
        <path d="M36.8764 33.1232C37.1136 33.1232 37.3058 32.9309 37.3058 32.6937C37.3058 32.4565 37.1136 32.2642 36.8764 32.2642C36.6392 32.2642 36.4469 32.4565 36.4469 32.6937C36.4469 32.9309 36.6392 33.1232 36.8764 33.1232Z" fill="#3E3314"/>
        <path d="M34.3284 31.8601C34.5307 31.8601 34.6947 31.6961 34.6947 31.4937C34.6947 31.2914 34.5307 31.1273 34.3284 31.1273C34.126 31.1273 33.962 31.2914 33.962 31.4937C33.962 31.6961 34.126 31.8601 34.3284 31.8601Z" fill="#3E3314"/>
        <path d="M36.9401 30.3127C37.1406 30.3127 37.3032 30.1501 37.3032 29.9495C37.3032 29.7489 37.1406 29.5863 36.9401 29.5863C36.7395 29.5863 36.5769 29.7489 36.5769 29.9495C36.5769 30.1501 36.7395 30.3127 36.9401 30.3127Z" fill="#3E3314"/>
        <path d="M33.1438 41.261C33.3862 41.261 33.5827 41.0645 33.5827 40.8221C33.5827 40.5796 33.3862 40.3831 33.1438 40.3831C32.9014 40.3831 32.7048 40.5796 32.7048 40.8221C32.7048 41.0645 32.9014 41.261 33.1438 41.261Z" fill="#3E3314"/>
        <path d="M33.2296 41.7631C33.4686 41.7631 33.6623 41.5694 33.6623 41.3305C33.6623 41.0915 33.4686 40.8978 33.2296 40.8978C32.9907 40.8978 32.797 41.0915 32.797 41.3305C32.797 41.5694 32.9907 41.7631 33.2296 41.7631Z" fill="#3E3314"/>
        <path d="M32.8822 43.841C33.013 43.841 33.1191 43.735 33.1191 43.6041C33.1191 43.4733 33.013 43.3673 32.8822 43.3673C32.7514 43.3673 32.6454 43.4733 32.6454 43.6041C32.6454 43.735 32.7514 43.841 32.8822 43.841Z" fill="#3E3314"/>
        <path d="M33.3181 42.9821C33.4541 42.9821 33.5644 42.8718 33.5644 42.7358C33.5644 42.5997 33.4541 42.4894 33.3181 42.4894C33.1821 42.4894 33.0718 42.5997 33.0718 42.7358C33.0718 42.8718 33.1821 42.9821 33.3181 42.9821Z" fill="#3E3314"/>
        <path d="M33.7823 42.1136C33.9218 42.1136 34.0349 42.0005 34.0349 41.861C34.0349 41.7214 33.9218 41.6083 33.7823 41.6083C33.6428 41.6083 33.5297 41.7214 33.5297 41.861C33.5297 42.0005 33.6428 42.1136 33.7823 42.1136Z" fill="#3E3314"/>
        <path d="M33.4154 40.7621C33.5898 40.7621 33.7312 40.6207 33.7312 40.4463C33.7312 40.2719 33.5898 40.1305 33.4154 40.1305C33.241 40.1305 33.0996 40.2719 33.0996 40.4463C33.0996 40.6207 33.241 40.7621 33.4154 40.7621Z" fill="#3E3314"/>
        <path d="M35.8569 43.7463C36.0697 43.7463 36.2422 43.5738 36.2422 43.361C36.2422 43.1483 36.0697 42.9758 35.8569 42.9758C35.6442 42.9758 35.4717 43.1483 35.4717 43.361C35.4717 43.5738 35.6442 43.7463 35.8569 43.7463Z" fill="#3E3314"/>
        <path d="M35.4625 42.0536C35.6264 42.0536 35.7593 41.9207 35.7593 41.7568C35.7593 41.5929 35.6264 41.46 35.4625 41.46C35.2986 41.46 35.1656 41.5929 35.1656 41.7568C35.1656 41.9207 35.2986 42.0536 35.4625 42.0536Z" fill="#3E3314"/>
        <path d="M36.1381 42.4326C36.3753 42.4326 36.5676 42.2403 36.5676 42.0031C36.5676 41.7659 36.3753 41.5737 36.1381 41.5737C35.9009 41.5737 35.7086 41.7659 35.7086 42.0031C35.7086 42.2403 35.9009 42.4326 36.1381 42.4326Z" fill="#3E3314"/>
        <path d="M36.558 41.321C36.647 41.321 36.7191 41.2489 36.7191 41.16C36.7191 41.071 36.647 40.9989 36.558 40.9989C36.4691 40.9989 36.397 41.071 36.397 41.16C36.397 41.2489 36.4691 41.321 36.558 41.321Z" fill="#3E3314"/>
        <path d="M35.6701 38.9526C35.8427 38.9526 35.9827 38.8126 35.9827 38.64C35.9827 38.4673 35.8427 38.3273 35.6701 38.3273C35.4974 38.3273 35.3574 38.4673 35.3574 38.64C35.3574 38.8126 35.4974 38.9526 35.6701 38.9526Z" fill="#3E3314"/>
        <path d="M37.2148 40.4842C37.4189 40.4842 37.5843 40.3188 37.5843 40.1147C37.5843 39.9107 37.4189 39.7452 37.2148 39.7452C37.0108 39.7452 36.8453 39.9107 36.8453 40.1147C36.8453 40.3188 37.0108 40.4842 37.2148 40.4842Z" fill="#3E3314"/>
        <path d="M38.5951 41.2295C38.6841 41.2295 38.7562 41.1573 38.7562 41.0684C38.7562 40.9795 38.6841 40.9073 38.5951 40.9073C38.5062 40.9073 38.4341 40.9795 38.4341 41.0684C38.4341 41.1573 38.5062 41.2295 38.5951 41.2295Z" fill="#3E3314"/>
        <path d="M37.4861 39.6979C37.6536 39.6979 37.7893 39.5621 37.7893 39.3947C37.7893 39.2273 37.6536 39.0916 37.4861 39.0916C37.3187 39.0916 37.183 39.2273 37.183 39.3947C37.183 39.5621 37.3187 39.6979 37.4861 39.6979Z" fill="#3E3314"/>
        <path d="M36.6879 38.0399C36.8867 38.0399 37.0479 37.8788 37.0479 37.6799C37.0479 37.4811 36.8867 37.3199 36.6879 37.3199C36.4891 37.3199 36.3279 37.4811 36.3279 37.6799C36.3279 37.8788 36.4891 38.0399 36.6879 38.0399Z" fill="#3E3314"/>
        <path d="M37.4259 38.7316C37.5829 38.7316 37.7101 38.6043 37.7101 38.4474C37.7101 38.2904 37.5829 38.1631 37.4259 38.1631C37.269 38.1631 37.1417 38.2904 37.1417 38.4474C37.1417 38.6043 37.269 38.7316 37.4259 38.7316Z" fill="#3E3314"/>
        <path d="M37.9752 39.0189C38.1479 39.0189 38.2879 38.8789 38.2879 38.7062C38.2879 38.5336 38.1479 38.3936 37.9752 38.3936C37.8026 38.3936 37.6626 38.5336 37.6626 38.7062C37.6626 38.8789 37.8026 39.0189 37.9752 39.0189Z" fill="#3E3314"/>
        <path d="M36.4002 38.6715C36.5241 38.6715 36.6244 38.5711 36.6244 38.4473C36.6244 38.3235 36.5241 38.2231 36.4002 38.2231C36.2764 38.2231 36.176 38.3235 36.176 38.4473C36.176 38.5711 36.2764 38.6715 36.4002 38.6715Z" fill="#3E3314"/>
        <path d="M38.8318 38.8168C38.9817 38.8168 39.1033 38.6952 39.1033 38.5452C39.1033 38.3952 38.9817 38.2736 38.8318 38.2736C38.6818 38.2736 38.5602 38.3952 38.5602 38.5452C38.5602 38.6952 38.6818 38.8168 38.8318 38.8168Z" fill="#3E3314"/>
        <path d="M37.1894 38.3842C37.3062 38.3842 37.4009 38.2894 37.4009 38.1726C37.4009 38.0557 37.3062 37.961 37.1894 37.961C37.0725 37.961 36.9778 38.0557 36.9778 38.1726C36.9778 38.2894 37.0725 38.3842 37.1894 38.3842Z" fill="#3E3314"/>
        <path d="M35.6924 37.6389C35.8022 37.6389 35.8913 37.5498 35.8913 37.44C35.8913 37.3301 35.8022 37.241 35.6924 37.241C35.5825 37.241 35.4934 37.3301 35.4934 37.44C35.4934 37.5498 35.5825 37.6389 35.6924 37.6389Z" fill="#3E3314"/>
        <path d="M38.8922 36.3158C39.0527 36.3158 39.1827 36.1857 39.1827 36.0253C39.1827 35.8648 39.0527 35.7347 38.8922 35.7347C38.7318 35.7347 38.6017 35.8648 38.6017 36.0253C38.6017 36.1857 38.7318 36.3158 38.8922 36.3158Z" fill="#3E3314"/>
        <path d="M35.784 35.9684C35.9357 35.9684 36.0587 35.8454 36.0587 35.6936C36.0587 35.5419 35.9357 35.4189 35.784 35.4189C35.6323 35.4189 35.5093 35.5419 35.5093 35.6936C35.5093 35.8454 35.6323 35.9684 35.784 35.9684Z" fill="#3E3314"/>
        <path d="M36.6943 34.1652C36.8007 34.1652 36.887 34.0789 36.887 33.9725C36.887 33.8662 36.8007 33.7799 36.6943 33.7799C36.588 33.7799 36.5017 33.8662 36.5017 33.9725C36.5017 34.0789 36.588 34.1652 36.6943 34.1652Z" fill="#3E3314"/>
        <path d="M38.4714 32.7441C38.6249 32.7441 38.7493 32.6197 38.7493 32.4662C38.7493 32.3128 38.6249 32.1884 38.4714 32.1884C38.3179 32.1884 38.1935 32.3128 38.1935 32.4662C38.1935 32.6197 38.3179 32.7441 38.4714 32.7441Z" fill="#3E3314"/>
        <path d="M34.8648 32.9178C35.0985 32.9178 35.288 32.7284 35.288 32.4947C35.288 32.261 35.0985 32.0715 34.8648 32.0715C34.6311 32.0715 34.4417 32.261 34.4417 32.4947C34.4417 32.7284 34.6311 32.9178 34.8648 32.9178Z" fill="#3E3314"/>
        <path d="M25.454 38.5674C25.5586 38.5674 25.6435 38.4825 25.6435 38.3779C25.6435 38.2732 25.5586 38.1884 25.454 38.1884C25.3494 38.1884 25.2645 38.2732 25.2645 38.3779C25.2645 38.4825 25.3494 38.5674 25.454 38.5674Z" fill="#3E3314"/>
        <path d="M28.887 37.1589C29.0335 37.1589 29.1522 37.0401 29.1522 36.8936C29.1522 36.7471 29.0335 36.6284 28.887 36.6284C28.7405 36.6284 28.6217 36.7471 28.6217 36.8936C28.6217 37.0401 28.7405 37.1589 28.887 37.1589Z" fill="#3E3314"/>
        <path d="M27.2067 39.562C27.3637 39.562 27.4909 39.4348 27.4909 39.2778C27.4909 39.1208 27.3637 38.9936 27.2067 38.9936C27.0497 38.9936 26.9225 39.1208 26.9225 39.2778C26.9225 39.4348 27.0497 39.562 27.2067 39.562Z" fill="#3E3314"/>
        <path d="M28.189 39.8494C28.3337 39.8494 28.4511 39.7321 28.4511 39.5873C28.4511 39.4425 28.3337 39.3252 28.189 39.3252C28.0442 39.3252 27.9269 39.4425 27.9269 39.5873C27.9269 39.7321 28.0442 39.8494 28.189 39.8494Z" fill="#3E3314"/>
        <path d="M27.6617 41.1694C27.8117 41.1694 27.9333 41.0479 27.9333 40.8979C27.9333 40.7479 27.8117 40.6263 27.6617 40.6263C27.5117 40.6263 27.3901 40.7479 27.3901 40.8979C27.3901 41.0479 27.5117 41.1694 27.6617 41.1694Z" fill="#3E3314"/>
        <path d="M28.7851 41.8073C28.9089 41.8073 29.0093 41.7069 29.0093 41.5831C29.0093 41.4593 28.9089 41.3589 28.7851 41.3589C28.6613 41.3589 28.5609 41.4593 28.5609 41.5831C28.5609 41.7069 28.6613 41.8073 28.7851 41.8073Z" fill="#3E3314"/>
        <path d="M31.9851 39.0884C32.0845 39.0884 32.165 39.0078 32.165 38.9084C32.165 38.809 32.0845 38.7284 31.9851 38.7284C31.8856 38.7284 31.8051 38.809 31.8051 38.9084C31.8051 39.0078 31.8856 39.0884 31.9851 39.0884Z" fill="#3E3314"/>
        <path d="M30.4027 42.6694C30.5928 42.6694 30.747 42.5153 30.747 42.3252C30.747 42.1351 30.5928 41.981 30.4027 41.981C30.2126 41.981 30.0585 42.1351 30.0585 42.3252C30.0585 42.5153 30.2126 42.6694 30.4027 42.6694Z" fill="#3E3314"/>
        <path d="M32.717 39.7547C32.9438 39.7547 33.1276 39.5709 33.1276 39.3442C33.1276 39.1175 32.9438 38.9337 32.717 38.9337C32.4903 38.9337 32.3065 39.1175 32.3065 39.3442C32.3065 39.5709 32.4903 39.7547 32.717 39.7547Z" fill="#3E3314"/>
        <path d="M32.3545 42.461C32.5882 42.461 32.7776 42.2715 32.7776 42.0378C32.7776 41.8041 32.5882 41.6147 32.3545 41.6147C32.1208 41.6147 31.9314 41.8041 31.9314 42.0378C31.9314 42.2715 32.1208 42.461 32.3545 42.461Z" fill="#3E3314"/>
        <path d="M32.5812 43.6452C32.7294 43.6452 32.8496 43.525 32.8496 43.3768C32.8496 43.2285 32.7294 43.1083 32.5812 43.1083C32.433 43.1083 32.3129 43.2285 32.3129 43.3768C32.3129 43.525 32.433 43.6452 32.5812 43.6452Z" fill="#3E3314"/>
        <path d="M34.0185 39.9631C34.1825 39.9631 34.3154 39.8302 34.3154 39.6663C34.3154 39.5023 34.1825 39.3694 34.0185 39.3694C33.8546 39.3694 33.7217 39.5023 33.7217 39.6663C33.7217 39.8302 33.8546 39.9631 34.0185 39.9631Z" fill="#3E3314"/>
        <path d="M33.6421 41.5073C33.8235 41.5073 33.9706 41.3603 33.9706 41.1789C33.9706 40.9975 33.8235 40.8505 33.6421 40.8505C33.4608 40.8505 33.3137 40.9975 33.3137 41.1789C33.3137 41.3603 33.4608 41.5073 33.6421 41.5073Z" fill="#3E3314"/>
        <path d="M34.0626 41.662C34.2074 41.662 34.3248 41.5447 34.3248 41.3999C34.3248 41.2552 34.2074 41.1378 34.0626 41.1378C33.9179 41.1378 33.8005 41.2552 33.8005 41.3999C33.8005 41.5447 33.9179 41.662 34.0626 41.662Z" fill="#3E3314"/>
        <path d="M34.2523 39.5305C34.3622 39.5305 34.4512 39.4414 34.4512 39.3315C34.4512 39.2216 34.3622 39.1326 34.2523 39.1326C34.1424 39.1326 34.0533 39.2216 34.0533 39.3315C34.0533 39.4414 34.1424 39.5305 34.2523 39.5305Z" fill="#3E3314"/>
        <path d="M34.4953 41.3968C34.6436 41.3968 34.7638 41.2767 34.7638 41.1284C34.7638 40.9802 34.6436 40.86 34.4953 40.86C34.3471 40.86 34.2269 40.9802 34.2269 41.1284C34.2269 41.2767 34.3471 41.3968 34.4953 41.3968Z" fill="#3E3314"/>
        <path d="M35.67 42.2936C35.8741 42.2936 36.0395 42.1282 36.0395 41.9242C36.0395 41.7201 35.8741 41.5547 35.67 41.5547C35.466 41.5547 35.3005 41.7201 35.3005 41.9242C35.3005 42.1282 35.466 42.2936 35.67 42.2936Z" fill="#3E3314"/>
        <path d="M35.2341 40.6073C35.4032 40.6073 35.5404 40.4702 35.5404 40.301C35.5404 40.1318 35.4032 39.9947 35.2341 39.9947C35.0649 39.9947 34.9277 40.1318 34.9277 40.301C34.9277 40.4702 35.0649 40.6073 35.2341 40.6073Z" fill="#3E3314"/>
        <path d="M36.9649 41.1631C37.1341 41.1631 37.2712 41.026 37.2712 40.8568C37.2712 40.6876 37.1341 40.5505 36.9649 40.5505C36.7957 40.5505 36.6586 40.6876 36.6586 40.8568C36.6586 41.026 36.7957 41.1631 36.9649 41.1631Z" fill="#3E3314"/>
        <path d="M34.6087 38.7126C34.8162 38.7126 34.9845 38.5443 34.9845 38.3368C34.9845 38.1292 34.8162 37.961 34.6087 37.961C34.4012 37.961 34.2329 38.1292 34.2329 38.3368C34.2329 38.5443 34.4012 38.7126 34.6087 38.7126Z" fill="#3E3314"/>
        <path d="M35.1869 38.2295C35.3596 38.2295 35.4995 38.0895 35.4995 37.9168C35.4995 37.7442 35.3596 37.6042 35.1869 37.6042C35.0142 37.6042 34.8743 37.7442 34.8743 37.9168C34.8743 38.0895 35.0142 38.2295 35.1869 38.2295Z" fill="#3E3314"/>
        <path d="M37.5618 39.1105C37.6542 39.1105 37.7291 39.0356 37.7291 38.9431C37.7291 38.8507 37.6542 38.7758 37.5618 38.7758C37.4693 38.7758 37.3944 38.8507 37.3944 38.9431C37.3944 39.0356 37.4693 39.1105 37.5618 39.1105Z" fill="#3E3314"/>
        <path d="M37.969 38.7662C38.0998 38.7662 38.2059 38.6602 38.2059 38.5294C38.2059 38.3986 38.0998 38.2925 37.969 38.2925C37.8382 38.2925 37.7322 38.3986 37.7322 38.5294C37.7322 38.6602 37.8382 38.7662 37.969 38.7662Z" fill="#3E3314"/>
        <path d="M36.0419 36.8084C36.171 36.8084 36.2756 36.7037 36.2756 36.5747C36.2756 36.4456 36.171 36.341 36.0419 36.341C35.9129 36.341 35.8082 36.4456 35.8082 36.5747C35.8082 36.7037 35.9129 36.8084 36.0419 36.8084Z" fill="#3E3314"/>
        <path d="M33.0144 40.5221C33.2167 40.5221 33.3807 40.3581 33.3807 40.1557C33.3807 39.9534 33.2167 39.7894 33.0144 39.7894C32.8121 39.7894 32.6481 39.9534 32.6481 40.1557C32.6481 40.3581 32.8121 40.5221 33.0144 40.5221Z" fill="#3E3314"/>
        <path d="M33.3961 41.3368C33.5967 41.3368 33.7593 41.1743 33.7593 40.9737C33.7593 40.7731 33.5967 40.6105 33.3961 40.6105C33.1956 40.6105 33.033 40.7731 33.033 40.9737C33.033 41.1743 33.1956 41.3368 33.3961 41.3368Z" fill="#3E3314"/>
        <path d="M32.5059 43.9926C32.6629 43.9926 32.7902 43.8654 32.7902 43.7084C32.7902 43.5514 32.6629 43.4242 32.5059 43.4242C32.3489 43.4242 32.2217 43.5514 32.2217 43.7084C32.2217 43.8654 32.3489 43.9926 32.5059 43.9926Z" fill="#3E3314"/>
        <path d="M33.1976 43.3137C33.3476 43.3137 33.4692 43.1921 33.4692 43.0421C33.4692 42.8921 33.3476 42.7705 33.1976 42.7705C33.0476 42.7705 32.926 42.8921 32.926 43.0421C32.926 43.1921 33.0476 43.3137 33.1976 43.3137Z" fill="#3E3314"/>
        <path d="M35.3167 41.1221C35.4859 41.1221 35.623 40.985 35.623 40.8158C35.623 40.6466 35.4859 40.5095 35.3167 40.5095C35.1475 40.5095 35.0104 40.6466 35.0104 40.8158C35.0104 40.985 35.1475 41.1221 35.3167 41.1221Z" fill="#3E3314"/>
        <path d="M36.0428 40.7621C36.2608 40.7621 36.4375 40.5853 36.4375 40.3673C36.4375 40.1493 36.2608 39.9726 36.0428 39.9726C35.8248 39.9726 35.6481 40.1493 35.6481 40.3673C35.6481 40.5853 35.8248 40.7621 36.0428 40.7621Z" fill="#3E3314"/>
        <path d="M36.0712 41.1852C36.2962 41.1852 36.4786 41.0028 36.4786 40.7779C36.4786 40.5529 36.2962 40.3705 36.0712 40.3705C35.8462 40.3705 35.6638 40.5529 35.6638 40.7779C35.6638 41.0028 35.8462 41.1852 36.0712 41.1852Z" fill="#3E3314"/>
        <path d="M36.0046 41.0052C36.1859 41.0052 36.333 40.8582 36.333 40.6768C36.333 40.4954 36.1859 40.3484 36.0046 40.3484C35.8232 40.3484 35.6761 40.4954 35.6761 40.6768C35.6761 40.8582 35.8232 41.0052 36.0046 41.0052Z" fill="#3E3314"/>
        <path d="M35.1202 42.2684C35.2144 42.2684 35.2908 42.1921 35.2908 42.0979C35.2908 42.0037 35.2144 41.9274 35.1202 41.9274C35.0261 41.9274 34.9497 42.0037 34.9497 42.0979C34.9497 42.1921 35.0261 42.2684 35.1202 42.2684Z" fill="#3E3314"/>
        <path d="M35.0446 43.0042C35.2399 43.0042 35.3983 42.8459 35.3983 42.6506C35.3983 42.4552 35.2399 42.2969 35.0446 42.2969C34.8493 42.2969 34.6909 42.4552 34.6909 42.6506C34.6909 42.8459 34.8493 43.0042 35.0446 43.0042Z" fill="#3E3314"/>
        <path d="M33.2006 41.1411C33.314 41.1411 33.4059 41.0492 33.4059 40.9358C33.4059 40.8224 33.314 40.7305 33.2006 40.7305C33.0873 40.7305 32.9954 40.8224 32.9954 40.9358C32.9954 41.0492 33.0873 41.1411 33.2006 41.1411Z" fill="#3E3314"/>
        <path d="M35.0604 41.7C35.2732 41.7 35.4457 41.5275 35.4457 41.3147C35.4457 41.1019 35.2732 40.9294 35.0604 40.9294C34.8477 40.9294 34.6752 41.1019 34.6752 41.3147C34.6752 41.5275 34.8477 41.7 35.0604 41.7Z" fill="#3E3314"/>
        <path d="M34.6092 40.0958C34.7766 40.0958 34.9123 39.96 34.9123 39.7926C34.9123 39.6252 34.7766 39.4894 34.6092 39.4894C34.4418 39.4894 34.306 39.6252 34.306 39.7926C34.306 39.96 34.4418 40.0958 34.6092 40.0958Z" fill="#3E3314"/>
        <path d="M36.9934 40.4842C37.1434 40.4842 37.2649 40.3626 37.2649 40.2126C37.2649 40.0626 37.1434 39.941 36.9934 39.941C36.8434 39.941 36.7218 40.0626 36.7218 40.2126C36.7218 40.3626 36.8434 40.4842 36.9934 40.4842Z" fill="#3E3314"/>
        <path d="M36.0867 39.3316C36.2471 39.3316 36.3772 39.2015 36.3772 39.0411C36.3772 38.8806 36.2471 38.7505 36.0867 38.7505C35.9262 38.7505 35.7961 38.8806 35.7961 39.0411C35.7961 39.2015 35.9262 39.3316 36.0867 39.3316Z" fill="#3E3314"/>
        <path d="M37.679 38.9842C37.822 38.9842 37.9379 38.8683 37.9379 38.7253C37.9379 38.5822 37.822 38.4663 37.679 38.4663C37.536 38.4663 37.42 38.5822 37.42 38.7253C37.42 38.8683 37.536 38.9842 37.679 38.9842Z" fill="#3E3314"/>
        <path d="M37.3655 38.0369C37.4963 38.0369 37.6023 37.9308 37.6023 37.8C37.6023 37.6692 37.4963 37.5632 37.3655 37.5632C37.2347 37.5632 37.1287 37.6692 37.1287 37.8C37.1287 37.9308 37.2347 38.0369 37.3655 38.0369Z" fill="#3E3314"/>
        <path d="M37.8934 37.56C38.0556 37.56 38.1871 37.4285 38.1871 37.2663C38.1871 37.1041 38.0556 36.9726 37.8934 36.9726C37.7312 36.9726 37.5997 37.1041 37.5997 37.2663C37.5997 37.4285 37.7312 37.56 37.8934 37.56Z" fill="#3E3314"/>
        <path d="M38.0194 36.8968C38.1136 36.8968 38.1899 36.8205 38.1899 36.7263C38.1899 36.6321 38.1136 36.5558 38.0194 36.5558C37.9252 36.5558 37.8489 36.6321 37.8489 36.7263C37.8489 36.8205 37.9252 36.8968 38.0194 36.8968Z" fill="#3E3314"/>
        <path d="M39.043 36.5431C39.2034 36.5431 39.3335 36.4131 39.3335 36.2526C39.3335 36.0922 39.2034 35.9621 39.043 35.9621C38.8825 35.9621 38.7524 36.0922 38.7524 36.2526C38.7524 36.4131 38.8825 36.5431 39.043 36.5431Z" fill="#3E3314"/>
        </g>
        </svg>
    )
}
