import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import reportWebVitals from './reportWebVitals';

import './index.css';
import * as Sentry from '@sentry/react';

import App from './App';
import { PageApplications } from './pages/Application/PageApplications';
import { PageApplication } from './pages/Application/PageApplication';
import { PageLogin } from './pages/Login/PageLogin';
import { PagePassword } from './pages/Login/PagePassword';
import { PageObjects } from './pages/Object/PageObjects';
import { PageObject } from './pages/Object/PageObject';
import { PagePackDigital } from './pages/PackDigital/PagePackDigital';
import { PageServices } from './pages/Service/PageServices';
import { PageService } from './pages/Service/PageService';
import { PageSubscriber } from './pages/Subscriber/PageSubscriber';
import { PageSubscription } from './pages/Subscription/PageSubscription';
import { PageTheme } from './pages/Theme/PageTheme';
import { IndexBackOffice } from './pages/Backoffice/BackOffice';
import { PageSections } from './pages/Backoffice/Sections';
import { PageSectionEdit } from './pages/Backoffice/SectionEdit';

import { PageOffers, PageProductEdit, PageProducts, PageThemes } from './pages/Backoffice';

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./settings/authConfig";

import PrivateRoute from './service/private-route';

// import TagManager from 'react-gtm-module';


export const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById("root");

const sentry_enabled = process.env.REACT_APP_SENTRY_ENBABLED === '1'

Sentry.init({
    dsn: "https://f490f89f2ad540f1a7f26aef557d5dfa@sentry.mutuellegsmc.fr/9",
    enabled: sentry_enabled,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// const tagManagerArgs = {
//     gtmId: process.env.REACT_APP_GOOGLE_GTM_KEY || "",
//     events: {
//         event: 'gtm.js',
//         'gtm.start': new Date().getTime()
//     },
// }

// TagManager.initialize(tagManagerArgs)


ReactDOM.render(
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <Routes>
                <Route path="login" element={<PageLogin />} />
                <Route path="account/password/:uuid" element={<PagePassword step="reset" />} />
                <Route path="account/:uuid" element={<PagePassword step="activate" />} />
                <Route path="/" element={<App />} />
                <Route path="adherent" element={<PrivateRoute authorities={["ROLE_USER"]}><PageSubscriber /></PrivateRoute>} />
                <Route path="applications/:code/obtenir" element={<PageSubscription />} />
                <Route path="applications/:code" element={<PageApplication />} />
                <Route path="applications" element={<PageApplications />} />
                <Route path="objets/:code/obtenir" element={<PageSubscription />} />
                <Route path="objets/:code" element={<PageObject />} />
                <Route path="objets" element={<PageObjects />} />
                <Route path="services/:code/obtenir" element={<PageSubscription />} />
                <Route path="services/:code" element={<PageService />} />
                <Route path="services" element={<PageServices />} />
                <Route path="theme/:theme" element={<PageTheme />} />
                <Route path="pack-digital" element={<PagePackDigital />} />

                {/* Articles disabled
                <Route path="actualites" element={<PageArticles />} />
                <Route path="actualites/:id" element={<PageArticle />} /> */}

                <Route path="/bo" element={<IndexBackOffice />} />
                <Route path="/bo/produits/:id" element={<PageProductEdit />} />
                <Route path="/bo/produits" element={<PageProducts />} />
                <Route path="/bo/offres" element={<PageOffers />} />
                <Route path="/bo/sections/:id" element={<PageSectionEdit />} />
                <Route path="/bo/sections" element={<PageSections />} />
                <Route path="/bo/themes" element={<PageThemes />} />
            </Routes>
        </MsalProvider>
    </BrowserRouter>,
    rootElement
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
