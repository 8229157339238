import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, FormLabel, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useFormik } from 'formik';
import * as yup from 'yup';

import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';

import { StarIcon } from "../Icon/Theme/StarIcon";
import { FieldLabel } from "../Field/FieldLabel";

import { reviewProduct } from "../../api/Review.api";

import { IProduct } from "../../models/Product.model";
import { IReview, IReviewDTO } from "../../models/Review.model";

import { colors } from "../../static/colors";


interface Props {
    product: IProduct;
    review?: IReview;
    open: boolean;
    send: (review: IReview) => void;
    handleClose: () => void;
}


export const DialogReview = (props: Props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState<string>();

    const getInitialValues = () => {
        return {
            reviewScore: props.review?.note,
            description: props.review?.description
        }
    };

    const validationSchema = yup.object({
        reviewScore: yup.number().nullable().required('Champ obligatoire'),
        description: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!props.product.id)
                return;

            const toSend: IReviewDTO = {
                note: values.reviewScore as number,
                description: values.description as string,
                isActive: true,
                productId: props.product.id
            }
            reviewProduct(toSend).then(response => {
                props.handleClose();
                props.send(response);
            }).catch(_ => setError("Vous ne pouvez pas commenter ce produit"))
        }
    })


    useEffect(() => {
        if (!props.open)
            return;

        formik.resetForm();
        formik.setFieldValue("reviewScore", props.review?.note);
        formik.setFieldValue("description", props.review?.description);
    }, [props.open])


    return (
        <Dialog
            maxWidth='md'
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Avis sur {props.product.name}
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent >
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>
                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.reviewScore && Boolean(formik.errors.reviewScore)} >
                                <FieldLabel label="Note" isRequired />
                            </FormLabel>

                            <Rating
                                name="reviewScore"
                                value={formik.values.reviewScore}
                                getLabelText={(value: number) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                onChange={(e, v) => formik.setFieldValue("reviewScore", v)}
                                precision={1}
                                icon={<StarIcon bgColor={colors.yellow.main} color={colors.yellow.main} selected />}
                                emptyIcon={<StarIcon bgColor="inherit" color={colors.yellow.main} selected />} />

                            {formik.touched.reviewScore && formik.errors.reviewScore &&
                            <FormHelperText>
                                <Typography
                                    variant='caption'
                                    color={colors.red.main}>
                                    {formik.errors.reviewScore}
                                </Typography>
                            </FormHelperText>}
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                <FieldLabel label="Commentaire" isRequired={true} />
                            </FormLabel>
                            <TextField
                                multiline
                                minRows={5}
                                maxRows={10}
                                fullWidth
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {error &&
                        <Grid
                            item
                            xs={12}>
                            <FormHelperText
                                sx={{
                                    ml: 2
                                }}>
                                <Typography
                                    variant='caption'
                                    color={colors.red.main}>
                                    {error}
                                </Typography>
                            </FormHelperText>
                        </Grid>}
                    <Button
                        autoFocus
                        variant='contained'
                        type="submit"
                        sx={{
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Valider
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
