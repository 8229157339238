import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography"

import { CardListEmpty } from "../Card/CardListEmpty";
import { CardProduct } from "../Card/CardProduct";
import { CardProductType } from "../Card/CardProductType";
import { CardProductSkeleton } from "../Card/CardProductSkeleton";
import { PlusIcon } from "../Icon/PlusIcon";

import { getProducts } from "../../api/Product.api";

import { IProduct, ProductType } from "../../models/Product.model";

import { colors } from "../../static/colors";


const defaultNbRowsMD: number = 3;
const defaultNbRowsSM: number = 2;
const defaultNbRowsXS: number = 2;

const nbItemByRowsMD: number = 5;
const nbItemByRowsSM: number = 3;
const nbItemByRowsXS: number = 2;

const nbRowsAddedMD: number = 1;
const nbRowsAddedSM: number = 1;
const nbRowsAddedXS: number = 2;


interface Props {
    nbRows?: number;
    type?: ProductType;
    filterByThemes?: string[];
    showTypeLink?: Boolean;
}


export const ListProducts = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const [nbRowsDisplayed, setNbRowsDisplayed] = useState<number>(props.nbRows || screenSizeUpMD ? defaultNbRowsMD : screenSizeUpSM ? defaultNbRowsSM : defaultNbRowsXS);

    const [products, setProducts] = useState<IProduct[] | undefined>(undefined);


    useEffect(() => {
        getProducts(0, 50, false, false, props.type?.toString(), props.filterByThemes)
        .then((response) => {
            setProducts(response.content);
        })
        .catch(exception => setProducts([]));
    }, [props.type,
        props.filterByThemes])


    return (
        <Grid
            container
            justifyContent='stretch'
            alignItems='stretch'
            spacing={{
                md: 4,
                xs: 2
            }}>

            {props.showTypeLink && props.type &&
            <Grid
                item
                md={12 / nbItemByRowsMD}
                sm={12 / nbItemByRowsSM}
                xs={12 / nbItemByRowsXS} >

                <CardProductType type={props.type} />

            </Grid>}

            {products === undefined ?
                Array((screenSizeUpMD ? nbItemByRowsMD : screenSizeUpSM ? nbItemByRowsSM : nbItemByRowsXS) - (props.showTypeLink && props.type ? 1 : 0)).fill("").map(_ => {
                    return (
                        <Grid
                            item
                            md={12 / nbItemByRowsMD}
                            sm={12 / nbItemByRowsSM}
                            xs={12 / nbItemByRowsXS} >

                            <CardProductSkeleton />

                        </Grid>
                    );
                })
            
            : products.length === 0 ?
            <Grid
                item
                xs
                sx={{
                    minHeight: '200px'
                }}>
                <CardListEmpty
                    title="Aucun résultat"
                    description={props.filterByThemes && props.filterByThemes.length > 0 ? "Essayez de rechercher une autre thématique" : undefined}/>
            </Grid>

            :
            products.slice(0, nbRowsDisplayed * (screenSizeUpMD ? nbItemByRowsMD : screenSizeUpSM ? nbItemByRowsSM : nbItemByRowsXS) - (props.showTypeLink && props.type ? 1 : 0))
                    .map(_ => {
                return (
                    <Grid
                        item
                        md={12 / nbItemByRowsMD}
                        sm={12 / nbItemByRowsSM}
                        xs={12 / nbItemByRowsXS}  >

                        <CardProduct
                            product={_}/>

                    </Grid>
                );
            })}

            {products && products.length > nbRowsDisplayed * (screenSizeUpMD ? nbItemByRowsMD : screenSizeUpSM ? nbItemByRowsSM : nbItemByRowsXS) - (props.showTypeLink && props.type ? 1 : 0) &&
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center'
                }}>
                
                <Button
                    sx={{
                        color: colors.black.main
                    }}
                    onClick={() => setNbRowsDisplayed(nbRowsDisplayed + (screenSizeUpMD ? nbRowsAddedMD : screenSizeUpSM ? nbRowsAddedSM : nbRowsAddedXS))}>
                    <Box>
                        <Typography variant='button' component='div' sx={{mb: 1}}>
                            Voir +
                            {props.type === ProductType.APPLICATION ? " d'applications" :
                             props.type === ProductType.OBJECT ? " d'objets" :
                             props.type === ProductType.SERVICE ? " de services" : ""}
                            {props.filterByThemes && props.filterByThemes.length > 0 &&
                                props.filterByThemes.map(_ =>  <span> “{_}”</span>)
                            }
                        </Typography>
                        <PlusIcon />
                    </Box>
                </Button>

            </Grid>}
        </Grid>
    )
}
