import { CardImage } from "../Card/CardImage";
import { Carousel } from "./Carousel";

import { IImage } from "../../models/Image.model";


interface Props {
    images: IImage[];
}


const defaultNbItemsMD: number = 3.8;
const defaultNbItemsSM: number = 2.5;
const defaultNbItemsXS: number = 1.5;


export const CarouselImage = (props: Props) => {


    return (
        <Carousel
            elements={
                props.images.map((_, id) => {
                return <CardImage
                    image={_} />;
                })
            }
            nbItemsMD={defaultNbItemsMD}
            nbItemsSM={defaultNbItemsSM}
            nbItemsXS={defaultNbItemsXS} />
    );
}
