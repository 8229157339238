import { ISms } from "../models/Sms.model";

import AuthService from "../service/AuthService"


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'

const authService = new AuthService();


export const getProductConsumptionSms = (id: number): Promise<ISms[]> => {

    return fetch(`${apiUrl}/product/consumption/${id}/sms`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({
                "Content-Type": "application/json"
            }),
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}


export const validateSms = (id: number, code: string): Promise<string> => {

    return fetch(`${apiUrl}/sms/${id}/validate?code=${code}`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({
                "Content-Type": "application/json"
            }),
        })
        .then(_ => {
            if(_.status === 200) return _.json()
            else throw _
        });
}
