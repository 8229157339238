import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ChipOffer } from "../Chip/ChipOffer";
import { ChipTheme } from "../Chip/ChipTheme";

import { IImage, ImageType } from "../../models/Image.model";
import { IProduct, ProductType } from "../../models/Product.model";

import { colors } from "../../static/colors"


interface Props {
    product: IProduct;
    rank?: number;
}


export const CardProduct = (props: Props) => {

    const navigate = useNavigate();
    const image: IImage | undefined = props.product.images.filter(_ => _.type === ImageType.LOGO).sort((a, b) => a.order - b.order)[0];


    return (
        <Card
            sx={{
                height: '100%',
                boxShadow: props.rank ? 'none' : "0px 4px 20px 0px #00000028",
                transition: "transform .2s ease-in-out",
                ':hover': {
                    transition: "transform .2s ease-in-out",
                    transform: "translateY(-8px)",
                    boxShadow: props.rank ? 'none' : "0px 4px 20px 0px #3F8CD860"
                }
            }} >
            <CardActionArea
                onClick={event => {
                    let url: string = '/applications';

                    if (props.product.type === ProductType.APPLICATION)
                        url = '/applications'
                    else if (props.product.type === ProductType.OBJECT)
                        url = '/objets'
                    else if (props.product.type === ProductType.SERVICE)
                        url = '/services'

                    navigate(url + "/" + props.product.code);
                }}
                sx={{
                    height: '100%'
                }} >
                <Box
                    sx={{
                        height: '100%'
                    }} >
                    <Grid
                        container
                        direction="row" >

                        {props.rank !== undefined &&
                        <Grid
                            item
                            xs={3}>
                            <Typography
                                key={`rank`}
                                fontFamily='Poppins'
                                fontSize={140}
                                fontWeight={700}
                                lineHeight={0.85}
                                sx={{
                                    color: 'white',
                                    textShadow: `0px 4px 12px #00000048`
                                }}>
                                {props.rank}
                            </Typography>
                        </Grid>}

                        <Grid
                            item
                            xs={props.rank !== undefined ? 9 : 12}>

                            {image ?
                            <CardMedia
                                component="img"
                                src={image.image}
                                sx={{
                                    background: 'white',
                                    borderRadius: 2,
                                    height: '120px'
                                }} />
                            :
                            <Box
                                sx={{
                                    backgroundColor: colors.grey.secondary,
                                    borderRadius: 2,
                                    height: '120px'
                                }} />}

                            {props.product.userOffer &&
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: props.rank !== undefined ? '25%' : 0,
                                    zIndex: 'modal',
                                }} >
                                <ChipOffer offer={props.product.userOffer} />
                            </Box>}
                        </Grid>
                    </Grid>

                    <CardContent
                        sx={{
                            px: 1,
                            pt: {
                                sm: 2,
                                xs: 1
                            },
                            ':last-child': {
                                pb: 1
                            }
                        }} >
                        <Typography
                            variant="h3"
                            fontWeight={700}
                            sx={{
                                mb: {
                                    sm: 1,
                                    xs: 0
                                }
                            }} >
                            {props.product.name}
                        </Typography>

                        {props.product.tagline &&
                        <Typography
                            variant="body2"
                            component='div'>
                            {props.product.tagline}
                        </Typography>}

                        {props.product.themes.length > 0 &&
                        <Box
                            sx={{
                                mt: 1.5
                            }}>
                            {props.product.themes.map( (_ , i) =>
                                <ChipTheme theme={_} key={`theme_${i}`}/>
                            )}
                        </Box>}

                    </CardContent>
                </Box>
            </CardActionArea>

        </Card>
    )
}
