
import { defaultIconColor, defaultIconSize, IIcon } from "./../settings"


export const TwitterCircleIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_820_146808)">
        <path d="M15.0008 29.9997C23.285 29.9997 30.0007 23.2841 30.0007 14.9999C30.0007 6.71567 23.285 0 15.0008 0C6.71665 0 0.000976562 6.71567 0.000976562 14.9999C0.000976562 23.2841 6.71665 29.9997 15.0008 29.9997Z" fill={props.color || defaultIconColor} />
        <path d="M24.1883 10.78C23.5455 11.065 22.854 11.2578 22.1289 11.3439C22.8693 10.9003 23.4374 10.1984 23.7056 9.36067C23.0128 9.77165 22.2452 10.0698 21.4288 10.2305C20.7748 9.53394 19.843 9.09863 18.8111 9.09863C16.8314 9.09863 15.2257 10.7043 15.2257 12.684C15.2257 12.965 15.2576 13.2386 15.319 13.5012C12.3393 13.3517 9.69724 11.9244 7.92874 9.75453C7.62018 10.284 7.44317 10.9003 7.44317 11.5573C7.44317 12.8009 8.07661 13.8988 9.03813 14.5416C8.45068 14.5231 7.89746 14.3619 7.41429 14.0926C7.41403 14.1079 7.41403 14.1231 7.41403 14.1381C7.41403 15.8753 8.65042 17.3243 10.2903 17.6534C9.98976 17.7358 9.67237 17.7793 9.34589 17.7793C9.11434 17.7793 8.89 17.7572 8.67128 17.7154C9.12744 19.1395 10.4513 20.1762 12.0206 20.2053C10.7933 21.1671 9.2475 21.7401 7.56724 21.7401C7.27846 21.7401 6.99236 21.7233 6.7124 21.6899C8.29827 22.7076 10.1834 23.3009 12.208 23.3009C18.8028 23.3009 22.4093 17.8376 22.4093 13.0993C22.4093 12.9439 22.4059 12.7891 22.3989 12.6356C23.1 12.1303 23.7078 11.499 24.1883 10.78Z" fill={props.bgColor || "white"} />
        </g>
        </svg>
    )
}
