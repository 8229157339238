import {
  Route,
  Navigate
} from 'react-router-dom';
import AuthService from './AuthService';

interface IPrivateRouteProps {
  children: any
  authorities: string[]
}


const authentication = new AuthService();

function PrivateRoute(props: IPrivateRouteProps) {
  return (
    authentication.hasAnyAuthorities(props.authorities) ?
      props.children
      :
      <Navigate to="/login" />

  );
}

export default PrivateRoute;