import './App.css';

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ButtonChatBot } from './components/Button/ButtonChatBot';
import { CardPackDigitalDiscover } from './components/Card/CardPackDigitalDiscover';
import { CardSection } from './components/Card/CardSection';
import { CarouselProduct } from './components/Carousel/CarouselProduct';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { FilterTheme } from './components/Filter/FilterTheme';
import { PageHeader } from './components/Page/PageHeader';
import { TitleSection } from './components/Title/TitleSection';

import { getSections } from './api/Section.api';

import { ProductType } from './models/Product.model';
import { ISection, SectionType } from './models/Section.model';


import { Page } from './settings/Page';

import { theme } from './static/theme';


function App() {

    const navigate = useNavigate();
  
    const page: Page = Page.HOME;

    const [sectionHighlight, setSectionHighlight] = useState<ISection | undefined>();

    useEffect(() => {
        getSections(0, 1, SectionType.HIGHLIGHT)
        .then((response) => {
            setSectionHighlight(response.content[0]);
        })
        .catch(exception => setSectionHighlight(undefined));
    }, [])


    return (
        <ThemeProvider theme={theme}>

            <Header
                page={page} />

            <Box
                sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    mx: 'auto',
                    mt: 2
                }} >

                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={3}
                    sx={{
                        px: {
                            md: 5,
                            xs: 2
                        }
                    }} >

                    <Grid
                        item
                        xs={12} >
                        <PageHeader page={page} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <FilterTheme
                            filterBy={(_) => {
                                if (_ === undefined)
                                    return;

                                    navigate(`/theme/${_.toLowerCase()}`);
                            }} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Les dernières applications santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/applications')} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            type={ProductType.APPLICATION}
                            showTypeLink />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Les derniers produits santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/objets')} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            type={ProductType.OBJECT}
                            showTypeLink />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <TitleSection
                            content={<Box component='span'>Les derniers services santé</Box>}
                            label="Tout voir"
                            onClick={() => navigate('/services')} />
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            type={ProductType.SERVICE}
                            showTypeLink />
                    </Grid>

                    {sectionHighlight && sectionHighlight.products.length > 0 &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: 2
                        }}>
                        <CardSection
                            section={sectionHighlight} />
                    </Grid>}

                    <Grid
                        item
                        xs={12}
                        sx={{
                            mt: 2.5
                        }}>
                        <TitleSection
                            content={
                                <Box
                                    component='span'>
                                    Les innovations préférées
                                    <Box
                                        component='span'
                                        sx={{
                                            display: {
                                                md: 'inline',
                                                xs: 'none'
                                            }
                                        }}>
                                        &nbsp;des adhérents GSMC
                                    </Box>
                                </Box>} />
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <CarouselProduct
                            showRank />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            my: {
                                md: 5,
                                xs: 3
                            }
                        }}>
                        <CardPackDigitalDiscover />
                    </Grid>

                </Grid>

            </Box>

            <Footer />

            {/* COMMENT : ChatBot in stand-by
            <ButtonChatBot /> */}

        </ThemeProvider>
    );
}

export default App;
