import { colors } from "../../static/colors"

import { defaultIconColor, defaultIconHeaderSize, IIcon } from "./settings"


export const FruitIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M31.0574 20.9569C30.6054 21.6907 30.0685 22.3685 29.4957 23.0144C28.3259 24.3553 27.0202 25.582 25.5061 26.5087C22.0391 28.633 17.6311 29.0063 13.7244 27.8818C12.5491 27.5446 11.4277 27.0887 10.3846 26.465C7.12692 24.4929 5.10215 20.9859 4.94227 17.1903L4.98926 17.1807C12.9342 15.1681 20.9286 13.3021 28.9464 11.5977L7.49479 16.6973C8.24221 20.2063 10.711 23.2993 13.9609 24.803C17.2109 26.3067 21.1647 26.1781 24.31 24.4672C27.4553 22.7563 29.6982 19.4824 30.1715 15.9342C30.3792 14.4132 30.2671 12.8843 30.168 11.3477C30.8729 11.2035 31.5701 11.0462 32.2749 10.9019C33.3901 14.1985 32.9179 17.9913 31.0574 20.9569Z" fill={props.color || colors.orange.main}/>
        <path d="M24.3154 24.4465C21.17 26.1574 17.2163 26.2859 13.9663 24.7823C10.7164 23.2786 8.24761 20.1855 7.50019 16.6766L28.9517 11.577C29.3615 11.4981 29.7637 11.406 30.1866 11.3194C30.2987 12.8484 30.3977 14.3849 30.1901 15.9059C29.7167 19.4541 27.4738 22.728 24.3154 24.4465Z" fill={props.secondaryColor || "#FFD89F"} />
        <path d="M15.7126 16.1268C14.9676 16.9597 13.6878 17.2608 13.0995 18.2134C12.8399 18.6435 12.7673 19.2458 13.1282 19.5979C13.3663 19.8282 13.7503 19.8867 14.0641 19.7931C14.3778 19.6995 14.6496 19.4726 14.8516 19.2158C15.4951 18.389 15.571 17.2768 15.6379 16.24" fill="white"/>
        <path d="M22.5439 14.8476C23.4087 15.5567 23.7567 16.8269 24.7281 17.3869C25.1637 17.6258 25.7716 17.6777 26.1161 17.3036C26.3388 17.0524 26.3842 16.676 26.2699 16.3567C26.1556 16.0374 25.9231 15.7863 25.6532 15.5919C24.8076 14.9767 23.6975 14.9346 22.6496 14.9092" fill="white"/>
        <path d="M19.361 16.1685C18.9466 17.301 18.7807 18.5001 18.8801 19.7036C18.9217 20.17 19.0123 20.6606 19.358 20.9864C19.6168 21.2222 20.0029 21.3146 20.3581 21.2321C20.7057 21.1365 20.9961 20.8812 21.1264 20.5609C21.3571 19.9898 21.1078 19.3457 20.8439 18.7975C20.4187 17.9397 19.954 17.1046 19.4237 16.3075" fill="white"/>
        <path d="M30.1733 11.3271C29.7635 11.406 29.3614 11.4981 28.9385 11.5847L30.1733 11.3271Z" fill="url(#paint0_linear_1113_20970)"/>
        {props.showDetails &&
        <g style={{
            mixBlendMode: "overlay"
        }}>
        <path d="M32.4509 19.9423C32.4872 19.9214 32.4996 19.8751 32.4787 19.8388C32.4578 19.8026 32.4114 19.7902 32.3752 19.8111C32.3389 19.832 32.3265 19.8784 32.3475 19.9146C32.3684 19.9508 32.4147 19.9633 32.4509 19.9423Z" fill={props.bgColor || defaultIconColor} />
        <path d="M33.6258 21.4008C33.7128 21.3505 33.7426 21.2392 33.6924 21.1521C33.6421 21.0651 33.5308 21.0353 33.4437 21.0855C33.3567 21.1358 33.3269 21.2471 33.3771 21.3341C33.4274 21.4212 33.5387 21.451 33.6258 21.4008Z" fill={props.bgColor || defaultIconColor} />
        <path d="M33.162 21.8092C33.22 21.7756 33.2399 21.7014 33.2064 21.6434C33.1729 21.5854 33.0987 21.5655 33.0406 21.599C32.9826 21.6325 32.9627 21.7067 32.9962 21.7647C33.0297 21.8228 33.1039 21.8427 33.162 21.8092Z" fill={props.bgColor || defaultIconColor} />
        <path d="M32.0174 21.7691C32.0827 21.7314 32.1051 21.648 32.0674 21.5827C32.0297 21.5174 31.9462 21.495 31.8809 21.5327C31.8156 21.5704 31.7933 21.6539 31.8309 21.7192C31.8686 21.7845 31.9521 21.8068 32.0174 21.7691Z" fill={props.bgColor || defaultIconColor} />
        <path d="M32.8592 22.6496C32.939 22.6035 32.9663 22.5015 32.9202 22.4217C32.8742 22.3419 32.7721 22.3145 32.6923 22.3606C32.6125 22.4067 32.5852 22.5087 32.6313 22.5885C32.6773 22.6683 32.7794 22.6957 32.8592 22.6496Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.868 21.7497C30.9261 21.7162 30.9459 21.642 30.9124 21.584C30.8789 21.5259 30.8047 21.506 30.7467 21.5396C30.6886 21.5731 30.6688 21.6473 30.7023 21.7053C30.7358 21.7633 30.81 21.7832 30.868 21.7497Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.6179 22.8056C31.7195 22.7469 31.7543 22.6171 31.6956 22.5155C31.637 22.4139 31.5071 22.3791 31.4056 22.4378C31.304 22.4964 31.2692 22.6263 31.3278 22.7278C31.3865 22.8294 31.5163 22.8642 31.6179 22.8056Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.3309 23.4615C31.3889 23.428 31.4088 23.3538 31.3753 23.2958C31.3418 23.2377 31.2676 23.2178 31.2096 23.2513C31.1515 23.2849 31.1317 23.3591 31.1652 23.4171C31.1987 23.4751 31.2729 23.495 31.3309 23.4615Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.3611 24.7576C31.4626 24.6989 31.4974 24.5691 31.4388 24.4675C31.3802 24.366 31.2503 24.3312 31.1487 24.3898C31.0472 24.4484 31.0124 24.5783 31.071 24.6799C31.1296 24.7814 31.2595 24.8162 31.3611 24.7576Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.6278 23.8491C29.6931 23.8114 29.7154 23.7279 29.6777 23.6626C29.64 23.5973 29.5566 23.575 29.4913 23.6127C29.426 23.6504 29.4036 23.7338 29.4413 23.7991C29.479 23.8644 29.5625 23.8868 29.6278 23.8491Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.364 25.3676C30.4075 25.3425 30.4224 25.2869 30.3973 25.2434C30.3721 25.2 30.3165 25.1851 30.2731 25.2102C30.2296 25.2353 30.2147 25.2909 30.2398 25.3343C30.2649 25.3778 30.3205 25.3927 30.364 25.3676Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.7089 25.5362C29.7596 25.5069 29.777 25.4419 29.7477 25.3912C29.7184 25.3404 29.6535 25.323 29.6027 25.3523C29.5519 25.3816 29.5345 25.4466 29.5638 25.4973C29.5931 25.5481 29.6581 25.5655 29.7089 25.5362Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.363 24.9645C28.4065 24.9394 28.4214 24.8838 28.3963 24.8403C28.3712 24.7968 28.3156 24.7819 28.2721 24.807C28.2286 24.8321 28.2137 24.8877 28.2388 24.9312C28.2639 24.9747 28.3195 24.9896 28.363 24.9645Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.2263 26.5503C29.2698 26.5252 29.2847 26.4696 29.2596 26.4261C29.2345 26.3826 29.1789 26.3677 29.1354 26.3928C29.0919 26.4179 29.077 26.4735 29.1021 26.517C29.1272 26.5605 29.1828 26.5754 29.2263 26.5503Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.0018 26.0668C28.0743 26.0249 28.0992 25.9322 28.0573 25.8596C28.0154 25.7871 27.9226 25.7622 27.8501 25.8041C27.7776 25.846 27.7527 25.9387 27.7946 26.0113C27.8365 26.0838 27.9292 26.1087 28.0018 26.0668Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.159 25.2748C27.2388 25.2287 27.2661 25.1267 27.22 25.0469C27.174 24.9671 27.0719 24.9397 26.9921 24.9858C26.9123 25.0319 26.885 25.1339 26.9311 25.2137C26.9771 25.2935 27.0792 25.3208 27.159 25.2748Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.6277 26.5105C27.7075 26.4645 27.7349 26.3624 27.6888 26.2826C27.6427 26.2028 27.5407 26.1755 27.4609 26.2216C27.3811 26.2676 27.3538 26.3697 27.3998 26.4495C27.4459 26.5293 27.5479 26.5566 27.6277 26.5105Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.4197 26.788C27.514 26.7335 27.5463 26.6129 27.4918 26.5186C27.4374 26.4243 27.3168 26.392 27.2225 26.4465C27.1282 26.5009 27.0959 26.6215 27.1503 26.7158C27.2048 26.8101 27.3254 26.8424 27.4197 26.788Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.6443 25.7474C26.7458 25.6888 26.7806 25.5589 26.722 25.4573C26.6634 25.3558 26.5335 25.321 26.4319 25.3796C26.3304 25.4382 26.2956 25.5681 26.3542 25.6697C26.4129 25.7712 26.5427 25.806 26.6443 25.7474Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.3328 27.4863C27.3981 27.4487 27.4205 27.3652 27.3828 27.2999C27.3451 27.2346 27.2616 27.2122 27.1963 27.2499C27.1311 27.2876 27.1087 27.3711 27.1464 27.4364C27.1841 27.5017 27.2676 27.524 27.3328 27.4863Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.2401 26.0502C26.2909 26.0209 26.3083 25.9559 26.279 25.9052C26.2496 25.8544 26.1847 25.837 26.1339 25.8663C26.0832 25.8956 26.0658 25.9605 26.0951 26.0113C26.1244 26.0621 26.1893 26.0795 26.2401 26.0502Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.8259 27.8836C26.9275 27.825 26.9623 27.6951 26.9036 27.5936C26.845 27.492 26.7151 27.4572 26.6136 27.5158C26.512 27.5745 26.4772 27.7043 26.5359 27.8059C26.5945 27.9075 26.7244 27.9423 26.8259 27.8836Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.1073 27.458C26.1653 27.4244 26.1852 27.3502 26.1517 27.2922C26.1182 27.2342 26.044 27.2143 25.9859 27.2478C25.9279 27.2813 25.908 27.3555 25.9415 27.4135C25.975 27.4716 26.0492 27.4915 26.1073 27.458Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.5906 27.686C25.6704 27.6399 25.6978 27.5379 25.6517 27.4581C25.6056 27.3783 25.5036 27.351 25.4238 27.397C25.344 27.4431 25.3166 27.5452 25.3627 27.625C25.4088 27.7048 25.5108 27.7321 25.5906 27.686Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.3523 28.5767C25.3957 28.5516 25.4106 28.496 25.3855 28.4525C25.3604 28.4091 25.3048 28.3942 25.2614 28.4193C25.2179 28.4444 25.203 28.5 25.2281 28.5435C25.2532 28.5869 25.3088 28.6018 25.3523 28.5767Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.7146 28.717C24.8089 28.6625 24.8412 28.5419 24.7868 28.4476C24.7323 28.3533 24.6117 28.321 24.5174 28.3755C24.4231 28.4299 24.3908 28.5505 24.4453 28.6448C24.4997 28.7391 24.6203 28.7714 24.7146 28.717Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.1512 28.7101C24.231 28.6641 24.2583 28.562 24.2122 28.4822C24.1662 28.4024 24.0641 28.3751 23.9843 28.4212C23.9045 28.4672 23.8772 28.5693 23.9233 28.6491C23.9693 28.7289 24.0714 28.7562 24.1512 28.7101Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.952 28.79C24.0173 28.7523 24.0396 28.6688 24.0019 28.6035C23.9642 28.5383 23.8808 28.5159 23.8155 28.5536C23.7502 28.5913 23.7278 28.6748 23.7655 28.74C23.8032 28.8053 23.8867 28.8277 23.952 28.79Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.7088 29.1579C23.7958 29.1077 23.8256 28.9964 23.7754 28.9093C23.7251 28.8223 23.6138 28.7925 23.5268 28.8427C23.4397 28.893 23.4099 29.0043 23.4601 29.0913C23.5104 29.1784 23.6217 29.2082 23.7088 29.1579Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.1121 27.9434C23.1847 27.9015 23.2095 27.8088 23.1676 27.7362C23.1258 27.6637 23.033 27.6388 22.9605 27.6807C22.8879 27.7226 22.8631 27.8153 22.9049 27.8879C22.9468 27.9604 23.0396 27.9853 23.1121 27.9434Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.4099 27.6662C22.4462 27.6452 22.4586 27.5989 22.4377 27.5627C22.4167 27.5264 22.3704 27.514 22.3342 27.5349C22.2979 27.5559 22.2855 27.6022 22.3064 27.6384C22.3274 27.6747 22.3737 27.6871 22.4099 27.6662Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.4598 29.6338C22.5323 29.5919 22.5572 29.4992 22.5153 29.4266C22.4734 29.3541 22.3806 29.3292 22.3081 29.3711C22.2356 29.413 22.2107 29.5058 22.2526 29.5783C22.2945 29.6509 22.3872 29.6757 22.4598 29.6338Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.2391 28.8849C21.3189 28.8389 21.3462 28.7368 21.3001 28.657C21.254 28.5772 21.152 28.5499 21.0722 28.596C20.9924 28.642 20.9651 28.7441 21.0112 28.8239C21.0572 28.9037 21.1593 28.931 21.2391 28.8849Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.9041 28.2731C20.9984 28.2186 21.0307 28.098 20.9762 28.0037C20.9218 27.9094 20.8012 27.8771 20.7069 27.9316C20.6126 27.986 20.5803 28.1066 20.6347 28.2009C20.6892 28.2952 20.8098 28.3275 20.9041 28.2731Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.8768 29.7425C20.9421 29.7048 20.9644 29.6213 20.9267 29.556C20.8891 29.4907 20.8056 29.4683 20.7403 29.506C20.675 29.5437 20.6526 29.6272 20.6903 29.6925C20.728 29.7578 20.8115 29.7801 20.8768 29.7425Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.6443 30.0689C20.7386 30.0145 20.7709 29.8939 20.7165 29.7996C20.662 29.7053 20.5414 29.673 20.4471 29.7274C20.3528 29.7819 20.3205 29.9024 20.375 29.9967C20.4294 30.091 20.55 30.1234 20.6443 30.0689Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.1814 29.6006C20.2685 29.5504 20.2983 29.4391 20.248 29.352C20.1978 29.265 20.0865 29.2351 19.9994 29.2854C19.9124 29.3357 19.8825 29.447 19.9328 29.534C19.9831 29.6211 20.0944 29.6509 20.1814 29.6006Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.8241 28.2836C19.8749 28.2542 19.8923 28.1893 19.8629 28.1385C19.8336 28.0878 19.7687 28.0704 19.7179 28.0997C19.6671 28.129 19.6497 28.1939 19.6791 28.2447C19.7084 28.2955 19.7733 28.3129 19.8241 28.2836Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.2059 28.3954C19.2639 28.3619 19.2838 28.2877 19.2503 28.2297C19.2168 28.1716 19.1426 28.1517 19.0846 28.1852C19.0265 28.2187 19.0067 28.293 19.0402 28.351C19.0737 28.409 19.1479 28.4289 19.2059 28.3954Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.99 28.5374C19.0698 28.4913 19.0972 28.3893 19.0511 28.3095C19.005 28.2297 18.903 28.2024 18.8232 28.2484C18.7434 28.2945 18.7161 28.3965 18.7621 28.4763C18.8082 28.5561 18.9102 28.5835 18.99 28.5374Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.2987 29.9177C18.3567 29.8842 18.3766 29.81 18.3431 29.7519C18.3096 29.6939 18.2354 29.674 18.1773 29.7075C18.1193 29.741 18.0994 29.8152 18.1329 29.8733C18.1664 29.9313 18.2406 29.9512 18.2987 29.9177Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.6424 28.0543C17.7077 28.0167 17.7301 27.9332 17.6924 27.8679C17.6547 27.8026 17.5712 27.7802 17.5059 27.8179C17.4406 27.8556 17.4183 27.9391 17.4559 28.0044C17.4936 28.0697 17.5771 28.092 17.6424 28.0543Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.2352 28.7447C17.2932 28.7112 17.3131 28.637 17.2796 28.579C17.2461 28.5209 17.1719 28.501 17.1139 28.5345C17.0558 28.5681 17.036 28.6423 17.0695 28.7003C17.103 28.7583 17.1772 28.7782 17.2352 28.7447Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4891 29.7883C16.5399 29.759 16.5573 29.6941 16.528 29.6433C16.4987 29.5925 16.4337 29.5751 16.383 29.6044C16.3322 29.6338 16.3148 29.6987 16.3441 29.7495C16.3734 29.8003 16.4383 29.8177 16.4891 29.7883Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.3963 29.5969C16.4833 29.5466 16.5131 29.4353 16.4629 29.3482C16.4126 29.2612 16.3013 29.2314 16.2143 29.2816C16.1272 29.3319 16.0974 29.4432 16.1476 29.5302C16.1979 29.6173 16.3092 29.6471 16.3963 29.5969Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.1121 27.922C16.2064 27.8676 16.2387 27.747 16.1842 27.6527C16.1298 27.5584 16.0092 27.526 15.9149 27.5805C15.8206 27.6349 15.7883 27.7555 15.8427 27.8498C15.8972 27.9441 16.0178 27.9764 16.1121 27.922Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.199 29.2203C15.2643 29.1826 15.2867 29.0991 15.249 29.0338C15.2113 28.9685 15.1278 28.9462 15.0625 28.9839C14.9973 29.0216 14.9749 29.105 15.0126 29.1703C15.0503 29.2356 15.1338 29.258 15.199 29.2203Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.2029 27.7115C15.29 27.6613 15.3198 27.55 15.2695 27.4629C15.2193 27.3759 15.108 27.346 15.0209 27.3963C14.9338 27.4466 14.904 27.5579 14.9543 27.6449C15.0045 27.732 15.1159 27.7618 15.2029 27.7115Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.2479 28.6658C14.2987 28.6365 14.3161 28.5716 14.2868 28.5208C14.2575 28.47 14.1925 28.4526 14.1417 28.4819C14.091 28.5113 14.0736 28.5762 14.1029 28.627C14.1322 28.6778 14.1971 28.6952 14.2479 28.6658Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.2908 27.345C14.3706 27.2989 14.3979 27.1969 14.3519 27.1171C14.3058 27.0373 14.2038 27.01 14.124 27.056C14.0442 27.1021 14.0168 27.2042 14.0629 27.2839C14.109 27.3637 14.211 27.3911 14.2908 27.345Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.3973 27.7384C13.4771 27.6923 13.5044 27.5903 13.4583 27.5105C13.4122 27.4307 13.3102 27.4034 13.2304 27.4494C13.1506 27.4955 13.1233 27.5975 13.1694 27.6773C13.2154 27.7571 13.3175 27.7845 13.3973 27.7384Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.2205 26.8249C13.3148 26.7704 13.3471 26.6499 13.2926 26.5556C13.2382 26.4613 13.1176 26.4289 13.0233 26.4834C12.929 26.5378 12.8967 26.6584 12.9511 26.7527C13.0056 26.847 13.1262 26.8793 13.2205 26.8249Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.9372 25.9721C13.0388 25.9135 13.0736 25.7836 13.015 25.6821C12.9563 25.5805 12.8265 25.5457 12.7249 25.6043C12.6233 25.663 12.5885 25.7928 12.6472 25.8944C12.7058 25.996 12.8357 26.0308 12.9372 25.9721Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.6443 27.2797C11.7458 27.221 11.7806 27.0912 11.722 26.9896C11.6634 26.8881 11.5335 26.8533 11.4319 26.9119C11.3304 26.9705 11.2956 27.1004 11.3542 27.202C11.4129 27.3035 11.5427 27.3383 11.6443 27.2797Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.5076 26.7103C11.5729 26.6726 11.5953 26.5891 11.5576 26.5238C11.5199 26.4585 11.4364 26.4362 11.3711 26.4739C11.3059 26.5116 11.2835 26.595 11.3212 26.6603C11.3589 26.7256 11.4424 26.748 11.5076 26.7103Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.6512 25.261C11.7165 25.2233 11.7389 25.1398 11.7012 25.0745C11.6635 25.0093 11.58 24.9869 11.5147 25.0246C11.4494 25.0623 11.427 25.1458 11.4647 25.211C11.5024 25.2763 11.5859 25.2987 11.6512 25.261Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.74395 26.0822C9.81649 26.0403 9.84135 25.9475 9.79947 25.875C9.75759 25.8025 9.66483 25.7776 9.59229 25.8195C9.51974 25.8614 9.49489 25.9541 9.53677 26.0267C9.57865 26.0992 9.67141 26.1241 9.74395 26.0822Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.5242 24.7036C10.6185 24.6492 10.6508 24.5286 10.5963 24.4343C10.5419 24.34 10.4213 24.3077 10.327 24.3621C10.2327 24.4166 10.2004 24.5371 10.2548 24.6314C10.3093 24.7258 10.4299 24.7581 10.5242 24.7036Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.93632 25.4104C9.01611 25.3644 9.04346 25.2623 8.99739 25.1825C8.95132 25.1027 8.84928 25.0754 8.76948 25.1215C8.68969 25.1675 8.66235 25.2696 8.70842 25.3494C8.75449 25.4292 8.85652 25.4565 8.93632 25.4104Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.96081 24.6956C9.01158 24.6662 9.02898 24.6013 8.99967 24.5505C8.97035 24.4998 8.90542 24.4824 8.85464 24.5117C8.80386 24.541 8.78646 24.6059 8.81578 24.6567C8.8451 24.7075 8.91003 24.7249 8.96081 24.6956Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.67649 24.5969C8.71997 24.5718 8.73487 24.5162 8.70977 24.4727C8.68466 24.4293 8.62906 24.4144 8.58557 24.4395C8.54209 24.4646 8.52719 24.5202 8.55229 24.5637C8.5774 24.6071 8.633 24.622 8.67649 24.5969Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.30741 24.1095C8.38721 24.0635 8.41455 23.9614 8.36848 23.8816C8.32241 23.8018 8.22037 23.7745 8.14058 23.8206C8.06078 23.8666 8.03344 23.9687 8.07951 24.0485C8.12558 24.1283 8.22762 24.1556 8.30741 24.1095Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.67948 23.1767C8.75928 23.1307 8.78662 23.0286 8.74055 22.9488C8.69448 22.869 8.59244 22.8417 8.51265 22.8878C8.43285 22.9338 8.40551 23.0359 8.45158 23.1157C8.49765 23.1955 8.59969 23.2228 8.67948 23.1767Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.13458 22.9308C8.20712 22.8889 8.23198 22.7961 8.19009 22.7236C8.14821 22.651 8.05545 22.6262 7.98291 22.6681C7.91037 22.7099 7.88551 22.8027 7.9274 22.8752C7.96928 22.9478 8.06204 22.9726 8.13458 22.9308Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.33085 22.2395C8.41065 22.1934 8.43798 22.0914 8.39192 22.0116C8.34585 21.9318 8.24381 21.9044 8.16401 21.9505C8.08422 21.9966 8.05688 22.0986 8.10295 22.1784C8.14902 22.2582 8.25105 22.2856 8.33085 22.2395Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.0711 21.9685C7.14364 21.9267 7.1685 21.8339 7.12662 21.7614C7.08474 21.6888 6.99198 21.664 6.91943 21.7058C6.84689 21.7477 6.82204 21.8405 6.86392 21.913C6.9058 21.9856 6.99856 22.0104 7.0711 21.9685Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.56335 21.0014C7.61413 20.972 7.63153 20.9071 7.60221 20.8563C7.57289 20.8055 7.50796 20.7881 7.45718 20.8175C7.4064 20.8468 7.389 20.9117 7.41832 20.9625C7.44764 21.0133 7.51257 21.0307 7.56335 21.0014Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.84454 20.9086C6.91708 20.8667 6.94193 20.774 6.90005 20.7014C6.85817 20.6289 6.76541 20.604 6.69287 20.6459C6.62033 20.6878 6.59548 20.7805 6.63736 20.8531C6.67924 20.9256 6.772 20.9505 6.84454 20.9086Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.19316 20.6895C6.27295 20.6434 6.30029 20.5414 6.25422 20.4616C6.20815 20.3818 6.10612 20.3545 6.02632 20.4005C5.94652 20.4466 5.91918 20.5486 5.96525 20.6284C6.01133 20.7082 6.11336 20.7356 6.19316 20.6895Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.65317 19.8815C7.71121 19.848 7.73109 19.7738 7.69758 19.7158C7.66408 19.6578 7.58987 19.6379 7.53184 19.6714C7.4738 19.7049 7.45392 19.7791 7.48743 19.8371C7.52093 19.8952 7.59514 19.9151 7.65317 19.8815Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.24495 19.8709C6.31024 19.8332 6.33261 19.7498 6.29491 19.6845C6.25722 19.6192 6.17374 19.5968 6.10845 19.6345C6.04316 19.6722 6.02079 19.7557 6.05849 19.821C6.09618 19.8863 6.17966 19.9086 6.24495 19.8709Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.63552 19.0332C7.67175 19.0122 7.68417 18.9659 7.66325 18.9297C7.64233 18.8934 7.59599 18.881 7.55975 18.9019C7.52352 18.9229 7.5111 18.9692 7.53202 19.0054C7.55294 19.0417 7.59928 19.0541 7.63552 19.0332Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.11696 18.6499C7.1532 18.6289 7.16562 18.5826 7.1447 18.5464C7.12377 18.5101 7.07744 18.4977 7.0412 18.5186C7.00496 18.5396 6.99255 18.5859 7.01347 18.6221C7.03439 18.6584 7.08073 18.6708 7.11696 18.6499Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.51926 18.4342C6.56275 18.4091 6.57764 18.3535 6.55254 18.31C6.52743 18.2665 6.47183 18.2516 6.42834 18.2767C6.38486 18.3019 6.36996 18.3575 6.39507 18.4009C6.42017 18.4444 6.47578 18.4593 6.51926 18.4342Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.22347 18.3423C5.28875 18.3046 5.31112 18.2211 5.27343 18.1558C5.23573 18.0906 5.15225 18.0682 5.08696 18.1059C5.02168 18.1436 4.99931 18.2271 5.037 18.2923C5.0747 18.3576 5.15818 18.38 5.22347 18.3423Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.74192 17.2725C6.7854 17.2474 6.8003 17.1918 6.77519 17.1483C6.75009 17.1049 6.69449 17.09 6.651 17.1151C6.60752 17.1402 6.59262 17.1958 6.61772 17.2393C6.64283 17.2827 6.69843 17.2976 6.74192 17.2725Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1824 28.2472C13.255 28.2053 13.2798 28.1126 13.2379 28.04C13.1961 27.9675 13.1033 27.9426 13.0308 27.9845C12.9582 28.0264 12.9334 28.1192 12.9752 28.1917C13.0171 28.2643 13.1099 28.2891 13.1824 28.2472Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5594 28.6244C12.6102 28.5951 12.6276 28.5302 12.5983 28.4794C12.569 28.4286 12.5041 28.4112 12.4533 28.4405C12.4025 28.4698 12.3851 28.5348 12.4144 28.5855C12.4437 28.6363 12.5087 28.6537 12.5594 28.6244Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5545 27.8568C12.5907 27.8358 12.6031 27.7895 12.5822 27.7533C12.5613 27.717 12.5149 27.7046 12.4787 27.7255C12.4425 27.7465 12.43 27.7928 12.451 27.829C12.4719 27.8653 12.5182 27.8777 12.5545 27.8568Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.4198 27.0767C12.4778 27.0432 12.4977 26.969 12.4642 26.911C12.4307 26.853 12.3565 26.8331 12.2984 26.8666C12.2404 26.9001 12.2205 26.9743 12.254 27.0323C12.2875 27.0904 12.3617 27.1102 12.4198 27.0767Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.4383 28.0461C11.5181 28.0001 11.5454 27.898 11.4993 27.8182C11.4533 27.7384 11.3512 27.7111 11.2714 27.7572C11.1916 27.8032 11.1643 27.9053 11.2104 27.9851C11.2564 28.0649 11.3585 28.0922 11.4383 28.0461Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.8611 27.8361C10.9626 27.7775 10.9974 27.6476 10.9388 27.5461C10.8802 27.4445 10.7503 27.4097 10.6487 27.4684C10.5472 27.527 10.5124 27.6569 10.571 27.7584C10.6296 27.86 10.7595 27.8948 10.8611 27.8361Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.2508 28.2057C10.3378 28.1555 10.3676 28.0442 10.3174 27.9571C10.2671 27.8701 10.1558 27.8402 10.0688 27.8905C9.9817 27.9408 9.95187 28.0521 10.0021 28.1391C10.0524 28.2262 10.1637 28.256 10.2508 28.2057Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.4988 26.3291C10.5714 26.2872 10.5962 26.1944 10.5544 26.1219C10.5125 26.0494 10.4197 26.0245 10.3472 26.0664C10.2746 26.1083 10.2498 26.201 10.2917 26.2736C10.3335 26.3461 10.4263 26.371 10.4988 26.3291Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.8895 26.6986C9.94753 26.6651 9.96741 26.5909 9.93391 26.5329C9.9004 26.4748 9.8262 26.455 9.76816 26.4885C9.71013 26.522 9.69025 26.5962 9.72375 26.6542C9.75726 26.7122 9.83146 26.7321 9.8895 26.6986Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.03102 26.7908C9.11807 26.7405 9.1479 26.6292 9.09764 26.5421C9.04738 26.4551 8.93607 26.4253 8.84902 26.4755C8.76197 26.5258 8.73215 26.6371 8.78241 26.7241C8.83266 26.8112 8.94397 26.841 9.03102 26.7908Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.91969 25.1746C10.0067 25.1243 10.0366 25.013 9.98631 24.926C9.93605 24.8389 9.82475 24.8091 9.7377 24.8594C9.65065 24.9096 9.62082 25.0209 9.67108 25.108C9.72133 25.195 9.83264 25.2249 9.91969 25.1746Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.12585 25.5281C9.17663 25.4988 9.19403 25.4338 9.16471 25.3831C9.13539 25.3323 9.07046 25.3149 9.01968 25.3442C8.9689 25.3735 8.9515 25.4384 8.98082 25.4892C9.01014 25.54 9.07507 25.5574 9.12585 25.5281Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.25562 24.7524C9.34992 24.698 9.38223 24.5774 9.32779 24.4831C9.27334 24.3888 9.15275 24.3565 9.05845 24.4109C8.96414 24.4654 8.93183 24.586 8.98628 24.6803C9.04073 24.7746 9.16131 24.8069 9.25562 24.7524Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.59153 24.6632C8.63501 24.6381 8.64991 24.5825 8.6248 24.539C8.5997 24.4955 8.5441 24.4806 8.50061 24.5057C8.45713 24.5308 8.44223 24.5864 8.46733 24.6299C8.49244 24.6734 8.54804 24.6883 8.59153 24.6632Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.36217 24.0246C8.41295 23.9953 8.43035 23.9304 8.40103 23.8796C8.37172 23.8288 8.30679 23.8114 8.25601 23.8407C8.20523 23.87 8.18783 23.935 8.21715 23.9857C8.24646 24.0365 8.3114 24.0539 8.36217 24.0246Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.31233 23.7559C8.37762 23.7182 8.39999 23.6347 8.3623 23.5694C8.3246 23.5041 8.24112 23.4818 8.17583 23.5195C8.11054 23.5572 8.08817 23.6406 8.12587 23.7059C8.16356 23.7712 8.24705 23.7936 8.31233 23.7559Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.57304 24.1128C7.65284 24.0667 7.68018 23.9647 7.6341 23.8849C7.58803 23.8051 7.486 23.7777 7.4062 23.8238C7.32641 23.8699 7.29907 23.9719 7.34514 24.0517C7.39121 24.1315 7.49324 24.1588 7.57304 24.1128Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.47731 24.763C6.56437 24.7127 6.59419 24.6014 6.54393 24.5144C6.49367 24.4273 6.38236 24.3975 6.29531 24.4478C6.20826 24.498 6.17844 24.6093 6.2287 24.6964C6.27895 24.7834 6.39026 24.8132 6.47731 24.763Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.36398 23.3574C7.40747 23.3323 7.42237 23.2767 7.39726 23.2332C7.37216 23.1897 7.31656 23.1748 7.27307 23.1999C7.22959 23.225 7.21469 23.2806 7.23979 23.3241C7.2649 23.3676 7.3205 23.3825 7.36398 23.3574Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.2771 24.2653C6.37141 24.2109 6.40372 24.0903 6.34927 23.996C6.29482 23.9017 6.17424 23.8694 6.07993 23.9238C5.98563 23.9783 5.95332 24.0988 6.00776 24.1932C6.06221 24.2875 6.1828 24.3198 6.2771 24.2653Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.38167 22.9623C7.44696 22.9246 7.46933 22.8411 7.43163 22.7758C7.39394 22.7106 7.31045 22.6882 7.24517 22.7259C7.17988 22.7636 7.15751 22.8471 7.19521 22.9123C7.2329 22.9776 7.31638 23 7.38167 22.9623Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.65015 23.9972C5.74445 23.9428 5.77677 23.8222 5.72232 23.7279C5.66787 23.6336 5.54728 23.6012 5.45298 23.6557C5.35868 23.7101 5.32636 23.8307 5.38081 23.925C5.43526 24.0193 5.55585 24.0516 5.65015 23.9972Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.81614 22.2556C6.85962 22.2305 6.87452 22.1749 6.84941 22.1314C6.82431 22.0879 6.7687 22.073 6.72522 22.0981C6.68174 22.1232 6.66684 22.1788 6.69194 22.2223C6.71705 22.2658 6.77265 22.2807 6.81614 22.2556Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.0203 22.7847C5.1001 22.7386 5.12744 22.6366 5.08137 22.5568C5.0353 22.477 4.93326 22.4497 4.85347 22.4957C4.77367 22.5418 4.74633 22.6439 4.7924 22.7236C4.83847 22.8034 4.94051 22.8308 5.0203 22.7847Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.99983 22.2355C5.06512 22.1978 5.08749 22.1143 5.0498 22.049C5.0121 21.9837 4.92862 21.9614 4.86333 21.9991C4.79804 22.0368 4.77567 22.1202 4.81337 22.1855C4.85106 22.2508 4.93455 22.2732 4.99983 22.2355Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.53298 21.3677C5.56921 21.3468 5.58163 21.3004 5.56071 21.2642C5.53979 21.228 5.49345 21.2156 5.45721 21.2365C5.42098 21.2574 5.40856 21.3037 5.42948 21.34C5.4504 21.3762 5.49674 21.3886 5.53298 21.3677Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.11214 21.0329C5.17742 20.9952 5.19979 20.9117 5.1621 20.8464C5.12441 20.7811 5.04092 20.7587 4.97564 20.7964C4.91035 20.8341 4.88798 20.9176 4.92567 20.9829C4.96337 21.0482 5.04685 21.0706 5.11214 21.0329Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.72437 20.5737C4.81867 20.5193 4.85098 20.3987 4.79654 20.3044C4.74209 20.2101 4.6215 20.1778 4.5272 20.2322C4.43289 20.2867 4.40058 20.4073 4.45503 20.5016C4.50948 20.5959 4.63006 20.6282 4.72437 20.5737Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.89919 20.2971C3.98624 20.2468 4.01607 20.1355 3.96581 20.0484C3.91555 19.9614 3.80424 19.9316 3.71719 19.9818C3.63014 20.0321 3.60031 20.1434 3.65057 20.2304C3.70083 20.3175 3.81214 20.3473 3.89919 20.2971Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.44415 19.2995C4.51669 19.2576 4.54155 19.1648 4.49966 19.0923C4.45778 19.0197 4.36502 18.9949 4.29248 19.0368C4.21994 19.0787 4.19508 19.1714 4.23697 19.244C4.27885 19.3165 4.37161 19.3414 4.44415 19.2995Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.40903 18.3922C5.46707 18.3587 5.48695 18.2845 5.45344 18.2265C5.41994 18.1684 5.34573 18.1486 5.2877 18.1821C5.22966 18.2156 5.20978 18.2898 5.24329 18.3478C5.27679 18.4059 5.351 18.4257 5.40903 18.3922Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.17943 18.1738C4.28099 18.1152 4.31578 17.9853 4.25715 17.8838C4.19851 17.7822 4.06865 17.7474 3.96709 17.8061C3.86553 17.8647 3.83074 17.9946 3.88937 18.0961C3.94801 18.1977 4.07787 18.2325 4.17943 18.1738Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.91972 17.4838C4.99951 17.4377 5.02685 17.3357 4.98078 17.2559C4.93471 17.1761 4.83268 17.1488 4.75288 17.1948C4.67309 17.2409 4.64575 17.3429 4.69182 17.4227C4.73789 17.5025 4.83992 17.5299 4.91972 17.4838Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.84843 16.9638C3.92822 16.9177 3.95556 16.8157 3.90949 16.7359C3.86342 16.6561 3.76139 16.6288 3.68159 16.6748C3.6018 16.7209 3.57446 16.8229 3.62053 16.9027C3.6666 16.9825 3.76863 17.0099 3.84843 16.9638Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.90218 16.6355C4.96746 16.5978 4.98983 16.5143 4.95214 16.449C4.91445 16.3837 4.83096 16.3613 4.76567 16.399C4.70039 16.4367 4.67802 16.5202 4.71571 16.5855C4.75341 16.6508 4.83689 16.6732 4.90218 16.6355Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.5593 16.2551C4.60278 16.23 4.61768 16.1744 4.59258 16.1309C4.56747 16.0874 4.51187 16.0725 4.46838 16.0976C4.4249 16.1227 4.41 16.1783 4.43511 16.2218C4.46021 16.2653 4.51581 16.2802 4.5593 16.2551Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.48703 15.5788C3.53052 15.5537 3.54542 15.4981 3.52031 15.4546C3.49521 15.4111 3.4396 15.3962 3.39612 15.4213C3.35263 15.4464 3.33773 15.502 3.36284 15.5455C3.38795 15.589 3.44355 15.6039 3.48703 15.5788Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.63547 15.1429C4.67896 15.1178 4.69386 15.0622 4.66875 15.0187C4.64364 14.9752 4.58804 14.9603 4.54456 14.9854C4.50107 15.0105 4.48617 15.0661 4.51128 15.1096C4.53638 15.1531 4.59199 15.168 4.63547 15.1429Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.65503 14.9912C3.74934 14.9368 3.78165 14.8162 3.7272 14.7219C3.67275 14.6276 3.55217 14.5953 3.45786 14.6497C3.36356 14.7042 3.33125 14.8247 3.38569 14.919C3.44014 15.0133 3.56073 15.0457 3.65503 14.9912Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.42657 14.2654C4.49911 14.2235 4.52397 14.1308 4.48209 14.0582C4.4402 13.9857 4.34744 13.9608 4.2749 14.0027C4.20236 14.0446 4.17751 14.1374 4.21939 14.2099C4.26127 14.2824 4.35403 14.3073 4.42657 14.2654Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.8122 28.7949C14.9138 28.7363 14.9486 28.6064 14.89 28.5049C14.8313 28.4033 14.7015 28.3685 14.5999 28.4272C14.4983 28.4858 14.4635 28.6157 14.5222 28.7172C14.5808 28.8188 14.7107 28.8536 14.8122 28.7949Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.1902 29.0836C14.2264 29.0627 14.2389 29.0163 14.2179 28.9801C14.197 28.9438 14.1507 28.9314 14.1144 28.9524C14.0782 28.9733 14.0658 29.0196 14.0867 29.0558C14.1076 29.0921 14.154 29.1045 14.1902 29.0836Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.7019 29.3304C13.789 29.2801 13.8188 29.1688 13.7685 29.0818C13.7183 28.9947 13.607 28.9649 13.5199 29.0151C13.4329 29.0654 13.403 29.1767 13.4533 29.2638C13.5036 29.3508 13.6149 29.3806 13.7019 29.3304Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.5554 28.347C13.5989 28.3219 13.6138 28.2663 13.5887 28.2228C13.5636 28.1793 13.508 28.1644 13.4645 28.1895C13.421 28.2146 13.4061 28.2702 13.4312 28.3137C13.4563 28.3572 13.5119 28.3721 13.5554 28.347Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.6258 27.2558C13.6911 27.2181 13.7135 27.1346 13.6758 27.0693C13.6381 27.004 13.5546 26.9816 13.4893 27.0193C13.424 27.057 13.4017 27.1405 13.4393 27.2058C13.477 27.2711 13.5605 27.2935 13.6258 27.2558Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.657 28.2175C12.7513 28.1631 12.7836 28.0425 12.7292 27.9482C12.6747 27.8539 12.5541 27.8216 12.4598 27.876C12.3655 27.9305 12.3332 28.0511 12.3876 28.1454C12.4421 28.2397 12.5627 28.272 12.657 28.2175Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.574 27.9508C12.6538 27.9048 12.6811 27.8027 12.6351 27.723C12.589 27.6432 12.487 27.6158 12.4072 27.6619C12.3274 27.708 12.3 27.81 12.3461 27.8898C12.3922 27.9696 12.4942 27.9969 12.574 27.9508Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.8201 27.1954C12.9144 27.1409 12.9467 27.0203 12.8922 26.926C12.8378 26.8317 12.7172 26.7994 12.6229 26.8539C12.5286 26.9083 12.4963 27.0289 12.5507 27.1232C12.6052 27.2175 12.7258 27.2498 12.8201 27.1954Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.9774 27.7691C12.0282 27.7398 12.0456 27.6749 12.0163 27.6241C11.987 27.5733 11.922 27.5559 11.8712 27.5852C11.8205 27.6145 11.8031 27.6795 11.8324 27.7303C11.8617 27.781 11.9266 27.7984 11.9774 27.7691Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.8836 26.5448C11.9561 26.503 11.981 26.4102 11.9391 26.3377C11.8972 26.2651 11.8045 26.2403 11.7319 26.2821C11.6594 26.324 11.6345 26.4168 11.6764 26.4893C11.7183 26.5619 11.8111 26.5867 11.8836 26.5448Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.5096 26.0781C11.5749 26.0404 11.5973 25.9569 11.5596 25.8916C11.5219 25.8263 11.4384 25.804 11.3731 25.8417C11.3078 25.8794 11.2854 25.9628 11.3231 26.0281C11.3608 26.0934 11.4443 26.1158 11.5096 26.0781Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.3641 25.917C11.4221 25.8834 11.442 25.8092 11.4085 25.7512C11.375 25.6932 11.3008 25.6733 11.2428 25.7068C11.1847 25.7403 11.1649 25.8145 11.1984 25.8725C11.2319 25.9306 11.3061 25.9505 11.3641 25.917Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.4256 25.8986C10.5126 25.8483 10.5424 25.737 10.4922 25.6499C10.4419 25.5629 10.3306 25.5331 10.2436 25.5833C10.1565 25.6336 10.1267 25.7449 10.1769 25.8319C10.2272 25.919 10.3385 25.9488 10.4256 25.8986Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.65311 25.5916C9.73291 25.5455 9.76025 25.4435 9.71418 25.3637C9.66811 25.2839 9.56608 25.2566 9.48628 25.3026C9.40648 25.3487 9.37914 25.4507 9.42521 25.5305C9.47128 25.6103 9.57332 25.6377 9.65311 25.5916Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.94125 24.5142C9.99929 24.4807 10.0192 24.4065 9.98567 24.3485C9.95216 24.2905 9.87796 24.2706 9.81992 24.3041C9.76189 24.3376 9.742 24.4118 9.77551 24.4698C9.80901 24.5279 9.88322 24.5477 9.94125 24.5142Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.29671 24.7635C9.362 24.7258 9.38436 24.6423 9.34667 24.577C9.30898 24.5118 9.22549 24.4894 9.16021 24.5271C9.09492 24.5648 9.07255 24.6483 9.11024 24.7136C9.14794 24.7788 9.23142 24.8012 9.29671 24.7635Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.7615 23.3573C10.8413 23.3113 10.8687 23.2092 10.8226 23.1294C10.7765 23.0496 10.6745 23.0223 10.5947 23.0684C10.5149 23.1144 10.4875 23.2165 10.5336 23.2963C10.5797 23.3761 10.6817 23.4034 10.7615 23.3573Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.0447 24.174C9.13175 24.1237 9.16157 24.0124 9.11131 23.9254C9.06106 23.8383 8.94975 23.8085 8.8627 23.8588C8.77565 23.909 8.74582 24.0203 8.79608 24.1074C8.84634 24.1944 8.95765 24.2243 9.0447 24.174Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.1473 22.9594C10.1981 22.9301 10.2155 22.8652 10.1862 22.8144C10.1569 22.7636 10.0919 22.7462 10.0412 22.7755C9.99038 22.8048 9.97298 22.8698 10.0023 22.9206C10.0316 22.9713 10.0966 22.9887 10.1473 22.9594Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.52417 23.2131C8.61848 23.1586 8.65079 23.038 8.59634 22.9437C8.54189 22.8494 8.42131 22.8171 8.327 22.8716C8.2327 22.926 8.20039 23.0466 8.25483 23.1409C8.30928 23.2352 8.42987 23.2675 8.52417 23.2131Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.10338 22.3358C9.15416 22.3064 9.17156 22.2415 9.14224 22.1907C9.11293 22.14 9.048 22.1226 8.99722 22.1519C8.94644 22.1812 8.92904 22.2461 8.95836 22.2969C8.98767 22.3477 9.05261 22.3651 9.10338 22.3358Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.3757 22.1074C8.41919 22.0823 8.43409 22.0267 8.40898 21.9832C8.38388 21.9398 8.32827 21.9249 8.28479 21.95C8.24131 21.9751 8.22641 22.0307 8.25151 22.0742C8.27662 22.1176 8.33222 22.1325 8.3757 22.1074Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.44118 21.1604C9.52823 21.1101 9.55806 20.9988 9.5078 20.9118C9.45754 20.8247 9.34623 20.7949 9.25918 20.8452C9.17213 20.8954 9.14231 21.0067 9.19256 21.0938C9.24282 21.1808 9.35413 21.2107 9.44118 21.1604Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.94602 20.7279C8.9895 20.7028 9.0044 20.6472 8.97929 20.6037C8.95419 20.5602 8.89859 20.5453 8.8551 20.5704C8.81162 20.5956 8.79672 20.6512 8.82183 20.6946C8.84693 20.7381 8.90253 20.753 8.94602 20.7279Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.96262 20.7529C8.0061 20.7278 8.021 20.6722 7.9959 20.6287C7.97079 20.5852 7.91519 20.5703 7.8717 20.5954C7.82822 20.6205 7.81332 20.6761 7.83843 20.7196C7.86353 20.7631 7.91914 20.778 7.96262 20.7529Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.93346 20.1568C7.98424 20.1275 8.00164 20.0626 7.97232 20.0118C7.94301 19.961 7.87807 19.9436 7.8273 19.9729C7.77652 20.0022 7.75912 20.0672 7.78844 20.1179C7.81775 20.1687 7.88268 20.1861 7.93346 20.1568Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.58181 19.187C8.66886 19.1367 8.69869 19.0254 8.64843 18.9384C8.59817 18.8513 8.48686 18.8215 8.39981 18.8718C8.31276 18.922 8.28293 19.0333 8.33319 19.1204C8.38345 19.2074 8.49476 19.2373 8.58181 19.187Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.9588 19.6513C7.03134 19.6095 7.05619 19.5167 7.01431 19.4442C6.97243 19.3716 6.87967 19.3468 6.80713 19.3886C6.73459 19.4305 6.70973 19.5233 6.75162 19.5958C6.7935 19.6684 6.88626 19.6932 6.9588 19.6513Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.46855 19.1983C6.54834 19.1522 6.57568 19.0502 6.52961 18.9704C6.48354 18.8906 6.38151 18.8632 6.30171 18.9093C6.22191 18.9554 6.19457 19.0574 6.24065 19.1372C6.28672 19.217 6.38875 19.2444 6.46855 19.1983Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.06419 18.5917C7.16575 18.5331 7.20055 18.4032 7.14191 18.3017C7.08328 18.2001 6.95342 18.1653 6.85186 18.224C6.7503 18.2826 6.7155 18.4125 6.77414 18.514C6.83277 18.6156 6.96263 18.6504 7.06419 18.5917Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.00273 17.9092C7.08252 17.8631 7.10986 17.7611 7.06379 17.6813C7.01772 17.6015 6.91569 17.5741 6.83589 17.6202C6.75609 17.6663 6.72875 17.7683 6.77482 17.8481C6.82089 17.9279 6.92293 17.9552 7.00273 17.9092Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.00366 17.2432C7.09797 17.1888 7.13028 17.0682 7.07583 16.9739C7.02139 16.8796 6.9008 16.8473 6.8065 16.9017C6.71219 16.9562 6.67988 17.0768 6.73433 17.1711C6.78877 17.2654 6.90936 17.2977 7.00366 17.2432Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.46077 16.6994C7.52606 16.6617 7.54843 16.5782 7.51073 16.5129C7.47304 16.4476 7.38956 16.4252 7.32427 16.4629C7.25898 16.5006 7.23661 16.5841 7.2743 16.6494C7.312 16.7147 7.39548 16.7371 7.46077 16.6994Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.71271 17.0082C5.78525 16.9663 5.8101 16.8735 5.76822 16.801C5.72634 16.7284 5.63358 16.7036 5.56104 16.7455C5.48849 16.7873 5.46364 16.8801 5.50552 16.9526C5.5474 17.0252 5.64016 17.05 5.71271 17.0082Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.1072 16.4477C6.19425 16.3974 6.22408 16.2861 6.17382 16.1991C6.12356 16.112 6.01225 16.0822 5.9252 16.1324C5.83815 16.1827 5.80832 16.294 5.85858 16.3811C5.90884 16.4681 6.02015 16.4979 6.1072 16.4477Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.09261 16.4202C5.15789 16.3825 5.18026 16.299 5.14257 16.2337C5.10487 16.1684 5.02139 16.1461 4.9561 16.1838C4.89082 16.2215 4.86845 16.3049 4.90614 16.3702C4.94384 16.4355 5.02732 16.4579 5.09261 16.4202Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.06716 15.6471C5.10339 15.6261 5.11581 15.5798 5.09489 15.5436C5.07397 15.5073 5.02763 15.4949 4.99139 15.5158C4.95516 15.5368 4.94274 15.5831 4.96366 15.6193C4.98458 15.6556 5.03092 15.668 5.06716 15.6471Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.58188 15.1745C5.63992 15.141 5.6598 15.0668 5.6263 15.0088C5.59279 14.9507 5.51858 14.9309 5.46055 14.9644C5.40251 14.9979 5.38263 15.0721 5.41614 15.1301C5.44964 15.1881 5.52385 15.208 5.58188 15.1745Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.53009 14.5389C5.60263 14.497 5.62748 14.4043 5.5856 14.3317C5.54372 14.2592 5.45096 14.2343 5.37842 14.2762C5.30588 14.3181 5.28102 14.4109 5.3229 14.4834C5.36479 14.5559 5.45755 14.5808 5.53009 14.5389Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.8523 26.7902C10.9466 26.7358 10.9789 26.6152 10.9245 26.5209C10.87 26.4266 10.7494 26.3943 10.6551 26.4487C10.5608 26.5032 10.5285 26.6238 10.583 26.7181C10.6374 26.8124 10.758 26.8447 10.8523 26.7902Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.1102 26.5358C10.161 26.5065 10.1784 26.4416 10.1491 26.3908C10.1198 26.34 10.0548 26.3226 10.0041 26.3519C9.95327 26.3813 9.93588 26.4462 9.96519 26.497C9.99451 26.5478 10.0594 26.5651 10.1102 26.5358Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.5867 25.8753C10.6882 25.8166 10.723 25.6868 10.6644 25.5852C10.6057 25.4836 10.4759 25.4488 10.3743 25.5075C10.2728 25.5661 10.238 25.696 10.2966 25.7975C10.3552 25.8991 10.4851 25.9339 10.5867 25.8753Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.1649 25.8734C10.2229 25.8399 10.2428 25.7657 10.2093 25.7076C10.1758 25.6496 10.1016 25.6297 10.0436 25.6632C9.98552 25.6967 9.96564 25.7709 9.99914 25.829C10.0327 25.887 10.1069 25.9069 10.1649 25.8734Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.50181 26.2395C9.55984 26.206 9.57972 26.1317 9.54622 26.0737C9.51271 26.0157 9.4385 25.9958 9.38047 26.0293C9.32244 26.0628 9.30255 26.137 9.33606 26.195C9.36957 26.2531 9.44377 26.273 9.50181 26.2395Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.84747 24.9887C9.92001 24.9468 9.94487 24.854 9.90298 24.7815C9.8611 24.709 9.76834 24.6841 9.6958 24.726C9.62326 24.7679 9.59841 24.8606 9.64029 24.9332C9.68217 25.0057 9.77493 25.0306 9.84747 24.9887Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.78488 25.3922C8.82837 25.3671 8.84327 25.3115 8.81816 25.268C8.79306 25.2245 8.73745 25.2096 8.69397 25.2347C8.65049 25.2598 8.63559 25.3154 8.66069 25.3589C8.6858 25.4024 8.7414 25.4173 8.78488 25.3922Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.71751 25.0634C8.81907 25.0047 8.85387 24.8749 8.79523 24.7733C8.7366 24.6718 8.60674 24.637 8.50518 24.6956C8.40362 24.7542 8.36882 24.8841 8.42746 24.9856C8.48609 25.0872 8.61596 25.122 8.71751 25.0634Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.67272 23.9857C8.7235 23.9563 8.7409 23.8914 8.71158 23.8406C8.68226 23.7899 8.61733 23.7725 8.56655 23.8018C8.51577 23.8311 8.49838 23.896 8.52769 23.9468C8.55701 23.9976 8.62194 24.015 8.67272 23.9857Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.53489 23.5045C8.57837 23.4794 8.59327 23.4238 8.56816 23.3803C8.54306 23.3369 8.48745 23.322 8.44397 23.3471C8.40049 23.3722 8.38559 23.4278 8.41069 23.4713C8.4358 23.5147 8.4914 23.5296 8.53489 23.5045Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.51345 23.3768C8.6005 23.3266 8.63032 23.2153 8.58007 23.1282C8.52981 23.0412 8.4185 23.0113 8.33145 23.0616C8.2444 23.1118 8.21457 23.2232 8.26483 23.3102C8.31509 23.3973 8.4264 23.4271 8.51345 23.3768Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.82497 23.3367C7.91202 23.2864 7.94185 23.1751 7.89159 23.088C7.84133 23.001 7.73002 22.9712 7.64297 23.0214C7.55592 23.0717 7.52609 23.183 7.57635 23.27C7.62661 23.3571 7.73792 23.3869 7.82497 23.3367Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.94021 22.9899C8.02726 22.9397 8.05708 22.8284 8.00682 22.7413C7.95656 22.6543 7.84525 22.6244 7.7582 22.6747C7.67115 22.7249 7.64133 22.8363 7.69159 22.9233C7.74184 23.0104 7.85316 23.0402 7.94021 22.9899Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.20774 22.3622C8.25122 22.3371 8.26612 22.2815 8.24101 22.238C8.21591 22.1945 8.16031 22.1796 8.11682 22.2047C8.07334 22.2298 8.05844 22.2854 8.08354 22.3289C8.10865 22.3724 8.16425 22.3873 8.20774 22.3622Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.66196 22.4144C6.71999 22.3809 6.73988 22.3067 6.70637 22.2486C6.67287 22.1906 6.59866 22.1707 6.54063 22.2042C6.48259 22.2377 6.46271 22.3119 6.49621 22.37C6.52972 22.428 6.60393 22.4479 6.66196 22.4144Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.58185 21.76C6.65439 21.7182 6.67924 21.6254 6.63736 21.5529C6.59548 21.4803 6.50272 21.4555 6.43018 21.4973C6.35763 21.5392 6.33278 21.632 6.37466 21.7045C6.41655 21.7771 6.5093 21.8019 6.58185 21.76Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.42458 20.8533C7.51163 20.803 7.54146 20.6917 7.4912 20.6046C7.44094 20.5176 7.32963 20.4878 7.24258 20.538C7.15553 20.5883 7.1257 20.6996 7.17596 20.7866C7.22622 20.8737 7.33753 20.9035 7.42458 20.8533Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.30358 20.8527C6.35436 20.8233 6.37176 20.7584 6.34244 20.7076C6.31312 20.6569 6.24819 20.6395 6.19741 20.6688C6.14663 20.6981 6.12924 20.763 6.15855 20.8138C6.18787 20.8646 6.2528 20.882 6.30358 20.8527Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.56823 20.9443C5.61901 20.915 5.63641 20.8501 5.60709 20.7993C5.57777 20.7485 5.51284 20.7311 5.46206 20.7605C5.41128 20.7898 5.39388 20.8547 5.4232 20.9055C5.45252 20.9563 5.51745 20.9737 5.56823 20.9443Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.53689 20.6472C5.62394 20.597 5.65376 20.4856 5.6035 20.3986C5.55325 20.3115 5.44193 20.2817 5.35488 20.332C5.26783 20.3822 5.23801 20.4935 5.28827 20.5806C5.33853 20.6676 5.44984 20.6975 5.53689 20.6472Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.36696 20.5351C5.45401 20.4848 5.48384 20.3735 5.43358 20.2865C5.38332 20.1994 5.27201 20.1696 5.18496 20.2199C5.09791 20.2701 5.06809 20.3814 5.11834 20.4685C5.1686 20.5555 5.27991 20.5853 5.36696 20.5351Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.97542 19.3785C7.04071 19.3408 7.06307 19.2573 7.02538 19.192C6.98769 19.1268 6.9042 19.1044 6.83892 19.1421C6.77363 19.1798 6.75126 19.2633 6.78895 19.3285C6.82665 19.3938 6.91013 19.4162 6.97542 19.3785Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.49103 19.4483C6.56357 19.4065 6.58842 19.3137 6.54654 19.2412C6.50466 19.1686 6.4119 19.1438 6.33936 19.1856C6.26681 19.2275 6.24196 19.3203 6.28384 19.3928C6.32572 19.4654 6.41848 19.4902 6.49103 19.4483Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.92069 19.4622C6.00049 19.4161 6.02783 19.3141 5.98176 19.2343C5.93569 19.1545 5.83365 19.1272 5.75386 19.1732C5.67406 19.2193 5.64672 19.3213 5.69279 19.4011C5.73886 19.4809 5.8409 19.5083 5.92069 19.4622Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.4861 18.6808C6.57316 18.6306 6.60298 18.5193 6.55272 18.4322C6.50246 18.3452 6.39115 18.3153 6.3041 18.3656C6.21705 18.4159 6.18723 18.5272 6.23749 18.6142C6.28774 18.7013 6.39905 18.7311 6.4861 18.6808Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.54673 18.7496C4.60476 18.7161 4.62464 18.6419 4.59114 18.5839C4.55763 18.5259 4.48342 18.506 4.42539 18.5395C4.36736 18.573 4.34747 18.6472 4.38098 18.7052C4.41449 18.7633 4.48869 18.7832 4.54673 18.7496Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.97732 17.5563C6.06437 17.5061 6.09419 17.3948 6.04393 17.3077C5.99367 17.2207 5.88236 17.1908 5.79531 17.2411C5.70826 17.2913 5.67844 17.4027 5.7287 17.4897C5.77895 17.5768 5.89027 17.6066 5.97732 17.5563Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.45189 17.6489C5.55345 17.5903 5.58824 17.4604 5.52961 17.3589C5.47097 17.2573 5.34111 17.2225 5.23955 17.2812C5.13799 17.3398 5.1032 17.4697 5.16183 17.5712C5.22047 17.6728 5.35033 17.7076 5.45189 17.6489Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.00571 16.9091C6.06374 16.8756 6.08363 16.8014 6.05012 16.7433C6.01662 16.6853 5.94241 16.6654 5.88438 16.6989C5.82634 16.7324 5.80646 16.8066 5.83996 16.8647C5.87347 16.9227 5.94768 16.9426 6.00571 16.9091Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.4461 17.3016C4.51864 17.2597 4.5435 17.167 4.50162 17.0944C4.45974 17.0219 4.36698 16.997 4.29443 17.0389C4.22189 17.0808 4.19704 17.1736 4.23892 17.2461C4.2808 17.3186 4.37356 17.3435 4.4461 17.3016Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.37481 16.3626C5.44736 16.3207 5.47221 16.2279 5.43033 16.1554C5.38844 16.0829 5.29569 16.058 5.22314 16.0999C5.1506 16.1418 5.12575 16.2345 5.16763 16.3071C5.20951 16.3796 5.30227 16.4045 5.37481 16.3626Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.45485 16.5253C3.54191 16.4751 3.57173 16.3638 3.52147 16.2767C3.47121 16.1897 3.3599 16.1598 3.27285 16.2101C3.1858 16.2603 3.15598 16.3717 3.20624 16.4587C3.25649 16.5458 3.3678 16.5756 3.45485 16.5253Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.34653 15.3099C4.40456 15.2764 4.42445 15.2022 4.39094 15.1441C4.35744 15.0861 4.28323 15.0662 4.2252 15.0997C4.16716 15.1332 4.14728 15.2074 4.18078 15.2655C4.21429 15.3235 4.2885 15.3434 4.34653 15.3099Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.98122 15.223C4.06827 15.1728 4.0981 15.0614 4.04784 14.9744C3.99758 14.8873 3.88627 14.8575 3.79922 14.9078C3.71217 14.958 3.68234 15.0693 3.7326 15.1564C3.78286 15.2434 3.89417 15.2733 3.98122 15.223Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.62387 15.0259C2.68191 14.9924 2.70179 14.9182 2.66829 14.8601C2.63478 14.8021 2.56057 14.7822 2.50254 14.8157C2.44451 14.8492 2.42462 14.9234 2.45813 14.9815C2.49163 15.0395 2.56584 15.0594 2.62387 15.0259Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6648 33.3485C19.7083 33.3234 19.7232 33.2678 19.698 33.2243C19.6729 33.1808 19.6173 33.1659 19.5739 33.191C19.5304 33.2161 19.5155 33.2717 19.5406 33.3152C19.5657 33.3587 19.6213 33.3736 19.6648 33.3485Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.2596 33.1623C19.3249 33.1246 19.3473 33.0411 19.3096 32.9758C19.2719 32.9105 19.1884 32.8881 19.1231 32.9258C19.0578 32.9635 19.0354 33.047 19.0731 33.1123C19.1108 33.1776 19.1943 33.1999 19.2596 33.1623Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6667 32.1389C19.7683 32.0802 19.8031 31.9504 19.7445 31.8488C19.6858 31.7473 19.556 31.7125 19.4544 31.7711C19.3528 31.8297 19.318 31.9596 19.3767 32.0611C19.4353 32.1627 19.5652 32.1975 19.6667 32.1389Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.9763 31.0671C20.0634 31.0169 20.0932 30.9056 20.043 30.8185C19.9927 30.7315 19.8814 30.7016 19.7943 30.7519C19.7073 30.8022 19.6775 30.9135 19.7277 31.0005C19.778 31.0876 19.8893 31.1174 19.9763 31.0671Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.9754 32.3978C19.0335 32.3643 19.0534 32.2901 19.0198 32.232C18.9863 32.174 18.9121 32.1541 18.8541 32.1876C18.7961 32.2211 18.7762 32.2953 18.8097 32.3534C18.8432 32.4114 18.9174 32.4313 18.9754 32.3978Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.4685 32.2175C18.512 32.1924 18.5269 32.1368 18.5018 32.0933C18.4767 32.0498 18.421 32.0349 18.3776 32.06C18.3341 32.0851 18.3192 32.1407 18.3443 32.1842C18.3694 32.2277 18.425 32.2426 18.4685 32.2175Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.9646 32.1935C18.0517 32.1433 18.0815 32.032 18.0312 31.9449C17.981 31.8579 17.8697 31.828 17.7826 31.8783C17.6956 31.9286 17.6657 32.0399 17.716 32.1269C17.7663 32.214 17.8776 32.2438 17.9646 32.1935Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.0096 30.9065C18.0749 30.8688 18.0973 30.7853 18.0596 30.7201C18.0219 30.6548 17.9384 30.6324 17.8731 30.6701C17.8078 30.7078 17.7854 30.7913 17.8231 30.8566C17.8608 30.9218 17.9443 30.9442 18.0096 30.9065Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.9344 30.1092C17.9924 30.0757 18.0123 30.0015 17.9788 29.9435C17.9453 29.8854 17.8711 29.8655 17.8131 29.8991C17.7551 29.9326 17.7352 30.0068 17.7687 30.0648C17.8022 30.1228 17.8764 30.1427 17.9344 30.1092Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.2752 30.3322C17.3114 30.3113 17.3238 30.2649 17.3029 30.2287C17.282 30.1924 17.2356 30.18 17.1994 30.2009C17.1632 30.2219 17.1507 30.2682 17.1717 30.3044C17.1926 30.3407 17.2389 30.3531 17.2752 30.3322Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.115 31.3872C16.2093 31.3328 16.2416 31.2122 16.1872 31.1179C16.1327 31.0236 16.0121 30.9913 15.9178 31.0457C15.8235 31.1001 15.7912 31.2207 15.8457 31.315C15.9001 31.4093 16.0207 31.4417 16.115 31.3872Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0018 30.6126C16.0671 30.5749 16.0894 30.4914 16.0517 30.4261C16.0141 30.3608 15.9306 30.3385 15.8653 30.3761C15.8 30.4138 15.7776 30.4973 15.8153 30.5626C15.853 30.6279 15.9365 30.6503 16.0018 30.6126Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4685 29.1169C16.5556 29.0666 16.5854 28.9553 16.5351 28.8683C16.4849 28.7812 16.3736 28.7514 16.2865 28.8016C16.1995 28.8519 16.1696 28.9632 16.2199 29.0503C16.2702 29.1373 16.3815 29.1671 16.4685 29.1169Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.9598 28.9027C16.0396 28.8566 16.0669 28.7546 16.0208 28.6748C15.9748 28.595 15.8727 28.5677 15.7929 28.6137C15.7131 28.6598 15.6858 28.7618 15.7319 28.8416C15.7779 28.9214 15.88 28.9488 15.9598 28.9027Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.0526 29.9697C15.1106 29.9362 15.1305 29.862 15.097 29.8039C15.0635 29.7459 14.9893 29.726 14.9312 29.7595C14.8732 29.793 14.8533 29.8672 14.8868 29.9253C14.9203 29.9833 14.9945 30.0032 15.0526 29.9697Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.5408 29.9325C14.6206 29.8864 14.6479 29.7844 14.6019 29.7046C14.5558 29.6248 14.4538 29.5974 14.374 29.6435C14.2942 29.6896 14.2668 29.7916 14.3129 29.8714C14.359 29.9512 14.461 29.9785 14.5408 29.9325Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.6336 29.0027C14.7134 28.9567 14.7407 28.8546 14.6947 28.7748C14.6486 28.695 14.5465 28.6677 14.4667 28.7138C14.387 28.7598 14.3596 28.8619 14.4057 28.9417C14.4518 29.0215 14.5538 29.0488 14.6336 29.0027Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.1746 28.4799C14.2616 28.4297 14.2915 28.3183 14.2412 28.2313C14.1909 28.1442 14.0796 28.1144 13.9926 28.1647C13.9055 28.2149 13.8757 28.3262 13.926 28.4133C13.9762 28.5003 14.0875 28.5302 14.1746 28.4799Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.0038 29.2432C13.0546 29.2139 13.072 29.149 13.0426 29.0982C13.0133 29.0474 12.9484 29.03 12.8976 29.0593C12.8468 29.0887 12.8294 29.1536 12.8587 29.2044C12.8881 29.2551 12.953 29.2725 13.0038 29.2432Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.9831 27.4516C14.0266 27.4265 14.0415 27.3709 14.0164 27.3274C13.9913 27.2839 13.9357 27.269 13.8922 27.2941C13.8487 27.3192 13.8338 27.3749 13.8589 27.4183C13.884 27.4618 13.9396 27.4767 13.9831 27.4516Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.9383 27.2507C14.0036 27.213 14.026 27.1295 13.9883 27.0642C13.9506 26.9989 13.8671 26.9766 13.8018 27.0143C13.7365 27.052 13.7142 27.1354 13.7518 27.2007C13.7895 27.266 13.873 27.2884 13.9383 27.2507Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.4314 27.1926C13.5112 27.1465 13.5386 27.0445 13.4925 26.9647C13.4464 26.8849 13.3444 26.8576 13.2646 26.9036C13.1848 26.9497 13.1575 27.0517 13.2035 27.1315C13.2496 27.2113 13.3516 27.2387 13.4314 27.1926Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.2614 26.8698C13.3049 26.8447 13.3198 26.7891 13.2947 26.7456C13.2696 26.7021 13.214 26.6872 13.1705 26.7124C13.127 26.7375 13.1121 26.7931 13.1373 26.8365C13.1624 26.88 13.218 26.8949 13.2614 26.8698Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.9813 27.3816C12.0321 27.3523 12.0495 27.2873 12.0202 27.2366C11.9909 27.1858 11.9259 27.1684 11.8751 27.1977C11.8244 27.227 11.807 27.292 11.8363 27.3427C11.8656 27.3935 11.9305 27.4109 11.9813 27.3816Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.1542 26.4063C12.2049 26.377 12.2223 26.3121 12.193 26.2613C12.1637 26.2105 12.0988 26.1931 12.048 26.2224C11.9972 26.2517 11.9798 26.3167 12.0091 26.3675C12.0385 26.4182 12.1034 26.4356 12.1542 26.4063Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7517 25.9209C11.8315 25.8748 11.8589 25.7728 11.8128 25.693C11.7667 25.6132 11.6647 25.5858 11.5849 25.6319C11.5051 25.678 11.4778 25.78 11.5238 25.8598C11.5699 25.9396 11.672 25.9669 11.7517 25.9209Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.8924 26.5568C10.9432 26.5275 10.9606 26.4626 10.9313 26.4118C10.902 26.361 10.8371 26.3436 10.7863 26.3729C10.7355 26.4023 10.7181 26.4672 10.7474 26.518C10.7767 26.5687 10.8417 26.5861 10.8924 26.5568Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.246 25.197C11.2967 25.1677 11.3141 25.1028 11.2848 25.052C11.2555 25.0012 11.1906 24.9838 11.1398 25.0131C11.089 25.0425 11.0716 25.1074 11.1009 25.1582C11.1303 25.2089 11.1952 25.2263 11.246 25.197Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.162 25.928C10.2128 25.8987 10.2302 25.8337 10.2008 25.783C10.1715 25.7322 10.1066 25.7148 10.0558 25.7441C10.005 25.7734 9.98763 25.8383 10.017 25.8891C10.0463 25.9399 10.1112 25.9573 10.162 25.928Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.56327 26.3784C9.64307 26.3323 9.67041 26.2303 9.62434 26.1505C9.57827 26.0707 9.47623 26.0434 9.39644 26.0894C9.31664 26.1355 9.2893 26.2375 9.33537 26.3173C9.38144 26.3971 9.48347 26.4245 9.56327 26.3784Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6053 24.4812C10.6778 24.4394 10.7027 24.3466 10.6608 24.2741C10.6189 24.2015 10.5262 24.1767 10.4536 24.2185C10.3811 24.2604 10.3562 24.3532 10.3981 24.4257C10.44 24.4983 10.5327 24.5231 10.6053 24.4812Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.82398 25.1601C9.91829 25.1057 9.9506 24.9851 9.89615 24.8908C9.8417 24.7965 9.72111 24.7641 9.62681 24.8186C9.5325 24.873 9.50019 24.9936 9.55464 25.0879C9.60909 25.1822 9.72968 25.2145 9.82398 25.1601Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.45393 24.5503C9.51922 24.5126 9.54159 24.4291 9.5039 24.3639C9.4662 24.2986 9.38272 24.2762 9.31743 24.3139C9.25214 24.3516 9.22978 24.4351 9.26747 24.5004C9.30516 24.5656 9.38865 24.588 9.45393 24.5503Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.42063 23.5533C9.46411 23.5282 9.47901 23.4726 9.45391 23.4291C9.4288 23.3856 9.3732 23.3707 9.32971 23.3958C9.28623 23.4209 9.27133 23.4765 9.29643 23.52C9.32154 23.5635 9.37714 23.5784 9.42063 23.5533Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.4051 23.9474C8.47039 23.9097 8.49276 23.8262 8.45507 23.7609C8.41737 23.6957 8.33389 23.6733 8.2686 23.711C8.20332 23.7487 8.18095 23.8322 8.21864 23.8974C8.25633 23.9627 8.33982 23.9851 8.4051 23.9474Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.79376 22.9523C8.8663 22.9104 8.89115 22.8177 8.84927 22.7451C8.80739 22.6726 8.71463 22.6477 8.64209 22.6896C8.56955 22.7315 8.54469 22.8242 8.58658 22.8968C8.62846 22.9693 8.72122 22.9942 8.79376 22.9523Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.87673 23.184C7.91296 23.1631 7.92538 23.1167 7.90446 23.0805C7.88354 23.0442 7.8372 23.0318 7.80096 23.0528C7.76473 23.0737 7.75231 23.12 7.77323 23.1562C7.79415 23.1925 7.84049 23.2049 7.87673 23.184Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.90124 23.4667C6.95202 23.4374 6.96941 23.3725 6.9401 23.3217C6.91078 23.2709 6.84585 23.2535 6.79507 23.2829C6.74429 23.3122 6.72689 23.3771 6.75621 23.4279C6.78553 23.4787 6.85046 23.4961 6.90124 23.4667Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.96462 22.6951C7.00085 22.6742 7.01327 22.6279 6.99235 22.5917C6.97143 22.5554 6.92509 22.543 6.88885 22.5639C6.85262 22.5848 6.8402 22.6312 6.86112 22.6674C6.88204 22.7037 6.92838 22.7161 6.96462 22.6951Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.00073 22.394C7.09504 22.3396 7.12735 22.219 7.0729 22.1247C7.01846 22.0304 6.89787 21.9981 6.80357 22.0525C6.70926 22.107 6.67695 22.2276 6.7314 22.3219C6.78584 22.4162 6.90643 22.4485 7.00073 22.394Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.48808 21.9016C6.56787 21.8555 6.59521 21.7535 6.54914 21.6737C6.50307 21.5939 6.40104 21.5666 6.32124 21.6126C6.24145 21.6587 6.21411 21.7607 6.26018 21.8405C6.30625 21.9203 6.40828 21.9477 6.48808 21.9016Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.3953 21.2549C6.4751 21.2088 6.50244 21.1068 6.45637 21.027C6.4103 20.9472 6.30826 20.9198 6.22847 20.9659C6.14867 21.012 6.12133 21.114 6.1674 21.1938C6.21347 21.2736 6.31551 21.3009 6.3953 21.2549Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.98023 20.8993C6.07453 20.8449 6.10684 20.7243 6.0524 20.63C5.99795 20.5357 5.87736 20.5033 5.78306 20.5578C5.68875 20.6122 5.65644 20.7328 5.71089 20.8271C5.76534 20.9214 5.88592 20.9537 5.98023 20.8993Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.38259 20.5614C5.46964 20.5111 5.49947 20.3998 5.44921 20.3128C5.39895 20.2257 5.28764 20.1959 5.20059 20.2462C5.11354 20.2964 5.08371 20.4077 5.13397 20.4948C5.18423 20.5818 5.29554 20.6117 5.38259 20.5614Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.7928 20.4813C4.85809 20.4436 4.88046 20.3601 4.84277 20.2948C4.80507 20.2295 4.72159 20.2072 4.6563 20.2449C4.59101 20.2826 4.56864 20.366 4.60634 20.4313C4.64403 20.4966 4.72751 20.519 4.7928 20.4813Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.98321 19.0237C6.05575 18.9818 6.08061 18.889 6.03873 18.8165C5.99684 18.7439 5.90409 18.7191 5.83154 18.761C5.759 18.8028 5.73415 18.8956 5.77603 18.9681C5.81791 19.0407 5.91067 19.0655 5.98321 19.0237Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.93332 19.2961C3.97681 19.271 3.99171 19.2154 3.9666 19.1719C3.9415 19.1284 3.88589 19.1135 3.84241 19.1386C3.79892 19.1637 3.78402 19.2193 3.80913 19.2628C3.83424 19.3063 3.88984 19.3212 3.93332 19.2961Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.40513 18.7786C4.46316 18.7451 4.48304 18.6709 4.44954 18.6129C4.41603 18.5549 4.34182 18.535 4.28379 18.5685C4.22576 18.602 4.20587 18.6762 4.23938 18.7342C4.27288 18.7923 4.34709 18.8121 4.40513 18.7786Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.97638 18.4306C4.04892 18.3888 4.07377 18.296 4.03189 18.2235C3.99001 18.1509 3.89725 18.1261 3.82471 18.1679C3.75217 18.2098 3.72731 18.3026 3.76919 18.3751C3.81108 18.4477 3.90383 18.4725 3.97638 18.4306Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.90016 18.0546C3.9364 18.0337 3.94882 17.9873 3.92789 17.9511C3.90697 17.9149 3.86064 17.9024 3.8244 17.9234C3.78816 17.9443 3.77575 17.9906 3.79667 18.0269C3.81759 18.0631 3.86393 18.0755 3.90016 18.0546Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.87681 16.8953C4.93484 16.8618 4.95472 16.7876 4.92122 16.7296C4.88771 16.6716 4.8135 16.6517 4.75547 16.6852C4.69744 16.7187 4.67755 16.7929 4.71106 16.8509C4.74456 16.909 4.81877 16.9288 4.87681 16.8953Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.89231 17.585C2.93579 17.5599 2.95069 17.5043 2.92559 17.4608C2.90048 17.4174 2.84488 17.4025 2.80139 17.4276C2.75791 17.4527 2.74301 17.5083 2.76811 17.5518C2.79322 17.5952 2.84882 17.6101 2.89231 17.585Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.24683 17.1357C3.34113 17.0813 3.37344 16.9607 3.319 16.8664C3.26455 16.7721 3.14396 16.7398 3.04966 16.7942C2.95536 16.8487 2.92304 16.9693 2.97749 17.0636C3.03194 17.1579 3.15252 17.1902 3.24683 17.1357Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.12868 16.3285C4.21573 16.2783 4.24556 16.167 4.1953 16.0799C4.14504 15.9929 4.03373 15.963 3.94668 16.0133C3.85963 16.0636 3.82981 16.1749 3.88006 16.2619C3.93032 16.349 4.04163 16.3788 4.12868 16.3285Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.69408 16.7545C2.79563 16.6958 2.83043 16.566 2.7718 16.4644C2.71316 16.3629 2.5833 16.3281 2.48174 16.3867C2.38018 16.4453 2.34538 16.5752 2.40402 16.6767C2.46265 16.7783 2.59252 16.8131 2.69408 16.7545Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.94612 15.856C3.01141 15.8183 3.03378 15.7349 2.99608 15.6696C2.95839 15.6043 2.87491 15.5819 2.80962 15.6196C2.74433 15.6573 2.72196 15.7408 2.75966 15.8061C2.79735 15.8714 2.88083 15.8937 2.94612 15.856Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.20481 15.0928C3.24829 15.0677 3.26319 15.0121 3.23809 14.9687C3.21298 14.9252 3.15738 14.9103 3.11389 14.9354C3.07041 14.9605 3.05551 15.0161 3.08061 15.0596C3.10572 15.1031 3.16132 15.118 3.20481 15.0928Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.49694 14.9587C2.54772 14.9294 2.56512 14.8645 2.5358 14.8137C2.50648 14.7629 2.44155 14.7455 2.39077 14.7749C2.33999 14.8042 2.32259 14.8691 2.35191 14.9199C2.38123 14.9707 2.44616 14.9881 2.49694 14.9587Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.55933 14.8532C2.65363 14.7987 2.68594 14.6781 2.6315 14.5838C2.57705 14.4895 2.45646 14.4572 2.36216 14.5116C2.26786 14.5661 2.23554 14.6867 2.28999 14.781C2.34444 14.8753 2.46502 14.9076 2.55933 14.8532Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.2469 23.1585C29.3339 23.1082 29.3637 22.9969 29.3135 22.9099C29.2632 22.8228 29.1519 22.793 29.0648 22.8433C28.9778 22.8935 28.948 23.0048 28.9982 23.0919C29.0485 23.1789 29.1598 23.2088 29.2469 23.1585Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.6913 24.4433C29.7493 24.4097 29.7692 24.3355 29.7357 24.2775C29.7022 24.2195 29.628 24.1996 29.5699 24.2331C29.5119 24.2666 29.492 24.3408 29.5255 24.3988C29.559 24.4569 29.6332 24.4768 29.6913 24.4433Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.9099 24.3339C28.997 24.2837 29.0268 24.1723 28.9766 24.0853C28.9263 23.9982 28.815 23.9684 28.7279 24.0187C28.6409 24.0689 28.6111 24.1802 28.6613 24.2673C28.7116 24.3543 28.8229 24.3842 28.9099 24.3339Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.4783 24.1628C28.5436 24.1251 28.566 24.0416 28.5283 23.9763C28.4906 23.9111 28.4071 23.8887 28.3418 23.9264C28.2766 23.9641 28.2542 24.0476 28.2919 24.1128C28.3296 24.1781 28.4131 24.2005 28.4783 24.1628Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1766 24.5817C28.2419 24.5441 28.2642 24.4606 28.2266 24.3953C28.1889 24.33 28.1054 24.3076 28.0401 24.3453C27.9748 24.383 27.9524 24.4665 27.9901 24.5318C28.0278 24.5971 28.1113 24.6194 28.1766 24.5817Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.447 25.6867C28.5486 25.6281 28.5834 25.4982 28.5247 25.3967C28.4661 25.2951 28.3362 25.2603 28.2347 25.3189C28.1331 25.3776 28.0983 25.5074 28.1569 25.609C28.2156 25.7106 28.3454 25.7454 28.447 25.6867Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1199 25.9985C28.2142 25.9441 28.2465 25.8235 28.192 25.7292C28.1376 25.6349 28.017 25.6026 27.9227 25.657C27.8284 25.7115 27.7961 25.8321 27.8505 25.9264C27.905 26.0207 28.0256 26.053 28.1199 25.9985Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.0223 25.4936C27.0731 25.4643 27.0905 25.3994 27.0612 25.3486C27.0319 25.2978 26.9669 25.2805 26.9162 25.3098C26.8654 25.3391 26.848 25.404 26.8773 25.4548C26.9066 25.5056 26.9715 25.523 27.0223 25.4936Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.3474 25.6903C26.3837 25.6694 26.3961 25.6231 26.3752 25.5868C26.3542 25.5506 26.3079 25.5382 26.2717 25.5591C26.2354 25.58 26.223 25.6264 26.2439 25.6626C26.2649 25.6988 26.3112 25.7113 26.3474 25.6903Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.0624 26.1356C26.1132 26.1063 26.1306 26.0414 26.1012 25.9906C26.0719 25.9398 26.007 25.9224 25.9562 25.9517C25.9054 25.9811 25.888 26.046 25.9173 26.0968C25.9467 26.1475 26.0116 26.1649 26.0624 26.1356Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.0213 25.7903C25.1011 25.7443 25.1284 25.6422 25.0823 25.5624C25.0363 25.4826 24.9342 25.4553 24.8544 25.5014C24.7746 25.5474 24.7473 25.6495 24.7934 25.7293C24.8394 25.8091 24.9415 25.8364 25.0213 25.7903Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.7859 26.8374C24.8585 26.7955 24.8833 26.7027 24.8415 26.6302C24.7996 26.5576 24.7068 26.5328 24.6343 26.5747C24.5617 26.6166 24.5369 26.7093 24.5788 26.7819C24.6206 26.8544 24.7134 26.8793 24.7859 26.8374Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.1736 27.0508C24.2679 26.9963 24.3002 26.8757 24.2458 26.7814C24.1913 26.6871 24.0707 26.6548 23.9764 26.7093C23.8821 26.7637 23.8498 26.8843 23.9042 26.9786C23.9587 27.0729 24.0793 27.1052 24.1736 27.0508Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.5505 27.0949C23.594 27.0698 23.6089 27.0142 23.5838 26.9707C23.5587 26.9272 23.5031 26.9123 23.4596 26.9375C23.4161 26.9626 23.4012 27.0182 23.4263 27.0617C23.4514 27.1051 23.507 27.12 23.5505 27.0949Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.2694 28.0632C23.3274 28.0297 23.3473 27.9555 23.3138 27.8974C23.2803 27.8394 23.2061 27.8195 23.148 27.853C23.09 27.8865 23.0701 27.9607 23.1036 28.0188C23.1371 28.0768 23.2113 28.0967 23.2694 28.0632Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.2068 26.8902C22.2866 26.8442 22.314 26.7421 22.2679 26.6623C22.2218 26.5825 22.1198 26.5552 22.04 26.6013C21.9602 26.6473 21.9329 26.7494 21.9789 26.8292C22.025 26.909 22.127 26.9363 22.2068 26.8902Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.7869 28.7444C22.8595 28.7025 22.8843 28.6098 22.8424 28.5372C22.8006 28.4647 22.7078 28.4398 22.6353 28.4817C22.5627 28.5236 22.5379 28.6164 22.5797 28.6889C22.6216 28.7614 22.7144 28.7863 22.7869 28.7444Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.8143 27.0293C21.8868 26.9874 21.9117 26.8947 21.8698 26.8221C21.8279 26.7496 21.7351 26.7248 21.6626 26.7666C21.5901 26.8085 21.5652 26.9013 21.6071 26.9738C21.649 27.0464 21.7417 27.0712 21.8143 27.0293Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.7069 27.8443C21.7722 27.8066 21.7945 27.7231 21.7568 27.6579C21.7191 27.5926 21.6357 27.5702 21.5704 27.6079C21.5051 27.6456 21.4827 27.7291 21.5204 27.7944C21.5581 27.8597 21.6416 27.882 21.7069 27.8443Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.4744 28.8369C21.5397 28.7992 21.5621 28.7157 21.5244 28.6504C21.4867 28.5851 21.4032 28.5628 21.3379 28.6005C21.2727 28.6381 21.2503 28.7216 21.288 28.7869C21.3257 28.8522 21.4092 28.8746 21.4744 28.8369Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.7469 28.1535C20.8194 28.1116 20.8443 28.0188 20.8024 27.9463C20.7605 27.8738 20.6678 27.8489 20.5952 27.8908C20.5227 27.9327 20.4978 28.0254 20.5397 28.098C20.5816 28.1705 20.6743 28.1954 20.7469 28.1535Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.0144 29.5575C21.1015 29.5072 21.1313 29.3959 21.081 29.3089C21.0308 29.2218 20.9195 29.192 20.8324 29.2423C20.7454 29.2925 20.7155 29.4038 20.7658 29.4909C20.8161 29.5779 20.9274 29.6078 21.0144 29.5575Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.9822 28.437C20.0184 28.4161 20.0308 28.3698 20.0099 28.3335C19.989 28.2973 19.9427 28.2849 19.9064 28.3058C19.8702 28.3267 19.8578 28.3731 19.8787 28.4093C19.8996 28.4455 19.946 28.4579 19.9822 28.437Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.7176 28.8878C19.7538 28.8669 19.7662 28.8205 19.7453 28.7843C19.7244 28.748 19.678 28.7356 19.6418 28.7566C19.6055 28.7775 19.5931 28.8238 19.6141 28.86C19.635 28.8963 19.6813 28.9087 19.7176 28.8878Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.6394 28.0216C18.6757 28.0007 18.6881 27.9544 18.6672 27.9181C18.6462 27.8819 18.5999 27.8695 18.5637 27.8904C18.5274 27.9113 18.515 27.9577 18.5359 27.9939C18.5569 28.0301 18.6032 28.0426 18.6394 28.0216Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.2684 27.8333C18.3264 27.7998 18.3463 27.7256 18.3128 27.6676C18.2793 27.6095 18.2051 27.5897 18.1471 27.6232C18.089 27.6567 18.0692 27.7309 18.1027 27.7889C18.1362 27.8469 18.2104 27.8668 18.2684 27.8333Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.1092 28.468C18.1745 28.4303 18.1969 28.3468 18.1592 28.2815C18.1215 28.2162 18.038 28.1939 17.9727 28.2316C17.9074 28.2692 17.885 28.3527 17.9227 28.418C17.9604 28.4833 18.0439 28.5057 18.1092 28.468Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5555 28.7527C17.6208 28.715 17.6432 28.6315 17.6055 28.5662C17.5678 28.5009 17.4843 28.4785 17.419 28.5162C17.3537 28.5539 17.3313 28.6374 17.369 28.7027C17.4067 28.768 17.4902 28.7903 17.5555 28.7527Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.7322 29.2981C16.8192 29.2479 16.8491 29.1366 16.7988 29.0495C16.7486 28.9625 16.6372 28.9327 16.5502 28.9829C16.4631 29.0332 16.4333 29.1445 16.4836 29.2315C16.5338 29.3186 16.6451 29.3484 16.7322 29.2981Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0925 28.161C16.1868 28.1066 16.2191 27.986 16.1647 27.8917C16.1103 27.7974 15.9897 27.7651 15.8954 27.8195C15.8011 27.874 15.7687 27.9945 15.8232 28.0888C15.8776 28.1832 15.9982 28.2155 16.0925 28.161Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.1815 29.4932C15.254 29.4513 15.2789 29.3586 15.237 29.286C15.1951 29.2135 15.1023 29.1886 15.0298 29.2305C14.9572 29.2724 14.9324 29.3651 14.9743 29.4377C15.0162 29.5102 15.1089 29.5351 15.1815 29.4932Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.1395 28.3609C15.1975 28.3274 15.2174 28.2532 15.1839 28.1952C15.1504 28.1371 15.0762 28.1173 15.0182 28.1508C14.9601 28.1843 14.9402 28.2585 14.9738 28.3165C15.0073 28.3745 15.0815 28.3944 15.1395 28.3609Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.6697 28.7901C14.7712 28.7314 14.806 28.6016 14.7474 28.5C14.6887 28.3984 14.5589 28.3636 14.4573 28.4223C14.3558 28.4809 14.321 28.6108 14.3796 28.7123C14.4382 28.8139 14.5681 28.8487 14.6697 28.7901Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.576 28.1432C14.663 28.093 14.6928 27.9817 14.6426 27.8946C14.5923 27.8076 14.481 27.7777 14.3939 27.828C14.3069 27.8783 14.2771 27.9896 14.3273 28.0766C14.3776 28.1637 14.4889 28.1935 14.576 28.1432Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.7761 29.1834C13.8704 29.129 13.9027 29.0084 13.8483 28.9141C13.7938 28.8198 13.6733 28.7875 13.579 28.8419C13.4847 28.8964 13.4523 29.0169 13.5068 29.1112C13.5612 29.2056 13.6818 29.2379 13.7761 29.1834Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.8885 27.3148C13.9683 27.2687 13.9956 27.1667 13.9495 27.0869C13.9035 27.0071 13.8014 26.9798 13.7216 27.0258C13.6418 27.0719 13.6145 27.1739 13.6606 27.2537C13.7066 27.3335 13.8087 27.3609 13.8885 27.3148Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.6122 28.3496C12.6702 28.3161 12.6901 28.2419 12.6566 28.1838C12.6231 28.1258 12.5489 28.1059 12.4908 28.1394C12.4328 28.1729 12.4129 28.2471 12.4464 28.3052C12.4799 28.3632 12.5541 28.3831 12.6122 28.3496Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5457 27.9322C12.6183 27.8903 12.6431 27.7975 12.6012 27.725C12.5593 27.6524 12.4666 27.6276 12.394 27.6695C12.3215 27.7113 12.2966 27.8041 12.3385 27.8767C12.3804 27.9492 12.4732 27.974 12.5457 27.9322Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.3875 26.6575C12.4673 26.6114 12.4946 26.5094 12.4486 26.4296C12.4025 26.3498 12.3005 26.3224 12.2207 26.3685C12.1409 26.4146 12.1135 26.5166 12.1596 26.5964C12.2057 26.6762 12.3077 26.7035 12.3875 26.6575Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.8162 26.7597C11.8888 26.7178 11.9136 26.625 11.8717 26.5525C11.8299 26.48 11.7371 26.4551 11.6646 26.497C11.592 26.5389 11.5672 26.6316 11.609 26.7042C11.6509 26.7767 11.7437 26.8016 11.8162 26.7597Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7009 26.6511C11.8025 26.5924 11.8373 26.4626 11.7786 26.361C11.72 26.2595 11.5901 26.2247 11.4886 26.2833C11.387 26.3419 11.3522 26.4718 11.4109 26.5734C11.4695 26.6749 11.5994 26.7097 11.7009 26.6511Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.7312 26.913C10.7747 26.8879 10.7896 26.8323 10.7645 26.7888C10.7393 26.7453 10.6837 26.7304 10.6403 26.7555C10.5968 26.7806 10.5819 26.8362 10.607 26.8797C10.6321 26.9232 10.6877 26.9381 10.7312 26.913Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.1609 25.807C11.2335 25.7651 11.2583 25.6723 11.2165 25.5998C11.1746 25.5273 11.0818 25.5024 11.0093 25.5443C10.9367 25.5862 10.9119 25.6789 10.9538 25.7515C10.9956 25.824 11.0884 25.8489 11.1609 25.807Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.5828 25.6853C10.6554 25.6435 10.6802 25.5507 10.6383 25.4782C10.5965 25.4056 10.5037 25.3808 10.4312 25.4226C10.3586 25.4645 10.3338 25.5573 10.3756 25.6298C10.4175 25.7024 10.5103 25.7272 10.5828 25.6853Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.82501 26.0707C9.89755 26.0288 9.9224 25.936 9.88052 25.8635C9.83864 25.7909 9.74588 25.7661 9.67334 25.808C9.6008 25.8498 9.57594 25.9426 9.61783 26.0151C9.65971 26.0877 9.75247 26.1125 9.82501 26.0707Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.40999 25.5044C9.47528 25.4667 9.49765 25.3832 9.45995 25.3179C9.42226 25.2526 9.33877 25.2302 9.27349 25.2679C9.2082 25.3056 9.18583 25.3891 9.22352 25.4544C9.26122 25.5197 9.3447 25.5421 9.40999 25.5044Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.91579 24.5295C10.0028 24.4792 10.0327 24.3679 9.98241 24.2809C9.93215 24.1938 9.82084 24.164 9.73379 24.2142C9.64674 24.2645 9.61691 24.3758 9.66717 24.4629C9.71743 24.5499 9.82874 24.5797 9.91579 24.5295Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.93245 25.1321C8.99774 25.0944 9.02011 25.0109 8.98241 24.9456C8.94472 24.8804 8.86124 24.858 8.79595 24.8957C8.73066 24.9334 8.70829 25.0169 8.74599 25.0821C8.78368 25.1474 8.86716 25.1698 8.93245 25.1321Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.35816 24.9559C8.45246 24.9015 8.48477 24.7809 8.43032 24.6866C8.37588 24.5923 8.25529 24.56 8.16099 24.6144C8.06668 24.6689 8.03437 24.7895 8.08882 24.8838C8.14326 24.9781 8.26385 25.0104 8.35816 24.9559Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.28499 24.5255C8.35028 24.4878 8.37265 24.4043 8.33495 24.339C8.29726 24.2737 8.21378 24.2514 8.14849 24.289C8.0832 24.3267 8.06083 24.4102 8.09852 24.4755C8.13622 24.5408 8.2197 24.5632 8.28499 24.5255Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.0896 24.5508C8.18391 24.4963 8.21622 24.3758 8.16177 24.2814C8.10732 24.1871 7.98674 24.1548 7.89243 24.2093C7.79813 24.2637 7.76582 24.3843 7.82026 24.4786C7.87471 24.5729 7.9953 24.6052 8.0896 24.5508Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.92466 24.2957C7.98269 24.2622 8.00257 24.188 7.96907 24.1299C7.93556 24.0719 7.86135 24.052 7.80332 24.0855C7.74529 24.119 7.7254 24.1932 7.75891 24.2513C7.79242 24.3093 7.86662 24.3292 7.92466 24.2957Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.50763 23.8178C7.58017 23.7759 7.60502 23.6831 7.56314 23.6106C7.52126 23.5381 7.4285 23.5132 7.35596 23.5551C7.28342 23.597 7.25856 23.6897 7.30044 23.7623C7.34233 23.8348 7.43508 23.8597 7.50763 23.8178Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.5477 23.4621C7.60574 23.4286 7.62562 23.3544 7.59212 23.2964C7.55861 23.2383 7.4844 23.2184 7.42637 23.252C7.36833 23.2855 7.34845 23.3597 7.38196 23.4177C7.41546 23.4757 7.48967 23.4956 7.5477 23.4621Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.35821 23.6762C6.43075 23.6343 6.45561 23.5415 6.41373 23.469C6.37184 23.3965 6.27909 23.3716 6.20654 23.4135C6.134 23.4554 6.10915 23.5481 6.15103 23.6207C6.19291 23.6932 6.28567 23.7181 6.35821 23.6762Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.76938 22.5983C6.82742 22.5648 6.8473 22.4906 6.8138 22.4326C6.78029 22.3746 6.70608 22.3547 6.64805 22.3882C6.59001 22.4217 6.57013 22.4959 6.60364 22.5539C6.63714 22.612 6.71135 22.6319 6.76938 22.5983Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.47925 22.5205C6.57356 22.4661 6.60587 22.3455 6.55142 22.2512C6.49697 22.1569 6.37639 22.1246 6.28208 22.179C6.18778 22.2335 6.15547 22.354 6.20991 22.4483C6.26436 22.5426 6.38495 22.575 6.47925 22.5205Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.2264 22.264C6.29168 22.2263 6.31405 22.1428 6.27636 22.0775C6.23866 22.0122 6.15518 21.9899 6.08989 22.0276C6.02461 22.0653 6.00224 22.1488 6.03993 22.214C6.07763 22.2793 6.16111 22.3017 6.2264 22.264Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.53684 21.2259C6.58032 21.2008 6.59522 21.1452 6.57012 21.1017C6.54501 21.0582 6.48941 21.0433 6.44592 21.0684C6.40244 21.0935 6.38754 21.1491 6.41265 21.1926C6.43775 21.2361 6.49335 21.251 6.53684 21.2259Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.77718 21.6647C4.84246 21.627 4.86483 21.5435 4.82714 21.4782C4.78945 21.4129 4.70596 21.3906 4.64067 21.4283C4.57539 21.466 4.55302 21.5494 4.59071 21.6147C4.62841 21.68 4.71189 21.7024 4.77718 21.6647Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.26935 20.1549C6.34189 20.113 6.36674 20.0202 6.32486 19.9477C6.28298 19.8752 6.19022 19.8503 6.11768 19.8922C6.04514 19.9341 6.02028 20.0268 6.06216 20.0994C6.10404 20.1719 6.1968 20.1968 6.26935 20.1549Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.86798 20.7011C4.94052 20.6592 4.96537 20.5664 4.92349 20.4939C4.88161 20.4214 4.78885 20.3965 4.71631 20.4384C4.64377 20.4803 4.61891 20.573 4.6608 20.6456C4.70268 20.7181 4.79544 20.743 4.86798 20.7011Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.99875 19.9253C5.04224 19.9002 5.05714 19.8446 5.03203 19.8011C5.00692 19.7576 4.95132 19.7427 4.90784 19.7678C4.86435 19.7929 4.84945 19.8485 4.87456 19.892C4.89967 19.9355 4.95527 19.9504 4.99875 19.9253Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.70493 19.9023C4.76296 19.8688 4.78285 19.7946 4.74934 19.7366C4.71584 19.6785 4.64163 19.6586 4.58359 19.6921C4.52556 19.7256 4.50568 19.7999 4.53918 19.8579C4.57269 19.9159 4.6469 19.9358 4.70493 19.9023Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.11411 19.4553C4.17214 19.4218 4.19203 19.3476 4.15852 19.2895C4.12501 19.2315 4.05081 19.2116 3.99277 19.2451C3.93474 19.2786 3.91486 19.3528 3.94836 19.4109C3.98187 19.4689 4.05608 19.4888 4.11411 19.4553Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.7468 18.8793C3.79028 18.8542 3.80518 18.7986 3.78008 18.7551C3.75497 18.7116 3.69937 18.6967 3.65588 18.7218C3.6124 18.7469 3.5975 18.8025 3.62261 18.846C3.64771 18.8895 3.70332 18.9044 3.7468 18.8793Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.63547 18.1737C4.67896 18.1486 4.69386 18.0929 4.66875 18.0495C4.64364 18.006 4.58804 17.9911 4.54456 18.0162C4.50107 18.0413 4.48617 18.0969 4.51128 18.1404C4.53638 18.1839 4.59199 18.1988 4.63547 18.1737Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.66184 17.6148C4.70532 17.5897 4.72022 17.5341 4.69512 17.4906C4.67001 17.4471 4.61441 17.4322 4.57092 17.4573C4.52744 17.4825 4.51254 17.5381 4.53765 17.5815C4.56275 17.625 4.61835 17.6399 4.66184 17.6148Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.98902 17.3915C5.08332 17.337 5.11563 17.2164 5.06118 17.1221C5.00674 17.0278 4.88615 16.9955 4.79185 17.05C4.69754 17.1044 4.66523 17.225 4.71968 17.3193C4.77413 17.4136 4.89471 17.4459 4.98902 17.3915Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.37282 17.4136C3.45987 17.3634 3.4897 17.2521 3.43944 17.165C3.38918 17.078 3.27787 17.0481 3.19082 17.0984C3.10377 17.1486 3.07395 17.26 3.1242 17.347C3.17446 17.4341 3.28577 17.4639 3.37282 17.4136Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.12876 16.2947C4.18679 16.2612 4.20667 16.187 4.17317 16.1289C4.13966 16.0709 4.06546 16.051 4.00742 16.0845C3.94939 16.118 3.92951 16.1922 3.96301 16.2503C3.99652 16.3083 4.07072 16.3282 4.12876 16.2947Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.4383 26.772C20.5108 26.7301 20.5357 26.6374 20.4938 26.5648C20.4519 26.4923 20.3592 26.4674 20.2866 26.5093C20.2141 26.5512 20.1892 26.644 20.2311 26.7165C20.273 26.789 20.3657 26.8139 20.4383 26.772Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.9305 28.1696C20.9885 28.1361 21.0084 28.0619 20.9749 28.0038C20.9414 27.9458 20.8672 27.9259 20.8092 27.9594C20.7511 27.9929 20.7313 28.0671 20.7648 28.1252C20.7983 28.1832 20.8725 28.2031 20.9305 28.1696Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.2127 27.866C20.2708 27.8325 20.2907 27.7583 20.2572 27.7003C20.2237 27.6423 20.1494 27.6224 20.0914 27.6559C20.0334 27.6894 20.0135 27.7636 20.047 27.8216C20.0805 27.8797 20.1547 27.8995 20.2127 27.866Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.4031 27.738C19.4901 27.6877 19.52 27.5764 19.4697 27.4894C19.4195 27.4023 19.3081 27.3725 19.2211 27.4227C19.134 27.473 19.1042 27.5843 19.1545 27.6714C19.2047 27.7584 19.316 27.7882 19.4031 27.738Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.6218 27.2955C18.6653 27.2704 18.6802 27.2148 18.6551 27.1713C18.63 27.1278 18.5744 27.1129 18.5309 27.138C18.4874 27.1631 18.4725 27.2187 18.4976 27.2622C18.5227 27.3057 18.5783 27.3206 18.6218 27.2955Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.3153 27.28C18.3733 27.2465 18.3932 27.1723 18.3597 27.1143C18.3262 27.0563 18.252 27.0364 18.1939 27.0699C18.1359 27.1034 18.116 27.1776 18.1495 27.2356C18.183 27.2937 18.2572 27.3135 18.3153 27.28Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.9177 28.3502C17.9612 28.3251 17.9761 28.2695 17.951 28.226C17.9259 28.1826 17.8703 28.1677 17.8268 28.1928C17.7833 28.2179 17.7684 28.2735 17.7935 28.317C17.8186 28.3604 17.8742 28.3753 17.9177 28.3502Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5564 28.4535C17.6579 28.3949 17.6927 28.265 17.6341 28.1635C17.5755 28.0619 17.4456 28.0271 17.344 28.0857C17.2425 28.1444 17.2077 28.2742 17.2663 28.3758C17.325 28.4773 17.4548 28.5121 17.5564 28.4535Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.9617 28.2718C17.0415 28.2258 17.0688 28.1237 17.0228 28.0439C16.9767 27.9641 16.8747 27.9368 16.7949 27.9829C16.7151 28.0289 16.6877 28.131 16.7338 28.2108C16.7799 28.2906 16.8819 28.3179 16.9617 28.2718Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.5877 29.1708C16.6747 29.1206 16.7045 29.0093 16.6543 28.9222C16.604 28.8352 16.4927 28.8053 16.4057 28.8556C16.3186 28.9059 16.2888 29.0172 16.339 29.1042C16.3893 29.1913 16.5006 29.2211 16.5877 29.1708Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.9412 27.6876C16.0355 27.6331 16.0678 27.5125 16.0133 27.4182C15.9589 27.3239 15.8383 27.2916 15.744 27.3461C15.6497 27.4005 15.6174 27.5211 15.6718 27.6154C15.7263 27.7097 15.8469 27.742 15.9412 27.6876Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.3895 28.5485C14.4475 28.515 14.4674 28.4408 14.4339 28.3828C14.4004 28.3248 14.3262 28.3049 14.2682 28.3384C14.2101 28.3719 14.1902 28.4461 14.2238 28.5041C14.2573 28.5622 14.3315 28.582 14.3895 28.5485Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.7752 27.2751C14.8405 27.2374 14.8629 27.1539 14.8252 27.0886C14.7875 27.0234 14.704 27.001 14.6387 27.0387C14.5734 27.0764 14.5511 27.1599 14.5888 27.2251C14.6265 27.2904 14.7099 27.3128 14.7752 27.2751Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.287 27.3995C14.3378 27.3702 14.3552 27.3053 14.3258 27.2545C14.2965 27.2037 14.2316 27.1863 14.1808 27.2157C14.13 27.245 14.1126 27.3099 14.142 27.3607C14.1713 27.4115 14.2362 27.4289 14.287 27.3995Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.8065 27.904C13.879 27.8621 13.9039 27.7694 13.862 27.6968C13.8201 27.6243 13.7273 27.5994 13.6548 27.6413C13.5822 27.6832 13.5574 27.776 13.5993 27.8485C13.6412 27.9211 13.7339 27.9459 13.8065 27.904Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.4656 27.6458C13.5309 27.6081 13.5533 27.5247 13.5156 27.4594C13.4779 27.3941 13.3944 27.3717 13.3292 27.4094C13.2639 27.4471 13.2415 27.5306 13.2792 27.5959C13.3169 27.6612 13.4004 27.6835 13.4656 27.6458Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.6707 27.877C12.736 27.8393 12.7584 27.7559 12.7207 27.6906C12.683 27.6253 12.5995 27.6029 12.5342 27.6406C12.4689 27.6783 12.4466 27.7618 12.4843 27.8271C12.522 27.8924 12.6054 27.9147 12.6707 27.877Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.7538 26.6558C12.8118 26.6223 12.8317 26.5481 12.7982 26.49C12.7647 26.432 12.6905 26.4121 12.6324 26.4456C12.5744 26.4791 12.5545 26.5533 12.588 26.6114C12.6215 26.6694 12.6957 26.6893 12.7538 26.6558Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.4812 26.3052C12.5827 26.2466 12.6175 26.1167 12.5589 26.0151C12.5003 25.9136 12.3704 25.8788 12.2688 25.9374C12.1673 25.996 12.1325 26.1259 12.1911 26.2275C12.2498 26.329 12.3796 26.3638 12.4812 26.3052Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.0428 26.5761C12.1153 26.5343 12.1402 26.4415 12.0983 26.369C12.0564 26.2964 11.9637 26.2716 11.8911 26.3134C11.8186 26.3553 11.7937 26.4481 11.8356 26.5206C11.8775 26.5932 11.9702 26.618 12.0428 26.5761Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.2732 25.6721C12.3603 25.6219 12.3901 25.5105 12.3398 25.4235C12.2896 25.3364 12.1783 25.3066 12.0912 25.3569C12.0042 25.4071 11.9743 25.5184 12.0246 25.6055C12.0749 25.6925 12.1862 25.7224 12.2732 25.6721Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.2468 27.1403C11.3411 27.0859 11.3734 26.9653 11.319 26.871C11.2646 26.7767 11.144 26.7444 11.0497 26.7988C10.9554 26.8533 10.923 26.9738 10.9775 27.0682C11.0319 27.1625 11.1525 27.1948 11.2468 27.1403Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.9226 26.4871C11.0241 26.4285 11.0589 26.2986 11.0003 26.1971C10.9417 26.0955 10.8118 26.0607 10.7103 26.1194C10.6087 26.178 10.5739 26.3079 10.6325 26.4094C10.6912 26.511 10.821 26.5458 10.9226 26.4871Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6043 26.0578C10.6768 26.0159 10.7017 25.9231 10.6598 25.8506C10.6179 25.778 10.5252 25.7532 10.4526 25.7951C10.3801 25.837 10.3552 25.9297 10.3971 26.0023C10.439 26.0748 10.5318 26.0997 10.6043 26.0578Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.8553 26.0348C9.92059 25.9971 9.94296 25.9136 9.90527 25.8483C9.86757 25.783 9.78409 25.7606 9.7188 25.7983C9.65351 25.836 9.63114 25.9195 9.66884 25.9848C9.70653 26.0501 9.79002 26.0725 9.8553 26.0348Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.50171 25.5735C9.59602 25.519 9.62833 25.3985 9.57388 25.3042C9.51943 25.2098 9.39885 25.1775 9.30454 25.232C9.21024 25.2864 9.17793 25.407 9.23237 25.5013C9.28682 25.5956 9.40741 25.6279 9.50171 25.5735Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.96272 25.6399C9.02801 25.6022 9.05038 25.5187 9.01269 25.4535C8.97499 25.3882 8.89151 25.3658 8.82622 25.4035C8.76093 25.4412 8.73856 25.5247 8.77626 25.59C8.81395 25.6552 8.89744 25.6776 8.96272 25.6399Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.64536 25.788C8.7034 25.7545 8.72328 25.6803 8.68977 25.6223C8.65627 25.5643 8.58206 25.5444 8.52402 25.5779C8.46599 25.6114 8.44611 25.6856 8.47962 25.7436C8.51312 25.8017 8.58733 25.8216 8.64536 25.788Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.68536 25.2215C8.7579 25.1796 8.78276 25.0869 8.74087 25.0143C8.69899 24.9418 8.60623 24.917 8.53369 24.9588C8.46115 25.0007 8.4363 25.0935 8.47818 25.166C8.52006 25.2386 8.61282 25.2634 8.68536 25.2215Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.55155 24.686C8.63135 24.6399 8.65869 24.5379 8.61262 24.4581C8.56655 24.3783 8.46451 24.351 8.38472 24.3971C8.30492 24.4431 8.27758 24.5452 8.32365 24.625C8.36972 24.7047 8.47176 24.7321 8.55155 24.686Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.88171 25.0728C7.93249 25.0434 7.94988 24.9785 7.92057 24.9277C7.89125 24.877 7.82632 24.8596 7.77554 24.8889C7.72476 24.9182 7.70736 24.9831 7.73668 25.0339C7.76599 25.0847 7.83093 25.1021 7.88171 25.0728Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.93438 23.6766C8.00693 23.6347 8.03178 23.542 7.9899 23.4694C7.94802 23.3969 7.85526 23.372 7.78272 23.4139C7.71017 23.4558 7.68532 23.5486 7.7272 23.6211C7.76908 23.6936 7.86184 23.7185 7.93438 23.6766Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.70581 23.8251C6.80012 23.7707 6.83243 23.6501 6.77798 23.5558C6.72353 23.4615 6.60295 23.4292 6.50864 23.4836C6.41434 23.5381 6.38203 23.6587 6.43648 23.753C6.49092 23.8473 6.61151 23.8796 6.70581 23.8251Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.44608 23.2223C6.52588 23.1763 6.55322 23.0742 6.50715 22.9944C6.46108 22.9146 6.35905 22.8873 6.27925 22.9334C6.19945 22.9794 6.17211 23.0815 6.21818 23.1613C6.26425 23.2411 6.36629 23.2684 6.44608 23.2223Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.67746 23.6828C5.72095 23.6577 5.73585 23.6021 5.71074 23.5586C5.68564 23.5151 5.63003 23.5002 5.58655 23.5253C5.54306 23.5504 5.52816 23.6061 5.55327 23.6495C5.57837 23.693 5.63398 23.7079 5.67746 23.6828Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.89133 22.0539C6.93482 22.0288 6.94972 21.9732 6.92461 21.9297C6.8995 21.8862 6.8439 21.8713 6.80042 21.8964C6.75693 21.9215 6.74203 21.9771 6.76714 22.0206C6.79224 22.0641 6.84785 22.079 6.89133 22.0539Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.04759 22.4717C6.14915 22.4131 6.18395 22.2832 6.12531 22.1816C6.06668 22.0801 5.93681 22.0453 5.83525 22.1039C5.7337 22.1626 5.6989 22.2924 5.75754 22.394C5.81617 22.4955 5.94603 22.5303 6.04759 22.4717Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.39731 22.1642C5.45535 22.1307 5.47523 22.0565 5.44172 21.9985C5.40822 21.9404 5.33401 21.9205 5.27598 21.954C5.21794 21.9875 5.19806 22.0618 5.23157 22.1198C5.26507 22.1778 5.33928 22.1977 5.39731 22.1642Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.70292 21.3915C5.78272 21.3455 5.81006 21.2434 5.76399 21.1636C5.71792 21.0838 5.61588 21.0565 5.53609 21.1026C5.45629 21.1486 5.42895 21.2507 5.47502 21.3305C5.52109 21.4103 5.62312 21.4376 5.70292 21.3915Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.19809 21.3336C5.25613 21.3001 5.27601 21.2259 5.2425 21.1678C5.209 21.1098 5.13479 21.0899 5.07676 21.1234C5.01873 21.1569 4.99884 21.2311 5.03235 21.2892C5.06585 21.3472 5.14006 21.3671 5.19809 21.3336Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.44116 20.6327C5.53547 20.5782 5.56778 20.4577 5.51333 20.3634C5.45889 20.269 5.3383 20.2367 5.244 20.2912C5.14969 20.3456 5.11738 20.4662 5.17183 20.5605C5.22627 20.6548 5.34686 20.6871 5.44116 20.6327Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.85925 20.7752C3.91003 20.7458 3.92742 20.6809 3.89811 20.6301C3.86879 20.5793 3.80386 20.5619 3.75308 20.5913C3.7023 20.6206 3.6849 20.6855 3.71422 20.7363C3.74354 20.7871 3.80847 20.8045 3.85925 20.7752Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.55646 20.1625C4.629 20.1206 4.65385 20.0279 4.61197 19.9553C4.57009 19.8828 4.47733 19.8579 4.40479 19.8998C4.33224 19.9417 4.30739 20.0345 4.34927 20.107C4.39115 20.1795 4.48391 20.2044 4.55646 20.1625Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.15894 19.9893C4.25324 19.9349 4.28555 19.8143 4.23111 19.72C4.17666 19.6257 4.05607 19.5934 3.96177 19.6478C3.86746 19.7023 3.83515 19.8228 3.8896 19.9172C3.94405 20.0115 4.06463 20.0438 4.15894 19.9893Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.98415 19.0225C5.0712 18.9722 5.10103 18.8609 5.05077 18.7738C5.00051 18.6868 4.8892 18.657 4.80215 18.7072C4.7151 18.7575 4.68527 18.8688 4.73553 18.9558C4.78579 19.0429 4.8971 19.0727 4.98415 19.0225Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.08971 19.5031C3.14049 19.4738 3.15789 19.4089 3.12857 19.3581C3.09926 19.3073 3.03432 19.2899 2.98355 19.3192C2.93277 19.3485 2.91537 19.4135 2.94468 19.4643C2.974 19.515 3.03893 19.5324 3.08971 19.5031Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.79665 19.4799C2.8837 19.4297 2.91353 19.3183 2.86327 19.2313C2.81301 19.1442 2.7017 19.1144 2.61465 19.1647C2.5276 19.2149 2.49777 19.3262 2.54803 19.4133C2.59829 19.5003 2.7096 19.5302 2.79665 19.4799Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.57696 18.4693C4.6495 18.4274 4.67436 18.3346 4.63248 18.2621C4.59059 18.1895 4.49784 18.1647 4.42529 18.2066C4.35275 18.2485 4.3279 18.3412 4.36978 18.4138C4.41166 18.4863 4.50442 18.5112 4.57696 18.4693Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.4754 18.2297C3.54794 18.1878 3.5728 18.0951 3.53091 18.0225C3.48903 17.95 3.39627 17.9251 3.32373 17.967C3.25119 18.0089 3.22633 18.1017 3.26822 18.1742C3.3101 18.2467 3.40286 18.2716 3.4754 18.2297Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.71558 18.2481C2.80988 18.1937 2.84219 18.0731 2.78775 17.9788C2.7333 17.8845 2.61271 17.8522 2.51841 17.9066C2.42411 17.9611 2.39179 18.0816 2.44624 18.1759C2.50069 18.2702 2.62127 18.3026 2.71558 18.2481Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.5487 17.8369C2.59948 17.8075 2.61687 17.7426 2.58756 17.6918C2.55824 17.6411 2.49331 17.6237 2.44253 17.653C2.39175 17.6823 2.37435 17.7472 2.40367 17.798C2.43299 17.8488 2.49792 17.8662 2.5487 17.8369Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.02707 16.6676C4.07056 16.6424 4.08546 16.5868 4.06035 16.5434C4.03525 16.4999 3.97964 16.485 3.93616 16.5101C3.89267 16.5352 3.87777 16.5908 3.90288 16.6343C3.92799 16.6778 3.98359 16.6927 4.02707 16.6676Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.40612 16.2904C3.4569 16.2611 3.4743 16.1962 3.44498 16.1454C3.41566 16.0946 3.35073 16.0772 3.29995 16.1065C3.24917 16.1358 3.23177 16.2008 3.26109 16.2516C3.29041 16.3023 3.35534 16.3197 3.40612 16.2904Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.22638 16.0963C2.29892 16.0544 2.32377 15.9616 2.28189 15.8891C2.24001 15.8166 2.14725 15.7917 2.07471 15.8336C2.00217 15.8755 1.97731 15.9682 2.01919 16.0408C2.06108 16.1133 2.15383 16.1382 2.22638 16.0963Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.30157 15.1076C3.37411 15.0657 3.39897 14.9729 3.35709 14.9004C3.3152 14.8279 3.22245 14.803 3.1499 14.8449C3.07736 14.8868 3.05251 14.9795 3.09439 15.0521C3.13627 15.1246 3.22903 15.1495 3.30157 15.1076Z" fill={props.bgColor || defaultIconColor} />
        <path d="M1.33678 15.4362C1.38756 15.4068 1.40496 15.3419 1.37564 15.2911C1.34633 15.2404 1.2814 15.223 1.23062 15.2523C1.17984 15.2816 1.16244 15.3465 1.19176 15.3973C1.22107 15.4481 1.286 15.4655 1.33678 15.4362Z" fill={props.bgColor || defaultIconColor} />
        <path d="M2.81524 14.8106C2.88779 14.7688 2.91264 14.676 2.87076 14.6035C2.82888 14.5309 2.73612 14.5061 2.66358 14.5479C2.59103 14.5898 2.56618 14.6826 2.60806 14.7551C2.64994 14.8277 2.7427 14.8525 2.81524 14.8106Z" fill={props.bgColor || defaultIconColor} />
        <path d="M1.19116 14.8198C1.28547 14.7654 1.31778 14.6448 1.26333 14.5505C1.20889 14.4562 1.0883 14.4239 0.993995 14.4783C0.899691 14.5328 0.86738 14.6534 0.921827 14.7477C0.976273 14.842 1.09686 14.8743 1.19116 14.8198Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.3094 29.9973C17.3819 29.9554 17.4068 29.8626 17.3649 29.7901C17.323 29.7176 17.2303 29.6927 17.1577 29.7346C17.0852 29.7765 17.0603 29.8692 17.1022 29.9418C17.1441 30.0143 17.2368 30.0392 17.3094 29.9973Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0877 31.0355C16.182 30.9811 16.2143 30.8605 16.1598 30.7662C16.1054 30.6719 15.9848 30.6396 15.8905 30.694C15.7962 30.7485 15.7639 30.8691 15.8183 30.9634C15.8728 31.0577 15.9933 31.09 16.0877 31.0355Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0085 30.0481C16.052 30.023 16.0669 29.9674 16.0418 29.9239C16.0167 29.8804 15.9611 29.8655 15.9176 29.8906C15.8741 29.9157 15.8592 29.9713 15.8843 30.0148C15.9094 30.0583 15.965 30.0732 16.0085 30.0481Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.9558 29.7456C16.0501 29.6912 16.0824 29.5706 16.028 29.4763C15.9735 29.382 15.8529 29.3497 15.7586 29.4041C15.6643 29.4585 15.632 29.5791 15.6865 29.6734C15.7409 29.7677 15.8615 29.8001 15.9558 29.7456Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.704 29.7335C15.762 29.7 15.7819 29.6258 15.7484 29.5677C15.7149 29.5097 15.6406 29.4898 15.5826 29.5233C15.5246 29.5568 15.5047 29.631 15.5382 29.6891C15.5717 29.7471 15.6459 29.767 15.704 29.7335Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.9314 30.2148C15.0257 30.1603 15.058 30.0397 15.0036 29.9454C14.9491 29.8511 14.8285 29.8188 14.7342 29.8733C14.6399 29.9277 14.6076 30.0483 14.6621 30.1426C14.7165 30.2369 14.8371 30.2692 14.9314 30.2148Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.4997 30.4989C14.6013 30.4402 14.6361 30.3104 14.5775 30.2088C14.5188 30.1072 14.389 30.0724 14.2874 30.1311C14.1858 30.1897 14.151 30.3196 14.2097 30.4211C14.2683 30.5227 14.3982 30.5575 14.4997 30.4989Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.0555 30.1246C14.1208 30.0869 14.1432 30.0034 14.1055 29.9381C14.0678 29.8728 13.9843 29.8505 13.919 29.8882C13.8537 29.9259 13.8313 30.0093 13.869 30.0746C13.9067 30.1399 13.9902 30.1623 14.0555 30.1246Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.5682 29.7054C13.6407 29.6635 13.6656 29.5707 13.6237 29.4982C13.5818 29.4256 13.489 29.4008 13.4165 29.4427C13.344 29.4845 13.3191 29.5773 13.361 29.6498C13.4029 29.7224 13.4956 29.7472 13.5682 29.7054Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1512 29.8935C13.2237 29.8516 13.2486 29.7589 13.2067 29.6863C13.1648 29.6138 13.0721 29.5889 12.9995 29.6308C12.927 29.6727 12.9021 29.7655 12.944 29.838C12.9859 29.9106 13.0786 29.9354 13.1512 29.8935Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.0467 29.166C13.1265 29.1199 13.1538 29.0179 13.1077 28.9381C13.0617 28.8583 12.9596 28.831 12.8798 28.877C12.8 28.9231 12.7727 29.0251 12.8188 29.1049C12.8648 29.1847 12.9669 29.2121 13.0467 29.166Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1209 28.3529C13.179 28.3194 13.1989 28.2451 13.1654 28.1871C13.1319 28.1291 13.0576 28.1092 12.9996 28.1427C12.9416 28.1762 12.9217 28.2504 12.9552 28.3084C12.9887 28.3665 13.0629 28.3864 13.1209 28.3529Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5516 29.0666C12.6313 29.0205 12.6587 28.9185 12.6126 28.8387C12.5665 28.7589 12.4645 28.7315 12.3847 28.7776C12.3049 28.8237 12.2776 28.9257 12.3237 29.0055C12.3697 29.0853 12.4718 29.1126 12.5516 29.0666Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.7273 27.5815C12.8288 27.5229 12.8636 27.393 12.805 27.2914C12.7464 27.1899 12.6165 27.1551 12.5149 27.2137C12.4134 27.2724 12.3786 27.4022 12.4372 27.5038C12.4959 27.6053 12.6257 27.6401 12.7273 27.5815Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7694 28.7125C11.8347 28.6749 11.857 28.5914 11.8193 28.5261C11.7816 28.4608 11.6981 28.4384 11.6329 28.4761C11.5676 28.5138 11.5452 28.5973 11.5829 28.6626C11.6206 28.7279 11.7041 28.7502 11.7694 28.7125Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.5555 28.5204C10.6208 28.4827 10.6432 28.3992 10.6055 28.3339C10.5678 28.2687 10.4843 28.2463 10.419 28.284C10.3537 28.3217 10.3313 28.4052 10.369 28.4704C10.4067 28.5357 10.4902 28.5581 10.5555 28.5204Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.4129 27.7273C10.4782 27.6896 10.5006 27.6061 10.4629 27.5408C10.4252 27.4755 10.3417 27.4531 10.2764 27.4908C10.2111 27.5285 10.1888 27.612 10.2265 27.6773C10.2641 27.7426 10.3476 27.7649 10.4129 27.7273Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.84356 27.4081C9.91611 27.3662 9.94096 27.2734 9.89908 27.2009C9.8572 27.1283 9.76444 27.1035 9.6919 27.1454C9.61935 27.1872 9.5945 27.28 9.63638 27.3525C9.67826 27.4251 9.77102 27.4499 9.84356 27.4081Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.2146 26.8082C10.3089 26.7537 10.3412 26.6331 10.2868 26.5388C10.2323 26.4445 10.1117 26.4122 10.0174 26.4667C9.92313 26.5211 9.89082 26.6417 9.94526 26.736C9.99971 26.8303 10.1203 26.8626 10.2146 26.8082Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.245 26.4055C10.303 26.372 10.3229 26.2978 10.2894 26.2397C10.2559 26.1817 10.1817 26.1618 10.1236 26.1953C10.0656 26.2288 10.0457 26.303 10.0792 26.3611C10.1127 26.4191 10.1869 26.439 10.245 26.4055Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.67854 26.3648C9.74383 26.3271 9.7662 26.2436 9.72851 26.1783C9.69081 26.113 9.60733 26.0906 9.54204 26.1283C9.47675 26.166 9.45439 26.2495 9.49208 26.3148C9.52977 26.3801 9.61326 26.4025 9.67854 26.3648Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.26243 26.342C9.30591 26.3169 9.32081 26.2613 9.2957 26.2178C9.2706 26.1743 9.21499 26.1594 9.17151 26.1845C9.12802 26.2096 9.11313 26.2652 9.13823 26.3087C9.16334 26.3522 9.21894 26.3671 9.26243 26.342Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.39236 25.6888C9.47941 25.6385 9.50923 25.5272 9.45897 25.4402C9.40871 25.3531 9.2974 25.3233 9.21035 25.3735C9.1233 25.4238 9.09348 25.5351 9.14374 25.6222C9.19399 25.7092 9.3053 25.739 9.39236 25.6888Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.40803 26.2575C8.47332 26.2198 8.49569 26.1363 8.458 26.071C8.4203 26.0057 8.33682 25.9833 8.27153 26.021C8.20625 26.0587 8.18388 26.1422 8.22157 26.2075C8.25926 26.2728 8.34275 26.2952 8.40803 26.2575Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.38644 25.552C8.42993 25.5269 8.44483 25.4713 8.41972 25.4278C8.39462 25.3843 8.33902 25.3694 8.29553 25.3945C8.25205 25.4197 8.23715 25.4753 8.26225 25.5187C8.28736 25.5622 8.34296 25.5771 8.38644 25.552Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.98229 24.1574C9.03307 24.128 9.05047 24.0631 9.02115 24.0123C8.99183 23.9615 8.9269 23.9441 8.87612 23.9735C8.82534 24.0028 8.80795 24.0677 8.83726 24.1185C8.86658 24.1693 8.93151 24.1867 8.98229 24.1574Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.95484 24.4705C8.04914 24.4161 8.08145 24.2955 8.02701 24.2012C7.97256 24.1069 7.85197 24.0746 7.75767 24.129C7.66336 24.1835 7.63105 24.3041 7.6855 24.3984C7.73995 24.4927 7.86053 24.525 7.95484 24.4705Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.87968 23.9177C7.95947 23.8716 7.98681 23.7696 7.94074 23.6898C7.89467 23.61 7.79264 23.5827 7.71284 23.6287C7.63305 23.6748 7.60571 23.7768 7.65178 23.8566C7.69785 23.9364 7.79988 23.9638 7.87968 23.9177Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.71267 23.5937C6.79972 23.5435 6.82954 23.4322 6.77928 23.3451C6.72903 23.2581 6.61772 23.2282 6.53067 23.2785C6.44362 23.3288 6.41379 23.4401 6.46405 23.5271C6.51431 23.6142 6.62562 23.644 6.71267 23.5937Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.75079 22.6617C7.82333 22.6198 7.84819 22.527 7.80631 22.4545C7.76442 22.3819 7.67166 22.3571 7.59912 22.399C7.52658 22.4408 7.50173 22.5336 7.54361 22.6061C7.58549 22.6787 7.67825 22.7035 7.75079 22.6617Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.2761 22.2348C7.31958 22.2096 7.33448 22.154 7.30937 22.1106C7.28427 22.0671 7.22866 22.0522 7.18518 22.0773C7.1417 22.1024 7.1268 22.158 7.1519 22.2015C7.17701 22.245 7.23261 22.2599 7.2761 22.2348Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.72538 22.3426C6.80518 22.2966 6.83252 22.1945 6.78645 22.1147C6.74038 22.0349 6.63834 22.0076 6.55855 22.0537C6.47875 22.0997 6.45141 22.2018 6.49748 22.2816C6.54355 22.3614 6.64558 22.3887 6.72538 22.3426Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.13355 22.1064C6.22785 22.0519 6.26016 21.9314 6.20572 21.837C6.15127 21.7427 6.03068 21.7104 5.93638 21.7649C5.84207 21.8193 5.80976 21.9399 5.86421 22.0342C5.91866 22.1285 6.03924 22.1608 6.13355 22.1064Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.86704 21.7351C5.92507 21.7016 5.94495 21.6273 5.91145 21.5693C5.87794 21.5113 5.80374 21.4914 5.7457 21.5249C5.68767 21.5584 5.66779 21.6326 5.70129 21.6906C5.7348 21.7487 5.809 21.7686 5.86704 21.7351Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.58671 21.2491C5.6665 21.2031 5.69384 21.101 5.64777 21.0212C5.6017 20.9414 5.49967 20.9141 5.41987 20.9602C5.34008 21.0062 5.31274 21.1083 5.35881 21.1881C5.40488 21.2679 5.50691 21.2952 5.58671 21.2491Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.27331 20.1342C6.32409 20.1048 6.34149 20.0399 6.31217 19.9891C6.28285 19.9384 6.21792 19.921 6.16714 19.9503C6.11636 19.9796 6.09896 20.0445 6.12828 20.0953C6.1576 20.1461 6.22253 20.1635 6.27331 20.1342Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.42366 19.8715C4.48895 19.8339 4.51132 19.7504 4.47362 19.6851C4.43593 19.6198 4.35245 19.5974 4.28716 19.6351C4.22187 19.6728 4.1995 19.7563 4.2372 19.8216C4.27489 19.8869 4.35837 19.9092 4.42366 19.8715Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.45005 18.9809C5.50808 18.9474 5.52796 18.8732 5.49446 18.8152C5.46095 18.7571 5.38675 18.7372 5.32871 18.7708C5.27068 18.8043 5.25079 18.8785 5.2843 18.9365C5.31781 18.9945 5.39201 19.0144 5.45005 18.9809Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.10814 18.4783C5.2097 18.4197 5.24449 18.2898 5.18586 18.1882C5.12722 18.0867 4.99736 18.0519 4.8958 18.1105C4.79424 18.1691 4.75945 18.299 4.81808 18.4006C4.87672 18.5021 5.00658 18.5369 5.10814 18.4783Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.28206 17.7826C6.34735 17.7449 6.36972 17.6614 6.33202 17.5962C6.29433 17.5309 6.21085 17.5085 6.14556 17.5462C6.08027 17.5839 6.0579 17.6674 6.09559 17.7327C6.13329 17.7979 6.21677 17.8203 6.28206 17.7826Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.35523 17.5121C5.44953 17.4577 5.48184 17.3371 5.4274 17.2428C5.37295 17.1485 5.25236 17.1162 5.15806 17.1706C5.06375 17.2251 5.03144 17.3457 5.08589 17.44C5.14034 17.5343 5.26092 17.5666 5.35523 17.5121Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.59649 16.7774C5.66903 16.7355 5.69389 16.6427 5.65201 16.5702C5.61012 16.4977 5.51737 16.4728 5.44482 16.5147C5.37228 16.5566 5.34743 16.6493 5.38931 16.7219C5.43119 16.7944 5.52395 16.8193 5.59649 16.7774Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.24875 16.627C4.29224 16.6019 4.30714 16.5463 4.28203 16.5028C4.25693 16.4593 4.20132 16.4445 4.15784 16.4696C4.11435 16.4947 4.09945 16.5503 4.12456 16.5937C4.14966 16.6372 4.20527 16.6521 4.24875 16.627Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.10725 15.7455C4.17254 15.7078 4.19491 15.6243 4.15722 15.559C4.11952 15.4938 4.03604 15.4714 3.97075 15.5091C3.90546 15.5468 3.8831 15.6303 3.92079 15.6955C3.95848 15.7608 4.04197 15.7832 4.10725 15.7455Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.30443 15.3C5.40599 15.2414 5.44078 15.1115 5.38215 15.0099C5.32351 14.9084 5.19365 14.8736 5.09209 14.9322C4.99053 14.9909 4.95574 15.1207 5.01437 15.2223C5.07301 15.3238 5.20287 15.3586 5.30443 15.3Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.81622 14.5128C3.88876 14.4709 3.91362 14.3781 3.87173 14.3056C3.82985 14.2331 3.73709 14.2082 3.66455 14.2501C3.59201 14.292 3.56716 14.3847 3.60904 14.4573C3.65092 14.5298 3.74368 14.5547 3.81622 14.5128Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.19999 13.9936C4.27979 13.9476 4.30713 13.8455 4.26106 13.7657C4.21499 13.6859 4.11295 13.6586 4.03316 13.7047C3.95336 13.7507 3.92602 13.8528 3.97209 13.9326C4.01816 14.0124 4.12019 14.0397 4.19999 13.9936Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.60235 13.6909C4.67489 13.649 4.69975 13.5563 4.65787 13.4837C4.61599 13.4112 4.52323 13.3863 4.45068 13.4282C4.37814 13.4701 4.35329 13.5628 4.39517 13.6354C4.43705 13.7079 4.52981 13.7328 4.60235 13.6909Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.02811 13.0594C4.10791 13.0134 4.13525 12.9113 4.08918 12.8315C4.04311 12.7517 3.94108 12.7244 3.86128 12.7705C3.78148 12.8165 3.75414 12.9186 3.80021 12.9984C3.84628 13.0782 3.94832 13.1055 4.02811 13.0594Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.23522 12.5725C5.286 12.5431 5.3034 12.4782 5.27408 12.4274C5.24476 12.3766 5.17983 12.3592 5.12905 12.3886C5.07827 12.4179 5.06088 12.4828 5.09019 12.5336C5.11951 12.5844 5.18444 12.6018 5.23522 12.5725Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.68735 12.294C5.74539 12.2605 5.76527 12.1862 5.73176 12.1282C5.69826 12.0702 5.62405 12.0503 5.56602 12.0838C5.50798 12.1173 5.4881 12.1915 5.5216 12.2495C5.55511 12.3076 5.62932 12.3275 5.68735 12.294Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.6141 31.2224C17.6866 31.1805 17.7115 31.0877 17.6696 31.0152C17.6277 30.9427 17.5349 30.9178 17.4624 30.9597C17.3899 31.0016 17.365 31.0943 17.4069 31.1669C17.4488 31.2394 17.5415 31.2643 17.6141 31.2224Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.2518 30.8365C17.3171 30.7988 17.3394 30.7153 17.3017 30.65C17.2641 30.5848 17.1806 30.5624 17.1153 30.6001C17.05 30.6378 17.0276 30.7213 17.0653 30.7865C17.103 30.8518 17.1865 30.8742 17.2518 30.8365Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.0477 30.2712C17.1202 30.2293 17.1451 30.1366 17.1032 30.064C17.0613 29.9915 16.9685 29.9666 16.896 30.0085C16.8235 30.0504 16.7986 30.1432 16.8405 30.2157C16.8824 30.2882 16.9751 30.3131 17.0477 30.2712Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.5417 29.8801C16.5852 29.855 16.6001 29.7994 16.575 29.7559C16.5499 29.7125 16.4943 29.6976 16.4508 29.7227C16.4073 29.7478 16.3924 29.8034 16.4175 29.8469C16.4426 29.8903 16.4982 29.9052 16.5417 29.8801Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.4686 30.4475C15.5339 30.4098 15.5562 30.3263 15.5185 30.261C15.4809 30.1957 15.3974 30.1734 15.3321 30.211C15.2668 30.2487 15.2444 30.3322 15.2821 30.3975C15.3198 30.4628 15.4033 30.4852 15.4686 30.4475Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.3465 30.1151C15.4263 30.069 15.4536 29.967 15.4075 29.8872C15.3615 29.8074 15.2594 29.7801 15.1796 29.8261C15.0998 29.8722 15.0725 29.9742 15.1186 30.054C15.1646 30.1338 15.2667 30.1612 15.3465 30.1151Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.0867 30.0898C15.1593 30.0479 15.1841 29.9552 15.1422 29.8826C15.1004 29.8101 15.0076 29.7852 14.9351 29.8271C14.8625 29.869 14.8377 29.9618 14.8795 30.0343C14.9214 30.1068 15.0142 30.1317 15.0867 30.0898Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.3796 28.625C15.4739 28.5705 15.5063 28.45 15.4518 28.3557C15.3974 28.2614 15.2768 28.229 15.1825 28.2835C15.0882 28.3379 15.0559 28.4585 15.1103 28.5528C15.1647 28.6471 15.2853 28.6794 15.3796 28.625Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.0788 28.2556C15.1223 28.2305 15.1372 28.1749 15.1121 28.1314C15.087 28.0879 15.0314 28.073 14.9879 28.0981C14.9444 28.1232 14.9295 28.1788 14.9546 28.2223C14.9797 28.2658 15.0354 28.2807 15.0788 28.2556Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.6385 28.2821C14.711 28.2402 14.7359 28.1474 14.694 28.0749C14.6521 28.0024 14.5594 27.9775 14.4868 28.0194C14.4143 28.0613 14.3894 28.154 14.4313 28.2266C14.4732 28.2991 14.5659 28.324 14.6385 28.2821Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.658 28.5856C13.7378 28.5395 13.7651 28.4375 13.7191 28.3577C13.673 28.2779 13.571 28.2506 13.4912 28.2966C13.4114 28.3427 13.384 28.4448 13.4301 28.5246C13.4762 28.6043 13.5782 28.6317 13.658 28.5856Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.3143 28.1714C13.3651 28.1421 13.3825 28.0771 13.3532 28.0264C13.3239 27.9756 13.2589 27.9582 13.2082 27.9875C13.1574 28.0168 13.14 28.0818 13.1693 28.1325C13.1986 28.1833 13.2635 28.2007 13.3143 28.1714Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.3758 27.7328C13.4266 27.7035 13.444 27.6385 13.4147 27.5878C13.3854 27.537 13.3205 27.5196 13.2697 27.5489C13.2189 27.5782 13.2015 27.6432 13.2308 27.6939C13.2601 27.7447 13.3251 27.7621 13.3758 27.7328Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.9509 27.3301C12.9944 27.305 13.0093 27.2494 12.9842 27.206C12.9591 27.1625 12.9035 27.1476 12.86 27.1727C12.8165 27.1978 12.8016 27.2534 12.8267 27.2969C12.8518 27.3404 12.9074 27.3553 12.9509 27.3301Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.1629 27.9075C12.221 27.874 12.2409 27.7998 12.2073 27.7417C12.1738 27.6837 12.0996 27.6638 12.0416 27.6973C11.9836 27.7308 11.9637 27.805 11.9972 27.8631C12.0307 27.9211 12.1049 27.941 12.1629 27.9075Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.6492 26.8387C12.7507 26.7801 12.7855 26.6502 12.7269 26.5486C12.6682 26.4471 12.5384 26.4123 12.4368 26.4709C12.3353 26.5296 12.3005 26.6594 12.3591 26.761C12.4177 26.8625 12.5476 26.8973 12.6492 26.8387Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7137 27.5539C11.7645 27.5246 11.7819 27.4597 11.7526 27.4089C11.7233 27.3581 11.6583 27.3407 11.6076 27.37C11.5568 27.3993 11.5394 27.4643 11.5687 27.515C11.598 27.5658 11.663 27.5832 11.7137 27.5539Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7478 27.0967C11.8421 27.0422 11.8744 26.9217 11.82 26.8273C11.7655 26.733 11.6449 26.7007 11.5506 26.7552C11.4563 26.8096 11.424 26.9302 11.4785 27.0245C11.5329 27.1188 11.6535 27.1511 11.7478 27.0967Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.0095 27.4874C11.1038 27.433 11.1361 27.3124 11.0817 27.2181C11.0272 27.1238 10.9067 27.0915 10.8124 27.1459C10.7181 27.2004 10.6857 27.321 10.7402 27.4153C10.7946 27.5096 10.9152 27.5419 11.0095 27.4874Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.8124 26.6907C10.8631 26.6614 10.8805 26.5965 10.8512 26.5457C10.8219 26.4949 10.757 26.4775 10.7062 26.5069C10.6554 26.5362 10.638 26.6011 10.6673 26.6519C10.6967 26.7027 10.7616 26.7201 10.8124 26.6907Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.2537 26.2078C10.319 26.1701 10.3414 26.0866 10.3037 26.0213C10.266 25.956 10.1825 25.9337 10.1172 25.9714C10.0519 26.0091 10.0296 26.0925 10.0673 26.1578C10.105 26.2231 10.1885 26.2455 10.2537 26.2078Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.45777 26.6497C9.55207 26.5953 9.58438 26.4747 9.52993 26.3804C9.47549 26.2861 9.3549 26.2538 9.2606 26.3082C9.16629 26.3627 9.13398 26.4832 9.18843 26.5776C9.24288 26.6719 9.36346 26.7042 9.45777 26.6497Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.25177 26.5058C9.32431 26.4639 9.34916 26.3711 9.30728 26.2986C9.2654 26.226 9.17264 26.2012 9.1001 26.2431C9.02756 26.285 9.0027 26.3777 9.04458 26.4503C9.08647 26.5228 9.17922 26.5477 9.25177 26.5058Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.2352 25.7797C9.29324 25.7462 9.31312 25.672 9.27961 25.6139C9.24611 25.5559 9.1719 25.536 9.11387 25.5695C9.05583 25.603 9.03595 25.6772 9.06946 25.7353C9.10296 25.7933 9.17717 25.8132 9.2352 25.7797Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.5613 25.8542C8.64835 25.8039 8.67818 25.6926 8.62792 25.6056C8.57766 25.5185 8.46635 25.4887 8.3793 25.5389C8.29225 25.5892 8.26242 25.7005 8.31268 25.7876C8.36294 25.8746 8.47425 25.9044 8.5613 25.8542Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.72833 24.1115C8.80087 24.0696 8.82573 23.9768 8.78385 23.9043C8.74196 23.8318 8.6492 23.8069 8.57666 23.8488C8.50412 23.8907 8.47926 23.9834 8.52115 24.056C8.56303 24.1285 8.65579 24.1534 8.72833 24.1115Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.3387 24.2832C7.40399 24.2455 7.42636 24.162 7.38866 24.0967C7.35097 24.0314 7.26749 24.009 7.2022 24.0467C7.13691 24.0844 7.11454 24.1679 7.15224 24.2332C7.18993 24.2985 7.27341 24.3209 7.3387 24.2832Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.74108 23.7358C7.79186 23.7065 7.80926 23.6416 7.77994 23.5908C7.75062 23.54 7.68569 23.5226 7.63491 23.552C7.58413 23.5813 7.56673 23.6462 7.59605 23.697C7.62537 23.7478 7.6903 23.7652 7.74108 23.7358Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.45099 22.6604C8.52353 22.6185 8.54838 22.5257 8.5065 22.4532C8.46462 22.3806 8.37186 22.3558 8.29932 22.3977C8.22678 22.4396 8.20192 22.5323 8.2438 22.6049C8.28569 22.6774 8.37844 22.7023 8.45099 22.6604Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.77913 22.4345C6.84442 22.3968 6.86678 22.3134 6.82909 22.2481C6.7914 22.1828 6.70792 22.1604 6.64263 22.1981C6.57734 22.2358 6.55497 22.3193 6.59266 22.3846C6.63036 22.4499 6.71384 22.4722 6.77913 22.4345Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.33278 22.2371C6.41983 22.1868 6.44966 22.0755 6.3994 21.9884C6.34914 21.9014 6.23783 21.8716 6.15078 21.9218C6.06373 21.9721 6.03391 22.0834 6.08416 22.1704C6.13442 22.2575 6.24573 22.2873 6.33278 22.2371Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.25085 21.5489C6.30163 21.5196 6.31903 21.4546 6.28971 21.4039C6.26039 21.3531 6.19546 21.3357 6.14468 21.365C6.0939 21.3943 6.0765 21.4593 6.10582 21.51C6.13514 21.5608 6.20007 21.5782 6.25085 21.5489Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.76054 21.4288C5.84033 21.3827 5.86767 21.2807 5.8216 21.2009C5.77553 21.1211 5.6735 21.0937 5.5937 21.1398C5.51391 21.1859 5.48657 21.2879 5.53264 21.3677C5.57871 21.4475 5.68074 21.4748 5.76054 21.4288Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.66778 21.2373C5.74032 21.1955 5.76518 21.1027 5.7233 21.0302C5.68141 20.9576 5.58866 20.9328 5.51611 20.9746C5.44357 21.0165 5.41872 21.1093 5.4606 21.1818C5.50248 21.2544 5.59524 21.2792 5.66778 21.2373Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.40313 20.1479C7.47567 20.106 7.50053 20.0133 7.45865 19.9407C7.41677 19.8682 7.32401 19.8433 7.25147 19.8852C7.17892 19.9271 7.15407 20.0199 7.19595 20.0924C7.23783 20.1649 7.33059 20.1898 7.40313 20.1479Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.85523 20.2884C5.94953 20.2339 5.98184 20.1134 5.92739 20.0191C5.87295 19.9248 5.75236 19.8924 5.65806 19.9469C5.56375 20.0013 5.53144 20.1219 5.58589 20.2162C5.64034 20.3105 5.76092 20.3428 5.85523 20.2884Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.79085 19.3274C5.85614 19.2897 5.8785 19.2062 5.84081 19.1409C5.80312 19.0756 5.71963 19.0532 5.65435 19.0909C5.58906 19.1286 5.56669 19.2121 5.60438 19.2774C5.64208 19.3427 5.72556 19.365 5.79085 19.3274Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.84739 18.5764C5.89087 18.5513 5.90577 18.4957 5.88066 18.4522C5.85556 18.4087 5.79995 18.3938 5.75647 18.4189C5.71299 18.444 5.69809 18.4996 5.72319 18.5431C5.7483 18.5866 5.8039 18.6015 5.84739 18.5764Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.8279 18.273C6.91495 18.2227 6.94478 18.1114 6.89452 18.0244C6.84426 17.9373 6.73295 17.9075 6.6459 17.9578C6.55885 18.008 6.52902 18.1193 6.57928 18.2064C6.62954 18.2934 6.74085 18.3233 6.8279 18.273Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.71164 17.674C5.75513 17.6489 5.77003 17.5933 5.74492 17.5498C5.71982 17.5063 5.66421 17.4914 5.62073 17.5166C5.57724 17.5417 5.56234 17.5973 5.58745 17.6407C5.61256 17.6842 5.66816 17.6991 5.71164 17.674Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.61217 17.3822C6.66295 17.3529 6.68035 17.288 6.65103 17.2372C6.62172 17.1864 6.55679 17.169 6.50601 17.1983C6.45523 17.2276 6.43783 17.2926 6.46715 17.3433C6.49646 17.3941 6.56139 17.4115 6.61217 17.3822Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.00376 16.9986C7.06179 16.9651 7.08167 16.8909 7.04817 16.8329C7.01466 16.7748 6.94046 16.7549 6.88242 16.7885C6.82439 16.822 6.80451 16.8962 6.83801 16.9542C6.87152 17.0122 6.94572 17.0321 7.00376 16.9986Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.42952 16.4895C6.49481 16.4518 6.51718 16.3683 6.47948 16.303C6.44179 16.2377 6.35831 16.2153 6.29302 16.253C6.22773 16.2907 6.20536 16.3742 6.24306 16.4395C6.28075 16.5048 6.36423 16.5272 6.42952 16.4895Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.76743 16.0139C6.82546 15.9804 6.84535 15.9062 6.81184 15.8482C6.77834 15.7902 6.70413 15.7703 6.64609 15.8038C6.58806 15.8373 6.56818 15.9115 6.60168 15.9695C6.63519 16.0276 6.7094 16.0474 6.76743 16.0139Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.83968 15.7083C4.90496 15.6706 4.92733 15.5871 4.88964 15.5218C4.85195 15.4565 4.76846 15.4342 4.70317 15.4718C4.63789 15.5095 4.61552 15.593 4.65321 15.6583C4.69091 15.7236 4.77439 15.746 4.83968 15.7083Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.69708 15.2481C4.76962 15.2062 4.79447 15.1134 4.75259 15.0409C4.71071 14.9684 4.61795 14.9435 4.54541 14.9854C4.47287 15.0273 4.44801 15.12 4.4899 15.1926C4.53178 15.2651 4.62454 15.29 4.69708 15.2481Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.34837 14.6789C5.44993 14.6203 5.48473 14.4904 5.42609 14.3889C5.36746 14.2873 5.23759 14.2525 5.13604 14.3111C5.03448 14.3698 4.99968 14.4996 5.05832 14.6012C5.11695 14.7027 5.24681 14.7375 5.34837 14.6789Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.58183 14.264C5.66162 14.218 5.68896 14.1159 5.64289 14.0361C5.59682 13.9563 5.49479 13.929 5.41499 13.9751C5.3352 14.0211 5.30786 14.1232 5.35393 14.203C5.4 14.2828 5.50203 14.3101 5.58183 14.264Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.65699 13.7305C6.75129 13.6761 6.7836 13.5555 6.72915 13.4612C6.67471 13.3669 6.55412 13.3346 6.45982 13.389C6.36551 13.4435 6.3332 13.5641 6.38765 13.6584C6.44209 13.7527 6.56268 13.785 6.65699 13.7305Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.69021 13.484C6.77726 13.4337 6.80708 13.3224 6.75682 13.2354C6.70657 13.1483 6.59525 13.1185 6.5082 13.1688C6.42115 13.219 6.39133 13.3303 6.44159 13.4174C6.49185 13.5044 6.60316 13.5343 6.69021 13.484Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.3064 14.4515C24.4007 14.3971 24.433 14.2765 24.3786 14.1822C24.3241 14.0879 24.2035 14.0556 24.1092 14.11C24.0149 14.1645 23.9826 14.2851 24.0371 14.3794C24.0915 14.4737 24.2121 14.506 24.3064 14.4515Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.9753 13.3287C23.0696 13.2743 23.102 13.1537 23.0475 13.0594C22.9931 12.9651 22.8725 12.9328 22.7782 12.9872C22.6839 13.0417 22.6516 13.1623 22.706 13.2566C22.7605 13.3509 22.881 13.3832 22.9753 13.3287Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.8777 13.5248C22.9503 13.4829 22.9751 13.3902 22.9333 13.3176C22.8914 13.2451 22.7986 13.2202 22.7261 13.2621C22.6535 13.304 22.6287 13.3968 22.6706 13.4693C22.7124 13.5418 22.8052 13.5667 22.8777 13.5248Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.6219 13.4451C22.7017 13.399 22.729 13.297 22.6829 13.2172C22.6369 13.1374 22.5348 13.1101 22.455 13.1561C22.3752 13.2022 22.3479 13.3043 22.394 13.384C22.44 13.4638 22.5421 13.4912 22.6219 13.4451Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.8602 15.2519C22.9327 15.21 22.9576 15.1172 22.9157 15.0447C22.8738 14.9721 22.781 14.9473 22.7085 14.9892C22.636 15.0311 22.6111 15.1238 22.653 15.1964C22.6949 15.2689 22.7876 15.2938 22.8602 15.2519Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.7333 14.4837C21.7913 14.4501 21.8112 14.3759 21.7777 14.3179C21.7442 14.2599 21.67 14.24 21.6119 14.2735C21.5539 14.307 21.534 14.3812 21.5675 14.4392C21.601 14.4973 21.6752 14.5172 21.7333 14.4837Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.5848 15.0769C21.6573 15.035 21.6822 14.9422 21.6403 14.8697C21.5984 14.7972 21.5056 14.7723 21.4331 14.8142C21.3606 14.8561 21.3357 14.9488 21.3776 15.0214C21.4195 15.0939 21.5122 15.1188 21.5848 15.0769Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.9549 16.0197C22.0419 15.9694 22.0717 15.8581 22.0215 15.7711C21.9712 15.684 21.8599 15.6542 21.7729 15.7045C21.6858 15.7547 21.656 15.866 21.7062 15.9531C21.7565 16.0401 21.8678 16.07 21.9549 16.0197Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.0926 15.4666C21.1434 15.4373 21.1608 15.3724 21.1315 15.3216C21.1022 15.2708 21.0373 15.2534 20.9865 15.2827C20.9357 15.312 20.9183 15.377 20.9476 15.4278C20.9769 15.4785 21.0419 15.4959 21.0926 15.4666Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.2694 14.5579C20.3274 14.5244 20.3473 14.4502 20.3138 14.3922C20.2803 14.3342 20.2061 14.3143 20.148 14.3478C20.09 14.3813 20.0701 14.4555 20.1036 14.5135C20.1371 14.5716 20.2113 14.5914 20.2694 14.5579Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6717 14.6754C19.7298 14.6419 19.7496 14.5677 19.7161 14.5096C19.6826 14.4516 19.6084 14.4317 19.5504 14.4652C19.4924 14.4987 19.4725 14.5729 19.506 14.631C19.5395 14.689 19.6137 14.7089 19.6717 14.6754Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.3943 15.7113C19.4958 15.6527 19.5306 15.5228 19.472 15.4213C19.4134 15.3197 19.2835 15.2849 19.1819 15.3435C19.0804 15.4022 19.0456 15.532 19.1042 15.6336C19.1629 15.7352 19.2927 15.77 19.3943 15.7113Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0241 15.7674C19.0676 15.7422 19.0825 15.6866 19.0574 15.6432C19.0323 15.5997 18.9767 15.5848 18.9332 15.6099C18.8897 15.635 18.8748 15.6906 18.9 15.7341C18.9251 15.7776 18.9807 15.7925 19.0241 15.7674Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.0242 15.0664C18.0604 15.0454 18.0728 14.9991 18.0519 14.9629C18.031 14.9266 17.9847 14.9142 17.9484 14.9351C17.9122 14.9561 17.8998 15.0024 17.9207 15.0386C17.9416 15.0749 17.988 15.0873 18.0242 15.0664Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.2488 17.0031C18.3359 16.9529 18.3657 16.8415 18.3154 16.7545C18.2652 16.6674 18.1538 16.6376 18.0668 16.6879C17.9797 16.7381 17.9499 16.8494 18.0002 16.9365C18.0504 17.0235 18.1618 17.0534 18.2488 17.0031Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.3377 15.9706C17.4175 15.9246 17.4448 15.8225 17.3988 15.7427C17.3527 15.6629 17.2506 15.6356 17.1709 15.6817C17.0911 15.7277 17.0637 15.8298 17.1098 15.9096C17.1559 15.9894 17.2579 16.0167 17.3377 15.9706Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.8719 16.4675C16.93 16.434 16.9498 16.3598 16.9163 16.3017C16.8828 16.2437 16.8086 16.2238 16.7506 16.2573C16.6926 16.2908 16.6727 16.365 16.7062 16.4231C16.7397 16.4811 16.8139 16.501 16.8719 16.4675Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.2742 16.2519C16.354 16.2059 16.3813 16.1038 16.3353 16.024C16.2892 15.9442 16.1872 15.9169 16.1074 15.963C16.0276 16.009 16.0002 16.1111 16.0463 16.1909C16.0924 16.2707 16.1944 16.298 16.2742 16.2519Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.034 15.9877C16.1065 15.9458 16.1314 15.8531 16.0895 15.7805C16.0476 15.708 15.9549 15.6831 15.8823 15.725C15.8098 15.7669 15.7849 15.8596 15.8268 15.9322C15.8687 16.0047 15.9615 16.0296 16.034 15.9877Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.7381 15.9307C15.8251 15.8804 15.8549 15.7691 15.8047 15.682C15.7544 15.595 15.6431 15.5652 15.5561 15.6154C15.469 15.6657 15.4392 15.777 15.4894 15.864C15.5397 15.9511 15.651 15.9809 15.7381 15.9307Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.5935 17.2576C15.6806 17.2074 15.7104 17.0961 15.6601 17.009C15.6099 16.922 15.4986 16.8921 15.4115 16.9424C15.3245 16.9926 15.2946 17.104 15.3449 17.191C15.3952 17.2781 15.5065 17.3079 15.5935 17.2576Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.8231 15.2857C14.8739 15.2564 14.8913 15.1915 14.862 15.1407C14.8327 15.0899 14.7677 15.0725 14.7169 15.1018C14.6662 15.1311 14.6488 15.1961 14.6781 15.2469C14.7074 15.2976 14.7723 15.315 14.8231 15.2857Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.4675 16.4894C14.511 16.4643 14.5259 16.4087 14.5008 16.3652C14.4757 16.3217 14.4201 16.3068 14.3766 16.3319C14.3331 16.357 14.3182 16.4126 14.3433 16.4561C14.3684 16.4996 14.424 16.5145 14.4675 16.4894Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.1404 17.2565C14.2347 17.2021 14.267 17.0815 14.2126 16.9872C14.1581 16.8929 14.0375 16.8606 13.9432 16.915C13.8489 16.9695 13.8166 17.0901 13.871 17.1844C13.9255 17.2787 14.0461 17.311 14.1404 17.2565Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.6551 14.3185C21.7276 14.2766 21.7525 14.1838 21.7106 14.1113C21.6687 14.0387 21.576 14.0139 21.5034 14.0558C21.4309 14.0976 21.406 14.1904 21.4479 14.2629C21.4898 14.3355 21.5825 14.3603 21.6551 14.3185Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.2576 14.9336C21.3302 14.8917 21.355 14.799 21.3131 14.7264C21.2713 14.6539 21.1785 14.629 21.106 14.6709C21.0334 14.7128 21.0086 14.8056 21.0504 14.8781C21.0923 14.9507 21.1851 14.9755 21.2576 14.9336Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.408 15.4069C21.4442 15.386 21.4566 15.3396 21.4357 15.3034C21.4148 15.2672 21.3684 15.2547 21.3322 15.2757C21.296 15.2966 21.2836 15.3429 21.3045 15.3792C21.3254 15.4154 21.3717 15.4278 21.408 15.4069Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.7303 15.6583C20.7811 15.629 20.7985 15.5641 20.7692 15.5133C20.7399 15.4625 20.6749 15.4451 20.6242 15.4744C20.5734 15.5038 20.556 15.5687 20.5853 15.6195C20.6146 15.6703 20.6796 15.6876 20.7303 15.6583Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.2957 15.296C19.39 15.2415 19.4223 15.1209 19.3678 15.0266C19.3134 14.9323 19.1928 14.9 19.0985 14.9545C19.0042 15.0089 18.9719 15.1295 19.0263 15.2238C19.0808 15.3181 19.2014 15.3504 19.2957 15.296Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0858 15.5396C19.1583 15.4978 19.1832 15.405 19.1413 15.3325C19.0994 15.2599 19.0066 15.2351 18.9341 15.2769C18.8615 15.3188 18.8367 15.4116 18.8786 15.4841C18.9205 15.5567 19.0132 15.5815 19.0858 15.5396Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.8318 15.4925C17.9044 15.4506 17.9292 15.3578 17.8874 15.2853C17.8455 15.2127 17.7527 15.1879 17.6802 15.2298C17.6076 15.2717 17.5828 15.3644 17.6247 15.437C17.6665 15.5095 17.7593 15.5343 17.8318 15.4925Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.618 15.5461C17.676 15.5126 17.6959 15.4384 17.6624 15.3803C17.6289 15.3223 17.5547 15.3024 17.4967 15.3359C17.4386 15.3694 17.4188 15.4437 17.4523 15.5017C17.4858 15.5597 17.56 15.5796 17.618 15.5461Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5173 16.1298C17.6189 16.0711 17.6537 15.9413 17.595 15.8397C17.5364 15.7382 17.4065 15.7034 17.305 15.762C17.2034 15.8206 17.1686 15.9505 17.2273 16.0521C17.2859 16.1536 17.4158 16.1884 17.5173 16.1298Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.1657 15.0363C17.2092 15.0112 17.2241 14.9556 17.199 14.9121C17.1739 14.8687 17.1183 14.8538 17.0748 14.8789C17.0313 14.904 17.0164 14.9596 17.0416 15.0031C17.0667 15.0465 17.1223 15.0614 17.1657 15.0363Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.7117 15.7364C16.7843 15.6945 16.8091 15.6018 16.7672 15.5292C16.7254 15.4567 16.6326 15.4318 16.5601 15.4737C16.4875 15.5156 16.4627 15.6084 16.5045 15.6809C16.5464 15.7535 16.6392 15.7783 16.7117 15.7364Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0682 16.1079C16.1335 16.0702 16.1559 15.9868 16.1182 15.9215C16.0805 15.8562 15.997 15.8338 15.9317 15.8715C15.8664 15.9092 15.844 15.9927 15.8817 16.058C15.9194 16.1233 16.0029 16.1456 16.0682 16.1079Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.2273 15.5605C15.3144 15.5102 15.3442 15.3989 15.2939 15.3119C15.2437 15.2248 15.1324 15.195 15.0453 15.2452C14.9583 15.2955 14.9284 15.4068 14.9787 15.4939C15.029 15.5809 15.1403 15.6107 15.2273 15.5605Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.7996 15.4571C14.8358 15.4361 14.8482 15.3898 14.8273 15.3536C14.8064 15.3173 14.7601 15.3049 14.7238 15.3258C14.6876 15.3467 14.6752 15.3931 14.6961 15.4293C14.717 15.4656 14.7633 15.478 14.7996 15.4571Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.3435 15.2127C14.4378 15.1582 14.4701 15.0376 14.4157 14.9433C14.3612 14.849 14.2406 14.8167 14.1463 14.8711C14.052 14.9256 14.0197 15.0462 14.0742 15.1405C14.1286 15.2348 14.2492 15.2671 14.3435 15.2127Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.7263 13.9925C13.7698 13.9674 13.7847 13.9118 13.7596 13.8683C13.7345 13.8248 13.6789 13.8099 13.6354 13.835C13.5919 13.8601 13.577 13.9157 13.6021 13.9592C13.6272 14.0027 13.6828 14.0176 13.7263 13.9925Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.6346 13.7127C13.6854 13.6834 13.7028 13.6185 13.6735 13.5677C13.6442 13.5169 13.5792 13.4995 13.5285 13.5288C13.4777 13.5581 13.4603 13.6231 13.4896 13.6739C13.5189 13.7246 13.5839 13.742 13.6346 13.7127Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5028 14.2088C12.5608 14.1753 12.5807 14.1011 12.5472 14.0431C12.5137 13.985 12.4395 13.9652 12.3814 13.9987C12.3234 14.0322 12.3035 14.1064 12.337 14.1644C12.3705 14.2224 12.4447 14.2423 12.5028 14.2088Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.4344 13.3023C12.4997 13.2646 12.5221 13.1811 12.4844 13.1158C12.4467 13.0505 12.3632 13.0281 12.2979 13.0658C12.2326 13.1035 12.2102 13.187 12.2479 13.2523C12.2856 13.3176 12.3691 13.34 12.4344 13.3023Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.325 10.3757C20.3976 10.3338 20.4224 10.2411 20.3805 10.1685C20.3386 10.096 20.2459 10.0711 20.1733 10.113C20.1008 10.1549 20.0759 10.2476 20.1178 10.3202C20.1597 10.3927 20.2525 10.4176 20.325 10.3757Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.618 11.0999C21.6833 11.0622 21.7057 10.9787 21.668 10.9134C21.6303 10.8481 21.5468 10.8258 21.4815 10.8635C21.4162 10.9011 21.3938 10.9846 21.4315 11.0499C21.4692 11.1152 21.5527 11.1376 21.618 11.0999Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.1434 11.9177C22.1942 11.8884 22.2116 11.8235 22.1823 11.7727C22.153 11.7219 22.088 11.7045 22.0373 11.7338C21.9865 11.7632 21.9691 11.8281 21.9984 11.8789C22.0277 11.9297 22.0926 11.947 22.1434 11.9177Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.2879 12.1311C20.3749 12.0808 20.4047 11.9695 20.3545 11.8825C20.3042 11.7954 20.1929 11.7656 20.1059 11.8159C20.0188 11.8661 19.989 11.9774 20.0392 12.0645C20.0895 12.1515 20.2008 12.1814 20.2879 12.1311Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.6805 12.4472C20.7313 12.4179 20.7487 12.353 20.7194 12.3022C20.6901 12.2514 20.6252 12.234 20.5744 12.2633C20.5236 12.2926 20.5062 12.3576 20.5355 12.4084C20.5648 12.4591 20.6298 12.4765 20.6805 12.4472Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.697 13.4191C21.7841 13.3688 21.8139 13.2575 21.7637 13.1704C21.7134 13.0834 21.6021 13.0536 21.515 13.1038C21.428 13.1541 21.3982 13.2654 21.4484 13.3524C21.4987 13.4395 21.61 13.4693 21.697 13.4191Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.786 13.5066C19.844 13.4731 19.8639 13.3989 19.8304 13.3409C19.7969 13.2828 19.7227 13.2629 19.6646 13.2965C19.6066 13.33 19.5867 13.4042 19.6202 13.4622C19.6537 13.5202 19.7279 13.5401 19.786 13.5066Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.4421 13.6699C19.4856 13.6448 19.5005 13.5892 19.4754 13.5457C19.4503 13.5022 19.3947 13.4873 19.3512 13.5124C19.3077 13.5375 19.2928 13.5931 19.3179 13.6366C19.343 13.6801 19.3986 13.695 19.4421 13.6699Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.1034 14.3563C19.1614 14.3228 19.1813 14.2486 19.1478 14.1905C19.1143 14.1325 19.0401 14.1126 18.982 14.1461C18.924 14.1796 18.9041 14.2538 18.9376 14.3119C18.9711 14.3699 19.0453 14.3898 19.1034 14.3563Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.1355 15.1073C18.237 15.0487 18.2718 14.9188 18.2132 14.8173C18.1546 14.7157 18.0247 14.6809 17.9231 14.7395C17.8216 14.7982 17.7868 14.928 17.8454 15.0296C17.9041 15.1312 18.0339 15.1659 18.1355 15.1073Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.782 13.858C17.8618 13.8119 17.8892 13.7098 17.8431 13.6301C17.797 13.5503 17.695 13.5229 17.6152 13.569C17.5354 13.6151 17.5081 13.7171 17.5541 13.7969C17.6002 13.8767 17.7022 13.904 17.782 13.858Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.2517 14.5136C16.2952 14.4885 16.3101 14.4329 16.285 14.3894C16.2599 14.3459 16.2043 14.331 16.1608 14.3562C16.1173 14.3813 16.1024 14.4369 16.1275 14.4803C16.1526 14.5238 16.2082 14.5387 16.2517 14.5136Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0008 14.291C16.0733 14.2492 16.0982 14.1564 16.0563 14.0839C16.0144 14.0113 15.9217 13.9865 15.8491 14.0284C15.7766 14.0702 15.7517 14.163 15.7936 14.2355C15.8355 14.3081 15.9282 14.3329 16.0008 14.291Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0858 13.5588C16.1511 13.5211 16.1734 13.4376 16.1357 13.3723C16.098 13.307 16.0146 13.2847 15.9493 13.3224C15.884 13.3601 15.8616 13.4436 15.8993 13.5088C15.937 13.5741 16.0205 13.5965 16.0858 13.5588Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.4344 16.6138C14.4852 16.5845 14.5026 16.5195 14.4733 16.4687C14.444 16.418 14.379 16.4006 14.3283 16.4299C14.2775 16.4592 14.2601 16.5241 14.2894 16.5749C14.3187 16.6257 14.3837 16.6431 14.4344 16.6138Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.238 16.1486C13.2815 16.1235 13.2964 16.0679 13.2713 16.0244C13.2462 15.981 13.1906 15.9661 13.1471 15.9912C13.1036 16.0163 13.0887 16.0719 13.1138 16.1154C13.1389 16.1588 13.1945 16.1737 13.238 16.1486Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1697 16.9411C13.2059 16.9201 13.2183 16.8738 13.1974 16.8376C13.1765 16.8013 13.1302 16.7889 13.0939 16.8098C13.0577 16.8308 13.0453 16.8771 13.0662 16.9133C13.0871 16.9496 13.1335 16.962 13.1697 16.9411Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.9705 16.9847C11.0358 16.947 11.0582 16.8635 11.0205 16.7982C10.9828 16.733 10.8993 16.7106 10.834 16.7483C10.7687 16.786 10.7464 16.8695 10.7841 16.9347C10.8218 17 10.9052 17.0224 10.9705 16.9847Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6951 16.6887C10.7894 16.6343 10.8217 16.5137 10.7672 16.4194C10.7128 16.3251 10.5922 16.2928 10.4979 16.3472C10.4036 16.4017 10.3713 16.5222 10.4257 16.6166C10.4802 16.7109 10.6008 16.7432 10.6951 16.6887Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.4949 16.8571C10.5964 16.7984 10.6312 16.6686 10.5726 16.567C10.5139 16.4655 10.3841 16.4307 10.2825 16.4893C10.181 16.5479 10.1462 16.6778 10.2048 16.7793C10.2634 16.8809 10.3933 16.9157 10.4949 16.8571Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6082 15.8106C10.6808 15.7687 10.7056 15.6759 10.6637 15.6034C10.6218 15.5309 10.5291 15.506 10.4565 15.5479C10.384 15.5898 10.3591 15.6825 10.401 15.7551C10.4429 15.8276 10.5357 15.8525 10.6082 15.8106Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.655 16.1339C10.6985 16.1088 10.7134 16.0532 10.6883 16.0097C10.6632 15.9662 10.6076 15.9513 10.5641 15.9764C10.5206 16.0015 10.5057 16.0571 10.5308 16.1006C10.5559 16.1441 10.6115 16.159 10.655 16.1339Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.197 19.1002C19.2841 19.05 19.3139 18.9386 19.2637 18.8516C19.2134 18.7645 19.1021 18.7347 19.015 18.785C18.928 18.8352 18.8982 18.9465 18.9484 19.0336C18.9987 19.1206 19.11 19.1505 19.197 19.1002Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.2429 18.6365C20.3372 18.582 20.3695 18.4614 20.3151 18.3671C20.2606 18.2728 20.1401 18.2405 20.0458 18.295C19.9514 18.3494 19.9191 18.47 19.9736 18.5643C20.028 18.6586 20.1486 18.6909 20.2429 18.6365Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.66 19.026C20.7325 18.9841 20.7574 18.8913 20.7155 18.8188C20.6736 18.7462 20.5808 18.7214 20.5083 18.7633C20.4358 18.8052 20.4109 18.8979 20.4528 18.9705C20.4947 19.043 20.5874 19.0679 20.66 19.026Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.6883 19.1681C21.7536 19.1304 21.776 19.0469 21.7383 18.9816C21.7006 18.9164 21.6171 18.894 21.5518 18.9317C21.4865 18.9694 21.4641 19.0529 21.5018 19.1181C21.5395 19.1834 21.623 19.2058 21.6883 19.1681Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.948 19.9816C22.0278 19.9355 22.0552 19.8334 22.0091 19.7537C21.963 19.6739 21.861 19.6465 21.7812 19.6926C21.7014 19.7387 21.6741 19.8407 21.7201 19.9205C21.7662 20.0003 21.8682 20.0276 21.948 19.9816Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.1932 20.5568C21.244 20.5275 21.2614 20.4625 21.2321 20.4117C21.2028 20.361 21.1378 20.3436 21.0871 20.3729C21.0363 20.4022 21.0189 20.4671 21.0482 20.5179C21.0775 20.5687 21.1424 20.5861 21.1932 20.5568Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.864 14.0251C25.9656 13.9665 26.0004 13.8366 25.9417 13.735C25.8831 13.6335 25.7532 13.5987 25.6517 13.6573C25.5501 13.716 25.5153 13.8458 25.5739 13.9474C25.6326 14.0489 25.7624 14.0837 25.864 14.0251Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.8738 14.4401C26.9753 14.3814 27.0101 14.2516 26.9515 14.15C26.8928 14.0485 26.763 14.0137 26.6614 14.0723C26.5599 14.1309 26.5251 14.2608 26.5837 14.3624C26.6423 14.4639 26.7722 14.4987 26.8738 14.4401Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.4099 14.5518C28.5042 14.4974 28.5365 14.3768 28.4821 14.2825C28.4276 14.1882 28.307 14.1559 28.2127 14.2103C28.1184 14.2648 28.0861 14.3853 28.1406 14.4796C28.195 14.574 28.3156 14.6063 28.4099 14.5518Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.0809 20.3894C11.1389 20.3559 11.1588 20.2816 11.1253 20.2236C11.0918 20.1656 11.0176 20.1457 10.9596 20.1792C10.9015 20.2127 10.8817 20.2869 10.9152 20.3449C10.9487 20.403 11.0229 20.4229 11.0809 20.3894Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.5379 20.09C11.6104 20.0481 11.6353 19.9554 11.5934 19.8828C11.5515 19.8103 11.4588 19.7854 11.3862 19.8273C11.3137 19.8692 11.2888 19.9619 11.3307 20.0345C11.3726 20.107 11.4654 20.1319 11.5379 20.09Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.868 19.2332C10.9333 19.1955 10.9557 19.1121 10.918 19.0468C10.8803 18.9815 10.7968 18.9591 10.7315 18.9968C10.6662 19.0345 10.6438 19.118 10.6815 19.1833C10.7192 19.2486 10.8027 19.2709 10.868 19.2332Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5984 19.6526C12.6782 19.6066 12.7056 19.5045 12.6595 19.4247C12.6134 19.3449 12.5114 19.3176 12.4316 19.3637C12.3518 19.4097 12.3245 19.5118 12.3705 19.5916C12.4166 19.6714 12.5186 19.6987 12.5984 19.6526Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.2586 19.3061C12.3311 19.2642 12.356 19.1715 12.3141 19.0989C12.2722 19.0264 12.1795 19.0015 12.1069 19.0434C12.0344 19.0853 12.0095 19.1781 12.0514 19.2506C12.0933 19.3232 12.1861 19.348 12.2586 19.3061Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.8826 13.7658C19.9552 13.7239 19.98 13.6311 19.9381 13.5586C19.8963 13.4861 19.8035 13.4612 19.731 13.5031C19.6584 13.545 19.6336 13.6377 19.6754 13.7103C19.7173 13.7828 19.8101 13.8077 19.8826 13.7658Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.1775 14.5748C18.2646 14.5245 18.2944 14.4132 18.2441 14.3261C18.1939 14.2391 18.0826 14.2093 17.9955 14.2595C17.9085 14.3098 17.8786 14.4211 17.9289 14.5081C17.9792 14.5952 18.0905 14.625 18.1775 14.5748Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6775 14.6545C19.7209 14.6294 19.7358 14.5738 19.7107 14.5303C19.6856 14.4868 19.63 14.4719 19.5865 14.497C19.5431 14.5221 19.5282 14.5777 19.5533 14.6212C19.5784 14.6647 19.634 14.6796 19.6775 14.6545Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.6218 15.282C18.7234 15.2234 18.7582 15.0935 18.6995 14.9919C18.6409 14.8904 18.511 14.8556 18.4095 14.9142C18.3079 14.9729 18.2731 15.1027 18.3318 15.2043C18.3904 15.3058 18.5203 15.3406 18.6218 15.282Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.1052 15.7559C19.1923 15.7056 19.2221 15.5943 19.1719 15.5072C19.1216 15.4202 19.0103 15.3904 18.9232 15.4406C18.8362 15.4909 18.8064 15.6022 18.8566 15.6892C18.9069 15.7763 19.0182 15.8061 19.1052 15.7559Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.617 15.9152C19.6532 15.8943 19.6656 15.8479 19.6447 15.8117C19.6238 15.7754 19.5774 15.763 19.5412 15.784C19.505 15.8049 19.4925 15.8512 19.5135 15.8875C19.5344 15.9237 19.5807 15.9361 19.617 15.9152Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6316 16.4852C19.7331 16.4266 19.7679 16.2967 19.7093 16.1952C19.6507 16.0936 19.5208 16.0588 19.4192 16.1175C19.3177 16.1761 19.2829 16.306 19.3415 16.4075C19.4002 16.5091 19.53 16.5439 19.6316 16.4852Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0291 17.2008C19.0653 17.1799 19.0777 17.1336 19.0568 17.0973C19.0359 17.0611 18.9895 17.0487 18.9533 17.0696C18.9171 17.0905 18.9047 17.1369 18.9256 17.1731C18.9465 17.2093 18.9928 17.2218 19.0291 17.2008Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.9949 17.6582C19.0675 17.6163 19.0923 17.5235 19.0504 17.451C19.0086 17.3785 18.9158 17.3536 18.8433 17.3955C18.7707 17.4374 18.7459 17.5301 18.7877 17.6027C18.8296 17.6752 18.9224 17.7001 18.9949 17.6582Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.4178 17.9047C19.4831 17.867 19.5055 17.7835 19.4678 17.7182C19.4301 17.6529 19.3466 17.6306 19.2813 17.6683C19.216 17.706 19.1936 17.7894 19.2313 17.8547C19.269 17.92 19.3525 17.9424 19.4178 17.9047Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.8973 18.2236C20.977 18.1775 21.0044 18.0755 20.9583 17.9957C20.9122 17.9159 20.8102 17.8885 20.7304 17.9346C20.6506 17.9807 20.6233 18.0827 20.6694 18.1625C20.7154 18.2423 20.8175 18.2696 20.8973 18.2236Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.9959 18.8132C19.0321 18.7923 19.0445 18.7459 19.0236 18.7097C19.0027 18.6735 18.9563 18.661 18.9201 18.682C18.8839 18.7029 18.8715 18.7492 18.8924 18.7855C18.9133 18.8217 18.9596 18.8341 18.9959 18.8132Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.0281 19.3561C20.0643 19.3352 20.0767 19.2888 20.0558 19.2526C20.0349 19.2164 19.9886 19.204 19.9523 19.2249C19.9161 19.2458 19.9037 19.2921 19.9246 19.3284C19.9455 19.3646 19.9919 19.377 20.0281 19.3561Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.8738 19.8477C19.9172 19.8226 19.9321 19.767 19.907 19.7235C19.8819 19.6801 19.8263 19.6652 19.7828 19.6903C19.7394 19.7154 19.7245 19.771 19.7496 19.8145C19.7747 19.8579 19.8303 19.8728 19.8738 19.8477Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.5993 20.3738C19.6428 20.3487 19.6577 20.2931 19.6326 20.2496C19.6075 20.2061 19.5519 20.1912 19.5084 20.2163C19.4649 20.2414 19.45 20.297 19.4751 20.3405C19.5003 20.384 19.5559 20.3989 19.5993 20.3738Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6452 20.9079C19.6887 20.8828 19.7036 20.8271 19.6785 20.7837C19.6534 20.7402 19.5978 20.7253 19.5543 20.7504C19.5108 20.7755 19.4959 20.8311 19.521 20.8746C19.5462 20.9181 19.6018 20.933 19.6452 20.9079Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.1805 21.1942C19.2385 21.1607 19.2584 21.0865 19.2249 21.0284C19.1914 20.9704 19.1172 20.9505 19.0592 20.984C19.0011 21.0175 18.9813 21.0917 19.0148 21.1498C19.0483 21.2078 19.1225 21.2277 19.1805 21.1942Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.6238 21.5683C19.6672 21.5431 19.6821 21.4875 19.657 21.4441C19.6319 21.4006 19.5763 21.3857 19.5328 21.4108C19.4894 21.4359 19.4745 21.4915 19.4996 21.535C19.5247 21.5785 19.5803 21.5934 19.6238 21.5683Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.2635 21.7939C19.3216 21.7604 19.3414 21.6862 19.3079 21.6282C19.2744 21.5701 19.2002 21.5502 19.1422 21.5837C19.0842 21.6172 19.0643 21.6915 19.0978 21.7495C19.1313 21.8075 19.2055 21.8274 19.2635 21.7939Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.8339 21.1152C20.8846 21.0859 20.902 21.021 20.8727 20.9702C20.8434 20.9194 20.7785 20.902 20.7277 20.9314C20.6769 20.9607 20.6595 21.0256 20.6888 21.0764C20.7181 21.1272 20.7831 21.1446 20.8339 21.1152Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.2342 21.6892C21.2995 21.6515 21.3219 21.568 21.2842 21.5027C21.2465 21.4374 21.163 21.4151 21.0977 21.4527C21.0324 21.4904 21.01 21.5739 21.0477 21.6392C21.0854 21.7045 21.1689 21.7269 21.2342 21.6892Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.4079 22.7445C20.5095 22.6858 20.5443 22.556 20.4857 22.4544C20.427 22.3528 20.2972 22.318 20.1956 22.3767C20.094 22.4353 20.0593 22.5652 20.1179 22.6667C20.1765 22.7683 20.3064 22.8031 20.4079 22.7445Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.8641 11.503C24.9294 11.4653 24.9517 11.3818 24.9141 11.3165C24.8764 11.2512 24.7929 11.2288 24.7276 11.2665C24.6623 11.3042 24.6399 11.3877 24.6776 11.453C24.7153 11.5183 24.7988 11.5406 24.8641 11.503Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.0105 11.9087C25.112 11.8501 25.1468 11.7202 25.0882 11.6186C25.0296 11.5171 24.8997 11.4823 24.7981 11.5409C24.6966 11.5996 24.6618 11.7294 24.7204 11.831C24.7791 11.9325 24.9089 11.9673 25.0105 11.9087Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.8474 12.5118C26.8909 12.4867 26.9058 12.4311 26.8807 12.3876C26.8556 12.3441 26.8 12.3292 26.7565 12.3543C26.713 12.3794 26.6981 12.435 26.7232 12.4785C26.7483 12.522 26.8039 12.5369 26.8474 12.5118Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.033 13.8926C26.12 13.8424 26.1498 13.7311 26.0996 13.644C26.0493 13.557 25.938 13.5271 25.851 13.5774C25.7639 13.6277 25.7341 13.739 25.7844 13.826C25.8346 13.9131 25.9459 13.9429 26.033 13.8926Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.8836 14.452C25.9561 14.4101 25.981 14.3174 25.9391 14.2448C25.8972 14.1723 25.8045 14.1474 25.7319 14.1893C25.6594 14.2312 25.6345 14.3239 25.6764 14.3965C25.7183 14.469 25.8111 14.4939 25.8836 14.452Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.6072 14.4018C26.6942 14.3515 26.7241 14.2402 26.6738 14.1532C26.6236 14.0661 26.5122 14.0363 26.4252 14.0866C26.3381 14.1368 26.3083 14.2481 26.3586 14.3352C26.4088 14.4222 26.5201 14.452 26.6072 14.4018Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.1091 14.9345C26.2034 14.8801 26.2358 14.7595 26.1813 14.6652C26.1269 14.5709 26.0063 14.5386 25.912 14.593C25.8177 14.6475 25.7853 14.768 25.8398 14.8623C25.8942 14.9566 26.0148 14.989 26.1091 14.9345Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.6228 14.796C27.7243 14.7374 27.7591 14.6075 27.7005 14.506C27.6419 14.4044 27.512 14.3696 27.4105 14.4283C27.3089 14.4869 27.2741 14.6168 27.3327 14.7183C27.3914 14.8199 27.5212 14.8547 27.6228 14.796Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.7331 14.9252C27.7766 14.9001 27.7915 14.8445 27.7664 14.801C27.7413 14.7576 27.6857 14.7427 27.6422 14.7678C27.5987 14.7929 27.5838 14.8485 27.6089 14.892C27.634 14.9354 27.6896 14.9503 27.7331 14.9252Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.3259 15.7379C27.3694 15.7128 27.3843 15.6572 27.3592 15.6137C27.3341 15.5702 27.2785 15.5553 27.235 15.5804C27.1915 15.6055 27.1766 15.6611 27.2017 15.7046C27.2268 15.7481 27.2824 15.763 27.3259 15.7379Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.3338 15.4193C28.3919 15.3858 28.4118 15.3116 28.3783 15.2536C28.3447 15.1955 28.2705 15.1757 28.2125 15.2092C28.1545 15.2427 28.1346 15.3169 28.1681 15.3749C28.2016 15.433 28.2758 15.4528 28.3338 15.4193Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1482 16.0692C28.2425 16.0147 28.2748 15.8941 28.2204 15.7998C28.1659 15.7055 28.0453 15.6732 27.951 15.7277C27.8567 15.7821 27.8244 15.9027 27.8789 15.997C27.9333 16.0913 28.0539 16.1236 28.1482 16.0692Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.1873 17.3772C27.2599 17.3353 27.2847 17.2425 27.2428 17.17C27.2009 17.0974 27.1082 17.0726 27.0356 17.1145C26.9631 17.1564 26.9382 17.2491 26.9801 17.3217C27.022 17.3942 27.1148 17.4191 27.1873 17.3772Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.1003 16.9904C29.1438 16.9653 29.1587 16.9097 29.1336 16.8662C29.1085 16.8227 29.0529 16.8078 29.0094 16.8329C28.9659 16.858 28.951 16.9136 28.9761 16.9571C29.0012 17.0006 29.0568 17.0155 29.1003 16.9904Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.8904 18.5649C27.9702 18.5188 27.9976 18.4168 27.9515 18.337C27.9054 18.2572 27.8034 18.2298 27.7236 18.2759C27.6438 18.322 27.6164 18.424 27.6625 18.5038C27.7086 18.5836 27.8106 18.6109 27.8904 18.5649Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.535 14.6133C17.6147 14.5673 17.6421 14.4652 17.596 14.3854C17.5499 14.3056 17.4479 14.2783 17.3681 14.3244C17.2883 14.3704 17.261 14.4725 17.3071 14.5523C17.3531 14.6321 17.4552 14.6594 17.535 14.6133Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4549 14.7461C16.5057 14.7168 16.5231 14.6519 16.4938 14.6011C16.4645 14.5503 16.3996 14.5329 16.3488 14.5622C16.298 14.5915 16.2806 14.6565 16.3099 14.7072C16.3392 14.758 16.4042 14.7754 16.4549 14.7461Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4431 15.3312C16.5447 15.2726 16.5795 15.1427 16.5208 15.0411C16.4622 14.9396 16.3323 14.9048 16.2308 14.9634C16.1292 15.022 16.0944 15.1519 16.153 15.2535C16.2117 15.355 16.3415 15.3898 16.4431 15.3312Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.1717 15.9262C17.2442 15.8844 17.2691 15.7916 17.2272 15.7191C17.1853 15.6465 17.0926 15.6217 17.02 15.6635C16.9475 15.7054 16.9226 15.7982 16.9645 15.8707C17.0064 15.9433 17.0991 15.9681 17.1717 15.9262Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.9119 16.4786C16.9772 16.4409 16.9996 16.3574 16.9619 16.2921C16.9242 16.2268 16.8407 16.2045 16.7754 16.2422C16.7102 16.2799 16.6878 16.3634 16.7255 16.4286C16.7632 16.4939 16.8467 16.5163 16.9119 16.4786Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5877 16.8593C17.6458 16.8258 17.6657 16.7516 17.6321 16.6936C17.5986 16.6356 17.5244 16.6157 17.4664 16.6492C17.4084 16.6827 17.3885 16.7569 17.422 16.8149C17.4555 16.873 17.5297 16.8928 17.5877 16.8593Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.6433 17.2296C17.6796 17.2087 17.692 17.1624 17.6711 17.1261C17.6501 17.0899 17.6038 17.0775 17.5676 17.0984C17.5313 17.1193 17.5189 17.1657 17.5398 17.2019C17.5608 17.2381 17.6071 17.2506 17.6433 17.2296Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.1835 18.0739C18.2342 18.0445 18.2516 17.9796 18.2223 17.9288C18.193 17.8781 18.1281 17.8607 18.0773 17.89C18.0265 17.9193 18.0091 17.9842 18.0384 18.035C18.0678 18.0858 18.1327 18.1032 18.1835 18.0739Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.3963 18.4054C16.4616 18.3677 16.484 18.2842 16.4463 18.2189C16.4086 18.1536 16.3251 18.1312 16.2598 18.1689C16.1945 18.2066 16.1722 18.2901 16.2099 18.3554C16.2475 18.4207 16.331 18.4431 16.3963 18.4054Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.3719 18.8224C17.4227 18.7931 17.4401 18.7281 17.4108 18.6774C17.3815 18.6266 17.3166 18.6092 17.2658 18.6385C17.215 18.6678 17.1976 18.7328 17.2269 18.7835C17.2562 18.8343 17.3212 18.8517 17.3719 18.8224Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4177 18.9885C16.5048 18.9382 16.5346 18.8269 16.4844 18.7398C16.4341 18.6528 16.3228 18.623 16.2357 18.6732C16.1487 18.7235 16.1189 18.8348 16.1691 18.9218C16.2194 19.0089 16.3307 19.0387 16.4177 18.9885Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.3211 19.3069C16.3864 19.2692 16.4088 19.1857 16.3711 19.1204C16.3334 19.0551 16.2499 19.0327 16.1846 19.0704C16.1193 19.1081 16.097 19.1916 16.1347 19.2569C16.1724 19.3222 16.2558 19.3445 16.3211 19.3069Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.237 19.2857C17.2805 19.2606 17.2954 19.205 17.2703 19.1615C17.2452 19.118 17.1896 19.1031 17.1461 19.1282C17.1026 19.1534 17.0877 19.209 17.1128 19.2524C17.1379 19.2959 17.1936 19.3108 17.237 19.2857Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.117 19.6532C17.1968 19.6071 17.2241 19.5051 17.178 19.4253C17.132 19.3455 17.0299 19.3181 16.9501 19.3642C16.8704 19.4103 16.843 19.5123 16.8891 19.5921C16.9352 19.6719 17.0372 19.6993 17.117 19.6532Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.367 19.9643C17.454 19.9141 17.4838 19.8028 17.4336 19.7157C17.3833 19.6287 17.272 19.5989 17.185 19.6491C17.0979 19.6994 17.0681 19.8107 17.1183 19.8977C17.1686 19.9848 17.2799 20.0146 17.367 19.9643Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.2752 19.8067C17.3405 19.769 17.3629 19.6856 17.3252 19.6203C17.2875 19.555 17.204 19.5326 17.1387 19.5703C17.0734 19.608 17.0511 19.6915 17.0888 19.7568C17.1265 19.8221 17.2099 19.8444 17.2752 19.8067Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.3122 19.8735C18.3557 19.8484 18.3706 19.7928 18.3455 19.7493C18.3204 19.7058 18.2648 19.6909 18.2213 19.716C18.1778 19.7411 18.1629 19.7967 18.188 19.8402C18.2131 19.8837 18.2687 19.8986 18.3122 19.8735Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.3171 20.1859C18.4187 20.1272 18.4535 19.9974 18.3949 19.8958C18.3362 19.7942 18.2064 19.7594 18.1048 19.8181C18.0032 19.8767 17.9684 20.0066 18.0271 20.1081C18.0857 20.2097 18.2156 20.2445 18.3171 20.1859Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.74489 15.5191C6.78113 15.4982 6.79354 15.4519 6.77262 15.4156C6.7517 15.3794 6.70537 15.367 6.66913 15.3879C6.63289 15.4088 6.62048 15.4552 6.6414 15.4914C6.66232 15.5276 6.70865 15.54 6.74489 15.5191Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.04864 16.0447C7.12119 16.0028 7.14604 15.91 7.10416 15.8375C7.06227 15.7649 6.96952 15.7401 6.89697 15.782C6.82443 15.8238 6.79958 15.9166 6.84146 15.9891C6.88334 16.0617 6.9761 16.0865 7.04864 16.0447Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.7595 16.5444C6.86106 16.4858 6.89586 16.3559 6.83723 16.2544C6.77859 16.1528 6.64873 16.118 6.54717 16.1767C6.44561 16.2353 6.41081 16.3652 6.46945 16.4667C6.52808 16.5683 6.65795 16.6031 6.7595 16.5444Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.46654 16.8893C7.56809 16.8307 7.60289 16.7008 7.54425 16.5992C7.48562 16.4977 7.35576 16.4629 7.2542 16.5215C7.15264 16.5802 7.11785 16.71 7.17648 16.8116C7.23512 16.9131 7.36498 16.9479 7.46654 16.8893Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.39241 17.2471C7.4577 17.2094 7.48007 17.1259 7.44237 17.0606C7.40468 16.9953 7.3212 16.973 7.25591 17.0107C7.19062 17.0484 7.16825 17.1318 7.20595 17.1971C7.24364 17.2624 7.32712 17.2848 7.39241 17.2471Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.97546 17.8904C7.02624 17.8611 7.04363 17.7961 7.01432 17.7454C6.985 17.6946 6.92007 17.6772 6.86929 17.7065C6.81851 17.7358 6.80111 17.8007 6.83043 17.8515C6.85975 17.9023 6.92468 17.9197 6.97546 17.8904Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.22341 17.6252C8.31046 17.575 8.34028 17.4637 8.29003 17.3766C8.23977 17.2896 8.12846 17.2597 8.04141 17.31C7.95436 17.3603 7.92453 17.4716 7.97479 17.5586C8.02505 17.6457 8.13636 17.6755 8.22341 17.6252Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.03684 18.0304C8.08033 18.0053 8.09522 17.9497 8.07012 17.9062C8.04501 17.8627 7.98941 17.8478 7.94592 17.8729C7.90244 17.898 7.88754 17.9536 7.91265 17.9971C7.93775 18.0406 7.99336 18.0555 8.03684 18.0304Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.11022 18.3388C8.161 18.3094 8.1784 18.2445 8.14908 18.1937C8.11976 18.1429 8.05483 18.1255 8.00405 18.1549C7.95327 18.1842 7.93588 18.2491 7.96519 18.2999C7.99451 18.3507 8.05944 18.3681 8.11022 18.3388Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.08184 18.1105C9.15438 18.0686 9.17924 17.9759 9.13736 17.9033C9.09548 17.8308 9.00272 17.8059 8.93018 17.8478C8.85763 17.8897 8.83278 17.9825 8.87466 18.055C8.91654 18.1275 9.0093 18.1524 9.08184 18.1105Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.83874 18.6007C8.88952 18.5714 8.90691 18.5065 8.8776 18.4557C8.84828 18.4049 8.78335 18.3875 8.73257 18.4168C8.68179 18.4461 8.66439 18.5111 8.69371 18.5619C8.72303 18.6126 8.78796 18.63 8.83874 18.6007Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.11113 19.4938C8.19092 19.4477 8.21826 19.3457 8.17219 19.2659C8.12612 19.1861 8.02408 19.1587 7.94429 19.2048C7.86449 19.2509 7.83715 19.3529 7.88322 19.4327C7.92929 19.5125 8.03133 19.5398 8.11113 19.4938Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.45198 19.8404C8.51727 19.8027 8.53964 19.7192 8.50194 19.6539C8.46425 19.5886 8.38077 19.5662 8.31548 19.6039C8.25019 19.6416 8.22782 19.7251 8.26552 19.7904C8.30321 19.8557 8.38669 19.8781 8.45198 19.8404Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.51638 19.8036C9.60343 19.7534 9.63325 19.6421 9.583 19.555C9.53274 19.468 9.42143 19.4381 9.33438 19.4884C9.24733 19.5387 9.2175 19.65 9.26776 19.737C9.31802 19.8241 9.42933 19.8539 9.51638 19.8036Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.56223 20.5471C8.60571 20.522 8.62061 20.4664 8.59551 20.4229C8.5704 20.3794 8.5148 20.3645 8.47131 20.3896C8.42783 20.4147 8.41293 20.4703 8.43804 20.5138C8.46314 20.5573 8.51875 20.5722 8.56223 20.5471Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.0848 20.5429C9.15008 20.5052 9.17245 20.4218 9.13476 20.3565C9.09707 20.2912 9.01358 20.2688 8.94829 20.3065C8.883 20.3442 8.86064 20.4277 8.89833 20.493C8.93602 20.5583 9.01951 20.5806 9.0848 20.5429Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6101 20.0301C10.7044 19.9756 10.7367 19.8551 10.6823 19.7608C10.6278 19.6664 10.5072 19.6341 10.4129 19.6886C10.3186 19.743 10.2863 19.8636 10.3408 19.9579C10.3952 20.0522 10.5158 20.0845 10.6101 20.0301Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.98318 21.1277C10.0702 21.0775 10.1001 20.9662 10.0498 20.8791C9.99954 20.7921 9.88822 20.7622 9.80117 20.8125C9.71412 20.8628 9.6843 20.9741 9.73456 21.0611C9.78481 21.1482 9.89613 21.178 9.98318 21.1277Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.80748 21.3694C9.85826 21.3401 9.87566 21.2751 9.84634 21.2244C9.81703 21.1736 9.7521 21.1562 9.70132 21.1855C9.65054 21.2148 9.63314 21.2798 9.66246 21.3305C9.69178 21.3813 9.75671 21.3987 9.80748 21.3694Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.2254 21.2164C11.3124 21.1661 11.3422 21.0548 11.292 20.9677C11.2417 20.8807 11.1304 20.8509 11.0434 20.9011C10.9563 20.9514 10.9265 21.0627 10.9767 21.1497C11.027 21.2368 11.1383 21.2666 11.2254 21.2164Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.0613 21.5386C11.1047 21.5135 11.1196 21.4579 11.0945 21.4145C11.0694 21.371 11.0138 21.3561 10.9703 21.3812C10.9269 21.4063 10.912 21.4619 10.9371 21.5054C10.9622 21.5489 11.0178 21.5638 11.0613 21.5386Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7088 21.2349C11.7669 21.2014 11.7868 21.1272 11.7532 21.0692C11.7197 21.0112 11.6455 20.9913 11.5875 21.0248C11.5295 21.0583 11.5096 21.1325 11.5431 21.1905C11.5766 21.2486 11.6508 21.2685 11.7088 21.2349Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.6298 23.0664C10.6805 23.037 10.6979 22.9721 10.6686 22.9213C10.6393 22.8705 10.5744 22.8531 10.5236 22.8825C10.4728 22.9118 10.4554 22.9767 10.4847 23.0275C10.514 23.0783 10.579 23.0957 10.6298 23.0664Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.1883 22.4292C12.2753 22.3789 12.3051 22.2676 12.2549 22.1806C12.2046 22.0935 12.0933 22.0637 12.0063 22.114C11.9192 22.1642 11.8894 22.2755 11.9396 22.3626C11.9899 22.4496 12.1012 22.4794 12.1883 22.4292Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.5799 23.6208C11.6452 23.5831 11.6676 23.4996 11.6299 23.4343C11.5922 23.3691 11.5087 23.3467 11.4434 23.3844C11.3781 23.4221 11.3558 23.5056 11.3934 23.5709C11.4311 23.6361 11.5146 23.6585 11.5799 23.6208Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.4295 23.2709C12.5093 23.2248 12.5366 23.1228 12.4905 23.043C12.4445 22.9632 12.3424 22.9358 12.2626 22.9819C12.1829 23.028 12.1555 23.13 12.2016 23.2098C12.2477 23.2896 12.3497 23.3169 12.4295 23.2709Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5438 23.7125C12.6091 23.6748 12.6314 23.5913 12.5937 23.526C12.556 23.4607 12.4726 23.4384 12.4073 23.4761C12.342 23.5138 12.3196 23.5972 12.3573 23.6625C12.395 23.7278 12.4785 23.7502 12.5438 23.7125Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.3944 24.5164C12.4452 24.4871 12.4626 24.4222 12.4333 24.3714C12.4039 24.3206 12.339 24.3032 12.2882 24.3325C12.2375 24.3619 12.2201 24.4268 12.2494 24.4776C12.2787 24.5283 12.3436 24.5457 12.3944 24.5164Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.8933 23.2304C13.9368 23.2053 13.9517 23.1497 13.9266 23.1062C13.9015 23.0627 13.8459 23.0478 13.8024 23.073C13.7589 23.0981 13.744 23.1537 13.7691 23.1971C13.7942 23.2406 13.8498 23.2555 13.8933 23.2304Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1755 24.5036C13.2698 24.4492 13.3022 24.3286 13.2477 24.2343C13.1933 24.14 13.0727 24.1077 12.9784 24.1621C12.8841 24.2165 12.8518 24.3371 12.9062 24.4314C12.9607 24.5257 13.0812 24.5581 13.1755 24.5036Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.8601 24.5983C13.9399 24.5522 13.9673 24.4502 13.9212 24.3704C13.8751 24.2906 13.7731 24.2633 13.6933 24.3093C13.6135 24.3554 13.5862 24.4574 13.6322 24.5372C13.6783 24.617 13.7803 24.6444 13.8601 24.5983Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.366 24.867C14.4313 24.8293 14.4537 24.7459 14.416 24.6806C14.3783 24.6153 14.2948 24.5929 14.2295 24.6306C14.1643 24.6683 14.1419 24.7518 14.1796 24.8171C14.2173 24.8824 14.3008 24.9047 14.366 24.867Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.6874 25.1196C14.7382 25.0903 14.7555 25.0253 14.7262 24.9746C14.6969 24.9238 14.632 24.9064 14.5812 24.9357C14.5304 24.965 14.513 25.0299 14.5423 25.0807C14.5717 25.1315 14.6366 25.1489 14.6874 25.1196Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.3709 24.8475C15.4507 24.8015 15.478 24.6994 15.432 24.6196C15.3859 24.5398 15.2838 24.5125 15.2041 24.5586C15.1243 24.6046 15.0969 24.7067 15.143 24.7865C15.1891 24.8663 15.2911 24.8936 15.3709 24.8475Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.4539 26.1131C15.5265 26.0713 15.5513 25.9785 15.5094 25.906C15.4675 25.8334 15.3748 25.8086 15.3022 25.8504C15.2297 25.8923 15.2049 25.9851 15.2467 26.0576C15.2886 26.1302 15.3814 26.155 15.4539 26.1131Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.3933 25.5879C16.4804 25.5376 16.5102 25.4263 16.4599 25.3393C16.4097 25.2522 16.2984 25.2224 16.2113 25.2726C16.1243 25.3229 16.0945 25.4342 16.1447 25.5213C16.195 25.6083 16.3063 25.6381 16.3933 25.5879Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.5281 25.7905C16.6079 25.7444 16.6353 25.6424 16.5892 25.5626C16.5431 25.4828 16.4411 25.4555 16.3613 25.5015C16.2815 25.5476 16.2541 25.6496 16.3002 25.7294C16.3463 25.8092 16.4483 25.8366 16.5281 25.7905Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.1112 26.3465C17.1764 26.3088 17.1988 26.2253 17.1611 26.1601C17.1234 26.0948 17.0399 26.0724 16.9747 26.1101C16.9094 26.1478 16.887 26.2313 16.9247 26.2966C16.9624 26.3618 17.0459 26.3842 17.1112 26.3465Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5028 24.5972C17.5536 24.5679 17.571 24.5029 17.5417 24.4521C17.5123 24.4014 17.4474 24.384 17.3966 24.4133C17.3459 24.4426 17.3285 24.5075 17.3578 24.5583C17.3871 24.6091 17.452 24.6265 17.5028 24.5972Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.7068 24.8295C17.8083 24.7709 17.8431 24.641 17.7845 24.5395C17.7259 24.4379 17.596 24.4031 17.4944 24.4618C17.3929 24.5204 17.3581 24.6503 17.4167 24.7518C17.4753 24.8534 17.6052 24.8882 17.7068 24.8295Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.8777 25.1861C17.9211 25.1609 17.936 25.1053 17.9109 25.0619C17.8858 25.0184 17.8302 25.0035 17.7867 25.0286C17.7433 25.0537 17.7284 25.1093 17.7535 25.1528C17.7786 25.1963 17.8342 25.2112 17.8777 25.1861Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.1814 24.5903C18.2177 24.5694 18.2301 24.523 18.2091 24.4868C18.1882 24.4506 18.1419 24.4381 18.1057 24.4591C18.0694 24.48 18.057 24.5263 18.0779 24.5626C18.0988 24.5988 18.1452 24.6112 18.1814 24.5903Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.8162 26.3258C18.896 26.2797 18.9233 26.1777 18.8773 26.0979C18.8312 26.0181 18.7292 25.9908 18.6494 26.0368C18.5696 26.0829 18.5422 26.1849 18.5883 26.2647C18.6344 26.3445 18.7364 26.3719 18.8162 26.3258Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.157 15.6395C21.2513 15.5851 21.2836 15.4645 21.2292 15.3702C21.1747 15.2759 21.0541 15.2436 20.9598 15.298C20.8655 15.3525 20.8332 15.4731 20.8876 15.5674C20.9421 15.6617 21.0627 15.694 21.157 15.6395Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.9607 15.2968C20.0478 15.2466 20.0776 15.1352 20.0273 15.0482C19.9771 14.9611 19.8658 14.9313 19.7787 14.9816C19.6917 15.0318 19.6618 15.1431 19.7121 15.2302C19.7624 15.3172 19.8737 15.3471 19.9607 15.2968Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.3845 16.3652C20.428 16.3401 20.4429 16.2845 20.4178 16.241C20.3927 16.1975 20.3371 16.1826 20.2936 16.2077C20.2501 16.2328 20.2352 16.2884 20.2603 16.3319C20.2854 16.3754 20.341 16.3903 20.3845 16.3652Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0038 15.7619C19.0546 15.7326 19.072 15.6677 19.0426 15.6169C19.0133 15.5661 18.9484 15.5487 18.8976 15.578C18.8468 15.6073 18.8294 15.6723 18.8587 15.723C18.8881 15.7738 18.953 15.7912 19.0038 15.7619Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.3601 17.1662C18.4036 17.1411 18.4185 17.0855 18.3934 17.042C18.3683 16.9985 18.3126 16.9836 18.2692 17.0087C18.2257 17.0338 18.2108 17.0895 18.2359 17.1329C18.261 17.1764 18.3166 17.1913 18.3601 17.1662Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.8865 15.0057C16.9735 14.9555 17.0034 14.8442 16.9531 14.7571C16.9029 14.6701 16.7915 14.6402 16.7045 14.6905C16.6174 14.7408 16.5876 14.8521 16.6379 14.9391C16.6881 15.0262 16.7994 15.056 16.8865 15.0057Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.076 14.4224C16.1341 14.3889 16.1539 14.3147 16.1204 14.2566C16.0869 14.1986 16.0127 14.1787 15.9547 14.2122C15.8967 14.2457 15.8768 14.3199 15.9103 14.378C15.9438 14.436 16.018 14.4559 16.076 14.4224Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.0564 13.4542C18.1435 13.4039 18.1733 13.2926 18.123 13.2055C18.0728 13.1185 17.9615 13.0887 17.8744 13.1389C17.7874 13.1892 17.7575 13.3005 17.8078 13.3875C17.8581 13.4746 17.9694 13.5044 18.0564 13.4542Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.5622 14.1779C18.6057 14.1528 18.6206 14.0972 18.5955 14.0537C18.5704 14.0102 18.5148 13.9953 18.4713 14.0204C18.4278 14.0455 18.4129 14.1011 18.438 14.1446C18.4631 14.1881 18.5187 14.203 18.5622 14.1779Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.9227 13.6196C18.9735 13.5903 18.9909 13.5253 18.9616 13.4745C18.9323 13.4238 18.8673 13.4064 18.8166 13.4357C18.7658 13.465 18.7484 13.5299 18.7777 13.5807C18.807 13.6315 18.8719 13.6489 18.9227 13.6196Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.5906 14.1095C19.6777 14.0592 19.7075 13.9479 19.6572 13.8609C19.607 13.7738 19.4956 13.744 19.4086 13.7943C19.3215 13.8445 19.2917 13.9558 19.342 14.0429C19.3922 14.1299 19.5035 14.1597 19.5906 14.1095Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.0184 12.5142C20.0909 12.4723 20.1158 12.3796 20.0739 12.307C20.032 12.2345 19.9392 12.2096 19.8667 12.2515C19.7942 12.2934 19.7693 12.3861 19.8112 12.4587C19.8531 12.5312 19.9458 12.5561 20.0184 12.5142Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.243 13.1201C21.3011 13.0866 21.3209 13.0124 21.2874 12.9544C21.2539 12.8963 21.1797 12.8765 21.1217 12.91C21.0636 12.9435 21.0438 13.0177 21.0773 13.0757C21.1108 13.1338 21.185 13.1536 21.243 13.1201Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.7508 14.2109C21.8016 14.1816 21.819 14.1166 21.7897 14.0659C21.7604 14.0151 21.6955 13.9977 21.6447 14.027C21.5939 14.0563 21.5765 14.1212 21.6058 14.172C21.6351 14.2228 21.7001 14.2402 21.7508 14.2109Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.073 15.2855C22.1528 15.2394 22.1802 15.1374 22.1341 15.0576C22.088 14.9778 21.986 14.9505 21.9062 14.9965C21.8264 15.0426 21.7991 15.1446 21.8451 15.2244C21.8912 15.3042 21.9932 15.3316 22.073 15.2855Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.156 15.5184C22.2358 15.4723 22.2632 15.3703 22.2171 15.2905C22.171 15.2107 22.069 15.1834 21.9892 15.2294C21.9094 15.2755 21.8821 15.3776 21.9281 15.4573C21.9742 15.5371 22.0762 15.5645 22.156 15.5184Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.7654 16.1466C21.838 16.1048 21.8628 16.012 21.821 15.9395C21.7791 15.8669 21.6863 15.8421 21.6138 15.8839C21.5412 15.9258 21.5164 16.0186 21.5583 16.0911C21.6001 16.1637 21.6929 16.1885 21.7654 16.1466Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.8015 16.3878C20.845 16.3627 20.8599 16.3071 20.8348 16.2636C20.8097 16.2201 20.7541 16.2052 20.7106 16.2303C20.6671 16.2554 20.6522 16.311 20.6773 16.3545C20.7024 16.398 20.758 16.4129 20.8015 16.3878Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.4177 16.3294C20.4612 16.3043 20.4761 16.2487 20.451 16.2052C20.4259 16.1617 20.3703 16.1468 20.3268 16.1719C20.2833 16.197 20.2684 16.2526 20.2935 16.2961C20.3186 16.3396 20.3742 16.3545 20.4177 16.3294Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.9314 17.6428C20.0185 17.5926 20.0483 17.4812 19.998 17.3942C19.9478 17.3071 19.8365 17.2773 19.7494 17.3276C19.6624 17.3778 19.6325 17.4891 19.6828 17.5762C19.7331 17.6632 19.8444 17.6931 19.9314 17.6428Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.9637 16.1177C19.0218 16.0842 19.0416 16.01 19.0081 15.9519C18.9746 15.8939 18.9004 15.874 18.8424 15.9075C18.7844 15.941 18.7645 16.0152 18.798 16.0733C18.8315 16.1313 18.9057 16.1512 18.9637 16.1177Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.6004 16.3977C18.6802 16.3516 18.7075 16.2496 18.6615 16.1698C18.6154 16.09 18.5133 16.0626 18.4335 16.1087C18.3538 16.1548 18.3264 16.2568 18.3725 16.3366C18.4186 16.4164 18.5206 16.4438 18.6004 16.3977Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.3543 17.6072C17.4413 17.557 17.4711 17.4457 17.4209 17.3586C17.3706 17.2716 17.2593 17.2417 17.1723 17.292C17.0852 17.3423 17.0554 17.4536 17.1056 17.5406C17.1559 17.6277 17.2672 17.6575 17.3543 17.6072Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.826 16.5121C17.8985 16.4702 17.9234 16.3775 17.8815 16.3049C17.8396 16.2324 17.7469 16.2075 17.6743 16.2494C17.6018 16.2913 17.5769 16.3841 17.6188 16.4566C17.6607 16.5291 17.7534 16.554 17.826 16.5121Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.991 15.6461C18.0853 15.5917 18.1176 15.4711 18.0631 15.3768C18.0087 15.2825 17.8881 15.2502 17.7938 15.3046C17.6995 15.3591 17.6672 15.4796 17.7216 15.574C17.7761 15.6683 17.8967 15.7006 17.991 15.6461Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.4119 15.1564C16.4989 15.1062 16.5288 14.9949 16.4785 14.9078C16.4282 14.8208 16.3169 14.7909 16.2299 14.8412C16.1428 14.8914 16.113 15.0028 16.1633 15.0898C16.2135 15.1769 16.3248 15.2067 16.4119 15.1564Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.6873 14.5431C17.7526 14.5054 17.775 14.4219 17.7373 14.3566C17.6996 14.2914 17.6161 14.269 17.5508 14.3067C17.4855 14.3444 17.4632 14.4279 17.5009 14.4932C17.5386 14.5584 17.622 14.5808 17.6873 14.5431Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.3338 13.8711C17.37 13.8502 17.3824 13.8039 17.3615 13.7676C17.3406 13.7314 17.2942 13.719 17.258 13.7399C17.2218 13.7608 17.2093 13.8071 17.2303 13.8434C17.2512 13.8796 17.2975 13.892 17.3338 13.8711Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5428 13.0501C17.5936 13.0207 17.611 12.9558 17.5817 12.905C17.5524 12.8542 17.4875 12.8368 17.4367 12.8662C17.3859 12.8955 17.3685 12.9604 17.3978 13.0112C17.4271 13.062 17.4921 13.0794 17.5428 13.0501Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.5838 12.6059C17.6491 12.5682 17.6715 12.4847 17.6338 12.4194C17.5961 12.3541 17.5126 12.3317 17.4473 12.3694C17.382 12.4071 17.3597 12.4906 17.3974 12.5559C17.435 12.6212 17.5185 12.6436 17.5838 12.6059Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.909 14.1709C18.9671 14.1373 18.987 14.0631 18.9534 14.0051C18.9199 13.9471 18.8457 13.9272 18.7877 13.9607C18.7297 13.9942 18.7098 14.0684 18.7433 14.1264C18.7768 14.1845 18.851 14.2044 18.909 14.1709Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0329 13.083C19.1345 13.0244 19.1693 12.8945 19.1107 12.793C19.052 12.6914 18.9222 12.6566 18.8206 12.7152C18.719 12.7739 18.6843 12.9037 18.7429 13.0053C18.8015 13.1069 18.9314 13.1417 19.0329 13.083Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0603 12.2795C19.1401 12.2335 19.1675 12.1314 19.1214 12.0516C19.0753 11.9718 18.9733 11.9445 18.8935 11.9906C18.8137 12.0366 18.7864 12.1387 18.8324 12.2185C18.8785 12.2983 18.9805 12.3256 19.0603 12.2795Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.6873 13.5817C20.7599 13.5398 20.7847 13.4471 20.7428 13.3745C20.7009 13.302 20.6082 13.2771 20.5356 13.319C20.4631 13.3609 20.4382 13.4536 20.4801 13.5262C20.522 13.5987 20.6148 13.6236 20.6873 13.5817Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.2753 13.9598C20.326 13.9305 20.3434 13.8655 20.3141 13.8148C20.2848 13.764 20.2199 13.7466 20.1691 13.7759C20.1183 13.8052 20.1009 13.8701 20.1302 13.9209C20.1595 13.9717 20.2245 13.9891 20.2753 13.9598Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0701 12.4488C19.1716 12.3902 19.2064 12.2603 19.1478 12.1587C19.0891 12.0572 18.9593 12.0224 18.8577 12.081C18.7562 12.1397 18.7214 12.2695 18.78 12.3711C18.8386 12.4726 18.9685 12.5074 19.0701 12.4488Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.8475 13.1903C19.9055 13.1568 19.9254 13.0826 19.8919 13.0246C19.8584 12.9665 19.7842 12.9467 19.7262 12.9802C19.6681 13.0137 19.6483 13.0879 19.6818 13.1459C19.7153 13.2039 19.7895 13.2238 19.8475 13.1903Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.2518 12.4673C21.3026 12.438 21.32 12.373 21.2907 12.3223C21.2614 12.2715 21.1964 12.2541 21.1457 12.2834C21.0949 12.3127 21.0775 12.3777 21.1068 12.4284C21.1361 12.4792 21.201 12.4966 21.2518 12.4673Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.6434 11.9614C21.6942 11.9321 21.7116 11.8671 21.6823 11.8163C21.653 11.7656 21.588 11.7482 21.5373 11.7775C21.4865 11.8068 21.4691 11.8717 21.4984 11.9225C21.5277 11.9733 21.5926 11.9907 21.6434 11.9614Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.5731 13.0891C23.6239 13.0598 23.6413 12.9949 23.612 12.9441C23.5827 12.8933 23.5177 12.8759 23.4669 12.9052C23.4162 12.9345 23.3988 12.9995 23.4281 13.0503C23.4574 13.101 23.5223 13.1184 23.5731 13.0891Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.9939 14.0886C23.0302 14.0677 23.0426 14.0214 23.0216 13.9852C23.0007 13.9489 22.9544 13.9365 22.9182 13.9574C22.8819 13.9783 22.8695 14.0247 22.8904 14.0609C22.9113 14.0972 22.9577 14.1096 22.9939 14.0886Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.8611 13.9212C23.9337 13.8793 23.9585 13.7865 23.9167 13.714C23.8748 13.6414 23.782 13.6166 23.7095 13.6585C23.6369 13.7004 23.6121 13.7931 23.654 13.8657C23.6958 13.9382 23.7886 13.9631 23.8611 13.9212Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.8787 15.1364C23.973 15.082 24.0053 14.9614 23.9508 14.8671C23.8964 14.7728 23.7758 14.7405 23.6815 14.7949C23.5872 14.8494 23.5549 14.9699 23.6093 15.0642C23.6638 15.1586 23.7844 15.1909 23.8787 15.1364Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.1316 16.2685C23.2259 16.214 23.2582 16.0935 23.2038 15.9992C23.1493 15.9049 23.0287 15.8725 22.9344 15.927C22.8401 15.9814 22.8078 16.102 22.8623 16.1963C22.9167 16.2906 23.0373 16.3229 23.1316 16.2685Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.2937 17.1557C23.3735 17.1096 23.4009 17.0076 23.3548 16.9278C23.3087 16.848 23.2067 16.8206 23.1269 16.8667C23.0471 16.9128 23.0198 17.0148 23.0658 17.0946C23.1119 17.1744 23.2139 17.2018 23.2937 17.1557Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.1541 17.4278C22.2339 17.3818 22.2612 17.2797 22.2152 17.1999C22.1691 17.1201 22.0671 17.0928 21.9873 17.1389C21.9075 17.1849 21.8801 17.287 21.9262 17.3668C21.9723 17.4466 22.0743 17.4739 22.1541 17.4278Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.1044 18.7356C22.1551 18.7063 22.1725 18.6414 22.1432 18.5906C22.1139 18.5398 22.049 18.5224 21.9982 18.5517C21.9474 18.581 21.93 18.646 21.9593 18.6967C21.9886 18.7475 22.0536 18.7649 22.1044 18.7356Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.659 19.3602C21.7315 19.3183 21.7564 19.2256 21.7145 19.153C21.6726 19.0805 21.5799 19.0556 21.5073 19.0975C21.4348 19.1394 21.4099 19.2321 21.4518 19.3047C21.4937 19.3772 21.5865 19.4021 21.659 19.3602Z" fill={props.bgColor || defaultIconColor} />
        <path d="M21.1736 18.8526C21.2534 18.8065 21.2808 18.7045 21.2347 18.6247C21.1886 18.5449 21.0866 18.5175 21.0068 18.5636C20.927 18.6097 20.8997 18.7117 20.9457 18.7915C20.9918 18.8713 21.0938 18.8987 21.1736 18.8526Z" fill={props.bgColor || defaultIconColor} />
        <path d="M20.366 20.0019C20.4603 19.9474 20.4926 19.8269 20.4381 19.7326C20.3837 19.6383 20.2631 19.6059 20.1688 19.6604C20.0745 19.7148 20.0422 19.8354 20.0966 19.9297C20.1511 20.024 20.2717 20.0563 20.366 20.0019Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.5994 19.3413C19.6937 19.2868 19.726 19.1662 19.6715 19.0719C19.6171 18.9776 19.4965 18.9453 19.4022 18.9997C19.3079 19.0542 19.2756 19.1748 19.33 19.2691C19.3845 19.3634 19.5051 19.3957 19.5994 19.3413Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.9304 18.0642C20.0247 18.0098 20.057 17.8892 20.0026 17.7949C19.9481 17.7006 19.8276 17.6683 19.7333 17.7227C19.6389 17.7772 19.6066 17.8977 19.6611 17.992C19.7155 18.0863 19.8361 18.1187 19.9304 18.0642Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.8074 19.0625C18.88 19.0207 18.9048 18.9279 18.8629 18.8554C18.8211 18.7828 18.7283 18.758 18.6558 18.7998C18.5832 18.8417 18.5584 18.9345 18.6002 19.007C18.6421 19.0796 18.7349 19.1044 18.8074 19.0625Z" fill={props.bgColor || defaultIconColor} />
        <path d="M19.0134 18.0851C19.0497 18.0642 19.0621 18.0178 19.0412 17.9816C19.0203 17.9454 18.9739 17.933 18.9377 17.9539C18.9014 17.9748 18.889 18.0211 18.91 18.0574C18.9309 18.0936 18.9772 18.106 19.0134 18.0851Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.6072 17.0768C18.6506 17.0517 18.6655 16.9961 18.6404 16.9526C18.6153 16.9091 18.5597 16.8942 18.5162 16.9193C18.4728 16.9444 18.4579 17 18.483 17.0435C18.5081 17.087 18.5637 17.1019 18.6072 17.0768Z" fill={props.bgColor || defaultIconColor} />
        <path d="M18.2829 16.8787C18.3264 16.8536 18.3413 16.798 18.3162 16.7545C18.2911 16.7111 18.2355 16.6962 18.192 16.7213C18.1485 16.7464 18.1336 16.802 18.1587 16.8455C18.1838 16.8889 18.2394 16.9038 18.2829 16.8787Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.8495 17.2163C16.9003 17.187 16.9177 17.1221 16.8883 17.0713C16.859 17.0205 16.7941 17.0031 16.7433 17.0324C16.6925 17.0617 16.6751 17.1267 16.7044 17.1775C16.7338 17.2282 16.7987 17.2456 16.8495 17.2163Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.1853 17.0387C16.2651 16.9927 16.2925 16.8906 16.2464 16.8108C16.2003 16.731 16.0983 16.7037 16.0185 16.7498C15.9387 16.7958 15.9114 16.8979 15.9574 16.9777C16.0035 17.0575 16.1055 17.0848 16.1853 17.0387Z" fill={props.bgColor || defaultIconColor} />
        <path d="M17.0007 15.4472C17.0442 15.422 17.0591 15.3664 17.034 15.323C17.0089 15.2795 16.9533 15.2646 16.9098 15.2897C16.8663 15.3148 16.8514 15.3704 16.8765 15.4139C16.9016 15.4574 16.9572 15.4723 17.0007 15.4472Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.5769 15.8317C15.6204 15.8066 15.6353 15.751 15.6102 15.7075C15.585 15.664 15.5294 15.6491 15.486 15.6742C15.4425 15.6993 15.4276 15.7549 15.4527 15.7984C15.4778 15.8419 15.5334 15.8568 15.5769 15.8317Z" fill={props.bgColor || defaultIconColor} />
        <path d="M16.0388 14.9343C16.1331 14.8798 16.1654 14.7592 16.111 14.6649C16.0565 14.5706 15.936 14.5383 15.8417 14.5928C15.7473 14.6472 15.715 14.7678 15.7695 14.8621C15.8239 14.9564 15.9445 14.9887 16.0388 14.9343Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.3573 13.9967C15.4081 13.9674 15.4255 13.9025 15.3961 13.8517C15.3668 13.8009 15.3019 13.7835 15.2511 13.8128C15.2003 13.8421 15.1829 13.9071 15.2123 13.9578C15.2416 14.0086 15.3065 14.026 15.3573 13.9967Z" fill={props.bgColor || defaultIconColor} />
        <path d="M15.3201 13.1428C15.3999 13.0967 15.4272 12.9947 15.3812 12.9149C15.3351 12.8351 15.2331 12.8078 15.1533 12.8538C15.0735 12.8999 15.0461 13.0019 15.0922 13.0817C15.1383 13.1615 15.2403 13.1889 15.3201 13.1428Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.2674 12.8843C30.3544 12.8341 30.3842 12.7228 30.334 12.6357C30.2837 12.5487 30.1724 12.5188 30.0854 12.5691C29.9983 12.6194 29.9685 12.7307 30.0187 12.8177C30.069 12.9048 30.1803 12.9346 30.2674 12.8843Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.6521 12.7319C30.6955 12.7068 30.7104 12.6512 30.6853 12.6078C30.6602 12.5643 30.6046 12.5494 30.5612 12.5745C30.5177 12.5996 30.5028 12.6552 30.5279 12.6987C30.553 12.7421 30.6086 12.757 30.6521 12.7319Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.8113 13.7587C27.8911 13.7126 27.9184 13.6106 27.8724 13.5308C27.8263 13.451 27.7243 13.4237 27.6445 13.4697C27.5647 13.5158 27.5373 13.6178 27.5834 13.6976C27.6295 13.7774 27.7315 13.8048 27.8113 13.7587Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.1424 13.4795C27.2077 13.4418 27.2301 13.3583 27.1924 13.2931C27.1547 13.2278 27.0712 13.2054 27.0059 13.2431C26.9406 13.2808 26.9183 13.3643 26.9559 13.4296C26.9936 13.4948 27.0771 13.5172 27.1424 13.4795Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1023 12.7152C28.1385 12.6942 28.151 12.6479 28.13 12.6117C28.1091 12.5754 28.0628 12.563 28.0265 12.5839C27.9903 12.6049 27.9779 12.6512 27.9988 12.6874C28.0197 12.7237 28.0661 12.7361 28.1023 12.7152Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1629 12.2428C28.2282 12.2051 28.2506 12.1216 28.2129 12.0564C28.1752 11.9911 28.0917 11.9687 28.0264 12.0064C27.9611 12.0441 27.9388 12.1276 27.9765 12.1929C28.0141 12.2581 28.0976 12.2805 28.1629 12.2428Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1775 11.3584C28.2209 11.3333 28.2358 11.2777 28.2107 11.2342C28.1856 11.1907 28.13 11.1758 28.0865 11.2009C28.0431 11.226 28.0282 11.2816 28.0533 11.3251C28.0784 11.3686 28.134 11.3835 28.1775 11.3584Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.8884 10.4913C26.9827 10.4369 27.0151 10.3163 26.9606 10.222C26.9062 10.1277 26.7856 10.0954 26.6913 10.1498C26.597 10.2043 26.5646 10.3249 26.6191 10.4192C26.6735 10.5135 26.7941 10.5458 26.8884 10.4913Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.7322 10.0387C26.8048 9.99685 26.8296 9.90409 26.7878 9.83155C26.7459 9.759 26.6531 9.73415 26.5806 9.77603C26.508 9.81791 26.4832 9.91067 26.5251 9.98322C26.5669 10.0558 26.6597 10.0806 26.7322 10.0387Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.2146 10.269C28.3089 10.2146 28.3412 10.094 28.2868 9.99971C28.2323 9.90541 28.1117 9.8731 28.0174 9.92754C27.9231 9.98199 27.8908 10.1026 27.9453 10.1969C27.9997 10.2912 28.1203 10.3235 28.2146 10.269Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.6482 10.2996C30.6916 10.2745 30.7065 10.2189 30.6814 10.1754C30.6563 10.1319 30.6007 10.117 30.5572 10.1421C30.5138 10.1672 30.4989 10.2228 30.524 10.2663C30.5491 10.3098 30.6047 10.3247 30.6482 10.2996Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.5643 11.0307C29.6223 10.9972 29.6422 10.9229 29.6087 10.8649C29.5752 10.8069 29.501 10.787 29.443 10.8205C29.3849 10.854 29.3651 10.9282 29.3986 10.9862C29.4321 11.0443 29.5063 11.0642 29.5643 11.0307Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.5183 11.0765C31.6126 11.022 31.6449 10.9014 31.5905 10.8071C31.536 10.7128 31.4154 10.6805 31.3211 10.735C31.2268 10.7894 31.1945 10.91 31.249 11.0043C31.3034 11.0986 31.424 11.1309 31.5183 11.0765Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.4022 11.9656C30.4747 11.9237 30.4995 11.831 30.4577 11.7584C30.4158 11.6859 30.323 11.661 30.2505 11.7029C30.1779 11.7448 30.1531 11.8376 30.195 11.9101C30.2369 11.9826 30.3296 12.0075 30.4022 11.9656Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.6052 12.1992C31.6923 12.1489 31.7221 12.0376 31.6719 11.9505C31.6216 11.8635 31.5103 11.8337 31.4232 11.8839C31.3362 11.9342 31.3064 12.0455 31.3566 12.1325C31.4069 12.2196 31.5182 12.2494 31.6052 12.1992Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.2761 12.809C30.3704 12.7545 30.4027 12.6339 30.3483 12.5396C30.2938 12.4453 30.1733 12.413 30.079 12.4675C29.9847 12.5219 29.9523 12.6425 30.0068 12.7368C30.0612 12.8311 30.1818 12.8634 30.2761 12.809Z" fill={props.bgColor || defaultIconColor} />
        <path d="M32.0223 13.3769C32.0876 13.3392 32.1099 13.2557 32.0723 13.1904C32.0346 13.1252 31.9511 13.1028 31.8858 13.1405C31.8205 13.1782 31.7981 13.2617 31.8358 13.327C31.8735 13.3922 31.957 13.4146 32.0223 13.3769Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.203 13.5348C31.261 13.5013 31.2809 13.4271 31.2474 13.3691C31.2139 13.311 31.1397 13.2911 31.0816 13.3246C31.0236 13.3582 31.0037 13.4324 31.0372 13.4904C31.0707 13.5484 31.1449 13.5683 31.203 13.5348Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.4148 13.8676C30.5163 13.8089 30.5511 13.6791 30.4925 13.5775C30.4339 13.4759 30.304 13.4412 30.2024 13.4998C30.1009 13.5584 30.0661 13.6883 30.1247 13.7898C30.1834 13.8914 30.3132 13.9262 30.4148 13.8676Z" fill={props.bgColor || defaultIconColor} />
        <path d="M31.7566 14.4937C31.8437 14.4434 31.8735 14.3321 31.8232 14.245C31.773 14.158 31.6617 14.1281 31.5746 14.1784C31.4876 14.2287 31.4577 14.34 31.508 14.427C31.5583 14.5141 31.6696 14.5439 31.7566 14.4937Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.4266 15.3141C30.4919 15.2764 30.5142 15.1929 30.4766 15.1276C30.4389 15.0624 30.3554 15.04 30.2901 15.0777C30.2248 15.1154 30.2024 15.1989 30.2401 15.2641C30.2778 15.3294 30.3613 15.3518 30.4266 15.3141Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.5916 16.0245C30.6641 15.9826 30.689 15.8899 30.6471 15.8173C30.6052 15.7448 30.5125 15.7199 30.4399 15.7618C30.3674 15.8037 30.3425 15.8965 30.3844 15.969C30.4263 16.0415 30.5191 16.0664 30.5916 16.0245Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.035 16.153C30.093 16.1195 30.1129 16.0453 30.0794 15.9872C30.0459 15.9292 29.9717 15.9093 29.9137 15.9428C29.8556 15.9763 29.8358 16.0505 29.8693 16.1086C29.9028 16.1666 29.977 16.1865 30.035 16.153Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.453 16.4189C29.5038 16.3896 29.5212 16.3247 29.4919 16.2739C29.4625 16.2231 29.3976 16.2057 29.3468 16.2351C29.296 16.2644 29.2786 16.3293 29.308 16.3801C29.3373 16.4309 29.4022 16.4483 29.453 16.4189Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.1951 17.0064C30.2604 16.9687 30.2828 16.8853 30.2451 16.82C30.2074 16.7547 30.1239 16.7323 30.0586 16.77C29.9934 16.8077 29.971 16.8912 30.0087 16.9565C30.0464 17.0218 30.1299 17.0441 30.1951 17.0064Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.1492 17.2605C30.1854 17.2395 30.1978 17.1932 30.1769 17.157C30.156 17.1207 30.1097 17.1083 30.0734 17.1292C30.0372 17.1502 30.0248 17.1965 30.0457 17.2327C30.0666 17.269 30.113 17.2814 30.1492 17.2605Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.1317 18.3216C30.2042 18.2797 30.229 18.187 30.1872 18.1144C30.1453 18.0419 30.0525 18.017 29.98 18.0589C29.9074 18.1008 29.8826 18.1936 29.9245 18.2661C29.9663 18.3387 30.0591 18.3635 30.1317 18.3216Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.7341 18.7259C29.7776 18.7008 29.7925 18.6452 29.7674 18.6017C29.7423 18.5582 29.6867 18.5433 29.6432 18.5684C29.5997 18.5935 29.5848 18.6491 29.6099 18.6926C29.635 18.7361 29.6906 18.751 29.7341 18.7259Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.4333 18.6884C28.4768 18.6633 28.4917 18.6077 28.4666 18.5642C28.4415 18.5207 28.3859 18.5058 28.3424 18.5309C28.2989 18.556 28.284 18.6116 28.3091 18.6551C28.3342 18.6986 28.3898 18.7135 28.4333 18.6884Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.1199 19.3607C29.1561 19.3398 29.1685 19.2935 29.1476 19.2572C29.1267 19.221 29.0804 19.2086 29.0441 19.2295C29.0079 19.2504 28.9955 19.2968 29.0164 19.333C29.0373 19.3692 29.0837 19.3817 29.1199 19.3607Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.8006 19.8081C28.8731 19.7663 28.898 19.6735 28.8561 19.601C28.8142 19.5284 28.7215 19.5036 28.6489 19.5454C28.5764 19.5873 28.5515 19.6801 28.5934 19.7526C28.6353 19.8252 28.7281 19.85 28.8006 19.8081Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.3026 20.3758C29.3751 20.334 29.4 20.2412 29.3581 20.1686C29.3162 20.0961 29.2234 20.0712 29.1509 20.1131C29.0783 20.155 29.0535 20.2478 29.0954 20.3203C29.1373 20.3929 29.23 20.4177 29.3026 20.3758Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.2654 20.0643C28.3088 20.0392 28.3237 19.9836 28.2986 19.9402C28.2735 19.8967 28.2179 19.8818 28.1744 19.9069C28.131 19.932 28.1161 19.9876 28.1412 20.0311C28.1663 20.0746 28.2219 20.0895 28.2654 20.0643Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.4207 20.7278C28.5077 20.6776 28.5376 20.5663 28.4873 20.4792C28.437 20.3922 28.3257 20.3623 28.2387 20.4126C28.1516 20.4629 28.1218 20.5742 28.1721 20.6612C28.2223 20.7483 28.3336 20.7781 28.4207 20.7278Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.8348 15.1638C27.9146 15.1177 27.9419 15.0157 27.8958 14.9359C27.8498 14.8561 27.7477 14.8288 27.6679 14.8748C27.5881 14.9209 27.5608 15.0229 27.6069 15.1027C27.6529 15.1825 27.755 15.2099 27.8348 15.1638Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.0515 13.3218C27.1458 13.2674 27.1781 13.1468 27.1237 13.0525C27.0692 12.9582 26.9487 12.9259 26.8543 12.9803C26.76 13.0348 26.7277 13.1554 26.7822 13.2497C26.8366 13.344 26.9572 13.3763 27.0515 13.3218Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.7039 14.0834C26.7692 14.0457 26.7916 13.9622 26.7539 13.8969C26.7162 13.8316 26.6327 13.8093 26.5674 13.847C26.5021 13.8847 26.4798 13.9681 26.5175 14.0334C26.5552 14.0987 26.6386 14.1211 26.7039 14.0834Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.5975 15.2652C26.6483 15.2359 26.6657 15.171 26.6364 15.1202C26.6071 15.0694 26.5421 15.052 26.4914 15.0813C26.4406 15.1106 26.4232 15.1756 26.4525 15.2263C26.4818 15.2771 26.5467 15.2945 26.5975 15.2652Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.2634 15.0203C26.3577 14.9659 26.39 14.8453 26.3356 14.751C26.2812 14.6567 26.1606 14.6244 26.0663 14.6788C25.972 14.7333 25.9396 14.8539 25.9941 14.9482C26.0485 15.0425 26.1691 15.0748 26.2634 15.0203Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.8309 14.9376C25.9106 14.8915 25.938 14.7895 25.8919 14.7097C25.8458 14.6299 25.7438 14.6026 25.664 14.6486C25.5842 14.6947 25.5569 14.7967 25.6029 14.8765C25.649 14.9563 25.7511 14.9837 25.8309 14.9376Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.2703 15.4537C25.3646 15.3992 25.3969 15.2786 25.3424 15.1843C25.288 15.09 25.1674 15.0577 25.0731 15.1122C24.9788 15.1666 24.9465 15.2872 25.0009 15.3815C25.0554 15.4758 25.176 15.5081 25.2703 15.4537Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.742 13.7798C24.829 13.7295 24.8588 13.6182 24.8086 13.5312C24.7583 13.4441 24.647 13.4143 24.56 13.4645C24.4729 13.5148 24.4431 13.6261 24.4933 13.7132C24.5436 13.8002 24.6549 13.83 24.742 13.7798Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.0945 13.7507C24.1815 13.7004 24.2114 13.5891 24.1611 13.502C24.1109 13.415 23.9995 13.3852 23.9125 13.4354C23.8254 13.4857 23.7956 13.597 23.8459 13.684C23.8961 13.7711 24.0075 13.8009 24.0945 13.7507Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.8446 14.2278C23.8954 14.1985 23.9128 14.1335 23.8834 14.0828C23.8541 14.032 23.7892 14.0146 23.7384 14.0439C23.6876 14.0732 23.6703 14.1381 23.6996 14.1889C23.7289 14.2397 23.7938 14.2571 23.8446 14.2278Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.5253 15.4633C23.576 15.434 23.5934 15.3691 23.5641 15.3183C23.5348 15.2675 23.4699 15.2501 23.4191 15.2794C23.3683 15.3088 23.3509 15.3737 23.3802 15.4245C23.4096 15.4752 23.4745 15.4926 23.5253 15.4633Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.9266 13.7597C22.9991 13.7179 23.024 13.6251 22.9821 13.5526C22.9402 13.48 22.8474 13.4552 22.7749 13.497C22.7024 13.5389 22.6775 13.6317 22.7194 13.7042C22.7613 13.7768 22.854 13.8016 22.9266 13.7597Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.4255 15.048C23.5271 14.9894 23.5619 14.8596 23.5032 14.758C23.4446 14.6564 23.3147 14.6216 23.2132 14.6803C23.1116 14.7389 23.0768 14.8688 23.1355 14.9703C23.1941 15.0719 23.324 15.1067 23.4255 15.048Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.3865 11.8828C22.488 11.8242 22.5228 11.6943 22.4642 11.5928C22.4055 11.4912 22.2757 11.4564 22.1741 11.515C22.0726 11.5737 22.0378 11.7035 22.0964 11.8051C22.155 11.9067 22.2849 11.9415 22.3865 11.8828Z" fill={props.bgColor || defaultIconColor} />
        <path d="M22.537 11.5679C22.5878 11.5386 22.6052 11.4737 22.5758 11.4229C22.5465 11.3721 22.4816 11.3547 22.4308 11.3841C22.38 11.4134 22.3626 11.4783 22.392 11.5291C22.4213 11.5799 22.4862 11.5973 22.537 11.5679Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.0897 11.9492C23.1477 11.9157 23.1676 11.8415 23.1341 11.7835C23.1006 11.7255 23.0264 11.7056 22.9684 11.7391C22.9103 11.7726 22.8904 11.8468 22.9239 11.9048C22.9575 11.9629 23.0317 11.9827 23.0897 11.9492Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.4286 11.596C23.4866 11.5625 23.5065 11.4883 23.473 11.4302C23.4395 11.3722 23.3653 11.3523 23.3072 11.3858C23.2492 11.4193 23.2293 11.4935 23.2628 11.5516C23.2963 11.6096 23.3705 11.6295 23.4286 11.596Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.8845 11.2627C23.9208 11.2417 23.9332 11.1954 23.9123 11.1592C23.8913 11.1229 23.845 11.1105 23.8088 11.1314C23.7725 11.1524 23.7601 11.1987 23.781 11.2349C23.802 11.2712 23.8483 11.2836 23.8845 11.2627Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.3065 12.7528C24.3573 12.7234 24.3747 12.6585 24.3454 12.6077C24.3161 12.5569 24.2511 12.5395 24.2003 12.5689C24.1496 12.5982 24.1322 12.6631 24.1615 12.7139C24.1908 12.7647 24.2557 12.7821 24.3065 12.7528Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.5671 13.3553C24.6106 13.3302 24.6255 13.2746 24.6004 13.2311C24.5753 13.1876 24.5197 13.1727 24.4762 13.1978C24.4327 13.2229 24.4178 13.2785 24.4429 13.322C24.468 13.3655 24.5236 13.3804 24.5671 13.3553Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.8181 11.7571C24.8544 11.7362 24.8668 11.6898 24.8459 11.6536C24.8249 11.6174 24.7786 11.605 24.7424 11.6259C24.7061 11.6468 24.6937 11.6931 24.7146 11.7294C24.7356 11.7656 24.7819 11.778 24.8181 11.7571Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.9793 11.7338C25.0155 11.7129 25.0279 11.6665 25.007 11.6303C24.9861 11.5941 24.9397 11.5816 24.9035 11.6026C24.8673 11.6235 24.8549 11.6698 24.8758 11.7061C24.8967 11.7423 24.943 11.7547 24.9793 11.7338Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.7996 12.9418C25.8939 12.8873 25.9262 12.7667 25.8717 12.6724C25.8173 12.5781 25.6967 12.5458 25.6024 12.6003C25.5081 12.6547 25.4758 12.7753 25.5302 12.8696C25.5847 12.9639 25.7053 12.9962 25.7996 12.9418Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.3884 13.0218C26.4247 13.0009 26.4371 12.9546 26.4162 12.9183C26.3953 12.8821 26.3489 12.8697 26.3127 12.8906C26.2764 12.9115 26.264 12.9578 26.285 12.9941C26.3059 13.0303 26.3522 13.0427 26.3884 13.0218Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.6619 12.0405C26.7272 12.0028 26.7496 11.9193 26.7119 11.854C26.6742 11.7887 26.5907 11.7664 26.5254 11.8041C26.4602 11.8418 26.4378 11.9252 26.4755 11.9905C26.5132 12.0558 26.5967 12.0782 26.6619 12.0405Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.2225 11.9797C27.295 11.9378 27.3199 11.8451 27.278 11.7725C27.2361 11.7 27.1433 11.6751 27.0708 11.717C26.9983 11.7589 26.9734 11.8517 27.0153 11.9242C27.0572 11.9967 27.1499 12.0216 27.2225 11.9797Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.406 12.4173C27.4931 12.367 27.5229 12.2557 27.4726 12.1687C27.4224 12.0816 27.3111 12.0518 27.224 12.1021C27.137 12.1523 27.1071 12.2636 27.1574 12.3507C27.2077 12.4377 27.319 12.4675 27.406 12.4173Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.5496 11.8786C27.6222 11.8368 27.647 11.744 27.6051 11.6715C27.5632 11.5989 27.4705 11.5741 27.3979 11.6159C27.3254 11.6578 27.3006 11.7506 27.3424 11.8231C27.3843 11.8957 27.4771 11.9205 27.5496 11.8786Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.9754 11.9481C28.0552 11.902 28.0825 11.8 28.0365 11.7202C27.9904 11.6404 27.8883 11.6131 27.8086 11.6591C27.7288 11.7052 27.7014 11.8072 27.7475 11.887C27.7936 11.9668 27.8956 11.9942 27.9754 11.9481Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.8319 15.9558C30.8827 15.9264 30.9001 15.8615 30.8708 15.8107C30.8414 15.76 30.7765 15.7426 30.7257 15.7719C30.675 15.8012 30.6576 15.8661 30.6869 15.9169C30.7162 15.9677 30.7811 15.9851 30.8319 15.9558Z" fill={props.bgColor || defaultIconColor} />
        <path d="M30.2586 15.1137C30.3311 15.0718 30.356 14.979 30.3141 14.9065C30.2722 14.834 30.1795 14.8091 30.1069 14.851C30.0344 14.8929 30.0095 14.9856 30.0514 15.0582C30.0933 15.1307 30.1861 15.1556 30.2586 15.1137Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.1844 16.6799C29.2569 16.638 29.2818 16.5453 29.2399 16.4727C29.198 16.4002 29.1053 16.3753 29.0327 16.4172C28.9602 16.4591 28.9353 16.5518 28.9772 16.6244C29.0191 16.6969 29.1118 16.7218 29.1844 16.6799Z" fill={props.bgColor || defaultIconColor} />
        <path d="M29.0692 15.9053C29.12 15.876 29.1374 15.811 29.1081 15.7603C29.0787 15.7095 29.0138 15.6921 28.963 15.7214C28.9123 15.7507 28.8949 15.8156 28.9242 15.8664C28.9535 15.9172 29.0184 15.9346 29.0692 15.9053Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.5808 16.6072C28.6171 16.5863 28.6295 16.5399 28.6086 16.5037C28.5876 16.4675 28.5413 16.4551 28.5051 16.476C28.4688 16.4969 28.4564 16.5432 28.4773 16.5795C28.4983 16.6157 28.5446 16.6281 28.5808 16.6072Z" fill={props.bgColor || defaultIconColor} />
        <path d="M28.1298 15.7647C28.1805 15.7353 28.1979 15.6704 28.1686 15.6196C28.1393 15.5688 28.0744 15.5514 28.0236 15.5808C27.9728 15.6101 27.9554 15.675 27.9847 15.7258C28.014 15.7766 28.079 15.794 28.1298 15.7647Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.7566 16.3306C27.8292 16.2887 27.854 16.196 27.8122 16.1234C27.7703 16.0509 27.6775 16.026 27.605 16.0679C27.5324 16.1098 27.5076 16.2025 27.5495 16.2751C27.5914 16.3476 27.6841 16.3725 27.7566 16.3306Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.1492 16.734C27.2507 16.6754 27.2855 16.5455 27.2269 16.444C27.1682 16.3424 27.0384 16.3076 26.9368 16.3662C26.8353 16.4249 26.8005 16.5547 26.8591 16.6563C26.9177 16.7579 27.0476 16.7927 27.1492 16.734Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.949 14.6599C27.0216 14.618 27.0464 14.5252 27.0045 14.4527C26.9627 14.3802 26.8699 14.3553 26.7974 14.3972C26.7248 14.4391 26.7 14.5318 26.7418 14.6044C26.7837 14.6769 26.8765 14.7018 26.949 14.6599Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.5369 15.704C26.6312 15.6495 26.6635 15.529 26.609 15.4346C26.5546 15.3403 26.434 15.308 26.3397 15.3625C26.2454 15.4169 26.2131 15.5375 26.2675 15.6318C26.322 15.7261 26.4426 15.7584 26.5369 15.704Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.7361 16.2888C25.8232 16.2386 25.853 16.1272 25.8027 16.0402C25.7525 15.9531 25.6412 15.9233 25.5541 15.9736C25.4671 16.0238 25.4372 16.1351 25.4875 16.2222C25.5377 16.3092 25.6491 16.3391 25.7361 16.2888Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.7741 15.2332C25.8176 15.2081 25.8325 15.1525 25.8074 15.109C25.7823 15.0655 25.7267 15.0506 25.6832 15.0758C25.6397 15.1009 25.6248 15.1565 25.6499 15.1999C25.6751 15.2434 25.7307 15.2583 25.7741 15.2332Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.6267 14.1277C25.6702 14.1026 25.6851 14.047 25.66 14.0035C25.6349 13.96 25.5793 13.9451 25.5358 13.9702C25.4923 13.9953 25.4774 14.0509 25.5025 14.0944C25.5276 14.1379 25.5832 14.1528 25.6267 14.1277Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.7303 15.1533C24.8101 15.1072 24.8374 15.0052 24.7913 14.9254C24.7453 14.8456 24.6432 14.8183 24.5634 14.8643C24.4836 14.9104 24.4563 15.0124 24.5024 15.0922C24.5484 15.172 24.6505 15.1994 24.7303 15.1533Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.9148 15.6234C23.951 15.6025 23.9635 15.5562 23.9425 15.5199C23.9216 15.4837 23.8753 15.4713 23.8391 15.4922C23.8028 15.5131 23.7904 15.5595 23.8113 15.5957C23.8322 15.6319 23.8786 15.6443 23.9148 15.6234Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.114 14.633C24.2011 14.5828 24.2309 14.4715 24.1806 14.3844C24.1304 14.2974 24.0191 14.2676 23.932 14.3178C23.845 14.3681 23.8152 14.4794 23.8654 14.5664C23.9157 14.6535 24.027 14.6833 24.114 14.633Z" fill={props.bgColor || defaultIconColor} />
        <path d="M23.7235 13.3179C23.7743 13.2886 23.7917 13.2237 23.7624 13.1729C23.733 13.1221 23.6681 13.1047 23.6173 13.134C23.5666 13.1634 23.5492 13.2283 23.5785 13.2791C23.6078 13.3299 23.6727 13.3473 23.7235 13.3179Z" fill={props.bgColor || defaultIconColor} />
        <path d="M24.9245 12.8516C24.968 12.8265 24.9829 12.7709 24.9578 12.7274C24.9327 12.6839 24.8771 12.669 24.8336 12.6941C24.7901 12.7192 24.7752 12.7748 24.8003 12.8183C24.8254 12.8618 24.881 12.8767 24.9245 12.8516Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.0818 11.8502C25.1833 11.7915 25.2181 11.6617 25.1595 11.5601C25.1008 11.4586 24.971 11.4238 24.8694 11.4824C24.7679 11.541 24.7331 11.6709 24.7917 11.7725C24.8503 11.874 24.9802 11.9088 25.0818 11.8502Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.8553 12.6125C25.9351 12.5664 25.9624 12.4644 25.9163 12.3846C25.8703 12.3048 25.7682 12.2774 25.6884 12.3235C25.6086 12.3696 25.5813 12.4716 25.6274 12.5514C25.6734 12.6312 25.7755 12.6585 25.8553 12.6125Z" fill={props.bgColor || defaultIconColor} />
        <path d="M25.9197 11.664C25.9923 11.6222 26.0171 11.5294 25.9752 11.4569C25.9334 11.3843 25.8406 11.3595 25.7681 11.4013C25.6955 11.4432 25.6707 11.536 25.7126 11.6085C25.7544 11.6811 25.8472 11.7059 25.9197 11.664Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.2771 11.4931C26.3642 11.4428 26.394 11.3315 26.3437 11.2445C26.2935 11.1574 26.1822 11.1276 26.0951 11.1779C26.0081 11.2281 25.9782 11.3394 26.0285 11.4265C26.0788 11.5135 26.1901 11.5434 26.2771 11.4931Z" fill={props.bgColor || defaultIconColor} />
        <path d="M26.8113 10.9921C26.8475 10.9712 26.8599 10.9248 26.839 10.8886C26.8181 10.8524 26.7718 10.8399 26.7355 10.8609C26.6993 10.8818 26.6869 10.9281 26.7078 10.9644C26.7287 11.0006 26.7751 11.013 26.8113 10.9921Z" fill={props.bgColor || defaultIconColor} />
        <path d="M27.6169 11.6301C27.7185 11.5714 27.7533 11.4416 27.6946 11.34C27.636 11.2385 27.5061 11.2037 27.4046 11.2623C27.303 11.3209 27.2682 11.4508 27.3269 11.5524C27.3855 11.6539 27.5154 11.6887 27.6169 11.6301Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.27233 15.4956C8.32311 15.4663 8.34051 15.4014 8.31119 15.3506C8.28187 15.2998 8.21694 15.2824 8.16616 15.3117C8.11538 15.341 8.09799 15.406 8.1273 15.4568C8.15662 15.5075 8.22155 15.5249 8.27233 15.4956Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.92655 15.7485C9.00635 15.7025 9.03369 15.6004 8.98761 15.5206C8.94154 15.4408 8.83951 15.4135 8.75972 15.4596C8.67992 15.5056 8.65258 15.6077 8.69865 15.6875C8.74472 15.7672 8.84676 15.7946 8.92655 15.7485Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.2205 16.5587C8.30029 16.5126 8.32763 16.4106 8.28156 16.3308C8.23549 16.251 8.13346 16.2237 8.05366 16.2697C7.97387 16.3158 7.94653 16.4178 7.9926 16.4976C8.03867 16.5774 8.1407 16.6048 8.2205 16.5587Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.56329 16.7283C8.63583 16.6864 8.66069 16.5937 8.6188 16.5211C8.57692 16.4486 8.48416 16.4237 8.41162 16.4656C8.33908 16.5075 8.31423 16.6002 8.35611 16.6728C8.39799 16.7453 8.49075 16.7702 8.56329 16.7283Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.89724 17.3063C8.98429 17.256 9.01411 17.1447 8.96386 17.0576C8.9136 16.9706 8.80229 16.9408 8.71524 16.991C8.62819 17.0413 8.59836 17.1526 8.64862 17.2397C8.69888 17.3267 8.81019 17.3565 8.89724 17.3063Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.81226 18.1257C7.90656 18.0713 7.93887 17.9507 7.88443 17.8564C7.82998 17.7621 7.70939 17.7298 7.61509 17.7842C7.52078 17.8387 7.48847 17.9593 7.54292 18.0536C7.59737 18.1479 7.71795 18.1802 7.81226 18.1257Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.47827 18.2487C8.57258 18.1943 8.60489 18.0737 8.55044 17.9794C8.49599 17.8851 8.37541 17.8528 8.2811 17.9072C8.1868 17.9617 8.15449 18.0822 8.20894 18.1766C8.26338 18.2709 8.38397 18.3032 8.47827 18.2487Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.992 18.74C8.06454 18.6981 8.0894 18.6054 8.04752 18.5328C8.00563 18.4603 7.91287 18.4354 7.84033 18.4773C7.76779 18.5192 7.74294 18.612 7.78482 18.6845C7.8267 18.7571 7.91946 18.7819 7.992 18.74Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.29477 18.6007C9.3528 18.5672 9.37269 18.493 9.33918 18.435C9.30568 18.3769 9.23147 18.3571 9.17344 18.3906C9.1154 18.4241 9.09552 18.4983 9.12903 18.5563C9.16253 18.6143 9.23674 18.6342 9.29477 18.6007Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.99788 19.0001C10.0632 18.9625 10.0855 18.879 10.0478 18.8137C10.0101 18.7484 9.92666 18.726 9.86138 18.7637C9.79609 18.8014 9.77372 18.8849 9.81141 18.9502C9.84911 19.0155 9.93259 19.0378 9.99788 19.0001Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.59751 20.1238C8.65554 20.0903 8.67542 20.0161 8.64192 19.958C8.60841 19.9 8.53421 19.8801 8.47617 19.9136C8.41814 19.9471 8.39826 20.0213 8.43176 20.0794C8.46527 20.1374 8.53947 20.1573 8.59751 20.1238Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.57409 20.2079C9.62487 20.1786 9.64227 20.1136 9.61295 20.0629C9.58363 20.0121 9.5187 19.9947 9.46792 20.024C9.41714 20.0533 9.39974 20.1183 9.42906 20.169C9.45838 20.2198 9.52331 20.2372 9.57409 20.2079Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.61996 20.409C9.678 20.3755 9.69788 20.3013 9.66438 20.2433C9.63087 20.1852 9.55667 20.1653 9.49863 20.1989C9.4406 20.2324 9.42072 20.3066 9.45422 20.3646C9.48773 20.4226 9.56193 20.4425 9.61996 20.409Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.50464 21.0886C9.59895 21.0342 9.63126 20.9136 9.57681 20.8193C9.52236 20.725 9.40178 20.6927 9.30747 20.7471C9.21317 20.8016 9.18086 20.9222 9.2353 21.0165C9.28975 21.1108 9.41034 21.1431 9.50464 21.0886Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.3074 20.9928C10.3436 20.9718 10.356 20.9255 10.3351 20.8893C10.3142 20.853 10.2679 20.8406 10.2316 20.8615C10.1954 20.8825 10.183 20.9288 10.2039 20.965C10.2248 21.0013 10.2712 21.0137 10.3074 20.9928Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.3045 21.958C10.3698 21.9203 10.3922 21.8368 10.3545 21.7715C10.3168 21.7062 10.2333 21.6839 10.168 21.7215C10.1027 21.7592 10.0804 21.8427 10.1181 21.908C10.1557 21.9733 10.2392 21.9957 10.3045 21.958Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.0858 22.0673C11.1366 22.038 11.154 21.9731 11.1247 21.9223C11.0953 21.8715 11.0304 21.8541 10.9796 21.8834C10.9289 21.9128 10.9115 21.9777 10.9408 22.0285C10.9701 22.0792 11.035 22.0966 11.0858 22.0673Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.9968 21.8563C12.0403 21.8312 12.0552 21.7756 12.0301 21.7321C12.005 21.6886 11.9494 21.6737 11.9059 21.6988C11.8624 21.7239 11.8475 21.7795 11.8726 21.823C11.8977 21.8665 11.9533 21.8814 11.9968 21.8563Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.5204 21.8521C12.5784 21.8186 12.5983 21.7444 12.5648 21.6864C12.5313 21.6283 12.4571 21.6085 12.399 21.642C12.341 21.6755 12.3211 21.7497 12.3546 21.8077C12.3881 21.8657 12.4623 21.8856 12.5204 21.8521Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.745 22.2124C12.803 22.1789 12.8229 22.1046 12.7894 22.0466C12.7559 21.9886 12.6817 21.9687 12.6236 22.0022C12.5656 22.0357 12.5457 22.1099 12.5792 22.1679C12.6127 22.226 12.6869 22.2459 12.745 22.2124Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.655 23.5076C11.6985 23.4825 11.7134 23.4269 11.6883 23.3834C11.6632 23.3399 11.6076 23.325 11.5641 23.3501C11.5206 23.3752 11.5057 23.4308 11.5308 23.4743C11.5559 23.5178 11.6115 23.5327 11.655 23.5076Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.7781 24.207C11.8579 24.1609 11.8853 24.0588 11.8392 23.9791C11.7931 23.8993 11.6911 23.8719 11.6113 23.918C11.5315 23.9641 11.5041 24.0661 11.5502 24.1459C11.5963 24.2257 11.6983 24.253 11.7781 24.207Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.6843 23.4742C13.7786 23.4197 13.8109 23.2992 13.7565 23.2048C13.702 23.1105 13.5815 23.0782 13.4872 23.1327C13.3929 23.1871 13.3605 23.3077 13.415 23.402C13.4694 23.4963 13.59 23.5286 13.6843 23.4742Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.1521 24.0089C13.1956 23.9838 13.2105 23.9282 13.1854 23.8847C13.1602 23.8412 13.1046 23.8263 13.0612 23.8514C13.0177 23.8765 13.0028 23.9321 13.0279 23.9756C13.053 24.0191 13.1086 24.034 13.1521 24.0089Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.5907 24.2817C13.6487 24.2482 13.6686 24.174 13.6351 24.116C13.6016 24.058 13.5274 24.0381 13.4693 24.0716C13.4113 24.1051 13.3914 24.1793 13.4249 24.2373C13.4584 24.2954 13.5326 24.3153 13.5907 24.2817Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.4842 24.3435C14.5423 24.31 14.5621 24.2358 14.5286 24.1778C14.4951 24.1197 14.4209 24.0999 14.3629 24.1334C14.3049 24.1669 14.285 24.2411 14.3185 24.2991C14.352 24.3571 14.4262 24.377 14.4842 24.3435Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.60911 24.0573C8.65259 24.0322 8.66749 23.9766 8.64238 23.9331C8.61728 23.8896 8.56167 23.8747 8.51819 23.8998C8.4747 23.9249 8.45981 23.9805 8.48491 24.024C8.51002 24.0675 8.56562 24.0824 8.60911 24.0573Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.28399 24.0702C8.35653 24.0283 8.38139 23.9355 8.33951 23.863C8.29762 23.7904 8.20487 23.7656 8.13232 23.8075C8.05978 23.8493 8.03493 23.9421 8.07681 24.0146C8.11869 24.0872 8.21145 24.112 8.28399 24.0702Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.65793 22.838C8.70142 22.8129 8.71632 22.7573 8.69121 22.7138C8.66611 22.6703 8.6105 22.6554 8.56702 22.6806C8.52353 22.7057 8.50863 22.7613 8.53374 22.8047C8.55884 22.8482 8.61445 22.8631 8.65793 22.838Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.38943 22.5552C8.42566 22.5343 8.43808 22.4879 8.41715 22.4517C8.39623 22.4155 8.3499 22.4031 8.31366 22.424C8.27742 22.4449 8.26501 22.4912 8.28593 22.5275C8.30685 22.5637 8.35319 22.5761 8.38943 22.5552Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.86215 23.1566C6.92019 23.1231 6.94007 23.0489 6.90657 22.9908C6.87306 22.9328 6.79885 22.9129 6.74082 22.9464C6.68279 22.9799 6.6629 23.0541 6.69641 23.1122C6.72991 23.1702 6.80412 23.1901 6.86215 23.1566Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.71654 21.5424C7.81809 21.4837 7.85289 21.3539 7.79426 21.2523C7.73562 21.1508 7.60576 21.116 7.5042 21.1746C7.40264 21.2332 7.36785 21.3631 7.42648 21.4646C7.48511 21.5662 7.61498 21.601 7.71654 21.5424Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.18735 21.3228C7.24539 21.2893 7.26527 21.2151 7.23176 21.157C7.19826 21.099 7.12405 21.0791 7.06602 21.1126C7.00798 21.1461 6.9881 21.2203 7.02161 21.2784C7.05511 21.3364 7.12932 21.3563 7.18735 21.3228Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.01929 20.7893C7.11359 20.7348 7.1459 20.6142 7.09146 20.5199C7.03701 20.4256 6.91642 20.3933 6.82212 20.4477C6.72782 20.5022 6.69551 20.6228 6.74995 20.7171C6.8044 20.8114 6.92499 20.8437 7.01929 20.7893Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.50956 21.3284C5.58936 21.2823 5.6167 21.1803 5.57063 21.1005C5.52456 21.0207 5.42252 20.9934 5.34273 21.0394C5.26293 21.0855 5.23559 21.1876 5.28166 21.2673C5.32773 21.3471 5.42976 21.3745 5.50956 21.3284Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.01052 19.7082C6.04675 19.6872 6.05917 19.6409 6.03825 19.6047C6.01733 19.5684 5.97099 19.556 5.93475 19.5769C5.89852 19.5978 5.8861 19.6442 5.90702 19.6804C5.92794 19.7167 5.97428 19.7291 6.01052 19.7082Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.42854 19.3955C4.49383 19.3578 4.5162 19.2744 4.47851 19.2091C4.44081 19.1438 4.35733 19.1214 4.29204 19.1591C4.22675 19.1968 4.20439 19.2803 4.24208 19.3456C4.27977 19.4109 4.36326 19.4332 4.42854 19.3955Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.02707 18.7355C6.07056 18.7104 6.08546 18.6548 6.06035 18.6113C6.03524 18.5678 5.97964 18.5529 5.93616 18.578C5.89267 18.6031 5.87777 18.6587 5.90288 18.7022C5.92799 18.7457 5.98359 18.7606 6.02707 18.7355Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.17558 18.5613C4.25537 18.5153 4.28271 18.4132 4.23664 18.3334C4.19057 18.2536 4.08854 18.2263 4.00874 18.2724C3.92895 18.3184 3.90161 18.4205 3.94768 18.5003C3.99375 18.5801 4.09578 18.6074 4.17558 18.5613Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.10823 17.8994C4.17352 17.8617 4.19589 17.7783 4.15819 17.713C4.1205 17.6477 4.03702 17.6253 3.97173 17.663C3.90644 17.7007 3.88407 17.7842 3.92177 17.8495C3.95946 17.9148 4.04294 17.9371 4.10823 17.8994Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.70677 17.4841C5.80833 17.4254 5.84312 17.2956 5.78449 17.194C5.72585 17.0925 5.59599 17.0577 5.49443 17.1163C5.39288 17.1749 5.35808 17.3048 5.41671 17.4064C5.47535 17.5079 5.60521 17.5427 5.70677 17.4841Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.98327 17.0791C5.03405 17.0498 5.05145 16.9849 5.02213 16.9341C4.99281 16.8833 4.92788 16.8659 4.8771 16.8952C4.82632 16.9245 4.80892 16.9895 4.83824 17.0402C4.86756 17.091 4.93249 17.1084 4.98327 17.0791Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.58384 16.8721C4.64187 16.8386 4.66175 16.7644 4.62825 16.7063C4.59474 16.6483 4.52053 16.6284 4.4625 16.6619C4.40447 16.6954 4.38458 16.7696 4.41809 16.8277C4.45159 16.8857 4.5258 16.9056 4.58384 16.8721Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.39335 16.3341C5.47315 16.2881 5.50049 16.186 5.45442 16.1062C5.40835 16.0265 5.30631 15.9991 5.22652 16.0452C5.14672 16.0913 5.11938 16.1933 5.16545 16.2731C5.21152 16.3529 5.31355 16.3802 5.39335 16.3341Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.88069 16.9119C7.94598 16.8742 7.96835 16.7907 7.93065 16.7254C7.89296 16.6601 7.80948 16.6378 7.74419 16.6755C7.6789 16.7132 7.65653 16.7966 7.69423 16.8619C7.73192 16.9272 7.8154 16.9496 7.88069 16.9119Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.37194 17.4861C7.42272 17.4568 7.44012 17.3918 7.4108 17.3411C7.38148 17.2903 7.31655 17.2729 7.26577 17.3022C7.21499 17.3315 7.19759 17.3965 7.22691 17.4472C7.25623 17.498 7.32116 17.5154 7.37194 17.4861Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.96372 17.3906C9.02176 17.3571 9.04164 17.2829 9.00813 17.2249C8.97463 17.1669 8.90042 17.147 8.84239 17.1805C8.78435 17.214 8.76447 17.2882 8.79797 17.3462C8.83148 17.4043 8.90569 17.4241 8.96372 17.3906Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.49294 17.8197C8.52918 17.7987 8.54159 17.7524 8.52067 17.7162C8.49975 17.6799 8.45341 17.6675 8.41718 17.6884C8.38094 17.7094 8.36852 17.7557 8.38944 17.7919C8.41036 17.8282 8.4567 17.8406 8.49294 17.8197Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.45288 18.5084C8.54719 18.454 8.5795 18.3334 8.52505 18.2391C8.47061 18.1448 8.35002 18.1125 8.25571 18.1669C8.16141 18.2214 8.1291 18.342 8.18355 18.4363C8.23799 18.5306 8.35858 18.5629 8.45288 18.5084Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.87089 18.5649C8.95068 18.5189 8.97802 18.4168 8.93195 18.337C8.88588 18.2572 8.78385 18.2299 8.70405 18.276C8.62426 18.322 8.59692 18.4241 8.64299 18.5039C8.68906 18.5837 8.79109 18.611 8.87089 18.5649Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.6961 18.3863C9.76864 18.3444 9.7935 18.2517 9.75162 18.1791C9.70973 18.1066 9.61698 18.0817 9.54443 18.1236C9.47189 18.1655 9.44704 18.2583 9.48892 18.3308C9.5308 18.4034 9.62356 18.4282 9.6961 18.3863Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.37773 19.4099C8.45752 19.3638 8.48486 19.2618 8.43879 19.182C8.39272 19.1022 8.29069 19.0749 8.21089 19.1209C8.13109 19.167 8.10375 19.269 8.14982 19.3488C8.19589 19.4286 8.29793 19.456 8.37773 19.4099Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.28794 19.9522C8.34597 19.9187 8.36586 19.8445 8.33235 19.7864C8.29884 19.7284 8.22464 19.7085 8.1666 19.742C8.10857 19.7755 8.08869 19.8497 8.12219 19.9078C8.1557 19.9658 8.2299 19.9857 8.28794 19.9522Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.80745 20.0025C8.87274 19.9648 8.89511 19.8813 8.85741 19.816C8.81972 19.7507 8.73624 19.7283 8.67095 19.766C8.60566 19.8037 8.58329 19.8872 8.62098 19.9525C8.65868 20.0178 8.74216 20.0402 8.80745 20.0025Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.2625 19.7928C10.3278 19.7551 10.3502 19.6716 10.3125 19.6063C10.2748 19.541 10.1913 19.5186 10.126 19.5563C10.0607 19.594 10.0384 19.6775 10.0761 19.7428C10.1138 19.8081 10.1972 19.8304 10.2625 19.7928Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.50958 20.9456C9.58212 20.9037 9.60698 20.811 9.56509 20.7384C9.52321 20.6659 9.43045 20.641 9.35791 20.6829C9.28537 20.7248 9.26052 20.8175 9.3024 20.8901C9.34428 20.9626 9.43704 20.9875 9.50958 20.9456Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.55837 21.4251C9.64542 21.3748 9.67525 21.2635 9.62499 21.1765C9.57473 21.0894 9.46342 21.0596 9.37637 21.1099C9.28932 21.1601 9.25949 21.2714 9.30975 21.3585C9.36001 21.4455 9.47132 21.4754 9.55837 21.4251Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.76636 21.7251C9.86066 21.6706 9.89297 21.5501 9.83853 21.4558C9.78408 21.3615 9.6635 21.3291 9.56919 21.3836C9.47489 21.438 9.44257 21.5586 9.49702 21.6529C9.55147 21.7472 9.67205 21.7795 9.76636 21.7251Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.8142 21.6283C10.8505 21.6073 10.8629 21.561 10.842 21.5248C10.821 21.4885 10.7747 21.4761 10.7385 21.497C10.7022 21.518 10.6898 21.5643 10.7107 21.6005C10.7317 21.6368 10.778 21.6492 10.8142 21.6283Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.0047 22.866C10.0555 22.8367 10.0729 22.7718 10.0436 22.721C10.0143 22.6702 9.94936 22.6528 9.89858 22.6821C9.84781 22.7115 9.83041 22.7764 9.85972 22.8272C9.88904 22.8779 9.95397 22.8953 10.0047 22.866Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.9901 22.6302C11.0554 22.5925 11.0777 22.509 11.04 22.4437C11.0023 22.3784 10.9189 22.356 10.8536 22.3937C10.7883 22.4314 10.7659 22.5149 10.8036 22.5802C10.8413 22.6455 10.9248 22.6678 10.9901 22.6302Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.50565 23.7839C7.58545 23.7378 7.61279 23.6358 7.56672 23.556C7.52065 23.4762 7.41862 23.4489 7.33882 23.495C7.25902 23.541 7.23168 23.6431 7.27775 23.7229C7.32382 23.8026 7.42586 23.83 7.50565 23.7839Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.23717 23.2565C7.28795 23.2272 7.30535 23.1622 7.27603 23.1114C7.24672 23.0607 7.18179 23.0433 7.13101 23.0726C7.08023 23.1019 7.06283 23.1668 7.09215 23.2176C7.12146 23.2684 7.18639 23.2858 7.23717 23.2565Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.26641 23.274C6.33896 23.2321 6.36381 23.1393 6.32193 23.0668C6.28005 22.9942 6.18729 22.9694 6.11475 23.0113C6.04221 23.0531 6.01735 23.1459 6.05923 23.2184C6.10111 23.291 6.19387 23.3158 6.26641 23.274Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.65891 22.8889C5.70239 22.8638 5.71729 22.8082 5.69219 22.7647C5.66708 22.7213 5.61148 22.7064 5.56799 22.7315C5.52451 22.7566 5.50961 22.8122 5.53472 22.8557C5.55982 22.8991 5.61542 22.914 5.65891 22.8889Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.3738 22.3353C5.41003 22.3144 5.42245 22.268 5.40153 22.2318C5.38061 22.1956 5.33427 22.1831 5.29803 22.2041C5.2618 22.225 5.24938 22.2713 5.2703 22.3076C5.29122 22.3438 5.33756 22.3562 5.3738 22.3353Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.51938 21.4988C6.57742 21.4653 6.5973 21.3911 6.56379 21.333C6.53029 21.275 6.45608 21.2551 6.39805 21.2886C6.34001 21.3221 6.32013 21.3964 6.35364 21.4544C6.38714 21.5124 6.46135 21.5323 6.51938 21.4988Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.21073 21.1164C6.29053 21.0703 6.31787 20.9683 6.2718 20.8885C6.22573 20.8087 6.12369 20.7813 6.0439 20.8274C5.9641 20.8735 5.93676 20.9755 5.98283 21.0553C6.0289 21.1351 6.13094 21.1624 6.21073 21.1164Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.32492 20.6123C5.36841 20.5872 5.38331 20.5316 5.3582 20.4881C5.3331 20.4446 5.27749 20.4297 5.23401 20.4548C5.19052 20.48 5.17563 20.5356 5.20073 20.579C5.22584 20.6225 5.28144 20.6374 5.32492 20.6123Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.82894 20.0578C4.89422 20.0201 4.91659 19.9366 4.8789 19.8713C4.8412 19.806 4.75772 19.7836 4.69243 19.8213C4.62714 19.859 4.60478 19.9425 4.64247 20.0078C4.68016 20.0731 4.76365 20.0955 4.82894 20.0578Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.10141 19.4106C6.15945 19.3771 6.17933 19.3029 6.14583 19.2449C6.11232 19.1868 6.03811 19.1669 5.98008 19.2004C5.92205 19.2339 5.90216 19.3082 5.93567 19.3662C5.96917 19.4242 6.04338 19.4441 6.10141 19.4106Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.05944 18.9092C5.11022 18.8799 5.12762 18.815 5.0983 18.7642C5.06898 18.7134 5.00405 18.696 4.95327 18.7254C4.90249 18.7547 4.88509 18.8196 4.91441 18.8704C4.94373 18.9212 5.00866 18.9386 5.05944 18.9092Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.19411 18.533C4.28116 18.4828 4.31099 18.3714 4.26073 18.2844C4.21047 18.1973 4.09916 18.1675 4.01211 18.2178C3.92506 18.268 3.89523 18.3793 3.94549 18.4664C3.99575 18.5534 4.10706 18.5833 4.19411 18.533Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.40214 18.0461C5.48193 18 5.50927 17.898 5.4632 17.8182C5.41713 17.7384 5.3151 17.711 5.2353 17.7571C5.15551 17.8032 5.12817 17.9052 5.17424 17.985C5.22031 18.0648 5.32234 18.0921 5.40214 18.0461Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.23421 17.3891C4.2995 17.3514 4.32186 17.2679 4.28417 17.2027C4.24648 17.1374 4.16299 17.115 4.09771 17.1527C4.03242 17.1904 4.01005 17.2739 4.04774 17.3392C4.08544 17.4044 4.16892 17.4268 4.23421 17.3891Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.40024 16.8559C4.45828 16.8224 4.47816 16.7482 4.44465 16.6902C4.41115 16.6321 4.33694 16.6122 4.27891 16.6458C4.22087 16.6793 4.20099 16.7535 4.2345 16.8115C4.268 16.8695 4.34221 16.8894 4.40024 16.8559Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.41485 16.5492C4.4874 16.5073 4.51225 16.4146 4.47037 16.342C4.42849 16.2695 4.33573 16.2446 4.26319 16.2865C4.19064 16.3284 4.16579 16.4212 4.20767 16.4937C4.24955 16.5663 4.34231 16.5911 4.41485 16.5492Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.69809 15.9479C4.75613 15.9144 4.77601 15.8402 4.74251 15.7821C4.709 15.7241 4.63479 15.7042 4.57676 15.7377C4.51873 15.7712 4.49884 15.8454 4.53235 15.9035C4.56585 15.9615 4.64006 15.9814 4.69809 15.9479Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.281 26.8926C10.3608 26.8465 10.3882 26.7445 10.3421 26.6647C10.296 26.5849 10.194 26.5575 10.1142 26.6036C10.0344 26.6497 10.0071 26.7517 10.0531 26.8315C10.0992 26.9113 10.2012 26.9386 10.281 26.8926Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.66282 27.2138C8.7063 27.1887 8.7212 27.133 8.69609 27.0896C8.67099 27.0461 8.61538 27.0312 8.5719 27.0563C8.52842 27.0814 8.51352 27.137 8.53862 27.1805C8.56373 27.224 8.61933 27.2389 8.66282 27.2138Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.61691 27.0127C8.6604 26.9876 8.6753 26.932 8.65019 26.8885C8.62509 26.845 8.56949 26.8301 8.526 26.8552C8.48252 26.8803 8.46762 26.9359 8.49272 26.9794C8.51783 27.0229 8.57343 27.0378 8.61691 27.0127Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.23421 26.7436C8.2995 26.7059 8.32187 26.6224 8.28417 26.5571C8.24648 26.4918 8.16299 26.4694 8.09771 26.5071C8.03242 26.5448 8.01005 26.6283 8.04775 26.6936C8.08544 26.7589 8.16893 26.7813 8.23421 26.7436Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.12477 25.2835C9.16101 25.2626 9.17343 25.2163 9.15251 25.18C9.13158 25.1438 9.08525 25.1314 9.04901 25.1523C9.01277 25.1732 9.00036 25.2196 9.02128 25.2558C9.0422 25.292 9.08854 25.3045 9.12477 25.2835Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.44411 25.3786C8.48035 25.3577 8.49276 25.3114 8.47184 25.2751C8.45092 25.2389 8.40458 25.2265 8.36835 25.2474C8.33211 25.2683 8.31969 25.3147 8.34062 25.3509C8.36154 25.3871 8.40787 25.3995 8.44411 25.3786Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.55058 24.7744C8.63037 24.7283 8.65771 24.6263 8.61164 24.5465C8.56557 24.4667 8.46354 24.4394 8.38374 24.4854C8.30395 24.5315 8.2766 24.6335 8.32268 24.7133C8.36875 24.7931 8.47078 24.8205 8.55058 24.7744Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.88073 24.8282C7.93151 24.7989 7.9489 24.734 7.91959 24.6832C7.89027 24.6324 7.82534 24.615 7.77456 24.6444C7.72378 24.6737 7.70638 24.7386 7.7357 24.7894C7.76502 24.8402 7.82995 24.8576 7.88073 24.8282Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.39331 24.409C7.48762 24.3545 7.51993 24.234 7.46548 24.1397C7.41103 24.0454 7.29045 24.013 7.19614 24.0675C7.10184 24.1219 7.06953 24.2425 7.12397 24.3368C7.17842 24.4311 7.29901 24.4634 7.39331 24.409Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.05356 23.7295C7.1116 23.696 7.13148 23.6218 7.09797 23.5638C7.06447 23.5057 6.99026 23.4858 6.93223 23.5193C6.87419 23.5529 6.85431 23.6271 6.88782 23.6851C6.92132 23.7431 6.99553 23.763 7.05356 23.7295Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.38062 23.8379C6.47492 23.7834 6.50723 23.6629 6.45278 23.5686C6.39834 23.4742 6.27775 23.4419 6.18345 23.4964C6.08914 23.5508 6.05683 23.6714 6.11128 23.7657C6.16572 23.86 6.28631 23.8923 6.38062 23.8379Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.38364 22.874C7.44167 22.8405 7.46156 22.7663 7.42805 22.7082C7.39455 22.6502 7.32034 22.6303 7.26231 22.6638C7.20427 22.6973 7.18439 22.7715 7.21789 22.8296C7.2514 22.8876 7.32561 22.9075 7.38364 22.874Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.71175 22.8939C6.77703 22.8562 6.7994 22.7727 6.76171 22.7074C6.72402 22.6421 6.64053 22.6198 6.57525 22.6575C6.50996 22.6952 6.48759 22.7786 6.52528 22.8439C6.56298 22.9092 6.64646 22.9316 6.71175 22.8939Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.35628 21.8563C7.42157 21.8187 7.44393 21.7352 7.40624 21.6699C7.36855 21.6046 7.28506 21.5822 7.21978 21.6199C7.15449 21.6576 7.13212 21.7411 7.16981 21.8064C7.20751 21.8717 7.29099 21.894 7.35628 21.8563Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.73413 21.777C5.82844 21.7225 5.86075 21.6019 5.8063 21.5076C5.75186 21.4133 5.63127 21.381 5.53696 21.4355C5.44266 21.4899 5.41035 21.6105 5.4648 21.7048C5.51924 21.7991 5.63983 21.8314 5.73413 21.777Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.34449 21.2839C5.43879 21.2295 5.4711 21.1089 5.41665 21.0146C5.36221 20.9203 5.24162 20.888 5.14732 20.9424C5.05301 20.9969 5.0207 21.1175 5.07515 21.2118C5.12959 21.3061 5.25018 21.3384 5.34449 21.2839Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.55157 20.3418C6.62411 20.2999 6.64897 20.2071 6.60709 20.1346C6.5652 20.062 6.47244 20.0372 6.3999 20.0791C6.32736 20.121 6.30251 20.2137 6.34439 20.2863C6.38627 20.3588 6.47903 20.3837 6.55157 20.3418Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.7009 19.9917C5.74439 19.9666 5.75929 19.911 5.73418 19.8675C5.70907 19.824 5.65347 19.8091 5.60999 19.8342C5.5665 19.8593 5.5516 19.915 5.57671 19.9584C5.60182 20.0019 5.65742 20.0168 5.7009 19.9917Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.62481 20.1584C4.69736 20.1165 4.72221 20.0237 4.68033 19.9512C4.63845 19.8786 4.54569 19.8538 4.47315 19.8957C4.4006 19.9375 4.37575 20.0303 4.41763 20.1028C4.45951 20.1754 4.55227 20.2002 4.62481 20.1584Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.6111 19.7107C4.64734 19.6897 4.65976 19.6434 4.63883 19.6072C4.61791 19.5709 4.57158 19.5585 4.53534 19.5794C4.4991 19.6003 4.48669 19.6467 4.50761 19.6829C4.52853 19.7192 4.57487 19.7316 4.6111 19.7107Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.53003 18.9704C6.62434 18.916 6.65665 18.7954 6.6022 18.7011C6.54775 18.6068 6.42717 18.5744 6.33286 18.6289C6.23856 18.6833 6.20625 18.8039 6.26069 18.8982C6.31514 18.9925 6.43573 19.0248 6.53003 18.9704Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.02228 18.6328C5.09482 18.591 5.11967 18.4982 5.07779 18.4257C5.03591 18.3531 4.94315 18.3283 4.87061 18.3701C4.79806 18.412 4.77321 18.5048 4.81509 18.5773C4.85697 18.6499 4.94973 18.6747 5.02228 18.6328Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.49099 17.8368C5.57804 17.7866 5.60786 17.6753 5.55761 17.5882C5.50735 17.5012 5.39604 17.4714 5.30899 17.5216C5.22194 17.5719 5.19211 17.6832 5.24237 17.7702C5.29263 17.8573 5.40394 17.8871 5.49099 17.8368Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.49876 17.4298C6.60032 17.3712 6.63512 17.2413 6.57648 17.1398C6.51785 17.0382 6.38799 17.0034 6.28643 17.062C6.18487 17.1207 6.15007 17.2505 6.20871 17.3521C6.26734 17.4537 6.3972 17.4885 6.49876 17.4298Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.82398 17.1363C4.91828 17.0818 4.95059 16.9613 4.89614 16.867C4.8417 16.7727 4.72111 16.7403 4.62681 16.7948C4.5325 16.8492 4.50019 16.9698 4.55464 17.0641C4.60909 17.1584 4.72967 17.1907 4.82398 17.1363Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.54184 16.8973C6.59988 16.8638 6.61976 16.7896 6.58626 16.7315C6.55275 16.6735 6.47854 16.6536 6.42051 16.6871C6.36248 16.7206 6.34259 16.7948 6.3761 16.8529C6.4096 16.9109 6.48381 16.9308 6.54184 16.8973Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.06432 16.4027C6.1151 16.3734 6.1325 16.3085 6.10318 16.2577C6.07387 16.2069 6.00893 16.1895 5.95816 16.2188C5.90738 16.2481 5.88998 16.3131 5.9193 16.3639C5.94861 16.4146 6.01354 16.432 6.06432 16.4027Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.00267 16.2977C6.10423 16.239 6.13903 16.1092 6.08039 16.0076C6.02176 15.9061 5.89189 15.8713 5.79033 15.9299C5.68878 15.9885 5.65398 16.1184 5.71261 16.22C5.77125 16.3215 5.90111 16.3563 6.00267 16.2977Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.44028 15.8694C5.49831 15.8359 5.5182 15.7617 5.48469 15.7037C5.45119 15.6456 5.37698 15.6257 5.31895 15.6592C5.26091 15.6927 5.24103 15.767 5.27453 15.825C5.30804 15.883 5.38225 15.9029 5.44028 15.8694Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.56038 15.5031C6.62567 15.4654 6.64803 15.382 6.61034 15.3167C6.57265 15.2514 6.48916 15.229 6.42388 15.2667C6.35859 15.3044 6.33622 15.3879 6.37391 15.4532C6.41161 15.5185 6.49509 15.5408 6.56038 15.5031Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.617 30.1861C14.6751 30.1526 14.695 30.0784 14.6614 30.0204C14.6279 29.9623 14.5537 29.9424 14.4957 29.976C14.4377 30.0095 14.4178 30.0837 14.4513 30.1417C14.4848 30.1997 14.559 30.2196 14.617 30.1861Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.2243 29.8698C14.2678 29.8447 14.2827 29.7891 14.2576 29.7456C14.2325 29.7021 14.1769 29.6872 14.1334 29.7124C14.0899 29.7375 14.075 29.7931 14.1001 29.8365C14.1252 29.88 14.1809 29.8949 14.2243 29.8698Z" fill={props.bgColor || defaultIconColor} />
        <path d="M14.7469 28.8329C14.8194 28.791 14.8443 28.6983 14.8024 28.6257C14.7605 28.5532 14.6678 28.5283 14.5952 28.5702C14.5227 28.6121 14.4978 28.7049 14.5397 28.7774C14.5816 28.8499 14.6743 28.8748 14.7469 28.8329Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.827 29.0308C13.885 28.9973 13.9049 28.9231 13.8714 28.8651C13.8379 28.8071 13.7637 28.7872 13.7057 28.8207C13.6476 28.8542 13.6277 28.9284 13.6613 28.9864C13.6948 29.0445 13.769 29.0643 13.827 29.0308Z" fill={props.bgColor || defaultIconColor} />
        <path d="M13.4246 29.3335C13.5189 29.279 13.5512 29.1585 13.4967 29.0642C13.4423 28.9699 13.3217 28.9375 13.2274 28.992C13.1331 29.0464 13.1008 29.167 13.1552 29.2613C13.2097 29.3556 13.3303 29.3879 13.4246 29.3335Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.8093 29.0579C12.9109 28.9992 12.9457 28.8694 12.887 28.7678C12.8284 28.6663 12.6985 28.6315 12.597 28.6901C12.4954 28.7487 12.4606 28.8786 12.5193 28.9802C12.5779 29.0817 12.7078 29.1165 12.8093 29.0579Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.8689 28.4631C12.9124 28.438 12.9273 28.3824 12.9021 28.3389C12.877 28.2954 12.8214 28.2805 12.778 28.3056C12.7345 28.3307 12.7196 28.3863 12.7447 28.4298C12.7698 28.4733 12.8254 28.4882 12.8689 28.4631Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.4471 28.7942C12.5052 28.7607 12.525 28.6865 12.4915 28.6285C12.458 28.5704 12.3838 28.5505 12.3258 28.584C12.2677 28.6176 12.2479 28.6918 12.2814 28.7498C12.3149 28.8078 12.3891 28.8277 12.4471 28.7942Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.575 27.7401C12.6693 27.6857 12.7016 27.5651 12.6471 27.4708C12.5927 27.3765 12.4721 27.3442 12.3778 27.3986C12.2835 27.4531 12.2512 27.5736 12.3056 27.6679C12.3601 27.7623 12.4807 27.7946 12.575 27.7401Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.2576 27.2223C12.2938 27.2013 12.3062 27.155 12.2853 27.1188C12.2644 27.0825 12.2181 27.0701 12.1818 27.091C12.1456 27.1119 12.1332 27.1583 12.1541 27.1945C12.175 27.2308 12.2214 27.2432 12.2576 27.2223Z" fill={props.bgColor || defaultIconColor} />
        <path d="M12.3211 26.573C12.3573 26.5521 12.3697 26.5058 12.3488 26.4695C12.3279 26.4333 12.2815 26.4209 12.2453 26.4418C12.2091 26.4627 12.1966 26.5091 12.2176 26.5453C12.2385 26.5815 12.2848 26.5939 12.3211 26.573Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.2186 27.9098C11.2694 27.8805 11.2868 27.8156 11.2575 27.7648C11.2282 27.714 11.1632 27.6967 11.1125 27.726C11.0617 27.7553 11.0443 27.8202 11.0736 27.871C11.1029 27.9218 11.1678 27.9392 11.2186 27.9098Z" fill={props.bgColor || defaultIconColor} />
        <path d="M11.2186 27.1216C11.2911 27.0797 11.316 26.987 11.2741 26.9144C11.2322 26.8419 11.1394 26.817 11.0669 26.8589C10.9944 26.9008 10.9695 26.9936 11.0114 27.0661C11.0533 27.1386 11.146 27.1635 11.2186 27.1216Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.5496 27.5084C10.6222 27.4665 10.647 27.3738 10.6051 27.3012C10.5633 27.2287 10.4705 27.2038 10.398 27.2457C10.3254 27.2876 10.3006 27.3803 10.3424 27.4529C10.3843 27.5254 10.4771 27.5503 10.5496 27.5084Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.409 26.8713C10.4888 26.8252 10.5161 26.7232 10.47 26.6434C10.424 26.5636 10.3219 26.5363 10.2421 26.5824C10.1623 26.6284 10.135 26.7305 10.1811 26.8103C10.2271 26.8901 10.3292 26.9174 10.409 26.8713Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.81325 27.1448C9.9003 27.0945 9.93013 26.9832 9.87987 26.8961C9.82961 26.8091 9.7183 26.7793 9.63125 26.8295C9.5442 26.8798 9.51437 26.9911 9.56463 27.0781C9.61489 27.1652 9.7262 27.195 9.81325 27.1448Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.72641 26.5996C9.78444 26.5661 9.80433 26.4919 9.77082 26.4339C9.73732 26.3758 9.66311 26.356 9.60508 26.3895C9.54705 26.423 9.52716 26.4972 9.56066 26.5552C9.59417 26.6132 9.66838 26.6331 9.72641 26.5996Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.31429 26.8555C9.37957 26.8178 9.40194 26.7343 9.36425 26.669C9.32655 26.6037 9.24307 26.5814 9.17778 26.6191C9.1125 26.6568 9.09013 26.7402 9.12782 26.8055C9.16552 26.8708 9.249 26.8932 9.31429 26.8555Z" fill={props.bgColor || defaultIconColor} />
        <path d="M10.1189 25.0947C10.1623 25.0696 10.1772 25.014 10.1521 24.9705C10.127 24.9271 10.0714 24.9122 10.028 24.9373C9.98447 24.9624 9.96957 25.018 9.99467 25.0615C10.0198 25.1049 10.0754 25.1198 10.1189 25.0947Z" fill={props.bgColor || defaultIconColor} />
        <path d="M9.03985 25.6828C9.11239 25.641 9.13725 25.5482 9.09537 25.4757C9.05349 25.4031 8.96073 25.3783 8.88818 25.4201C8.81564 25.462 8.79079 25.5548 8.83267 25.6273C8.87455 25.6999 8.96731 25.7247 9.03985 25.6828Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.86507 24.9254C8.93035 24.8877 8.95272 24.8043 8.91503 24.739C8.87734 24.6737 8.79385 24.6513 8.72857 24.689C8.66328 24.7267 8.64091 24.8102 8.6786 24.8755C8.71629 24.9408 8.79978 24.9631 8.86507 24.9254Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.75279 25.5155C7.81082 25.482 7.8307 25.4078 7.79719 25.3498C7.76369 25.2917 7.68948 25.2719 7.63145 25.3054C7.57341 25.3389 7.55353 25.4131 7.58704 25.4711C7.62054 25.5291 7.69475 25.549 7.75279 25.5155Z" fill={props.bgColor || defaultIconColor} />
        <path d="M8.36011 24.7791C8.45442 24.7246 8.48673 24.604 8.43228 24.5097C8.37783 24.4154 8.25725 24.3831 8.16294 24.4375C8.06864 24.492 8.03633 24.6126 8.09077 24.7069C8.14522 24.8012 8.26581 24.8335 8.36011 24.7791Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.96075 24.239C8.03329 24.1972 8.05815 24.1044 8.01626 24.0319C7.97438 23.9593 7.88162 23.9345 7.80908 23.9764C7.73654 24.0182 7.71169 24.111 7.75357 24.1835C7.79545 24.2561 7.88821 24.2809 7.96075 24.239Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.51745 24.1978C7.56823 24.1684 7.58563 24.1035 7.55631 24.0527C7.52699 24.002 7.46206 23.9846 7.41128 24.0139C7.3605 24.0432 7.3431 24.1081 7.37242 24.1589C7.40174 24.2097 7.46667 24.2271 7.51745 24.1978Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.8865 24.1065C6.97355 24.0562 7.00337 23.9449 6.95311 23.8579C6.90285 23.7708 6.79154 23.741 6.70449 23.7913C6.61744 23.8415 6.58762 23.9528 6.63788 24.0399C6.68813 24.1269 6.79945 24.1568 6.8865 24.1065Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.81521 23.3769C6.90226 23.3266 6.93208 23.2153 6.88182 23.1283C6.83157 23.0412 6.72025 23.0114 6.6332 23.0616C6.54615 23.1119 6.51633 23.2232 6.56659 23.3103C6.61684 23.3973 6.72816 23.4271 6.81521 23.3769Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.03597 23.179C6.10125 23.1413 6.12362 23.0579 6.08593 22.9926C6.04823 22.9273 5.96475 22.9049 5.89946 22.9426C5.83418 22.9803 5.81181 23.0638 5.8495 23.1291C5.88719 23.1944 5.97068 23.2167 6.03597 23.179Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.6238 22.1392C7.71085 22.089 7.74068 21.9776 7.69042 21.8906C7.64016 21.8035 7.52885 21.7737 7.4418 21.824C7.35475 21.8742 7.32492 21.9855 7.37518 22.0726C7.42544 22.1596 7.53675 22.1895 7.6238 22.1392Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.94321 22.6546C6.00124 22.6211 6.02113 22.5469 5.98762 22.4889C5.95412 22.4308 5.87991 22.411 5.82188 22.4445C5.76384 22.478 5.74396 22.5522 5.77746 22.6102C5.81097 22.6682 5.88518 22.6881 5.94321 22.6546Z" fill={props.bgColor || defaultIconColor} />
        <path d="M7.09056 21.3966C7.19212 21.3379 7.22692 21.2081 7.16828 21.1065C7.10964 21.0049 6.97978 20.9701 6.87822 21.0288C6.77667 21.0874 6.74187 21.2173 6.8005 21.3188C6.85914 21.4204 6.989 21.4552 7.09056 21.3966Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.17952 21.3619C5.24481 21.3242 5.26718 21.2407 5.22948 21.1754C5.19179 21.1102 5.10831 21.0878 5.04302 21.1255C4.97773 21.1632 4.95536 21.2467 4.99306 21.3119C5.03075 21.3772 5.11423 21.3996 5.17952 21.3619Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.54372 20.8711C5.63077 20.8209 5.6606 20.7096 5.61034 20.6225C5.56008 20.5355 5.44877 20.5057 5.36172 20.5559C5.27467 20.6062 5.24484 20.7175 5.2951 20.8045C5.34536 20.8916 5.45667 20.9214 5.54372 20.8711Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.17758 19.9973C6.23562 19.9638 6.2555 19.8896 6.222 19.8315C6.18849 19.7735 6.11428 19.7536 6.05625 19.7871C5.99822 19.8206 5.97833 19.8948 6.01184 19.9529C6.04534 20.0109 6.11955 20.0308 6.17758 19.9973Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.83181 20.2139C4.86804 20.193 4.88046 20.1466 4.85954 20.1104C4.83862 20.0742 4.79228 20.0617 4.75604 20.0827C4.71981 20.1036 4.70739 20.1499 4.72831 20.1862C4.74923 20.2224 4.79557 20.2348 4.83181 20.2139Z" fill={props.bgColor || defaultIconColor} />
        <path d="M6.33078 19.3832C6.37427 19.3581 6.38917 19.3025 6.36406 19.259C6.33895 19.2155 6.28335 19.2006 6.23987 19.2257C6.19638 19.2508 6.18148 19.3064 6.20659 19.3499C6.2317 19.3934 6.2873 19.4083 6.33078 19.3832Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.53781 19.193C5.5813 19.1679 5.5962 19.1123 5.57109 19.0688C5.54599 19.0253 5.49038 19.0104 5.4469 19.0355C5.40341 19.0606 5.38852 19.1162 5.41362 19.1597C5.43873 19.2032 5.49433 19.2181 5.53781 19.193Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.98606 18.3914C6.02954 18.3663 6.04444 18.3107 6.01933 18.2672C5.99423 18.2238 5.93863 18.2089 5.89514 18.234C5.85166 18.2591 5.83676 18.3147 5.86186 18.3582C5.88697 18.4016 5.94257 18.4165 5.98606 18.3914Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.36203 18.1911C5.40552 18.1659 5.42042 18.1103 5.39531 18.0669C5.37021 18.0234 5.3146 18.0085 5.27112 18.0336C5.22763 18.0587 5.21273 18.1143 5.23784 18.1578C5.26295 18.2013 5.31855 18.2162 5.36203 18.1911Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.19507 17.9022C5.28938 17.8478 5.32169 17.7272 5.26724 17.6329C5.21279 17.5386 5.09221 17.5063 4.9979 17.5607C4.9036 17.6152 4.87129 17.7358 4.92573 17.8301C4.98018 17.9244 5.10077 17.9567 5.19507 17.9022Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.56819 17.0916C5.63348 17.0539 5.65585 16.9704 5.61816 16.9051C5.58046 16.8398 5.49698 16.8175 5.43169 16.8551C5.3664 16.8928 5.34403 16.9763 5.38173 17.0416C5.41942 17.1069 5.5029 17.1293 5.56819 17.0916Z" fill={props.bgColor || defaultIconColor} />
        <path d="M4.26243 16.7418C4.30591 16.7167 4.32081 16.6611 4.2957 16.6176C4.2706 16.5742 4.21499 16.5593 4.17151 16.5844C4.12802 16.6095 4.11313 16.6651 4.13823 16.7086C4.16334 16.752 4.21894 16.7669 4.26243 16.7418Z" fill={props.bgColor || defaultIconColor} />
        <path d="M3.99005 16.602C4.06259 16.5601 4.08744 16.4673 4.04556 16.3948C4.00368 16.3222 3.91092 16.2974 3.83838 16.3393C3.76584 16.3811 3.74098 16.4739 3.78287 16.5464C3.82475 16.619 3.91751 16.6438 3.99005 16.602Z" fill={props.bgColor || defaultIconColor} />
        <path d="M5.09261 15.965C5.1579 15.9273 5.18026 15.8438 5.14257 15.7785C5.10488 15.7132 5.02139 15.6909 4.9561 15.7286C4.89082 15.7663 4.86845 15.8497 4.90614 15.915C4.94384 15.9803 5.02732 16.0027 5.09261 15.965Z" fill={props.bgColor || defaultIconColor} />
        </g>}
        </svg>
    )
}
