import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import { ChevronRightIcon } from "../Icon/ChevronRightIcon"

import { colors } from "../../static/colors"


interface Props {
    content: JSX.Element;
    label?: string;
    onClick?: () => void;
}


export const TitleSection = (props: Props) => {

    return (
        <Grid
            container
            alignItems='center'
            sx={{
                pt: {
                    md: 2
                }
            }} >
            <Grid
                item
                xs
                sx={{
                    py: 'auto'
                }} >
                <Typography variant='h2'>{props.content}</Typography>
            </Grid>

            {props.label &&
            <Grid
                item
                xs='auto'>
                <Button
                    variant='text'
                    color='inherit'
                    onClick={() => {
                        if(props.onClick)
                            return props.onClick();
                    }}
                    sx={{
                        display: {
                            md: 'flex',
                            xs: 'none'
                        }
                    }}>
                    <Typography variant='button' color={colors.grey.main}>{props.label}</Typography>
                </Button>

                <IconButton
                    onClick={() => {
                        if(props.onClick)
                            return props.onClick();
                    }}
                    sx={{
                        display: {
                            md: 'none',
                            xs: 'flex'
                        }
                    }}>
                    <ChevronRightIcon />
                </IconButton>
            </Grid>}
        </Grid>
    )
}
