import { IReview, IReviewDTO } from "../models/Review.model"
import AuthService from "../service/AuthService"

const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'
const authService = new AuthService();


export const getReview = (id: number): Promise<IReview> => {

    return fetch(`${apiUrl}/review/${id}`,
        {
            method: "GET",
        }).then(_ => {
            if (_.status === 200) return _.json()
            else throw "Error"
        })
}

export const getProductReviews = (code: string): Promise<IReview[]> => {

    return fetch(`${apiUrl}/product/${code}/review`,
        {
            method: "GET",
        }).then(_ => {
            if (_.status === 200) return _.json()
            else throw "Error"
        })
}


export const reviewProduct = (review: IReviewDTO): Promise<IReview> => {

    return fetch(`${apiUrl}/review`,
        {
            method: "POST",
            headers: authService.getRequestHeaders({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(review)

        }).then(_ => {
            if (_.status === 200) return _.json()
            else throw "Forbidden"
        })
}
