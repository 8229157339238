import decode from 'jwt-decode';
import { ILoginRequest } from '../models/Authentication.model';

const domain = process.env.REACT_APP_API_URL // API server domain

export default class AuthService {

    // Initializing important variables
    constructor() {
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(log: ILoginRequest) {
        // Get a token from api server using the fetch api
        return fetch(`${domain}/marketplace/auth/signin`, {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(log)
        }).then(async res => {
            if (res.status >= 200 && res.status < 300) {
                const response = await this._parseJSON(res);
                this.setToken(response.token);
                return Promise.resolve(res);
            }
            else {
                var error: any = new Error(res.statusText)
                error.response = res
                throw error
            }
            // Setting the token in localStorage

        }).catch(error => {
            throw new Error("Identifiants incorrect")
        })
    }

    isLoggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token: string) {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken: string) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken(): string | undefined {
        // Retrieves the user token from localStorage
        const token = localStorage.getItem('id_token');
        if (token !== null && token !== undefined && token.length > 0) {
            return token;
        } else return undefined;
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    getProfile() {
        if (this.isLoggedIn())
            return decode(this.getToken() as string);
        else return undefined
    }

    _checkStatus(response: any) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error: any = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    _parseJSON(response: any) {
        return response && response.text().then(function (text: string) {
            return text ? JSON.parse(text) : {}
        })
    }

    hasAnyAuthorities(hasAnyAuthorities: String[]) {
        if (this.isLoggedIn()) {
            const profile: any = this.getProfile();
            const authorities = profile.role;
            if (authorities && authorities.length !== 0) {
                if (hasAnyAuthorities.length === 0) {
                    return true;
                }
                return hasAnyAuthorities.some(auth => authorities.includes(auth));
            }
            return false;
        }
        return false;
    }

    getUserInfo() {
        if (this.isLoggedIn()) {
            const profile: any = this.getProfile();

            return {
                firstname: profile.firstname,
                lastname: profile.lastname
            }
        }
        else return undefined
    }

    getRequestHeaders(headers: any) {
    
        if (this.isLoggedIn())
            headers["Authorization"] = `Bearer ${this.getToken()}`;
        
        return headers;
    }
}
