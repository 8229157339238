import { defaultIconColor, defaultIconSize, IIcon } from "../settings"

export const FoodIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 31 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M25.3944 11.3611C25.3944 16.7765 20.8533 21.1666 15.2516 21.1666C9.64995 21.1666 5.10889 16.7765 5.10889 11.3611C5.10889 5.94575 9.89979 3.10384 15.2516 7.23249C21.644 3.10384 25.3944 5.94575 25.3944 11.3611Z" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinejoin="round"/>
        <path d="M15.0019 6.93849C15.0019 6.93849 13.5074 4.6787 15.251 1.91675" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "3" : "2"} strokeLinecap="round"/>
        <ellipse rx="1.83287" ry="3.03477" transform="matrix(0.914825 -0.403851 0.410533 0.911846 10.364 13.7387)" fill={props.color || defaultIconColor}/>
        </svg>
    )
}
