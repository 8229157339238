
import { defaultIconColor, defaultIconSize, IIcon } from "./settings"


export const LensIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0882 12.7941C24.0882 19.0317 19.0317 24.0882 12.7941 24.0882C6.55655 24.0882 1.5 19.0317 1.5 12.7941C1.5 6.55655 6.55655 1.5 12.7941 1.5C19.0317 1.5 24.0882 6.55655 24.0882 12.7941Z" stroke={props.color || defaultIconColor} strokeWidth="3"/>
        <path d="M21 22L28 30" stroke={props.color || defaultIconColor} strokeWidth="3" strokeLinecap="round"/>
        </svg>
    )
}
