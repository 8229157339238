import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { colors } from './colors'


export const theme = responsiveFontSizes(createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: colors.blue.main
        },
        secondary: {
            main: colors.orange.main
        },
        error: {
            main: colors.red.main
        },
        warning: {
            main: colors.orange.main
        },
        info: {
            main: colors.blue.main
        },
        success: {
            main: colors.green.main
        },
        text: {
            primary: colors.black.main,
            secondary: colors.black.secondary
        }
    },
    typography: {
        fontFamily: "'Nunito'",
        fontWeightRegular: 400,
        fontWeightBold: 900,
        h1: {
            fontSize: '2.6rem',
            lineHeight: 1.2,
            fontWeight: 900
        },
        h2: {
            fontSize: '1.6rem',
            lineHeight: 1.2,
            fontWeight: 900
        },
        h3: {
            fontSize: '1.2rem',
            lineHeight: 1.2,
            fontWeight: 900
        },
        h4: {
            fontSize: '0.9rem',
            lineHeight: 0.4,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '1.1rem',
            lineHeight: 1.1,
            fontWeight: 400
        },
        subtitle2: {
            fontSize: '1.1rem',
            lineHeight: 1.1,
            fontWeight: 400,
            color: colors.black.secondary
        },
        body1: {
            fontSize: '0.9rem',
            lineHeight: 1.2,
            fontWeight: 400
        },
        body2: {
            fontSize: '0.9rem',
            lineHeight: 1.2,
            fontWeight: 400,
            color: colors.black.secondary
        },
        caption: {
            fontSize: '0.65rem',
            lineHeight: 0.9,
            fontWeight: 700
        },
        button: {
            fontSize: '0.9rem',
            lineHeight: 1.1,
            fontWeight: 900,
            textTransform: 'none'
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0)',
                    boxShadow: '0 0 0 0 white',
                    ':before': {
                        display: 'none'
                    }
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: 0,                   
                }, 
                expandIconWrapper: {
                    '&.Mui-expanded': {
                        transform:'rotate(90deg)'
                    }
                }              

            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    width: '100%',
                    borderRadius: 16,
                    textAlign: 'left'
                },
                action: {
                    padding: 0
                },
                message: {
                    paddingTop: '10px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 32,
                    padding: 16
                }
            },
            variants: [
                {
                    props: { variant: 'text' },
                    style: {
                    }
                },
                {
                    props: { variant: 'contained' },
                    style: {
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                    }
                }
            ],
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: 'none'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: '12px'
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white !important",
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.black.main,
                    textDecoration: "none",
                    ':hover': {
                        cursor: 'pointer'
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: colors.blue.secondary,
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "white !important",
                    boxShadow: "0px 4px 20px 0px #00000028"
                },
                root: {
                    padding: 0,
                    borderRadius: 32,
                    boxShadow: "2",
                },
                input: {
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingRight: 16,
                    paddingLeft: 20
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '12px',
                    borderRadius: '20px',
                    backgroundColor: colors.grey.secondary,
                    '& .MuiLinearProgress-bar': {
                        borderRadius: '20px',
                        backgroundColor: colors.blue.main
                    },
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px'
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    maxWidth: '1200px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderColor: "white !important",
                }
            }
        }
    }
}));
