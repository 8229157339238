import { PublicClientApplication } from "@azure/msal-browser";

import { IProduct, IPageProduct } from "../models/Product.model"

import AuthService from "../service/AuthService";

import { msalConfig } from "../settings/authConfig";


const apiUrl = process.env.REACT_APP_API_URL + '/marketplace'


export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}


const authService = new AuthService();


export const getProductsBo = (page: number, pageSize: number): Promise<IPageProduct> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/bo/product?page=${page}&pageSize=${pageSize}`,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                }
            })
            .then(_ => _.json());
    });
}


export const getProducts = (page: number,
                            pageSize: number,
                            ranking?: boolean,
                            filterUser?: boolean,
                            type?: string,
                            themes?: string[]): Promise<IPageProduct> => {
    let uri: string = `${apiUrl}/product?page=${page}&pageSize=${pageSize}`;

    if (type)
        uri += `&type=${type}`;

    if (themes && themes.length > 0)
        uri += `&themes=${themes}`

    if (filterUser)
        uri += `&filterUser=${filterUser}`

    if (ranking !== undefined)
        uri += `&ranking=${ranking}`

    return fetch(uri,
        {
            method: "GET",
            headers: authService.getRequestHeaders({})
        })
        .then(_ => _.json())
}


export const getProduct = (id: number): Promise<IProduct> => {

    return fetch(`${apiUrl}/product/${id}`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({})
        })
        .then(_ => _.json())
}


export const getProductByCode = (code: string): Promise<IProduct> => {

    return fetch(`${apiUrl}/product/code/${code}`,
        {
            method: "GET",
            headers: authService.getRequestHeaders({})
        })
        .then(_ => _.json())
}


export const searchProducts = (search: string | undefined, limit: number) => {
    if (search !== undefined)
        return fetch(`${apiUrl}/product/search?search=${search}&limit=${limit}`,
            {
                method: "GET"
            })
            .then(_ => {
                if (_.status === 200) return _.json()
                else throw "bad request"
            })
    else throw "undefined search"
}


export const createProduct = (produit: IProduct): Promise<IProduct> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/product`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(produit)
            })
            .then(_ => {
                if (_.status === 200) return _.json()
                else throw `POST product have returned : ${_.status}`
            })
    });
}


export const modifyProduct = (produit: IProduct): Promise<IProduct> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/product`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(produit)
            })
            .then(_ => {
                if (_.status === 200) return _.json()
                else throw `PUT product have returned : ${_.status}`
            })
    });
}


export const updateProductDownload = (id: number): Promise<IProduct> => {
    return fetch(`${apiUrl}/product/${id}/download`,
        {
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw `/product/${id}/download has returned : ${_.status}`
        });
}
