import { useContext, useEffect } from "react";

import Box from "@mui/material/Box";

import { VisibilityContext } from "react-horizontal-scrolling-menu";


interface Props {
    itemId: string;
    width?: string;
    children: JSX.Element;
    isVisible: (isVisible: boolean) => void;
}


export const CarouselItem = (props: Props) => {

    const visibility = useContext(VisibilityContext);

    if (visibility.initComplete)
        props.isVisible(visibility.isItemVisible(props.itemId));

    return (
        <Box
            sx={{
                height: 'calc(100% - 32px)',
                width: props.width || '100%',
                m: 2
            }} >
            {props.children}
        </Box>
    );
}
