import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ChevronRightIcon } from "../Icon/ChevronRightIcon";


interface Props {
    title: string;
    elements: JSX.Element[];
}


export const FooterColumn = (props: Props) => {


    return (
        <Grid
            container
            justifyContent='space-between' >

            <Grid
                container
                justifyContent='space-between'
                spacing={3}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography variant='h3'>
                        {props.title}
                    </Typography>
                </Grid>

                {props.elements.map((_, i) => {
                    return (
                        <Grid
                            key={`footer_${i}`}
                            item
                            xs={12} >
                            {_}
                        </Grid>
                    );
                })}

            </Grid>

            <Accordion
                sx={{
                    display: {
                        sm: 'block',
                        md: 'none'
                    }
                }} >

                <AccordionSummary
                    expandIcon={<ChevronRightIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" >
                    <Typography variant='h3'>
                        {props.title}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>
                        {props.elements.map((_, i) => {
                            return (
                                <Grid
                                    key={`footer_${i}`}
                                    item
                                    xs={12} >
                                    {_}
                                </Grid>
                            );
                        })}
                    </Grid>
                </AccordionDetails>

            </Accordion>

        </Grid>
    )
}
