
import { defaultIconColor, IIcon } from "./settings"


export const ArrowRightLongCurvedIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : "154"}
            height={props.height ? props.height : "70"}
            viewBox="0 0 154 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M144.884 66.1776V66.1776C110.694 33.7274 65.4458 15.4902 18.3096 15.1622L5.85266 15.0756" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "5" : "3"} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M116.992 64.435L146.826 67.5822L140.526 36.7512" stroke={props.color || defaultIconColor} strokeWidth={props.selected ? "5" : "3"} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
