import { Fragment, useEffect, useState } from 'react';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import {SelectChangeEvent} from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WarningIcon from '@mui/icons-material/Warning';

import { ErrorComponent } from '../../components/ErrorComponent';
import { FieldLabel } from '../../components/Field/FieldLabel';
import { HeaderBo } from '../../components/Header/HeaderBo';
import { Loading } from '../../components/Loading';

import { createOffer, getOffersBo, modifyOffer } from '../../api/Offer.api';
import { getProductsBo } from '../../api/Product.api';
import { saveRelationProductOffer } from '../../api/ProductOffer.api';

import { IOffer, IPageOffer } from '../../models/Offer.model';
import { IProduct } from '../../models/Product.model';
import { IProductOffer, OfferType, offerTypeOptions, UnitType, unitTypeOptions } from '../../models/ProductOffer.model';

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';


export const PageOffers = () => {

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.OFFERS;

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const [openDialogOffer, setOpenDialogOffer] = useState(false);

    const [offers, setOffers] = useState<IPageOffer>();
    const [products, setProducts] = useState<IProduct[]>([]);

    const handleUpdate = () => {
        getOffersBo(pageNumber, pageSize).then(res => {
            setOffers(res)
        })
    }


    useEffect(() => {
        getProductsBo(0, 10000).then(res => {
            setProducts(res.content)
        }).catch(() => {
            setProducts([])
        })
    }, [])


    useEffect(() => {
        handleUpdate()
    }, [pageNumber, pageSize])


    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading} >

            <ThemeProvider theme={theme}>

                <DialogOffer
                    open={openDialogOffer}
                    handleClose={() => {
                        setOpenDialogOffer(false);
                        handleUpdate();
                    }}
                    handleSubmit={() => {
                        handleUpdate();
                    }} />

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        mt: {
                            md: 2,
                            xs: 0
                        }
                    }} >

                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }} >
                        <Grid
                            item
                            xs={12} >
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setOpenDialogOffer(true);
                                }}
                                sx={{
                                    color: colors.black.main
                                }}>
                                Ajouter une offre
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12} >

                            <Card
                                sx={{
                                    border: 1,
                                    borderColor: colors.grey.secondary,
                                    width: '100%'
                                }}>
                                <TableContainer>
                                    <Table aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"></TableCell>
                                                <TableCell>Nom</TableCell>
                                                <TableCell>Code</TableCell>
                                                <TableCell align="center">Nb produits</TableCell>
                                                <TableCell align="center">Actif</TableCell>
                                                <TableCell
                                                    key={'outil'}
                                                    sx={{
                                                        minWidth: '20px'
                                                    }}>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {offers === undefined ?
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        sx={{
                                                            height: '150px',
                                                            textAlign: 'center'
                                                        }}>
                                                        <Typography
                                                            variant='subtitle1'>
                                                            Chargement des offres en cours...
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                : offers === null ?
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={6}
                                                            sx={{
                                                                height: '150px',
                                                                textAlign: 'center'
                                                            }}>
                                                            <WarningIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: colors.red.main
                                                                }} />
                                                            <Typography
                                                                variant='subtitle1'>
                                                                Une erreur est survenue lors de la récupération des offres
                                                            </Typography>
                                                            <Typography
                                                                variant='body2'
                                                                sx={{
                                                                    mt: 1
                                                                }}>
                                                                Veuillez réessayer ultérieurement
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    : offers.numberOfElements === 0 ?
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={6}
                                                                sx={{
                                                                    height: '150px',
                                                                    textAlign: 'center'
                                                                }}>
                                                                <InboxIcon
                                                                    fontSize="large"
                                                                    sx={{
                                                                        color: colors.black.secondary
                                                                    }} />
                                                                <Typography
                                                                    variant='subtitle1'>
                                                                    Aucune offre
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    : offers.content.map((_) => {
                                                        return (
                                                            <Row offer={_} products={products} update={() => handleUpdate()}/>
                                                        )
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    count={offers ? offers.totalElements : 0}
                                    rowsPerPage={pageSize}
                                    page={pageNumber}
                                    onPageChange={(event, newPage) => setPageNumber(newPage)}
                                    onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
                                    sx={{
                                        border: 0
                                    }} />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}


interface RowProps {
    offer: IOffer
    products: IProduct[]
    update: () => void;
}

function Row(props: RowProps) {
    const offer = props.offer
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogOffer, setOpenDialogOffer] = useState(false);

    const [productOffer, setProductOffer] = useState<IProductOffer>();

    useEffect(() => {
        setOpen(false)
    },[props.offer.id])

    const handleSubmit = (values: any) => {

        const toSendWithOffer: any = {
            offerId: offer.id,
            ...values
        }
        saveRelationProductOffer(toSendWithOffer)
        .then(res => {
            props.update();
            handleClose();
        }).catch((error) => {

        });
    }

    const handleClickModify = (_: IProductOffer) => {
        setProductOffer(_);
        setOpenDialog(true);
    }

    const handleClose = () => {
        setProductOffer(undefined);
        setOpenDialog(false);
    }
    

    return (
        <Fragment>
            <DialogProductOffer
                open={openDialog}
                products={props.products}
                productOffer={productOffer}
                handleClose={() => handleClose()}
                handleSubmit={(values) => handleSubmit(values)} />

            <DialogOffer
                open={openDialogOffer}
                offer={offer}
                handleClose={() => setOpenDialogOffer(false)}
                handleSubmit={() => props.update()} />

            <TableRow>
                <TableCell
                    align='center'
                    sx={{
                        width: '20px'
                    }}>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography component="span" variant="body1" fontWeight={700}>{offer.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography component="span" variant="body1" fontWeight={400}>{offer.code}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Chip
                        label={offer.productOffers?.length} />
                </TableCell>
                <TableCell align="center">
                    {offer.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        width: '20px'
                    }}>
                    <IconButton
                        onClick={() => setOpenDialogOffer(true)}>
                        <CreateIcon
                            sx={{
                                color: colors.blue.main
                            }} />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    colSpan={6}
                    sx={{
                        p: 0
                    }}>
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit>

                        <Typography
                            variant='subtitle1'>
                            <Button
                                variant='text'
                                onClick={() => setOpenDialog(true)}
                                startIcon={<AddIcon />}
                                sx={{
                                    color: colors.black.main,
                                    mx: 2,
                                    my: 1
                                }}>
                                Ajouter un produit
                            </Button>
                        </Typography>

                        {offer.productOffers === undefined || offer.productOffers.length === 0 ?
                        <Box
                            sx={{
                                height: '150px',
                                textAlign: 'center'
                            }}>
                            <InboxIcon
                                fontSize="large"
                                sx={{
                                    color: colors.black.secondary
                                }} />
                            <Typography
                                variant='subtitle1'>
                                Aucun produit
                            </Typography>
                        </Box>

                        :
                        <Card
                            sx={{
                                border: 1,
                                borderColor: colors.grey.secondary,
                                mx: 2,
                                mb: 2
                            }}>
                                
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {offer.productOffers?.map(productOffer => {
                                    return (
                                        <TableRow>
                                            <TableCell>{productOffer.product.name}</TableCell>
                                            <TableCell>{productOffer.product.code}</TableCell>
                                            <TableCell align="center">
                                                <Chip
                                                    color={
                                                        productOffer.type === OfferType.DISCOUNT ? 'success' :
                                                        productOffer.type === OfferType.PERIOD ? 'primary' : 'warning'}
                                                    label={offerTypeOptions.find(_ => _.value === productOffer.type)?.label}
                                                    sx={{
                                                        color: 'white'
                                                    }} />
                                            </TableCell>
                                            <TableCell align="center">
                                                {productOffer.value}&nbsp;
                                                <Chip
                                                    label={unitTypeOptions.find(_ => _.value === productOffer.unit)?.label} />
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    maxWidth: '225px'
                                                }}>
                                                {productOffer.description}
                                            </TableCell>
                                            <TableCell align="center">
                                                {productOffer.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}</TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    width: '20px'
                                                }}>
                                                <IconButton
                                                    onClick={() => handleClickModify(productOffer)} >
                                                    <CreateIcon
                                                        sx={{
                                                            color: colors.blue.main
                                                        }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )})}
                                </TableBody>
                            </Table>
                        </Card>}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}


interface IPropsDialogOffer {
    open: boolean;
    offer?: IOffer;
    handleClose: () => void;
    handleSubmit: () => void;
}


const DialogOffer = (props: IPropsDialogOffer) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        formik.setValues({
            name: props.offer?.name,
            code: props.offer?.code,
            description: props.offer?.description,
            isActive: props.offer?.isActive || false
        });
        formik.setTouched({});
    }, [props.open])


    const validationSchema = yup.object({
        name: yup.string().required('Champ obligatoire'),
        code: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues: {
            name: props.offer?.name,
            code: props.offer?.code,
            description: props.offer?.description,
            isActive: props.offer?.isActive || false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (props.offer) {
                const toSend: IOffer = {
                    id: props.offer.id,
                    dateCreated: props.offer?.dateCreated,
                    name: values.name as string,
                    code: values.code as string,
                    description: values.description as string,
                    isActive: values.isActive,
                    productOffers: props.offer?.productOffers
                }
                modifyOffer(toSend).then((response: IOffer) => {
                    if (response.id) {
                        props.handleClose();
                        props.handleSubmit();
                    }
                });

            }
            else {
                const toSend: IOffer = {
                    name: values.name as string,
                    code: values.code as string,
                    description: values.description as string,
                    isActive: values.isActive,
                    productOffers: []
                }

                createOffer(toSend).then((response: IOffer) => {
                    if (response.id) {
                        props.handleClose();
                        props.handleSubmit();
                    }
                });
            }
        }
    })


    return (
        <Dialog
            maxWidth={'md'}
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Offre
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.code && Boolean(formik.errors.code)} >
                                <FieldLabel label="Code" isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="code"
                                variant="outlined"
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.name && Boolean(formik.errors.name)} >
                                <FieldLabel label="Nom" isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="name"
                                variant="outlined"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                <FieldLabel label="Description" />
                            </FormLabel>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description} />
                        </Grid>
                                                        
                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'center'
                            }}>
                            <Typography
                                component='span'
                                fontWeight={700}>
                                Est actif :
                            </Typography>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif" />
                        </Grid>      

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        type="submit"
                        sx={{
                            color: 'white'
                        }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}


interface IPropsDialogProductOffer {
    open: boolean;
    products: IProduct[];
    productOffer?: IProductOffer;
    handleClose: () => void;
    handleSubmit: (toSend) => void;
}


const initialValues = {
    product: undefined as string | undefined,
    type: undefined as any,
    description: undefined as undefined | string,
    value: undefined as undefined | number,
    unit: undefined as undefined | string,
    isActive: false as boolean,
}


const DialogProductOffer = (props: IPropsDialogProductOffer) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const validationSchema = yup.object({
        product: yup.string().required('Champ obligatoire'),
        type: yup.string().required('Champ obligatoire'),
        value: yup.number().required('Champ obligatoire'),
        unit: yup.string().required('Champ obligatoire'),
        description: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: any = {
                id: props.productOffer?.id,
                productId: props.products.find(_ => _.code === values.product)?.id,
                type: values.type as string,
                value: values.value,
                unit: values.unit,
                description: values.description,
                isActive: values.isActive
            }
            props.handleSubmit(toSend);
        }
    })


    useEffect(() => {
        formik.resetForm();

        if(props.productOffer) {
            const values = {
                product: props.productOffer.product.code,
                type: props.productOffer.type,
                value: props.productOffer.value,
                unit: props.productOffer.unit,
                description: props.productOffer.description,
                isActive: props.productOffer.isActive as boolean,
            }

            formik.setValues(values);
        }
        else {
            formik.setValues(initialValues);
        }
    }, [props.open, props.productOffer])


    useEffect(() => {
        if (formik.touched.type)
            formik.setFieldValue("unit", undefined);
    }, [formik.values.type])


    return (
        <Dialog
            maxWidth={'md'}
            open={props.open}
            fullScreen={fullScreen}>

            <DialogTitle
                sx={{
                    m: 0,
                    p: 2
                }}>
                <Typography
                    variant='h3'>
                    Produit
                </Typography>
                <IconButton
                    onClick={() => props.handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.black.main,
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        justifyContent='space-between'
                        spacing={3}>
                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.product && Boolean(formik.errors.product)} >
                                <FieldLabel label="Produit" isRequired />
                            </FormLabel>
                            <Select
                                labelId="product"
                                id="product"
                                fullWidth
                                value={formik.values.product}
                                onChange={(e: SelectChangeEvent) => formik.setFieldValue("product", e.target.value)}
                                onBlur={() => formik.setFieldTouched("product")}
                                input={<OutlinedInput label="Produit" />} >
                                {props.products && props.products.map((product: IProduct) => (
                                    <MenuItem
                                        key={product.id}
                                        value={product.code}>
                                        {product.code}
                                    </MenuItem>
                                ))}
                            </Select>

                            {formik.touched.product && formik.errors.product &&
                            <FormHelperText>
                                <Typography
                                    variant='caption'
                                    color={colors.red.main}>
                                    {formik.errors.product}
                                </Typography>
                            </FormHelperText>}
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.type && Boolean(formik.errors.type)} >
                                <FieldLabel label="Type" isRequired />
                            </FormLabel>
                            <Select
                                labelId="type"
                                id="type"
                                fullWidth
                                value={formik.values.type}
                                onChange={(e: SelectChangeEvent) => formik.setFieldValue("type", e.target.value)}
                                onBlur={() => formik.setFieldTouched("type")}
                                input={<OutlinedInput label="Type" />} >
                                {offerTypeOptions.map((_, id) => (
                                    <MenuItem
                                        key={id}
                                        value={_.value}>
                                        {_.label}
                                    </MenuItem>
                                ))}
                            </Select>

                            {formik.touched.type && formik.errors.type &&
                            <FormHelperText>
                                <Typography
                                    variant='caption'
                                    color={colors.red.main}>
                                    {formik.errors.type}
                                </Typography>
                            </FormHelperText>}
                        </Grid>

                        <Grid
                            item
                            xs={6}>
                            <FormLabel error={formik.touched.value && Boolean(formik.errors.value)} >
                                <FieldLabel label="Valeur" isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="value"
                                name="value"
                                type="number"
                                inputProps={{
                                    step: "0.5"
                                }}
                                value={formik.values.value}
                                onChange={formik.handleChange}
                                error={formik.touched.value && Boolean(formik.errors.value)}
                                helperText={formik.touched.value && formik.errors.value}
                                onBlur={formik.handleBlur} />
                        </Grid>

                        <Grid
                            item
                            xs={6}>
                            <FormLabel error={formik.touched.unit && Boolean(formik.errors.unit)} >
                                <FieldLabel label="Unité" isRequired />
                            </FormLabel>
                            <Select
                                labelId="unit"
                                id="unit"
                                fullWidth
                                value={formik.values.unit}
                                onChange={(e: SelectChangeEvent) => formik.setFieldValue("unit", e.target.value)}
                                onBlur={() => formik.setFieldTouched("unit")}
                                input={<OutlinedInput label="Unité" />} >
                                {unitTypeOptions.filter(_ => {
                                    switch (formik.values.type) {
                                        case undefined:
                                            return true;

                                        case OfferType.DISCOUNT:
                                            if (_.value === UnitType.EURO || _.value === UnitType.PERCENT)
                                                return true;

                                            return false;
                                        
                                        case OfferType.PERIOD:
                                            if (_.value === UnitType.DAY || _.value === UnitType.MONTH || _.value === UnitType.YEAR)
                                                return true;

                                            return false;

                                        case OfferType.CONSO:
                                            if (_.value === UnitType.NUMBER)
                                                return true;

                                            return false;
                                    }
                                })
                                .map((_, id) => (
                                    <MenuItem
                                        key={id}
                                        value={_.value}>
                                        {_.label}
                                    </MenuItem>
                                ))}
                            </Select>

                            {formik.touched.unit && formik.errors.unit &&
                            <FormHelperText>
                                <Typography
                                    variant='caption'
                                    color={colors.red.main}>
                                    {formik.errors.unit}
                                </Typography>
                            </FormHelperText>}
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                <FieldLabel label="Description" isRequired />
                            </FormLabel>
                            <TextField
                                multiline
                                minRows={5}
                                maxRows={10}
                                fullWidth
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description} />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: 'right'
                            }}>
                            <Typography
                                component='span'
                                fontWeight={700}>
                                Est actif :
                            </Typography>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant='contained'
                        autoFocus
                        type="submit"
                        sx={{
                            color: 'white'
                        }}>
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
