import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";

import { FacebookCircleIcon } from "../Icon/Social/FacebookCircleIcon";
import { LinkedinCircleIcon } from "../Icon/Social/LinkedinCircleIcon";
import { TwitterCircleIcon } from "../Icon/Social/TwitterCircleIcon";
import { YoutubeCircleIcon } from "../Icon/Social/YoutubeCircleIcon";

import { colors } from "../../static/colors";


export const FooterSocial = () => {


    return (
        <Grid
            container
            justifyContent={{
                md: 'left',
                xs: 'center'
            }}
            spacing={{
                lg: 3,
                md: 2,
                xs: 3
            }} >

            <Grid
                item >

                <IconButton
                    aria-label="download"
                    sx={{
                        p: 0,
                        float: 'right'
                    }}
                    onClick={() => {}} >
                    <LinkedinCircleIcon />
                </IconButton>
            </Grid>

            <Grid
                item >

                <IconButton
                    aria-label="download"
                    sx={{
                        p: 0,
                        float: 'right'
                    }}
                    onClick={() => {}} >
                    <FacebookCircleIcon />
                </IconButton>
            </Grid>

            <Grid
                item >

                <IconButton
                    aria-label="download"
                    sx={{
                        p: 0,
                        float: 'right'
                    }}
                    onClick={() => {}} >
                    <TwitterCircleIcon bgColor={colors.orange.light}/>
                </IconButton>
            </Grid>

            <Grid
                item >

                <IconButton
                    aria-label="download"
                    sx={{
                        p: 0,
                        float: 'right'
                    }}
                    onClick={() => {}} >
                    <YoutubeCircleIcon />
                </IconButton>
            </Grid>
                
        </Grid>
    )
}
