import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CardLink } from '../Card/CardLink';
import { CardPackDigitalDiscover } from '../Card/CardPackDigitalDiscover';
import { CardSection } from '../Card/CardSection';
import { CarouselProduct } from '../Carousel/CarouselProduct';
import { FilterTheme } from '../Filter/FilterTheme';
import { ListProducts } from '../List/ListProducts';
import { PageHeader } from '../Page/PageHeader';
import { TitleSection } from '../Title/TitleSection';

import { PlusIcon } from '../Icon/PlusIcon';
import { SmartphoneIllustration } from '../Icon/Illustration/SmartphoneIllustration';
import { ObjectIllustration } from '../Icon/Illustration/ObjectIllustration';
import { ServiceIllustration } from '../Icon/Illustration/ServiceIllustration';

import { getSections } from '../../api/Section.api';
import { getThemes } from '../../api/Theme.api';

import { ProductType } from '../../models/Product.model';
import { ISection, SectionType } from '../../models/Section.model';
import { ITheme } from '../../models/Theme.model';

import { getPageInfos, IPageInfos, Page } from '../../settings/Page';

import { colors } from '../../static/colors';
import { useNavigate } from 'react-router-dom';


interface Props {
    page: Page;
}


export const PageProducts = (props: Props) => {

    const navigate = useNavigate();
    const pageInfos: IPageInfos = getPageInfos(props.page);
    const [themes, setThemes] = useState<ITheme[] | undefined>();  // TODO : order by number of products
    const [sectionHighlight, setSectionHighlight] = useState<ISection | undefined>();

    const [nbThemesDisplayed, setNbThemesDisplayed] = useState<number>(2);

    useEffect(() => {
        window.scrollTo({
            top: 0
        });

        getThemes(0, 50)
        .then((response) => {
            setThemes(response.content);
        })
        .catch(exception => setThemes([]));
    }, [])


    useEffect(() => {
        getSections(0, 1, SectionType.HIGHLIGHT)
        .then((response) => {
            setSectionHighlight(response.content[0]);
        })
        .catch(exception => setSectionHighlight(undefined));
    }, [])


    return (
        <Box
            sx={{
                maxWidth: '1200px',
                width: '100%',
                mx: 'auto',
                mt: 2
            }} >

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={3}
                sx={{
                    px: {
                        md: 5,
                        xs: 2
                    }
                }} >

                <Grid
                    item
                    md
                    xs={12} >
                    <PageHeader page={props.page} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FilterTheme
                        filterBy={(_) => {
                            if (_ === undefined)
                                return;

                                navigate(`/theme/${_.toLowerCase()}`);
                        }} />
                </Grid>
                
                {sectionHighlight && sectionHighlight.products.length > 0 &&
                <Grid
                    item
                    xs={12}
                    sx={{
                        mt: 2
                    }}>
                    <CardSection
                        section={sectionHighlight} />
                </Grid>}

                <Grid
                    item
                    xs={12}>
                    <TitleSection
                        content={
                            <Box
                                component='span'>
                                {props.page === Page.APPLICATIONS ? "Les applications préférées" :
                                 props.page === Page.OBJECTS ? "Les objets préférés" :
                                 props.page === Page.SERVICES ? "Les services préférés" : "Les produits préférés"}
                                <Box
                                    component='span'
                                    sx={{
                                        display: {
                                            md: 'inline',
                                            xs: 'none'
                                        }
                                    }}>
                                    &nbsp;des adhérents GSMC
                                </Box>
                            </Box>} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <CarouselProduct
                        type={pageInfos.productType || ProductType.APPLICATION}
                        showRank />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}
                        onClick={() => navigate("/pack-digital")} >
                        Découvrir le pack digital
                    </Button>
                </Grid>

                {themes && themes.slice(0, nbThemesDisplayed).map(_ => {
                    return (
                        <>
                        <Grid
                            item
                            xs={12} >
                            <TitleSection
                                content={
                                    <Box component='span'>
                                        Catalogue
                                        {props.page === Page.APPLICATIONS ? " applications " :
                                         props.page === Page.OBJECTS ? " objets " :
                                         props.page === Page.SERVICES ? " services " : ""}
                                         “{_.name}”
                                    </Box>}
                                label="Voir toute la thématique"
                                onClick={() => {
                                    if (_ === undefined)
                                        return;
    
                                        navigate(`/theme/${_.code.toLowerCase()}`);
                                }}/>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <ListProducts
                                type={props.page === Page.APPLICATIONS ? ProductType.APPLICATION :
                                        props.page === Page.OBJECTS ? ProductType.OBJECT :
                                        props.page === Page.SERVICES ? ProductType.SERVICE : undefined}
                                filterByThemes={[_.code]}/>
                        </Grid>
                        </>
                    );
                })}

                {themes && themes.length > nbThemesDisplayed &&
                <Grid
                    item
                    xs={12}
                    sx={{
                        textAlign: 'center'
                    }}>
                    
                    <Button
                        sx={{
                            color: colors.black.main
                        }}
                        onClick={() => setNbThemesDisplayed(nbThemesDisplayed + 2)}>
                        <Box>
                            <Typography variant='button' component='div' sx={{mb: 1}}>
                                Voir + de catalogues
                                {pageInfos.productType === ProductType.APPLICATION ? " d'applications" :
                                 pageInfos.productType === ProductType.OBJECT ? " d'objets" :
                                 pageInfos.productType === ProductType.SERVICE ? " de services" : ""}
                            </Typography>
                            <PlusIcon />
                        </Box>
                    </Button>

                </Grid>}

                {props.page !== Page.APPLICATIONS &&
                <Grid
                    item
                    md={6}
                    xs={12}>
                    <CardLink
                        label="Découvrez les applications santé"
                        icon={<SmartphoneIllustration />}
                        uri='/applications'/>
                </Grid>}

                {props.page !== Page.OBJECTS &&
                <Grid
                    item
                    md={6}
                    xs={12}>
                    <CardLink
                        label="Découvrez les objets santé"
                        icon={<ObjectIllustration color={colors.blue.main} />}
                        uri='/objets'/>
                </Grid>}

                {props.page !== Page.SERVICES &&
                <Grid
                    item
                    md={6}
                    xs={12}>
                    <CardLink
                        label="Découvrez les services santé"
                        icon={<ServiceIllustration color={colors.orange.main} showDetails />}
                        uri='/services'/>
                </Grid>}

                <Grid
                    item
                    xs={12}
                    sx={{
                        my: {
                            md: 5,
                            xs: 3
                        }
                    }}>
                    <CardPackDigitalDiscover />
                </Grid>
            </Grid>

        </Box>
    );
}
