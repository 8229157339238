import { cloneElement, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ErrorComponent } from '../../components/ErrorComponent';
import { HeaderBo } from '../../components/Header/HeaderBo';
import { FruitIcon } from '../../components/Icon/FruitIcon';
import { SmartphoneIllustration } from '../../components/Icon/Illustration/SmartphoneIllustration';
import { ObjectIllustration } from '../../components/Icon/Illustration/ObjectIllustration';
import { ServiceIllustration } from '../../components/Icon/Illustration/ServiceIllustration';
import { Loading } from '../../components/Loading';

import { loginRequest } from '../../settings/authConfig';
import { PageBo } from '../../settings/Page';

import { colors } from '../../static/colors';
import { theme } from '../../static/theme';


export const IndexBackOffice = () => {

    const authRequest = {
        ...loginRequest
    };

    const page: PageBo = PageBo.INDEX;

    const navigate = useNavigate();


    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading} >

            <ThemeProvider theme={theme}>

                <HeaderBo
                    page={page} />

                <Box
                    sx={{
                        width: '100%',
                        mx: 'auto',
                        mt: {
                            md: 2,
                            xs: 0
                        }
                    }} >

                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={3}
                        sx={{
                            px: {
                                md: 5,
                                xs: 2
                            }
                        }} >
                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='h2'>
                                Back Office
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <CardLinkBO
                                title="Produits"
                                description="Gérer les applications, objets et services de la marketplace"
                                icon={<SmartphoneIllustration showDetails />}
                                onClick={() => {
                                    navigate("/bo/produits")
                                }}/>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <CardLinkBO
                                title="Offres"
                                description="Gérer les offres de la marketplace"
                                icon={<ObjectIllustration color={colors.blue.main} showDetails />}
                                onClick={() => {
                                    navigate("/bo/offres")
                                }}/>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <CardLinkBO
                                title="Sections"
                                description="Gérer les sections “Zoom sur” de la marketplace"
                                icon={<FruitIcon showDetails />}
                                onClick={() => {
                                    navigate("/bo/sections")
                                }}/>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <CardLinkBO
                                title="Thèmes"
                                description="Gérer les thèmes des produits de la marketplace"
                                icon={<ServiceIllustration color={colors.orange.main} showDetails />}
                                onClick={() => {
                                    navigate("/bo/themes")
                                }}/>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </MsalAuthenticationTemplate>
    )
}


interface IPropsCardBO {
    title: string;
    description?: string;
    icon?: JSX.Element;
    onClick: () => void;
}


const CardLinkBO = (props: IPropsCardBO) => {


    return (
        <Card
            onClick={() => props.onClick()}
            sx={{
                height: '100%',
                my: 2,
                boxShadow: "0px 4px 20px 0px #00000028, 0px -4px 20px 0px #00000028",
                ':hover': {
                    cursor: 'pointer',
                    boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860"
                }
            }}>
            <Grid
                container
                justifyContent="space-around"
                alignItems="center"
                sx={{
                    height: '100%'
                }}>

                {props.icon &&
                <Grid
                    item
                    xs='auto'
                    sx={{
                        mx: 2,
                        textAlign: 'center'
                    }}>
                    {cloneElement(props.icon, {width: '50px', height: '50px'})}
                </Grid>}

                <Grid
                    item
                    xs
                    sx={{
                        mr: 2
                    }}>
                    <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        sx={{
                            mb: 2
                        }}>
                        {props.title}
                    </Typography>

                    {props.description &&
                    <Typography
                        variant="body1"
                        fontWeight={400}>
                        {props.description}
                    </Typography>}
                </Grid>
            </Grid>
        </Card>
    )
}
